export default (theme) => ({
  root: {},
  title: {
    color: theme.palette.text.main,
    fontSize: '18px',
    fontWeight: '300',
    margin: '0',
    border: 'none',
    marginBottom: '25px',
  },
  documentContainer: {
    border: `1px solid ${theme.palette.text.light}`,
    borderRadius: '6px',
    padding: '10px',
    height: '15em',
    overflowY: 'scroll',
    marginBottom: '20px',
  },
  docTitle: {
    color: theme.palette.text.main,
    fontSize: '18px',
    fontWeight: '500',
    margin: '0',
    border: 'none',
    marginBottom: '25px',
  },
  docParahraph: {
    color: theme.palette.text.main,
    fontSize: '16px',
    fontWeight: '300',
    margin: '0',
    border: 'none',
    marginBottom: '5px',
  },
  paragraphTitle: {
    color: theme.palette.text.main,
    fontSize: '16px',
    fontWeight: 'bold',
    border: 'none',
    margin: '10px 0',
  },
  paddingLeft: {
    paddingLeft: '20px',
  },
  buttonsSection: {
    margin: '20px 0',
  },
  terms: {
    color: theme.palette.text.main,
    fontSize: '14px',
    fontWeight: '300',
    lineHeight: '20px',
  },
});
