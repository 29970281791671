import { combineReducers } from 'redux';

import verification from '../pages/Verification/reducers';
import profile from '../pages/ProfileData/reducers';
import personalDetails from '../components/PersonalDetailsForm/reducers';

const reducer = combineReducers({
  verification,
  profile,
  personalDetails,
});

export default reducer;
