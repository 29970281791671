import { ERROR_MESSAGE_HEIGHT } from '../../constants';

const styles = (theme) => ({
  wrapper: {
    width: '100%',
    paddingBottom: ERROR_MESSAGE_HEIGHT,
  },
  wrapperTwoMessageLines: {
    //paddingBottom: ERROR_MESSAGE_HEIGHT * 2,
  },
  label: {
    color: theme.palette.simpleGrey,
    fontSize: 15,
    lineHeight: '15px',
    '&$labelFocused:not($labelError)': {
      color: theme.palette.simpleGrey,
    },
  },
  labelFocused: {},
  labelError: {},
  textFieldContainer: {
    flex: 1,
    width: '100%',
    marginBottom: 7,
  },
  textField: {
    '&$textFieldFocused:not($textFieldError) $notchedOutline': {
      borderColor: `${theme.palette.hawkesBlue} !important`,
    },
    '&:hover:not($textFieldDisabled):not($textFieldError):not($textFieldFocused) $notchedOutline': {
      borderColor: '#DADFEC !important',
    },
    '&$textFieldError $notchedOutline': {
      borderColor: `${theme.palette.error.main} !important`,
    },
    height: 48,
    color: '#36425A',
    lineHeight: '20px',
    fontSize: 16,
  },
  purpleBorder: {
    '&$textFieldFocused:not($textFieldError) $notchedOutline': {
      borderColor: `${theme.palette.moodyBlue} !important`,
    },
    '&:hover:not($textFieldDisabled):not($textFieldError):not($textFieldFocused) $notchedOutline': {
      borderColor: `${theme.palette.moodyBlue} !important`,
    },
  },
  multiline: {
    height: 'auto',
    minHeight: 48,
  },
  notchedOutline: {
    borderWidth: '1px !important',
    borderColor: '#DADFEC !important',
  },
  textFieldFocused: {},
  textFieldDisabled: {},
  textFieldError: {},
  input: {
    color: theme.input.color,
    '&::placeholder': {
      color: theme.palette.simpleGrey,
      opacity: 1,
      fontSize: 16,
      lineHeight: '20px',
    },
  },
  privateInput: {
    letterSpacing: 8,
    '&::placeholder': {
      letterSpacing: 'normal',
    },
  },
  iconButton: {
    color: theme.palette.blackText,
    fontSize: 22,
    width: 36,
    height: 36,
    padding: 7,
  },
  error: {
    ...theme.textError,
    bottom: 0,
    left: 0,
    maxHeight: ERROR_MESSAGE_HEIGHT - 3,
  },
  errorIcon: {
    fontSize: 12,
  },
  tip: {
    ...theme.tip,
  },
  optionalText: {
    color: theme.palette.simpleGrey,
  },
  tooltip: {
    border: '1px solid #EFF2F7',
    borderRadius: '4px 0 4px 4px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 4px 10px 0 rgba(73,93,124,0.13)',
    padding: '6px 11px',
    opacity: 1,
  },
  tooltipPopper: {
    left: '-105px !important',
    [theme.breakpoints.down(480)]: {
      left: '-20px !important',
    },
  },
  tooltipText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#36425A',
    fontSize: 12,
    fontWeight: 300,
    letterSpacing: '0.05px',
    lineHeight: '20px',
  },
  tooltipIcon: {
    fontSize: 14,
    marginRight: 4,
    color: '#2727C5',
  },
  tooltipPlacementBottom: {
    margin: '-18px 0',
  },
  invisible: {
    display: 'none',
  },
  formHelperText: {
    marginTop: '-2px',
    color: theme.palette.simpleGrey,
  },
});

export default styles;
