import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import formatLargeText from 'utils/formatLargeText';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { getFieldData } from '../TableTextField/helpers';
import CustomLink from 'modules/common/CustomLink';

const getWidth = (width) => ({ width });

const TableSingleTextField = ({
  classes,
  label,
  dataLabel,
  tableItem,
  formatFunc,
  getRedirectRoute,
  showLabel = false,
  isHeadRow,
  width = 208,
  halfSymbolsAmount = 12,
}) => {
  const { fieldValue: fullText } = useMemo(() => getFieldData(label, dataLabel, tableItem), [
    label,
    dataLabel,
    tableItem,
  ]);

  if (showLabel && isHeadRow) {
    return (
      <div className={classes.item} style={getWidth(width)}>
        <Typography className={classes.label}>{label}</Typography>
      </div>
    );
  }

  const shortText = (formatFunc || formatLargeText)(fullText, false, halfSymbolsAmount);

  const TooltipWrapper = ({ children }) => {
    if (shortText !== fullText && !formatFunc) {
      return <Tooltip title={fullText}>{children}</Tooltip>;
    }
    return children;
  };

  const CustomLinkWrapper = ({ children }) => {
    if (getRedirectRoute) {
      return <CustomLink to={getRedirectRoute(tableItem)}>{children}</CustomLink>;
    }
    return children;
  };

  return (
    <div className={classes.item} style={getWidth(width)}>
      <CustomLinkWrapper>
        <TooltipWrapper>
          <Typography className={classes.text}>{shortText || fullText}</Typography>
        </TooltipWrapper>
      </CustomLinkWrapper>
    </div>
  );
};

TableSingleTextField.propTypes = {
  showLabel: PropTypes.bool,
  label: PropTypes.string.isRequired,
  dataLabel: PropTypes.string.isRequired,
  tableItem: PropTypes.object.isRequired,
  formatFunc: PropTypes.func,
  width: PropTypes.number,
  halfSymbolsAmount: PropTypes.number,
  getRedirectRoute: PropTypes.func,
};

export default withStyles(styles)(TableSingleTextField);
