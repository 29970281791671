import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';

import TPIconButton from 'components/TP-UI/TPIconButton';
import TPTooltip from 'components/TP-UI/TPTooltip';
import { SIZES, TRIGGER } from 'components/TP-UI/constants';

const TPCopyButton = ({ text, disabled = false, onClick, size = SIZES.MEDIUM }) => {
  const { t } = useTranslation('common');
  const browserSupport = !!navigator.clipboard;
  const [message, setMessage] = useState(t('labels.copy'));
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleClick = useCallback(() => {
    if (browserSupport) {
      navigator.clipboard.writeText(text);

      setMessage(t('labels.copied'));
      setTooltipOpen(true);

      setTimeout(() => {
        setMessage(t('labels.copy'));
        setTooltipOpen(false);
      }, 3000);

      if (onClick) {
        onClick();
      }
    }
  }, [text, onClick, t, browserSupport]);

  return browserSupport ? (
    <TPTooltip value={tooltipOpen} trigger={TRIGGER.HOVER} content={message}>
      <TPIconButton
        aria-label={t('labels.copy')}
        disabled={disabled}
        onClick={handleClick}
        size={size}>
        <FileCopyOutlinedIcon fontSize={size === SIZES.XSMALL ? 'inherit' : size} />
      </TPIconButton>
    </TPTooltip>
  ) : null;
};

TPCopyButton.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf([SIZES.LARGE, SIZES.MEDIUM, SIZES.SMALL, SIZES.XSMALL]),
};

export default TPCopyButton;
