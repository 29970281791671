import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReportProblemOutlined } from '@mui/icons-material';
import { Box } from 'material-latest';

import { getTranslation } from 'utils/compositeTranslationHandler';
import { SIZES } from 'components/TP-UI/constants';
import styles from './styles';

const TPWarningMessage = ({ warning, size, className }) => {
  const { t } = useTranslation('common');

  return (
    <Box
      component={'span'}
      sx={[styles.warning, className, size === SIZES.MEDIUM && styles.mediumSize]}>
      <ReportProblemOutlined fontSize={size} color="inherit" />
      &nbsp;
      {getTranslation(t, warning) || 'Warning'}
    </Box>
  );
};

TPWarningMessage.propTypes = {
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  size: PropTypes.oneOf([SIZES.SMALL, SIZES.MEDIUM]),
};

export default TPWarningMessage;
