import React from 'react';
import PropTypes from 'prop-types';
import { Box, Avatar } from 'material-latest';

import styles from './styles';

const TPCurrency = ({ label, flag }) => {
  return label || flag ? (
    <Box component="span" sx={styles.root}>
      {flag ? <Avatar variant="square" sx={styles.flagIcon} alt={label} src={flag} /> : null}
      {label || ''}
    </Box>
  ) : null;
};

TPCurrency.propTypes = {
  label: PropTypes.string,
  flag: PropTypes.string,
};

export default TPCurrency;
