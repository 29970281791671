export default (theme) => ({
  wrapper: {
    width: '100%',
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 40px',
    bottom: 0,
    backgroundColor: theme.palette.lightGreen,
    zIndex: 1600,
    opacity: 0.8,
  },
  policyText: {
    minHeight: '40px',
    maxHeight: '150px',
    overflow: 'auto',
    padding: '10px 0',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '19px',
    color: theme.palette.textColor,
    letterSpacing: 0,
  },
  policyLinkText: {
    outlineColor: theme.palette.link.outlineColor,
    color: theme.palette.blackText,
    fontWeight: 700,
    textDecoration: 'none',
    fontSize: 14,
    '&:hover': {
      color: theme.palette.link.hover,
    },
    '&:active': {
      color: theme.palette.link.active,
    },
  },
  closeButton: {
    position: 'absolute',
    right: '20px',
    fontSize: '16px',
    color: theme.palette.textColor,
    [theme.breakpoints.down('md')]: {
      position: 'inherit',
      right: 0,
    },
  },
});
