import * as employeeActions from 'modules/userManagement/actions';
import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = [];

export default handleActions(
  {
    [actionCreators.getEmployeesSuccess](state, action) {
      return action.response.data.employees;
    },
    [actionCreators.getEmployeesFail]() {
      return defaultState;
    },
    [actionCreators.updateEmployee](state, action) {
      const updatedEmployee = action.payload;
      return state.map((employee) => {
        if (employee._id === updatedEmployee._id) {
          return updatedEmployee;
        }
        return employee;
      });
    },
    [actionCreators.editEmployeesAssignedCxdSuccess](state, action) {
      const updatedEmployee = action.payload.user;
      return state.map((employee) => {
        if (employee._id === updatedEmployee._id) {
          return { ...updatedEmployee, assignedCxds: action.response.data.assignedCxds };
        }
        return employee;
      });
    },
    [actionCreators.editEmployeesAssignedCxdFail]() {
      return defaultState;
    },
    [employeeActions.addEmployeeSuccess](state, action) {
      return [...state, action.response.data];
    },
  },

  defaultState,
);
