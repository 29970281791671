import { combineReducers } from 'redux';

import verificationUser from './verificationUser';
import stepper from './stepper';

const reducer = combineReducers({
  verificationUser,
  stepper,
});

export default reducer;
