export const UPLOADER_FILE_TYPE = {
  PNG: 'PNG',
  JPG: 'JPG',
  JPEG: 'JPEG',
  PDF: 'PDF',
};

export const MIME_TYPE = {
  [UPLOADER_FILE_TYPE.PDF]: 'application/pdf',
  [UPLOADER_FILE_TYPE.PNG]: 'image/png',
  [UPLOADER_FILE_TYPE.JPG]: 'image/jpg',
  [UPLOADER_FILE_TYPE.JPEG]: 'image/jpeg',
};

export const FILE_TYPES_FROM_MIME = {
  [MIME_TYPE.PDF]: UPLOADER_FILE_TYPE.PDF,
  [MIME_TYPE.JPG]: UPLOADER_FILE_TYPE.JPG,
  [MIME_TYPE.JPEG]: UPLOADER_FILE_TYPE.JPEG,
  [MIME_TYPE.PNG]: UPLOADER_FILE_TYPE.PNG,
};

export const IMAGE_FILE_TYPES = { [MIME_TYPE.JPG]: [], [MIME_TYPE.JPEG]: [], [MIME_TYPE.PNG]: [] };

export const PDF_FILE_TYPES = { [MIME_TYPE.PDF]: [] };

export const PHOTO_FILE_TYPES = { ...IMAGE_FILE_TYPES, ...PDF_FILE_TYPES };
