import React, { useCallback, useState } from 'react';
import OverviewDataView from '../views/OverviewDataView';
import {
  CUSTOM_DATE_RANGE_KEY,
  DASHBOARD_OVERVIEW_DATA_HEADER_FORM,
  DASHBOARD_STATS_CUSTOMIZATION_FORM,
} from '../constants';
import moment from 'moment';
import get from 'lodash/get';
import {
  getDashboardComparisonGraphDataRequest,
  getDashboardGraphDataRequest,
  getDashboardOverviewDataRequest,
  saveDashboardStatsCustomizationDataRequest,
} from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDashboardComparisonGraphDatasets,
  getDashboardGraphDatasets,
  getDashboardOverviewData,
  getDashboardStats,
  getLeadOwners,
} from '../selectors';
import { createPendingSelector } from '../../api/selectors';
import { DATE_FORMAT_YYYYMMDD } from '../../../constants';
import { getIsGlobalAdmin } from 'modules/auth/selectors';

const OverviewDataContainer = () => {
  const dispatch = useDispatch();
  const stats = useSelector(getDashboardStats);
  const overviewData = useSelector(getDashboardOverviewData);
  const graphDatasets = useSelector(getDashboardGraphDatasets);
  const isGraphDataLoading = useSelector(createPendingSelector(getDashboardGraphDataRequest));
  const isOverviewDataLoading = useSelector(createPendingSelector(getDashboardOverviewDataRequest));
  const [shouldCompareTo, setShouldCompareTo] = useState(false);
  const comparisonGraphDatasets = useSelector(getDashboardComparisonGraphDatasets);
  const isComparisonGraphDataLoading = useSelector(
    createPendingSelector(getDashboardComparisonGraphDataRequest),
  );
  const isGlobalAdmin = useSelector(getIsGlobalAdmin);
  const leadOwners = useSelector(getLeadOwners);

  const handleHeaderFormSubmit = useCallback(
    (data) => {
      const dateFrom = get(data, 'dateFrom', moment());
      const dateTo = get(data, 'dateTo', moment());
      const formattedDateFrom = moment(dateFrom).format(DATE_FORMAT_YYYYMMDD);
      const formattedDateTo = moment(dateTo).format(DATE_FORMAT_YYYYMMDD);
      const shouldCompare = get(data, 'shouldCompare', false);

      let payload = {
        dateFrom: formattedDateFrom,
        dateTo: formattedDateTo,
        asUser: get(stats, 'asUser', ''),
      };

      if (shouldCompare) {
        const dateFromCompare = get(data, 'dateFromCompare', moment());
        const dateToCompare = get(data, 'dateToCompare', moment());
        const formattedDateFromCompare = moment(dateFromCompare).format(DATE_FORMAT_YYYYMMDD);
        const formattedDateToCompare = moment(dateToCompare).format(DATE_FORMAT_YYYYMMDD);

        payload = {
          ...payload,
          dateFromCompare: formattedDateFromCompare,
          dateToCompare: formattedDateToCompare,
        };

        dispatch(
          getDashboardComparisonGraphDataRequest({
            dateFrom: formattedDateFromCompare,
            dateTo: formattedDateToCompare,
          }),
        );
        setShouldCompareTo(true);
      } else {
        setShouldCompareTo(false);
      }

      dispatch(getDashboardOverviewDataRequest(payload));
      dispatch(
        getDashboardGraphDataRequest({
          dateFrom: formattedDateFrom,
          dateTo: formattedDateTo,
          asUser: payload.asUser,
        }),
      );
    },
    [dispatch, stats],
  );

  const headerProps = {
    form: DASHBOARD_OVERVIEW_DATA_HEADER_FORM,
    initialValues: {
      customDateSelector: CUSTOM_DATE_RANGE_KEY.MONTH_TO_DATE,
      dateFrom: moment()
        .startOf('month')
        .format(DATE_FORMAT_YYYYMMDD),
      dateTo: moment().format(DATE_FORMAT_YYYYMMDD),
      shouldCompare: false,
      compareDateSelector: CUSTOM_DATE_RANGE_KEY.MONTH_TO_DATE,
      dateFromCompare: moment()
        .startOf('month')
        .subtract(1, 'month')
        .format(DATE_FORMAT_YYYYMMDD),
      dateToCompare: moment()
        .subtract(1, 'month')
        .format(DATE_FORMAT_YYYYMMDD),
    },
    leadOwners,
    onSubmit: handleHeaderFormSubmit,
  };

  const handleStatsCustomizationFormSubmit = useCallback(
    (data) => {
      dispatch(saveDashboardStatsCustomizationDataRequest({ metrics: data }));
    },
    [dispatch],
  );

  const statsCustomizationProps = {
    form: DASHBOARD_STATS_CUSTOMIZATION_FORM,
    initialValues: stats,
    onSubmit: handleStatsCustomizationFormSubmit,
    isGlobalAdmin: isGlobalAdmin,
    leadOwners,
  };

  return (
    <OverviewDataView
      headerProps={headerProps}
      statsCustomizationProps={statsCustomizationProps}
      overviewData={overviewData}
      isOverviewDataLoading={isOverviewDataLoading}
      isGraphDataLoading={isGraphDataLoading}
      graphDatasets={graphDatasets}
      shouldCompareTo={shouldCompareTo}
      comparisonGraphDatasets={comparisonGraphDatasets}
      isComparisonGraphDataLoading={isComparisonGraphDataLoading}
    />
  );
};

export default OverviewDataContainer;
