import { colors } from '../colors';

export const palette = {
  primary: {
    dark: colors.brightGreen.dark,
    main: colors.brightGreen.main,
    light: colors.brightGreen.light,
    lighter: colors.brightGreen.lighter,
    lightest: colors.brightGreen.lightest,
    lightest2: colors.brightGreen.lightest2,
  },
  text: {
    dark: colors.blue.dark,
    main: colors.blue.main,
    light: colors.blue.light,
    lighter: colors.blue.lighter,
    lightest: colors.blue.lightest,
  },
  textSecondary: {
    dark: colors.grey1.dark,
    main: colors.grey1.main,
    light: colors.grey1.light,
    lighter: colors.grey1.lighter,
    lightest: colors.grey1.lightest,
  },
  icon: {
    dark: colors.grey2.dark,
    main: colors.grey2.main,
    light: colors.grey2.light,
    lighter: colors.grey2.lighter,
    lightest: colors.grey2.lightest,
  },
  dividerCustom: {
    dark: colors.grey3.dark,
    main: colors.grey3.main,
    light: colors.grey3.light,
    lighter: colors.grey3.lighter,
    lightest: colors.grey3.lightest,
  },
  success: {
    dark: colors.green.dark,
    main: colors.green.main,
    light: colors.green.light,
    lighter: colors.green.lighter,
    lightest: colors.green.lightest,
    contrastText: colors.white,
  },
  error: {
    dark: colors.red.dark,
    main: colors.red.main,
    light: colors.red.light,
    lighter: colors.red.lighter,
    lightest: colors.red.lightest,
    contrastText: colors.white,
  },
  warning: {
    dark: colors.orange.dark,
    main: colors.orange.main,
    light: colors.orange.light,
    lighter: colors.orange.lighter,
    lightest: colors.orange.lightest,
    contrastText: colors.white,
  },
  background: {
    main: colors.white,
  },
};
