import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = {
  marketingData: [],
  sources: [],
};

export default handleActions(
  {
    [actionCreators.getMarketingChannelDataSuccess](state, action) {
      return {
        ...state,
        marketingData: action.response.data.marketing,
      };
    },

    [actionCreators.getMarketingChannelDataFail]() {
      return defaultState;
    },

    [actionCreators.getMarketingChannelSourcesSuccess](state, action) {
      return {
        ...state,
        sources: action.response.data,
      };
    },
  },
  defaultState,
);
