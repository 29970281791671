import createAction from 'redux-actions/es/createAction';

export const forgotPasswordRequest = createAction('FORGOT_PASSWORD_REQUEST');
export const forgotPasswordSuccess = createAction('FORGOT_PASSWORD_SUCCESS');
export const forgotPasswordFail = createAction('FORGOT_PASSWORD_FAIL');

export const redirectToSignIn = createAction('REDIRECT_TO_SIGN_IN');

export const setSelectHubOpen = createAction('SET_SELECT_HUB_OPEN');

export const initializeForm = createAction('FORGOT_PASSWORD_FORM_INITIALIZE');
