const styles = (theme) => ({
  label: {
    color: theme.palette.simpleGrey,
    fontSize: 12,
    fontStyle: 'normal',
    lineHeight: 1.4,
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  dataText: {
    color: theme.palette.textColor,
    fontSize: 14,
    lineHeight: 1.3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: 30,
  },
  item: {
    padding: '20px 10px',
    '@media (min-width:400px)': {
      height: 'auto',
    },
  },
  dataIconContainer: {
    minWidth: 20,
    maxWidth: 20,
    height: 20,
    borderRadius: 50,
    marginRight: 5,
    overflow: 'hidden',
    position: 'relative',
  },
  dataIcon: {
    height: 20,
    transform: 'scale(1)',
    objectPosition: 'center',
    objectFit: 'cover',
    width: 20,
  },
  loader: {
    margin: '6px auto',
  },
});

export default styles;
