export default {
  error_small: {
    marginLeft: '3px',
  },
  error_medium: {
    marginLeft: '4px',
  },
  errorContainer: (theme) => ({
    // Must be the same style as for TPTextField
    minHeight: theme.textError.root.minHeight,
  }),
};
