import { PAYMENT_METHODS } from 'constants/index';

export const DEPOSIT_CARD_FORM = 'DEPOSIT_CARD_FORM';
export const DEPOSIT_BANKWIRE_FORM = 'DEPOSIT_BANKWIRE_FORM';
export const DEPOSIT_SMARTWAY_FORM = 'DEPOSIT_SMARTWAY_FORM';

export const DEPOSIT_STATUSES = {
  APPROVED: 'success',
  SUCCESS: 'success',
  DECLINED: 'declined',
  REJECTED: 'declined',
  PENDING: 'pending',
};

export const BANK_WIRE_PAYMENT_METHODS = [PAYMENT_METHODS.bank, PAYMENT_METHODS.thaiTransfer];

export const CURRENCIES_LOCAL_TRANSFER = [
  { value: 'myr', label: 'MYR' },
  { value: 'vnd', label: 'VND' },
  { value: 'idr', label: 'IDR' },
  { value: 'thb', label: 'THB' },
];

export const CURRENCIES_PAYMENT_ASIA = [{ value: 'cny', label: 'CNY' }];

export const PAYMENT_METHODS_BASE_CURRENCIES = {
  [PAYMENT_METHODS.local]: CURRENCIES_LOCAL_TRANSFER,
  [PAYMENT_METHODS.paymentAsia]: CURRENCIES_PAYMENT_ASIA,
};

export const MIN_DEPOSITS = {
  usd: 10,
  gbp: 10,
  eur: 10,
  aud: 10,
  cad: 15,
  hkd: 100,
  jpy: 1120,
  nzd: 15,
  sgd: 15,
  myr: 15,
  vnd: 15,
  idr: 15,
  thb: 20,
};

export const MAX_DEPOSITS = {
  usd: 34000,
  gbp: 27000,
  eur: 30000,
  aud: 50000,
  cad: 46000,
  hkd: 270000,
  jpy: 3795000,
  nzd: 52000,
  sgd: 47000,
  myr: 10500,
  vnd: 11000,
  idr: 12000,
  thb: 14000,
};

export const DEPOSIT_SEARCH_FORM = 'DEPOSIT_SEARCH_FORM';

export const THAI_BANK_NAMES = [
  {
    label: 'Bank for Agriculture and Agricultural Cooperatives',
    value: 'Bank for Agriculture and Agricultural Cooperatives',
  },
  { label: 'Islamic Bank of Thailand', value: 'Islamic Bank of Thailand' },
  { label: 'Government Saving Bank', value: 'Government Saving Bank' },
  { label: 'TISCO', value: 'TISCO' },
  { label: 'United Overseas Bank, Thailand', value: 'United Overseas Bank, Thailand' },
  { label: 'Thanachart Bank', value: 'Thanachart Bank' },
  { label: 'Siam Commercial Bank', value: 'Siam Commercial Bank' },
  { label: 'Thai Military Bank', value: 'Thai Military Bank' },
  { label: 'Citibank', value: 'Citibank' },
  { label: 'KasikornBank', value: 'KasikornBank' },
  { label: 'Bank of Ayudhaya', value: 'Bank of Ayudhaya' },
  { label: 'Krung Thai Bank', value: 'Krung Thai Bank' },
  { label: 'Bangkok Bank', value: 'Bangkok Bank' },
];

export const DEPOSIT_METHOD_FORM = 'DEPOSIT_METHOD_FORM';

export const PAYMENT_PROCESS_STATUSES = {
  REDIRECTING: 'redirecting',
};

export const MT4_IS_NOT_CREATED_PLACEHOLDER = '0';

export const BANK_TRANSFER_AU = {
  aud: {
    currency: 'AUD',
    bankName: 'National Australia Bank',
    bankCountry: 'Australia',
    bankAddress: '255 George Street, Sydney, NSW, 2000, Australia',
    swift: 'NATAAU3303M',
    bsb: '082-057',
    accountNumber: '26-498-2765',
    accountName: 'Gleneagle Securities (Aust) Pty Limited P9 AUD Trust Retail',
    accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
  },
  cad: {
    currency: 'CAD',
    bankName: 'National Australia Bank',
    bankCountry: 'Australia',
    bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
    swift: 'NATAAU3303M',
    bsb: '082-039',
    accountNumber: 'GSAPLCAD07',
    accountName: 'Gleneagle Securities (Aust) Pty Limited P9 CAD Trust Retail',
    accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
  },
  eur: {
    currency: 'EUR',
    bankName: 'National Australia Bank',
    bankCountry: 'Australia',
    bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
    swift: 'NATAAU3303M',
    bsb: '082-039',
    accountNumber: 'GSAPLEUR07',
    accountName: 'Gleneagle Securities (Aust) Pty Limited P9 EUR Trust Retail',
    accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
  },
  gbp: {
    currency: 'GBP',
    bankName: 'National Australia Bank',
    bankCountry: 'Australia',
    bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
    swift: 'NATAAU3303M',
    bsb: '082-039',
    accountNumber: 'GSAPLGBP07',
    accountName: 'Gleneagle Securities (Aust) Pty Limited P9 GBP Trust Retail',
    accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
  },
  hkd: {
    currency: 'HKD',
    bankName: 'National Australia Bank',
    bankCountry: 'Australia',
    bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
    swift: 'NATAAU3303M',
    bsb: '082-039',
    accountNumber: 'GSAPLHKD06',
    accountName: 'Gleneagle Securities (Aust) Pty Limited P9 HKD Trust Retail',
    accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
  },
  nzd: {
    currency: 'NZD',
    bankName: 'National Australia Bank',
    bankCountry: 'Australia',
    bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
    swift: 'NATAAU3303M',
    bsb: '082-039',
    accountNumber: 'GSAPLNZD05',
    accountName: 'Gleneagle Securities (Aust) Pty Limited P9 NZD Trust Retail',
    accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
  },
  sgd: {
    currency: 'SGD',
    bankName: 'National Australia Bank',
    bankCountry: 'Australia',
    bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
    swift: 'NATAAU3303M',
    bsb: '082-039',
    accountNumber: 'GSAPLSGD06',
    accountName: 'Gleneagle Securities (Aust) Pty Limited P9 SGD Trust Retail',
    accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
  },
  usd: {
    currency: 'USD',
    bankName: 'National Australia Bank',
    bankCountry: 'Australia',
    bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
    swift: 'NATAAU3303M',
    bsb: '082-039',
    accountNumber: 'GSAPLUSD18',
    accountName: 'Gleneagle Securities (Aust) Pty Limited P9 USD Trust Retail',
    accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
  },
  zar: {
    currency: 'ZAR',
    bankName: 'National Australia Bank',
    bankCountry: 'Australia',
    bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
    swift: 'NATAAU3303M',
    bsb: '082-039',
    accountNumber: 'GSAPLZAR02',
    accountName: 'Gleneagle Securities (Aust) Pty Limited P9 ZAR Trust Retail',
    accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
  },
};

export const BANK_TRANSFER = {
  aud: {
    currency: 'AUD',
    bankName: 'National Australia Bank',
    bankCountry: 'Australia',
    bankAddress: '255 George Street, Sydney, NSW, 2000, Australia',
    swift: 'NATAAU3303M',
    bsb: '082-057',
    accountNumber: '26-534-4853',
    accountName: 'Gleneagle Securities (Aust) Pty Limited FX AUD Trust P9',
    accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
  },
  cad: {
    currency: 'CAD',
    bankName: 'National Australia Bank',
    bankCountry: 'Australia',
    bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
    swift: 'NATAAU3303M',
    bsb: '082-039',
    accountNumber: 'GSAPLCAD10',
    accountName: 'Gleneagle Securities (Aust) Pty Limited FX CAD Trust P9',
    accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
  },
  eur: {
    currency: 'EUR',
    bankName: 'National Australia Bank',
    bankCountry: 'Australia',
    bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
    swift: 'NATAAU3303M',
    bsb: '082-039',
    accountNumber: 'GSAPLEUR10',
    accountName: 'Gleneagle Securities (Aust) Pty Limited FX EUR Trust P9',
    accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
  },
  gbp: {
    currency: 'GBP',
    bankName: 'National Australia Bank',
    bankCountry: 'Australia',
    bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
    swift: 'NATAAU3303M',
    bsb: '082-039',
    accountNumber: 'GSAPLGBP10',
    accountName: 'Gleneagle Securities (Aust) Pty Limited FX GBP Trust P9',
    accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
  },
  hkd: {
    currency: 'HKD',
    bankName: 'National Australia Bank',
    bankCountry: 'Australia',
    bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
    swift: 'NATAAU3303M',
    bsb: '082-039',
    accountNumber: 'GSAPLHKD08',
    accountName: 'Gleneagle Securities (Aust) Pty Limited FX HKD Trust P9',
    accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
  },
  jpy: {
    currency: 'JPY',
    bankName: 'National Australia Bank',
    bankCountry: 'Australia',
    bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
    swift: 'NATAAU3303M',
    bsb: '082-039',
    accountNumber: 'GSAPLJPY06',
    accountName: 'Gleneagle Securities (Aust) Pty Limited FX JPY Trust P9',
    accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
  },
  nzd: {
    currency: 'NZD',
    bankName: 'National Australia Bank',
    bankCountry: 'Australia',
    bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
    swift: 'NATAAU3303M',
    bsb: '082-039',
    accountNumber: 'GSAPLNZD08',
    accountName: 'Gleneagle Securities (Aust) Pty Limited FX NZD Trust P9',
    accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
  },
  sgd: {
    currency: 'SGD',
    bankName: 'National Australia Bank',
    bankCountry: 'Australia',
    bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
    swift: 'NATAAU3303M',
    bsb: '082-039',
    accountNumber: 'GSAPLSGD09',
    accountName: 'Gleneagle Securities (Aust) Pty Limited FX SGD Trust P9',
    accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
  },
  usd: {
    currency: 'USD',
    bankName: 'National Australia Bank',
    bankCountry: 'Australia',
    bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
    swift: 'NATAAU3303M',
    bsb: '082-039',
    accountNumber: 'GSAPLUSD21',
    accountName: 'Gleneagle Securities (Aust) Pty Limited FX USD Trust P9',
    accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
  },
};
