export default {
  root: (theme) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    paddingTop: theme.spacing(1),
  }),
  fullWidth: {
    width: '100%',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    '& > * ': {
      flex: '1 0 fit-content',
    },
  },
  noGutters: {
    padding: 0,
    gap: 0,
  },
};
