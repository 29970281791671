import { createPendingSelector } from 'modules/api/selectors';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateMfaRequest } from '../actions';
import MfaInput from '../components/MfaInput';
import { MFA_INPUT_FORM } from '../constants';
import { logout } from 'modules/auth/actions';
import { deleteUserSessionsRequest } from 'modules/profile/pages/ProfileData/actions';

const MfaInputContainer = () => {
  const dispatch = useDispatch();
  const isPending = useSelector(createPendingSelector(validateMfaRequest));

  const handleSubmit = useCallback(
    (data) => {
      dispatch(validateMfaRequest(data));
    },
    [dispatch],
  );

  const handleLogout = useCallback(() => {
    dispatch(deleteUserSessionsRequest());
    dispatch(logout());
  }, [dispatch]);

  return (
    <MfaInput
      form={MFA_INPUT_FORM}
      isPending={isPending}
      onSubmit={handleSubmit}
      handleLogout={handleLogout}
    />
  );
};

export default MfaInputContainer;
