import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as apiSelectors from 'modules/api/selectors';
import ActiveUsersPage from '../views/ActiveUsersView';
import { getActiveUsersGraphDataRequest } from '../actions';
import { getActiveUsersGraphDatasets, getActiveUserGraphData } from '../selectors';
import get from 'lodash/get';
import { DASHBOARD_ACTIVE_USERS_HEADER_FORM } from '../constants';
import { CUSTOM_DATE_RANGE_KEY } from '../constants';
import moment from 'moment';
import { DATE_FORMAT_YYYYMMDD } from '../../../constants';

const DEFAULT_PERIOD = 30;

const ActiveUsersContainer = () => {
  const dispatch = useDispatch();
  const isPending = useSelector(apiSelectors.createPendingSelector(getActiveUsersGraphDataRequest));
  const graphDatasets = useSelector(getActiveUsersGraphDatasets);
  const activeUsersData = useSelector(getActiveUserGraphData);
  const [period, setPeriod] = useState(DEFAULT_PERIOD);

  useEffect(() => {
    const dateFrom = moment()
      .startOf('month')
      .format(DATE_FORMAT_YYYYMMDD);
    const dateTo = moment().format(DATE_FORMAT_YYYYMMDD);
    dispatch(getActiveUsersGraphDataRequest({ dateFrom, dateTo }));
  }, [dispatch]);

  const handleHeaderFormSubmit = useCallback(
    (data) => {
      const dateFrom = get(data, 'dateFrom', moment());
      const dateTo = get(data, 'dateTo', moment());
      const formattedDateFrom = moment(dateFrom).format(DATE_FORMAT_YYYYMMDD);
      const formattedDateTo = moment(dateTo).format(DATE_FORMAT_YYYYMMDD);

      setPeriod(moment(dateTo).diff(moment(dateFrom), 'days'));

      dispatch(
        getActiveUsersGraphDataRequest({ dateFrom: formattedDateFrom, dateTo: formattedDateTo }),
      );
    },
    [dispatch],
  );

  const headerProps = {
    form: DASHBOARD_ACTIVE_USERS_HEADER_FORM,
    initialValues: {
      customDateSelector: CUSTOM_DATE_RANGE_KEY.MONTH_TO_DATE,
      dateFrom: moment()
        .startOf('month')
        .format(DATE_FORMAT_YYYYMMDD),
      dateTo: moment().format(DATE_FORMAT_YYYYMMDD),
    },
    onSubmit: handleHeaderFormSubmit,
  };

  return (
    <ActiveUsersPage
      isPending={isPending}
      data={activeUsersData}
      datasets={graphDatasets}
      headerProps={headerProps}
      period={period}
    />
  );
};

export default ActiveUsersContainer;
