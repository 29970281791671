import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { getUser, targetUserQuizSelector } from '../../../auth/selectors';
import { Routes } from '../../../../constants/routeConstants';
import { ACCOUNT_PLANS } from '../../../../constants/acctountPlans';
import formatDate, { editDate } from '../../../../utils/formatDate';
import { BLOCK_DAYS_AMOUNT } from '../../constants/blockedDaysAmount';
import { DATE_FORMAT_FULL } from '../../../../constants';
import { Trans, useTranslation } from 'react-i18next';
import CustomLink from '../../../common/CustomLink';
import { closeModal } from '../../../common/CustomModal/actions';
import verificationModalNames from '../../constants/verificationModalNames';
import ImageSubtitleTextContent from '../../../common/ContentModal/ImageSubtitleTextContent';
import CustomModal from '../../../common/CustomModal';

import quizFailureImage from '../../../../static/quizzes/quiz-failure.svg';
import styles from './styles';

const QuizBlockedModal = ({ classes }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('quiz');

  const { selectedPlan } = useSelector(getUser);
  const targetQuizProgress = useSelector(targetUserQuizSelector);

  const { blockedDate } = targetQuizProgress || {};

  const endDate = editDate(blockedDate, BLOCK_DAYS_AMOUNT, 'day');
  const formattedEndDate = formatDate(endDate, DATE_FORMAT_FULL);

  const generateTranslatedInfoText = useCallback(
    (selectedPlan) => {
      if (selectedPlan === ACCOUNT_PLANS.PRO) {
        return (
          <Trans i18nKey="quizBlockedModal.pro.description" ns="quiz">
            Sadly you didn&apos;t pass the quiz on the second attempt. If you are still interested
            in becoming a Pro client, please reach out to our support team at{' '}
            <CustomLink path="mailto:support@globalprime.com">support@globalprime.com</CustomLink>{' '}
            and we can be of further assistance.
          </Trans>
        );
      }

      return t(`quizBlockedModal.retail.description`, {
        date: formattedEndDate ?? '',
      });
    },
    [t, formattedEndDate],
  );

  const handleClose = useCallback(() => {
    dispatch(closeModal(verificationModalNames.QUIZ_BLOCKED_MODAL));
    history.push(Routes.MY_ACCOUNTS);
  }, [history, dispatch]);

  const buttons = useMemo(
    () => [
      {
        label: t('common:buttons.gotIt'),
        color: 'primary',
        onClick: handleClose,
      },
    ],
    [t, handleClose],
  );

  return (
    <CustomModal
      modalClassNames={classes.modal}
      className={classes.modalPaper}
      modalName={verificationModalNames.QUIZ_BLOCKED_MODAL}
      title={t(
        `quiz:${
          selectedPlan === ACCOUNT_PLANS.PRO ? ACCOUNT_PLANS.PRO : ACCOUNT_PLANS.RETAIL
        }.page.title`,
      )}
      isCloseButtonNeeded
      customCloseModal={handleClose}
      buttons={buttons}>
      <ImageSubtitleTextContent
        image={quizFailureImage}
        text={generateTranslatedInfoText(selectedPlan || ACCOUNT_PLANS.RETAIL)}
        subtitle={t(`quizBlockedModal.${selectedPlan || ACCOUNT_PLANS.RETAIL}.title`)}
      />
    </CustomModal>
  );
};

export default withStyles(styles)(QuizBlockedModal);
