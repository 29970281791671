import { combineReducers } from 'redux';

import hideCompleteAML from './hideCompleteAML';
import questions from './questions';

const reducer = combineReducers({
  questions,
});

export const persistentReducer = combineReducers({
  hideCompleteAML,
});

export default reducer;
