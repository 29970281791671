import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import omit from 'lodash/omit';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useMatchUrl from 'hooks/navigation/useMatchUrl';
import useChildrenProps from 'hooks/useChildrenProps';
import useDefaultTabPath from '../hooks/useDefaultTabPath';
import useTabsDefaultPaths from '../hooks/useTabsDefaultPaths';
import NavTabs from '../components/NavTabs';
import NavTabsContent from '../components/NavTabsContent';
import { setCurrentPage } from 'modules/common/PaginationBar/actions';

const NavTabsContainer = ({
  TabsComponent = Tabs,
  TabComponent = Tab,
  children,
  tabContentProps,
  ...tabsViewProps
}) => {
  const dispatch = useDispatch();
  const currentBaseUrl = useMatchUrl();
  const tabsProps = useTabsDefaultPaths(useChildrenProps(children));
  const tabsPropsWithoutChildren = tabsProps.map((tabProps) => omit(tabProps, ['children']));
  const defaultTabPath = useDefaultTabPath(tabsProps);

  const onChange = () => {
    dispatch(setCurrentPage(1));
  };

  return (
    <>
      <NavTabs
        currentBaseUrl={currentBaseUrl}
        TabsComponent={TabsComponent}
        TabComponent={TabComponent}
        tabs={tabsPropsWithoutChildren}
        onChange={onChange}
        {...tabsViewProps}
      />
      <NavTabsContent
        currentBaseUrl={currentBaseUrl}
        tabsData={tabsProps}
        defaultPath={defaultTabPath}
        tabContentProps={tabContentProps}
      />
    </>
  );
};

// eslint-disable-line
NavTabsContainer.Tab = () => null; // eslint-disable-line

NavTabsContainer.propTypes = {
  children: PropTypes.node.isRequired,
  TabsComponent: PropTypes.element,
  TabComponent: PropTypes.element,
  onChange: PropTypes.func,
};

export default memo(NavTabsContainer);
