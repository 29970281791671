import { palette } from '../../../components/CustomThemeProvider/themes/palette';

const styles = () => ({
  progressBar: {
    backgroundColor: palette.lightGreen,
    height: 8,
    borderRadius: '4px',
  },
  progressBarColor: {
    background: palette.green2,
    borderRadius: '4px',
  },
});

export default styles;
