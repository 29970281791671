import { palette } from '../../../../../components/CustomThemeProvider/themes/palette';

export const styles = {
  menuDropDown: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    maxWidth: '250px',
    width: '100%',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
  userInfo: {
    padding: '0 16px',
  },
  userName: {
    fontSize: '16px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  userEmail: {
    fontSize: '14px',
    color: palette.simpleGrey,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  menuItem: {
    padding: '5px 16px',
  },
};
