import { createAction } from 'redux-actions';

export const symbolConfigRequest = createAction('SYMBOL_CONFIG_REQUEST');
export const symbolConfigSuccess = createAction('SYMBOL_CONFIG_SUCCESS');
export const symbolConfigFail = createAction('SYMBOL_CONFIG_FAIL');

export const currencyCalculatorRequest = createAction('CURRENCY_CALCULATOR_REQUEST');
export const currencyCalculatorSuccess = createAction('CURRENCY_CALCULATOR_SUCCESS');
export const currencyCalculatorFail = createAction('CURRENCY_CALCULATOR_FAIL');

export const pipValueCalculatorRequest = createAction('PIP_VALUE_CALCULATOR_REQUEST');
export const pipValueCalculatorSuccess = createAction('PIP_VALUE_CALCULATOR_SUCCESS');
export const pipValueCalculatorFail = createAction('PIP_VALUE_CALCULATOR_FAIL');

export const marginCalculatorRequest = createAction('MARGIN_CALCULATOR_REQUEST');
export const marginCalculatorSuccess = createAction('MARGIN_CALCULATOR_SUCCESS');
export const marginCalculatorFail = createAction('MARGIN_CALCULATOR_FAIL');

export const swapsCalculatorRequest = createAction('SWAPS_CALCULATOR_REQUEST');
export const swapsCalculatorSuccess = createAction('SWAPS_CALCULATOR_SUCCESS');
export const swapsCalculatorFail = createAction('SWAPS_CALCULATOR_FAIL');

export const profitLossCalculatorRequest = createAction('PROFIT_LOSS_CALCULATOR_REQUEST');
export const profitLossCalculatorSuccess = createAction('PROFIT_LOSS_CALCULATOR_SUCCESS');
export const profitLossCalculatorFail = createAction('PROFIT_LOSS_CALCULATOR_FAIL');

export const clearResults = createAction('CLEAR_RESULTS');
