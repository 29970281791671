import React from 'react';
import { compose } from 'redux';
import { Form, Field, reduxForm } from 'redux-form';
import { Typography, Grid, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import TextInput from 'modules/common/TextInput';
import PrimaryButton from 'modules/common/PrimaryButton';
import { email, required, checkNonLatin } from 'utils/validation/fieldValidationRules';

import styles from './styles';
import { REQUEST_FUSION_PLUS_FORM } from '../../constants';

const RequestFusionPlusForm = ({ classes, isPending, onCancel, handleSubmit }) => {
  const { t } = useTranslation('myFollowers');

  return (
    <Form onSubmit={handleSubmit}>
      <Typography className={classes.title}>{t('page.requestAccess')}</Typography>
      <Grid>
        <Grid container spacing={8}>
          <Grid item xs={12} className={classes.fieldContainer}>
            <Field
              name="email"
              label={t('common:labels.email')}
              placeholder={t('common:labels.email')}
              type="text"
              component={TextInput}
              className={classes.textField}
              validate={[checkNonLatin, required, email]}
            />
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.buttonsSection}>
        <Button size="large" onClick={onCancel} color="primary" className={classes.cancelButton}>
          CLOSE
        </Button>
        <PrimaryButton
          color="primary"
          size="large"
          label={t('buttons.sendRequest')}
          type="submit"
          isPending={isPending}
        />
      </div>
    </Form>
  );
};

export default compose(
  reduxForm({
    form: REQUEST_FUSION_PLUS_FORM,
  }),
  withStyles(styles),
)(RequestFusionPlusForm);
