import theme from 'components/CustomThemeProvider/themes/normal';

const styles = (theme) => ({
  titleBody: {
    fontWeight: 700,
  },
  paper: {
    padding: '32px 66px',
    [theme.breakpoints.down(480)]: {
      padding: '5px 10px',
    },
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const sxStyles = {
  title: {
    textAlign: 'center',
    color: theme.palette.black,
    fontSize: 26,
    lineHeight: '42px',
    [theme.breakpoints.down(480)]: {
      fontSize: 14,
      lineHeight: '22px',
    },
  },
};

export { styles, sxStyles };
