import { createAction } from 'redux-actions';

export const getReportDataRequest = createAction('GET_REPORT_DATA_REQUEST');
export const getReportDataSuccess = createAction('GET_REPORT_DATA_SUCCESS');
export const getReportDataFail = createAction('GET_REPORT_DATA_FAIL');

export const getInvitationLinkRequest = createAction('GET_INVITATION_LINK_REQUEST');
export const getInvitationLinkSuccess = createAction('GET_INVITATION_LINK_SUCCESS');
export const getInvitationLinkFail = createAction('GET_INVITATION_LINK_FAIL');

export const createInvitationLinkRequest = createAction('CREATE_INVITATION_LINK_REQUEST');
export const createInvitationLinkSuccess = createAction('CREATE_INVITATION_LINK_SUCCESS');
export const createInvitationLinkFail = createAction('CREATE_INVITATION_LINK_FAIL');
