export default {
  control: (theme) => ({
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
  }),
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1,
  },
  flagIcon: (theme) => ({
    width: 36,
    height: 18,
    marginRight: theme.spacing(1),
  }),
};
