import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'material-latest';

import { COLOR_TYPES, inherit, variantMapping } from './config';
import TPTooltip from 'components/TP-UI/TPTooltip';
import { MAIN_COLORS } from 'components/TP-UI/constants';

import styles from './styles';

const TPTypography = ({
  variant = 'body1',
  color = MAIN_COLORS.PRIMARY,
  noWrap = false,
  truncated = false,
  lineClamp = 2,
  component,
  className,
  bold,
  semiBold,
  children,
  ...props
}) => {
  const ref = useRef();
  const [ellipsed, setEllipsed] = useState(false);
  const colorType = COLOR_TYPES[color];
  const variantType = variant === inherit ? null : variant;

  useEffect(() => {
    const el = ref.current;
    let observer;

    if (truncated && el) {
      if (window.ResizeObserver) {
        observer = new ResizeObserver((entries) => {
          const entry = entries[0]?.target;
          setEllipsed(entry.offsetHeight < entry.scrollHeight);
        });

        observer.observe(el);
      }
    }

    return () => {
      if (observer) {
        // Cleanup the observer by unobserving all elements
        observer.disconnect();
      }
    };
  }, [ref, truncated]);

  let Tag = React.Fragment;
  let wrapperProps = {};
  if (truncated) {
    Tag = TPTooltip;
    wrapperProps = { content: children, maxWidth: '380px', disabled: !ellipsed };
  }

  return (
    <Tag {...wrapperProps}>
      <Typography
        ref={ref}
        variant={variantType}
        color={colorType}
        noWrap={noWrap}
        component={component || variantMapping[variant]}
        sx={[
          ...(Array.isArray(className) ? className : [className]),
          variant === inherit && styles.inherit,
          truncated && styles.truncated,
          semiBold && styles.semiBold,
          bold && styles.bold,
        ]}
        style={
          truncated
            ? {
                WebkitLineClamp: lineClamp,
              }
            : null
        }
        {...props}>
        {children}
      </Typography>
    </Tag>
  );
};

TPTypography.propTypes = {
  variant: PropTypes.oneOf([...Object.keys(variantMapping), inherit]),
  color: PropTypes.oneOf(Object.keys(COLOR_TYPES)),
  noWrap: PropTypes.bool,
  truncated: PropTypes.bool,
  lineClamp: PropTypes.number,
  component: PropTypes.elementType,
  className: PropTypes.string,
  bold: PropTypes.bool,
  semiBold: PropTypes.bool,
  children: PropTypes.node,
};

export default TPTypography;
