export default (theme) => ({
  formControlLabelRoot: {
    marginLeft: '-11px',
    alignItems: 'flex-start',
    '&:not(:last-child)': {
      marginBottom: 12,
    },
  },
  formControlLabel: {
    fontSize: 16,
    color: theme.palette.text.main,
    fontWeight: 400,
  },
  formControlLabelDisabled: {
    color: `${theme.palette.simpleGrey} !important`,
  },
  icon: {
    fontSize: 24,
  },
  radioButton: {
    padding: '0 10px',
  },
  radioChecked: {
    color: theme.palette.green2,
  },
});
