import React from 'react';
import { Grid } from 'material-latest';
import { Field, reduxForm } from 'redux-form';

import TPCheckbox from 'components/TP-UI/TPCheckbox';
import TPCheckboxGroup from 'components/TP-UI/TPCheckboxGroup';
import TPTypography from 'components/TP-UI/TPTypography';
import { required, requiredArray } from 'utils/validation/fieldValidationRules';

const OPTIONS = [
  { value: 'Value1', label: 'Value 1' },
  { value: 'Value2', label: 'Value 2' },
  { value: 'Value3', label: 'Value 3' },
  { value: 'Value4', label: 'Value 5' },
];

const Checkboxes = () => {
  const signUpText = (
    <>
      I confirm I am over 18 years and accept the
      {/*<TPLink target="_blank" rel="noopener noreferrer" path="">*/}
      {/*  Terms and Conditions*/}
      {/*</TPLink>*/}
    </>
  );

  return (
    <form>
      <Grid container>
        <Grid item xs={3}>
          <Field label="Option large" component={TPCheckbox} name="checkbox4" />
          <Field label="Option large" component={TPCheckbox} name="checkbox5" disabled />
          <Field
            label="Option large"
            component={TPCheckbox}
            name="checkbox6"
            validate={[required]}
            meta={{ error: { key: 'common:errors.form.isRequired' }, submitFailed: true }}
          />
        </Grid>
        <Grid item xs={3}>
          <Field name="checkbox1" label="Option small" component={TPCheckbox} size="small" />
          <Field
            name="checkbox2"
            label="Option small"
            disabled
            component={TPCheckbox}
            size="small"
          />
          <Field
            name="checkbox3"
            label="Option small"
            component={TPCheckbox}
            size="small"
            meta={{ error: { key: 'common:errors.form.isRequired' }, submitFailed: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <Field label={signUpText} disabled component={TPCheckbox} name="checkbox7" />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Field
              legend="Group of checkboxes"
              name="categories"
              component={TPCheckboxGroup}
              options={OPTIONS}
              validate={[requiredArray]}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              legend="Group of checkboxes, error"
              name="categories"
              component={TPCheckboxGroup}
              options={OPTIONS}
              validate={[requiredArray]}
              meta={{ error: { key: 'common:errors.form.isRequired' }, submitFailed: true }}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              legend="Group of checkboxes"
              name="categories"
              component={TPCheckboxGroup}
              options={OPTIONS}
              validate={[requiredArray]}
              size="small"
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              legend="Group of checkboxes, error"
              name="categories"
              component={TPCheckboxGroup}
              options={OPTIONS}
              validate={[requiredArray]}
              meta={{ error: { key: 'common:errors.form.isRequired' }, submitFailed: true }}
              size="small"
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default reduxForm({
  form: 'TP-CHECKBOX-FORM',
  initialValues: {
    checkbox2: true,
    checkbox7: true,
    categories: [],
  },
})(Checkboxes);
