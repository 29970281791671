import { handleActions } from 'redux-actions';
import get from 'lodash/get';

import { getReportDataSuccess, getReportDataRequest } from '../actions';

const defaultState = {
  report: {},
  isLoading: true,
};

export default handleActions(
  {
    [getReportDataRequest]: (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [getReportDataSuccess]: (state, action) => {
      return {
        report: get(action, 'response.data', {}),
        isLoading: false,
      };
    },
  },
  defaultState,
);
