import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TableList from '../components/TableList';

const TableListContainer = ({
  tableFields,
  data,
  minHeight,
  generateTableItemId,
  maxHeight,
  ExpandedRowContent,
  onClick,
  isVerificationHistoryTable,
}) => {
  const [expendedRowId, setExpandedRowId] = useState('');

  const onExpand = (eventId) => {
    setExpandedRowId(eventId);
  };

  const props = {
    tableFields,
    data,
    minHeight,
    maxHeight,
    generateTableItemId,
    ExpandedRowContent,
    onExpand,
    expendedRowId,
    onClick,
    isVerificationHistoryTable,
  };
  return <TableList {...props} />;
};

TableListContainer.propTypes = {
  tableFields: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  generateTableItemId: PropTypes.func,
  onClick: PropTypes.func,
};

export default TableListContainer;
