import { put, takeEvery } from 'redux-saga/effects';

import * as actionCreators from '../actions';

function* showInfo() {
  yield put(actionCreators.getLpoaRequest());
}

function* index() {
  yield takeEvery([actionCreators.signLpoaSuccess, actionCreators.revokeLpoaSuccess], showInfo);
}

export default index;
