import api from 'modules/api/index';

export const getDashboardStatsCustomizationDataRequest = () => {
  return api.get(`/internal-dashboard/settings`);
};

export const saveDashboardStatsCustomizationDataRequest = (payload) => {
  return api.post(`/internal-dashboard/settings`, payload);
};

export const getDashboardOverviewDataRequest = ({
  dateFrom,
  dateTo,
  dateFromCompare,
  dateToCompare,
  asUser,
}) => {
  return api.get(`/internal-dashboard/metrics`, {
    params: {
      from: dateFrom,
      to: dateTo,
      fromCompare: dateFromCompare,
      toCompare: dateToCompare,
      asUser: asUser,
    },
  });
};

export const getTopUserVolumesRequest = (payload) => {
  return api.get(`/internal-dashboard/top-volumes`, { params: payload });
};

export const getDashboardGraphDataRequest = ({ dateFrom, dateTo, asUser }) => {
  return api.get(`/internal-dashboard/metrics/group`, {
    params: { from: dateFrom, to: dateTo, asUser: asUser },
  });
};

export const getDashboardComparisonGraphDataRequest = ({ dateFrom, dateTo, asUser }) => {
  return api.get(`/internal-dashboard/metrics/group`, {
    params: { from: dateFrom, to: dateTo, asUser: asUser },
  });
};

export const getActiveUsersGraphDataRequest = ({ dateFrom, dateTo, asUser }) => {
  return api.get(`/internal-dashboard/metrics/group`, {
    params: { from: dateFrom, to: dateTo, asUser: asUser },
  });
};
