import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'material-latest/Avatar';

import styles from './styles';

const TPCardAvatar = ({ primary, className, children, size, ...props }) => {
  return (
    <Avatar
      sx={[styles.root, primary && styles.primary, className]}
      style={{ height: size, width: size }}
      {...props}>
      {children}
    </Avatar>
  );
};

TPCardAvatar.propTypes = {
  primary: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.string,
};

export default TPCardAvatar;
