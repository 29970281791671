export default (theme) => ({
  formControlLabel: {
    alignItems: 'flex-start',
  },
  checkbox: {
    padding: '2px 12px',
  },
  label: {
    color: theme.palette.text.main,
    fontSize: 16,
    fontWeight: '300',
    lineHeight: '20px',
  },
  disabled: {},
  checkboxChecked: {
    color: theme.palette.green2,
  },
  uncheckedIcon: {
    fontSize: 19,
  },
  checkedIcon: {
    fontSize: 14.5,
    backgroundColor: theme.palette.green2,
    color: theme.palette.textColor,
  },
  checkedIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 19,
    height: 19,
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: '12px',
    margin: '5px 0',
  },
  formError: {
    ...theme.textError,
    position: 'relative',
    width: '100%',
  },
  errorIcon: {
    fontSize: 12,
  },
});
