import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Typography } from '@material-ui/core';

const TableListOfLinksField = ({ classes, label, dataLabel, tableItem, className }) => {
  return (
    <div className={classNames([classes.item, className])}>
      <Typography className={classes.label}>{label}</Typography>
      {
        <Typography className={classes.dataText}>
          {get(tableItem, dataLabel).map((data) => (
            <a href={data.url} className={classes.link} key={data.url}>
              {data.originalName}
              <br />
            </a>
          ))}
        </Typography>
      }
    </div>
  );
};

TableListOfLinksField.propTypes = {
  label: PropTypes.string.isRequired,
  dataLabel: PropTypes.string.isRequired,
  tableItem: PropTypes.object.isRequired,
  formatFunc: PropTypes.func,
  className: PropTypes.string,
  route: PropTypes.string,
};

export default withStyles(styles)(TableListOfLinksField);
