import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '../../Tooltip/components/Tooltip';

export const AML_QUIZ_MODAL = 'AML_QUIZ_MODAL';
export const AML_SUCCESS_MODAL = 'AML_SUCCESS_MODAL';
export const AML_COMPLETE_MODAL = 'AML_COMPLETE_MODAL';

export const AML_QUIZ_FORM = 'AML_QUIZ_FORM';

export const EMAIL_QUIZ_QUERY = 'showAML';

export const AML_QUIZ_MODAL_TITLE = 'Questionnaire';
export const AML_QUIZ_MODAL_TITLE_END_ADORNMENT = (
  <Tooltip
    placement="top-center"
    isLink={false}
    label={<HelpOutlineIcon fontSize={'small'} />}
    value={
      'Why do we ask this? Understanding the origin of funds helps prevent money laundering and other financial crimes. This information is necessary for compliance with regulatory requirements.'
    }
  />
);
