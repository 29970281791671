import { handleActions } from 'redux-actions';

import { hideCompleteAML, clearCompleteAML } from '../actions';

const defaultState = false;

export default handleActions(
  {
    [hideCompleteAML]: () => {
      return true;
    },
    [clearCompleteAML]: () => {
      return false;
    },
  },
  defaultState,
);
