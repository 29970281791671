import { palette } from '../palette';
import { fontSize, fontWeight } from '../../font';

const OverridesMuiTab = {
  styleOverrides: {
    root: {
      flexDirection: 'row',
      alignItems: 'center',
      color: palette.text.dark,
      border: `1px solid ${palette.primary.dark}`,
      backgroundColor: 'transparent',
      padding: '7px 21px',
      minWidth: '120px',
      fontSize: fontSize._18,
      fontWeight: fontWeight.regular,
      textTransform: 'capitalize',
      '@media (min-width: 960px)': {
        minWidth: '120px',
      },
      '@media (max-width: 480px)': {
        minWidth: '80px',
      },
      '&:hover': {
        borderColor: palette.primary.dark,
      },
      '&.Mui-selected': {
        backgroundColor: palette.primary.lightest,
        borderColor: palette.primary.lightest,
        color: palette.text.dark,
        fontWeight: fontWeight.semiBold,
        '&:hover': {
          borderColor: palette.primary.lightest,
        },
      },
    },
    textColorInherit: {
      color: palette.text,
      opacity: 1,
    },
  },
};

export default OverridesMuiTab;
