import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import styles from './styles';

const TIMEOUT = 3000;

const ErrorPageContent = ({ classes }) => {
  const { t } = useTranslation('errorPage');

  useEffect(() => {
    setTimeout(() => window.location.reload(true), TIMEOUT);
  }, []);

  return (
    <section className={classes.section}>
      <div className={classes.contentContainer}>
        <div>
          <Typography className={classes.title}> {t('updatingCache')}</Typography>
        </div>
      </div>
    </section>
  );
};

export default withStyles(styles)(ErrorPageContent);
