export const TRIGGER = {
  HOVER: 'hover',
  CLICK: 'click',
};

export const PLACEMENT = {
  TOP_START: 'top-start',
  TOP: 'top',
  TOP_END: 'top-end',
  LEFT_START: 'left-start',
  LEFT: 'left',
  LEFT_END: 'left-end',
  RIGHT_START: 'right-start',
  RIGHT: 'right',
  RIGHT_END: 'right-end',
  BOTTOM_START: 'bottom-start',
  BOTTOM: 'bottom',
  BOTTOM_END: 'bottom-end',
  AUTO_START: 'auto-start',
  AUTO: 'auto',
  AUTO_END: 'auto-end',
};

export const SIZES = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
  XSMALL: 'xsmall',
  XLARGE: 'extraLarge',
};

export const MAIN_COLORS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  INHERIT: 'inherit',
};
