import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'material-latest';
import Lightbox from 'lightbox-react';

import TPTypography from 'components/TP-UI/TPTypography';
import TPLink from 'components/TP-UI/TPLink';
import { MAIN_COLORS } from 'components/TP-UI/constants';
import { getImageUrl, isCSV, isPDF } from '../helpers/file';
import downloadFile from 'utils/downloadFile';

import 'lightbox-react/style.css';
import styles from './styles';

const TPFilesPreviewModal = ({ value = 0, items = [], onClose, loadErrorMessage }) => {
  const [currentIndex, setCurrentIndex] = useState(value);
  const itemsLength = items.length;
  const currentItem = items[currentIndex];
  const imageUrl = useMemo(() => getImageUrl(currentItem?.url, currentItem.file), [currentItem]);
  useEffect(() => {
    setCurrentIndex(value);
  }, [value]);

  const handleDownloadFile = useCallback(
    async (e) => {
      e.preventDefault();
      if (imageUrl) {
        const response = await fetch(imageUrl);
        const data = await response.blob();
        downloadFile(
          data,
          currentItem?.originalName || currentItem?.name || currentItem?.file?.name,
        );
      }
    },
    [currentItem, imageUrl],
  );

  const handlePrevClick = useCallback(() => {
    if (imageUrl && typeof imageUrl === 'object') {
      // Revoke the data uris to avoid memory leaks
      URL.revokeObjectURL(imageUrl);
    }
    setCurrentIndex((val) => (val + itemsLength - 1) % itemsLength);
  }, [imageUrl, itemsLength]);

  const handleNextClick = useCallback(() => {
    if (imageUrl && typeof imageUrl === 'object') {
      // Revoke the data uris to avoid memory leaks
      URL.revokeObjectURL(imageUrl);
    }
    setCurrentIndex((val) => (val + 1) % itemsLength);
  }, [imageUrl, itemsLength]);

  const getSrc = useCallback(
    (item, itemImageUrl) =>
      isPDF(item?.url || item?.file?.path || '') ? (
        <Box sx={styles.pdfPreview}>
          <object
            width="100%"
            height="100%"
            type="application/pdf"
            data={currentItem?.url || URL.createObjectURL(currentItem?.file)}>
            <TPTypography color={MAIN_COLORS.SECONDARY} className={styles.warningMessage}>
              Unable to display PDF file.
              {currentItem?.url ? (
                <>
                  Please download the PDF to view it:{' '}
                  <TPLink target="_blank" path={currentItem.url}>
                    Download PDF
                  </TPLink>
                </>
              ) : null}
            </TPTypography>
          </object>
        </Box>
      ) : isCSV(item?.url || item?.file?.path) ? (
        <TPTypography color={MAIN_COLORS.SECONDARY} className={styles.warningMessage}>
          Unable to display CSV file.
          {item?.url ? (
            <>
              Please download the CSV to view it:{' '}
              <TPLink target="_blank" path={item.url}>
                Download CSV
              </TPLink>
            </>
          ) : null}
        </TPTypography>
      ) : (
        itemImageUrl || (
          <TPTypography color={MAIN_COLORS.SECONDARY} className={styles.warningMessage}>
            Unable to display file.
          </TPTypography>
        )
      ),
    [],
  );
  const prev = items[(currentIndex + itemsLength - 1) % itemsLength];
  const next = items[(currentIndex + 1) % itemsLength];

  const mainSrc = getSrc(currentItem, imageUrl);
  const prevSrc = getSrc(prev, getImageUrl(prev?.url, prev?.file));
  const nextSrc = getSrc(prev, getImageUrl(next?.url, next?.file));

  const styles = { overlay: { zIndex: 1900 } };

  return currentIndex >= 0 ? (
    <Lightbox
      imageCaption={currentItem?.originalName || currentItem?.file?.name || ''}
      mainSrc={mainSrc}
      enableZoom={!!imageUrl}
      prevSrc={prevSrc}
      nextSrc={nextSrc}
      reactModalStyle={styles}
      onCloseRequest={onClose}
      onMovePrevRequest={handlePrevClick}
      onMoveNextRequest={handleNextClick}
      imageLoadErrorMessage={
        <TPTypography color={MAIN_COLORS.SECONDARY}>
          <>
            <div>{loadErrorMessage || 'Oops! Something went wrong!'}</div>
            <div>
              Please download the image to view it:{' '}
              <TPLink target="_blank" path={next?.url} onClick={handleDownloadFile}>
                Download
              </TPLink>
            </div>
          </>
        </TPTypography>
      }
    />
  ) : null;
};

TPFilesPreviewModal.propTypes = {
  value: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  loadErrorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default TPFilesPreviewModal;
