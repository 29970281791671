import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React from 'react';
import Tooltip from '../../common/Tooltip/components/Tooltip';

export const PRE_QUIZ_MODAL = 'PRE_QUIZ_MODAL';

export const PRO_QUIZ_FORM = 'PRO_QUIZ_FORM';
export const PRO_QUIZ_MODAL = 'PRO_QUIZ_MODAL';
export const RETAIL_QUIZ_FORM = 'RETAIL_QUIZ_FORM';
export const RETAIL_QUIZ_MODAL = 'RETAIL_QUIZ_MODAL';

export const RETAIL_PRO_QUIZ_MODAL_TITLE_END_ADORNMENT = (
  <Tooltip
    placement="top-center"
    isLink={false}
    label={<HelpOutlineIcon fontSize={'small'} />}
    value={
      'Due to Australian regulatory guide 227, we are required to test whether these products are appropriate for you. Therefore, we must ask these before successfully opening your account.'
    }
  />
);
