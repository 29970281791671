const styles = () => ({
  wordBreakAll: {
    wordBreak: 'break-all',
  },
  copyButton: {
    backgroundColor: 'unset',
    marginLeft: 10,
  },
});
export default styles;
