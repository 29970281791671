import api from '..';

export const getMyFollowersRequest = () => {
  return api.get('/followers');
};

export const createOwnCopierRequest = (payload) => {
  return api.post('/followers', payload);
};

export const getMyFollowersAccountsRequest = () => {
  return api.get('/followers/account');
};

export const getMyFollowersAccountRequest = ({ mt4login }) => {
  return api.get(`/followers/account/${mt4login}`);
};

export const sendReloadTsAccountRequest = ({ mt4login, password }) => {
  return api.post(`/followers/account/${mt4login}/connect`, { password });
};

export const createFollowerAccountRequest = (payload) => {
  return api.post('/followers/account', payload);
};

export const getMyFollowersMyInvitationsRequest = () => {
  return api.get('/followers/my-invites');
};

export const updateFollowerAccountRequest = ({ mt4login, data }) => {
  return api.put(`/followers/account/${mt4login}`, data);
};

export const addCopierByInviteRequest = (payload) => {
  const { inviteFollowerId } = payload;
  delete payload.inviteFollowerId;
  return api.post(`/followers/copier/join/${inviteFollowerId}`, payload);
};

export const joinAddCopierByInviteRequest = addCopierByInviteRequest;

export const addCopierByEmailRequest = (payload) => {
  return api.post(`/followers/copier/email`, payload);
};

export const createFollowerInvitationLinkRequest = (payload) => {
  return api.post(`/followers/copier/link`, payload);
};

export const getMyFollowersJoinIdRequest = ({ id }) => {
  return api.get(`/followers/copier/join/${id}`);
};

export const deleteCopierRequest = (data) => {
  return api.delete(`/followers/copier/join/${data._id}`);
};

export const editCopierRequest = (data) => {
  const { _id } = data;
  delete data._id;
  return api.put(`/followers/copier/join/${_id}`, data);
};

export const editCopierSettingsRequest = (data) => {
  const { _id } = data;
  delete data._id;
  return api.put(`/followers/copier/join/${_id}/settings`, data);
};

export const getCopierAccountSettingsRequest = ({ mt4login }) => {
  return api.get(`/followers/settings/${mt4login}`);
};

export const updateCopierAccountSettingsRequest = ({ mt4login, data }) => {
  return api.put(`/followers/settings/${mt4login}`, data);
};

export const getFollowerInvitesRequest = () => {
  return api.get(`/followers/invites`);
};

export const deleteInviteRequest = (invite) => {
  return api.delete(`/followers/invites/${invite._id}`);
};

export const editInviteRequest = (invite) => {
  return api.put(`/followers/invites/${invite._id}`, invite);
};

export const myFollowersTurnOffInvitationRequest = (invite) => {
  return api.post(`/followers/invites/${invite._id}/turn-off`);
};

export const editInviteNotificationsRequest = (invite) => {
  return api.post(`/followers/invites/${invite._id}/notifications`, {
    emailNotifications: invite.emailNotifications,
  });
};

export const myFollowersRejectInvitationRequest = (invite) => {
  return api.post(`/followers/invites/${invite}/reject`);
};

export const myFollowersTradesOpenedRequest = () => {
  return api.get(`/followers/trades/opened`);
};

export const myFollowersTradesErrorRequest = () => {
  return api.get(`/followers/trades/error`);
};

export const myFollowersTradesClosedRequest = ({ skip = 0, limit = 50 }) => {
  return api.get(`/followers/trades/closed?skip=${skip}&limit=${limit}`);
};

export const myFollowersJoinWaitlistCopyRequest = (payload) => {
  return api.post(`/followers/waitlist/copy`, payload);
};

export const myFollowersJoinWaitlistRequest = (payload) => {
  return api.post(`/followers/waitlist/join`, payload);
};

export const myFollowersGetWaitlistRequest = () => {
  return api.get(`/followers/waitlist`);
};
