import { Box, Stack, Typography } from 'material-latest';
import MaintenanceIcon from 'static/maintenance.svg';

const MaintenancePage = () => {
  return (
    <Box height="100vh" maxWidth="720px" marginX="auto" textAlign="center" px={4}>
      <Stack spacing={2} justifyContent="center" height="100%" fontSize="16px">
        <Box maxWidth="400px" marginX="auto">
          <img src={MaintenanceIcon} width="100%" height="100%" />
        </Box>
        <Typography as="h1" fontSize="20px" fontWeight={600}>
          Under Maintenance
        </Typography>
        <Typography>
          It looks like our Client Portal is getting a little TLC!
          <br />
          Our tune-up will last from <b>21:00 UTC (31/03/23) to 21:00 UTC (02/04/23).</b>
        </Typography>
        <Typography>
          We&apos;ll be back up and running before you know it.
          <br />
          In the meantime, take a break, stretch your legs, or watch some cat videos, we won&apos;t
          judge.
        </Typography>
      </Stack>
    </Box>
  );
};

export default MaintenancePage;
