import {
  SIDE_MENU_WIDTH_SMALL,
  SIDE_MENU_WIDTH_LARGE,
  SIDE_MENU_WIDTH_COLLAPSED,
} from 'modules/menu/constants';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  notifications: {
    left: '30%',
    '@media (max-width:1024px)': {
      left: '0',
    },
  },
  container: {
    display: 'flex',
    '@media (max-width:1024px)': {
      flexDirection: 'column',
    },
  },
  publicContainer: {
    justifyContent: 'center',
  },
  drawerHidden: {
    display: 'none',
  },
  headerMobile: {
    display: 'none',
    '@media (max-width:1024px)': {
      display: 'block',
      width: '100%',
    },
  },
  headerWithWarning: {
    marginBottom: 40,
    '@media (max-width:680px)': {
      marginBottom: 60,
    },
    '@media (max-width:540px)': {
      marginBottom: 90,
    },
    '@media (max-width:400px)': {
      marginBottom: 110,
    },
    '@media (max-width:360px)': {
      marginBottom: 130,
    },
  },
  headerDesktop: {
    display: 'block',
    width: '100%',
  },
  mainComponent: {
    width: `calc(100vw - ${SIDE_MENU_WIDTH_LARGE}px)`,
    '@media (max-width:1440px)': {
      width: `calc(100vw - ${SIDE_MENU_WIDTH_SMALL}px)`,
    },
    '@media (max-width:1024px)': {
      width: '100%',
      marginLeft: 0,
    },
    backgroundColor: theme.mainLayout.backgroundColor,
    minHeight: '100vh',
    boxSizing: 'border-box',
  },
  sideMenuCollapsed: {
    width: `calc(100vw - ${SIDE_MENU_WIDTH_COLLAPSED}px)`,
  },
  mainComponentMobile: {
    paddingBottom: 80,
  },
  appBar: {
    overflow: 'hidden',
  },
  mainLayoutContent: {
    padding: 25,
    [theme.breakpoints.down('xs')]: {
      padding: 16,
    },
  },
  publicContent: {
    marginLeft: 0,
    paddingTop: 0,
    backgroundColor: 'transparent',
    padding: 0,
    boxShadow: '0px 0px 20px -20px rgba(34, 60, 80, 0.2)',
  },
});

export default styles;
