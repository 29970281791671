import { createMuiTheme } from '@material-ui/core/styles';
import { common } from './common';
import { palette } from './palette';

// Dark theme
const theme = createMuiTheme({
  themeName: 'dark',

  // palette
  palette: {
    type: 'dark',
    text: {
      main: '#F2F1F1',
      light: '#9EA1B9',
      dark: '#E5E9FE',
    },
    inputLabel: {
      main: '#F2F1F1',
    },
    note: {
      main: '#9EA1B9',
    },
    picker: {
      color: '#46ADAE',
    },
    ...palette,
  },

  // common styles
  ...common,

  // styles
  mainLayout: {
    backgroundColor: '#091945',
  },
  contentLayout: {
    backgroundColor: palette.spaceCadet,
    accentBackgroundColor: '#111D43',
    color: '#FFFFFF',
    borderColor: palette.spaceCadet,
    boxShadow: '-5px 5px 18px 0 rgba(17,29,67,0.8)',
  },

  // components
  input: {
    color: '#F2F1F1',
  },
  radioInput: {
    color: '#F2F1F1',
  },
  imageArray: {
    backgroundColor: '#111D43',
    border: 'none',
  },
  picker: {
    backgroundColor: '#111D43',
    hoverBackgroundColor: '#01082B',
  },
  signIn: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #EFFAD0',
  },
  scrollOnTopButton: {
    backgroundColor: palette.spaceCadet,
    border: `1px solid ${palette.spaceCadet}`,
    backgroundColorHover: '#111D43',
    backgroundColorActive: '#111D43',
  },
  tooltip: {
    backgroundColor: '#091943',
    color: '#F2F1F1',
  },
  clientTable: {
    header: {
      backgroundColor: palette.spaceCadet,
      border: `1px solid ${palette.spaceCadet}`,
    },
    button: {
      backgroundColor: palette.spaceCadet,
    },
    separator: '1px solid #091945',
    backgroundColor: palette.spaceCadet,
    border: 'none',
    labelColor: '#F2F1F1',
  },
  searchInput: {
    backgroundColor: palette.spaceCadet,
  },
  tableAmountField: {
    backgroundColor: '#111D43',
    labelColor: '#F2F1F1',
    color: '#F2F1F1',
  },
  tableTextField: {
    labelColor: '#F2F1F1',
  },
  tableAmountReuseField: {
    backgroundColor: '#111D43',
    labelColor: '#F2F1F1',
    color: '#F2F1F1',
  },
  tableDeleteField: {
    backgroundColor: '#111D43',
  },
  account: {
    backgroundColor: palette.spaceCadet,
    backgroundColorFooter: '#111D43',
    border: 'none',
  },
  myPerformance: {
    backgroundColor: palette.spaceCadet,
    backgroundColorFooter: '#111D43',
    border: 'none',
    boxShadow: '0 5px 11px 0 rgba(8,25,63,0.8)',
  },
  separator: {
    color: '#1E3374',
  },
  loader: {
    backgroundColor: 'rgba(9, 25, 69, 0.3)',
  },
  alphabeticalPicker: {
    backgroundColor: palette.spaceCadet,
    borderWidth: '1px !important',
    borderColor: '#DADFEC !important',
    option: {
      selected: {
        backgroundColor: '#111D43 !important',
      },
    },
  },
  article: {
    sourceColor: '#92AADC',
    tag: {
      backgroundColor: '#111D43',
    },
  },
  header: {
    backgroundColor: '#091945',
    warning: {
      backgroundColor: palette.spaceCadet,
      boxShadow: '-5px 5px 18px 0 rgba(17,29,67,0.8)',
    },
  },
  inviteFriendForm: {
    radioGroupItem: {
      border: '1px solid #08193F',
      boxShadow: '-5px 5px 18px 0 rgba(8,25,63,0.8)',

      checked: {
        border: '1px solid #92AADC',
      },
    },
  },

  //views
  searchPage: {
    recentEntity: {
      backgroundColor: palette.spaceCadet,
      boxShadow: '-5px 5px 18px 0 rgba(17,29,67,0.8)',
    },
    recentEntityBar: {
      positive: {
        backgroundColor: '#7DC4B5',
      },
      neutral: {
        backgroundColor: '#888EB4',
      },
    },
    recentEntitySentiment: {
      backgroundColor: '#111D43',
      boxShadow: '-5px 5px 18px 0 rgba(21,36,83,0.8)',
    },
  },
  analystViewsPage: {
    analystPreview: {
      border: `1px solid ${palette.spaceCadet}`,
      backgroundColor: palette.spaceCadet,
      boxShadow: '-5px 5px 18px 0 rgba(17,29,67,0.8)',
    },
    analystDetails: {
      border: `1px solid ${palette.spaceCadet}`,
      backgroundColor: palette.spaceCadet,
      boxShadow: '-5px 5px 18px 0 rgba(17,29,67,0.8)',
    },
  },
  profile: {
    stepper: {
      title: {
        backgroundColor: '#14224F',
        border: '1px solid #14224F',
        finished: {
          backgroundColor: '#14224F',
          color: '#9EA1B9',
        },
      },
    },
  },
  contactInfo: {
    backgroundColor: palette.spaceCadet,
    boxShadow: '-5px 5px 18px 0 rgba(17,29,67,0.8)',
  },
  proDeclarationModal: {
    text: {
      color: '#E5E9FE',
    },
    footer: {
      color: '#E5E9FE',
    },
    sign: {
      color: '#E5E9FE',
    },
  },

  // overrides
  overrides: {
    MuiTabs: {
      root: {
        border: '1px solid #0D1635',
        backgroundColor: '#0D1635',
        borderRadius: '6px 6px 0 0',
      },
      indicator: {
        height: '4px',
        background: 'linear-gradient(113.45deg, #742DCE 0%, #050B99 100%)',
      },
      scroller: {
        scrollbarWidth: 'none',
        marginBottom: '0!important',
      },
    },
    MuiTab: {
      root: {
        color: '#F2F1F1',
        backgroundColor: '#0D1635',
        fontWeight: 400,
        padding: '5px 20px',
        minWidth: '120px',
        fontSize: '20px',
        lineHeight: 1.75,
        textTransform: 'none',
        '@media (min-width: 960px)': {
          fontSize: '20px',
          minWidth: '120px',
        },
        '@media (max-width: 480px)': {
          fontSize: '16px',
          flexGrow: 1,
          maxWidth: 'none',
          flexShrink: 1,
          padding: '5px 0',
          minWidth: 'unset',
        },
        '&[aria-selected="true"]': {
          backgroundColor: '#111D43',
          color: '#F2F1F1',
          fontWeight: 500,
        },
      },
      textColorInherit: {
        color: '#F2F1F1',
        opacity: 0.7,
      },
    },
    MuiButton: {
      root: {
        '&$disabled': {
          color: '#ffffff',
          background: 'none',
          backgroundColor: '#D8D8DF',
        },
      },
      flatPrimary: {
        color: palette.turquoise,
        textTransform: 'none',
        fontWeight: 500,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent',
          color: '#78D5D3',
        },
        '&:active': {
          backgroundColor: 'transparent',
          color: palette.moonstoneBlue,
        },
      },
      containedPrimary: {
        color: 'white',
        fontWeight: 700,
        backgroundSize: '200% auto',
        transition: '0.5s',
        backgroundColor: '#ffffff',
        background: 'linear-gradient(113.45deg, #742DCE 0%, #050B99 51%, #742DCE 100%)',
        '&:hover': {
          backgroundPosition: 'right center',
        },
        '&:active': {
          background: 'linear-gradient(113.45deg, #050B99 0%, #080C9A 100%)',
        },
      },
      containedSecondary: {
        color: 'white',
        fontWeight: 700,
        backgroundSize: '200% auto',
        transition: '0.5s',
        backgroundColor: '#ffffff',
        background: 'linear-gradient(116.57deg, #7ADEC9 0%, #5BB0CE 51%, #7ADEC9 100%)',
        '&:hover': {
          backgroundPosition: 'right center',
        },
        '&:active': {
          background: 'linear-gradient(116.57deg, #5AB6C6 0%, #5BB0CE 100%)',
        },
      },
      contained: {
        '&$disabled': {
          color: '#ffffff',
          background: 'none',
          backgroundColor: '#D8D8DF',
        },
      },
      sizeSmall: {
        padding: '10px 15px',
        fontSize: 12,
      },
      sizeLarge: {
        padding: '16px 24px',
        fontSize: 15,
        textTransform: 'uppercase',
        fontWeight: '700',
      },
      label: {
        lineHeight: '15px',
        boxShadow: 'none',
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: palette.spaceCadet,
      },
    },
  },
});

export default theme;
