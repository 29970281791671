export default {
  root: {
    maxWidth: '120px',
  },
  fileContainer: (theme) => ({
    display: 'flex',
    width: 120,
    height: 120,
    overflow: 'hidden',
    border: `1px solid ${theme.palette.primary.lightest}`,
  }),
  fileContainerError: (theme) => ({
    border: `1px solid ${theme.palette.error.dark}`,
  }),
  fileImage: {
    width: 'auto',
    maxWidth: 'fit-content',
  },
  fileExtension: {
    textTransform: 'uppercase',
  },
  nonImageFileContainer: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    flex: 1,
    backgroundColor: theme.palette.primary.lightest,
  }),
  fileItemDescription: {
    display: 'flex',
    alignItems: 'center',
    '&  > *:first-child': {
      flex: 1,
    },
  },
  clickable: {
    cursor: 'pointer',
  },
};
