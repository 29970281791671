import CircularProgress from 'material-latest/CircularProgress';
import Box from 'material-latest/Box';
import Typography from 'material-latest/Typography';

import { styles } from './styles';

const CollapsedProgress = ({ value }) => {
  return (
    <Box sx={styles.wrapper}>
      <CircularProgress size={72} sx={styles.progress} variant="determinate" value={value} />
      <Box sx={styles.valueWrapper}>
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export default CollapsedProgress;
