import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const ALERT_VARIANTS = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};

export const ALERT_ICONS = {
  [ALERT_VARIANTS.SUCCESS]: CheckCircleIcon,
  [ALERT_VARIANTS.ERROR]: CancelIcon,
  [ALERT_VARIANTS.WARNING]: ErrorOutlineIcon,
  [ALERT_VARIANTS.INFO]: InfoOutlinedIcon,
};
