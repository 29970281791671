import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Box } from 'material-latest';

import TPSliderItem from './components/TPSliderItem';
import TPFilesPreviewModal from '../TPFilesPreviewModal';
import { SIDES_DEFAULT_NUMBER, SLIDE_DEFAULT_WIDTH } from './constants';

import './slickStyle.css';
import styles from './styles';

const SLIDER_DEFAULT_OPTIONS = {
  centerMode: false,
  dots: false,
  infinite: false,
  speed: 500,
  slidesToScroll: 1,
};

const TPSlider = ({ items = [], options, preview = false, onItemClick, className }) => {
  const [previewItemIndex, setPreviewItemIndex] = useState(null);
  const sliderOptions = useMemo(
    () => (options ? Object.assign({}, SLIDER_DEFAULT_OPTIONS, options) : SLIDER_DEFAULT_OPTIONS),
    [options],
  );
  const sliderRef = useRef();
  const observer = useRef();
  const [slidesToShow, setSlidesToShow] = useState(1);

  useEffect(() => {
    const sliderElem = sliderRef.current;
    if (sliderElem) {
      if (window.ResizeObserver) {
        observer.current = new ResizeObserver(function(entries) {
          // since we are observing only a single element, so we access the first element in entries array
          const rect = entries[0].contentRect;
          const width = rect.width;
          setSlidesToShow(width ? Math.round(width / SLIDE_DEFAULT_WIDTH) || 1 : 1);
        });

        observer.current.observe(sliderElem);
      } else {
        //for OLD browser that does not support ResizeObserver
        setSlidesToShow(SIDES_DEFAULT_NUMBER);
      }
    }
    return () => {
      if (observer.current) {
        observer.current.unobserve(sliderElem);
      }
    };
  }, [items.length]);

  const handleClick = useCallback(
    (item, index) => {
      if (preview) {
        setPreviewItemIndex(index);
      }
      if (onItemClick) {
        onItemClick(item);
      }
    },
    [preview, onItemClick],
  );

  const handlePreviewClose = useCallback(() => {
    setPreviewItemIndex(null);
  }, []);

  return (
    <Box sx={[styles.root, className]} ref={sliderRef}>
      <Slider {...sliderOptions} slidesToShow={slidesToShow}>
        {items
          ? items.map((item, index) => (
              <TPSliderItem
                key={item.name + index}
                url={item.url}
                name={item.name}
                onClick={() => handleClick(item, index)}
              />
            ))
          : null}
      </Slider>
      {preview && previewItemIndex !== null && (
        <TPFilesPreviewModal value={previewItemIndex} items={items} onClose={handlePreviewClose} />
      )}
    </Box>
  );
};

TPSlider.propTypes = {
  items: PropTypes.array.isRequired,
  options: PropTypes.array,
  onItemClick: PropTypes.func,
  preview: PropTypes.bool,
};

export default TPSlider;
