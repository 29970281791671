export default {
  root: (theme) => ({
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: theme.spacing(2),
    backgroundColor: theme.palette.primary.lightest,
    borderStyle: 'dashed',
    borderWidth: '2px',
    borderColor: theme.palette.primary.dark,
  }),
  fullWidth: {
    width: '100%',
  },
  fileAccepted: (theme) => ({
    borderColor: theme.palette.primary.dark,
  }),
  fileRejected: (theme) => ({
    borderColor: theme.palette.error.dark,
    backgroundColor: theme.palette.error.lightest,
  }),
  buttonsContainer: (theme) => ({
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: theme.spacing(2),
    '@media(max-width): 420px': {
      flexDirection: 'column',
    },
  }),
  errorContainer: (theme) => ({
    minHeight: theme.textError.root.minHeight,
  }),
};
