import React, { useCallback, useMemo } from 'react';
import { withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { accountPlanSelector } from '../../../auth/selectors';
import { Routes } from '../../../../constants/routeConstants';
import { ACCOUNT_SERVERS } from '../../../../constants';
import { ACCOUNT_PLANS } from '../../../../constants/acctountPlans';
import { closeModal } from '../../../common/CustomModal/actions';
import verificationModalNames from '../../constants/verificationModalNames';
import CustomModal from '../../../common/CustomModal';
import ImageSubtitleTextContent from '../../../common/ContentModal/ImageSubtitleTextContent';
import CustomLink from '../../../common/CustomLink';

import quizFailureImage from '../../../../static/quizzes/quiz-failure.svg';
import styles from './styles';

const SecondFailModal = ({ classes }) => {
  const { t } = useTranslation('quiz');
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedPlan = useSelector(accountPlanSelector);

  const generateTranslatedInfoText = useCallback(
    (selectedPlan) => {
      if (selectedPlan === ACCOUNT_PLANS.PRO) {
        return (
          <Trans i18nKey="quiz">
            Sadly you didn&apos;t pass the quiz on the second attempt. If you are still interested
            in becoming a Pro client, please reach out to our support team at{' '}
            <CustomLink to="mailto:support@globalprime.com">support@globalprime.com</CustomLink> and
            we can be of further assistance.
          </Trans>
        );
      }
      return t(`secondFailModal.${ACCOUNT_PLANS.RETAIL}.infoText`);
    },
    [t],
  );

  const handleClose = useCallback(() => {
    dispatch(closeModal(verificationModalNames.SECOND_FAILED_MODAL));
    history.push({
      pathname: Routes.MY_ACCOUNTS,
      state: { activeTab: ACCOUNT_SERVERS.DEMO },
    });
  }, [dispatch, history]);

  const buttons = useMemo(
    () => [
      {
        label: t('common:buttons.gotIt'),
        color: 'primary',
        onClick: handleClose,
      },
    ],
    [t, handleClose],
  );

  return (
    <CustomModal
      modalClassNames={classes.modal}
      className={classes.modalPaper}
      modalName={verificationModalNames.SECOND_FAILED_MODAL}
      title={t(
        `quiz:${
          selectedPlan === ACCOUNT_PLANS.PRO ? ACCOUNT_PLANS.PRO : ACCOUNT_PLANS.RETAIL
        }.page.title`,
      )}
      isCloseButtonNeeded
      customCloseModal={handleClose}
      buttons={buttons}>
      <ImageSubtitleTextContent
        image={quizFailureImage}
        text={generateTranslatedInfoText(selectedPlan)}
        subtitle={t(`secondFailModal.${selectedPlan || ACCOUNT_PLANS.RETAIL}.title`)}
      />
    </CustomModal>
  );
};

export default withStyles(styles)(SecondFailModal);
