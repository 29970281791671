import { handleActions } from 'redux-actions';

import { getUserEmailFeedSuccess, getUserEmailFeedFail } from '../actions';

const defaultState = {};

export default handleActions(
  {
    [getUserEmailFeedSuccess](state, action) {
      return action.response.data;
    },

    [getUserEmailFeedFail]() {
      return defaultState;
    },
  },
  defaultState,
);
