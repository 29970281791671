import { handleActions } from 'redux-actions';

import * as actions from '../actions';

const defaultState = 0;

export default handleActions(
  {
    [actions.setTmdProgressIndex](state, action) {
      return action.payload;
    },
  },
  defaultState,
);
