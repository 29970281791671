import { fontSize } from '../../font';
import { palette } from '../palette';

const OverridesMuiFormHelperText = {
  styleOverrides: {
    root: {
      fontSize: fontSize._12,
      lineHeight: 1.323,
      color: palette.textSecondary.dark,
      '&.Mui-error': {
        color: palette.textSecondary.dark,
      },
      '&.Mui-disabled': {
        color: palette.textSecondary.lightest,
      },
    },
    contained: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
};

export default OverridesMuiFormHelperText;
