import { fontSize } from './fontSize';
import { palette } from './palette';

export const common = {
  climCustomStyles: {
    climCustomButton: {
      borderRadius: 0,
      boxShadow: 'none',
      background: 'rgba(200, 101, 103, 1)',
      '&:hover': {
        background: 'rgba(200, 101, 103, 0.7)',
      },
      '&:active': {
        background: 'rgba(200, 101, 103, 0.7)',
      },
    },
    climCustomLink: {
      margin: '0 0 4px 0',
      color: '#C86567',
      fontSize: 14,
      fontWeight: 300,
      '&:hover': {
        color: 'rgba(200, 101, 103, 0.7)',
      },
      '&:active': {
        color: 'rgba(200, 101, 103, 0.7)',
      },
    },
    climCustomLogo: {
      maxWidth: 183,
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: ['Red Hat Display', 'sans-serif'],
  },
  textError: {
    position: 'absolute',
    color: palette.error.main,
    fontSize: fontSize.xs,
    display: 'flex',
    alignItems: 'center',
  },
  tip: {
    color: '#767C9E',
    fontSize: fontSize.xs,
    display: 'block',
  },
};
