import get from 'lodash/get';

export const getDestinationHub = (state) => get(state, 'auth.signIn.destinationHub');

export const getHideMfaToken = (state) => get(state, 'auth.hideMfaToken');

export const getSelectHub = (state) => get(state, 'selectHub');

export const getBlockedHubs = (state) => get(state, 'auth.signIn.blockedHubs', []);

export const getShowCaptcha = (state) => get(state, 'auth.signIn.showCaptcha');
