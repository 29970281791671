import React from 'react';
import PropTypes from 'prop-types';
import { Box, Radio } from 'material-latest';
import { RadioButtonChecked, RadioButtonUncheckedRounded } from '@mui/icons-material';

import { MAIN_COLORS, SIZES } from 'components/TP-UI/constants';

import styles from './styles';

const TPRadio = ({ label, color, disabled, checked, size, ...others }) => {
  return (
    <Box sx={styles.root}>
      <Box component="label" sx={[styles.rootLabel, disabled && styles.rootLabelDisabled]}>
        <Radio
          checked={checked}
          disabled={disabled}
          color={color}
          size={size}
          icon={<RadioButtonUncheckedRounded fontSize={size} />}
          checkedIcon={<RadioButtonChecked fontSize={size} />}
          {...others}
        />
        <Box
          component="span"
          sx={[styles.label, disabled && styles.labelDisabled, size && styles[`${size}SizeLabel`]]}>
          {label}
        </Box>
      </Box>
    </Box>
  );
};

TPRadio.defaultProps = {
  color: MAIN_COLORS.PRIMARY,
  size: SIZES.MEDIUM,
  disabled: false,
};

TPRadio.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  color: PropTypes.oneOf([MAIN_COLORS.PRIMARY, MAIN_COLORS.SECONDARY]),
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  size: PropTypes.oneOf([SIZES.MEDIUM, SIZES.SMALL]),
  value: PropTypes.any,
  name: PropTypes.string,
};

export default TPRadio;
