import { combineReducers } from 'redux';

import discover from './discover';
import dashboard from '../pages/Dashboard/reducers';
import myFeeds from '../pages/MyFeeds/reducers';
import sentiment from '../pages/Sentiment/reducers';

const reducer = combineReducers({ discover, dashboard, myFeeds, sentiment });

export default reducer;
