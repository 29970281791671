import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

export const formatCountries = (countries) => {
  let formattedCountries = countries.map((country) => ({
    ...country,
    value: country.name,
    label: country.name,
  }));
  return formattedCountries;
};

export const formatAlphabeticalCountries = (countries) => {
  let alphaCountries = groupBy(countries, ({ name: [firstLetter] }) => firstLetter);

  alphaCountries = map(alphaCountries, (items, key) => ({
    label: key,
    options: sortBy(items, 'label'),
  }));

  alphaCountries = sortBy(alphaCountries, 'label');

  return alphaCountries;
};
