export default (theme) => ({
  container: {
    padding: 16,
    flex: '1 1',
    border: `1px solid ${theme.palette.lightGreen}`,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.textColor,
  },
  description: {
    fontSize: 14,
    color: theme.palette.simpleGrey,
    mb: 4,
  },
  resultsDataWrapper: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
    },
    padding: 0,
    marginTop: 20,
  },
  resultsGroup: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(600)]: {
      flexDirection: 'row',
    },
    '&:last-child': {
      borderLeft: `1px solid ${theme.palette.lightGreen}`,
      paddingLeft: 16,
      [theme.breakpoints.down(600)]: {
        borderLeft: 'none',
        paddingLeft: 0,
        borderTop: `1px solid ${theme.palette.lightGreen}`,
        paddingTop: 16,
      },
    },
  },
  resultsRow: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
  },
  nonGroupResultRow: {
    width: '100%',
  },
  resultsRowLabel: {
    width: '180px',
    height: '17px',
    fontSize: 12,
    fontWeight: '300',
    lineHeight: '17px',
    color: theme.palette.simpleGrey,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  resultsRowValue: {
    height: '17px',
    fontSize: 16,
    lineHeight: '17px',
    color: theme.palette.textColor,
  },
  resultsGroupLabel: {
    color: theme.palette.textColor,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '26px',
    marginBottom: 16,
  },
});
