import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import styles from './styles';

const ProgressBar = ({ classes, className, progress }) => {
  return (
    <LinearProgress
      variant="determinate"
      value={progress}
      classes={{
        colorPrimary: classes.progressBar,
        barColorPrimary: classes.progressBarColor,
      }}
      className={className}
    />
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default withStyles(styles)(ProgressBar);
