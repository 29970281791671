import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Typography } from '@material-ui/core';
import formatPrice from 'utils/formatPrice';

const TableAmountField = ({
  classes,
  label,
  dataLabel,
  customCurrencies,
  tableItem,
  className,
  showLabel,
  isHeadRow,
}) => {
  if (showLabel && isHeadRow) {
    return (
      <div className={classNames([classes.amountContainer, className])}>
        <Typography className={classes.label}>{label}</Typography>
      </div>
    );
  }
  return (
    <div className={classNames([classes.amountContainer, className])}>
      {dataLabel.map((dataLabel, key) => {
        if (tableItem[dataLabel] === undefined) {
          return null;
        }
        return (
          <Typography key={key} className={classes.amountText}>
            {formatPrice(tableItem[dataLabel], customCurrencies[key] || tableItem.currency)}
          </Typography>
        );
      })}
    </div>
  );
};

TableAmountField.propTypes = {
  label: PropTypes.string.isRequired,
  dataLabel: PropTypes.string.isRequired,
  tableItem: PropTypes.object,
  className: PropTypes.string,
  showLabel: PropTypes.bool,
};

export default withStyles(styles)(TableAmountField);
