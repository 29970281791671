import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { change, reduxForm } from 'redux-form';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { ACCOUNT_SERVERS } from 'constants/index';
import { ADD_ACCOUNT_FORM } from '../../constants';
import * as actions from '../../actions';
import { styles, sxStyles } from './styles';

import { Typography } from 'material-latest';
import PrimaryButton from 'modules/common/PrimaryButton';

const ReadyToCreateAccountModal = ({ classes, actions, isVisible, handleChange }) => {
  const { accountsHideReadyToCreateDialog, accountsShowAddDialog } = actions;
  const { t } = useTranslation('accounts');
  const onButtonClick = (value) => () => {
    handleChange(ADD_ACCOUNT_FORM, 'accountServer', value);
    return accountsShowAddDialog();
  };
  return (
    <Dialog
      open={isVisible}
      onClose={accountsHideReadyToCreateDialog}
      aria-labelledby="ready-to-create-dialog-title"
      aria-describedby="ready-to-create-dialog-description"
      PaperProps={{
        classes: {
          root: classes.paper,
        },
      }}>
      <DialogTitle id="ready-to-create-dialog-title">
        <Typography sx={sxStyles.title}>{t('createNewAccount.title')}</Typography>
      </DialogTitle>
      <DialogContent id="ready-to-create-dialog-description">
        <Typography sx={sxStyles.title} fontWeight={700}>
          {t('createNewAccount.body')}
        </Typography>
      </DialogContent>
      <DialogActions
        id="ready-to-create-dialog-action-buttons"
        className={classes.buttonsContainer}>
        <PrimaryButton
          color="secondary"
          size="large"
          onClick={onButtonClick(ACCOUNT_SERVERS.DEMO)}
          label={t('createNewAccount.demo')}
          type="submit"
        />
        <PrimaryButton
          color="primary"
          onClick={onButtonClick(ACCOUNT_SERVERS.LIVE)}
          size="large"
          label={t('createNewAccount.live')}
          type="submit"
        />
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    handleChange: bindActionCreators(change, dispatch),
  };
};

export default compose(
  reduxForm({ change }),
  connect(null, mapDispatchToProps),
  withStyles(styles),
)(ReadyToCreateAccountModal);
