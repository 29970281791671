import React, { useCallback } from 'react';
import { TMD_SUCCESS_MODAL } from '../../constants';
import { useDispatch } from 'react-redux';
import TMDSuccessModal from '../../components/TMDSuccessModal';
import { closeModal } from 'modules/common/CustomModal/actions';
import PropTypes from 'prop-types';

const TMDSuccessModalContainer = ({ onSuccess }) => {
  const dispatch = useDispatch();

  const handleContinue = useCallback(
    (e) => {
      dispatch(closeModal(TMD_SUCCESS_MODAL));
      onSuccess(e);
    },
    [dispatch, onSuccess],
  );

  const handleCancel = useCallback(() => {
    dispatch(closeModal(TMD_SUCCESS_MODAL));
  }, [dispatch]);

  return <TMDSuccessModal onContinue={handleContinue} onCancel={handleCancel} />;
};

TMDSuccessModalContainer.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default TMDSuccessModalContainer;
