import React from 'react';
import styles from './styles';
import MainLayout from 'modules/common/MainLayout';
import DocumentTitle from 'react-document-title';
import CountryUsersContainer from '../../containers/CountryUsersContainer';
import VolumeUsersContainer from '../../containers/UserVolumesContainer';
import ActiveUsersContainer from '../../containers/ActiveUsersContainer';
import MarketingChannelsContainer from '../../containers/MarketingChannelsContainer';
import NetDepositContainer from '../../containers/NetDepositContainer';
import { withStyles } from '@material-ui/core/styles';
import NavTabs from '../../../common/NavTabs/containers/NavTabsContainer';
import OverviewDataContainer from '../../containers/OverviewDataContainer';
import { useSelector } from 'react-redux';
import { getIsGlobalAdmin } from 'modules/auth/selectors';

const DashboardPage = ({ classes }) => {
  const isGlobalAdmin = useSelector(getIsGlobalAdmin);

  return (
    <MainLayout title="Dashboard" contentClassName={classes.root}>
      <DocumentTitle title="Dashboard" />
      <NavTabs>
        <div path="overview" label="Overview Data">
          <OverviewDataContainer />
        </div>
        {isGlobalAdmin ? (
          <div path="country-users" label="Country Users">
            <CountryUsersContainer />
          </div>
        ) : null}
        <div path="volume-users" label="Volume Users">
          <VolumeUsersContainer />
        </div>
        <div path="active-users" label="Active Users">
          <ActiveUsersContainer />
        </div>
        <div path="net-deposit-users" label="Net Deposit Users">
          <NetDepositContainer />
        </div>
        <div path="marketing-channels" label="Marketing Channels">
          <MarketingChannelsContainer />
        </div>
      </NavTabs>
    </MainLayout>
  );
};

export default withStyles(styles)(DashboardPage);
