import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import bell from 'static/notificationCentre/bell.svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import InlineBadge from 'modules/common/InlineBadge';
import { IconButton } from '@material-ui/core';

const Notifications = memo(
  ({ classes, getUserNotificationsOpen, notificationsCount, bellClassNames = '' }) => {
    const { t } = useTranslation('notificationCentre');
    return (
      <IconButton
        onClick={getUserNotificationsOpen}
        className={classNames(classes.button, bellClassNames)}>
        <img src={bell} alt={t('page.notifications')} className={classes.bell} />
        {notificationsCount > 0 && (
          <InlineBadge
            classNames={{ root: classes.rootBage, badge: classes.badge }}
            badgeContent={notificationsCount}
          />
        )}
      </IconButton>
    );
  },
);

Notifications.displayName = 'Notifications';

Notifications.propTypes = {
  notificationsCount: PropTypes.number.isRequired,
  getUserNotificationsOpen: PropTypes.func.isRequired,
  bellClassNames: PropTypes.string,
};

export default withStyles(styles)(Notifications);
