import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import get from 'lodash/get';

import { createPendingSelector } from 'modules/api/selectors';

import { pipValueCalculatorRequest } from '../actions';
import { PIP_VALUE_CALCULATOR_FORM } from '../constants';
import {
  getCurrencySpecs,
  getPreparedPipValueResults,
  pipValueFormSelector,
  getContractSizes,
} from '../selectors';

import PipValueCalculatorForm from '../components/PipValueCalculatorForm';

const PipValueCalculatorFormContainer = ({ symbols }) => {
  const dispatch = useDispatch();
  const isPending = useSelector(createPendingSelector(pipValueCalculatorRequest));
  const currencySpecs = useSelector(getCurrencySpecs);
  const results = useSelector(getPreparedPipValueResults);
  const currencyPair = useSelector((state) => pipValueFormSelector(state, 'symbol'));
  const contractSizes = useSelector(getContractSizes);

  const currencySpec = useMemo(() => {
    return get(currencySpecs, get(currencyPair, 'value', currencyPair), {});
  }, [currencySpecs, currencyPair]);

  useEffect(() => {
    if (currencySpec.contractSize) {
      dispatch(change(PIP_VALUE_CALCULATOR_FORM, 'contractSize', currencySpec.contractSize));
    }
  }, [currencySpec.contractSize, dispatch]);

  const handleSubmit = useCallback(
    ({ symbol, baseCurrency, volumeInLots }) => {
      dispatch(
        pipValueCalculatorRequest({
          symbol: symbol.value,
          baseCurrency,
          volumeInLots,
          marginStyle: currencySpec.marginStyle,
          pipValue: currencySpec.pipValue,
        }),
      );
    },
    [dispatch, currencySpec],
  );

  return (
    <PipValueCalculatorForm
      isPending={isPending}
      form={PIP_VALUE_CALCULATOR_FORM}
      symbols={symbols}
      contractSizes={contractSizes}
      results={results}
      onSubmit={handleSubmit}
    />
  );
};

export default memo(PipValueCalculatorFormContainer);
