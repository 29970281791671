const MuiFormControlLabel = {
  styleOverrides: {
    marginDense: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
};

export default MuiFormControlLabel;
