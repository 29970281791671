export const DEFAULT_PAGE_LIMIT = 50;

export const PAGE_LIMITS_VALUES = [5, 10, 20, 50, 100];

export const PAGINATION_FORM = 'PAGINATION_FORM';

export const PAGINATION_BAR_PAGES_LIMIT = 9;

export const totalNumbersLabels = {
  clients: 'clients',
  templates: 'templates',
  emails: 'emailHistory',
  withdrawals: 'pendingWithdrawals',
  paypalWithdrawals: 'pendingWithdrawals',
  deposits: 'smartwayRequests',
  payments: 'paymentsHistory',
  accounts: 'pendingAccounts',
  verifications: 'verificationRequests',
  bankDetails: 'bankDetails',
  userEmails: 'userEmails',
  userPayments: 'userPayments',
  employees: 'employees',
  blockedUsers: 'blockedUsers',
  securityLogs: 'securityLogs',
  partnerTransfers: 'partnerTransfers',
  followUps: 'followUps',
  operationNotifications: 'notifications',
  users: 'users',
  tickets: 'tickets',
  notifications: 'notifications',
  customFields: 'customFields',
  birthdays: 'birthdays',
  requests: 'migrationRequests',
  queue: 'queue',
  vpsUsers: 'vpsUsers',
  deletedUsers: 'deletedUsers',
  migrateToIB: 'migrateToIB',
  bulkMigrateToIB: 'bulkMigrateToIB',
  tradingStatements: 'tradingStatements',
  quiz: 'quiz',
};

export const PAGINATION_DEFAULT_PAGE = 1;

export const PAGINATION_DEFAULT_SKIP = 0;
