import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import {
  TMD_CHECK_MODAL,
  TMD_TEST_MODAL_TITLE,
  TMD_TEST_MODAL_TITLE_END_ADORNMENT,
} from '../../constants';
import CustomModal from '../../../CustomModal';
import ProgressBar from '../../../ProgressBar';
import ImageSubtitleTextContent from '../../../ContentModal/ImageSubtitleTextContent';

import quizCheckImage from '../../../../../static/quizzes/quiz-check.svg';

import styles from './styles';

const TMDCheckModal = ({ onSubmit, onRecheck, loading, onRecheckCancel, classes }) => {
  const { t } = useTranslation('common');
  const buttons = useMemo(
    () => [
      { label: t('buttons.recheck'), variant: 'text', onClick: onRecheck, disabled: loading },
      { label: t('buttons.submit'), color: 'primary', onClick: onSubmit, disabled: loading },
    ],
    [t, onRecheck, onSubmit, loading],
  );

  return (
    <CustomModal
      modalName={TMD_CHECK_MODAL}
      customCloseModal={onRecheckCancel}
      isCloseButtonNeeded
      title={TMD_TEST_MODAL_TITLE}
      titleEndAdornment={TMD_TEST_MODAL_TITLE_END_ADORNMENT}
      buttons={buttons}
      modalClassNames={classes.modal}
      className={classes.modalPaper}>
      <div>
        <ProgressBar progress={100} className={classes.progressBar} />
        <ImageSubtitleTextContent
          image={quizCheckImage}
          subtitle="Check your answers"
          text={
            <>
              <Typography className={classes.text}>
                In order to trade with Global Prime, you must answer 100% of these questions
                correctly.
              </Typography>
              <Typography className={classes.text}>
                Kindly check your answers. You will{' '}
                <Typography
                  className={classnames(classes.text, classes.highlighted)}
                  color="error"
                  component="span">
                  not be able to continue
                </Typography>{' '}
                if you fail the &quot;Appropriateness&quot; test.
              </Typography>
            </>
          }
        />
      </div>
    </CustomModal>
  );
};

TMDCheckModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onRecheck: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onRecheckCancel: PropTypes.func.isRequired,
};

export default withStyles(styles)(TMDCheckModal);
