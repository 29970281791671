import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import SecondaryButton from '../../../SecondaryButton';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

const ScrollOnTopButton = ({ classes, scrollOnTop, isButtonShowed }) => {
  const { t } = useTranslation('common');
  return (
    <div>
      <SecondaryButton
        onClick={scrollOnTop}
        className={classNames({
          [classes.scrollOnTopButton]: true,
          [classes.hidden]: !isButtonShowed,
        })}
        label={t('buttons.onTop')}
        content={<ArrowUpward className={classes.icon} />}
      />
    </div>
  );
};

ScrollOnTopButton.propTypes = {
  scrollOnTop: PropTypes.func.isRequired,
  isButtonShowed: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ScrollOnTopButton);
