import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedRounded from '@material-ui/icons/RadioButtonUncheckedRounded';
import classnames from 'classnames';

import styles from './styles';

const RadioInput = ({ classes, ...others }) => {
  return (
    <FormControlLabel
      {...others}
      control={
        <Radio
          color="primary"
          icon={<RadioButtonUncheckedRounded className={classes.icon} />}
          checkedIcon={
            <RadioButtonChecked className={classnames(classes.icon, classes.radioChecked)} />
          }
          classes={{
            root: classes.radioButton,
            checked: classes.radioButtonChecked,
          }}
        />
      }
      classes={{
        root: classes.formControlLabelRoot,
        label: classnames(classes.formControlLabel, {
          [classes.formControlLabelDisabled]: others.disabled,
        }),
      }}
    />
  );
};

RadioInput.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(RadioInput);
