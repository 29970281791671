import { combineActions, handleActions } from 'redux-actions';

import * as signUpActionCreators from '../pages/SignUp/actions';
import * as signInActionCreators from '../pages/SignIn/actions';
import * as migrationActionCreators from '../pages/Migration/actions';
import * as actionCreators from '../actions';
import * as userActionCreators from 'modules/userManagement/pages/UserDetails/actions';
import * as leadActionCreators from 'modules/lead/actions';
import { forcePasswordChangeSuccess } from '../pages/ForcePasswordChange/actions';

const defaultState = null;

export default handleActions(
  {
    [combineActions(
      signUpActionCreators.signupSuccess,
      signInActionCreators.signinSuccess,
      signUpActionCreators.googleAuthSuccess,
      migrationActionCreators.userMigrationSuccess,
      userActionCreators.signinByEmailSuccess,
      leadActionCreators.leadUserJoinSuccess,
      forcePasswordChangeSuccess,
    )](state, action) {
      return action.response.data.refreshToken;
    },
    [combineActions(signInActionCreators.signinNonfxSuccess)](state, action) {
      return action.payload.refreshToken;
    },
    [combineActions(actionCreators.logout)]() {
      return defaultState;
    },
  },
  defaultState,
);
