import { range } from 'lodash';

export const YEARS = range(new Date().getFullYear() - 100, new Date().getFullYear() + 2, 1)
  .reverse()
  .map((v) => ({
    label: v.toString(),
    value: v,
  }));

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
].map((v, index) => ({ label: v, value: index }));

export const MAX_YEAR_SIZE = 4;

export const MAX_MONTH_SIZE = 9;
