export default {
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  root: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
  }),
};
