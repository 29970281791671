export default (paymentOptions) => {
  const formattedPaymentOptions = paymentOptions.map((paymentOption) => {
    return {
      ...paymentOption,
      label: paymentOption.cardNumberMasked,
      value: paymentOption._id,
    };
  });
  return formattedPaymentOptions;
};
