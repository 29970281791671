import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel } from 'material-latest';

import { MAIN_COLORS } from 'components/TP-UI/constants';

const TPFormLabel = ({ component = 'label', color = MAIN_COLORS.PRIMARY, children, ...others }) => {
  return (
    <FormLabel component={component} color={color} {...others}>
      {children}
    </FormLabel>
  );
};

TPFormLabel.propTypes = {
  component: PropTypes.elementType,
  children: PropTypes.node,
  color: PropTypes.oneOf([MAIN_COLORS.PRIMARY]),
};

export default TPFormLabel;
