export default {
  languageLabelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  label: (theme) => ({
    marginLeft: theme.spacing(1),
    lineHeight: 1.2,
  }),
  menuPaper: {
    maxHeight: 'initial',
  },
};
