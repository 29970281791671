import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import Spinner from '../Spinner';

const LoaderFullPage = ({ classes, isLoading }) => {
  return (
    <div className={classNames({ [classes.loaderContainer]: true, [classes.hidden]: !isLoading })}>
      <Spinner />
    </div>
  );
};

LoaderFullPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(LoaderFullPage);
