import React from 'react';
import { Grid } from 'material-latest';
import { Field, reduxForm } from 'redux-form';
import TPRadioGroup from 'components/TP-UI/TPRadioGroup';
import TPTypography from 'components/TP-UI/TPTypography';

const radioButtons = [
  { value: 'radio1', label: 'Radio 1' },
  { value: 'radio2', label: 'Radio 2' },
];

const Radios = () => {
  return (
    <form>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Field
            component={TPRadioGroup}
            name="radioGroup1"
            legend="Radio Group"
            buttons={radioButtons}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            component={TPRadioGroup}
            name="radioGroup2"
            buttons={radioButtons}
            legend="Radio Group, error"
            meta={{ error: { key: 'common:errors.form.isRequired' }, submitFailed: true }}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            component={TPRadioGroup}
            name="radioGroup3"
            legend="Radio Group"
            buttons={radioButtons}
            size="small"
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            component={TPRadioGroup}
            name="radioGroup4"
            buttons={radioButtons}
            legend="Radio Group, error"
            meta={{ error: { key: 'common:errors.form.isRequired' }, submitFailed: true }}
            size="small"
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            component={TPRadioGroup}
            name="radioGroup2"
            buttons={radioButtons}
            legend="Radio Group row"
            size="small"
            disabled
            row
          />
        </Grid>
        <Grid item xs={9}>
          <TPTypography variant="h6">Radio Group without legend</TPTypography>
          <Field
            component={TPRadioGroup}
            name="radioGroup2"
            buttons={radioButtons}
            disabled
            row
            size="small"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default reduxForm({
  form: 'TP-RADIO-FORM',
  initialValues: {
    radioGroup1: 'radio1',
    radioGroup2: 'radio2',
  },
})(Radios);
