import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import FormControl from 'material-latest/FormControl';
import FormHelperText from 'material-latest/FormHelperText';
import Box from 'material-latest/Box';

import TPErrorMessage from 'components/TP-UI/TPErrorMessage';
import TPFormLabel from 'components/TP-UI/TPFormLabel';
import { SIZES } from 'components/TP-UI/constants';
import config from 'config';
import { editorConfig } from './config';

import styles from './styles';

export const TPTinyEditor = ({
  disabled,
  name,
  error = null,
  filesUploadUrl,
  fullWidth,
  hideDetails = false,
  hint,
  initialValue,
  label,
  onChange,
  onBlur,
  required,
  reservedErrorSpace = true,
  value,
  variant = 'outlined',
  className,
}) => {
  const [focused, setFocused] = useState(false);

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(
    (e) => {
      setFocused(false);
      if (onBlur) {
        onBlur(e);
      }
    },
    [onBlur],
  );

  return (
    <Box sx={[styles.tinyEditorContainer, focused && styles.focused]}>
      <FormControl
        variant={variant}
        required={required}
        focused={focused}
        disabled={disabled}
        fullWidth={fullWidth}
        sx={[className, !!error && styles.tinyEditorError]}
        error={!!error}>
        {label && (
          <TPFormLabel disabled={disabled} focused={focused} className={styles.tinyEditorLabel}>
            {label}
          </TPFormLabel>
        )}
        <Editor
          id={name}
          apiKey={config.TINY_API_KEY}
          initialValue={initialValue}
          value={value}
          disabled={disabled}
          onEditorChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          init={{
            images_upload_url: filesUploadUrl,
            ...editorConfig,
          }}
        />
      </FormControl>
      {!hideDetails && (
        <>
          {hint && <FormHelperText>{hint}</FormHelperText>}
          <Box sx={[reservedErrorSpace && styles.errorContainer]}>
            {error && <TPErrorMessage error={error} size={SIZES.SMALL} />}
          </Box>
        </>
      )}
    </Box>
  );
};

TPTinyEditor.propTypes = {
  disabled: PropTypes.bool,
  filesUploadUrl: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  hideDetails: PropTypes.bool,
  hint: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  initialValue: PropTypes.string,
  required: PropTypes.bool,
  reservedErrorSpace: PropTypes.bool,
  value: PropTypes.any,
  variant: PropTypes.any,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

const TPReduxTinyEditor = ({ input, meta, ...others }) => {
  const error = meta.submitFailed && meta.error ? meta.error : null;
  const { onChange, onBlur, value } = input;
  const handleBlur = useCallback(() => onBlur(value), [onBlur, value]);
  return (
    <TPTinyEditor {...input} error={error} {...others} onChange={onChange} onBlur={handleBlur} />
  );
};

export default TPReduxTinyEditor;
