export default {
  loading: {
    pointerEvents: 'none',
    color: 'transparent',
    cursor: 'default',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-9px',
    marginLeft: '-9px',
  },
  progressPrimary: (theme) => ({
    color: theme.palette.text.dark,
  }),
  progressAlternative: (theme) => ({
    color: theme.palette.primary.dark,
  }),
  progressSecondary: (theme) => ({
    color: theme.palette.text.dark,
  }),
  progressLargeSize: {
    marginTop: '-12px',
    marginLeft: '-12px',
  },
  progressSmallSize: {
    marginTop: '-7px',
    marginLeft: '-7px',
  },
  textNoWrap: {
    whiteSpace: 'nowrap',
  },
  iconDefault: (theme) => ({
    width: 18,
    marginRight: theme.spacing(0.5),
  }),
  iconSmall: (theme) => ({
    width: 14,
    marginRight: theme.spacing(0.25),
  }),
  iconLarge: (theme) => ({
    width: 22,
    marginRight: theme.spacing(0.75),
  }),
};
