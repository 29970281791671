import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'material-latest';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

import { SIZES } from 'components/TP-UI/constants';

import styles from './styles';

const TRENDING_SETTINGS = {
  flat: { className: 'flat', Icon: TrendingFlatIcon },
  up: { className: 'up', Icon: CallMadeIcon },
  down: { className: 'down', Icon: CallReceivedIcon },
};

const TPTrendingIcon = ({ trending, size = '', className }) => {
  const { className: iconClassName, Icon } = TRENDING_SETTINGS[trending];

  return Icon ? (
    <Box
      component="span"
      sx={[
        styles.trendingContainer,
        styles[iconClassName],
        size && styles[`${size}Size`],
        className,
      ]}>
      <Icon color="inherit" fontSize="inherit" />
    </Box>
  ) : null;
};

TPTrendingIcon.propTypes = {
  trending: PropTypes.oneOf(['down', 'up', 'flat']),
  size: PropTypes.oneOf(['', SIZES.SMALL, SIZES.MEDIUM, SIZES.LARGE, SIZES.XLARGE]),
};

export default TPTrendingIcon;
