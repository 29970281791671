export default {
  numberContainer: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  up: (theme) => ({
    ...theme.numberDifference.up,
  }),
  down: (theme) => ({
    ...theme.numberDifference.down,
  }),
  flat: (theme) => ({
    ...theme.numberDifference.flat,
  }),
  smallSize: (theme) => ({
    ...theme.numberDifference.smallSize,
  }),
  mediumSize: (theme) => ({
    ...theme.numberDifference.mediumSize,
  }),
  largeSize: (theme) => ({
    ...theme.numberDifference.largeSize,
  }),
  startAdornment: (theme) => ({
    paddingRight: theme.spacing(1),
  }),
  endAdornment: (theme) => ({
    paddingLeft: theme.spacing(1),
  }),
};
