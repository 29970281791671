import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const TableList = ({
  classes,
  tableFields,
  tableItem,
  ExpandedRowContent,
  onExpand,
  isExpanded,
  onClick = () => {},
  rowIndex,
  className,
  isHeadRow,
}) => {
  return (
    <div
      className={classNames([classes.itemContainer, className])}
      onClick={() => onClick(tableItem)}>
      {ExpandedRowContent ? (
        <button onClick={onExpand} className={classes.fieldsContainer}>
          {tableFields.map(({ Component, ...otherProps }, index) => (
            <Component
              key={index}
              {...otherProps}
              tableItem={tableItem}
              rowIndex={rowIndex}
              isHeadRow={isHeadRow}
            />
          ))}
        </button>
      ) : (
        <div className={classes.fieldsContainer}>
          {tableFields.map(({ Component, ...otherProps }, index) => (
            <Component
              key={index}
              {...otherProps}
              tableItem={tableItem}
              rowIndex={rowIndex}
              isHeadRow={isHeadRow}
            />
          ))}
        </div>
      )}
      {isExpanded && ExpandedRowContent && <ExpandedRowContent tableItem={tableItem} />}
    </div>
  );
};

TableList.propTypes = {
  tableFields: PropTypes.array.isRequired,
  tableItem: PropTypes.object.isRequired,
  onExpand: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(TableList);
