import { createSelector } from 'reselect';
import {
  allowCopyAccount,
  getIsAdminRole,
  getIsAllowedUpgradeToPro,
  getIsCountryManager,
  showMigrateMyAccount,
} from '../../auth/selectors';
import { MENU_ITEM_TYPE } from '../constants';
import toolsIcon from 'static/ico_tools.svg';
import { Routes } from '../../../constants/routeConstants';
import { Roles } from '../../../constants/userRoles';
import verificationIcon from 'static/ico_id_verification.svg';
import mailManageIcon from 'static/mail_manage.svg';
import userManagementIcon from 'static/ico_user_management.svg';
import { default as migrateMyAccountIcon, default as accountsIcon } from 'static/ico_accounts.svg';

import iconAccounts from 'static/menu/accounts.svg';
import iconDownloads from 'static/menu/downloads.svg';
import iconPayments from 'static/menu/payments.svg';
import iconProfile from 'static/menu/profile.svg';
import iconReduceFees from 'static/menu/reduceFees.svg';
import iconSponsoredVps from 'static/menu/sponsoredVps.svg';
import iconTradingCalculators from 'static/menu/tradingCalculators.svg';

import swapCalls from 'static/menu/swap_calls.svg';
import verifiedUser from 'static/menu/verified_user.svg';
import assignmentLate from 'static/menu/assignment_late.svg';
import notificationsActive from 'static/menu/notifications_active.svg';
import gpDbad from 'static/menu/fmd_bad.svg';
import emojiHourglass from 'static/menu/hourglass_empty.svg';
import notListedLocation from 'static/menu/not_listed_location.svg';
import tune from 'static/menu/tune.svg';
import isMobile from 'utils/isMobile';

export const getMenuItemsList = createSelector(
  getIsAllowedUpgradeToPro,
  allowCopyAccount,
  showMigrateMyAccount,
  getIsCountryManager,
  getIsAdminRole,
  (isAllowedUpgrateToPro, isCountryManager) => [
    {
      id: 'dashboard',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: 'Dashboard',
      icon: toolsIcon,
      url: Routes.DASHBOARD,
      access: [Roles.ADMIN],
      mainMenuItem: true,
      visible: true,
      chinaManagerAllow: isCountryManager,
    },
    {
      id: 'id_verification',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: 'ID Verification',
      icon: verificationIcon,
      url: Routes.VERIFICATION_MANAGEMENT,
      access: [Roles.ADMIN, Roles.MANAGER],
      mainMenuItem: true,
      visible: true,
    },
    {
      id: 'payment_management',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: 'Payment Management',
      icon: iconPayments,
      url: Routes.PAYMENT_MANAGEMENT,
      access: [Roles.ADMIN, Roles.MANAGER],
      mainMenuItem: true,
      visible: true,
    },
    {
      id: 'email_management',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: 'Email Management',
      icon: mailManageIcon,
      url: Routes.EMAIL_MANAGEMENT,
      access: [Roles.ADMIN, Roles.MANAGER],
      mainMenuItem: false,
      visible: true,
    },
    {
      id: 'user_management',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: 'User Management',
      icon: userManagementIcon,
      url: Routes.USER_MANAGEMENT,
      access: [Roles.ADMIN, Roles.MANAGER],
      mainMenuItem: false,
      visible: true,
    },
    {
      id: 'operations',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: 'Operations',
      icon: accountsIcon,
      url: Routes.OPERATIONS,
      access: [Roles.ADMIN],
      mainMenuItem: false,
      visible: true,
    },
    {
      id: 'settingsMenu',
      type: MENU_ITEM_TYPE.NESTED,
      label: 'Settings',
      icon: iconProfile,
      access: [Roles.MANAGER, Roles.ADMIN],
      mainMenuItem: false,
      visible: true,
      items: [
        {
          id: 'migration_requests',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'Migration requests',
          icon: swapCalls,
          url: Routes.MIGRATION_REQUESTS,
          access: [Roles.ADMIN, Roles.MANAGER],
          visible: true,
        },
        {
          id: 'security',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'Security',
          icon: verifiedUser,
          url: Routes.SECURITY,
          access: [Roles.ADMIN],
          visible: true,
        },
        {
          id: 'pageWarning',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'Page Warning',
          icon: assignmentLate,
          url: Routes.PAGE_WARNING,
          access: [Roles.ADMIN],
          visible: true,
        },
        {
          id: 'notifications',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'Notifications',
          icon: notificationsActive,
          url: Routes.NOTIFICATIONS,
          access: [Roles.ADMIN],
          visible: true,
        },
        {
          id: 'riskGroup',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'Risk Group',
          icon: gpDbad,
          url: Routes.RISK_GROUP,
          access: [Roles.ADMIN],
          visible: true,
        },
        {
          id: 'paymentQueue',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'Queue',
          icon: emojiHourglass,
          url: Routes.PAYMENT_QUEUE,
          access: [Roles.ADMIN],
          visible: true,
        },
        {
          id: 'questions',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'Custom Fields',
          icon: notListedLocation,
          url: Routes.CUSTOM_FIELDS,
          access: [Roles.ADMIN],
          visible: true,
        },
        {
          id: 'settings',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'Settings',
          icon: tune,
          url: Routes.SETTINGS,
          access: [Roles.MANAGER, Roles.ADMIN],
          visible: true,
        },
      ],
    },
    {
      id: 'accounts',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: { key: 'menu:category.accounts' },
      icon: iconAccounts,
      url: Routes.MY_ACCOUNTS,
      access: [Roles.CLIENT],
      mainMenuItem: true,
      visible: true,
    },
    {
      id: 'payments',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: { key: 'menu:category.payments' },
      icon: iconPayments,
      url: Routes.PAYMENTS,
      access: [Roles.CLIENT],
      mainMenuItem: true,
      visible: true,
    },
    {
      id: 'vps',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: { key: 'menu:category.vps' },
      icon: iconSponsoredVps,
      url: Routes.VPS,
      access: [Roles.CLIENT],
      mainMenuItem: false,
      visible: true,
    },
    {
      id: 'tradingCalculators',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: {
        key: isMobile()
          ? 'menu:category.mobileTradingCalculatrs'
          : 'menu:category.tradingCalculators',
      },
      icon: iconTradingCalculators,
      url: Routes.TRADING_CALCULATORS,
      access: [Roles.CLIENT],
      isPublic: true,
      mainMenuItem: true,
      visible: true,
    },
    {
      id: 'downloads',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: { key: 'menu:category.downloads' },
      icon: iconDownloads,
      url: Routes.PLATFORMS,
      access: [Roles.CLIENT],
      mainMenuItem: false,
      visible: true,
    },
    {
      id: 'reduceFees',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: { key: 'menu:category.reduceFees' },
      icon: iconReduceFees,
      url: Routes.REDUCE_FEES,
      access: [Roles.CLIENT],
      mainMenuItem: false,
      visible: false,
    },
    {
      id: 'upgradeToPro',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: { key: 'menu:category.upgradeToPro' },
      icon: migrateMyAccountIcon,
      url: Routes.UPGRADE_TO_PRO_V_2,
      access: [Roles.CLIENT],
      mainMenuItem: false,
      visible: isAllowedUpgrateToPro,
    },
  ],
);
