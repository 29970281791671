import { handleActions } from 'redux-actions';
import get from 'lodash/get';

import { symbolConfigSuccess } from '../actions';
import { getContractSizes } from '../helpers';

const defaultState = {
  specification: {},
  symbols: [],
  contractSizes: [],
};

export default handleActions(
  {
    [symbolConfigSuccess]: (state, action) => {
      const symbols = get(action, 'response.data', []);
      const contractSizes = getContractSizes(symbols);
      const specification = symbols.reduce((res, symbol) => {
        res[symbol.symbol] = {
          currencyPair: symbol.symbol,
          contractSize: symbol.contractSize,
          marginStyle: symbol.marginStyle,
          baseUnit: symbol.baseCurrency,
          pipValue: symbol.pipValue,
          profitUnit: symbol.termCurrency,
          pipMultiplier: 1,
          minFluctuation: Number(Math.pow(10, -symbol.digits).toFixed(symbol.digits)),
        };
        return res;
      }, {});

      return {
        ...state,
        symbols,
        specification,
        contractSizes,
      };
    },
  },
  defaultState,
);
