export default (theme) => ({
  form: {
    minWidth: 404,
    background: theme.signIn.backgroundColor,
    border: theme.signIn.border,
    borderRadius: 6,
    padding: '30px  30px 60px 30px',
    boxShadow: '0 5px 11px 0 rgba(63, 90, 151, 0.06)',
    [theme.breakpoints.down('xs')]: {
      width: 320,
      padding: '24px 32px 24px 32px',
    },
    position: 'relative',
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 36,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 24,
    },
  },
  textField: {
    marginTop: 16,
  },
  formError: {
    ...theme.textError,
    top: 14,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      top: 8,
    },
  },
  errorIcon: {
    fontSize: 12,
  },
  forgotPasswordWrapper: {
    width: '100%',
    textAlign: 'right',
  },
  submitButton: {
    marginTop: 12,
    [theme.breakpoints.down('xs')]: {
      marginTop: 8,
    },
  },
  customLinkWrapper: {
    marginTop: 22,
    textAlign: 'center',
  },
  customLinkText: {
    margin: '0 0 4px 0',
    color: theme.palette.text.main,
    fontSize: 14,
    fontWeight: 300,
  },
  loader: {
    height: 50,
    padding: 20,
    marginTop: 9,
    textAlign: 'center',
  },
  languageContainer: {
    position: 'absolute',
    top: '14px',
    right: '14px',
  },
  legal: {
    width: '100%',
    maxHeight: '40%',
    border: `1px solid ${theme.contentLayout.borderColor}`,
    borderTop: '0',
    fontSize: '16px',
    lineHeight: '24px',
    padding: '10px',
    backgroundColor: theme.contentLayout.backgroundColor,
    marginBottom: '30px',
    color: theme.palette.text.main,
  },
  title: {
    color: theme.palette.text.main,
    fontSize: '28px',
    fontWeight: '300',
    lineHeight: '34px',
    marginBottom: '30px',
  },
});
