const generateArray = (length, offset = 0) =>
  Array.from({ length: length }, (v, i) => ({ label: offset + i + 1, value: offset + i }));

export const generatePageList = (value, pagesAmount, maxDisplayedPagesAmount) => {
  let pages = null;
  if (pagesAmount > maxDisplayedPagesAmount) {
    const firstPartOfPages = Math.ceil(maxDisplayedPagesAmount / 2);
    const secondPartOfPages = maxDisplayedPagesAmount - firstPartOfPages;
    if (value < firstPartOfPages || value + secondPartOfPages > pagesAmount - 1) {
      //displaying values like 1,2,3,4,..., 14,15,16,17
      pages = generateArray(firstPartOfPages);
      pages.push({ label: '...', value: null });
      pages = pages.concat(generateArray(secondPartOfPages, pagesAmount - secondPartOfPages));
    } else {
      //displaying values like 1...6,7,8,9,10,11,...,17
      pages = generateArray(
        maxDisplayedPagesAmount - 3,
        value - Math.floor((maxDisplayedPagesAmount - 3) / 2),
      );
      pages.unshift({ label: '...', value: null });
      pages.unshift({ label: 1, value: 0 });
      pages.push({ label: '...', value: null });
      pages.push({ label: pagesAmount, value: pagesAmount - 1 });
    }
  } else {
    pages = generateArray(pagesAmount);
  }
  return pages;
};
