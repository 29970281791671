import React from 'react';

import TPTypography from 'components/TP-UI/TPTypography';

const TPCardSubtitle = ({ variant = 'body1', color = 'primary', children, ...props }) => {
  return (
    <TPTypography variant={variant} color={color} {...props}>
      {children}
    </TPTypography>
  );
};

export default TPCardSubtitle;
