import { palette } from '../../../../components/CustomThemeProvider/themes/palette';

const styles = () => ({
  menuListItem: {
    flex: 1,
    justifyContent: 'flex-start',
    height: '60px',
    boxSizing: 'border-box',
    '&:hover': {
      background: 'none',
    },
  },
  menuListItemCollapsed: {
    justifyContent: 'center',
    height: '40px',
    padding: '0 16px',
  },
  menuListItemSelected: {
    backgroundColor: `${palette.sideMenuItemBackground} !important`,
    '& $menuListItemText': {
      fontWeight: 'bold',
      color: palette.black,
    },
    '& $checked': {
      width: '4px',
    },
  },
  menuListItemText: {
    fontSize: '16px',
    lineHeight: '19px',
    color: palette.black,
  },
  checked: {
    width: '0px',
    height: '100%',
    background: palette.sideMenuItemBackground,
    position: 'absolute',
    left: 0,
    top: 0,
  },
  iconContainerCollapsed: {
    marginRight: 0,
  },
  icon: {
    width: 20,
    height: 20,
  },
  textContainer: {
    paddingLeft: '0',
    whiteSpace: 'normal',
  },
  linkContainer: {
    textDecoration: 'none',
    '&:focus, &:hover, &:visited, &:link, &:active': {
      textDecoration: 'none',
    },
  },
});

export default styles;
