const styles = (theme) => ({
  label: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: 1.2,
    color: theme.palette.simpleGrey,
  },
  cellLabel: {
    '@media (min-width:1366px)': {
      display: 'none',
    },
  },
  dataText: {
    color: theme.palette.text.main,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '19px',
  },
  item: {
    padding: 16,
    height: 80,
    '@media (min-width:400px)': {
      height: 'auto',
    },
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  statusImage: {
    marginRight: 9,
  },
  dotImage: {
    marginRight: 9,
    width: 14,
  },
});

export default styles;
