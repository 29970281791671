import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import CustomModal from 'modules/common/CustomModal';
import styles from './styles';

const WelcomeMigrationModal = ({ classes, modalName, onCloseModal, userName, destinationHub }) => {
  const { t } = useTranslation('auth');
  return (
    <CustomModal
      title={t('welcomeMigrationModal.title', { destinationHub })}
      text={
        userName
          ? t('welcomeMigrationModal.messageWithName', { userName, destinationHub })
          : t('welcomeMigrationModal.message', { destinationHub })
      }
      redirect={onCloseModal}
      isCloseButtonNeeded
      modalName={modalName}
      className={classes.rejectionModal}></CustomModal>
  );
};

WelcomeMigrationModal.propTypes = {
  userName: PropTypes.string,
  modalName: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  destinationHub: PropTypes.string.isRequired,
};

export default withStyles(styles)(WelcomeMigrationModal);
