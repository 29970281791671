export default {
  root: (theme) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(0.25),
  }),
  disabled: (theme) => ({
    color: theme.palette.dividerCustom.lighter,
  }),
  iconFilled: (theme) => ({
    color: theme.palette.warning.dark,
  }),
  iconEmpty: (theme) => ({
    color: theme.palette.text.dark,
  }),
  iconHovered: (theme) => ({
    cursor: 'pointer',
    color: theme.palette.warning.dark,
  }),
  errorContainer: (theme) => ({
    minHeight: theme.textError.root.minHeight,
  }),
};
