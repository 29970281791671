import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import Picker from 'modules/common/Picker';
import { required } from '../../../../utils/validation/fieldValidationRules';
import { Typography } from '@material-ui/core';
import classnames from 'classnames';

const TableCheckboxField = ({
  classes,
  label,
  dataLabel,
  tableItem,
  dataValues,
  showLabel,
  isHeadRow,
}) => {
  if (showLabel && isHeadRow) {
    return (
      <div className={classnames(classes.itemWrapper, classes.item)}>
        <Typography className={classes.title}>{label}</Typography>
      </div>
    );
  }

  return (
    <div className={classes.item}>
      <Field
        name={tableItem[dataLabel]}
        component={Picker}
        label={label}
        values={dataValues}
        validate={[required]}
        placeholder={label}
      />
    </div>
  );
};

TableCheckboxField.propTypes = {
  label: PropTypes.string,
  dataLabel: PropTypes.string,
  dataValues: PropTypes.array,
  tableItem: PropTypes.object.isRequired,
  showLabel: PropTypes.bool,
};

export default compose(
  reduxForm({ form: 'TABLE_DROPDOWN_FORM' }),
  withStyles(styles),
)(TableCheckboxField);
