import api from 'modules/api/index';

export const getSwapsRequest = (payload) => {
  const { isDemo } = payload;
  return api.get(`/swap?isDemo=${isDemo}`, payload);
};

export const updateSwapsRequest = (payload) => {
  const { isDemo, newSwaps } = payload;
  return api.put(`/swap?isDemo=${isDemo}`, { swaps: newSwaps });
};

export const getMigrateToIbRequest = (payload) => {
  return api.post(`/migration/get-migrate-to-ib`, payload);
};

export const operationsBulkMigrateToIbRequest = (payload) => {
  return api.post(`/migration/bulk-migrate-to-ib`, { data: payload });
};
