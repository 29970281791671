import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import translateLabelsInArray from 'i18n/helpers/translateLabelsInArray';
import { TRADE_VALUES } from '../../constants';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';

import Picker from 'modules/common/Picker';
import TextInput from 'modules/common/TextInput';
import PrimaryButton from 'modules/common/PrimaryButton';
import { required, number } from 'utils/validation/fieldValidationRules';

import styles from './styles';
import Results from '../Results';
import SymbolPicker from '../SymbolPicker';
import * as authSelectors from 'modules/auth/selectors';
import { Box, Icon, Typography, Grid } from 'material-latest';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const ProfitLossCalculatorForm = ({
  isPending,
  contractSizes,
  results,
  handleSubmit,
  symbols,
  form,
}) => {
  const { t, i18n } = useTranslation('tradingCalculators');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tradeValues = useMemo(() => translateLabelsInArray(TRADE_VALUES), [i18n.language], []);
  const currencies = useSelector(authSelectors.getUserCurrencies);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.headerContainer}>
        <Icon sx={styles.headerIcon} component={InfoOutlinedIcon} />
        <Typography sx={styles.headerText}>{t('profitLossCalculator.description')}</Typography>
      </Box>
      <Box container sx={styles.formContainer}>
        <Box component="form" sx={styles.form} onSubmit={handleSubmit}>
          <Box>
            <Typography sx={styles.title}>Calculator</Typography>
            <Typography sx={styles.description}>{t('formTitle')}</Typography>
            <Grid container gap={2}>
              <Grid item xs={12}>
                <SymbolPicker symbols={symbols} form={form} />
              </Grid>
              <Grid item xs={12} md={5.7} sx={styles.field}>
                <Field
                  label={t('fields.baseCurrency')}
                  placeholder={t('placeholders.baseCurrency')}
                  name="baseCurrency"
                  validate={[required]}
                  component={Picker}
                  values={currencies}
                />
              </Grid>
              <Grid item xs={12} md={5.7} sx={styles.field}>
                <Field
                  label={t('fields.contractSize')}
                  placeholder={t('placeholders.contractSize')}
                  name="contractSize"
                  component={Picker}
                  values={contractSizes}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={5.7} sx={styles.field}>
                <Field
                  label={t('fields.openPrice')}
                  placeholder={t('placeholders.openPrice')}
                  endAdornment="$"
                  name="openPrice"
                  type="text"
                  validate={[number, required]}
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} md={5.7} sx={styles.field}>
                <Field
                  label={t('fields.closePrice')}
                  placeholder={t('placeholders.closePrice')}
                  endAdornment="$"
                  name="closePrice"
                  type="text"
                  validate={[number, required]}
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} md={5.7} sx={styles.field}>
                <Field
                  label={t('fields.volumeInLots')}
                  placeholder={t('placeholders.volumeInLots')}
                  name="volumeInLots"
                  type="text"
                  validate={[number, required]}
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} md={5.7} sx={styles.field}>
                <Field
                  label={t('fields.tradeType')}
                  placeholder={t('placeholders.tradeType')}
                  name="tradeType"
                  component={Picker}
                  values={tradeValues}
                />
              </Grid>
              <Grid item xs={12} sx={styles.buttonField}>
                <PrimaryButton
                  color="primary"
                  label={t('buttons.calculate')}
                  type="submit"
                  size="large"
                  isPending={isPending}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Results results={results} />
      </Box>
    </Box>
  );
};

ProfitLossCalculatorForm.propTypes = {
  isPending: PropTypes.bool.isRequired,
  symbols: PropTypes.array.isRequired,
  contractSizes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
  form: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm()(ProfitLossCalculatorForm);
