const styles = () => ({
  deleteContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 0',
    '@media (min-width:768px)': {
      minWidth: '200px',
      maxWidth: '200px',
    },
    '@media (max-width:768px)': {
      minWidth: '100px',
      maxWidth: '100px',
    },
  },
});

export default styles;
