import React from 'react';
import PropTypes from 'prop-types';

import { Avatar } from 'material-latest';
import { FLAG_IMG_BASE_URL } from './config';

import styles from './styles';

const TPFlagIcon = ({ label, countryCode, className }) => {
  const src = countryCode
    ? `${FLAG_IMG_BASE_URL}/${String(countryCode).toLowerCase()}.svg`
    : label?.charAt(0) ?? '';
  return <Avatar variant="square" sx={[styles.flagIcon, className]} alt={label} src={src} />;
};

TPFlagIcon.propTypes = {
  label: PropTypes.string,
  countryCode: PropTypes.string, //e.g. BE
};

export default TPFlagIcon;
