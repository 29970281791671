import { palette } from '../../../components/CustomThemeProvider/themes/palette';

export const styles = {
  avatarWrapper: {
    backgroundColor: palette.lightGreen,
    color: palette.black,
    cursor: 'pointer',
  },
  checkMark: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
};
