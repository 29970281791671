import React, { Suspense, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MainLayout from 'modules/common/MainLayout';
import Breadcrumbs from 'modules/common/Breadcrumbs';
import * as selectors from '../selectors';
import { Routes } from 'constants/routeConstants';
import * as actions from '../actions';
import { push } from 'connected-react-router';
import PayIDMonoovaPayment from '../components/PayIDMonoovaPayment';

const DepositFinmoContainer = () => {
  const { t } = useTranslation('payments');
  const dispatch = useDispatch();
  const monoovaData = useSelector(selectors.getMonoova);

  useEffect(() => {}, [dispatch]);

  useEffect(() => {
    if (!monoovaData._id) {
      dispatch(push(Routes.PAYMENTS));
    }
  }, [monoovaData, dispatch]);

  const handleCopyBankInfo = useCallback(() => {
    dispatch(actions.copyBankwireInfo());
  }, [dispatch]);

  return (
    <MainLayout title={t('deposit.monoova.payId')}>
      <Breadcrumbs
        routes={[
          { label: t('deposit.deposit'), link: Routes.PAYMENTS },
          {
            label: t('deposit.monoova.title'),
            link: '',
          },
        ]}
      />
      <Suspense>
        <PayIDMonoovaPayment data={monoovaData} onCopy={handleCopyBankInfo} />
      </Suspense>
    </MainLayout>
  );
};

export default withRouter(DepositFinmoContainer);
