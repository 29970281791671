import blueLogoGP from 'static/global-prime_logo.svg';
import backgroundGP from 'static/signUpBackground.png';

import { MIGRATION_DESTINATIONS_VALUES_GPX } from 'modules/userManagement/pages/UserDetails/constants';

const TESTING_CONFIG = {
  CURRENT_HUB_LABEL: 'Global Prime',
  HOST: 'https://globalprime-staging.com',
  AVAILABLE_LANGUAGES: ['en', 'th', 'ch', 'chm', 'id', 'jp'],
  MAINTENANCE_BYPASS_TOKEN: '',
  LOGO: {
    blue: blueLogoGP,
  },
  LOGIN_BACKGROUND: backgroundGP,
  IS_CLIM: process.env.REACT_APP_COMPANY === 'Clim',
  MIGRATION_DESTINATIONS_VALUES: MIGRATION_DESTINATIONS_VALUES_GPX,
  API_HOST: 'https://api.globalprime-staging.com',
  HUBS: [
    {
      KEY: 'GLOBAL_PRIME',
      LABEL: 'Global Prime',
      SIGN_IN_URL: 'https://secure.globalprime-staging.com/hub/auth/sign-in',
      SIGN_UP_URL: 'https://secure.globalprime-staging.com/hub/auth/sign-up',
    },
    {
      KEY: 'GLOBAL_PRIME_X',
      LABEL: 'Global Prime X',
      SIGN_IN_URL: 'https://secure.globalprime-staging.com/hub/auth/sign-in',
      SIGN_UP_URL: 'https://secure.globalprime-staging.com/hub/auth/sign-up',
    },
  ],
  GOOGLE_TAG_MANAGER: {
    ID: 'GTM-TEST',
    THAI_ID: 'GTM-THAI-TEST',
  },
  RESTRICTED_COUNTRIES: {},
  ACCOUNT_TYPES: [
    {
      i18nValue: 'common:constants.accountTypes.ZERO',
      value: 'ZERO',
    },
    {
      i18nValue: 'common:constants.accountTypes.classic',
      value: 'Classic',
    },
  ],
  SIGNIN_DISCLAIMER: 'disclaimerX',
  DEFAULT_DEPOSIT_METHOD: 'Praxis',
  MIGRATE_ACCOUNT_COUNTRIES: [],
  MIGRATE_ACCOUNT_COUNTRIES_HIDE: ['Australia'],
  HUB_NEED_MIGRATION_REQUEST: 'GLOBAL_PRIME_X',
  UPGRADE_TO_PRO_COUNTRIES: ['Australia'],
  UPGRADE_TO_PRO_PAGE: 'https://globalprime-staging.com/trading-accounts/pro',
  CAPTCHA: {
    client: '6LdXQSUlAAAAAES4U-VTQfAMX83Km003xFJ4QJQ7',
    clientV3: '6LfQpfYpAAAAAOBECDbAmaluKWWPfadBful6hLvP',
  },
  PLACES: {
    API_KEY: 'AIzaSyCYy78CkjHRwGHmkyc-zmwqZWER6c7tgNg',
  },
  GOOGLE_OAUTH: {
    CLIENT_ID: '229843913100-8goddesoaaprvbkeock9g3q0h69p3rlp.apps.googleusercontent.com',
  },
  FOLLOWER_JOIN: 'https://secure.globalprime-staging.com/',
  TRANSLATIONS_NOT_ALLOWED_COUNTRY_CODES: new Set(['AU']),
  TWILIO: {
    ALLOW_IM: ['admin@example.com'],
  },
  TINY_API_KEY: 'hu9vpj5mwa2wc4o970urlwv8qn2j84hbv1e1jbf0xc6llq7z',
};

export default TESTING_CONFIG;
