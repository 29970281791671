import { createAction } from 'redux-actions';

export const getPendingVerificationsRequest = createAction('GET_PENDING_VERIFICATIONS_REQUEST');
export const getPendingVerificationsSuccess = createAction('GET_PENDING_VERIFICATIONS_SUCCESS');
export const getPendingVerificationsFail = createAction('GET_PENDING_VERIFICATIONS_FAIL');

export const getPendingAccountsRequest = createAction('GET_PENDING_ACCOUNTS_REQUEST');
export const getPendingAccountsSuccess = createAction('GET_PENDING_ACCOUNTS_SUCCESS');
export const getPendingAccountsFail = createAction('GET_PENDING_ACCOUNTS_FAIL');

export const approveAccountsRequest = createAction('APPROVE_ACCOUNTS_REQUEST');
export const approveAccountsSuccess = createAction('APPROVE_ACCOUNTS_SUCCESS');
export const approveAccountsFail = createAction('APPROVE_ACCOUNTS_FAIL');

export const declineAccountsRequest = createAction('DECLINE_ACCOUNTS_REQUEST');
export const declineAccountsSuccess = createAction('DECLINE_ACCOUNTS_SUCCESS');
export const declineAccountsFail = createAction('DECLINE_ACCOUNTS_FAIL');

export const getPendingExperienceUploadsRequest = createAction(
  'GET_PENDING_EXPERIENCE_UPLOADS_REQUEST',
);
export const getPendingExperienceUploadsSuccess = createAction(
  'GET_PENDING_EXPERIENCE_UPLOADS_SUCCESS',
);
export const getPendingExperienceUploadsFail = createAction('GET_PENDING_EXPERIENCE_UPLOADS_FAIL');
