import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  MenuItem,
  ListSubheader,
  Paper,
  ClickAwayListener,
  MenuList,
  Popper,
  Grow,
  Box,
} from 'material-latest';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { TPTextField } from 'components/TP-UI/TPTextField';
import { PLACEMENT, SIZES } from 'components/TP-UI/constants';
import TPChip from 'components/TP-UI/TPChip';
import useDebounce from 'hooks/useDebounce';
import { groupOptionsByKey } from '../helpers/mappers';
import { getOption } from '../helpers/options';
import { MENU_MAX_WIDTH } from './constants';

import styles from './styles';

export const TPAutocomplete = ({
  name,
  label,
  value,
  options = [],
  optionValue = 'value',
  optionLabel = 'label',
  returnsObject,
  placeholder = '',
  hint,
  error = null,
  required,
  groupBy,
  grouped,
  multiple = false,
  disabled,
  clearable,
  fullWidth,
  size = SIZES.MEDIUM,
  className,
  renderOption,
  noOptionsText,
  noOptionsFoundText,
  reservedErrorSpace = true,
  autocomplete,
  autofocus = false,
  hideArrow = false,
  hideDetails = false,
  menuPlacement = PLACEMENT.BOTTOM_START,
  startAdornment,
  endAdornment,
  clientSide = true,
  select = false,
  inputProps,
  onBlur,
  onFocus,
  onClick,
  onChange,
  onSearchChange,
}) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm);
  const [filteredDisplayedOptions, setFilteredDisplayedOptions] = useState([]);
  const displayedOptions = useMemo(() => {
    return groupBy ? groupOptionsByKey({ options, key: groupBy }) : options;
  }, [options, groupBy]);
  const selectedOption = useMemo(
    () =>
      typeof value === 'object'
        ? value
        : getOption(displayedOptions, optionValue, value, groupBy || grouped),
    [value, optionValue, displayedOptions, groupBy, grouped],
  );

  const handleChange = useCallback(
    (val) => {
      if (onChange && selectedOption !== val) {
        const newVal = multiple
          ? val.map((v) => (returnsObject ? v : v[optionValue] || v))
          : returnsObject
          ? val
          : (val && val[optionValue]) || '';
        onChange(newVal);
      }
    },
    [onChange, multiple, selectedOption, optionValue, returnsObject],
  );

  useEffect(() => {
    if (selectedOption) {
      setSearchTerm(selectedOption[optionLabel] ?? selectedOption[optionValue] ?? '');
    } else {
      setSearchTerm('');
    }
  }, [selectedOption, optionValue, optionLabel]);

  useEffect(() => {
    if (clientSide && displayedOptions && optionLabel && optionValue) {
      if (select) {
        setFilteredDisplayedOptions(displayedOptions);
      } else {
        const option = getOption(displayedOptions, optionLabel, searchTerm, groupBy || grouped);
        if (!option || open) {
          const filtered =
            groupBy || grouped
              ? displayedOptions.reduce((groups, group) => {
                  const options = group.options.filter((option) =>
                    option[optionLabel]?.toLowerCase().includes(searchTerm.toLowerCase()),
                  );
                  if (options.length > 0) {
                    groups.push({ ...group, options });
                  }
                  return groups;
                }, [])
              : displayedOptions.filter((option) =>
                  option[optionLabel]?.toLowerCase().includes(searchTerm.toLowerCase()),
                );
          setFilteredDisplayedOptions(filtered);
        }
      }
    }
  }, [
    clientSide,
    searchTerm,
    displayedOptions,
    optionLabel,
    optionValue,
    groupBy,
    grouped,
    open,
    select,
  ]);

  useEffect(() => {
    if (onSearchChange) {
      onSearchChange(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, onSearchChange]);

  const handleAddItem = useCallback(
    (val) => {
      if (!multiple) {
        setOpen(false);
      }
      const newVal = multiple ? [...(value || []), val] : val;
      handleChange(newVal);
    },
    [value, multiple, handleChange],
  );

  const handleDeleteItem = useCallback(
    (index) => {
      if (!multiple) {
        setOpen(false);
      }
      const newVal = [...value];
      newVal.splice(index, 1);
      handleChange(newVal);
    },
    [value, multiple, handleChange],
  );

  const handleMenuItemClick = useCallback(
    (item) => {
      if (multiple) {
        const index = (value || []).findIndex((v) => v === item[optionValue]);
        if (index > -1) {
          handleDeleteItem(index);
        } else {
          handleAddItem(item);
        }
      } else {
        handleAddItem(item);
      }
    },
    [value, multiple, optionValue, handleAddItem, handleDeleteItem],
  );

  const handleTextFieldFocus = useCallback(
    (event) => {
      if (!select) {
        setOpen(true);
        setSearchTerm('');
      }
      if (onFocus) {
        onFocus(event);
      }
    },
    [onFocus, select],
  );

  const handleClose = useCallback(
    (event) => {
      if (!ref?.current || event.target === ref.current || ref.current.contains(event.target))
        return;

      setOpen(false);
      let option = searchTerm
        ? getOption(displayedOptions, optionLabel, searchTerm, groupBy || grouped)
        : null;
      if (option) {
        const newVal = multiple ? [...value, option] : option;
        handleChange(newVal);
      } else {
        setSearchTerm((selectedOption && selectedOption[optionLabel]) || '');
      }
      if (onBlur) {
        onBlur(event);
      }
    },
    [
      onBlur,
      value,
      multiple,
      selectedOption,
      searchTerm,
      displayedOptions,
      handleChange,
      optionLabel,
      groupBy,
      grouped,
    ],
  );

  const handleTextFieldClick = useCallback(
    (event) => {
      if (!open) {
        setOpen(true);
        if (!select) {
          setSearchTerm('');
        }
      } else if (select) {
        setOpen(false);
      }
      if (onClick) {
        onClick(event);
      }
    },
    [open, select, onClick],
  );

  const handleTextFieldChange = useCallback(
    (value) => {
      if (open) {
        setSearchTerm(value || '');
      } else {
        const option = value
          ? getOption(displayedOptions, optionLabel, value, groupBy || grouped)
          : null;
        const newVal = multiple ? [...value, option] : option;
        handleChange(newVal);
      }
    },
    [open, multiple, handleChange, displayedOptions, optionLabel, groupBy, grouped],
  );

  const handleTextFieldKeyDown = useCallback((event) => {
    if (event.key === 'ArrowDown') {
      setOpen(true);
      event.preventDefault();
    }
  }, []);

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const textFieldProps = {
    name,
    label,
    placeholder,
    hint,
    hideDetails,
    error,
    required,
    disabled,
    clearable,
    autocomplete,
    autofocus,
    fullWidth,
    size,
    reservedErrorSpace,
    startAdornment,
    endAdornment,
    inputProps,
  };
  const displayedPlaceholder =
    value === '' || Array.isArray(value)
      ? placeholder
      : (selectedOption && selectedOption[optionLabel]?.toString()) || value?.toString();
  const Icon = !hideArrow ? (open ? ArrowDropUpIcon : ArrowDropDownIcon) : null;
  const menuItems = clientSide ? filteredDisplayedOptions : displayedOptions;
  const menuOpened = open && (clientSide || debouncedSearchTerm !== '');
  const showNoOptionsFound = clientSide
    ? options.length > 0 && filteredDisplayedOptions.length === 0
    : debouncedSearchTerm !== '' && displayedOptions.length === 0;

  return (
    <>
      <TPTextField
        {...textFieldProps}
        endAdornment={
          <>
            {endAdornment}
            {Icon && <Icon />}
          </>
        }
        ref={ref}
        value={searchTerm}
        className={[
          select && styles.textFieldSelectMode,
          ...(Array.isArray(className) ? className : [className]),
        ]}
        placeholder={displayedPlaceholder}
        clearable={clearable}
        readonly={select}
        onFocus={handleTextFieldFocus}
        onBlur={onBlur}
        onClick={handleTextFieldClick}
        onChange={handleTextFieldChange}
        onKeyDown={handleTextFieldKeyDown}
        aria-controls={open ? `${name}-menu-list` : undefined}
        aria-haspopup="true"
      />
      <Popper
        open={menuOpened}
        anchorEl={ref?.current}
        role="menu"
        transition
        placement={menuPlacement}
        //need to prevent overflow to avoid cases overlapping text field
        //when the is not enough space to display list of item at the bottom or top
        modifiers={{
          preventOverflow: {
            priority: ['bottom', 'top', 'left', 'right'],
            boundariesElement: 'viewport',
          },
          shift: {
            enabled: true,
          },
        }}
        disablePortal
        style={{
          minWidth: ref?.current?.offsetWidth + 'px' || 'auto',
          maxWidth: Math.max(ref?.current?.offsetWidth, MENU_MAX_WIDTH) + 'px',
        }}
        sx={styles.menuRoot}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === PLACEMENT.BOTTOM_START ? 'center top' : 'center bottom',
            }}>
            <Paper sx={styles.menuPaper} elevation={4}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  variant="selectedMenu"
                  autoFocusItem={select}
                  id={`${name}-menu-list`}
                  disabled={disabled}
                  onKeyDown={handleListKeyDown}>
                  {menuItems && !groupBy && !grouped ? (
                    menuItems.map((item) => (
                      <MenuItem
                        value={item[optionValue]}
                        key={item[optionValue]}
                        disabled={item.disabled}
                        sx={[styles.menuItem, size === SIZES.SMALL && styles.menuItemSmall]}
                        selected={
                          multiple
                            ? value?.includes(item[optionValue])
                            : item[optionValue] === value
                        }
                        onClick={() => handleMenuItemClick(item)}>
                        {renderOption ? renderOption(item) : item[optionLabel]}
                      </MenuItem>
                    ))
                  ) : menuItems ? (
                    menuItems.map((item) => {
                      return [
                        <ListSubheader key={item.label} sx={styles.groupLabel}>
                          {item.label}
                        </ListSubheader>,
                        ...item.options.map((item) => (
                          <MenuItem
                            value={item[optionValue]}
                            key={item[optionValue]}
                            sx={[styles.menuItem, size === SIZES.SMALL && styles.menuItemSmall]}
                            disabled={item.disabled}
                            selected={
                              multiple
                                ? value?.includes(item[optionValue])
                                : item[optionValue] === value
                            }
                            onClick={() => handleMenuItemClick(item)}>
                            {renderOption ? renderOption(item) : item[optionLabel]}
                          </MenuItem>
                        )),
                      ];
                    })
                  ) : (
                    <MenuItem disabled sx={styles.menuItem}>
                      {noOptionsText || 'No options'}
                    </MenuItem>
                  )}
                  {showNoOptionsFound && (
                    <MenuItem sx={styles.menuItem} disabled>
                      {noOptionsFoundText || 'No options found'}
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {multiple && value && value.length ? (
        <Box sx={styles.chips}>
          {value?.map((chip, index) => {
            const opt = returnsObject ? chip : options.find((opt) => opt[optionValue] === chip);
            const label = opt ? (renderOption ? renderOption(opt) : opt[optionLabel]) : chip;
            return (
              <TPChip
                label={label}
                size={SIZES.SMALL}
                key={label + index}
                onDelete={() => handleDeleteItem(index)}
              />
            );
          })}
        </Box>
      ) : null}
    </>
  );
};

TPAutocomplete.muiName = Select.muiName;
TPAutocomplete.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  /**
   * Default object type
   */
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.any,
        value: PropTypes.any,
        disabled: PropTypes.bool,
      }),
    ),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.any,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.any,
            value: PropTypes.any,
            disabled: PropTypes.bool,
          }),
        ),
      }),
    ),
  ]),
  /**
   * Key in option object
   */
  optionValue: PropTypes.string,
  /**
   * Key in option object
   */
  optionLabel: PropTypes.string,
  /**
   * Key in option object to group items
   */
  groupBy: PropTypes.string,
  /**
   * Marked that options is already grouped
   */
  grouped: PropTypes.bool,
  multiple: PropTypes.bool,
  noOptionsText: PropTypes.node,
  noOptionsFoundText: PropTypes.node,
  placeholder: PropTypes.string,
  hint: PropTypes.node,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  size: PropTypes.oneOf([SIZES.MEDIUM, SIZES.SMALL]),
  renderOption: PropTypes.func,
  fullWidth: PropTypes.bool,
  /**
   * Reserved space to display error in 1 line
   */
  reservedErrorSpace: PropTypes.bool,
  autocomplete: PropTypes.string,
  autofocus: PropTypes.bool,
  hideArrow: PropTypes.bool,
  hideDetails: PropTypes.bool,
  menuPlacement: PropTypes.oneOf([
    PLACEMENT.BOTTOM_END,
    PLACEMENT.BOTTOM_START,
    PLACEMENT.BOTTOM,
    PLACEMENT.LEFT_END,
    PLACEMENT.LEFT_START,
    PLACEMENT.LEFT,
    PLACEMENT.RIGHT_END,
    PLACEMENT.RIGHT_START,
    PLACEMENT.RIGHT,
    PLACEMENT.TOP_END,
    PLACEMENT.TOP_START,
  ]),
  /**
   * Props that will be applied to the input element
   */
  inputProps: PropTypes.object,
  /**
   * If set false, means parent component filter options by itself
   */
  clientSide: PropTypes.bool,
  /**
   * If set to true, returns selected item (object) in onChange instead of selected value
   */
  returnsObject: PropTypes.bool,
  className: PropTypes.string,
  startAdornment: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  endAdornment: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  /**
   * If true simulate simple select behavior.
   * Can be used in cases when TPSelect can not be used due to the peculiarities of handling clicks
   * outside the component e.g. inside overlay like datepicker.
   * In other cases please use TPSelect component
   */
  select: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onClick: PropTypes.func,
  /**
   * Calls when search term is changed (debounced)
   */
  onSearchChange: PropTypes.func,
};

const TPReduxAutocomplete = ({ input, meta, ...others }) => {
  const error = meta.submitFailed && meta.error ? meta.error : null;
  const { onChange, onBlur, value } = input;
  const handleBlur = useCallback(() => onBlur(value), [onBlur, value]);
  return (
    <TPAutocomplete {...input} error={error} {...others} onChange={onChange} onBlur={handleBlur} />
  );
};

export default TPReduxAutocomplete;
