import { totalNumbersLabels } from '../constants';

const getTotalNumbersLabel = (response) => {
  const responseKeys = Object.keys(response);
  const possibleKeys = Object.keys(totalNumbersLabels);
  const [totalNumbersLabel] = possibleKeys.filter((totalNumbersLabel) =>
    responseKeys.includes(totalNumbersLabel),
  );
  return totalNumbersLabels[totalNumbersLabel];
};

export default getTotalNumbersLabel;
