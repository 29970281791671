import { handleActions } from 'redux-actions';
import { getAuthorisedPersonsSuccess } from '../actions';

const defaultState = [];

export default handleActions(
  {
    [getAuthorisedPersonsSuccess]: (state, action) => {
      return action.response.data;
    },
  },
  defaultState,
);
