import { combineActions, handleActions } from 'redux-actions';

import * as actionCreators from '../actions';
const defaultState = {};

export default handleActions(
  {
    [combineActions(actionCreators.openSaveBankDetailsModal)](state, action) {
      return action.payload;
    },
  },
  defaultState,
);
