const getVariablesTranslation = (t, values) => {
  if (values) {
    let error = {};
    let errorFields = values.map((obj) => {
      const key = 'key';
      for (let field in obj) {
        if (field !== key && obj[key]) {
          obj[field] = t(obj[key]);
        }
      }
      return obj;
    });
    Object.assign(error, ...errorFields);
    return error;
  }
};

export const getTranslation = (t, error) => {
  if (typeof error === 'object') {
    return t(error.key, getVariablesTranslation(t, error.values));
  }
  return error;
};
