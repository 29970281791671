import React from 'react';
import PropTypes from 'prop-types';
//import classNames from 'classnames';

import styles from './styles';
import { Creatable } from 'react-select';
import PickerMenu from '../PickerMenu';
import GroupHeading from '../GroupHeading';
import IndicatorSeparator from '../IndicatorSeparator';
import DropdownIndicator from '../DropdownIndicator';
import Option from '../Option';
import { withTheme } from '@material-ui/core/styles';

const WrappedPicker = (props) => {
  const {
    values,
    selectedValue,
    onChange,
    dropDownIconClassName,
    //isTopButtonShowed,
    handleTopButtonChange,
    theme,
    menuContainer,
    ...others
  } = props;
  return (
    <Creatable
      {...others}
      isClearable={false}
      options={values}
      value={selectedValue}
      onChange={onChange}
      styles={styles(theme)}
      components={{
        IndicatorSeparator: IndicatorSeparator,
        DropdownIndicator: function Indicator(props) {
          return <DropdownIndicator {...props} dropDownIconClassName={dropDownIconClassName} />;
        },
        Menu: function Picker(props) {
          return (
            <PickerMenu
              {...props}
              handleTopButtonChange={handleTopButtonChange}
              menuContainer={menuContainer}
            />
          );
        },
        GroupHeading: GroupHeading,
        Option: Option,
      }}
    />
  );
};

WrappedPicker.propTypes = {
  handleTopButtonChange: PropTypes.func.isRequired,
  isTopButtonShowed: PropTypes.bool.isRequired,
  dropDownIconClassName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
  selectedValue: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

export default withTheme()(WrappedPicker);
