import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import { ACCOUNT_MARKETING_CHANNEL_TYPES, MARKETING_GROUP_BY } from '../../constants';
import FormSelect from 'modules/common/FormSelect';
import PrimaryButton from 'modules/common/PrimaryButton';
import styles from './styles';

const MarketingFilter = ({ classes, openMarketingExport }) => {
  return (
    <>
      <PrimaryButton
        color="secondary"
        size="small"
        label="Export"
        type="button"
        onClick={openMarketingExport}
        className={classes.button}
      />
      <Field
        name="groupBy"
        id="groupBy"
        component={FormSelect}
        items={MARKETING_GROUP_BY}
        multiple={false}
        className={classes.select}
      />
      <Field
        name="accountType"
        id="accountType"
        component={FormSelect}
        items={ACCOUNT_MARKETING_CHANNEL_TYPES}
        multiple={false}
        className={classes.select}
      />
    </>
  );
};

MarketingFilter.propTypes = {
  openMarketingExport: PropTypes.func.isRequired,
};

export default withStyles(styles)(MarketingFilter);
