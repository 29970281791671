const styles = (theme) => ({
  container: {
    color: theme.proDeclarationModal.text.color,
    fontSize: '16px',
  },
  hideMarker: {
    listStyleType: 'none',
  },
  subTitle: {
    fontWeight: 'bold',
  },
  title: {
    paddingLeft: '3%',
    paddingTop: '3%',
  },
  content: {
    fontWeight: 'normal',
  },
});

export default styles;
