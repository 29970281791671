import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import get from 'lodash/get';

import { createPendingSelector } from 'modules/api/selectors';

import { profitLossCalculatorRequest } from '../actions';
import { PROFIT_LOSS_CALCULATOR_FORM, TRADE_TYPES } from '../constants';
import {
  getCurrencySpecs,
  getPreparedProfitLossResults,
  getCurrencyPairs,
  profitLossFormSelector,
  getContractSizes,
} from '../selectors';

import ProfitLossCalculatorForm from '../components/ProfitLossCalculatorForm';

const ProfitLossCalculatorFormContainer = ({ symbols }) => {
  const dispatch = useDispatch();
  const isPending = useSelector(createPendingSelector(profitLossCalculatorRequest));
  const currencySpecs = useSelector(getCurrencySpecs);
  const results = useSelector(getPreparedProfitLossResults);
  const currencyPairs = useSelector(getCurrencyPairs);
  const currencyPair = useSelector((state) => profitLossFormSelector(state, 'symbol'));
  const contractSizes = useSelector(getContractSizes);

  const currencySpec = useMemo(() => {
    return get(currencySpecs, get(currencyPair, 'value', currencyPair), {});
  }, [currencySpecs, currencyPair]);

  useEffect(() => {
    if (currencySpec.contractSize) {
      dispatch(change(PROFIT_LOSS_CALCULATOR_FORM, 'contractSize', currencySpec.contractSize));
    }
  }, [currencySpec.contractSize, dispatch]);

  const formInitialValues = useMemo(
    () => ({
      tradeType: TRADE_TYPES.BUY,
    }),
    [],
  );

  const handleSubmit = useCallback(
    (payload) => {
      dispatch(
        profitLossCalculatorRequest({
          ...payload,
          symbol: payload.symbol.value,
          marginStyle: currencySpec.marginStyle,
          pipValue: currencySpec.pipValue,
          pipMultiplier: currencySpec.pipMultiplier,
        }),
      );
    },
    [dispatch, currencySpec],
  );

  return (
    <ProfitLossCalculatorForm
      isPending={isPending}
      currencyPairs={currencyPairs}
      contractSizes={contractSizes}
      results={results}
      form={PROFIT_LOSS_CALCULATOR_FORM}
      initialValues={formInitialValues}
      onSubmit={handleSubmit}
      symbols={symbols}
    />
  );
};

export default memo(ProfitLossCalculatorFormContainer);
