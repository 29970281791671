import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = {
  tickets: [],
  totalNumbers: 0,
  ticketDetails: {},
  conversations: [],
};

export default handleActions(
  {
    [actionCreators.getTicketsSuccess](state, action) {
      const { tickets, totalNumber } = action.response.data;
      return { ...state, tickets, totalNumber };
    },
    [actionCreators.getTicketDetailsSuccess](state, action) {
      return { ...state, ticketDetails: action.response.data.ticketDetails };
    },
    [actionCreators.getTicketConversationsSuccess](state, action) {
      const conversations = state.conversations.concat(action.response.data.conversations);
      return { ...state, conversations, isLastPage: action.response.data.isLastPage };
    },
  },
  defaultState,
);
