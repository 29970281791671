import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import * as modalActionCreators from 'modules/common/CustomModal/actions';
import { REJECTION_MODAL } from '../constants';
import * as authActionCreators from 'modules/auth/actions';
import RejectionModal from '../components/RejectionModal';
import { isFirstLoginSinceRejection as isFirstLoginSinceRejectionSelector } from 'modules/auth/selectors';

class RejectionModalContainer extends Component {
  componentDidMount() {
    const { isFirstLoginSinceRejection } = this.props;
    if (!isFirstLoginSinceRejection) {
      return false;
    }
    setTimeout(() => this.props.modalActions.openModal(REJECTION_MODAL), 1000);
  }

  shouldComponentUpdate(props) {
    if (props.isFirstLoginSinceRejection === this.props.isFirstLoginSinceRejection) {
      return false;
    }
    const { isFirstLoginSinceRejection } = props;

    if (!isFirstLoginSinceRejection) {
      return false;
    }
    this.props.modalActions.openModal(REJECTION_MODAL);
    return true;
  }

  closeModal = () => {
    const {
      authActions: { loginSinceRejectionRequest },
      modalActions: { closeModal },
    } = this.props;
    closeModal(REJECTION_MODAL);
    loginSinceRejectionRequest();
  };

  render() {
    const props = {
      modalName: REJECTION_MODAL,
      closeModal: this.closeModal,
    };

    return <RejectionModal {...props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    isFirstLoginSinceRejection: isFirstLoginSinceRejectionSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    modalActions: bindActionCreators(modalActionCreators, dispatch),
    authActions: bindActionCreators(authActionCreators, dispatch),
    push: bindActionCreators(push, dispatch),
  };
};

RejectionModalContainer.propTypes = {
  modalActions: PropTypes.shape({
    toggleModal: PropTypes.func.isRequired,
  }).isRequired,
  authActions: PropTypes.shape({
    loginSinceRejectionRequest: PropTypes.func.isRequired,
  }).isRequired,
  push: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RejectionModalContainer);
