import React from 'react';
import PropTypes from 'prop-types';
import Grid from 'material-latest/Grid';
import Box from 'material-latest/Box';

import noMarchesImg from 'static/no_data_available.svg';
import TPTypography from 'components/TP-UI/TPTypography';

import styles from './styles';

const NoMatches = ({ text }) => {
  return (
    <Grid
      sx={styles.container}
      container
      direction="column"
      justifyContent="center"
      alignItems="center">
      <Grid item>
        <Box component="img" sx={styles.image} src={noMarchesImg} alt={text} />
      </Grid>
      <Grid item>
        <TPTypography variant="body1">{text}</TPTypography>
      </Grid>
    </Grid>
  );
};

NoMatches.propTypes = {
  text: PropTypes.string.isRequired,
};

export default NoMatches;
