import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = '';

export default handleActions(
  {
    [actionCreators.getCountryByIpSuccess](state, { response }) {
      return response.data;
    },
    [actionCreators.getCountryByIpFail]() {
      return defaultState;
    },
  },
  defaultState,
);
