export default {
  error_small: {
    marginLeft: '5px',
  },
  error_medium: {
    marginLeft: '7px',
  },
  errorContainer: (theme) => ({
    minHeight: theme.textError.root.minHeight,
  }),
};
