import React, { useState } from 'react';
import BottomBar from '@material-ui/core/BottomNavigation';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import menuIcon from 'static/ico_menu.svg';
import { getTranslation } from 'utils/compositeTranslationHandler';
import BottomNavigationAction from '../BottomNavigationAction';
import BottomNavigationMenuAction from '../BottomNavigationMenuAction';
import styles from './styles';
import { useSelector } from 'react-redux';
import { getMenuItemsList } from '../../selectors';
import useFilterMenuItems from 'modules/menu/hooks/useFilteredMenuItems';

const BottomNavigation = ({
  classes,
  location,
  isAuthenticated,
  userRole,
  hub,
  userCountry,
  isPro,
}) => {
  const { t } = useTranslation();
  const menuItemsList = useSelector(getMenuItemsList);
  const { mainMenuItems, subMenuItems, selectedItem: defSelectedItem } = useFilterMenuItems(
    menuItemsList,
    isAuthenticated,
    userRole,
    userCountry,
    hub,
    isPro,
    location,
  );
  const [value, setValue] = useState(defSelectedItem);

  return (
    <BottomBar
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}>
      {mainMenuItems.map((item) => (
        <BottomNavigationAction
          id={item.id}
          key={item.id}
          label={getTranslation(t, item.label)}
          icon={item.icon}
          subItems={get(item, 'items', []).filter((item) => item.visible)}
          url={item.url}
        />
      ))}
      <BottomNavigationMenuAction
        label={t('menu:navigation.menu')}
        icon={menuIcon}
        subItems={subMenuItems}
      />
    </BottomBar>
  );
};

export default withRouter(withStyles(styles)(BottomNavigation));
