export const VERIFICATION_MANAGEMENT_USERS_FORM = 'VERIFICATION_MANAGEMENT_USERS_FORM';
export const VERIFICATION_MANAGEMENT_ACCOUNTS_FORM = 'VERIFICATION_MANAGEMENT_ACCOUNTS_FORM';
export const VERIFICATION_MANAGEMENT_FOLLOWUPS_FORM = 'VERIFICATION_MANAGEMENT_FOLLOWUPS_FORM';
export const VERIFICATION_MANAGEMENT_EXPERIENCE_UPLOADS_FORM =
  'VERIFICATION_MANAGEMENT_EXPERIENCE_UPLOADS_FORM';

export const ACCOUNT_CREATION_MODAL = 'ACCOUNT_CREATION_MODAL';

export const DECLINE_POPUP_FORM = 'DECLINE_POPUP_FORM';

export const INITIAL_TABLE_VALUES = {
  asc: false,
};
export const USERS_TABLE_SORT_BY_VALUE = {
  sortBy: 'verification.requestedAt',
};
export const ACCOUNTS_TABLE_SORT_BY_VALUE = {
  sortBy: 'createdAt',
};
export const FOLLOWUPS_TABLE_SORT_BY_VALUE = {
  sortBy: 'date',
};
export const EXPERIENCE_UPLOADS_TABLE_SORT_BY_VALUE = {
  sortBy: 'tradingStatementEvidence.requestedAt',
};

export const EXPERIENCE = {
  TRADING_EXPERIENCE: 'Trading Experience',
  PROFESSIONAL_EXPERIENCE: 'Professional Experience',
};
