import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';

import styles from './styles';
import CustomLink from 'modules/common/CustomLink';
import { useSelector } from 'react-redux';
import { getUserDocuments } from 'modules/auth/selectors';

const SignUpCheckboxLabel = ({ classes }) => {
  const documents = useSelector(getUserDocuments);

  return (
    <BrowserRouter basename="/">
      <p className={classes.footerText}>
        <Trans i18nKey="confirm18Years" ns="auth">
          I confirm I am over 18 years and accept the
          <CustomLink target="_blank" rel="noopener noreferrer" to={documents.terms}>
            Terms and Conditions
          </CustomLink>
        </Trans>
      </p>
    </BrowserRouter>
  );
};

SignUpCheckboxLabel.propTypes = {
  classes: PropTypes.shape({ footerText: PropTypes.string.isRequired }).isRequired,
};

export default withStyles(styles)(SignUpCheckboxLabel);
