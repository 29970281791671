const styles = (theme) => ({
  control: () => ({
    display: 'flex',
    flex: 1,
    width: '100%',
    alignItems: 'center',
    border: 0,
    height: 'auto',
    background: 'transparent',
    '&:hover': {
      boxShadow: 'none',
    },
  }),
  menu: () => ({
    boxShadow: '0 10px 10px 0 rgba(0,0,0,0.13) !important',
    borderRadius: '8px !important',
    overflow: 'hidden',
    boxSizing: 'border-box',
    borderWidth: '1px',
    position: 'absolute',
    left: 0,
    width: '100%',
    zIndex: 10,
  }),
  menuList: () => ({
    backgroundColor: theme.contentLayout.backgroundColor,
  }),
  option: (base) => ({
    ...base,
    height: '38px',
    '&:hover': {
      backgroundColor: theme.picker.hoverBackgroundColor,
      overflow: 'hidden',
    },
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    paddingLeft: '35px',
    color: theme.palette.text.main,
    fontSize: '16px',
    lineHeight: '20px',
    boxSizing: 'border-box',
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: '16px',
    color: theme.palette.text.light,
  }),
  groupHeading: (base) => ({
    ...base,
    position: 'absolute',
    background: 'none',
    color: theme.palette.text.main,
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 'bold',
    marginTop: '9px',
    paddingTop: '0',
  }),
  valueContainer: (base) => ({
    ...base,
    paddingLeft: '0px',
    marginLeft: '-2px',
    paddingTop: 0,
    paddingBottom: 0,
  }),
  noOptionsMessage: (base) => ({
    ...base,
    color: theme.palette.text.main,
    fontSize: '16px',
    lineHeight: '20px',
  }),
  multiValue: (base) => ({
    ...base,
    borderRadius: 4,
    background: 'transparent',
    border: `1px solid ${theme.palette.simpleGrey}`,
    '&:hover': {
      border: `1px solid ${theme.palette.moonstoneBlue}`,
    },
  }),
  singleValue: (base) => ({
    ...base,
    color: theme.palette.text.main,
  }),
  multiValueLabel: (base) => ({
    ...base,
    maxWidth: 128,
    fontSize: 14,
    color: theme.palette.text.main,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: '#B7B7B7',
    borderBottomRightRadius: 4,
    borderTopRightRadius: 4,
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#AC3C5D',
      cursor: 'pointer',
    },
  }),
});

export default styles;
