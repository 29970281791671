const styles = () => ({
  root: {
    marginBottom: 24,
  },
  flexContainer: {
    flexWrap: 'wrap',
  },
  tab: {
    padding: 0,
    height: 38,
    whiteSpace: 'nowrap',
    fontSize: 18,
  },
  label: {
    padding: '6px 21px',
  },
});

export default styles;
