import { palette } from '../palette';
import { fontWeight } from '../../font';

const OverridesMuiStepLabel = {
  styleOverrides: {
    horizontal: {
      paddingLeft: '4px',
      paddingRight: '4px',
    },
    vertical: {
      paddingTop: '4px',
      paddingBottom: '4px',
    },
    iconContainer: {
      paddingRight: 0,
      backgroundColor: 'transparent',
      border: `1px solid ${palette.dividerCustom.lightest}`,
      borderRadius: '50%',
      position: 'relative',
      zIndex: 0,
      /**
       * Using a pseudo-element to provide a consistent circular background behind the icon.
       * This ensures that the background fills out so that the default MUI step icon checkmark svg receives a custom color.
       * The zIndex of -1 ensures that the background stays behind the actual icon.
       * We also set a zIndex on the iconContainer to create a new stacking context, so that the ::before background remains visible, even with a negative zIndex.
       */
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '5%',
        left: '5%',
        width: '90%',
        height: '90%',
        backgroundColor: palette.text.dark,
        borderRadius: '50%',
        zIndex: -1,
      },

      '&.Mui-disabled': {
        border: `1px solid ${palette.primary.lightest}`,
        '& .MuiStepIcon-root': {
          color: palette.background.main,
        },
        '& .MuiStepIcon-text': {
          fontWeight: fontWeight.regular,
        },
      },
    },
  },
};

export default OverridesMuiStepLabel;
