export default {
  selectContainer: {
    minWidth: '56px',
  },
  selectPlaceholder: {
    display: 'flex',
    justifyContent: 'center',
  },
  sign: (theme) => ({
    marginRight: theme.spacing(0.5),
    color: theme.palette.text.secondary,
  }),
  flagIcon: (theme) => ({
    marginRight: theme.spacing(1),
  }),
};
