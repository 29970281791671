const styles = (theme) => ({
  section: {
    width: '100%',
    display: 'flex',
    color: theme.palette.text.main,
    marginTop: 100,
    '@media (max-width:600px)': {
      flexDirection: 'column-reverse',
      marginTop: 0,
    },
  },
  image: {
    pointerEvents: 'none',
    position: 'relative',
    right: '300px',
    top: '-80px',
    width: '80%',
    height: '80%',
    maxWidth: '900px',
    '@media (max-width:880px)': {
      right: '380px',
      top: '-110px',
      width: '90%',
      height: '90%',
    },
    '@media (max-width:600px)': {
      width: '100%',
      right: 0,
      top: 0,
    },
  },
  title: {
    fontSize: 100,
    fontWeight: 300,
    '@media (max-width:600px)': {
      fontSize: 50,
    },
  },
  description: {
    fontSize: 22,
    fontWeight: 300,
    marginBottom: 20,
    maxWidth: 390,
    '@media (max-width:640px)': {
      fontSize: 18,
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width:480px)': {
      flexDirection: 'column-reverse',
      alignItems: 'center',
      justifyItems: 'center',
    },
  },
  buttonsContent: {
    textAlign: 'center',
  },
  arrowLeft: {
    transform: 'scale(-1)',
  },
  previousPageButton: {
    textTransform: 'uppercase',
    color: theme.palette.moonstoneBlue,
    fontSize: 15,
    fontWeight: 900,
    marginRight: 66,
    width: 200,
    '@media (max-width:480px)': {
      marginRight: 0,
    },
  },
  goHomeButton: {
    width: 200,
    marginBottom: 20,
    '@media (max-width:480px)': {
      width: 'auto',
    },
  },
});

export default styles;
