import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = [];

export default handleActions(
  {
    [actionCreators.getFullMyClientsAccountsSuccess](state, action) {
      return !action.payload.persistStoreData ? action.response.data.clients : state;
    },
    [actionCreators.getFullClientsAccountsFail]() {
      return defaultState;
    },
    // [actionCreators.updateFullClients](state, action) {
    //   return action.payload.data.clients;
    // },
  },
  defaultState,
);
