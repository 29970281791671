export const palette = {
  error: { main: '#FE6B6B', input: '#FE6B6B', dark: '#FD1C1C' },
  white: '#FFFFFF',
  lightGreen: '#EFFAD0',
  green: 'rgba(192,248,38, 0.3)',
  green2: '#C0F826',
  black: '#110D3D;',
  simpleGrey: '#9EA1B9',
  sideMenuItemBackground: '#EFFAD0',
  notification: '#FE6B6B',
  bageNotification: '#6FCF97',
  moodyBlue: '#7777C1',
  turquoise: '#4DB5B6',
  spaceCadet: '#152451',
  moonstoneBlue: '#74C3C4',
  icon: {
    black: '#1C1B1F',
    lightBlack: '#37353D',
    darkGray: '#53505B',
    gray: '#6E6A7A',
    lightGrey: '#8A8696',
  },
  divider: {
    darkGray: '#DADFEC',
    Gray: '#DFE3EE',
    lightGray: '#E3E7F1',
    whiteGray: '#ECEFF6',
  },
  success: {
    strongGreen: '#80CA8A',
    green: '#90D199',
    lightGreen: '#A0D7A7',
    strongWhiteGreen: '#B0DEB6',
    whiteGreen: '#C0E4C4',
  },
  error2: {
    strongRed: '#FE6B6B',
    red: '#FE7E7E',
    lightRed: '#FE9090',
    strongWhiteRed: '#FEA3A3',
    whiteRed: '#FFB5B5',
  },
  warning: {
    strongOrange: '#F2994A',
    orange: '#F4A661',
    lightOrange: '#F5B377',
    strongWhiteOrange: '#F7BF8E',
    whiteOrange: '#F8CCA5',
  },
  link: { hover: '#1E176A', outlineColor: '#ACF8E8', active: '#2A2097' },
  textColor: '#110D3D',
  blackText: '#1C1B1F',
  hawkesBlue: '#DADFEC',
  greyBlue: '#DBE0EC',
  whiteBorder: '#F5EFFF',
  lightText: '#9EA1B9',
  grayText: '#282525',
  text: {
    primary: '#110D3D',
  },
};
