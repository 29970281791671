import api from '..';
import getFormData from './getFormData';

export const getNotificationsRequest = ({ type, skip, limit }) => {
  return api.get(`/user-notifications/manage?type=${type}&skip=${skip}&limit=${limit}`);
};

export const createNotificationsRequest = (payload) => {
  const data = { ...payload };
  const formData = getFormData(data);

  let headers = {
    'Content-Type': 'multipart/form-data',
  };

  return api.post('/user-notifications/manage/', formData, headers);
};

export const updateNotificationsRequest = (payload) => {
  const _id = payload._id;
  delete payload._id;
  const formData = getFormData(payload);

  let headers = {
    'Content-Type': 'multipart/form-data',
  };

  return api.put(`/user-notifications/manage/${_id}`, formData, headers);
};

export const deleteNotificationsRequest = ({ _id }) => {
  return api.delete(`/user-notifications/manage/${_id}`);
};

export const getUserNotificationsCountRequest = (userId) => {
  return api.get(`/user-notifications/user/${userId}/count`);
};

export const checkIfNewUserNotificationsRequest = (userId) => {
  return api.get(`/user-notifications/user/${userId}/check`);
};

export const getUserNotificationsRequest = ({ userId, viewAll = false }) => {
  return api.get(`/user-notifications/user/${userId}`, { params: viewAll ? { viewAll } : {} });
};

export const markAsReadRequest = ({ userId, id }) => {
  return api.post(`/user-notifications/user/${userId}/mark-read`, { ids: [id] });
};

export const markAllAsReadRequest = ({ userId, ids }) => {
  return api.post(`/user-notifications/user/${userId}/mark-read`, { ids });
};

export const getPendingNotificationsCountRequest = () => {
  return api.get('/user-notifications/pending-count');
};

export const getUsersVerificationNotificationsCountRequest = () => {
  return api.get('/user-notifications/users-count');
};
export const getAccountsVerificationNotificationsCountRequest = () => {
  return api.get('/user-notifications/accounts-count');
};
export const getFollowupsVerificationNotificationsCountRequest = () => {
  return api.get('/user-notifications/followups-count');
};

export const getExperienceUploadsNotificationsCountRequest = () => {
  return api.get('/user-notifications/evidence-statement-count');
};
