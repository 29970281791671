const styles = (theme) => ({
  item: {
    width: '250px',
    textAlign: 'start',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  label: {
    color: theme.palette.simpleGrey,
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  cellLabel: {
    '@media (min-width:1366px)': {
      display: 'none',
    },
  },
  amountText: {
    color: theme.palette.text.main,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '22px',
    whiteSpace: 'nowrap',
  },
  amountSmallText: {
    fontSize: '15px',
  },
  icon: {
    fontSize: '15px',
    marginRight: '2px',
    width: '',
  },
  amountContainer: {
    width: '50%',
    '@media (max-width:1366px)': {
      width: 'auto',
    },
  },
});

export default styles;
