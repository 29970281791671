export default (theme) => {
  return {
    title: {
      fontWeight: 'bold',
      fontSize: '28px',
      lineHeight: '34px',
      textAlign: 'center',
      color: theme.palette.text.main,
    },
    formWrapper: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: 16,
    },
    container: {
      width: 510,
      background: theme.signIn.backgroundColor,
      border: theme.signIn.border,
      padding: 40,
      [theme.breakpoints.down('xs')]: {
        padding: 16,
        width: '100%',
      },
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },
    wrapper: {
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
        paddingTop: 12,
      },
      width: '100%',
    },
    textField: {
      marginTop: 20,
    },
    conditions: {
      marginTop: 16,
    },
    formError: {
      ...theme.textError,
      top: -22,
      width: '100%',
    },
    errorIcon: {
      fontSize: 12,
    },
    submitButton: {
      marginTop: 12,
      [theme.breakpoints.down('xs')]: {
        marginTop: 8,
      },
    },
    customLinkWrapper: {
      marginTop: 24,
      textAlign: 'center',
    },
    customLinkText: {
      margin: '0 0 4px 0',
      color: theme.palette.text.main,
      fontSize: 14,
      fontWeight: 300,
    },
    languageContainer: {
      position: 'absolute',
      top: '14px',
      right: '14px',
    },
    section: {
      marginTop: 5,
    },
    googleButton: {
      display: 'flex',
      justifyContent: 'center',
    },
    logo: {
      marginBottom: 40,
    },
    image: {
      marginBottom: '70px',
    },
    logoWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    welcomeBack: {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '32px',
      color: theme.palette.textColor,
    },
    welcomeBackDescription: {
      fontWeight: 400,
      fontSize: '16px',
      marginBottom: 20,
      lineHeight: '21px',
      color: theme.palette.textColor,
    },
    inputLabel: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '19px',
      color: theme.palette.simpleGrey,
    },
    or: {
      color: theme.palette.simpleGrey,
      textAlign: 'center',
      marginTop: 10,
    },
    spaceForFooter: {
      minHeight: '70vh',
    },
  };
};
