import React from 'react';
import { useTranslation } from 'react-i18next';
import DocumentTitle from 'react-document-title';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

import MainLayout from 'modules/common/MainLayout';
import NotFoundPageContent from '../NotFoundPageContent';

const NotFoundPage = ({
  classes,
  isUserAuthenticated,
  onReturnToPreviousPage,
  onRedirectToHomePage,
}) => {
  const { t } = useTranslation('notFoundPage');
  const props = {
    onReturnToPreviousPage,
    onRedirectToHomePage,
  };
  return isUserAuthenticated ? (
    <MainLayout
      title={t('title')}
      contentClassName={classes.mainLayoutContainer}
      headerClassName={classes.headerTitle}>
      <DocumentTitle title={t('title')} />
      <NotFoundPageContent {...props} />
    </MainLayout>
  ) : (
    <div className={classes.notFoundPageContentContainer}>
      <NotFoundPageContent {...props} />
    </div>
  );
};

export default withStyles(styles)(NotFoundPage);
