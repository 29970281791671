import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Routes } from '../../../../constants/routeConstants';
import { accountPlanSelector } from '../../../auth/selectors';
import { ACCOUNT_PLANS } from '../../../../constants/acctountPlans';
import verificationModalNames from '../../constants/verificationModalNames';
import CustomModal from '../../../common/CustomModal';
import ImageSubtitleTextContent from '../../../common/ContentModal/ImageSubtitleTextContent';
import { closeModal } from '../../../common/CustomModal/actions';

import quizSuccessImage from '../../../../static/quizzes/quiz-success.svg';
import styles from './styles';
import { getQuizInsightsRequest } from '../../../upgradeToProV2/actions';
import { useLocation } from 'react-router';

const SuccessQuizModal = ({ classes }) => {
  const { t } = useTranslation('quiz');
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedPlan = useSelector(accountPlanSelector);
  const location = useLocation();

  const handleSubmit = useCallback(() => {
    if (selectedPlan === ACCOUNT_PLANS.PRO) {
      if (location.pathname !== Routes.UPGRADE_TO_PRO_V_2) {
        history.push(Routes.UPGRADE_TO_PRO_V_2);
      } else {
        dispatch(getQuizInsightsRequest());
      }
    } else {
      history.push(Routes.MY_ACCOUNTS);
    }
    dispatch(closeModal(verificationModalNames.SUCCESS_QUIZ_MODAL));
  }, [dispatch, history, location, selectedPlan]);

  const buttons = useMemo(
    () => [
      {
        label: t('common:buttons.gotIt'),
        color: 'primary',
        onClick: handleSubmit,
      },
    ],
    [t, handleSubmit],
  );

  return (
    <CustomModal
      modalClassNames={classes.modal}
      className={classes.modalPaper}
      modalName={verificationModalNames.SUCCESS_QUIZ_MODAL}
      isCloseButtonNeeded
      title={t(
        `quiz:${
          selectedPlan === ACCOUNT_PLANS.PRO ? ACCOUNT_PLANS.PRO : ACCOUNT_PLANS.RETAIL
        }.page.title`,
      )}
      customCloseModal={handleSubmit}
      buttons={buttons}>
      <ImageSubtitleTextContent
        image={quizSuccessImage}
        subtitle={t('successQuizModal.title')}
        text={t(`successQuizModal.${selectedPlan || ACCOUNT_PLANS.RETAIL}Description`)}
      />
    </CustomModal>
  );
};

export default withStyles(styles)(SuccessQuizModal);
