const styles = (theme) => ({
  section: {
    display: 'flex',
    maxWidth: 535,
    margin: '0 auto',
    color: theme.palette.text.main,
    flexDirection: 'column',
    alignItems: 'center',
    padding: '100px 16px',
  },
  image: {
    pointerEvents: 'none',
    width: '100%',
  },
  body: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    marginTop: 16,
    marginBottom: 16,
    maxWidth: 1000,
  },
  title: {
    fontSize: 32,
    fontWeight: 600,
    '@media (max-width:600px)': {
      fontSize: 50,
    },
  },
  description: {
    fontSize: 18,
    fontWeight: 300,
  },
  submit: {
    marginTop: 16,
  },
  buttonsContent: {
    textAlign: 'center',
  },
  arrowLeft: {
    transform: 'scale(-1)',
  },
});

export default styles;
