export default (theme) => ({
  fieldset: {
    width: '100%',
  },
  error: {
    ...theme.textError,
    bottom: '-16px',
  },
  errorIcon: {
    fontSize: 12,
  },
});
