import React from 'react';
import { Route, Redirect } from 'react-router';

import { Routes } from 'constants/routeConstants';

import TradingCalculators from 'modules/tradingCalculators';
import MaintenancePage from 'modules/common/Maintenance';

const MaintenanceRedirect = () => <Redirect to={Routes.MAINTENANCE} />;

const AppRoutes = () => (
  <React.Fragment>
    <Route path={Routes.MAINTENANCE} component={MaintenancePage} />
    <Route path={Routes.PUBLIC_CALCULATORS}>
      <TradingCalculators isPublic />
    </Route>
    <Route path="*" component={MaintenanceRedirect} />
  </React.Fragment>
);

export default AppRoutes;
