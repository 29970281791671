import createAction from 'redux-actions/es/createAction';

export const addEmployeeRequest = createAction('ADD_EMPLOYEE_REQUEST');
export const addEmployeeSuccess = createAction('ADD_EMPLOYEE_SUCCESS');
export const addEmployeeFail = createAction('ADD_EMPLOYEE_FAIL');

export const addClientRequest = createAction('ADD_CLIENT_REQUEST');
export const addClientSuccess = createAction('ADD_CLIENT_SUCCESS');
export const addClientFail = createAction('ADD_CLIENT_FAIL');

export const addIBClientRequest = createAction('ADD_I_B_CLIENT_REQUEST');
export const addIBClientSuccess = createAction('ADD_I_B_CLIENT_SUCCESS');
export const addIBClientFail = createAction('ADD_I_B_CLIENT_FAIL');
