import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isMobileOnly } from 'react-device-detect';
import DatePicker from 'react-datepicker';
import { Box } from 'material-latest';

import FormHelperText from 'material-latest/FormHelperText';
import TPErrorMessage from 'components/TP-UI/TPErrorMessage';
import { PLACEMENT, SIZES } from 'components/TP-UI/constants';
import TPDatePickerInput from './components/TPDatePickerInput';
import TPDatePickerHeader from './components/TPDatePickerHeader';
import getValidDate from './helpers/getValidDate';
import { DATE } from './config';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './styles';

export const TPDatePicker = ({
  name,
  dateFormat = DATE.dateFormat,
  value,
  placeholder,
  minDate,
  maxDate,
  required,
  disabled,
  fullWidth,
  popoverPlacement = PLACEMENT.BOTTOM_START,
  hint,
  error,
  reservedErrorSpace = true,
  returnsStartDay = false,
  onChange,
  customInput,
  ...props
}) => {
  const datePickerWrapperRef = useRef(null);
  const [opened, setOpened] = useState(false);

  const handleChange = useCallback(
    (date) => {
      const validDate = getValidDate(date);
      if (validDate) {
        if (returnsStartDay) {
          validDate.setHours(0);
          validDate.setMinutes(0);
          validDate.setSeconds(0);
        } else {
          validDate.setHours(23);
          validDate.setMinutes(59);
          validDate.setSeconds(59);
        }

        onChange(validDate.toISOString());
      } else {
        onChange('');
      }
    },
    [onChange, returnsStartDay],
  );

  const handleCalendarOpen = useCallback(() => {
    setOpened(true);
  }, []);

  const handleCalendarClose = useCallback(() => {
    setOpened(false);
  }, []);

  return (
    <Box
      ref={datePickerWrapperRef}
      sx={[styles.datePickerWrapper, fullWidth && styles.fullWidth, isMobileOnly && styles.mobile]}>
      <DatePicker
        name={name}
        selected={getValidDate(value)}
        dateFormat={dateFormat}
        fixedHeight
        popperPlacement={popoverPlacement}
        minDate={minDate}
        maxDate={maxDate}
        placeholderText={placeholder || new Date().toLocaleDateString('en-GB')}
        disabled={disabled}
        required={required}
        renderCustomHeader={(headerProps) => (
          <TPDatePickerHeader name={name} size={props.size} {...headerProps} />
        )}
        onChange={handleChange}
        onCalendarOpen={handleCalendarOpen}
        onCalendarClose={handleCalendarClose}
        customInput={
          customInput ? (
            customInput
          ) : (
            <TPDatePickerInput
              fullWidth={fullWidth}
              error={error}
              hideDetails
              readonly={isMobileOnly && !opened}
              {...props}
            />
          )
        }
      />
      {hint && <FormHelperText>{hint}</FormHelperText>}
      <Box sx={[reservedErrorSpace && styles.errorContainer]}>
        {error && <TPErrorMessage error={error} size={SIZES.SMALL} />}
      </Box>
    </Box>
  );
};

TPDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  dateFormat: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  placeholder: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  popoverPlacement: PropTypes.oneOf([
    PLACEMENT.TOP_START,
    PLACEMENT.TOP,
    PLACEMENT.TOP_END,
    PLACEMENT.RIGHT_START,
    PLACEMENT.RIGHT,
    PLACEMENT.RIGHT_END,
    PLACEMENT.BOTTOM_START,
    PLACEMENT.BOTTOM,
    PLACEMENT.BOTTOM_END,
    PLACEMENT.LEFT_START,
    PLACEMENT.LEFT,
    PLACEMENT.LEFT_END,
  ]),
  hint: PropTypes.node,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Reserved space to display error in 1 line
   */
  reservedErrorSpace: PropTypes.bool,
  /** Date picker will return start of day (00:00) instead of end of day by default (23:59) **/
  returnsStartDay: PropTypes.bool,
  customInput: PropTypes.node,
  /**
   * Datepicker text field size
   */
  size: PropTypes.oneOf([SIZES.MEDIUM, SIZES.SMALL]),
  onChange: PropTypes.func,
};

const TPReduxDatePicker = ({ input, meta, ...others }) => {
  const error = meta.submitFailed && meta.error ? meta.error : null;
  const { onChange, onBlur, value } = input;
  const handleBlur = useCallback(() => onBlur(value), [onBlur, value]);

  return (
    <TPDatePicker {...input} error={error} {...others} onChange={onChange} onBlur={handleBlur} />
  );
};

export default TPReduxDatePicker;
