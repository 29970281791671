import normal from './light/normal';
import { THEME_NAME } from '../../../constants/theme';

const themes = {
  [THEME_NAME.NORMAL]: normal,
};

export default function getTheme(theme) {
  return themes[theme];
}
