import get from 'lodash/get';
import { createSelector } from 'reselect';

export const getNotifications = (state) => {
  return get(state, 'userNotifications.notifications');
};

export const getMode = (state) => get(state, 'userNotifications.mode');

export const getNotification = (state) => get(state, 'userNotifications.notification');

export const getTotalNumbers = (state) => get(state, 'pagination.totalNumbers.notifications', 0);

export const getUserNotificationsCount = (state) =>
  get(state, 'userNotifications.userNotificationsCount');

export const getUserNotifications = createSelector(
  (state) => state.userNotifications.userNotifications,
  (state) => state.userNotifications.viewedUserNotificationIds,
  (notifications, viewedUserNotificationIds) => {
    return notifications
      .map((notification) => ({
        ...notification,
        viewed: viewedUserNotificationIds.includes(notification._id),
      }))
      .sort((a, b) => a.viewed - b.viewed);
  },
);

export const getPendingNotificationsCount = (state) =>
  get(state, 'userNotifications.pendingNotificationsCount');
export const getUsersVerificationNotificationsCount = (state) =>
  get(state, 'userNotifications.usersVerificationNotificationsCount');
export const getAccountsVerificationNotificationsCount = (state) =>
  get(state, 'userNotifications.accountsVerificationNotificationsCount');
export const getFollowupsVerificationNotificationsCount = (state) =>
  get(state, 'userNotifications.followUpsVerificationNotificationsCount');
export const getExperienceUploadsNotificationsCount = (state) =>
  get(state, 'userNotifications.experienceUploadsNotificationsCount');
