import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';

import styles from './styles';
import ResultsRowsGroup from './ResultsRowsGroup';
import ResultsRow from './ResultsRow';
import { Grid } from 'material-latest';

const Results = ({ classes, results }) => {
  const { t } = useTranslation('tradingCalculators');
  const { plain, withSubRows } = useMemo(
    () =>
      results.reduce(
        (map, result) => {
          if (result.rows) {
            map.withSubRows.push(result);
          } else {
            map.plain.push(result);
          }

          return map;
        },
        { plain: [], withSubRows: [] },
      ),
    [results],
  );

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>{t('resultsTitle')}</Typography>
      <Typography className={classes.description}>See your results below.</Typography>
      <Grid container display="flex" flexDirection={{ xs: 'column', sm: 'row' }} p={0} mt={3}>
        <Grid item container>
          {plain.map((resultItem, index) => (
            <ResultsRow
              key={index}
              label={resultItem.label}
              value={resultItem.value}
              className={classNames({
                [classes.nonGroupResultRow]: !results.some((item) => Array.isArray(item.rows)),
              })}
            />
          ))}
        </Grid>
        {withSubRows.length > 0 && (
          <Grid item container>
            {withSubRows.map((resultItem, index) => (
              <ResultsRowsGroup key={index} title={resultItem.label} rows={resultItem.rows} />
            ))}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

Results.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      PropTypes.shape({
        label: PropTypes.string,
        rows: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          }),
        ),
      }),
    ]),
  ).isRequired,
};

export default memo(withStyles(styles)(Results));
