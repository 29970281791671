import development from './development';
import production from './production';
import productionX from './productionX';
import staging from './staging';
import testing from './testing';

const config = {
  development,
  staging,
  testing,
  production,
  productionX,
};

export default config[process.env.REACT_APP_ENV || 'development'];
