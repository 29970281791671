import { handleActions } from 'redux-actions';

import { TOGGLE_COLLAPSIBLE_SIDEBAR } from '../actions';

const initialState = {
  isCollapsed: false,
};

export const sideBarReducer = handleActions(
  {
    [TOGGLE_COLLAPSIBLE_SIDEBAR]: (state) => {
      return {
        isCollapsed: !state.isCollapsed,
      };
    },
  },
  initialState,
);
