export default {
  root: (theme) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '& > *': {
      paddingBottom: '0 !important',
      marginBottom: '0 !important',
    },
    '& > *:first-child': {
      flex: 1,
      alignSelf: 'center',
      '& > *:last-child': {
        paddingBottom: 0,
      },
    },
  }),
};
