import oneOffBonus from 'static/A-one-off-bonus.svg';
import reducedRate from 'static/reduced-comm.svg';

export const INVITE_FRIEND_FORM = 'INVITE_FRIEND_FORM';

export const BONUS_TYPES_VALUES = [
  {
    i18nValue: 'reduceFees:form.step1.bonus1.title',
    i18nDescription: 'reduceFees:form.step1.bonus1.description',
    i18nLink: 'reduceFees:form.step1.bonus1.link',
    value: 'oneOffBonus',
    image: oneOffBonus,
  },
  {
    i18nValue: 'reduceFees:form.step1.bonus2.title',
    i18nDescription: 'reduceFees:form.step1.bonus2.description',
    value: 'reducedRate',
    image: reducedRate,
  },
];

export const BONUS_TYPES = {
  oneOffBonus: 'oneOffBonus',
  reducedRate: 'reducedRate',
};

export const InvitationStatuses = {
  PENDING: 'Pending',
  ACCEPTED: 'Accepted',
};
