const styles = () => ({
  root: {
    paddingBottom: '50px',
  },
  tabs: {
    width: '100%',
    maxWidth: '1292px',
  },
  tab: {
    padding: 0,
  },
  noVisibleAccounts: {
    margin: '25px 0',
    display: 'flex',
    padding: '20px 50px',
    backgroundColor: '#FFFFFF',
    fontWeight: '100',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '16px',
    boxShadow: '0 5px 11px 0 rgba(63,90,151,0.06)',
  },
  noVisibleAccountsLink: {
    paddingLeft: '0.5em',
    fontWeight: '100',
    fontSize: '16px',
  },
});

export default styles;
