import get from 'lodash/get';

export const getFullName = (user) => {
  const firstName = get(user, 'profile.firstName');
  const lastName = get(user, 'profile.lastName');
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  return user.email;
};

export default getFullName;
