import React from 'react';
import Avatar from 'material-latest/Avatar';

import styles from './styles';

const TPCardImage = ({ src, alt, className, children, ...props }) => {
  return (
    <Avatar variant="rounded" src={src} alt={alt} sx={[styles.root, className]} {...props}>
      {children}
    </Avatar>
  );
};

export default TPCardImage;
