export default (src, scripts) => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.addEventListener('load', () => resolve(script));
    window.document.body.appendChild(script);
    scripts.push(script);
  });
};
