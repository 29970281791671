import { all, takeEvery, put } from 'redux-saga/effects';
import { logoutAsCurrentUser } from '../actions';
import { signinByEmailRequest } from 'modules/userManagement/pages/UserDetails/actions';

function* logoutHandler() {
  const email = localStorage.getItem('adminEmail');
  if (email) {
    localStorage.removeItem('adminEmail');
    yield put(signinByEmailRequest({ email, type: 'admin' }));
  }
}

export default function* watchRequest() {
  yield all([takeEvery(logoutAsCurrentUser, logoutHandler)]);
}
