import { combineActions, handleActions } from 'redux-actions';

import * as actionCreators from '../actions';
const defaultState = { isValid: -1 };

export default handleActions(
  {
    [combineActions(actionCreators.validateAddressSuccess)](state, action) {
      return {
        ...state,
        isValid: action.response.data.isValid,
      };
    },
    [combineActions(actionCreators.feeSuccess)](state, action) {
      return {
        ...state,
        fee: action.response.data,
      };
    },
  },
  defaultState,
);
