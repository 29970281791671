import { createAction } from 'redux-actions';

export const getMfaQrCodeRequest = createAction('GET_MFA_QR_CODE_REQUEST');
export const getMfaQrCodeSuccess = createAction('GET_MFA_QR_CODE_SUCCESS');
export const getMfaQrCodeFail = createAction('GET_MFA_QR_CODE_FAIL');

export const setupMfaRequest = createAction('SETUP_MFA_REQUEST');
export const setupMfaSuccess = createAction('SETUP_MFA_SUCCESS');
export const setupMfaFail = createAction('SETUP_MFA_FAIL');

export const disableMfaRequest = createAction('DISABLE_MFA_REQUEST');
export const disableMfaSuccess = createAction('DISABLE_MFA_SUCCESS');
export const disableMfaFail = createAction('DISABLE_MFA_FAIL');

export const setPanelExpanded = createAction('SET_MFA_SETTINGS_PANEL_EXPANDED');
