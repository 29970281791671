import get from 'lodash/get';
import { handleActions } from 'redux-actions';
import * as actions from '../actions';

const defaultState = {
  recentEntitiesLoading: true,
  recent: [],
  entityIds: [],
  loadingCount: 0,
  articles: [],
  snippets: {},
  entities: [],
};

export default handleActions(
  {
    [actions.getRecentEntitiesRequest]: () => {
      return { ...defaultState };
    },
    [actions.getRecentEntitiesSuccess]: (state, action) => {
      const { entityIds, articles } = action.response.data;
      return { ...state, entityIds, articles, recentEntitiesLoading: entityIds.length };
    },
    [actions.getRecentEntitiesFail]: (state) => {
      return { ...state, recentEntitiesLoading: false, recent: [] };
    },
    [actions.setRecentEntities]: (state, action) => {
      return { ...state, recent: action.payload };
    },
    [actions.getDashboardDataSuccess]: (state, action) => {
      const res = action.response.data;
      const index = state.entityIds.findIndex((e) => e === res.entity.entityId);
      const loadingCount = state.loadingCount + 1;

      return {
        ...state,
        recent: [...state.recent.slice(0, index), res, ...state.recent.slice(index + 1)],
        loadingCount,
        recentEntitiesLoading: loadingCount < state.entityIds.length,
      };
    },
    [actions.getArticleContentRequest]: (state, action) => {
      const { provider, articleId } = action.payload;
      return {
        ...state,
        ...{
          snippets: { ...state.snippets, ...{ [`${provider / articleId}`]: { isLoading: true } } },
        },
      };
    },
    [actions.getArticleContentSuccess]: (state, action) => {
      const { provider, articleId } = action.payload;
      const data = get(action.response, 'data.article[0]');

      return {
        ...state,
        ...{
          snippets: {
            ...state.snippets,
            ...{ [`${provider}/${articleId}`]: { isLoading: false, data } },
          },
        },
      };
    },
    [actions.getArticleContentFail]: (state, action) => {
      const { provider, articleId } = action.payload;
      return {
        ...state,
        ...{
          snippets: {
            ...state.snippets,
            ...{ [`${provider}/${articleId}`]: { isLoading: false, data: '' } },
          },
        },
      };
    },
    [actions.getDiscoverEntitiesSuccess]: (state, action) => {
      return { ...state, entities: action.response.data };
    },
    [actions.getDiscoverEntitiesFail]: (state) => {
      return { ...state, entities: [] };
    },
  },
  defaultState,
);
