import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './styles';

class TooltipComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      arrowRef: null,
    };
  }

  handleArrowRef = (node) => {
    this.setState({
      arrowRef: node,
    });
  };

  render() {
    const {
      classes,
      isOpen,
      handleTooltipOpen,
      handleTooltipClose,
      label,
      value,
      t,
      style,
      containerStyles,
      placement = 'bottom-end',
      isLink = true,
    } = this.props;

    const title = React.isValidElement(value) ? (
      value
    ) : (
      <div dangerouslySetInnerHTML={{ __html: value }} />
    );

    return (
      <span
        className={classNames(containerStyles, {
          [classes.container]: this.props.icon,
        })}>
        {this.props.icon && <HelpOutlineIcon className={classes.helpIcon} fontSize={'small'} />}
        <Tooltip
          open={isOpen}
          onClose={handleTooltipClose}
          style={style}
          title={
            value ? (
              title
            ) : (
              <React.Fragment>
                {t('whyDoWeAsk.messages.secretQuestion')}
                <span className={classes.arrow} ref={this.handleArrowRef} />
              </React.Fragment>
            )
          }
          interactive
          leaveDelay={10}
          classes={{ tooltip: classes.tooltip, popper: classes.arrowPopper }}
          placement={placement}
          disableTouchListener
          PopperProps={{
            popperOptions: {
              modifiers: {
                arrow: {
                  enabled: Boolean(this.state.arrowRef),
                  element: this.state.arrowRef,
                },
              },
            },
          }}>
          {isLink ? (
            <Link onClick={handleTooltipOpen} classes={{ root: classes.link }}>
              {label}
            </Link>
          ) : (
            <span onClick={handleTooltipOpen} className={classes.label}>
              {label}
            </span>
          )}
        </Tooltip>
      </span>
    );
  }
}

TooltipComponent.propTypes = {
  t: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleTooltipOpen: PropTypes.func.isRequired,
  handleTooltipClose: PropTypes.func.isRequired,
  placement: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  classes: PropTypes.shape({
    arrow: PropTypes.string.isRequired,
    arrowPopper: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.bool,
  containerStyles: PropTypes.object,
  isLink: PropTypes.bool,
};

export default withTranslation('common')(withStyles(styles)(TooltipComponent));
