import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { useTranslation } from 'react-i18next';

import styles from './styles';
import ProgressBar from 'modules/common/ProgressBar';
import { startIdVerification } from 'modules/VerificationModalsManager/actions';
import PrimaryButton from '../../../common/PrimaryButton';
import ArrowIcon from './ArrowIcon';

const VerificationProgress = memo(({ classes, progress, isPosted, isNew, onClickContinue }) => {
  const { t } = useTranslation('menu');
  const dispatch = useDispatch();

  const handleContinue = useCallback(() => {
    dispatch(startIdVerification());
    onClickContinue();
  }, [dispatch, onClickContinue]);

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-end"
        className={classes.textContainer}>
        <Typography className={classes.progressText}>
          {isPosted ? t('account.awaitingApproval') : t('account.verificationProgress')}
        </Typography>
      </Grid>
      <div className={classes.progressBarWrapper}>
        <ProgressBar progress={isPosted ? 100 : progress} />
      </div>
      <Typography className={classes.progressTextBold}>{isPosted ? 100 : progress}%</Typography>
      <PrimaryButton
        className={classes.continueButton}
        variant="outlined"
        content={<ArrowIcon />}
        label={isNew ? t('common:buttons.verifyNow') : t('common:buttons.continue')}
        onClick={handleContinue}
      />
    </Grid>
  );
});

VerificationProgress.displayName = 'VerificationProgress';

VerificationProgress.propTypes = {
  progress: PropTypes.number.isRequired,
  isPosted: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  onClickContinue: PropTypes.func.isRequired,
};

export default withStyles(styles)(VerificationProgress);
