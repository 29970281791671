import React from 'react';
import { formValues, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { Box } from 'material-latest';

import TPGrid from 'components/TP-UI/TPGrid';
import TPTypography from 'components/TP-UI/TPTypography';
import TPSlider from 'components/TP-UI/TPSlider';
import TPCard from 'components/TP-UI/TPCard';
import TPCardContent from 'components/TP-UI/TPCard/components/TPCardContent';
import TPCardHeader from 'components/TP-UI/TPCard/components/TPCardHeader';
import TPCardTitle from 'components/TP-UI/TPCard/components/TPCardTitle';

const Sliders = ({ sliderFiles }) => {
  return (
    <Box component="form" noValidate>
      <TPGrid container spacing={1}>
        <TPGrid item xs={8}>
          <TPTypography variant="h6" mb={2}>
            Slider component
          </TPTypography>
          <TPCard fullWidth>
            <TPCardHeader>
              <TPCardTitle semiBild>ID Verification</TPCardTitle>
            </TPCardHeader>
            <TPCardContent divider>
              <TPSlider preview items={sliderFiles} />
            </TPCardContent>
          </TPCard>
        </TPGrid>
      </TPGrid>
    </Box>
  );
};

export default compose(
  reduxForm({
    form: 'TP-SLIDERS-FORM',
    initialValues: {
      sliderFiles: [
        {
          _id: '643e89a8c96b74621417adef',
          url:
            'https://fusionmarkets-staging.s3.ap-southeast-2.amazonaws.com/1681820069769_Client_new_account_id_verification_button_shown_not_clickable.JPG?AWSAccessKeyId=AKIA4KRP45JNWM5ZRBD4&Expires=1692115646&Signature=bMr7vqdYN28jZxBTtnOjbKVNvwk%3D',
          mimeType: 'image/jpeg',
          originalName: 'Client_new_account_id_verification_button_shown_not_clickable.JPG',
          size: 48202,
          key: '1681820069769_Client_new_account_id_verification_button_shown_not_clickable.JPG',
        },
        {
          _id: '643e89a8c96b74621417adf0',
          url:
            'https://fusionmarkets-staging.s3.ap-southeast-2.amazonaws.com/1681820069779_driver_license_back.PNG?AWSAccessKeyId=AKIA4KRP45JNWM5ZRBD4&Expires=1692115646&Signature=Uyth%2F8URUSEk%2B%2F1O5LozYoVC5SI%3D',
          mimeType: 'image/png',
          originalName: 'driver_license_back.PNG',
          size: 264082,
          key: '1681820069779_driver_license_back.PNG',
        },
        {
          _id: '643e89a8c96b74621417adf0',
          url:
            'https://fusionmarkets-staging.s3.ap-southeast-2.amazonaws.com/1681820069779_driver_license_back.PNG?AWSAccessKeyId=AKIA4KRP45JNWM5ZRBD4&Expires=1692710124&Signature=N4bAWtBbLSskppT%2Fo28LVJOqNuQ%3D',
          mimeType: 'image/png',
          originalName: 'driver_license_back.PNG',
          size: 264082,
          key: '1681820069779_driver_license_back.PNG',
        },
        {
          _id: '643e89a8c96b74621417ades',
          url:
            'https://fusionmarkets-staging.s3.ap-southeast-2.amazonaws.com/1681820069769_Client_new_account_id_verification_button_shown_not_clickable.JPG?AWSAccessKeyId=AKIA4KRP45JNWM5ZRBD4&Expires=1692115646&Signature=bMr7vqdYN28jZxBTtnOjbKVNvwk%3D',
          mimeType: 'image/jpeg',
          originalName: 'Client_new_account_id_verification_button_shown_not_clickable_copy.JPG',
          size: 48202,
          key: '1681820069769_Client_new_account_id_verification_button_shown_not_clickable.JPG',
        },
        {
          _id: '643e89a8c96b74621417aaf1',
          url:
            'https://fusionmarkets-staging.s3.ap-southeast-2.amazonaws.com/1681820069779_driver_license_back.PNG?AWSAccessKeyId=AKIA4KRP45JNWM5ZRBD4&Expires=1692115646&Signature=Uyth%2F8URUSEk%2B%2F1O5LozYoVC5SI%3D',
          mimeType: 'image/png',
          originalName: 'driver_license_back_copy.PNG',
          size: 264082,
          key: '1681820069779_driver_license_back.PNG',
        },
        {
          _id: '643e89a8c96b74621417a2f2',
          url:
            'https://fusionmarkets-staging.s3.ap-southeast-2.amazonaws.com/1681820069788_driver_license_front.PNG?AWSAccessKeyId=AKIA4KRP45JNWM5ZRBD4&Expires=1692115646&Signature=rLgs%2BaU2u176KcTvI7NcRePOmRw%3D',
          mimeType: 'image/png',
          originalName: 'driver_license_front_copy.PNG',
          size: 333130,
          key: '1681820069788_driver_license_front.PNG',
        },
      ],
    },
  }),
  formValues('sliderFiles'),
)(Sliders);
