import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import styles from './styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextGroup from 'modules/common/TextGroup/';
import { HorizontalBar } from 'react-chartjs-2';
import Header from '../../components/BaseHeader';
import noMatchesFound from '../../../../static/no_matches_found.svg';
import { palette } from 'components/CustomThemeProvider/themes/palette';
import Spinner from '../../../common/Spinner';

const CountryUsersPage = ({ classes, isCountryUsersGetPending, headerProps, items = [] }) => {
  const labels = items.map((i) => i.name);
  const data = items.map((i) => i.volume);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      onClick: () => {},
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            offsetGridLines: false,
          },
        },
      ],
    },
  };

  const dataset = {
    labels,
    datasets: [
      {
        label: 'Volume ($ million)',
        data,
        type: 'horizontalBar',
        backgroundColor: palette.green2,
        borderColor: 'rgb(54,93,235)',
        height: 6,
      },
    ],
  };

  const getPostFix = (index) => {
    switch (index) {
      case 0:
        return 'st';
      case 1:
        return 'nd';
      case 2:
        return 'rd';
      default:
        return 'th';
    }
  };

  return (
    <React.Fragment>
      <Header {...headerProps} />
      {isCountryUsersGetPending ? (
        <Grid container justify="center" alignItems="center" className={classes.root}>
          <Spinner />
        </Grid>
      ) : !items || !items.length ? (
        <Grid
          container
          className={classes.root}
          justify="center"
          alignItems="center"
          direction="column">
          <img src={noMatchesFound} alt="no-details-yet" />
          <TextGroup text="No registered users for this time period" />
        </Grid>
      ) : (
        <Grid container alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <HorizontalBar height={400} data={dataset} options={options} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container direction="column" className={classes.topUsersWrapper}>
              <Grid item>
                <Typography className={classes.subTitle}>Top 10 users by volume traded</Typography>
              </Grid>
              <Grid item>
                <Grid container direction="column" className={classes.listWrapper}>
                  {items.map((item, index) => (
                    <Grid item key={index}>
                      <Grid container>
                        <Grid item>
                          <Typography className={classes.indexLabel}>
                            {`${index + 1}${getPostFix(index)} place`}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container className={classes.gridField}>
                        <Grid item>
                          <Typography className={classes.nameLabel}>{item.name}:</Typography>
                        </Grid>
                        <Grid item>
                          <Typography className={classes.valueLabel}>${item.volume}M</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

CountryUsersPage.propTypes = {
  isCountryUsersGetPending: PropTypes.bool,
  items: PropTypes.array,
};

export default withStyles(styles)(CountryUsersPage);
