import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextGroup from 'modules/common/TextGroup/';
import { HorizontalBar } from 'react-chartjs-2';
import Header from '../../components/BaseHeader';
import noMatchesFound from 'static/no_matches_found.svg';
import Spinner from '../../../common/Spinner';

const MarketingChannelsView = ({ classes, headerProps, isPending, items = [] }) => {
  const isEmpty = !items?.find(({ count }) => count > 0);
  const labels = items.map(({ data }) => data);
  const sum = items.reduce((result, item) => result + item.count, 0);
  const data = items.map((i) => i.count);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      onClick: () => {},
    },
    scales: {
      yAxes: [
        {
          barPercentage: 0.6,
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            callback: (value) => {
              return Math.round((value / sum) * 100) + '%';
            },
            beginAtZero: true,
          },
          gridLines: {
            offsetGridLines: false,
          },
        },
      ],
    },
  };

  const dataset = {
    labels,
    datasets: [
      {
        label: 'Registered Users',
        data,
        type: 'horizontalBar',
        backgroundColor: '#C0F826',
        borderColor: 'rgb(54,93,235)',
      },
    ],
  };

  return (
    <React.Fragment>
      <Header {...headerProps} />
      <Grid container direction="column">
        {isPending ? (
          <Grid container justify="center" alignItems="center" className={classes.root}>
            <Spinner />
          </Grid>
        ) : isEmpty ? (
          <Grid
            container
            className={classes.root}
            justify="center"
            alignItems="center"
            direction="column">
            <img src={noMatchesFound} alt="no-details-yet" />
            <TextGroup text="No registered users for this time period" />
          </Grid>
        ) : (
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} className={classes.field}>
              <HorizontalBar height={400} data={dataset} options={options} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

MarketingChannelsView.propTypes = {
  isPending: PropTypes.bool,
  items: PropTypes.array,
};

export default withStyles(styles)(MarketingChannelsView);
