import { common } from '../common';
import { palette } from '../palette';

const OverridesMuiSelect = {
  styleOverrides: {
    outlined: {
      borderRadius: common.formControl.root.borderRadius,
    },
    standard: {
      color: common.formControl.root.color,
    },
    select: {
      '&:focus': {
        backgroundColor: common.formControl.focus.backgroundColor,
      },
    },
    selectMenu: {
      height: null,
      minHeight: 'auto',
    },
    icon: {
      color: palette.text.dark,
    },
  },
};

export default OverridesMuiSelect;
