import { all, put, takeEvery, select } from 'redux-saga/effects';
import { getCountriesAction, getCountriesRequest } from '../actions';
import { getUserId } from '../../auth/selectors';

function* navigateToDashboardHandler() {
  let userId = yield select(getUserId);
  yield put(getCountriesRequest(userId));
}

function* geoSaga() {
  yield all([takeEvery(getCountriesAction, navigateToDashboardHandler)]);
}

export default geoSaga;
