import api from 'modules/api/index';

export const selectDebitAccountRequest = (payload) => {
  return api.post('/vps/account', payload);
};

export const signVpsRequest = (payload) => {
  return api.post('/vps/sign', payload);
};

export const getVpsLotsRequest = (payload) => {
  const { isCached } = payload;
  return api.get(`/vps/lots?isCached=${isCached}`);
};

export const getVpsUsersRequest = (payload) => {
  return api.get('/vps/users', { params: payload });
};

export const getLotsListRequest = (payload) => {
  return api.get('/vps/lots-list', { params: payload });
};

export const cancelVpsRequest = (payload) => {
  return api.post('/vps/cancel', payload);
};

export const generateVpsRequest = (payload) => {
  return api.post('/vps/create', payload);
};
