export default {
  control: (theme) => ({
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  }),
  countryList: (theme) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  }),
  symbolsList: (theme) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  }),
};
