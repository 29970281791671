import { palette } from '../../../components/CustomThemeProvider/themes/palette';

const styles = {
  boxContainer: {
    display: 'flex',
    gap: 2,
    p: 2,
    backgroundColor: palette.lightGreen,
  },
  boxText: {
    fontSize: 14,
  },
  boxIcon: {
    color: palette.text,
    fontSize: 20,
  },
};

export default styles;
