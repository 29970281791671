import React, { useState } from 'react';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import WhatshotOutlinedIcon from '@mui/icons-material/WhatshotOutlined';
import FaceOutlinedIcon from '@mui/icons-material/FaceOutlined';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SwapCallsOutlinedIcon from '@mui/icons-material/SwapCallsOutlined';
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';

import TPGrid from 'components/TP-UI/TPGrid';
import TPSidebarLogo from 'components/TP-UI/TPSidebarLogo';
import TPNavMenuList from 'components/TP-UI/TPNavMenuList';
import config from 'config';

import styles from './styles';
import TPDivider from '../../../components/TP-UI/TPDivider';

const { LOGO, CURRENT_HUB_LABEL } = config;

const NavMenuLists = () => {
  const linkTo = '/auth/sign-in';
  const [expanded, setExpanded] = useState(true);
  const toggle = () => setExpanded((prevState) => !prevState);

  return (
    <TPGrid
      id="logoBlock"
      container
      alignItems="center"
      sx={[expanded && styles.maximized, !expanded && styles.minimized, styles.root]}>
      <TPGrid item xs={12}>
        <TPSidebarLogo
          alt={CURRENT_HUB_LABEL}
          src={LOGO[expanded ? 'blue' : 'blue']}
          path={linkTo}
          expanded={expanded}
          aria-controls="logoBlock"
          onExpandClick={toggle}
        />
      </TPGrid>
      <TPGrid item xs={12}>
        <TPDivider className={styles.divider} />
      </TPGrid>
      <TPGrid item xs={12}>
        <TPNavMenuList
          minimized={!expanded}
          selectedRoute="personal-details"
          menuItems={[
            {
              id: 'dashboard',
              label: 'Dashboard',
              iconComponent: DashboardOutlinedIcon,
            },
            {
              id: 'id_verification',
              label: 'ID Verification',
              iconComponent: VerifiedUserOutlinedIcon,
            },
            {
              id: 'settingsMenu',
              label: 'Settings',
              iconComponent: SettingsOutlinedIcon,
              items: [
                {
                  id: 'migration_requests',
                  label: 'Migration requests',
                  iconComponent: SwapCallsOutlinedIcon,
                  path: 'personal-details',
                },
                {
                  id: 'security',
                  label: 'Logs',
                  iconComponent: BeenhereOutlinedIcon,
                },
              ],
            },
            {
              id: 'news',
              label: { key: 'menu:category.news' },
              iconComponent: AssignmentOutlinedIcon,
              items: [
                {
                  id: 'news_search',
                  label: { key: 'menu:category.discover' },
                  iconComponent: TrendingUpOutlinedIcon,
                },
                {
                  id: 'market_buzz',
                  label: { key: 'menu:category.marketBuzz' },
                  iconComponent: WhatshotOutlinedIcon,
                },
                {
                  id: 'sentiment',
                  label: { key: 'menu:category.sentiment' },
                  iconComponent: FaceOutlinedIcon,
                },
                {
                  id: 'myFeeds',
                  label: { key: 'menu:category.myFeeds' },
                  iconComponent: StarBorderIcon,
                },
              ],
            },
          ]}
        />
      </TPGrid>
    </TPGrid>
  );
};

export default NavMenuLists;
