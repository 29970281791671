import { palette } from '../../../components/CustomThemeProvider/themes/palette';

export const styles = {
  wrapper: {
    position: 'relative',
    display: 'inline-flex',
  },
  valueWrapper: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progress: {
    color: palette.green2,
  },
};
