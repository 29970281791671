const styles = (theme) => ({
  liAccountContainer: {
    width: '50%',
    margin: '0',
    listStyleType: 'none',
    display: 'inline-block',
    [theme.breakpoints.down(1280)]: {
      width: '48%',
    },
    [theme.breakpoints.down(1085)]: {
      margin: '28px 0 0 0 ',
      width: '100%',
    },
    [theme.breakpoints.down(640)]: {
      width: '100%',
    },
  },
  ulAccountsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0',
  },
});

export default styles;
