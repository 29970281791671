import { combineReducers } from 'redux';

import bankDetails from './bankDetails';
import authorisedPersons from './authorisedPersons';
import profileData from './profileData';
import userSessions from './userSessions';

const reducer = combineReducers({
  bankDetails,
  authorisedPersons,
  profileData,
  userSessions,
});

export default reducer;
