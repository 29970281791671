import { formValueSelector } from 'redux-form';

export const formStartDate = (state, formName, id = 'dateFrom') => {
  const selector = formValueSelector(formName);
  return selector(state, id);
};

export const formEndDate = (state, formName, id = 'dateTo') => {
  const selector = formValueSelector(formName);
  return selector(state, id);
};
