const styles = (theme) => ({
  root: {
    padding: 0,
    paddingTop: 16,
    paddingBottom: 16,
    borderBottom: '1px solid #F2F4FA',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  container: {
    display: 'flex',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  clickable: {
    cursor: 'pointer',
  },
  title: {
    color: theme.palette.textColor,
    fontSize: 16,
    fontWeight: 'bold',
    position: 'relative',
    lineHeight: '20px',
  },
  titlePseudo: {
    '&::before': {
      content: '" "',
      display: 'block',
      height: 6,
      width: 6,
      position: 'absolute',
      left: -12,
      top: 8,
      backgroundColor: theme.palette.green2,
      borderRadius: '50%',
    },
  },
  description: {
    color: theme.palette.textColor,
    fontSize: 14,
    lineHeight: '19px',
    margin: '10px 0 0',
  },
  footer: {
    marginTop: '15px',
    display: 'flex',
  },
  footerItem: {
    marginRight: 20,
    color: theme.palette.simpleGrey,
    fontSize: 14,
    lineHeight: '30px',
  },
  image: {
    display: 'block',
    width: '100%',
    margin: 0,
    marginTop: 10,
    marginBottom: 10,
  },
  notification: {
    width: '100%',
  },
  link: {
    color: theme.palette.moodyBlue,
    textDecoration: 'none',
    fontWeight: '400',
    fontSize: 16,
    '&:hover': {
      color: '#2727C5',
    },
  },
});

export default styles;
