const MLN = 1000000;

export default (amount, currency = 'USD') => {
  if (!amount) {
    amount = 0;
  }
  let formattedAmount;
  let postfix = '';
  if (Intl) {
    if (amount > MLN) {
      amount = (amount / MLN).toFixed(4);
      postfix = 'M';
    }
    const formatter = new Intl.NumberFormat('en-US', {
      currency,
      style: 'currency',
      minimumFractionDigits: 0,
    });
    formattedAmount = formatter.format(amount).replace(/^(\D+)/, '$1\u00a0') + postfix;
  } else {
    formattedAmount =
      Number(amount)
        .toLocaleString('en-US', {
          currency,
          style: 'currency',
        })
        .replace(/^(\D+)/, '$1\u00a0') + postfix;
  }
  return formattedAmount;
};
