import { createAction } from 'redux-actions';

export const getCustomFieldsQuestionsRequest = createAction('GET_CUSTOM_FIELDS_QUESTIONS_REQUEST');
export const getCustomFieldsQuestionsSuccess = createAction('GET_CUSTOM_FIELDS_QUESTIONS_SUCCESS');
export const getCustomFieldsQuestionsFail = createAction('GET_CUSTOM_FIELDS_QUESTIONS_FAIL');

export const createCustomFieldsQuestionsRequest = createAction(
  'CREATE_CUSTOM_FIELDS_QUESTIONS_REQUEST',
);
export const createCustomFieldsQuestionsSuccess = createAction(
  'CREATE_CUSTOM_FIELDS_QUESTIONS_SUCCESS',
);
export const createCustomFieldsQuestionsFail = createAction('CREATE_CUSTOM_FIELDS_QUESTIONS_FAIL');

export const removeCustomFieldsQuestionsRequest = createAction(
  'REMOVE_CUSTOM_FIELDS_QUESTIONS_REQUEST',
);
export const removeCustomFieldsQuestionsSuccess = createAction(
  'REMOVE_CUSTOM_FIELDS_QUESTIONS_SUCCESS',
);
export const removeCustomFieldsQuestionsFail = createAction('REMOVE_CUSTOM_FIELDS_QUESTIONS_FAIL');

export const updateCustomFieldsQuestionsRequest = createAction(
  'UPDATE_CUSTOM_FIELDS_QUESTIONS_REQUEST',
);
export const updateCustomFieldsQuestionsSuccess = createAction(
  'UPDATE_CUSTOM_FIELDS_QUESTIONS_SUCCESS',
);
export const updateCustomFieldsQuestionsFail = createAction('UPDATE_CUSTOM_FIELDS_QUESTIONS_FAIL');
