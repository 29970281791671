import React from 'react';
import { Badge } from 'material-latest';
import PropTypes from 'prop-types';

const TPBadge = ({
  offset = 0,
  overlap = 'rectangular',
  classes = {},
  className,
  children,
  ...props
}) => {
  return (
    <Badge
      sx={[
        {
          '& .MuiBadge-badge': {
            transform: `scale(1) translate(${offset}0%, -${offset}0%)`,
            ...classes.badge,
            ...className,
          },
        },
      ]}
      overlap={overlap}
      {...props}>
      {children}
    </Badge>
  );
};

TPBadge.propTypes = {
  offset: PropTypes.number,
  overlap: PropTypes.oneOf(['circular', 'rectangular']),
  classes: PropTypes.object,
};

export default TPBadge;
