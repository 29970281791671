import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = {
  isOperationSubSubmitted: false,
};

export default handleActions(
  {
    [actionCreators.createSubscriptionModalOpen]: (state) => ({
      ...state,
      isOperationSubModalOpen: true,
    }),
    [actionCreators.createSubscriptionModalClose]: (state) => ({
      ...state,
      isOperationSubModalOpen: false,
    }),
    [actionCreators.createSubscriptionModalRequest]: (state) => ({
      ...state,
      isOperationSubSubmitted: true,
    }),
    [actionCreators.createSubscriptionModalSuccess]: (state) => ({
      ...state,
      isOperationSubSubmitted: false,
      isOperationSubModalOpen: false,
    }),
    [actionCreators.createSubscriptionModalFail]: (state) => ({
      ...state,
      isOperationSubSubmitted: false,
    }),
    [actionCreators.removeSubscriptionModalRequest]: (state) => ({
      ...state,
      isOperationSubSubmitted: true,
    }),
    [actionCreators.removeSubscriptionModalSuccess]: (state) => ({
      ...state,
      isOperationSubSubmitted: false,
      isOperationSubModalOpen: false,
    }),
    [actionCreators.removeSubscriptionModalFail]: (state) => ({
      ...state,
      isOperationSubSubmitted: false,
    }),
    [actionCreators.getSubscriptionModalSuccess]: (state, { payload }) => {
      return { ...state, subscriptions: payload.data.subscriptions };
    },
    [actionCreators.getSubscriptionModalFail]: () => {
      return { ...defaultState };
    },
  },
  defaultState,
);
