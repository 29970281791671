import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = {
  verifications: [],
  accounts: [],
  experienceUploads: [],
  verificationsTotalNumber: 0,
  accountsTotalNumber: 0,
  experienceUploadsTotalNumber: 0,
  accountApproved: false,
  accountDeclined: false,
};

export default handleActions(
  {
    [actionCreators.getPendingVerificationsSuccess](state, action) {
      return {
        ...state,
        verifications: action.response.data.verifications,
        verificationsTotalNumber: action.response.data.totalNumber,
        asc: action.payload.asc,
      };
    },

    [actionCreators.getPendingAccountsRequest](state) {
      return {
        ...state,
        accountApproved: false,
        accountDeclined: false,
      };
    },

    [actionCreators.getPendingAccountsSuccess](state, action) {
      return {
        ...state,
        accounts: action.response.data.accounts,
        accountsTotalNumber: action.response.data.totalNumber,
        asc: action.payload.asc,
      };
    },

    [actionCreators.getPendingExperienceUploadsSuccess](state, action) {
      return {
        ...state,
        experienceUploads: action.response.data.tradingStatements,
        experienceUploadsTotalNumber: action.response.data.totalNumber,
        asc: action.payload.asc,
      };
    },

    [actionCreators.approveAccountsSuccess](state) {
      return {
        ...state,
        accountApproved: true,
      };
    },
    [actionCreators.approveAccountsFail](state) {
      return {
        ...state,
        accountApproved: false,
      };
    },
    [actionCreators.declineAccountsSuccess](state) {
      return {
        ...state,
        accountDeclined: true,
      };
    },
    [actionCreators.declineAccountsFail](state) {
      return {
        ...state,
        accountDeclined: false,
      };
    },
  },
  defaultState,
);
