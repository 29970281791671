import { palette } from '../palette';
import { fontWeight, fontSize } from '../../font';

const OverridesMuiButton = {
  defaultProps: {
    disableElevation: true,
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      position: 'relative',
      fontWeight: fontWeight.bold,
      fontSize: fontSize._14,
      lineHeight: 1,
      textTransform: 'uppercase',
      borderRadius: 0,
    },
    textPrimary: {
      transition: '0.5s',
      color: palette.text.dark,
      '&:hover': {
        color: palette.text.main,
      },
      '&:active': {
        color: palette.text.light,
      },
      '&.Mui-disabled': {
        color: palette.textSecondary.lightest,
      },
    },
    containedPrimary: {
      transition: '0.5s',
      backgroundColor: palette.primary.dark,
      color: palette.text.dark,
      '&:hover': {
        background: palette.primary.main,
      },
      '&:active': {
        background: palette.primary.light,
      },
      '&.Mui-disabled': {
        color: palette.textSecondary.lightest,
        backgroundColor: palette.dividerCustom.lightest,
      },
    },
    outlinedPrimary: {
      transition: '0.5s',
      borderColor: palette.primary.dark,
      color: palette.text.dark,
      '&:hover': {
        borderColor: palette.primary.main,
      },
      '&:active': {
        borderColor: palette.primary.light,
      },
      '&.Mui-disabled': {
        color: palette.textSecondary.lightest,
        borderColor: palette.dividerCustom.lightest,
      },
    },
    sizeSmall: {
      fontSize: fontSize._12,
      '& svg.MuiSvgIcon-root': {
        fontSize: fontSize._12,
      },
    },
    textSizeSmall: {
      padding: 0,
    },
    sizeMedium: {
      padding: '10px 24px',
      fontSize: fontSize._12,
      '& svg.MuiSvgIcon-root': {
        fontSize: fontSize._12,
      },
    },
    textSizeMedium: {
      padding: 0,
    },
    outlinedSizeMedium: {
      padding: '9px 24px',
    },
    sizeLarge: {
      padding: '17px 24px',
      '& svg.MuiSvgIcon-root': {
        fontSize: fontSize._14,
      },
    },
    textSizeLarge: {
      padding: 0,
    },
    outlinedSizeLarge: {
      padding: '16px 24px',
    },
  },
};
export default OverridesMuiButton;
