import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import { getDeepObjectValue } from 'utils/getDeepObjectValue';
import { Typography } from '@material-ui/core';

const DescriptionList = ({
  value,
  descriptionProps,
  className,
  showEmpty = true,
  columns,
  classes,
}) => {
  return value ? (
    <dl
      className={classnames(classes.descriptionList, className, {
        [classes['columns' + columns]]: columns,
      })}>
      {descriptionProps
        ? descriptionProps.map((prop, index) => {
            let propValue = prop.dataKey ? getDeepObjectValue(value, prop.dataKey) : null;
            if (propValue && prop.format) {
              propValue = prop.format(propValue);
            }
            if (!propValue && !showEmpty && !prop.renderItem) {
              return null;
            }
            return (
              <div
                className={classnames(classes.descriptionItem, {
                  [classes.fullWidth]: prop.fullWidth,
                })}
                key={prop.dataKey + index}>
                <Typography className={classes.label} component="dt" variant="body3">
                  {prop.label}
                </Typography>
                <Typography
                  component="dd"
                  variant="body1"
                  className={classnames({
                    [classes[`${prop.textStyle}Text`]]: prop.textStyle,
                  })}>
                  {prop.renderItem ? prop.renderItem(value, propValue) : propValue || '\u2014'}
                </Typography>
              </div>
            );
          })
        : null}
    </dl>
  ) : null;
};

DescriptionList.displayName = 'MuiDescriptionList';

DescriptionList.propTypes = {
  /**
   * Data
   */
  value: PropTypes.object,
  /**
   * This value is responsible for showing or hiding the value
   */
  showEmpty: PropTypes.bool,
  /**
   * This value is responsible for showing data in columns, supported 1, 2, 3, 4 columns
   */
  columns: PropTypes.oneOf([1, 2, 3, 4]),
  /**
   * Config for every property, that should be displayed
   */
  descriptionProps: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Name(key) of value property, ex. email
       */
      dataKey: PropTypes.string.isRequired,
      /**
       * Label(title), e.g. Username
       */
      label: PropTypes.node.isRequired,
      /**
       * Text style of property value
       */
      textStyle: PropTypes.oneOf(['bold', '']),
      /**
       * Stretch prop item to container
       */
      fullWidth: PropTypes.bool,
      /**
       * Called when property value is rendered, function (value) => 'formatted string'
       */
      format: PropTypes.func,
      /**
       * Called when property value is rendered, function (item, value) => <Custom component />
       */
      renderItem: PropTypes.func,
    }),
  ).isRequired,
};

export default withStyles(styles)(DescriptionList);
