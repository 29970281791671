import { combineActions, handleActions } from 'redux-actions';

import * as actionCreators from '../actions';
const defaultState = [];

export default handleActions(
  {
    [combineActions(actionCreators.getUserSessionsSuccess)](state, action) {
      return action.response.data;
    },
    [combineActions(actionCreators.deleteUserSessionsSuccess)](state, action) {
      if (Array.isArray(action.response.data)) {
        return [];
      }

      const updatedState = state.filter((session) => {
        return session._id !== action.response.data._id;
      });

      return updatedState;
    },
  },
  defaultState,
);
