import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import upperCase from 'lodash/upperCase';
import { push } from 'connected-react-router';
import { reset } from 'redux-form';

import ConfirmPayment from '../components/ConfirmPayment';
import * as selectors from '../selectors';
import * as authSelectors from 'modules/auth/selectors';
import * as actionCreators from '../actions';
import { Routes } from 'constants/routeConstants';
import { DEPOSIT_CARD_FORM } from '../constants';

class ConfirmPaymentContainer extends PureComponent {
  componentDidMount() {
    const {
      actions: { confirmPaymentInit },
      isSCBPayment,
    } = this.props;
    if (!isSCBPayment) {
      confirmPaymentInit();
    }
  }

  onQrFinishClick = () => {
    const { push, reset } = this.props;
    reset(DEPOSIT_CARD_FORM);
    push(Routes.PAYMENTS);
  };

  render() {
    const {
      redirectUrl,
      account,
      amount,
      currency,
      qrImage,
      isSCBPayment,
      userCountry,
    } = this.props;
    const props = {
      redirectUrl,
      accountNickname: account.accountNickname,
      amountMoney: amount,
      currency: currency
        ? `${upperCase(account.currency)}/${upperCase(currency)}`
        : upperCase(account.currency),
      qrImage,
      isSCBPayment,
      onQrFinishClick: this.onQrFinishClick,
      userCountry,
    };

    return <ConfirmPayment {...props} />;
  }
}

ConfirmPaymentContainer.propTypes = {
  actions: PropTypes.shape({
    confirmPaymentInit: PropTypes.func.isRequired,
  }).isRequired,
  redirectUrl: PropTypes.string.isRequired,
  account: PropTypes.object.isRequired,
  amount: PropTypes.string.isRequired,
  currency: PropTypes.string,
  isSCBPayment: PropTypes.bool.isRequired,
  qrImage: PropTypes.string,
  userCountry: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    redirectUrl: selectors.getRedirectUrl(state),
    account: selectors.getAccount(state),
    amount: selectors.getAmount(state),
    currency: selectors.getCurrency(state),
    qrImage: selectors.getQrImage(state),
    isSCBPayment: selectors.isSCBPayment(state),
    userCountry: authSelectors.getUserCountry(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    push: bindActionCreators(push, dispatch),
    reset: bindActionCreators(reset, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPaymentContainer);
