import api from '..';

export const sendInviteRequest = (payload) => {
  return api.post('/reduce-fees', payload);
};

export const getReportDataRequest = (params) => {
  return api.get('/reduce-fees/report', { params });
};

export const getInvitationLinkRequest = () => {
  return api.get('/reduce-fees/link');
};

export const createInvitationLinkRequest = (params) => {
  return api.post('/reduce-fees/link', { params });
};

export const getAffiliateBalanceRequest = (params) => {
  return api.get('/reduce-fees/balance', { params });
};
