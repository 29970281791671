export default {
  datePickerWrapper: (theme) => ({
    display: 'inline-block',
    '& .react-datepicker__input-container': {
      position: 'static',
    },
    '& .react-datepicker-popper': {
      margin: '0',
      zIndex: 10,
    },
    '& .react-datepicker-wrapper': {
      display: 'block',
    },
    '& .react-datepicker': {
      ...theme.datePicker.root,
    },
    '& .react-datepicker__triangle': {
      display: 'none',
    },
    '& .react-datepicker__navigation': {
      overflow: 'visible',
      ...theme.datePicker.calendar.header.navigation.button,
      '&::after': {
        content: '""',
        display: 'block',
        ...theme.datePicker.calendar.header.navigation.icon,
      },
    },
    '& .react-datepicker__navigation--previous': {
      left: '18px',
      '&::after': {
        transform: 'rotate(225deg)',
        left: '4px',
      },
    },
    '& .react-datepicker__navigation--next': {
      right: '18px',
      '&::after': {
        transform: 'rotate(45deg)',
        right: '4px',
      },
    },
    '& .react-datepicker__header': {
      paddingTop: 0,
      ...theme.datePicker.calendar.header.root,
    },
    '& .react-datepicker__current-month': {
      paddingBottom: theme.spacing(1.25),
      ...theme.datePicker.calendar.header.currentMonth,
    },
    '& .react-datepicker__month': {
      margin: 0,
    },
    '& .react-datepicker__day-name': {
      ...theme.datePicker.calendar.body.week.day,
    },
    '& .react-datepicker__day': {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...theme.datePicker.calendar.body.month.day.root,
      '&:hover:not(.react-datepicker__day--selected):not(.react-datepicker__day--disabled)': {
        ...theme.datePicker.calendar.body.month.day.hover,
      },
      '&.react-datepicker__day--keyboard-selected:not(.react-datepicker__day--selected)': {
        ...theme.datePicker.calendar.body.month.day.selected,
      },
    },
    '& .react-datepicker__day--outside-month': {
      ...theme.datePicker.calendar.body.month.day.outsideMonth,
    },
    '& .react-datepicker__day--today:not(.react-datepicker__day--selected)': {
      ...theme.datePicker.calendar.body.month.day.today,
    },
    '& .react-datepicker__day--selected, & .react-datepicker__day--range-start, & .react-datepicker__day--range-end, & .react-datepicker__day--in-range': {
      ...theme.datePicker.calendar.body.month.day.selected,
    },
    '& .react-datepicker__day--disabled': {
      ...theme.datePicker.calendar.body.month.day.disabled,
    },
  }),
  fullWidth: {
    width: '100%',
  },
  errorContainer: (theme) => ({
    // Must be the same style as for TPTextField
    minHeight: theme.textError.root.minHeight,
  }),
};
