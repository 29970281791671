function getFormData(payload) {
  const formData = new window.FormData();
  for (let propertyName in payload) {
    appendFormDataFields(formData, payload[propertyName], propertyName);
  }

  return formData;
}

function appendFormDataFields(formData, data, previousKey) {
  if (data instanceof File /*data && data.path*/) {
    formData.append(previousKey, data);
  } else if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      //let key = `${previousKey}[${i}]`;
      let key = `${previousKey}`;
      let value = data[i] === null ? '' : data[i];
      appendFormDataFields(formData, value, key);
    }
  } else if (data instanceof Object && !Array.isArray(data)) {
    Object.keys(data).forEach((key) => {
      let value = data[key] === null ? '' : data[key];
      key = `${previousKey}.${key}`;
      appendFormDataFields(formData, value, key);
    });
  } else {
    let value = data === null ? '' : data;
    formData.append(previousKey, value);
  }
}

export default getFormData;
