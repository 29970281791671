import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = [];

export default handleActions(
  {
    [actionCreators.getPendingWithdrawalSuccess](state, action) {
      return !action.payload.persistStoreData ? action.response.data.withdrawals : state;
    },

    [actionCreators.getPendingWithdrawalFail]() {
      return defaultState;
    },
  },
  defaultState,
);
