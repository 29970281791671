import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Typography } from '@material-ui/core';

import CustomLink from 'modules/common/CustomLink';
import { getFieldData } from './helpers';
import TableSortButton from '../TableSortButton';
import Spinner from '../../Spinner';
import formatLargeText from '../../../../utils/formatLargeText';
import Tooltip from '@material-ui/core/Tooltip';

const getWidth = (width) => ({ width, minWidth: width });

const TableTextField = ({
  classes,
  label,
  shortLabel,
  showLabel,
  dataLabel,
  dataIcon,
  tableItem,
  formatFunc,
  route,
  getRedirectRoute,
  className,
  isLoading,
  sort,
  isHeadRow,
  width = 150,
}) => {
  const { fieldValue } = useMemo(() => getFieldData(label, dataLabel, tableItem), [
    label,
    dataLabel,
    tableItem,
  ]);
  if (showLabel && isHeadRow) {
    return (
      <div
        className={classNames([classes.item, classes.titleWrapper, className])}
        style={getWidth(width)}>
        <Typography className={classes.title}>{shortLabel || label}</Typography>
        {sort ? (
          <Typography className={classes.label}>
            <TableSortButton
              dataLabel={dataLabel}
              onSort={sort.onSort}
              isSorted={sort.isSorted}
              getSortDirection={sort.getSortDirection}
            />
          </Typography>
        ) : null}
      </div>
    );
  }

  const shortText = formatFunc
    ? formatFunc(fieldValue, undefined, tableItem) // undefined is needed to save old logic
    : formatLargeText(fieldValue, false, 11);

  const TooltipWrapper = ({ children }) => {
    if (shortText !== fieldValue) {
      return (
        <Tooltip title={fieldValue}>
          <Typography className={classes.dataText}>{children}</Typography>
        </Tooltip>
      );
    }
    return children;
  };

  return (
    <div className={classNames([classes.item, className])} style={getWidth(width)}>
      {showLabel ? (
        <Typography className={classNames(classes.title, classes.cellLabel)}>{label}</Typography>
      ) : null}
      <div className={classes.dataText}>
        {dataIcon && get(tableItem, dataIcon) && (
          <div className={classes.dataIconContainer}>
            <img className={classes.dataIcon} alt="flag" src={get(tableItem, dataIcon)} />
          </div>
        )}
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {route || getRedirectRoute ? (
              <CustomLink to={route || getRedirectRoute(tableItem)}>
                {formatFunc
                  ? formatFunc(fieldValue, undefined, tableItem)
                  : get(tableItem, dataLabel)}
              </CustomLink>
            ) : formatFunc ? (
              fieldValue !== undefined && (
                <TooltipWrapper>{formatFunc(fieldValue, undefined, tableItem)}</TooltipWrapper>
              )
            ) : (
              fieldValue !== undefined && <TooltipWrapper>{shortText}</TooltipWrapper>
            )}
          </>
        )}
      </div>
    </div>
  );
};

TableTextField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  shortLabel: PropTypes.string,
  showLabel: PropTypes.bool,
  dataLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  tableItem: PropTypes.object.isRequired,
  formatFunc: PropTypes.func,
  className: PropTypes.string,
  route: PropTypes.string,
  getRedirectRoute: PropTypes.func,
};

export default withStyles(styles)(TableTextField);
