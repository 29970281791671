const styles = () => ({
  welcomeModal: {
    padding: 32,
    borderRadius: 0,
  },
  section: {
    marginTop: 24,
  },
  listItem: {
    '&::marker': {
      fontSize: 12,
    },
  },
  buttonWrapper: {
    textAlign: 'end',
  },
  button: {
    padding: '14.5px 24px',
  },
  buttonTextContainer: {
    lineHeight: '19px',
  },
});

export default styles;
