import { handleActions } from 'redux-actions';
import * as actions from '../actions';

const defaultState = {
  pageWarning: null,
  isLoading: true,
  isUpdating: false,
};

export default handleActions(
  {
    [actions.getWarningPageRequest]: () => {
      return { ...defaultState };
    },
    [actions.getWarningPageSuccess]: (state, action) => {
      return { ...state, pageWarning: action.response.data, isLoading: false };
    },
    [actions.updateWarningPageRequest]: (state) => {
      return { ...state, isUpdating: true };
    },
    [actions.updateWarningPageSuccess]: (state) => {
      return { ...state, isUpdating: false };
    },
    [actions.updateWarningPageFail]: (state) => {
      return { ...state, isUpdating: false };
    },
  },
  defaultState,
);
