const styles = (theme) => ({
  select: {
    maxWidth: '166px',
    height: 32,
    flexGrow: 1,
    border: `1px solid ${theme.palette.green2}`,
  },

  selectRoot: {
    alignSelf: 'center',
    fontSize: '14px',
    backgroundColor: theme.contentLayout.backgroundColor,
    boxSizing: 'border-box',
    '& > div:focus': {
      backgroundColor: theme.contentLayout.backgroundColor,
      outline: `1px solid ${theme.palette.primary.main}`,
    },
    '& > div': {
      paddingLeft: '14px',
      height: 17,
    },
  },
  menuItem: {
    paddingLeft: 20,
  },
  disabledItem: {
    color: theme.palette.blackText,
    paddingLeft: 10,
    fontStyle: 'italic',
    fontWeight: 900,
    opacity: 0.8,
  },
});

export default styles;
