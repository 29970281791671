import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import TPIconButton from 'components/TP-UI/TPIconButton';
import TPBadge from 'components/TP-UI/TPBadge';
import TPMenu from 'components/TP-UI/TPMenu';
import TPTooltip from 'components/TP-UI/TPTooltip';

import { SORTING_ORDER } from './constants';

const TPSorting = ({ value, options = [], loading, disabled, onChange, className }) => {
  const { t } = useTranslation('common');
  const changed = value && value.sortDesc !== 0;

  const handleChange = useCallback(
    (_, item) => {
      if (onChange) {
        const newOrderIndex =
          value && value.dataKey === item.dataKey
            ? (SORTING_ORDER.indexOf(value.sortDesc) + 1) % SORTING_ORDER.length
            : 0;
        onChange({
          label: item.label,
          dataKey: item.dataKey,
          sortDesc: SORTING_ORDER[newOrderIndex],
        });
      }
    },
    [onChange, value],
  );

  const displayedOptions = useMemo(
    () =>
      options.map(({ dataKey, label }) => {
        const icon =
          value && dataKey === value.dataKey
            ? value.sortDesc > 0
              ? ArrowDownwardIcon
              : value.sortDesc < 0
              ? ArrowUpwardIcon
              : null
            : null;
        return {
          dataKey,
          label,
          iconComponent: icon,
        };
      }),
    [options, value],
  );

  return (
    <TPMenu
      value={value?.dataKey}
      menuItems={displayedOptions}
      onChange={handleChange}
      itemValue="dataKey">
      <TPTooltip content={t('labels.sorting')} disabled={isTablet || isMobile}>
        <TPIconButton className={className} disabled={loading || disabled}>
          <TPBadge variant="dot" color="error" invisible={!changed}>
            <FilterListIcon />
          </TPBadge>
        </TPIconButton>
      </TPTooltip>
    </TPMenu>
  );
};

TPSorting.propTypes = {
  value: PropTypes.shape({
    /**
     * Column title
     */
    label: PropTypes.node,
    dataKey: PropTypes.string,
    /**
     * Sorting order: 1 - desc, -1 - ask, 0 - not applied
     */
    sortDesc: PropTypes.oneOf([1, -1, 0]),
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Column title
       */
      label: PropTypes.node.isRequired,
      dataKey: PropTypes.string.isRequired,
    }),
  ),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  /**
   * Called when the sorting is changed, function ({dataKey, sortDesc})
   */
  onChange: PropTypes.func,
};

export default TPSorting;
