import { handleActions } from 'redux-actions';

import { hideMessage } from '../actions';

const defaultState = {};

export default handleActions(
  {
    [hideMessage]: (state, { payload }) => {
      return {
        ...state,
        [payload]: true,
      };
    },
  },
  defaultState,
);
