import { palette } from 'components/CustomThemeProvider/themes/palette';
import { DASHBOARD_METRICS, DASHBOARD_OVERVIEW_DATA_LABEL } from '../../constants';

export default (datasets) => ({
  datasets: [
    {
      label: DASHBOARD_OVERVIEW_DATA_LABEL.activeUsers,
      data: datasets[DASHBOARD_METRICS.activeUsers],
      borderColor: palette.green2,
      pointRadius: 1,
      fill: false,
      lineTension: 0.3,
      borderWidth: 2,
    },
  ],
});
