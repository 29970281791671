import React, { useEffect } from 'react';
import DashboardPage from '../views/DashboardView';
import { useDispatch } from 'react-redux';
import { getDashboardStatsCustomizationDataRequest } from '../actions';
import { store } from '../../../components/App';

const DashboardContainer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    import('../sagas').then((saga) => {
      store.injectSaga('DashboardContainer', saga.default);
      dispatch(getDashboardStatsCustomizationDataRequest());
    });
  }, [dispatch]);

  return <DashboardPage />;
};

export default DashboardContainer;
