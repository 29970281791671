import { fontSize } from '../../CustomThemeProviderMui5/themes/font';

export default {
  trendingContainer: {
    display: 'inline-flex',
    borderRadius: '50%',
    fontSize: 'inherit',
    padding: '4px',
  },
  up: (theme) => ({
    ...theme.trending.up,
  }),
  down: (theme) => ({
    ...theme.trending.down,
  }),
  flat: (theme) => ({
    ...theme.trending.flat,
  }),
  smallSize: (theme) => ({
    ...theme.trending.smallSize,
  }),
  mediumSize: (theme) => ({
    ...theme.trending.mediumSize,
  }),
  largeSize: (theme) => ({
    ...theme.trending.largeSize,
  }),
  extraLargeSize: (theme) => ({
    ...theme.trending.extraLargeSize,
  }),
};
