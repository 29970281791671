import { handleActions } from 'redux-actions';

import { getFollowUpsSuccess, getFollowUpsFail } from '../actions';

const defaultState = {
  items: [],
  totalNumber: 0,
};

export default handleActions(
  {
    [getFollowUpsSuccess](state, action) {
      return !action.payload.persistStoreData
        ? {
            items: action.response.data.followUps,
            totalNumber: action.response.data.followUps.totalNumber,
          }
        : state;
    },
    [getFollowUpsFail]() {
      return defaultState;
    },
  },
  defaultState,
);
