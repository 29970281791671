import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import CustomModal from 'modules/common/CustomModal';
import { TMD_TEST_MODAL_TITLE, TMD_WARNING_MODAL } from '../../constants';

import styles from './styles';

const TMDWarningModal = ({ onAccepted, onCancel, classes }) => {
  const { t } = useTranslation('common');
  const buttons = useMemo(
    () => [
      { label: t('buttons.notNow'), variant: 'text', onClick: onCancel },
      {
        label: t('buttons.goToQuestionnaire'),
        color: 'primary',
        onClick: onAccepted,
      },
    ],
    [t, onAccepted, onCancel],
  );

  return (
    <CustomModal
      modalClassNames={classes.modal}
      modalName={TMD_WARNING_MODAL}
      isCloseButtonNeeded
      title={TMD_TEST_MODAL_TITLE}
      text="Please ensure you read the questions carefully. Your ability to continue with Global Prime will depend on whether you’re able to correctly answer the next 3 questions."
      buttons={buttons}
      customCloseModal={onCancel}
      className={classes.modalPaper}
    />
  );
};

TMDWarningModal.propTypes = {
  onAccepted: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default withStyles(styles)(TMDWarningModal);
