import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = [];

export default handleActions(
  {
    [actionCreators.getBlockedUsersSuccess](state, action) {
      return action.response.data.blockedUsers;
    },
    [actionCreators.getBlockedUsersFail]() {
      return defaultState;
    },
    [actionCreators.updateBlockedUsers](state, action) {
      return state.filter((i) => i._id !== action.payload._id);
    },
  },
  defaultState,
);
