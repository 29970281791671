const style = () => ({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      fontFamily: 'Red Hat Display',
    },
    body: {
      margin: 0,
      padding: 0,
    },
    'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    'input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
});

export default style;
