import React, { memo, useCallback, useEffect } from 'react';
import { Drawer, Grid } from '@material-ui/core';
import MuiBottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { withStyles } from '@material-ui/core/styles';
import {
  getIsAdminRole,
  getUserIsPosted,
  getUserVerificationProgress,
} from 'modules/auth/selectors';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NavigationMenuList from '../NavigationMenuList';
import VerificationProgress from '../VerificationProgress';
import styles from './styles.js';
import { useBottomNavigation } from '../../context/bottomNavigationContext';
import Divider from '../../../common/Divider';
import MenuDropDown from '../../../common/HeaderDesktop/components/MenuDropdown';
import UserNotificationsContainer from 'modules/userNotifications/containers/notificationBell';
import AdminNotificationsContainer from 'modules/userNotifications/containers/notificationBellInternal';
import Logo from '../../../common/Logo';
import { getUserIsVerified } from 'modules/auth/selectors';
import { usePreviousValue } from '../../../../hooks/usePreviousValue';

const BottomNavigationMenuAction = memo(
  ({ location, classes, subItems, label, icon, value, selected }) => {
    const isPosted = useSelector(getUserIsPosted);
    const verificationProgress = useSelector(getUserVerificationProgress);
    const bottomNavigation = useBottomNavigation();
    const isUserVerified = useSelector(getUserIsVerified);

    const isAdminRole = useSelector(getIsAdminRole);

    const notificationCenter = isAdminRole ? (
      <AdminNotificationsContainer />
    ) : (
      <UserNotificationsContainer fullScreen />
    );

    const currentPathName = location.pathname;
    const previousPath = usePreviousValue(currentPathName);

    const handleSubMenuOpen = useCallback(() => {
      bottomNavigation.setMenuOpen(true);
    }, [bottomNavigation]);

    const handleSubMenuClose = useCallback(() => {
      bottomNavigation.setMenuOpen(false);
    }, [bottomNavigation]);

    useEffect(() => {
      if (currentPathName !== previousPath) {
        handleSubMenuClose();
      }
    }, [currentPathName, previousPath, handleSubMenuClose]);

    return (
      <>
        <MuiBottomNavigationAction
          value={value}
          selected={selected}
          showLabel
          classes={{ selected: classes.selected, label: classes.label }}
          label={label}
          onClick={handleSubMenuOpen}
          icon={<img src={icon} alt={label} className={classes.icon} />}
        />
        <Drawer
          anchor="top"
          open={bottomNavigation.menuOpen}
          disableEnforceFocus
          disablePortal
          onClose={handleSubMenuClose}
          classes={{ paper: classes.root }}>
          <div className={classes.subMenuRoot}>
            <div className={classes.infoContainer}>
              <Grid
                container
                className={classes.logoContainer}
                direction="row"
                justify="space-between">
                <Grid item>
                  <Logo color="blue" />
                </Grid>
                <Grid item>
                  <div className={classes.circlesWrapper}>
                    {notificationCenter}
                    <Divider orientation="vertical" />
                    <MenuDropDown onClickMenu={handleSubMenuClose} />
                  </div>
                </Grid>
              </Grid>
              <Divider orientation="horizontal" />
              {!isUserVerified && (
                <div className={classes.progressContainer}>
                  <VerificationProgress
                    progress={verificationProgress}
                    onClickContinue={handleSubMenuClose}
                    isPosted={isPosted}
                  />
                  <Divider orientation="horizontal" />
                </div>
              )}
            </div>
            <div className={classes.menuContainer}>
              <NavigationMenuList selectedRoute={location.pathname} menuItemsList={subItems} />
            </div>
          </div>
        </Drawer>
      </>
    );
  },
);

BottomNavigationMenuAction.displayName = 'BottomNavigationMenuAction';

export default withRouter(withStyles(styles)(BottomNavigationMenuAction));
