const styles = (theme) => ({
  question: {
    display: 'block',
    color: theme.palette.text.main,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '21px',
    marginBottom: '24px',
  },
  customQuestions: {
    marginTop: '14px',
  },
  input: {
    paddingBottom: '7px',
    '&:last-child': {
      marginBottom: '-21px',
    },
  },
});
export default styles;
