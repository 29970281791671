import { fontSize } from '../../CustomThemeProviderMui5/themes/font';

export default {
  warning: (theme) => ({
    color: theme.palette.warning.dark,
    fontSize: fontSize._12,
    display: 'flex',
    alignItems: 'center',
    padding: '2px 0',
    minHeight: '1.6em',
  }),
  mediumSize: {
    fontSize: fontSize._14,
    minHeight: '2em',
  },
};
