export default {
  root: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  }),
  flagIcon: (theme) => ({
    width: 32,
    height: 'auto',
    border: `1px solid ${theme.palette.primary.lightest}`,
  }),
};
