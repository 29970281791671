import { put, takeEvery, delay } from 'redux-saga/effects';
import get from 'lodash/get';

import * as actionCreators from '../actions';

let errorId = 1;
let infoDelay = 10000; //ms
let errorDelay = 0; //ms

function* showInfo(action) {
  let id = ++errorId;
  yield put(actionCreators.addNotification({ id: id, message: action.payload, type: 'info' }));
  yield delay(infoDelay);
  yield put(actionCreators.removeNotification(id));
}

function* showError(action) {
  let id = ++errorId;
  yield put(actionCreators.addNotification({ id: id, message: action.payload, type: 'error' }));
  if (errorDelay) {
    yield delay(errorDelay);
    yield put(actionCreators.removeNotification(id));
  }
}

function* showDocument(action) {
  let id = ++errorId;
  const message = get(action, 'payload.message', '');
  const modalName = get(action, 'payload.modalName', '');
  yield put(actionCreators.addNotification({ id: id, message, type: 'document', modalName }));
}

function* index() {
  yield takeEvery(actionCreators.showNotificationInfo, showInfo);
  yield takeEvery(actionCreators.showNotificationError, showError);
  yield takeEvery(actionCreators.showNotificationDocument, showDocument);
}

export default index;
