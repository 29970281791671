import { createAction } from 'redux-actions';

export const getDeletedUsersRequest = createAction('GET_DELETED_USERS_REQUEST');
export const getDeletedUsersSuccess = createAction('GET_DELETED_USERS_SUCCESS');
export const getDeletedUsersFail = createAction('GET_DELETED_USERS_FAIL');

export const restoreDeletedUser = createAction('RESTORE_DELETED_USER_REQUEST');
export const restoreDeletedUserSuccess = createAction('RESTORE_DELETED_USER_SUCCESS');
export const restoreDeletedUserFail = createAction('RESTORE_DELETED_USER_FAIL');

export const updateDeletedUsers = createAction('UPDATE_DELETED_USERS');
