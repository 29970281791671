import React, { lazy, Suspense, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import DocumentTitle from 'react-document-title';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { startIdVerification } from '../../../../../VerificationModalsManager/actions';
import { Typography } from '@material-ui/core';
import { Stack } from 'material-latest';
import isNil from 'lodash/isNil';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { DIALOG_NAME, READY_TO_CREATE_ACCOUNT_MODAL } from '../../constants';
import zeroAccounts from 'static/zero-accounts.svg';
import MainLayout from 'modules/common/MainLayout';
import { Routes } from 'constants/routeConstants';
import CustomLink from 'modules/common/CustomLink';
import { ACCOUNT_SERVERS, GLOBAL_PRIME } from 'constants/index';
import { VerificationStatuses } from 'constants/verificationStatuses';

import SortAccounts from '../SortAccounts';
import ReadyToCreateAccountModal from '../ReadyToCreateAccountModal';
import {
  getIsRetailQuizPassed,
  getIsUserWholeSale,
  getUserProStatus,
  isUserFromBannedCountry,
  targetUserQuizSelector,
} from '../../../../../auth/selectors';
import Tooltip from 'modules/common/Tooltip/components/Tooltip';
import Spinner from '../../../../../common/Spinner';
import InfoBox from 'modules/common/InfoBox';
import useQuiz from '../../../../../../hooks/useQuiz';

const AddAccountDialog = lazy(() => import('../../containers/AddAccountDialogContainer'));
const EditAccountDialog = lazy(() => import('../../containers/EditAccountsDialogContainer'));
const FundDemoAccountDialog = lazy(() => import('../../containers/FundDemoAccountDialogContainer'));
const MigrationModal = lazy(() => import('../MigrationModal'));
const Tabs = lazy(() => import('@material-ui/core/Tabs'));
const Tab = lazy(() => import('@material-ui/core/Tab'));
const PrimaryButton = lazy(() => import('modules/common/PrimaryButton'));
const HideAccountModal = lazy(() => import('../HideAccountModal'));
const UndoArchiveModal = lazy(() => import('../UndoArchiveModal'));

const getAccountName = (selectedTab) => {
  if (selectedTab === ACCOUNT_SERVERS.DEMO) {
    return 'common:labels.demo';
  }

  return 'common:labels.live';
};

const MyAccounts = ({
  classes,
  selectedTab,
  onTabChange,
  onLiveFund,
  onGoVerification,
  accounts = [],
  activeDialog,
  isAccountsGetPending,
  actions,
  isQuizPassed,
  userName,
  rebateAccountsLength,
  isLead2,
  isLead3,
  isLead4,
  wasRejected,
  userStatus,
  showHidden,
  hideAccountId,
  hideAccountIsHidden,
  hideAccountLoading,
  showLeverage,
  followerId,
  userIsVerified,
  userIsPosted,
  hub,
  isTradingDisabled,
  onUndoArchived,
  userAllowedAccountCreation,
}) => {
  const dispatch = useDispatch();

  let { accountsShowAddDialog, accountsShowFundDemoDialog } = actions;
  const { t } = useTranslation('accounts');

  const isPro = useSelector(getUserProStatus);
  const isUserWholeSale = useSelector(getIsUserWholeSale);
  const targetQuizProgress = useSelector(targetUserQuizSelector);
  const isUserFromBannedCountryState = useSelector(isUserFromBannedCountry);

  /*
    TODO:
    if user passed Retail Quiz, clecked on Upgrade To Pro button
    and hasn't passed Pro Quiz he still is Retail
  */
  const isRetailQuizPassed = useSelector(getIsRetailQuizPassed);

  const isQuizRequired =
    hub === GLOBAL_PRIME &&
    !isUserWholeSale &&
    !isPro &&
    !isRetailQuizPassed &&
    (!targetQuizProgress || !targetQuizProgress?.isPassed);

  const history = useHistory();

  const openMyPerformance = useCallback(
    (mt4login) => {
      history.push(Routes.MY_PERFORMANCE.replace(':id', mt4login));
    },
    [history],
  );

  const isNotUserFromBannedCountryState = useMemo(
    () => !isNil(isUserFromBannedCountryState) && !isUserFromBannedCountryState,
    [isUserFromBannedCountryState],
  );

  const isButtonVisible = useMemo(
    () =>
      userAllowedAccountCreation &&
      isNotUserFromBannedCountryState &&
      (selectedTab === ACCOUNT_SERVERS.DEMO ||
        (isQuizRequired
          ? isQuizPassed && (userIsPosted || userIsVerified)
          : userIsPosted || userIsVerified)),
    [
      isNotUserFromBannedCountryState,
      isQuizPassed,
      isQuizRequired,
      selectedTab,
      userAllowedAccountCreation,
      userIsPosted,
      userIsVerified,
    ],
  );

  const startQuiz = useQuiz();

  return (
    <MainLayout
      title={t('page.title')}
      mobileContent={
        isButtonVisible ? (
          <PrimaryButton
            color="primary"
            size="small"
            label={t('common.buttons.add_account_btn_mobile')}
            onClick={accountsShowAddDialog}
          />
        ) : null
      }
      desktopContent={
        isButtonVisible ? (
          <PrimaryButton
            color="primary"
            size="large"
            label={t('common.buttons.add_account_btn')}
            onClick={accountsShowAddDialog}
          />
        ) : null
      }>
      <DocumentTitle title={t('page.title')} />
      <div className={classes.root}>
        {isUserFromBannedCountryState && (
          <div className={classes.infoBox}>
            <InfoBox text={t('page.noteBannedRegion')} />
          </div>
        )}
        {isTradingDisabled && (
          <div className={classes.infoBox}>
            <InfoBox text={t('page.noteTradingDisabled')} />
          </div>
        )}
        <Stack direction="row" justifyContent="space-between">
          <Suspense fallback="">
            <Tabs scrollButtons="off" value={selectedTab} onChange={onTabChange}>
              <Tab label={t('common:labels.live')} value={ACCOUNT_SERVERS.LIVE} />
              <Tab label={t('common:labels.demo')} value={ACCOUNT_SERVERS.DEMO} />
              {rebateAccountsLength !== 0 && (
                <Tab label={t('common:labels.rebate')} value={ACCOUNT_SERVERS.REBATE} />
              )}
            </Tabs>
          </Suspense>
          <button onClick={actions.toggleShowHidden} className={classes.showHidden}>
            {t(`showHidden.${!!showHidden}`)}
          </button>
        </Stack>
        {isAccountsGetPending && (
          <div className={classes.loader}>
            <Spinner />
          </div>
        )}
        {!isAccountsGetPending && Boolean(accounts.length) && (
          <>
            {isQuizRequired && selectedTab === ACCOUNT_SERVERS.LIVE && (
              <div className={classes.infoBoxWrapper}>
                <InfoBox
                  text={
                    <div className={classes.quizReminder}>
                      <div>
                        <Trans i18nKey="page.note" ns="accounts">
                          &nbsp; Before you can start trading, you need to pass a required
                          <CustomLink onClick={startQuiz}>&nbsp; quick quiz &nbsp;</CustomLink>
                        </Trans>
                        {userStatus === VerificationStatuses.REJECTED ? null : (
                          <>&nbsp;{t('page.nodeFund')}</>
                        )}
                      </div>
                      <div className={classes.tooltipContainer}>
                        <Tooltip
                          isLink={false}
                          label={
                            <div className={classes.tooltipLabel}>
                              <HelpOutlineIcon className={classes.tooltipIcon} />
                              <Typography className={classes.tooltipText}>
                                {t('common:whyDoWeAsk.title')}
                              </Typography>
                            </div>
                          }
                          value={t('common:whyDoWeAsk.messages.quide227')}
                        />
                      </div>
                    </div>
                  }
                />
              </div>
            )}

            <SortAccounts
              onSort={actions.sortAccountRequest}
              classes={classes}
              showHidden={showHidden}
              accounts={accounts}
              actions={actions}
              openMyPerformance={openMyPerformance}
              hideAccountLoading={hideAccountLoading}
              isQuizRequired={isQuizRequired}
              isLead2={isLead2}
              isLead3={isLead3}
              isLead4={isLead4}
              accountsShowAddDialog={accountsShowAddDialog}
              onLiveFund={onLiveFund}
              accountsShowFundDemoDialog={accountsShowFundDemoDialog}
              onGoVerification={onGoVerification}
              wasRejected={wasRejected}
              userStatus={userStatus}
              showLeverage={showLeverage}
              userIsVerified={userIsVerified}
              followerId={followerId}
              hub={hub}
              onUndoArchived={onUndoArchived}
            />
          </>
        )}
        {!isAccountsGetPending &&
        !showHidden &&
        !accounts.filter((account) => !account.isHidden).length &&
        accounts.filter((account) => account.isHidden).length ? (
          <div className={classes.noDemoRoot}>
            <div className={classes.noDemoMessage}>{t('page.hiddenAccountsWarninig')}</div>
          </div>
        ) : null}
        {!isAccountsGetPending && !accounts.length && (
          <Stack
            spacing={2}
            alignItems="center"
            width="100%"
            padding={{ sm: '42px 60px 70px 60px', xs: '32px 16px' }}>
            <img alt="Empty account data" src={zeroAccounts} className={classes.emptyDataImg} />
            <Typography className={classes.noDemoMessage}>
              <Trans i18nKey="page.account.notExists" ns="accounts">
                It looks like you don’t have any &nbsp;
                {t(getAccountName(selectedTab))}
                &nbsp; Accounts setup yet.
              </Trans>
            </Typography>
            {selectedTab === ACCOUNT_SERVERS.DEMO ? (
              isNotUserFromBannedCountryState && userAllowedAccountCreation ? (
                <>
                  <Typography className={classes.noDemoText}>
                    {t('page.account.createAccount')}
                  </Typography>
                  <PrimaryButton
                    color="primary"
                    label={t('common.buttons.add_account_btn')}
                    size="large"
                    onClick={accountsShowAddDialog}
                  />
                </>
              ) : null
            ) : (
              <>
                <Typography className={classes.noDemoText} align="center">
                  {isQuizRequired ? (
                    <>
                      {t('page.account.createLive_au')}
                      &nbsp;
                      <Tooltip
                        isLink={false}
                        label={<HelpOutlineIcon className={classes.tooltipIcon} />}
                        value={t('page.account.createLiveTooltip')}
                      />
                    </>
                  ) : (
                    t('page.account.createLive')
                  )}
                </Typography>
                <PrimaryButton
                  color="primary"
                  size="large"
                  label={
                    userStatus === VerificationStatuses.NEW
                      ? t('common:buttons.verifyNow')
                      : t('common:buttons.continue')
                  }
                  onClick={() => dispatch(startIdVerification())}
                />
              </>
            )}
          </Stack>
        )}
        <Suspense fallback="">
          <AddAccountDialog isVisible={activeDialog === DIALOG_NAME.ADD_ACCOUNT} />
          <EditAccountDialog isVisible={activeDialog === DIALOG_NAME.EDIT_ACCOUNT} />
          <FundDemoAccountDialog isVisible={activeDialog === DIALOG_NAME.FUND_DEMO_ACCOUNT} />
          <ReadyToCreateAccountModal isVisible={activeDialog === READY_TO_CREATE_ACCOUNT_MODAL} />
          <MigrationModal userName={userName} />
          <HideAccountModal
            accountId={hideAccountId}
            isHidden={hideAccountIsHidden}
            onHide={actions.hideAccountRequest}
            closeModal={actions.hideAccountClose}
            isLoading={hideAccountLoading}
          />
          <UndoArchiveModal />
        </Suspense>
      </div>
    </MainLayout>
  );
};

MyAccounts.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  isAccountsGetPending: PropTypes.bool.isRequired,
  activeDialog: PropTypes.string.isRequired,
  onLiveFund: PropTypes.func.isRequired,
  onGoVerification: PropTypes.func.isRequired,
  onTabChange: PropTypes.func.isRequired,
  accounts: PropTypes.array,
  actions: PropTypes.object.isRequired,
  isQuizRequired: PropTypes.bool,
  isQuizPassed: PropTypes.bool,
  userName: PropTypes.string.isRequired,
  rebateAccountsLength: PropTypes.number.isRequired,
  isLead2: PropTypes.bool.isRequired,
  isLead3: PropTypes.bool.isRequired,
  isLead4: PropTypes.bool.isRequired,
  wasRejected: PropTypes.bool.isRequired,
  userStatus: PropTypes.string.isRequired,
  hub: PropTypes.string.isRequired,
  isTradingDisabled: PropTypes.bool.isRequired,
  onUndoArchived: PropTypes.func.isRequired,
  userAllowedAccountCreation: PropTypes.bool,
};

export default withStyles(styles)(MyAccounts);
