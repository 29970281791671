import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = {};

export default handleActions(
  {
    [actionCreators.getUserCycleSuccess](state, action) {
      return action.response.data.cycleData;
    },

    [actionCreators.getUserCycleFail]() {
      return defaultState;
    },
  },
  defaultState,
);
