import { createAction } from 'redux-actions';

export const getVpsUsersRequest = createAction('GET_VPS_USERS_REQUEST');
export const getVpsUsersSuccess = createAction('GET_VPS_USERS_SUCCESS');
export const getVpsUsersFail = createAction('GET_VPS_USERS_FAIL');

export const getLotsListRequest = createAction('GET_LOTS_LIST_REQUEST');
export const getLotsListSuccess = createAction('GET_LOTS_LIST_SUCCESS');
export const getLotsListFail = createAction('GET_LOTS_LIST_FAIL');

export const generateVpsRequest = createAction('GENERATE_VPS_REQUEST');
export const generateVpsSuccess = createAction('GENERATE_VPS_SUCCESS');
export const generateVpsFail = createAction('GENERATE_VPS_FAIL');
