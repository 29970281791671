export default function mergeUrlPaths(paths, removeParams) {
  const validPaths = paths.filter((path) => !!path?.trim()); // explicitly test for empty strings
  const mergedUrl = validPaths
    .map((path, index) =>
      path.startsWith('/') && index !== 0 ? path.replace(/^\/|\/$/g, '') : path,
    )
    .join('/');
  if (removeParams) {
    const [urlWithoutParams] = mergedUrl.split(/[:?]/); // split by colon or question mark
    return urlWithoutParams;
  }
  return mergedUrl;
}
