import { combineActions, handleActions } from 'redux-actions';
import { getMailchimpStatusSuccess, toggleMailchimpSubscriptionSuccess } from '../actions';
import { logout } from '../../../../auth/actions';

const defaultState = {};

export default handleActions(
  {
    [combineActions(getMailchimpStatusSuccess)]: (state, action) => {
      return action.response.data;
    },
    [combineActions(toggleMailchimpSubscriptionSuccess)]: (state, action) => {
      return {
        ...state,
        status: action.response.data.status,
      };
    },
    [logout]: () => defaultState,
  },
  defaultState,
);
