import React, { useEffect, useMemo } from 'react';
import { groupBy } from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Field, formValues, change } from 'redux-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AlphabeticalPicker from 'modules/common/AlphabeticalPicker';
import { Box, Grid } from 'material-latest';

import Picker from 'modules/common/Picker';
import { required } from 'utils/validation/fieldValidationRules';
import { ACCOUNT_TYPE_PRO } from 'modules/tradingCalculators/constants';

import styles from './styles';

const ALL = 'All';
const proRe = /[.]r$/;

const SymbolPicker = ({ symbols, form, symbolGroup, symbol, accountType, allowedGroups = [] }) => {
  const { t } = useTranslation('tradingCalculators');
  const dispatch = useDispatch();
  const currencies = useMemo(() => {
    return (symbols || [])
      .filter((symbol) => symbolGroup === ALL || symbolGroup === symbol.symbolGroup)
      .filter((symbol) => {
        if (accountType === ACCOUNT_TYPE_PRO) {
          return proRe.test(symbol.symbol);
        }

        return !proRe.test(symbol.symbol);
      })
      .map(({ symbol }) => ({ value: symbol, label: symbol }));
  }, [symbols, symbolGroup, accountType]);

  useEffect(() => {
    if (!symbolGroup) {
      dispatch(change(form, 'symbolGroup', ALL));
    }

    dispatch(change(form, 'symbol', null));
  }, [symbolGroup, dispatch, form]);

  const groups = useMemo(() => {
    return [
      { value: ALL, label: ALL },
      ...Object.keys(groupBy(symbols, 'symbolGroup')).map((symbolGroup) => ({
        value: symbolGroup,
        label: symbolGroup,
      })),
    ].filter(
      (symbolGroup) => allowedGroups.length === 0 || allowedGroups.includes(symbolGroup.value),
    );
  }, [symbols, allowedGroups]);

  return (
    <Box sx={styles.container}>
      <Grid container gap={2}>
        <Grid item xs={12} md={5.7} sx={styles.field}>
          <Field
            label={'Group'}
            placeholder={'Symbol group'}
            name="symbolGroup"
            validate={[required]}
            component={Picker}
            values={groups}
          />
        </Grid>
        <Grid item xs={12} md={5.7} sx={styles.field}>
          <Field
            label={t('fields.symbol')}
            placeholder={t('placeholders.symbol')}
            name="symbol"
            validate={[required]}
            component={AlphabeticalPicker}
            selectedValue={symbol}
            values={currencies}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

SymbolPicker.propTypes = {
  form: PropTypes.string.isRequired,
  symbols: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default compose(
  formValues('accountType'),
  formValues('symbolGroup'),
  formValues('symbol'),
)(SymbolPicker);
