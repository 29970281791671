import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';
import get from 'lodash/get';

import {
  ALL_IN_ONE_CALCULATOR_FORM,
  PIP_VALUE_CALCULATOR_FORM,
  MARGIN_CALCULATOR_FORM,
  SWAPS_CALCULATOR_FORM,
  PROFIT_LOSS_CALCULATOR_FORM,
  MARGIN_STYLES_WITH_DISABLED_LEVERAGE,
  SWAP_TYPES,
} from '../constants';
import { defaultResultsFormatter, formatCurrencySpecs, attachCurrency } from '../helpers';

const getAllInOneResults = (state) => get(state, 'tradingCalculators.results.allInOne');
const getCurrencyResults = (state) => get(state, 'tradingCalculators.results.currencyCalculator');
const getPipValueResults = (state) => get(state, 'tradingCalculators.results.pipValue');
const getMarginResults = (state) => get(state, 'tradingCalculators.results.margin');
const getSwapsResults = (state) => get(state, 'tradingCalculators.results.swaps');
const getProfitLossResults = (state) => get(state, 'tradingCalculators.results.profitLoss');
const currencySpecsSelector = (state) =>
  get(state, 'tradingCalculators.currencySpecs.specification');
const contractSizesSelector = (state) =>
  get(state, 'tradingCalculators.currencySpecs.contractSizes');

export const allInOneFormSelector = formValueSelector(ALL_IN_ONE_CALCULATOR_FORM);
export const pipValueFormSelector = formValueSelector(PIP_VALUE_CALCULATOR_FORM);
export const marginFormSelector = formValueSelector(MARGIN_CALCULATOR_FORM);
export const swapsFormSelector = formValueSelector(SWAPS_CALCULATOR_FORM);
export const profitLossFormSelector = formValueSelector(PROFIT_LOSS_CALCULATOR_FORM);

export const getContractSizes = createSelector(contractSizesSelector, (contractSizes) =>
  contractSizes.map((contractSize) => ({
    label: `1 lot = ${contractSize}`,
    value: contractSize,
  })),
);

export const getSymbolConfig = (state) => {
  return state.tradingCalculators.currencySpecs.symbols;
};

export const getCurrencySpecs = createSelector(
  currencySpecsSelector,
  (currencySpecs) => currencySpecs,
);

export const getCurrencyPairs = createSelector(getCurrencySpecs, formatCurrencySpecs);

export const getCurrencyPairMarginStyle = createSelector(
  [(state) => marginFormSelector(state, 'currencyPair'), getCurrencySpecs],
  (currencyPair, currencySpecs) => get(currencySpecs, `${currencyPair}.marginStyle`, ''),
);

export const getIsLeverageDisabled = createSelector(getCurrencyPairMarginStyle, (marginStyle) =>
  MARGIN_STYLES_WITH_DISABLED_LEVERAGE.includes(marginStyle),
);

export const getPreparedAllInOneResults = createSelector(
  getAllInOneResults,
  ({ results, meta }) => {
    const {
      swapLongCurrencyPair,
      swapShortCurrencyPair,
      minPriceFluctuation,
      requiredMargin,
      pipValue,
      swapLongCurrency,
      swapShortCurrency,
    } = results;
    const { baseCurrency } = meta;

    return [
      {
        label: 'symbolDetails',
        rows: [
          { label: 'swapLongCurrencyPair', value: swapLongCurrencyPair },
          { label: 'swapShortCurrencyPair', value: swapShortCurrencyPair },
          { label: 'minPriceFluctuation', value: minPriceFluctuation },
        ],
      },
      {
        label: 'accountBaseCurrency',
        rows: [
          { label: 'requiredMargin', value: attachCurrency(requiredMargin, baseCurrency) },
          { label: 'result', value: attachCurrency(pipValue, baseCurrency) },
          { label: 'swapLongCurrency', value: attachCurrency(swapLongCurrency, baseCurrency) },
          { label: 'swapShortCurrency', value: attachCurrency(swapShortCurrency, baseCurrency) },
        ],
      },
    ];
  },
);

export const getPreparedCurrencyResults = createSelector(
  getCurrencyResults,
  defaultResultsFormatter,
);

export const getPreparedPipValueResults = createSelector(
  getPipValueResults,
  ({ results, meta }) => {
    const { currentConversionPrice, accountPipValue, currencyPipValue, pipValue } = results;
    const { baseCurrency, convertedCurrency } = meta;

    return [
      {
        label: 'currentConversionPrice',
        value: currentConversionPrice,
      },
      {
        label: 'accountBaseCurrency',
        value: attachCurrency(accountPipValue, baseCurrency),
      },
      {
        label: 'convertedCurrency',
        value: attachCurrency(currencyPipValue, convertedCurrency),
      },
      {
        label: 'pipValue',
        value: pipValue,
      },
    ];
  },
);

export const getPreparedMarginResults = createSelector(getMarginResults, ({ results, meta }) => {
  const {
    currentConversionPrice,
    accountRequiredMargin,
    currencyRequiredMargin,
    symbolPrice,
  } = results;
  const { baseCurrency, convertedCurrency } = meta;

  return [
    { label: 'price', value: attachCurrency(symbolPrice, convertedCurrency) },
    { label: 'currentConversionPrice', value: currentConversionPrice },
    {
      label: 'accountBaseCurrency',
      rows: [
        {
          label: 'accountRequiredMargin',
          value: attachCurrency(accountRequiredMargin, baseCurrency),
        },
      ],
    },
    {
      label: 'convertedCurrency',
      rows: [
        {
          label: 'currencyRequiredMargin',
          value: attachCurrency(currencyRequiredMargin, convertedCurrency),
        },
      ],
    },
  ];
});

export const getPreparedSwapsResults = createSelector(getSwapsResults, ({ results, meta }) => {
  const {
    currentConversionPrice,
    swapLongCurrencyPair,
    swapShortCurrencyPair,
    swapLongCurrency,
    swapShortCurrency,
    swapLongConvertedCurrency,
    swapShortConvertedCurrency,
    price,
    type,
  } = results;
  const { baseCurrency, convertedCurrency } = meta;

  return [
    { label: 'swapLongCurrencyPair', value: swapLongCurrencyPair },
    { label: 'swapShortCurrencyPair', value: swapShortCurrencyPair },
    { label: 'currentConversionPrice', value: currentConversionPrice },
    ...(type === SWAP_TYPES.INTEREST ? [{ label: 'price', value: price }] : []),
    {
      label: 'accountBaseCurrency',
      rows: [
        {
          label: 'swapLongCurrency',
          value: attachCurrency(swapLongCurrency, baseCurrency),
        },
        {
          label: 'swapShortCurrency',
          value: attachCurrency(swapShortCurrency, baseCurrency),
        },
      ],
    },
    {
      label: 'convertedCurrency',
      rows: [
        {
          label: 'swapLongConvertedCurrency',
          value: attachCurrency(swapLongConvertedCurrency, convertedCurrency),
        },
        {
          label: 'swapShortConvertedCurrency',
          value: attachCurrency(swapShortConvertedCurrency, convertedCurrency),
        },
      ],
    },
  ];
});

export const getPreparedProfitLossResults = createSelector(
  getProfitLossResults,
  ({ results, meta }) => {
    const { profitLossSymbol, profitLossBase, pipValue, conversionRate } = results;
    const { baseCurrency, symbolCurrency } = meta;

    return [
      { label: 'profitLossSymbol', value: attachCurrency(profitLossSymbol, symbolCurrency) },
      { label: 'profitLossBase', value: attachCurrency(profitLossBase, baseCurrency) },
      { label: 'conversionRate', value: attachCurrency(conversionRate, baseCurrency) },
      { label: 'pipValue', value: pipValue },
    ];
  },
);
