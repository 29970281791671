import { QUIZ_MODAL_STYLES } from '../../../../../constants/quizModalStyles';

const styles = (theme) => ({
  ...QUIZ_MODAL_STYLES,
  text: {
    display: 'block',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '21px',
    color: theme.palette.text.main,
  },
  link: {
    fontSize: '16px',
    lineHeight: '21px',
    fontWeight: 700,
    textDecoration: 'underline',
    color: theme.palette.text.main,
  },
});

export default styles;
