import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { getTranslation } from 'utils/compositeTranslationHandler';
import { useTranslation } from 'react-i18next';
import { ErrorOutline } from '@material-ui/icons';

import styles from './styles';

const CheckboxInput = ({
  classes,
  input,
  label = '',
  meta: { error, submitFailed },
  errorMessage,
  ...others
}) => {
  const { name, value, onChange } = input;
  const { t } = useTranslation('common');

  return (
    <>
      <FormControlLabel
        classes={{
          root: classes.formControlLabel,
          label: classes.label,
          disabled: classes.disabled,
        }}
        control={
          <Checkbox
            name={name}
            checked={value}
            onChange={onChange}
            disabled={others.disabled}
            classes={{
              root: classes.checkbox,
              checked: classes.checkboxChecked,
            }}
            icon={<CheckBoxOutlineBlankIcon className={classes.uncheckedIcon} />}
            checkedIcon={
              <span className={classes.checkedIconWrapper}>
                <CheckBoxIcon className={classes.checkedIcon} />
              </span>
            }
            {...others}
          />
        }
        label={label}
      />
      <div className={classes.errorMessage}>
        {error && submitFailed && (
          <div className={classes.formError}>
            <ErrorOutline className={classes.errorIcon} />
            &nbsp;
            {errorMessage || getTranslation(t, error)}
          </div>
        )}
      </div>
    </>
  );
};

CheckboxInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    label: PropTypes.string.isRequired,
    checkbox: PropTypes.string.isRequired,
    checkboxChecked: PropTypes.string.isRequired,
    disabled: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(CheckboxInput);
