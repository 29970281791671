import React from 'react';
import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Typography from '@material-ui/core/Typography';

import styles from './styles';
import errorImage from 'static/notFoundImage.svg';
import Button from 'modules/common/PrimaryButton';

const ErrorPageContent = ({ classes, onReturnToPreviousPage }) => {
  const { t } = useTranslation('errorPage');
  return (
    <section className={classes.section}>
      <img src={errorImage} alt="Unexpected Error" className={classes.image} />
      <div className={classes.body}>
        <Typography className={classes.title}> {t('errorTitle')}</Typography>
        <Typography className={classes.description}>{t('errorMessage')}</Typography>
        <Typography className={classes.description}>{t('errorMessageBold')}</Typography>
      </div>
      <div className={classes.submit}>
        <Button
          color="primary"
          size="large"
          label={t('previousPage')}
          onClick={onReturnToPreviousPage}
          startIcon={<ArrowRightAltIcon className={classes.arrowLeft} />}
        />
      </div>
    </section>
  );
};

export default withStyles(styles)(ErrorPageContent);
