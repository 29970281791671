import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'material-latest';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

import TPTypography from 'components/TP-UI/TPTypography';
import TPImage from 'components/TP-UI/TPImage';
import { SIZES } from 'components/TP-UI/constants';
import { getFileExtension, getImageUrl } from '../../../helpers/file';

import styles from './styles';

const TPSliderItem = ({ url, name, onClick, className }) => {
  const extension = getFileExtension(url);
  const imageUrl = getImageUrl(url);
  return (
    <Box sx={[styles.root, className]}>
      <Box sx={[styles.fileContainer]} onClick={onClick}>
        {imageUrl ? (
          <TPImage className={styles.fileImage} src={url} alt={name} />
        ) : (
          <Box sx={styles.nonImageFileContainer}>
            <DescriptionOutlinedIcon fontSize={SIZES.LARGE} />
            <TPTypography component="span" variant="body2" bold className={styles.fileExtension}>
              {extension}
            </TPTypography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

TPSliderItem.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
};

export default TPSliderItem;
