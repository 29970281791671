import { createAction } from 'redux-actions';

export const getAnalystDataRequest = createAction('GET_ANALYST_DATA_REQUEST');
export const getAnalystDataSuccess = createAction('GET_ANALYST_DATA_SUCCESS');
export const getAnalystDataFail = createAction('GET_ANALYST_DATA_FAIL');

export const setSelected = createAction('ANALYST_DATA_SET_SELECTED');
export const setFilter = createAction('ANALYST_DATA_SET_FILTER');

export const setForexFilter = createAction('ANALYST_DATA_SET_FOREX_FILTER');
export const setType = createAction('ANALYST_DATA_TYPE');
