import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import Box from 'material-latest/Box';
import { isTablet, isMobile } from 'react-device-detect';

import TPIconButton from 'components/TP-UI/TPIconButton';
import { MAIN_COLORS, SIZES } from 'components/TP-UI/constants';
import { generatePageList } from '../../helpers/pageList';

import { MAX_DISPLAYED_PAGES_AMOUNT, MAX_DISPLAYED_PAGES_AMOUNT_MOBILE } from './constants';

import styles from './styles';

export const TPPagesNavigation = ({
  value = 0,
  pagesAmount = 0,
  disabled = false,
  onChange,
  className,
}) => {
  const [displayedPages, setDisplayedPages] = useState([]);
  const pageDisplayed = displayedPages.some((page) => page.value === value);
  const prevPagesAmountRef = useRef(pagesAmount);
  const maxDisplayedPagesAmount =
    isMobile && !isTablet ? MAX_DISPLAYED_PAGES_AMOUNT_MOBILE : MAX_DISPLAYED_PAGES_AMOUNT;

  useEffect(() => {
    if (value !== undefined && (!pageDisplayed || prevPagesAmountRef.current !== pagesAmount)) {
      const pages = generatePageList(value, pagesAmount, maxDisplayedPagesAmount);
      if (!pages.some((page) => page.value === value)) {
        onChange(0);
      }
      setDisplayedPages(pages);
    }
  }, [pagesAmount, value, pageDisplayed, maxDisplayedPagesAmount, onChange]);

  useEffect(() => {
    prevPagesAmountRef.current = pagesAmount;
  }, [pagesAmount]);

  const handlePrevPageClick = useCallback(() => {
    if (onChange) {
      onChange(value > 1 ? value - 1 : 0);
    }
  }, [onChange, value]);

  const handleNextPageClick = useCallback(() => {
    if (onChange) {
      onChange(value < pagesAmount ? value + 1 : pagesAmount - 1);
    }
  }, [onChange, pagesAmount, value]);

  const prevDisabled = value === 0 || disabled;
  const nextDisabled = value === pagesAmount - 1 || disabled;

  return (
    <Box sx={[styles.pagesContainer, className]}>
      <TPIconButton
        aria-label="Previous Page"
        onClick={handlePrevPageClick}
        size={SIZES.SMALL}
        disabled={prevDisabled}>
        <ArrowBackIos fontSize={SIZES.SMALL} sx={styles.prevIcon} />
      </TPIconButton>
      <Box component="ul" sx={styles.pageList}>
        {displayedPages.map((page, index) => (
          <li key={`${page.label}_ ${index}`}>
            <TPIconButton
              color={page.value === value ? MAIN_COLORS.PRIMARY : ''}
              size={SIZES.SMALL}
              disabled={page.value === null || disabled}
              onClick={() => onChange(page.value)}>
              {page.label}
            </TPIconButton>
          </li>
        ))}
      </Box>
      <TPIconButton
        aria-label="Next Page"
        onClick={handleNextPageClick}
        size={SIZES.SMALL}
        disabled={nextDisabled}>
        <ArrowForwardIos fontSize={SIZES.SMALL} sx={styles.nextIcon} />
      </TPIconButton>
    </Box>
  );
};

TPPagesNavigation.propTypes = {
  /**
   * Current page value
   */
  value: PropTypes.number,
  /**
   * Total number of pages
   */
  pagesAmount: PropTypes.number,
  disabled: PropTypes.bool,
  /**
   * Called when the page number is changed, function (page)
   */
  onChange: PropTypes.func,
};

export default TPPagesNavigation;
