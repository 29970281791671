export default (theme) => ({
  title: {
    color: theme.palette.text.main,
    fontSize: '18px',
    fontWeight: '600',
    border: 'none',
    margin: '10px 0',
  },
  strong: {
    color: theme.palette.text.main,
    fontSize: '16px',
    fontWeight: '500',
    margin: '0',
    border: 'none',
  },
  p: {
    color: theme.palette.text.main,
    fontSize: '16px',
    fontWeight: '100',
    margin: '0',
    border: 'none',
    lineHeight: '1.5',
  },
  ol: {
    color: theme.palette.text.main,
    fontSize: '16px',
    fontWeight: '100',
    margin: '0',
    border: 'none',
    paddingLeft: '1.5em',
  },
});
