import { createAction } from 'redux-actions';

export const paymentQueuePendingRequest = createAction('PAYMENT_QUEUE_PENDING_REQUEST');
export const paymentQueuePendingSuccess = createAction('PAYMENT_QUEUE_PENDING_SUCCESS');
export const paymentQueuePendingFail = createAction('PAYMENT_QUEUE_PENDING_FAIL');

export const paymentQueueProcessRequest = createAction('PAYMENT_QUEUE_PROCESS_REQUEST');
export const paymentQueueProcessSuccess = createAction('PAYMENT_QUEUE_PROCESS_SUCCESS');
export const paymentQueueProcessFail = createAction('PAYMENT_QUEUE_PROCESS_FAIL');

export const paymentQueueRemoveRequest = createAction('PAYMENT_QUEUE_REMOVE_REQUEST');
export const paymentQueueRemoveSuccess = createAction('PAYMENT_QUEUE_REMOVE_SUCCESS');
export const paymentQueueRemoveFail = createAction('PAYMENT_QUEUE_REMOVE_FAIL');

export const openProcessPaymentModal = createAction('OPEN_PROCESS_PAYMENT_MODAL');
export const closeProcessPaymentModal = createAction('CLOSE_PROCESS_PAYMENT_MODAL');
