import React from 'react';
import { Box } from 'material-latest';

import styles from './styles';

const TPCardHeader = ({ className, children }) => {
  return <Box sx={[styles.root, className]}>{children}</Box>;
};

export default TPCardHeader;
