import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const TableHeader = ({ Component: TableHeaderComponent, ...tableHeaderProps }) => {
  return <TableHeaderComponent {...tableHeaderProps} />;
};

TableHeader.propTypes = {
  Component: PropTypes.object.isRequired,
};

export default withStyles(styles)(TableHeader);
