import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import QuizForm from '../QuizForm';
import CustomModal from '../../../CustomModal';
import ProgressBar from '../../../ProgressBar';

import quizProcessImage from 'static/quizzes/quiz-process.svg';
import styles from './styles';

const QuizModal = (props) => {
  const {
    classes,
    form,
    answers,
    currentQuestionIndex = 0,
    handleSubmit,
    loading,
    modalName,
    onCancel,
    onPrevClick,
    progress,
    questionId,
    questionLabel,
    title,
    titleEndAdornment,
    totalQuestionCount = 0,
    questionComponent: QuestionComponent,
  } = props;
  const { t } = useTranslation('common');

  const buttons = useMemo(
    () => [
      ...(currentQuestionIndex > 0
        ? [
            {
              label: t('buttons.previous'),
              variant: 'text',
              onClick: onPrevClick,
              disabled: loading,
            },
          ]
        : []),
      {
        label:
          currentQuestionIndex === totalQuestionCount - 1 ? t('buttons.finish') : t('buttons.next'),
        color: 'primary',
        onClick: handleSubmit,
        isPending: loading,
      },
    ],
    [t, handleSubmit, onPrevClick, currentQuestionIndex, totalQuestionCount, loading],
  );

  return (
    <CustomModal
      customCloseModal={onCancel}
      isCloseButtonNeeded
      modalName={modalName}
      title={title}
      buttons={buttons}
      titleEndAdornment={titleEndAdornment}
      modalClassNames={classes.modal}
      className={classes.modalPaper}>
      <div>
        <ProgressBar progress={progress} className={classes.progressBar} />
        <div className={classes.image}>
          <img src={quizProcessImage} alt="quiz-process" />
        </div>
        {QuestionComponent ? (
          <QuestionComponent
            answers={answers}
            questionId={questionId}
            questionLabel={questionLabel}
            form={form}
          />
        ) : (
          <QuizForm answers={answers} questionId={questionId} questionLabel={questionLabel} />
        )}
      </div>
    </CustomModal>
  );
};

QuizModal.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  currentQuestionIndex: PropTypes.number,
  form: PropTypes.string,
  loading: PropTypes.bool,
  modalName: PropTypes.string,
  onPrevClick: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  progress: PropTypes.number,
  questionId: PropTypes.string,
  questionLabel: PropTypes.string,
  title: PropTypes.string,
  titleEndAdornment: PropTypes.node,
  totalQuestionCount: PropTypes.number,
  questionComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
};

export default compose(reduxForm(), withStyles(styles))(QuizModal);
