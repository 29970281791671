import { createAction } from 'redux-actions';

export const startUpgradeToProV2 = createAction('UPGRADE_TO_PRO_V_2');

export const upgradeToProV2Request = createAction('UPGRADE_TO_PRO_V_2_REQUEST');
export const upgradeToProV2Success = createAction('UPGRADE_TO_PRO_V_2_SUCCESS');
export const upgradeToProV2Fail = createAction('UPGRADE_TO_PRO_V_2_FAIL');

export const getQuizInsightsFail = createAction('GET_QUIZ_INSIGHTS_FAIL');
export const getQuizInsightsRequest = createAction('GET_QUIZ_INSIGHTS_REQUEST');
export const getQuizInsightsSuccess = createAction('GET_QUIZ_INSIGHTS_SUCCESS');

export const setEvidenceUploadView = createAction('SET_EVIDENCE_UPLOAD_VIEW');
