import config from 'config';
import { ACCOUNT_SERVERS } from 'constants/index.js';

import i18n from 'i18n';
import translateLabelsInArray from 'i18n/helpers/translateLabelsInArray';

export const getAccountTypes = () => {
  const { ACCOUNT_TYPES } = config;
  return translateLabelsInArray(ACCOUNT_TYPES);
};

export const accountTypesMap = getAccountTypes().reduce(
  (obj, account) => ({ ...obj, [account.value]: account.label }),
  {},
);

export const getAccountIsLive = (userAllowedAccountCreation = true) => {
  const accountTypes = i18n.t('common:constants.accountIsLive', {
    returnObjects: true,
  });

  return userAllowedAccountCreation === false
    ? accountTypes.filter(({ value }) => value !== ACCOUNT_SERVERS.LIVE)
    : accountTypes;
};

export const getSecretQuestions = () =>
  i18n.t('profile:verification.secondStep.securityQuestions', {
    returnObjects: true,
  });

export const getDocumentTypesThirdStep = () =>
  i18n.t('profile:verification.documentTypesThirdStep', {
    returnObjects: true,
  });

export const getDocumentTypesThirdStepSecondStep = () =>
  i18n.t('profile:verification.documentTypesThirdStepSecondStep', {
    returnObjects: true,
  });

export const getDocumentSides = () =>
  i18n.t('profile:verification.documentSides', {
    returnObjects: true,
  });

export const getBankWireInfo = () => [
  { _id: 'bankName', title: i18n.t('common:labels.bankName') },
  { _id: 'iban', title: i18n.t('common:labels.iban') },
  { _id: 'bankAddress', title: i18n.t('common:labels.bankAddress') },
  { _id: 'accountNumber', title: i18n.t('common:labels.accountNumber') },
  { _id: 'accountName', title: i18n.t('common:labels.accountName') },
  { _id: 'swiftCode', title: i18n.t('common:labels.swiftCode') },
  { _id: 'branch', title: i18n.t('common:labels.branch') },
  { _id: 'branchNumber', title: i18n.t('common:placeholders.branchNumberFull') },
  { _id: 'reference', title: i18n.t('common:labels.reference') },
  { _id: 'familyName', title: i18n.t('common:labels.familyName') },
  { _id: 'givenName', title: i18n.t('common:labels.givenName') },
  { _id: 'convertedAmount', title: i18n.t('common:labels.convertedAmount') },
  { _id: 'convertedCurrency', title: i18n.t('common:labels.convertedCurrency') },
  { _id: 'coin', title: i18n.t('common:labels.coin') },
  { _id: 'recipientAddress', title: i18n.t('common:labels.recipientAddress') },
  { _id: 'destinationTag', title: i18n.t('common:labels.destinationTag') },
  { _id: 'beneficiaryName', title: i18n.t('common:labels.accountName') },
  { _id: 'beneficiaryFirstName', title: i18n.t('common:labels.beneficiaryFirstName') },
  { _id: 'beneficiaryLastName', title: i18n.t('common:labels.beneficiaryLastName') },
  { _id: 'beneficiaryEmail', title: i18n.t('common:labels.beneficiaryEmail') },
  { _id: 'beneficiaryPhone', title: i18n.t('common:labels.beneficiaryPhone') },
  { _id: 'beneficiaryCity', title: i18n.t('common:labels.beneficiaryCity') },
  { _id: 'details', title: i18n.t('common:labels.beneficiaryCity') },
];

export const getBankWireTH = () => [
  { _id: 'companyName', title: i18n.t('common:labels.companyName') },
  { _id: 'bankName', title: i18n.t('common:labels.bankName') },
  { _id: 'reference', title: i18n.t('common:labels.reference') },
  { _id: 'accountNumber', title: i18n.t('common:labels.accountNumber') },
  { _id: 'swiftCode', title: i18n.t('common:labels.swiftCode') },
  { _id: 'branchNumber', title: i18n.t('common:placeholders.branchNumberFull') },
];

export const getBankWire = () => ({
  aud: [
    { _id: 'bankName', title: i18n.t('common:labels.bankName') },
    { _id: 'accountName', title: i18n.t('common:labels.accountName') },
    { _id: 'branchNumber', title: i18n.t('common:placeholders.branchNumberFull') },
    { _id: 'accountNumber', title: i18n.t('common:labels.accountNumber') },
    { _id: 'reference', title: i18n.t('common:labels.reference') },
  ],
  gbp: [
    { _id: 'bankName', title: i18n.t('common:labels.bankName') },
    { _id: 'bankAddress', title: i18n.t('common:labels.bankAddress') },
    { _id: 'swiftCode', title: i18n.t('common:labels.swiftCode') },
    { _id: 'accountName', title: i18n.t('common:labels.accountName') },
    { _id: 'accountNumber', title: i18n.t('common:labels.accountNumber') },
    { _id: 'reference', title: i18n.t('common:labels.reference') },
  ],
  usd: [
    { _id: 'bankName', title: i18n.t('common:labels.bankName') },
    { _id: 'bankAddress', title: i18n.t('common:labels.bankAddress') },
    { _id: 'swiftCode', title: i18n.t('common:labels.swiftCode') },
    { _id: 'accountName', title: i18n.t('common:labels.accountName') },
    { _id: 'accountNumber', title: i18n.t('common:labels.accountNumber') },
    { _id: 'reference', title: i18n.t('common:labels.reference') },
  ],
  eur: [
    { _id: 'bankName', title: i18n.t('common:labels.bankName') },
    { _id: 'bankAddress', title: i18n.t('common:labels.bankAddress') },
    { _id: 'swiftCode', title: i18n.t('common:labels.swiftCode') },
    { _id: 'accountName', title: i18n.t('common:labels.accountName') },
    { _id: 'accountNumber', title: i18n.t('common:labels.accountNumber') },
    { _id: 'reference', title: i18n.t('common:labels.reference') },
  ],
  sgd: [
    { _id: 'bankName', title: i18n.t('common:labels.bankName') },
    { _id: 'bankAddress', title: i18n.t('common:labels.bankAddress') },
    { _id: 'swiftCode', title: i18n.t('common:labels.swiftCode') },
    { _id: 'accountName', title: i18n.t('common:labels.accountName') },
    { _id: 'accountNumber', title: i18n.t('common:labels.accountNumber') },
    { _id: 'reference', title: i18n.t('common:labels.reference') },
  ],
  jpy: [
    { _id: 'bankName', title: i18n.t('common:labels.bankName') },
    { _id: 'bankAddress', title: i18n.t('common:labels.bankAddress') },
    { _id: 'swiftCode', title: i18n.t('common:labels.swiftCode') },
    { _id: 'accountName', title: i18n.t('common:labels.accountName') },
    { _id: 'accountNumber', title: i18n.t('common:labels.accountNumber') },
    { _id: 'reference', title: i18n.t('common:labels.reference') },
  ],
});
