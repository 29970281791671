import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = [];

export default handleActions(
  {
    [actionCreators.getCountryUsersSuccess](state, action) {
      return action.response.data.data;
    },

    [actionCreators.getCountryUsersFail]() {
      return defaultState;
    },
    [actionCreators.getCountryUsersCompareSuccess](state, action) {
      return action.response.data.data;
    },
    [actionCreators.getCountryUsersCompareExportSuccess](state, action) {
      return action.response.data.data;
    },

    [actionCreators.getCountryUsersCompareFail]() {
      return defaultState;
    },
  },
  defaultState,
);
