import { CALL_STATUS } from 'constants/callStatuses';
import { ClientsTypes } from 'constants/clientTypes';
import { Roles } from 'constants/userRoles';
import { RISK_FLAGS_PICKER_VALUES } from '../pages/UserDetails/components/ClientRiskClassification/constants';

export const CLIENT_TYPES_FORM = 'CLIENT_TYPES_FORM';

export const NEW_USER_FORM = 'NEW_USER_FORM';

export const ROLE_SELECT_ITEMS = [
  { label: 'Manager', value: Roles.MANAGER },
  { label: 'Admin', value: Roles.ADMIN },
];

export const ROLE_COUNTRY_MANAGER = [{ label: 'Manager', value: Roles.MANAGER }];

export const PERSONAL_DETAILS_SECTION = 'personalDetails';
export const ACCOUNT_DETAILS_SECTION = 'accountDetails';
export const ID_VERIFICATION_SECTION = 'idVerification';

export const ADD_USER_MODAL = 'ADD_USER_MODAL';

export const EMPLOYEE_FIELDS_TO_SEND = [
  'firstName',
  'lastName',
  'email',
  'role',
  'password',
  'managedCountries',
];

export const CLIENT_DETAILS_FIELDS_TO_SEND = [
  'firstName',
  'lastName',
  'dateOfBirth',
  'email',
  'country',
  'city',
  'state',
  'zipCode',
  'addressLine1',
  'addressLine2',
  'phoneNumber',
  'phoneCountry',
  'whoReferred',
  'cxd',
  'password',
];

export const CLIENT_TYPES_VALUES = [
  { label: 'Employee', value: ClientsTypes.EMPLOYEE, permissions: [Roles.ADMIN] },
  // { label: 'IB Client', value: ClientsTypes.IB_CLIENT, permissions: [Roles.ADMIN, Roles.MANAGER] },
  { label: 'Client', value: ClientsTypes.CLIENT, permissions: [Roles.ADMIN, Roles.MANAGER] },
];

export const ALLOWED_ROLES_TO_CREATE_USER = [Roles.ADMIN, Roles.MANAGER];

export const NEW_IB_CLIENT_FORM = 'NEW_IB_CLIENT_FORM';

export const ACCOUNT_STATUSES = [
  { value: 'statusHide', label: 'Hide' },
  { value: '', label: 'Show all' },
  { value: 'new', label: 'New' },
  { value: 'lead1', label: 'Lead 1' },
  { value: 'lead2', label: 'Lead 2' },
  { value: 'lead3', label: 'Lead 3' },
  { value: 'Lead4', label: 'Lead 4' },
  { value: 'posted', label: 'Posted' },
  { value: 'approved', label: 'Approved' },
  { value: 'rejected', label: 'Rejected' },
  { value: 'funded', label: 'Funded' },
  { value: 'active', label: 'Active' },
  { value: 'dormant', label: 'Dormant' },
];

export const CALL_STATUS_RANGES = [
  { value: 'callStatusHide', label: 'Hide' },
  { value: '', label: 'Show all' },
  { value: CALL_STATUS.ATTEMPTING_CONTACT_INITIAL, label: CALL_STATUS.ATTEMPTING_CONTACT_INITIAL },
  { value: CALL_STATUS.ATTEMPTING_CONTACT_WORKING, label: CALL_STATUS.ATTEMPTING_CONTACT_WORKING },
  {
    value: CALL_STATUS.ATTEMPTING_CONTACT_NO_CONTACT,
    label: CALL_STATUS.ATTEMPTING_CONTACT_NO_CONTACT,
  },
  { value: CALL_STATUS.OVER_3_ATTEMPTS_CALLED, label: CALL_STATUS.OVER_3_ATTEMPTS_CALLED },
  { value: CALL_STATUS.SOME_INTEREST, label: CALL_STATUS.SOME_INTEREST },
  { value: CALL_STATUS.STRONG_INTEREST, label: CALL_STATUS.STRONG_INTEREST },
  { value: CALL_STATUS.NOT_INTERESTED, label: CALL_STATUS.NOT_INTERESTED },
  { value: CALL_STATUS.DO_NOT_CONTACT, label: CALL_STATUS.DO_NOT_CONTACT },
  { value: CALL_STATUS.COMPLETED, label: CALL_STATUS.COMPLETED },
  { value: CALL_STATUS.WRONG_NUMBER, label: CALL_STATUS.WRONG_NUMBER },
  { value: CALL_STATUS.NO_CALL_STATUS, label: CALL_STATUS.NO_CALL_STATUS },
];

export const RISK_CLASSIFICATIONS_PICKER_VALUES = [
  { value: 'riskClassificationsHide', label: 'Hide' },
  { value: '', label: 'Show all' },
  ...RISK_FLAGS_PICKER_VALUES,
];
