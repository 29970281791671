import React from 'react';
import fields from './index';
import { withStyles } from '@material-ui/core/styles';
import merge from 'lodash/merge';
import * as commonThemes from './themes';
import isString from 'lodash/isString';

//eslint-disable-next-line react/display-name
const withSorting = (sort) => (Component) => ({ ...props }) => <Component {...props} sort={sort} />;

const createApi = (field, styles) => {
  return {
    addTheme(themeName) {
      const { themes } = field;
      let theme = themes[themeName] ? themes[themeName] : commonThemes[themeName];

      if (!theme) {
        throw new Error(`Unknown theme: '${themeName}'`);
      }

      const mergedStyles = styles ? merge({}, styles, theme()) : theme();
      return createApi(field, mergedStyles);
    },

    addSorting(onSort, isSorted, getSortDirection) {
      if (!onSort) {
        throw new Error('onSort is not defined.');
      }
      if (!isSorted) {
        throw new Error('isSorted is not defined.');
      }
      if (!getSortDirection) {
        throw new Error('getSortDirection is not defined.');
      }
      field.sort = { onSort, isSorted, getSortDirection };
      return createApi(field, styles);
    },

    addStyles(customStyles) {
      const mergedStyles = styles ? merge({}, styles, customStyles()) : customStyles();
      return createApi(field, mergedStyles);
    },

    get() {
      const { Component } = field;
      if (styles) {
        return withSorting(field.sort)(withStyles(() => styles)(Component));
      }

      return Component;
    },
  };
};

export default class FieldsFactory {
  static create(component) {
    let field = null;
    if (isString(component)) {
      field = fields[component];
      field.sort = null;
    } else {
      field = {
        Component: component,
        themes: {},
      };
    }

    if (!field) throw new Error('Unknown field name.');
    return createApi(field);
  }
}
