import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import includes from 'lodash/includes';
import { getUserDocuments } from 'modules/auth/selectors';

import { getIpCountryCode } from 'modules/geolocation/selectors';
import {
  POLICY_COOKIE_NAME,
  COUNTRY_CODES_FOR_COOKIES,
  FC_FRAME_BOTTOM_CLASS,
} from '../../../constants';

import CookiesBanner from '../components/CookiesBanner';

const CookiesBannerContainer = () => {
  const documents = useSelector(getUserDocuments);
  const ipCountryCode = useSelector(getIpCountryCode);
  const [isBannerViewed, setIsBannerViewed] = useState(Cookies.get(POLICY_COOKIE_NAME) === 'true');
  const showBanner = useMemo(
    () => includes(COUNTRY_CODES_FOR_COOKIES, ipCountryCode) && !isBannerViewed,
    [ipCountryCode, isBannerViewed],
  );

  const handleClose = useCallback(() => {
    Cookies.set(POLICY_COOKIE_NAME, true, { expires: 365 });
    setIsBannerViewed(true);
    document.getElementById('fc_frame')?.classList.remove(FC_FRAME_BOTTOM_CLASS);
  }, []);

  return showBanner ? (
    <CookiesBanner policyLink={documents.privacy} handleClose={handleClose} />
  ) : null;
};

export default CookiesBannerContainer;
