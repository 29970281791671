const styles = () => ({
  item: {
    '@media (max-width:1280px)': {
      width: '238px',
      padding: '13px 30px 13px 25px',
    },
    '@media (min-width:1280px)': {
      padding: '13px 30px 13px 25px',
      width: '250px',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    height: '34px',
    fontSize: '12px',
    fontWeight: '900',
    lineHeight: '15px',
    whiteSpace: 'nowrap',
  },
  button__large: {
    width: '116px',
  },
  button__small: {
    width: '50px',
  },
});

export default styles;
