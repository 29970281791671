import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = 1;

export default handleActions(
  {
    [actionCreators.setLastRequestId](state, action) {
      return action.payload;
    },
  },
  defaultState,
);
