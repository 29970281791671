import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import omit from 'lodash/omit';

import { MIGRATION_FORM, WELCOME_MIGRATION_MODAL } from '../constants';
import * as actionCreators from '../actions';
import * as apiSelectors from 'modules/api/selectors';
import * as appSelectors from 'selectors';
import * as modalActionCreators from 'modules/common/CustomModal/actions';
import Migration from '../components/Migration';
import WelcomeMigrationModal from '../components/WelcomeMigrationModal';
import { getUserName } from 'modules/auth/selectors';
import config from 'config';
import { isFirstTimeOpen } from '../selectors';

class MigrationContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
    };
  }

  componentDidMount() {
    const {
      modalActions: { openModal },
      isFirstTimeOpen,
    } = this.props;
    if (isFirstTimeOpen) {
      openModal(WELCOME_MIGRATION_MODAL);
    }
  }

  onSubmit = (data) => {
    const {
      actions: { userMigrationRequest },
      token,
    } = this.props;
    data = omit(data, ['conditions']);
    userMigrationRequest({ ...data, migrationToken: token });
  };

  onCloseModal = () => {
    const {
      actions: { setIsFirstTimeOpen },
    } = this.props;
    setIsFirstTimeOpen(false);
  };

  render() {
    const { isMigrationPending, tokenHub } = this.props;
    const { userName } = this.state;
    const props = {
      onSubmit: this.onSubmit,
      form: MIGRATION_FORM,
      isLoading: isMigrationPending,
      hub: tokenHub,
    };
    const modalProps = {
      userName,
      onCloseModal: this.onCloseModal,
      modalName: WELCOME_MIGRATION_MODAL,
      destinationHub: config.CURRENT_HUB_LABEL,
    };
    return (
      <>
        <WelcomeMigrationModal {...modalProps} />
        <Migration {...props} />
      </>
    );
  }
}

MigrationContainer.propTypes = {
  isMigrationPending: PropTypes.bool.isRequired,
  token: PropTypes.string,
  actions: PropTypes.shape({
    userMigrationRequest: PropTypes.func.isRequired,
  }).isRequired,
  modalActions: PropTypes.shape({
    openModal: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    isMigrationPending: apiSelectors.createPendingSelector(actionCreators.userMigrationRequest)(
      state,
    ),
    token: appSelectors.getToken(ownProps),
    tokenHub: appSelectors.getTokenHub(ownProps),
    userName: getUserName(state),
    isFirstTimeOpen: isFirstTimeOpen(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    modalActions: bindActionCreators(modalActionCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MigrationContainer);
