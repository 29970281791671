import React, { useCallback } from 'react';
import { Avatar, Box, Grid } from 'material-latest';
import AddAlert from '@mui/icons-material/AddAlert';

import TPChip from 'components/TP-UI/TPChip';
import TPSelect from 'components/TP-UI/TPSelect';
import { Field, reduxForm } from 'redux-form';
import TPTypography from 'components/TP-UI/TPTypography';
import TPLanguageSelect from 'components/TP-UI/TPLanguageSelect';

import CurrencySelect from 'modules/common/CurrencySelect';

import styles from './styles';
import { CURRENCIES_GP } from '../../../constants';

const testRequire = (value) => {
  return value === false ? { key: 'common:errors.form.isRequired' } : undefined;
};

const Selects = () => {
  const renderFlag = useCallback(
    (option) => (
      <Avatar variant="square" sx={styles.flagIcon} alt={option.label} src={option.img} />
    ),
    [],
  );
  const renderFlagWithLabel = useCallback(
    (option) => (
      <>
        <Avatar variant="square" sx={styles.flagIcon} alt={option.label} src={option.img} />{' '}
        {option.label}
      </>
    ),
    [],
  );

  return (
    <form>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <TPTypography variant="h6">Single selection</TPTypography>
          <Field
            component={TPSelect}
            id="age"
            name="age"
            label="Age"
            placeholder="Please select age"
            fullWidth
            options={[
              { label: '20-30', value: '[20,30]' },
              { label: '30-40', value: '[30,40]' },
              { label: '40-50', value: '[40,50]' },
            ]}
            required
            className={styles.control}
          />
        </Grid>
        <Grid item xs={3}>
          <TPTypography variant="h6">Multiple selection, optional</TPTypography>
          <Field
            component={TPSelect}
            id="type"
            name="type"
            multiple
            label="Type"
            placeholder="Please select type"
            fullWidth
            options={[
              { label: 'All', value: '' },
              { label: 'Type 1', value: 1 },
              { label: 'Type 2', value: 2 },
            ]}
            clearable
            className={styles.control}
          />
        </Grid>
        <Grid item xs={3}>
          <TPTypography variant="h6">Hint</TPTypography>
          <Field
            component={TPSelect}
            id="age"
            name="age"
            label="Age"
            placeholder="Please select age"
            fullWidth
            options={[
              { label: 'All', value: '' },
              { label: '20-30', value: '[20,30]' },
              { label: '30-40', value: '[30,40]' },
              { label: '40-50', value: '[40,50]' },
            ]}
            required
            hint="Additional information"
            className={styles.control}
          />
        </Grid>
        <Grid item xs={3}>
          <TPTypography variant="h6">No label</TPTypography>
          <Field
            component={TPSelect}
            id="currency"
            name="currency"
            placeholder="No label"
            fullWidth
            options={[
              { label: 'USD - US Dollar', value: 1, abbr: 'USD' },
              { label: 'EUR - Euro', value: 2, abbr: 'EUR' },
            ]}
            className={styles.control}
          />
        </Grid>
        <Grid item xs={3}>
          <TPTypography variant="h6">Error</TPTypography>
          <Field
            component={TPSelect}
            id="currencyR"
            name="currencyR"
            label="Currency"
            placeholder="Please select currency"
            fullWidth
            options={[
              { label: 'USD - US Dollar', value: 1, abbr: 'USD' },
              { label: 'EUR - Euro', value: 2, abbr: 'EUR' },
            ]}
            validate={[testRequire]}
            required
            hint="Additional info"
            meta={{ error: { key: 'common:errors.form.isRequired' }, submitFailed: true }}
            className={styles.control}
          />
        </Grid>
        <Grid item xs={3}>
          <TPTypography variant="h6">Disabled</TPTypography>
          <Field
            component={TPSelect}
            id="currency"
            name="currency"
            label="Disabled"
            disabled
            placeholder="Please select currency"
            fullWidth
            options={[
              { label: 'USD - US Dollar', value: 1, abbr: 'USD' },
              { label: 'EUR - Euro', value: 2, abbr: 'EUR' },
            ]}
            hint="Additional info"
            className={styles.control}
          />
        </Grid>
        <Grid item xs={3}>
          <TPTypography variant="h6">Grouping, hide arrow</TPTypography>
          <Field
            component={TPSelect}
            name="country"
            label="Groups"
            placeholder="Please select country"
            fullWidth
            groupBy="group"
            hideArrow
            options={[
              { label: 'Australia', value: 1, group: 'A', count: 10 },
              { label: 'Belarus', value: 2, group: 'B', count: 10 },
              { label: 'Belgium', value: 3, group: 'B', count: 5 },
            ]}
            hint="Additional info"
            className={styles.control}
          />
        </Grid>
        <Grid item xs={3}>
          <TPTypography variant="h6">Small size, clearable</TPTypography>
          <Field
            component={TPSelect}
            name="country"
            placeholder="Small size"
            fullWidth
            size="small"
            options={[
              { label: 'Australia', value: 1, group: 'A' },
              { label: 'Belarus', value: 2, group: 'B' },
            ]}
            hint="Additional info"
            clearable={true}
            className={styles.control}
          />
        </Grid>
        <Grid item xs={3}>
          <TPTypography variant="h6">Custom selected value render</TPTypography>
          <Field
            component={TPSelect}
            id="currency"
            name="currency"
            label="Currency"
            placeholder="Please select currency"
            fullWidth
            options={[
              { label: 'USD - US Dollar', value: 1, abbr: 'USD' },
              { label: 'EUR - Euro', value: 2, abbr: 'EUR' },
            ]}
            required
            renderValue={(selected) => selected.abbr}
            hint="Additional information"
            className={styles.control}
          />
        </Grid>
        <Grid item xs={3}>
          <TPTypography variant="h6">Custom selected values render</TPTypography>
          <Field
            component={TPSelect}
            id="currencyM"
            name="currencyM"
            label="Currency multiple"
            placeholder="Please select currency"
            fullWidth
            options={[
              { label: 'USD - US Dollar', value: 1, abbr: 'USD' },
              { label: 'EUR - Euro', value: 2, abbr: 'EUR' },
            ]}
            required
            multiple
            renderValue={(selected) => selected.map((s) => s.abbr).join(', ')}
            hint="Additional information"
            className={styles.control}
          />
        </Grid>
        <Grid item xs={3}>
          <TPTypography variant="h6">Custom selected values render as chips</TPTypography>
          <Field
            component={TPSelect}
            id="currencyM"
            name="currencyM"
            label="Currency multiple chips"
            placeholder="Please select currency"
            fullWidth
            options={[
              { label: 'USD - US Dollar', value: 1, abbr: 'USD' },
              { label: 'EUR - Euro', value: 2, abbr: 'EUR' },
            ]}
            required
            multiple
            renderValue={(selected) => (
              <Box sx={styles.chips}>
                {selected.map((s) => (
                  <TPChip key={s.value} size="small" label={s.abbr} />
                ))}
              </Box>
            )}
            hint="Additional information"
            className={styles.control}
          />
        </Grid>
        <Grid item xs={3}>
          <TPTypography variant="h6">Custom options render</TPTypography>
          <Field
            component={TPSelect}
            id="currency"
            name="currency"
            label="Custom Option"
            placeholder="Please select currency"
            fullWidth
            options={[
              { label: 'US Dollar', value: 1, abbr: 'USD' },
              { label: 'Euro', value: 2, abbr: 'EUR' },
            ]}
            renderOption={(option) => (
              <span>
                {option.abbr} - {option.label}
              </span>
            )}
            hint="Additional info"
            className={styles.control}
          />
        </Grid>
        <Grid item xs={3}>
          <TPTypography variant="h6">Custom options render with icons</TPTypography>
          <Field
            component={TPSelect}
            id="currency"
            name="currency"
            label="Custom Option"
            placeholder="Please select currency"
            fullWidth
            options={[
              { label: 'US Dollar', value: 1, icon: 'add_circle' },
              { label: 'Euro', value: 2, icon: 'AddAlert' },
            ]}
            renderOption={(option) => (
              <>
                <AddAlert fontSize="small" /> - {option.label}
              </>
            )}
            hint="Additional info"
            className={styles.control}
          />
        </Grid>
        <Grid item xs={3}>
          <TPTypography variant="h6">Custom variant </TPTypography>
          <Field
            component={TPSelect}
            id="country2"
            name="country2"
            variant="custom"
            options={[
              {
                label: 'Great Britain',
                value: 1,
              },
              {
                label: 'Thailand',
                value: 2,
              },
            ]}
            className={styles.control}
          />
        </Grid>
        <Grid item xs={3}>
          <TPTypography variant="h6">
            Custom variant, custom value and option renders, hidden arrow{' '}
          </TPTypography>
          <Field
            component={TPSelect}
            id="country2"
            name="country2"
            variant="custom"
            hideArrow
            options={[
              {
                label: 'Great Britain',
                value: 1,
                img: 'http://localhost:3000/static/media/en.2e9c71c7.svg',
              },
              {
                label: 'Thailand',
                value: 2,
                img: 'http://localhost:3000/static/media/th.9667e838.svg',
              },
            ]}
            renderValue={renderFlag}
            renderOption={renderFlag}
            className={styles.control}
          />
        </Grid>
        <Grid item xs={3}>
          <TPTypography variant="h6">Custom variant, custom value and option renders </TPTypography>
          <Field
            component={TPSelect}
            id="country2"
            name="country2"
            variant="custom"
            options={[
              {
                label: 'Great Britain',
                value: 1,
                img: 'http://localhost:3000/static/media/en.2e9c71c7.svg',
              },
              {
                label: 'Thailand',
                value: 2,
                img: 'http://localhost:3000/static/media/th.9667e838.svg',
              },
            ]}
            renderValue={renderFlag}
            renderOption={renderFlagWithLabel}
            className={styles.control}
          />
        </Grid>
        <Grid item xs={3}>
          <TPTypography variant="h6">TPLanguageSelect component</TPTypography>
          <Field
            component={TPLanguageSelect}
            id="language"
            name="language"
            hideArrow={false}
            className={styles.control}
            options={[
              {
                label: 'English',
                language: 'en',
                icon: 'http://localhost:3000/static/media/en.2e9c71c7.svg',
              },
              {
                label: 'Thai',
                language: 'th',
                icon: 'http://localhost:3000/static/media/th.9667e838.svg',
              },
            ]}
          />
        </Grid>
        <Grid item xs={3}>
          <TPTypography variant="h6">Minimized TPLanguageSelect component</TPTypography>
          <Field
            component={TPLanguageSelect}
            id="languageMinimized"
            name="languageMinimized"
            minimized
            options={[
              {
                label: 'English',
                language: 'en',
                icon: 'http://localhost:3000/static/media/en.2e9c71c7.svg',
              },
              {
                label: 'Thai',
                language: 'th',
                icon: 'http://localhost:3000/static/media/th.9667e838.svg',
              },
            ]}
            className={styles.control}
          />
        </Grid>
        <Grid item xs={3}>
          <TPTypography variant="h6">CurrencySelect component (based on TPSelect)</TPTypography>
          <Field
            component={CurrencySelect}
            id="currencyFrom"
            name="currencyFrom"
            options={CURRENCIES_GP}
            label="Currency"
            placeholder="Currency"
            className={styles.control}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default reduxForm({
  form: 'TP-SELECTS-FORM',
  initialValues: {
    age: null,
    type: [],
    currency: 1,
    currencyM: [1],
    currencyR: null,
    country: null,
    country2: 1,
    language: 'en',
    languageMinimized: 'en',
    currencyFrom: null,
  },
})(Selects);
