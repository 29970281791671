import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isMobile, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Box } from 'material-latest';

import { getFileExtension, isFile, getImageUrl } from '../../../helpers/file';
import TPIconButton from 'components/TP-UI/TPIconButton';
import TPTypography from 'components/TP-UI/TPTypography';
import TPImage from 'components/TP-UI/TPImage';
import TPTooltip from 'components/TP-UI/TPTooltip';
import { SIZES } from 'components/TP-UI/constants';

import styles from './styles';

export const TPFileItem = ({
  value,
  onClick,
  onDelete,
  className,
  disabled = false,
  error,
  startAdornment,
  endAdornment,
}) => {
  const { t } = useTranslation('common');
  const { file, url, originalName } = value || {};
  const name = file && isFile(file) ? file.name || 'camera-photo' : originalName || '';
  const extension = getFileExtension(name);
  const imageUrl = useMemo(() => getImageUrl(url, file), [url, file]);
  useEffect(() => {
    // Revoke the data uris to avoid memory leaks, will run on unmount
    return () => imageUrl && typeof imageUrl === 'object' && URL.revokeObjectURL(imageUrl);
  }, [imageUrl]);

  const handleClick = useCallback(
    (e) => {
      if (!disabled && onClick) {
        onClick(e);
      }
    },
    [onClick, disabled],
  );

  return (
    <Box sx={[className, styles.root]}>
      {startAdornment}
      <Box
        sx={[
          styles.fileContainer,
          !disabled && onClick && styles.clickable,
          error && styles.fileContainerError,
        ]}
        onClick={handleClick}>
        {imageUrl ? (
          <TPImage className={styles.fileImage} src={imageUrl} alt={name} />
        ) : (
          <Box sx={styles.nonImageFileContainer}>
            <DescriptionOutlinedIcon fontSize={SIZES.LARGE} />
            <TPTypography component="span" variant="body2" bold className={styles.fileExtension}>
              {extension}
            </TPTypography>
          </Box>
        )}
      </Box>
      <Box sx={styles.fileItemDescription}>
        <TPTypography
          component="span"
          variant="body2"
          color={error ? 'error' : 'primary'}
          truncated
          lineClamp={1}>
          {name}
        </TPTypography>
        {onDelete ? (
          <TPTooltip content={t('labels.delete')} disabled={isTablet || isMobile}>
            <TPIconButton disabled={disabled} onClick={onDelete} size={SIZES.SMALL}>
              <DeleteOutlineIcon fontSize={SIZES.SMALL} />
            </TPIconButton>
          </TPTooltip>
        ) : null}
      </Box>
      {endAdornment}
    </Box>
  );
};

TPFileItem.propTypes = {
  value: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  startAdornment: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  endAdornment: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};

const TPReduxFileItem = ({ input, meta, errors, ...others }) => {
  const error = meta.submitFailed && errors ? errors[0] : null;
  const { value } = input || {};
  return <TPFileItem items={value} {...input} error={error} {...others} />;
};

export default TPReduxFileItem;
