import moment from 'moment';
import 'moment/locale/th';
import 'moment/locale/zh-cn';
import 'moment/locale/en-au';
import Cookies from 'js-cookie';

import { DATE_FORMAT_FULL, LANG_COOKIE_NAME, languages } from 'constants/index';

export default (date, useUTC) => {
  const language = Cookies.get(LANG_COOKIE_NAME);
  const formattedLanguage =
    language && languages.CHINESE && language === languages.CHINESE ? 'zh-cn' : language;
  moment.locale(formattedLanguage);
  const momentDate = useUTC ? moment.utc(date, moment.ISO_8601) : moment(date, moment.ISO_8601);
  return date ? momentDate.format(DATE_FORMAT_FULL) : null;
};
