import isEqual from 'lodash/isEqual';

const checkPassword = (password, confirmPassword) =>
  password && confirmPassword && !isEqual(password, confirmPassword);

export const equalPasswords = (formData = {}) => {
  if (
    formData.personalDetails &&
    checkPassword(formData.personalDetails.password, formData.personalDetails.confirmPassword)
  ) {
    return {
      personalDetails: {
        confirmPassword: { key: 'common:errors.form.confirmPassword' },
      },
    };
  }

  if (checkPassword(formData.password, formData.confirmPassword)) {
    return {
      confirmPassword: { key: 'common:errors.form.confirmPassword' },
    };
  }

  return {};
};

export const acceptDocuments = (formData) => {
  if (!formData.conditions) {
    return { _error: { key: 'common:errors.form.acceptDocuments' } };
  }
  return {};
};
