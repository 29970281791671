import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';

const DropdownIndicator = (props) => {
  const { dropDownIconClassName } = props;
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? (
        <ArrowDropUp className={dropDownIconClassName} />
      ) : (
        <ArrowDropDown className={dropDownIconClassName} />
      )}
    </components.DropdownIndicator>
  );
};

DropdownIndicator.propTypes = {
  selectProps: PropTypes.shape({
    menuIsOpen: PropTypes.bool.isRequired,
  }),
  dropDownIconClassName: PropTypes.string.isRequired,
};

export default DropdownIndicator;
