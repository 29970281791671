export default {
  root: {
    maxWidth: '120px',
  },
  fileContainer: (theme) => ({
    display: 'flex',
    width: '100%',
    height: '120px',
    overflow: 'hidden',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.primary.lightest}`,
  }),
  fileImage: {
    width: 'auto',
    maxWidth: 'fit-content',
  },
  fileExtension: {
    textTransform: 'uppercase',
  },
  nonImageFileContainer: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.lightest,
    padding: theme.spacing(2),
    flex: 1,
  }),
};
