export const editorConfig = {
  height: 500,
  menubar: false,
  plugins: [
    'advlist',
    'autolink',
    'lists',
    'link',
    'image',
    'charmap',
    'preview',
    'anchor',
    'searchreplace',
    'visualblocks',
    'code',
    'fullscreen',
    'insertdatetime',
    'media',
    'table',
    'help',
    'wordcount',
  ],
  toolbar: `
      undo redo | blocks | 
      bold italic backcolor | numlist bullist |
      alignleft aligncenter alignright alignjustify |
      link image | code`,
  content_style: 'body { font-family:Lato,sans-serif; font-size:14px }',
  image_title: true,
  automatic_uploads: true,
  file_picker_types: 'image',
  images_reuse_filename: true,
  relative_urls: false,
  remove_script_host: false,
};
