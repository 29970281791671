const styles = () => ({
  root: {
    position: 'fixed',
    width: '600px',
    //left: '30%',
    maxWidth: '70%',
    top: 10,
    '@media (max-width:1024px)': {
      top: '75px',
      width: '100%',
      padding: '0 10px',
      //left: '0',
      maxWidth: '100%',
    },
    zIndex: 1500,
  },
});

export default styles;
