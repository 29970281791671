import React, { createContext, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { getIsFirstVisit } from '../../auth/selectors';
import isMobile from '../../../utils/isMobile';

const BottomNavigationContext = createContext();

const useProvideBottomNavigation = () => {
  const isFirstUserVisit = useSelector(getIsFirstVisit);
  const [menuOpen, setMenuOpen] = useState(isFirstUserVisit && isMobile());

  return {
    menuOpen,
    setMenuOpen,
  };
};

export const useBottomNavigation = () => {
  return useContext(BottomNavigationContext);
};

export const BottomNavigationProvider = ({ children }) => {
  const bottomNavigation = useProvideBottomNavigation();
  return (
    <BottomNavigationContext.Provider value={bottomNavigation}>
      {children}
    </BottomNavigationContext.Provider>
  );
};
