import React from 'react';
import LpoaDocumentModal from '../components/LpoaDocumentModal';

const LpoaContainer = () => {
  return (
    <>
      <LpoaDocumentModal />
    </>
  );
};

export default LpoaContainer;
