export default {
  multilineAdornment: {
    alignSelf: 'baseline',
  },
  errorContainer: (theme) => ({
    minHeight: theme.textError.root.minHeight,
  }),
  password: {
    '& input': {
      textOverflow: 'unset',
    },
  },
  number: {
    '& input': {
      appearance: 'textfield',
    },
  },
};
