export const UPLOADER_FILE_TYPE = {
  PNG: 'PNG',
  JPG: 'JPG',
  JPEG: 'JPEG',
  CSV: 'CSV',
  PDF: 'PDF',
  XLS: 'XLS',
};
export const MIME_TYPE = {
  [UPLOADER_FILE_TYPE.CSV]: 'text/csv',
  [UPLOADER_FILE_TYPE.PDF]: 'application/pdf',
  [UPLOADER_FILE_TYPE.PNG]: 'image/png',
  [UPLOADER_FILE_TYPE.JPG]: 'image/jpg',
  [UPLOADER_FILE_TYPE.JPEG]: 'image/jpeg',
  [UPLOADER_FILE_TYPE.XLS]: 'application/vnd.ms-excel',
};
export const ACCEPT_FILE_UPLOAD_TYPES = {
  [UPLOADER_FILE_TYPE.CSV]: { [MIME_TYPE.CSV]: [] },
  [UPLOADER_FILE_TYPE.PDF]: { [MIME_TYPE.PDF]: [] },
  [UPLOADER_FILE_TYPE.PNG]: { [MIME_TYPE.PNG]: [] },
  [UPLOADER_FILE_TYPE.JPG]: { [MIME_TYPE.JPG]: [] },
  [UPLOADER_FILE_TYPE.JPEG]: { [MIME_TYPE.JPEG]: [] },
  [UPLOADER_FILE_TYPE.XLS]: { [MIME_TYPE.XLS]: [] }, // need to ask
};
