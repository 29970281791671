import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { store } from 'components/App';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MainLayout from 'modules/common/MainLayout';
import DocumentTitle from 'react-document-title';
import PrimaryButton from 'modules/common/PrimaryButton';
import * as apiSelectors from 'modules/api/selectors';
import * as authSelectors from 'modules/auth/selectors';
import * as accountsSelectors from 'modules/accounts/pages/MyAccounts/selectors';
import * as actions from '../actions';
import * as accountsActionCreators from 'modules/accounts/pages/MyAccounts/actions';
import { getWaitlistCompleted, getWaitlist } from '../selectors';
import NotAllowed from '../components/NotAllowed';
import styles from './styles';
import { ACCOUNT_STATUSES } from 'constants/index';

const Waitlist = lazy(() => import('../components/Waitlist'));

const MyFollowersContainer = ({ classes }) => {
  const { t } = useTranslation('myFollowers');
  const dispatch = useDispatch();
  const [hasCopy, setHasCopy] = useState(false);
  const [hasJoin, setHasJoin] = useState(false);
  const allowCopyAccount = useSelector(authSelectors.allowCopyAccount);
  const hub = useSelector(authSelectors.getUserHub);
  const isWaitlistCompleted = useSelector(getWaitlistCompleted);
  const waitlist = useSelector(getWaitlist);
  const accounts = useSelector(accountsSelectors.getLiveAccounts);
  const myFollowersJoinWaitlistPending = useSelector(
    apiSelectors.createPendingSelector(actions.myFollowersJoinWaitlistCopyRequest),
  );
  const myFollowersJoinWaitlistJoinPending = useSelector(
    apiSelectors.createPendingSelector(actions.myFollowersJoinWaitlistRequest),
  );
  const isWaitlistPending = useSelector(
    apiSelectors.createPendingSelector(actions.myFollowersGetWaitlistRequest),
  );

  useEffect(() => {
    import('../sagas/waitlist').then((saga) => {
      store.injectSaga('MyFollowersWaitlist', saga.default);
    });
    dispatch(accountsActionCreators.getAccountsRequest({ isLive: true }));
    dispatch(actions.myFollowersGetWaitlistRequest());
  }, [dispatch]);

  const openMyFollowersJoinWaitlist = useCallback(() => {
    dispatch(actions.openMyFollowersJoinWaitlist());
  }, [dispatch]);

  const openMyFollowersJoinWaitlistCopy = useCallback(() => {
    dispatch(actions.openMyFollowersJoinWaitlistCopy());
  }, [dispatch]);

  const myFollowersJoinWaitlist = useCallback(
    (data) => {
      dispatch(actions.myFollowersJoinWaitlistCopyRequest(data));
    },
    [dispatch],
  );

  const myFollowersJoinWaitlistApply = useCallback(
    (data) => {
      dispatch(actions.myFollowersJoinWaitlistRequest(data));
    },
    [dispatch],
  );

  useEffect(() => {
    setHasCopy(waitlist.some((w) => w.type === 'copy'));
    setHasJoin(waitlist.some((w) => w.type === 'join'));
  }, [waitlist]);

  if (hub && !allowCopyAccount) {
    return <NotAllowed />;
  }

  const avaliableAccounts = accounts.filter((acc) => {
    if (acc.status !== ACCOUNT_STATUSES.APPROVED) {
      return false;
    }

    if (waitlist.findIndex((wAcc) => wAcc.accountId === acc._id) === -1) {
      return acc;
    }
    return false;
  });

  return (
    <MainLayout
      title={t('waitlist.joinWaitlist')}
      titleClassName={classes.waitlistHeader}
      contentClassName={classes.waitlistLayout}
      mobileContent={
        <>
          {hasJoin ? null : (
            <PrimaryButton
              className={`${classes.waitlistJoinButton} ${classes.waitlistButtonMobile}`}
              color="primary"
              size="large"
              label={t('waitlist.joinWaitlistMobile')}
              onClick={openMyFollowersJoinWaitlist}
            />
          )}
          {hasCopy ? null : (
            <PrimaryButton
              className={classes.waitlistButtonMobile}
              color="primary"
              size="large"
              label={t('waitlist.applyNowMobile')}
              onClick={openMyFollowersJoinWaitlistCopy}
            />
          )}
        </>
      }
      desktopContent={
        <>
          {hasJoin ? null : (
            <PrimaryButton
              className={classes.waitlistJoinButton}
              color="primary"
              size="large"
              label={t('waitlist.joinWaitlist')}
              onClick={openMyFollowersJoinWaitlist}
            />
          )}
          {hasCopy ? null : (
            <PrimaryButton
              color="primary"
              size="large"
              label={t('waitlist.applyNow')}
              onClick={openMyFollowersJoinWaitlistCopy}
            />
          )}
        </>
      }>
      <DocumentTitle title={t('waitlist.joinWaitlist')} />
      <Suspense>
        {isWaitlistPending ? null : (
          <Waitlist
            accounts={avaliableAccounts}
            openMyFollowersJoinWaitlist={openMyFollowersJoinWaitlist}
            openMyFollowersJoinWaitlistCopy={openMyFollowersJoinWaitlistCopy}
            myFollowersJoinWaitlist={myFollowersJoinWaitlist}
            myFollowersJoinWaitlistApply={myFollowersJoinWaitlistApply}
            myFollowersJoinWaitlistPending={myFollowersJoinWaitlistPending}
            myFollowersJoinWaitlistApplyPending={myFollowersJoinWaitlistJoinPending}
            isWaitlistCompleted={isWaitlistCompleted}
            hasCopy={hasCopy}
            hasJoin={hasJoin}
          />
        )}
      </Suspense>
    </MainLayout>
  );
};

export default withStyles(styles)(withRouter(MyFollowersContainer));
