import { useMemo } from 'react';

export default function useTabsDefaultPaths(tabsProps) {
  return useMemo(() => {
    return tabsProps.map((tabProps) => ({ ...tabProps, path: getPath(tabProps) }));
  }, [tabsProps]);
}

function getPath(tabProps) {
  if ('path' in tabProps) {
    return tabProps.path;
  }

  if (typeof tabProps.label === 'string') {
    return tabProps.label
      .split(' ')
      .join('-')
      .toLowerCase();
  }

  throw Error('Either path or string label should be provided to Component');
}
