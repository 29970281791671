import { fontSize } from '../../font';

const OverridesMuiSvgIcon = {
  styleOverrides: {
    root: {
      fontSize: fontSize._24,
    },

    fontSizeLarge: {
      fontSize: fontSize._36,
    },

    fontSizeSmall: {
      fontSize: fontSize._16,
    },

    fontSizeXsmall: {
      fontSize: fontSize._12,
    },

    fontSizeInherit: {
      fontSize: 'inherit',
    },
  },
};
export default OverridesMuiSvgIcon;
