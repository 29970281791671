import get from 'lodash/get';
import React, { useMemo, useState, useEffect } from 'react';
import { withStyles, Typography } from '@material-ui/core';
import styles from './styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextGroup from 'modules/common/TextGroup/';
import { Line } from 'react-chartjs-2';
import Header from '../../components/BaseHeader';
import getGridOptions from './getGridOptions';
import getDataset from './getDataset';
import { DASHBOARD_METRICS, DASHBOARD_OVERVIEW_DATA_LABEL } from '../../constants';
import noMatchesFound from '../../../../static/no_matches_found.svg';
import Spinner from '../../../common/Spinner';

const Grow = ({ isGrow, lastValue, firstValue }) => {
  const last = get(lastValue, DASHBOARD_METRICS.activeUsers, 0);
  const first = get(firstValue, DASHBOARD_METRICS.activeUsers, 0);

  return <>{` (${isGrow ? '+' : ''}${last - first})`}</>;
};

const ActiveUsersPage = ({ classes, isPending, data, datasets, headerProps }) => {
  const options = useMemo(() => getGridOptions(isPending), [isPending]);
  const dataset = useMemo(() => getDataset(datasets), [datasets]);

  const getFirstActualActiveUsersItem = (data) => getLastActualActiveUsersItem([...data].reverse());

  const getLastActualActiveUsersItem = (data) => {
    for (let i = data.length; i-- > 0; ) {
      if (data[i].activeUsers) return data[i];
    }
  };

  const [lastValue, setLastValue] = useState();
  const [firstValue, setFirstValue] = useState();
  const [isGrow, setIsGrow] = useState(false);

  useEffect(() => {
    if (!data) {
      return;
    }

    const firstItem = getFirstActualActiveUsersItem(data);
    const lastItem = getLastActualActiveUsersItem(data);
    setFirstValue(firstItem);
    setLastValue(lastItem);

    setIsGrow(
      get(lastItem, DASHBOARD_METRICS.activeUsers, 0) >
        get(firstItem, DASHBOARD_METRICS.activeUsers, 0),
    );
  }, [data]);

  return (
    <React.Fragment>
      <Header {...headerProps} />
      <Grid container direction="column" className={`${classes.sectionText}`}>
        {isPending ? (
          <Grid container justify="center" alignItems="center" className={classes.root}>
            <Spinner />
          </Grid>
        ) : !datasets || !(datasets[DASHBOARD_METRICS.activeUsers] || []).length ? (
          <Grid
            container
            className={classes.root}
            justify="center"
            alignItems="center"
            direction="column">
            <img src={noMatchesFound} alt="no-details-yet" />
            <TextGroup text="No active users for this time period" />
          </Grid>
        ) : (
          <Grid container direction="column">
            {lastValue ? (
              <Grid item xs={4} md={3} className={classes.usersCount}>
                <div className={classes.usersCountContainer}>
                  <Typography className={classes.title}>
                    {DASHBOARD_OVERVIEW_DATA_LABEL.activeUsers}
                  </Typography>
                  <Typography className={classes.lastValue}>
                    {lastValue[DASHBOARD_METRICS.activeUsers]}
                    <span>
                      <Grow isGrow={isGrow} lastValue={lastValue} firstValue={firstValue} />
                    </span>
                  </Typography>
                </div>
              </Grid>
            ) : null}
            <Grid item xs={12} md={12} className={classes.field}>
              <Line height={360} data={dataset} options={options} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

ActiveUsersPage.propTypes = {
  isPending: PropTypes.bool,
  items: PropTypes.array,
  headerProps: PropTypes.object,
};

export default withStyles(styles)(ActiveUsersPage);
