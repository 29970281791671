export const VIDEO_SETTINGS = {
  width: { min: 1024, ideal: 1280, max: 1920 },
  height: { min: 576, ideal: 720, max: 1080 },
};

export const WEBCAM_STATE = {
  WAIT: 'wait',
  DENIED: 'denied',
  GRANTED: 'granted',
  NO_CAMERA: 'noCamera',
};

export const WEBCAM_STATE_DEFAULT_MESSAGE = {
  [WEBCAM_STATE.DENIED]:
    "Seems you've denied access to the camera, please allow it on browser security preferences or choose to upload your documents instead.",
  [WEBCAM_STATE.GRANTED]: 'Camera loading, one moment please.',
  [WEBCAM_STATE.WAIT]:
    'Camera initializing. If your browser is showing a pop up, please press ‘allow’ to use this feature.',
  [WEBCAM_STATE.NO_CAMERA]: 'The device does not have a camera.',
};
