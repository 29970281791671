import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { ACCOUNT_COUNTRY_FILTER } from '../../constants';
import FormSelect from 'modules/common/FormSelect';
import { getIsAdminRole } from 'modules/auth/selectors';
import { getFullName } from 'utils/getFullName';

import styles from './styles';

const CountryUsersFilters = ({ classes, selectCountryFilter, leadOwners }) => {
  const leadOwnersOptions = useMemo(
    () => [
      {
        value: '',
        label: 'All Owners',
      },
      ...leadOwners.map((user) => ({ label: getFullName(user), value: user.email })),
    ],
    [leadOwners],
  );
  const isAdmin = useSelector(getIsAdminRole);
  return (
    <>
      <Field
        name="countryFilter"
        id="countryFilter"
        component={FormSelect}
        onChange={selectCountryFilter}
        items={ACCOUNT_COUNTRY_FILTER}
        multiple={false}
      />
      {isAdmin ? (
        <Field
          name="asUser"
          id="asUser"
          component={FormSelect}
          items={leadOwnersOptions}
          multiple={false}
          className={classes.select}
        />
      ) : null}
    </>
  );
};

CountryUsersFilters.propTypes = {
  selectCountryFilter: PropTypes.func.isRequired,
};

export default withStyles(styles)(CountryUsersFilters);
