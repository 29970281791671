import { combineActions, handleActions } from 'redux-actions';

import * as geolocationActions from 'modules/geolocation/actions';

const defaultState = null;

export default handleActions(
  {
    [combineActions(geolocationActions.getCountryByIpSuccess)](
      state,
      {
        response: {
          data: { country },
        },
      },
    ) {
      return country;
    },
  },
  defaultState,
);
