import MenuList from '@material-ui/core/MenuList';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getTranslation } from 'utils/compositeTranslationHandler';
import { MENU_ITEM_TYPE } from '../../constants';
import NavigationMenuListItem from '../NavigationMenuListItem';
import NavigationMenuNestedListItem from '../NavigationMenuNestedListItem';
import styles from './styles';

const NavigationMenuList = ({
  classes,
  selectedRoute,
  onMenuClick = () => {},
  menuItemsList,
  isCollapsed,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <MenuList className={classes.menuList} component="nav">
        {menuItemsList.map(({ label, id, icon, url, type, items }) => {
          if (type === MENU_ITEM_TYPE.NESTED) {
            return (
              <NavigationMenuNestedListItem
                id={id}
                key={id}
                label={getTranslation(t, label)}
                selectedRoute={selectedRoute}
                items={items}
                icon={icon}
                onClick={onMenuClick}
                isCollapsed={isCollapsed}
              />
            );
          }
          return (
            <NavigationMenuListItem
              id={id}
              key={id}
              label={getTranslation(t, label)}
              isSelected={selectedRoute.includes(url)}
              onClick={onMenuClick}
              icon={icon}
              link={url}
              isCollapsed={isCollapsed}
            />
          );
        })}
      </MenuList>
    </div>
  );
};

NavigationMenuList.propTypes = {
  selectedRoute: PropTypes.string.isRequired,
  onMenuClick: PropTypes.func.isRequired,
  menuItemsList: PropTypes.array.isRequired,
};

export default withStyles(styles)(NavigationMenuList);
