import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import { ErrorOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { getTranslation } from 'utils/compositeTranslationHandler';
import Radio from '../Radio';

import styles from './styles';

const RadioGroupInput = ({
  classes,
  input,
  meta: { error = null, submitFailed = null },
  buttons,
  disabled,
  ...others
}) => {
  const { t } = useTranslation();

  return (
    <FormControl component="fieldset" className={classes.fieldset}>
      <RadioGroup {...input} {...others}>
        {buttons.map(({ value, label }) => (
          <Radio key={value} value={value} label={label} disabled={disabled} />
        ))}
      </RadioGroup>
      {error && submitFailed && (
        <span className={classes.error}>
          <ErrorOutline className={classes.errorIcon} />
          &nbsp;
          {getTranslation(t, error)}
        </span>
      )}
    </FormControl>
  );
};

RadioGroupInput.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    submitFailed: PropTypes.bool.isRequired,
  }).isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
    }).isRequired,
  ).isRequired,
  disabled: PropTypes.bool,
};

RadioGroupInput.defaultProps = {
  meta: {
    error: null,
    submitFailed: false,
  },
};

export default withStyles(styles)(RadioGroupInput);
