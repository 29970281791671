import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = [];

export default handleActions(
  {
    [actionCreators.getCountriesSuccess](state, { response }) {
      return response.data.countries;
    },
    [actionCreators.getCountriesFail]() {
      return defaultState;
    },
  },
  defaultState,
);
