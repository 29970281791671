export default {
  form: (theme) => ({
    margin: `${theme.spacing(2)} 0 0 !important`,
  }),
  paymentButtons: (theme) => ({
    marginTop: theme.spacing(2),
  }),
  note: (theme) => ({
    paddingTop: theme.spacing(2),
  }),
  buttonIcon: (theme) => ({
    marginRight: theme.spacing(1),
  }),
  button: (theme) => ({
    marginBottom: theme.spacing(1),
  }),
  image: {
    minHeight: 100,
  },
  headerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
  },
  customTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  primaryText: {
    flex: 1,
  },
  marker: (theme) => ({
    fontSize: '12px',
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1),
  }),
  inlineList: (theme) => ({
    color: theme.palette.textSecondary.dark,
    marginBottom: 0,
    padding: 0,
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    marginBlockStart: 0,
  }),
  inlineListItem: (theme) => ({
    alignItems: 'center',
    display: 'inline-flex',
    '&:after': {
      content: '""',
      height: 3,
      width: 3,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.textSecondary.dark,
      borderRadius: '50%',
      display: 'inline-block',
    },
    '&:last-child:after': {
      display: 'none',
    },
  }),
  logo: {
    width: 'auto',
  },
};
