import { ALL_CURRENCIES, TRADING_PLATFORMS } from '../constants';
import { getAccountTypes, getSecretQuestions } from '../helpers';
import { QuizStatuses } from 'constants/quizStatus';

export const cardNumber = (value) =>
  value && value.replace(/\d{4}/g, (match) => match + ' ').trim();

export const formatLeverage = (value) => {
  return `1:${value}`;
};

export const formatCurrency = (value) => {
  const currency = ALL_CURRENCIES.find((currency) => currency.value === value);
  return currency ? currency.label : null;
};

export const formatSecretQuestion = (value) => {
  const question = getSecretQuestions().find((question) => question.value === value);
  return question ? `${question.label}?` : null;
};

export const formatAccountType = (value) => {
  const accountType = getAccountTypes().find((type) => type.value === value);
  return accountType ? accountType.label : null;
};

export const formatTradingPlatform = (value) => {
  const tradingPlatform = TRADING_PLATFORMS.find((platform) => platform.value === value);
  return tradingPlatform ? tradingPlatform.label : null;
};

export const formatSuffixOfNumber = (number) => {
  const ones = number % 10,
    tens = number % 100;
  if (ones === 1 && tens !== 11) {
    return number + 'st';
  } else if (ones === 2 && tens !== 12) {
    return number + 'nd';
  } else if (ones === 3 && tens !== 13) {
    return number + 'rd';
  }
  return number + 'th';
};

export const formatQuizProgress = (quizProgress) => {
  if (!quizProgress) {
    return QuizStatuses.HAS_NOT_TRIED;
  }
  const { wasFirstTry, wasSecondTry, passed, retailPassed, retailFailedStep } = quizProgress;
  if (retailPassed === undefined && wasFirstTry === undefined) {
    return QuizStatuses.HAS_NOT_TRIED;
  }
  if (retailFailedStep) {
    return retailFailedStep === 1 ? QuizStatuses.ATTEMPT1 : QuizStatuses.ATTEMPT2;
  }
  if (wasFirstTry && !wasSecondTry && !passed) {
    return QuizStatuses.ATTEMPT1;
  }
  if ((wasFirstTry && wasSecondTry && !passed) || retailPassed === false) {
    return QuizStatuses.ATTEMPT2;
  }
  return QuizStatuses.PASSED;
};

export const trimArray = (array) => {
  return array.map((item) => {
    const keys = Object.keys(item);
    const result = {};
    keys.forEach((key) => {
      result[key] = item[key] && item[key].trim ? item[key].trim() : item[key];
    });
    return result;
  });
};

export const formatNewQuizProgress = (quizProgress) => {
  if (!quizProgress) return '';

  if (!quizProgress.isPassed) {
    if (quizProgress.attempts === 0) {
      return QuizStatuses.HAS_NOT_TRIED;
    }

    if (quizProgress.attempts === 1) {
      return QuizStatuses.ATTEMPT1;
    }

    return QuizStatuses.ATTEMPT2;
  }

  return QuizStatuses.PASSED;
};
