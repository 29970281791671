import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import styles from './styles';
import ResultsRow from './ResultsRow';
import { Grid } from 'material-latest';

const ResultsRowsGroup = ({ classes, title, rows }) => {
  const { t } = useTranslation('tradingCalculators');

  return (
    <Grid item direction="column" xs={12} sm={6} className={classes.resultsGroup}>
      <Typography className={classes.resultsGroupLabel}>{t(`results.${title}`)}</Typography>
      {rows.map((row, index) => (
        <ResultsRow key={index} label={row.label} value={row.value} />
      ))}
    </Grid>
  );
};

ResultsRowsGroup.propTypes = {
  title: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ).isRequired,
};

export default withStyles(styles)(ResultsRowsGroup);
