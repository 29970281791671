export const htmlBodyFontSize = 14;
export const fontFamily = 'Red Hat Display';

export const fontWeight = {
  extraBold: 900,
  bold: 700,
  semiBold: 600,
  regular: 400,
  light: 300,
};

export const fontSize = {
  _10: 10,
  _12: 12,
  _14: 14,
  _15: 15,
  _16: 16,
  _18: 18,
  _20: 20,
  _24: 24,
  _32: 32,
  _36: 36,
  _48: 48,
  _56: 56,
};
