const styles = (theme) => ({
  DatePicker: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: 'unset',
    color: theme.input.color,
    lineHeight: '20px',
    fontSize: '16px',
    '&:focus': {
      borderColor: theme.palette.primary.main,
      outline: 'none',
    },
  },
  imgDisabled: {
    opacity: 0.4,
  },
  DatePickerIcon: {
    position: 'relative',
    '& img': {
      position: 'absolute',
      top: '13px',
      left: '10px',
      zIndex: 10,
    },
    '& .react-datepicker__input-container input': {
      paddingLeft: '38px !important',
    },
  },
  DatePickerWrapper: {
    position: 'relative',
    height: '100%',
    '& .react-datepicker': {
      borderColor: theme.palette.secondary.main,
      '& .react-datepicker__header': {
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.primary.main,
      },
    },
    '& > div.react-datepicker-wrapper': {
      height: '100%',
    },
    '& .react-datepicker__input-container': {
      height: '100%',
    },
    '& .react-datepicker__input-container input': {
      height: '100%',
      fontSize: '14px',
      width: '140px',
      paddingLeft: '8px',
    },
    '& .react-datepicker__input-container input::placeholder': {
      color: theme.palette.simpleGrey,
    },
    '& .react-datepicker__day--today': {
      backgroundColor: 'transparent',
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      color: '#000',
    },
    '& .react-datepicker__day--in-selecting-range': {
      backgroundColor: '#9bcfd2',
    },
    '& .react-datepicker__day--keyboard-selected': {
      backgroundColor: theme.palette.primary.main,
      color: '#000',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '& .react-datepicker__day--selected': {
      backgroundColor: theme.palette.primary.main,
      color: '#000',
    },
    '& .react-datepicker__day--in-range': {
      backgroundColor: theme.palette.primary.main,
      color: '#000',
    },
    '& .react-datepicker__close-icon::after': {
      backgroundColor: theme.palette.primary.main,
      color: '#000',
      fontWeight: 'bold',
    },
    '& .react-datepicker-popper': {
      zIndex: 10,
    },
    '& .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before': {
      borderBottomColor: theme.palette.secondary.main,
    },
  },
  textField: {
    '&:hover:not($disabled):not($focused):not($textFieldError) $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
      borderWidth: '1px',
    },
    '&$textFieldFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
      borderWidth: '1px',
    },
    '&:hover:not($disabled):not($focused):not($error) $dropDownIcon': {
      color: theme.palette.icon.black,
    },
    '&$textFieldFocused $dropDownIcon': {
      color: theme.palette.icon.black,
    },
    '&$textFieldError $notchedOutline': {
      borderColor: `${theme.palette.error.main} !important`,
    },
    height: '32px',
    color: theme.input.color,
    lineHeight: '20px',
    fontSize: '16px',
    textAlign: 'left',
  },
  DatePickerCustom: {
    height: 'min-content',
    position: 'relative',
    '& > div.react-datepicker-wrapper': {
      height: 'min-content',
    },
    '& .react-datepicker__input-container': {
      height: 'min-content',
    },
    '& .react-datepicker__input-container input': {
      height: '48px',
      fontSize: '16px',
      width: '200px',
      paddingLeft: '12px',
      borderColor: theme.palette.hawkesBlue,
      borderRadius: '4px',
      '&:focus': {
        borderColor: theme.palette.hawkesBlue,
      },
      '&:disabled': {
        backgroundColor: 'transparent',
      },
    },
    '@media (max-width:1280px)': {
      '& > div.react-datepicker-wrapper': {
        width: '100%',
      },
      '& .react-datepicker__input-container input': {
        width: '100%',
      },
    },
  },
  customLabel: {
    position: 'relative',
    top: '-26px',
    fontSize: '11px',
    right: '190px',
    color: theme.palette.simpleGrey,
    backgroundColor: 'white',
    padding: '0 4px',
    '&$labelFocused': {
      color: theme.palette.simpleGrey,
    },
    '&$labelError': {
      color: `${theme.palette.error.main} !important`,
    },
    '@media (max-width:1280px)': {
      top: '-58px',
      right: '-10px',
    },
  },
  errorSelect: {
    ...theme.textError,
    bottom: '2px',
    maxHeight: 11,
  },
  errorIcon: {
    fontSize: 12,
  },
  disabled: {},
  focused: {},
  textFieldError: {
    borderColor: `${theme.palette.error.main} !important`,
  },
  notchedOutline: {},
  textFieldFocused: {},
  dropDownIcon: {},
  labelError: {
    color: theme.palette.error.main,
  },
  labelFocused: {},
  error: {},
});

export default styles;
