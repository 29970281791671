import { combineActions, handleActions } from 'redux-actions';

import * as actionCreators from '../actions';
import { logout } from '../../../../auth/actions';

const defaultState = {};

export default handleActions(
  {
    [actionCreators.verificationPageOpened]() {
      return { ...defaultState };
    },
    [combineActions(
      actionCreators.getVerificationUserSuccess,
      actionCreators.updatePersonalDetailsSuccess,
      actionCreators.settingsVerificationUpdateSuccess,
      actionCreators.classicVerificationUpdateSuccess,
      actionCreators.classicVerificationSecondStepUpdateSuccess,
    )](state, action) {
      return { ...action.response.data };
    },
    [actionCreators.getVerificationUserSuccess]: (state, action) => {
      return { ...state, applicationLog: action.response.data };
    },
    [logout]: () => defaultState,
  },
  defaultState,
);
