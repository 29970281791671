import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { required } from 'utils/validation/fieldValidationRules';
import { RadioGroup } from 'modules/common/RadioClassic';

import styles from './styles';

const QuizForm = ({ classes, questionId, questionLabel, answers }) => {
  return (
    <div>
      <Typography className={classes.question}>{questionLabel}</Typography>
      {Array.isArray(answers) && (
        <Field buttons={answers} component={RadioGroup} name={questionId} validate={[required]} />
      )}
    </div>
  );
};

QuizForm.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  questionId: PropTypes.string,
  questionLabel: PropTypes.string,
};

export default withStyles(styles)(QuizForm);
