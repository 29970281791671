import moment from 'moment';
import { DATE_FORMAT_YYYYMMDD } from '../../../constants';
import { CUSTOM_DATE_RANGE_KEY } from '../constants';

export function getDateRangeValuesByCustomType(value) {
  const today = () => moment().startOf('day');
  const formatted = (date) => date.format(DATE_FORMAT_YYYYMMDD);

  switch (value) {
    case CUSTOM_DATE_RANGE_KEY.TODAY:
    case 'custom':
      return {
        dateFrom: formatted(today()),
        dateTo: formatted(today()),
      };
    case CUSTOM_DATE_RANGE_KEY.YESTERDAY:
      return {
        dateFrom: formatted(today().subtract(1, 'day')),
        dateTo: formatted(today().subtract(1, 'day')),
      };
    case CUSTOM_DATE_RANGE_KEY.LAST_7_DAYS:
      return {
        dateFrom: formatted(today().subtract(7, 'day')),
        dateTo: formatted(today()),
      };
    case CUSTOM_DATE_RANGE_KEY.LAST_7_DAYS_UTC:
      return {
        dateFrom: today()
          .subtract(7, 'day')
          .toISOString(),
        dateTo: today()
          .endOf('day')
          .toISOString(),
      };
    case CUSTOM_DATE_RANGE_KEY.LAST_30_DAYS:
      return {
        dateFrom: formatted(today().subtract(30, 'day')),
        dateTo: formatted(today()),
      };
    case CUSTOM_DATE_RANGE_KEY.WEEK_TO_DATE:
      return {
        dateFrom: formatted(moment().startOf('week')),
        dateTo: formatted(today()),
      };
    case CUSTOM_DATE_RANGE_KEY.MONTH_TO_DATE:
      return {
        dateFrom: formatted(moment().startOf('month')),
        dateTo: formatted(today()),
      };
    case CUSTOM_DATE_RANGE_KEY.QUARTER_TO_DATE:
      return {
        dateFrom: formatted(moment().startOf('quarter')),
        dateTo: formatted(today()),
      };
    case CUSTOM_DATE_RANGE_KEY.YEAR_TO_DATE:
      return {
        dateFrom: formatted(moment().startOf('year')),
        dateTo: formatted(today()),
      };
    case CUSTOM_DATE_RANGE_KEY.PREVIOUS_WEEK:
      return {
        dateFrom: formatted(
          moment()
            .subtract(1, 'week')
            .startOf('week'),
        ),
        dateTo: formatted(
          moment()
            .subtract(1, 'week')
            .endOf('week'),
        ),
      };
    case CUSTOM_DATE_RANGE_KEY.PREVIOUS_MONTH:
      return {
        dateFrom: formatted(
          moment()
            .subtract(1, 'month')
            .startOf('month'),
        ),
        dateTo: formatted(
          moment()
            .subtract(1, 'month')
            .endOf('month'),
        ),
      };
    case CUSTOM_DATE_RANGE_KEY.PREVIOUS_QUARTER:
      return {
        dateFrom: formatted(
          moment()
            .subtract(1, 'quarter')
            .startOf('quarter'),
        ),
        dateTo: formatted(
          moment()
            .subtract(1, 'quarter')
            .endOf('quarter'),
        ),
      };
    default:
      return {
        dateFrom: moment()
          .startOf('day')
          .format(DATE_FORMAT_YYYYMMDD),
        dateTo: moment()
          .startOf('day')
          .format(DATE_FORMAT_YYYYMMDD),
      };
  }
}
