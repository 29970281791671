import { QUIZ_MODAL_STYLES } from '../../../../constants/quizModalStyles';

const styles = (theme) => ({
  ...QUIZ_MODAL_STYLES,
  text: {
    display: 'block',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '21px',
    color: theme.palette.text.main,
    marginBottom: '8px',
    '&:last-child': {
      marginBottom: '12px',
    },
    '@media (min-width:768px)': {
      fontSize: '20px',
      lineHeight: '27px',
    },
  },
});

export default styles;
