import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles';
import Notifications from 'modules/notifications';

const LayoutNew = ({ classes, children, className }) => {
  return (
    <section className={classNames([classes.wrapper, className])}>
      <Notifications />
      <section className={classes.wraperContent}>{children}</section>
    </section>
  );
};

LayoutNew.propTypes = {
  classes: PropTypes.shape({
    wrapper: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(LayoutNew);
