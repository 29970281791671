import React from 'react';
import { withStyles } from '@material-ui/core';
import PrimaryButton from '../../../common/PrimaryButton';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, List, ListItem, ListItemText, Typography } from 'material-latest';

import { selectAccountPlanRequest } from '../../../accounts/pages/MyAccounts/actions';
import { ACCOUNT_PLANS } from '../../../../constants/acctountPlans';
import CustomModal from '../../../common/CustomModal';
import verificationModalNames from '../../constants/verificationModalNames';

import styles from './styles';

const ProOrRetailModal = ({ classes }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation('quiz');

  const handleAccountPlanSelect = (accountPlan) => () => {
    dispatch(selectAccountPlanRequest(accountPlan));
  };

  return (
    <CustomModal
      modalName={verificationModalNames.PRO_OR_RETAIL_MODAL}
      isCloseButtonNeeded
      className={classes.welcomeModal}
      hideButtons
      title={t('proOrRetailModal.title')}>
      {[ACCOUNT_PLANS.PRO, ACCOUNT_PLANS.RETAIL].map((sectionItem) => (
        <Box className={classes.section} key={sectionItem}>
          <Typography fontSize={20} fontWeight={600}>
            {t(`proOrRetailModal.${sectionItem}.title`)}
          </Typography>
          <List sx={{ listStyle: 'initial', pl: 3 }}>
            {t(`proOrRetailModal.${sectionItem}.benefits`, { returnObjects: true }).map(
              (listItem) => (
                <ListItem
                  className={classes.listItem}
                  sx={{ p: 0, display: 'list-item' }}
                  key={`${sectionItem}-${listItem}`}>
                  <ListItemText primary={listItem} />
                </ListItem>
              ),
            )}
          </List>
          <Box className={classes.buttonWrapper}>
            <PrimaryButton
              classes={{ buttonTextContainer: classes.buttonTextContainer }}
              className={classes.button}
              onClick={handleAccountPlanSelect(sectionItem)}
              label={t(`proOrRetailModal.${sectionItem}.selectAccountPlanButtonText`)}
              color="primary"
            />
          </Box>
        </Box>
      ))}
    </CustomModal>
  );
};

export default withStyles(styles)(ProOrRetailModal);
