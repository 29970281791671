import React from 'react';
import PropTypes from 'prop-types';
import Card from 'material-latest/Card';

import TPLoader from 'components/TP-UI/TPLoader';

import styles from './styles';
import { Box } from 'material-latest';

const TPCard = ({
  variant = 'outlined',
  loading,
  disabled,
  selected,
  fullWidth,
  stretch,
  onClick,
  className,
  children,
}) => {
  return (
    <Card
      variant={variant}
      disabled={loading || disabled}
      sx={[
        styles.root,
        className,
        onClick && styles.clickable,
        (loading || disabled) && styles.disabled,
        selected && styles.selected,
        fullWidth && styles.fullWidth,
        stretch && styles.stretch,
      ]}
      onClick={onClick}>
      {children}
      {loading ? (
        <Box sx={styles.cardLoader}>
          <TPLoader />
        </Box>
      ) : null}
    </Card>
  );
};

TPCard.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  fullWidth: PropTypes.bool,
  /**
   * Card expands to full height and width.
   */
  stretch: PropTypes.bool,
  onClick: PropTypes.func,
};

export default TPCard;
