import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import useActiveUrl from 'hooks/navigation/useActiveUrl';
import mergeUrlPaths from 'utils/mergeUrlPaths';
import styles from './styles';
import checkIfTabExists from '../../helpers';

const NavTabs = ({
  TabsComponent,
  TabComponent,
  tabs,
  currentBaseUrl,
  classes,
  onChange,
  ...tabsProps
}) => {
  const activeUrlPath = useActiveUrl();
  const selectedTabValue = checkIfTabExists(activeUrlPath, tabs, currentBaseUrl);
  return (
    <TabsComponent
      classes={{
        root: classes.root,
        flexContainer: classes.flexContainer,
      }}
      scrollButtons="off"
      {...tabsProps}
      value={selectedTabValue}>
      {tabs.map(({ path, ...tabProps }) => {
        const resultPath = mergeUrlPaths([currentBaseUrl, path], true);
        return (
          <TabComponent
            className={classes.tab}
            classes={{
              labelContainer: classes.label,
            }}
            key={resultPath}
            value={resultPath}
            {...tabProps}
            component={Link}
            to={resultPath}
            onClick={onChange}
          />
        );
      })}
    </TabsComponent>
  );
};

export default withStyles(styles)(React.memo(NavTabs));
