import { combineReducers } from 'redux';

import userAccounts from './userAccounts';
import userEmails from './userEmails';
import userPayments from './userPayments';
import userRecentPayments from './userRecentPayments';
import affiliate from './affiliate';
import userEmailFeed from './userEmailFeed';
import userTradingHistory from './userTradingHistory';
import userCycle from './userCycle';
import userOperationSub from './userOperationSub';
import userPaymentComments from './userPaymentComments';
import userTickets from './userTickets';
import userAccountsWithOpenedTrades from './userAccountsWithOpenedTrades';
import request from './userDetailsRequest';
import userQuiz from './userQuiz';
import userQuizComments from './userQuizComments';

const reducer = combineReducers({
  userAccounts,
  userEmails,
  userPayments,
  userRecentPayments,
  affiliate,
  userEmailFeed,
  userTradingHistory,
  userCycle,
  userOperationSub,
  userPaymentComments,
  userTickets,
  userAccountsWithOpenedTrades,
  request,
  userQuiz,
  userQuizComments,
});

export default reducer;
