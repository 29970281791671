import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import omit from 'lodash/omit';
import assign from 'lodash/assign';

import { equalPasswords } from 'utils/validation/formValidationRules';
import SignUp from '../components/SignUp';
import { SIGNUP_FORM } from '../constants';
import {
  MAP_HUB_BY_PREFIX,
  MSCLKID_COOKIE_NAME,
  REFCODE_COOKIE_NAME,
} from '../../../../../constants';
import {
  FOLLOWER_ID_COOKIE_NAME,
  REFERRAL_COOKIE_NAME,
  REFERRAL_COOKIE_HUB,
  UTM_COOKIE_NAME,
  GP_REFERRAL_COOKIE_NAME,
  FOLLOWER_HUB_COOKIE_NAME,
  CLICK_ID_COOKIE_NAME,
} from 'constants/index';
import * as actionCreators from '../actions';
import * as apiSelectors from 'modules/api/selectors';
import * as appSelectors from 'selectors';
import * as signupSelectors from '../selectors/index';
import * as notificationsActions from 'modules/notifications/actions';
import paramToLowerCase from 'utils/paramToLowerCase';
import { Box } from 'material-latest';
import { Footer } from 'modules/common/Footer';

class SignUpContainer extends PureComponent {
  componentDidMount() {
    const { params } = this.props;
    if (params.referral) {
      Cookies.set(GP_REFERRAL_COOKIE_NAME, params.referral, { sameSite: 'none', secure: true });
    }
  }

  getAdditionalData = (params) => {
    const data = {};

    const followerId = Cookies.get(FOLLOWER_ID_COOKIE_NAME) || params.followerId;
    const followerHub = Cookies.get(FOLLOWER_HUB_COOKIE_NAME) || params.followerHub;
    if (followerId) {
      data.followerId = followerId;
    }

    const referral = params.cxd || Cookies.get(REFERRAL_COOKIE_NAME);
    if (referral) {
      data.cxd = referral;
      data.subID = params.subID;
      const cxdHub = Cookies.get(REFERRAL_COOKIE_HUB);
      if (cxdHub) {
        data.hub = cxdHub;
      }
    }
    if (followerHub) {
      data.hub = followerHub;
    }
    const hub = MAP_HUB_BY_PREFIX[this.props.prefix];
    if (hub && !data.hub) {
      data.hub = hub;
    }
    const utm = Cookies.get(UTM_COOKIE_NAME);
    if (utm) {
      data.utm = JSON.parse(utm);
    }
    const inviteFriendCode = Cookies.get(GP_REFERRAL_COOKIE_NAME) || params.referral;
    if (inviteFriendCode) {
      data.inviteFriendCode = inviteFriendCode;
    }

    const _params = paramToLowerCase(params);

    const clickId = _params[CLICK_ID_COOKIE_NAME] || Cookies.get(CLICK_ID_COOKIE_NAME);
    if (clickId) {
      data.clickId = clickId;
    }

    const refcode = params[REFCODE_COOKIE_NAME] || Cookies.get(REFCODE_COOKIE_NAME);
    if (refcode) {
      data.refcode = refcode;
    }

    const msclkid = params[MSCLKID_COOKIE_NAME] || Cookies.get(MSCLKID_COOKIE_NAME);
    if (msclkid) {
      data.msclkid = msclkid;
    }

    return data;
  };

  onSubmit = (data) => {
    const {
      actions: { signupRequest },
      params,
    } = this.props;

    data = assign({}, data, this.getAdditionalData(params));
    data = omit(data, ['confirmPassword', 'conditions']);

    const segmentAnonymousId = localStorage.getItem('ajs_anonymous_id');

    if (segmentAnonymousId) {
      const extractedId = segmentAnonymousId.replaceAll('"', '');

      data.segmentAnonymousId = extractedId;
    }

    signupRequest(data);
  };

  validate = (data) => {
    let results = {};

    if (!data.conditions) {
      results.conditions = { key: 'common:errors.form.needConfirmYouAreOver18' };
    }
    results = assign(results, equalPasswords(data));
    return results;
  };

  handleGoogleSignupSuccess = (data) => {
    const {
      actions: { googleAuthRequest },
      params,
    } = this.props;

    data = assign({}, data, this.getAdditionalData(params));

    googleAuthRequest(data);
  };

  render() {
    return (
      <Box>
        <SignUp
          onSubmit={this.onSubmit}
          form={SIGNUP_FORM}
          validate={this.validate}
          isPending={this.props.isSignupPending}
          prefix={this.props.prefix}
          onGoogleSignupSuccess={this.handleGoogleSignupSuccess}
          userCountry={this.props.userCountry}
        />
        <Footer />
      </Box>
    );
  }
}

SignUpContainer.propTypes = {
  isSignupPending: PropTypes.bool.isRequired,
  params: PropTypes.object,
  actions: PropTypes.shape({
    signupRequest: PropTypes.func.isRequired,
    preInsertSignUp: PropTypes.func.isRequired,
    googleAuthRequest: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    isSignupPending: apiSelectors.createPendingSelector(actionCreators.signupRequest)(state),
    params: appSelectors.getParams(state),
    userCountry: signupSelectors.getUserCountry(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    notificationActions: bindActionCreators(notificationsActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpContainer);
