import { combineActions, handleActions } from 'redux-actions';

import * as actionCreators from '../actions';
import { ACCOUNT_STATUSES } from 'constants/index';
const defaultState = [];

export default handleActions(
  {
    [combineActions(actionCreators.searchBankDetailsSuccess)](state, action) {
      return action.response.data;
    },
    [combineActions(actionCreators.createBankDetailsSuccess)](state, action) {
      let bankAccounts = [...state];
      if (action.response.data && action.response.data.status === ACCOUNT_STATUSES.APPROVED) {
        bankAccounts.push(action.response.data);
      }
      return bankAccounts;
    },
    [combineActions(actionCreators.deleteBankDetailsSuccess)](state, action) {
      const updatedState = state.filter((bankAccount) => bankAccount._id !== action.payload);
      return updatedState;
    },
  },
  defaultState,
);
