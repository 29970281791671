import { createAction } from 'redux-actions';

export const getPendingMigrationsRequest = createAction('GET_PENDING_MIGRATIONS_REQUEST');
export const getPendingMigrationsSuccess = createAction('GET_PENDING_MIGRATIONS_SUCCESS');
export const getPendingMigrationsFail = createAction('GET_PENDING_MIGRATIONS_FAIL');

export const approveMigrationsRequest = createAction('APPROVE_MIGRATIONS_REQUEST');
export const approveMigrationsSuccess = createAction('APPROVE_MIGRATIONS_SUCCESS');
export const approveMigrationsFail = createAction('APPROVE_MIGRATIONS_FAIL');

export const declineMigrationsRequest = createAction('DECLINE_MIGRATIONS_REQUEST');
export const declineMigrationsSuccess = createAction('DECLINE_MIGRATIONS_SUCCESS');
export const declineMigrationsFail = createAction('DECLINE_MIGRATIONS_FAIL');
