import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ErrorOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Typography } from '@material-ui/core';
import styles from './styles';
import MenuScrollIndicators from 'modules/common/MenuScrollIndicators';
import getLabelFromValue from '../../helpers/getLabelFromValue';
import { getTranslation } from 'utils/compositeTranslationHandler';

const Picker = ({
  classes,
  input,
  meta,
  inputComponent,
  label,
  selectedValue,
  values,
  menuItemClass,
  menuPaperClass,
  children,
  isTopButtonShowed,
  handleTopButtonChange,
  maxHeight,
  menuClassName,
  dropDownIconClassName,
  rootClassName,
  isOpen,
  handlePicker,
  placeholder,
  isAlphabetical,
  showPlaceholder = true,
  onChange,
  labelRef,
  labelWidth,
  required,
  ...others
}) => {
  const { t } = useTranslation();
  const pickerId = `picker_${Date.now()}`;
  if (input && input.value) {
    selectedValue = input.value;
  }
  return (
    <FormControl
      variant="outlined"
      className={classNames(
        {
          [classes.textFieldContainer]: true,
          [classes.bottomErrorPadding]: values,
        },
        rootClassName,
      )}>
      {values && (
        <InputLabel
          shrink
          classes={{
            root: classes.label,
            focused: classes.labelFocused,
            error: classes.labelError,
          }}
          error={meta.submitFailed && meta.error ? true : false}
          htmlFor={pickerId}
          disabled={!values || values.length === 0 || others.disabled}>
          <div ref={labelRef}>
            {label}
            {required ? '*' : ''}
          </div>
        </InputLabel>
      )}
      <Select
        renderValue={
          selectedValue !== label && selectedValue
            ? function render(value) {
                return <span>{getLabelFromValue(value, values)}</span>;
              }
            : null
        }
        {...others}
        value={selectedValue || false}
        open={isOpen}
        onOpen={handlePicker}
        onClose={handlePicker}
        disabled={!values || values.length === 0 || others.disabled}
        MenuProps={{
          className: classNames({
            [classes.menu]: true,
            [menuClassName]: true,
            [classes.topSpaceMenuClass]: !isTopButtonShowed,
          }),
          classes: {
            paper: classNames(classes.menuPaper, menuPaperClass),
          },
          disableAutoFocusItem: true,
          MenuListProps: {
            classes: {
              root: classes.menuList,
            },
          },
        }}
        classes={{
          icon: classNames(classes.dropDownIcon, dropDownIconClassName),
          select: classes.select,
          root: classes.rootSelect,
        }}
        autoWidth
        inputProps={{
          placeholder: label,
        }}
        input={
          inputComponent || (
            <OutlinedInput
              classes={{
                root: classes.textField,
                focused: classes.textFieldFocused,
                notchedOutline: classes.notchedOutline,
                error: classes.textFieldError,
              }}
              {...input}
              error={meta.submitFailed && meta.error ? true : false}
              id={pickerId}
              labelWidth={labelWidth}
              notched
              {...others}
              placeholder={label}
            />
          )
        }>
        {showPlaceholder && (
          <MenuItem
            className={classNames(classes.menuItem, menuItemClass, classes.hiddenItem)}
            key={label}
            value={false}>
            <Typography className={classNames(classes.menuLabel, classes.placeholderLabel)}>
              {placeholder}
            </Typography>
          </MenuItem>
        )}
        <MenuScrollIndicators
          autoHeightMax={maxHeight}
          handleTopButtonChange={handleTopButtonChange}>
          {isAlphabetical ? (
            <div className={classes.alphabeticalValuesContainer}>
              {values.map((value, index) => {
                return (
                  <div key={value.label}>
                    <div
                      className={classNames({
                        [classes.delimiter]: index !== 0,
                      })}
                    />
                    {value.options.map((option, index) => (
                      <MenuItem
                        selected={option.name === selectedValue.name}
                        onClick={() => {
                          setTimeout(handlePicker, 0);
                          input.onChange(option);
                          onChange(option.name);
                        }}
                        className={classNames({
                          [classes.alphabeticalMenuItem]: true,
                          [menuItemClass]: true,
                        })}
                        key={option.name}
                        value={option.name}
                        classes={{
                          selected: classes.menuItemSelected,
                        }}>
                        {index === 0 && (
                          <div className={classes.firstLetterContainer}>
                            <Typography className={classes.firstItemLetter}>
                              {value.label}
                            </Typography>
                          </div>
                        )}
                        <Typography noWrap className={classes.menuLabel}>
                          {option.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </div>
                );
              })}
            </div>
          ) : (
            <>
              {values && values.length !== 0 ? (
                <div className={classes.itemsContainer}>
                  {values.map((option) => (
                    <MenuItem
                      selected={option.value === selectedValue}
                      onClick={() => {
                        input.onChange(option.value);
                        handlePicker();
                      }}
                      className={classNames({
                        [classes.menuItem]: true,
                        [menuItemClass]: true,
                      })}
                      key={option.value}
                      value={option.value}>
                      <Typography className={classes.menuLabel}>{option.label}</Typography>
                    </MenuItem>
                  ))}
                </div>
              ) : (
                children
              )}
            </>
          )}
        </MenuScrollIndicators>
      </Select>
      {meta.error && meta.submitFailed && (
        <span className={classes.errorSelect}>
          <ErrorOutline className={classes.errorIcon} />
          &nbsp;
          {getTranslation(t, meta.error)}
        </span>
      )}
    </FormControl>
  );
};

Picker.propTypes = {
  label: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  meta: PropTypes.object.isRequired,
  selectedValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  values: PropTypes.array.isRequired,
  menuItemClass: PropTypes.string,
  menuPaperClass: PropTypes.string,
  labelWidth: PropTypes.number,
  handleTopButtonChange: PropTypes.func.isRequired,
  isTopButtonShowed: PropTypes.bool.isRequired,
  maxHeight: PropTypes.number,
  inputComponent: PropTypes.object,
  menuClassName: PropTypes.string,
  dropDownIconClassName: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  handlePicker: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  rootClassName: PropTypes.string,
  isAlphabetical: PropTypes.bool,
  showPlaceholder: PropTypes.bool,
  onChange: PropTypes.func,
  showNoItemsError: PropTypes.bool,
};

export default withStyles(styles)(Picker);
