import moment from 'moment';
import 'moment/locale/th';
import 'moment/locale/zh-cn';
import 'moment/locale/en-au';
import Cookies from 'js-cookie';

import { LANG_COOKIE_NAME, languages } from 'constants/index';

export const editDate = (date, value, unit) => {
  const language = Cookies.get(LANG_COOKIE_NAME);
  const formattedLanguage =
    language && languages.CHINESE && language === languages.CHINESE ? 'zh-cn' : language;
  moment.locale(formattedLanguage);
  return moment(date)
    .add(value, unit)
    .format();
};

export default (date, format = 'DD-MM-YYYY HH:mm:ss', useUTC) => {
  const language = Cookies.get(LANG_COOKIE_NAME);
  const formattedLanguage =
    language && languages.CHINESE && language === languages.CHINESE ? 'zh-cn' : language;
  moment.locale(formattedLanguage);
  const formattedDate = moment(date).format();
  const momentDate = useUTC
    ? moment.utc(formattedDate, moment.ISO_8601)
    : moment(formattedDate, moment.ISO_8601);
  return date ? momentDate.format(format) : null;
};
