import React, { useState } from 'react';

import TPGrid from 'components/TP-UI/TPGrid';
import TPSidebarLogo from 'components/TP-UI/TPSidebarLogo';
import config from 'config';

import styles from './styles';

const { LOGO, CURRENT_HUB_LABEL } = config;

const SidebarLogos = () => {
  const linkTo = '/auth/sign-in';
  const [expanded, setExpanded] = useState(true);
  const toggle = () => setExpanded((prevState) => !prevState);

  return (
    <TPGrid
      id="logoBlock"
      item
      container
      alignItems="center"
      sx={[expanded && styles.maximized, !expanded && styles.minimized, styles.logoWrapper]}>
      <TPSidebarLogo
        alt={CURRENT_HUB_LABEL}
        src={LOGO[expanded ? 'blue' : 'blue']}
        path={linkTo}
        expanded={expanded}
        aria-controls="logoBlock"
        onExpandClick={toggle}
      />
    </TPGrid>
  );
};

export default SidebarLogos;
