export const ENTITY_GROUP = {
  FOREX: 'FOREX',
  COMMODITIES: 'COMMODITIES',
  INDICES: 'INDICES',
  STOCKS:
    'AE,AT,AU,BD,BE,BH,CA,CH,CN,DE,DK,EG,ES,FI,FR,HK,ID,IE,IL,IN,IT,JP,KR,KW,MX,MY,NL,NO,NZ,OM,PH,PK,PT,QA,SA,SE,SG,TH,TR,TW,UK,US,VN,ZA',
};

export const ROLLUP = {
  ONE_DAY: '1day',
  SEVEN_DAYS: '7day',
  THIRTY_DAYS: '30day',
  NINETY_DAYS: '90day',
};

export const BUZZ_LEVEL = {
  NO_NEWS: 'noNews',
  BELOW_AVERAGE: 'belowAverage',
  AVERAGE: 'average',
  ABOVE_AVERAGE: 'aboveAverage',
  BUZZING: 'buzzing',
};

export const VIEW_TYPE = {
  BUBBLE: 'bubble',
  TABLE: 'table',
};

export const SUBJECTIVITY_TYPE = {
  noData: 'noData',
  objective: 'objective',
  neutral: 'neutral',
  subjective: 'subjective',
};

export const SENTIMENT_TYPE = {
  positive: 'positive',
  veryPositive: 'veryPositive',
  neutral: 'neutral',
  negative: 'negative',
  veryNegative: 'veryNegative',
};

export const INDICATOR_TYPE = {
  NEWS_VOLUME: 'newsVolume',
  SENTIMENT: 'sentiment',
  ANALYST_VIEWS: 'analystViews',
};

export const SENTIMENT_ZSCORE = {
  LOW_LOW: -0.5,
  LOW_MID: -0.05,
  HIGH_MID: 0.05,
  HIGH_HIGH: 0.5,
};

export const NEWS_SOURCES = [
  'Daily FX',
  'FXStreet.com',
  'forexlive.com/',
  'Yahoo! News',
  'Action Forex',
  'forexcrunch.com/',
  '@jimwaterson',
  'Business Insider',
  '@InvestorsLive',
  'Time',
  '@NicTrades',
  '@JuliaHB1',
  '@TheStalwart',
  'Watch List News',
  'PR Newswire',
  '@DougKass',
  '@aantonop',
  '@carlquintanilla',
  '@elerianm',
  'zacks.com/',
  'Newsverge',
  '@nycjim',
  '@MerrillLynch',
  'Edge Markets',
  '@KeithMcCullough',
  '@ArlanFF101',
  '@DavidSchawel',
  '@jposhaughnessy',
  '@conorsen',
  'FOXNews.com',
  '@LizAnnSonders',
  '@DeanBaker13',
  '@NorthmanTrader',
  '@IvanTheK',
  '@ericjackson',
  '@cullenroche',
  '@EddyElfenbein',
  '@firstadopter',
  '@Fxflow',
  '@dsquareddigest',
  'Zero Hedge',
  '@tylercowen',
  'marketwatch.com/',
  'E Hacking News',
  '@carney',
  '@pdacosta',
  '@hmeisler',
  '@stevekovach',
  '@BarbarianCap',
  '@edwardnh',
  '@MorganStanley',
  'Koreabizwire',
  '@ianbremmer',
  '@allstarcharts',
  '@Convertbond',
  '@jsblokland',
  '@Cointelegraph',
  'Macau Daily Times',
  '@montie',
  '@OptionsHawk',
  '@MrHarryCole',
  '@RaoulGMI',
  'Moneycontrol.com',
  '@Muniguy2012',
  '@JKempEnergy',
  '@John_Hempton',
  '@Austan_Goolsbee',
  '@jonnajarian',
  'Kazinform',
  'Business Today| LATEST HEADLINES',
  '@adamfeuerstein',
  'Independent On Saturday',
  'JLN Metals',
  'Seattle Times',
  '@jessefelder',
  'Gulf Business',
  '@zephoria',
  '@cate_long',
  '@ModeledBehavior',
  'PM News Nigeria',
  'AOL.com',
  'The Next Web',
  '@jasonfried',
  'Financial Express',
  '@jimcramer',
  '@JimPethokoukis',
  '@karaswisher',
  'financialbuzz.com/',
  '@jdportes',
  '@Samir_Madani',
  '@AstraZeneca',
  'Irish Times',
  '@prchovanec',
  '@cornoptions1',
  'CBC',
  'Abnewswire',
  '@emmarcourt',
  '@munibonds',
  '@crypto',
  '@katie_martin_fx',
  '@mark_dow',
  '@tomkozlik',
  '@MxSba',
  'ReliefWeb',
  '@robpegoraro',
  '@waynopennyworld',
  '@Mark_Goldberg',
  '@jtcrombie',
  '@callieabost',
  'One America News Network',
  '@DPJHodges',
  'Russia Today',
  '@herbgreenberg',
  'Vanguard',
  '@awealthofcs',
  '@ElliottForecast',
  '@waltmossberg',
  '@bill_easterly',
  '@fwred',
  '@paulmasonnews',
  '@abnormalreturns',
  '@George_Osborne',
];

export const NEWS_DEFAULT_SOURCES = [
  'Daily FX',
  'FXStreet.com',
  'forexlive.com/',
  'Action Forex',
  'forexcrunch.com/',
];

export const NEWS_DISCOVER_FILTER_FORM = 'NEWS_DISCOVER_FILTER_FORM';
