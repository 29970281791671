import React, { PureComponent } from 'react';

import ScrollOnTopButton from '../components/ScrollOnTopButton';

class ScrollOnTopContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.scrollContainer = React.createRef();
    this.state = {
      isButtonShowed: false,
    };
  }

  componentDidMount() {
    window.onscroll = this.onScroll;
  }

  componentWillUnmount() {
    window.onscroll = null;
  }

  onScroll = () => {
    const scrollFromTop = window.pageYOffset || document.documentElement.scrollTop;
    const innerHeight = window.outerHeight || document.documentElement.clientHeight;
    let fixedScroll = innerHeight > 600 ? 600 : 500;
    const isButtonShowed = scrollFromTop + fixedScroll > innerHeight;
    this.setState({ isButtonShowed });

    if (this.scrollContainer?.current?.parentNode) {
      this.scrollContainer.current.parentNode.style.paddingBottom = isButtonShowed ? '80px' : '0px';
    }
  };

  scrollOnTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  render() {
    const props = {
      scrollOnTop: this.scrollOnTop,
      isButtonShowed: this.state.isButtonShowed,
    };
    return (
      <div ref={this.scrollContainer}>
        <ScrollOnTopButton {...this.props} {...props} />
      </div>
    );
  }
}

ScrollOnTopContainer.propTypes = {};

export default ScrollOnTopContainer;
