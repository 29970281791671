import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import FormHelperText from 'material-latest/FormHelperText';
import FormControl from 'material-latest/FormControl';
import Box from 'material-latest/Box';

import TPErrorMessage from 'components/TP-UI/TPErrorMessage';
import { SIZES } from 'components/TP-UI/constants';

import styles from './styles';

export const TPRating = ({
  name,
  value,
  disabled = false,
  icon: Icon = StarBorderOutlinedIcon,
  max = 5,
  readonly = false,
  size = SIZES.MEDIUM,
  classes = {},
  required = false,
  autofocus = false,
  reservedErrorSpace = false,
  hint,
  error,
  className,
  onChange,
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const items = useMemo(() => Array(max).fill(null), [max]);

  const handleMouseEnter = useCallback((i) => {
    setHoveredIndex(i);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHoveredIndex(-1);
  }, []);

  const handleClick = useCallback(
    (i) => {
      if (onChange) {
        onChange(i + 1);
      }
    },
    [onChange],
  );

  const enabled = !disabled && !readonly;

  return (
    <FormControl
      variant="standard"
      required={required}
      disabled={disabled}
      autoFocus={autofocus}
      className={className}>
      <Box id={name} sx={[styles.root]}>
        {items.map((_, i) => {
          let handlers = {};
          if (enabled) {
            handlers = {
              onMouseEnter: () => handleMouseEnter(i),
              onMouseLeave: handleMouseLeave,
              onClick: () => handleClick(i),
            };
          }
          return i + 1 <= value ? (
            <Icon
              key={i}
              sx={[
                styles.iconFilled,
                classes?.iconFilled && classes.iconFilled,
                i <= hoveredIndex && styles.iconHovered,
                classes?.iconFilled && i <= hoveredIndex && classes.iconHovered,
                hoveredIndex > -1 && i > hoveredIndex && styles.iconEmpty,
                classes?.iconEmpty && hoveredIndex > -1 && i > hoveredIndex && classes.iconEmpty,
                disabled && styles.disabled,
              ]}
              fontSize={size}
              {...handlers}
            />
          ) : (
            <Icon
              key={i}
              fontSize={size}
              sx={[
                styles.iconEmpty,
                classes?.iconEmpty && classes.iconEmpty,
                i <= hoveredIndex && styles.iconHovered,
                classes?.iconFilled && i <= hoveredIndex && classes.iconHovered,
                disabled && styles.disabled,
              ]}
              color="disabled"
              {...handlers}
            />
          );
        })}
      </Box>
      {hint && <FormHelperText>{hint}</FormHelperText>}
      <Box sx={[reservedErrorSpace && styles.errorContainer]}>
        {error && <TPErrorMessage error={error} size={SIZES.SMALL} />}
      </Box>
    </FormControl>
  );
};

TPRating.propTypes = {
  value: PropTypes.number,
  /**
   * The name attribute of the radio input elements. If readOnly is false, the prop is required, this input name`should be unique within the parent form.
   */
  name: PropTypes.string,
  /**
   * If true, the rating will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Removes all hover effects and pointer events.
   */
  readonly: PropTypes.bool,
  size: PropTypes.oneOf([SIZES.LARGE, SIZES.MEDIUM, SIZES.SMALL]),
  /**
   * The icon to display.
   */
  icon: PropTypes.elementType,
  /**
   * Maximum rating.
   */
  max: PropTypes.number,
  required: PropTypes.bool,
  autofocus: PropTypes.bool,
  /**
   * Reserved space to display error in 1 line
   */
  reservedErrorSpace: PropTypes.bool,
  hint: PropTypes.node,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  classes: PropTypes.shape({
    iconFilled: PropTypes.string,
    iconEmpty: PropTypes.string,
    iconHovered: PropTypes.string,
  }),
  className: PropTypes.string,
  /**
   * Callback fired when the value changes.
   */
  onChange: PropTypes.func,
};

const TPReduxRating = ({ input, meta, ...others }) => {
  const error = meta.submitFailed && meta.error ? meta.error : null;
  const { onChange } = input;
  return (
    <TPRating {...input} error={error} reservedErrorSpace={true} {...others} onChange={onChange} />
  );
};

export default TPReduxRating;
