import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { isDirty, reset } from 'redux-form';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getHideCompleteAML } from '../../selectors';
import { AML_COMPLETE_MODAL, EMAIL_QUIZ_QUERY } from '../../constants';
import { hideCompleteAML } from '../../actions';
import { getUser, isAuHub } from '../../../../auth/selectors';
import CustomModal from '../../../CustomModal';
import { closeModal, openModal } from '../../../CustomModal/actions';
import { withStyles } from '@material-ui/core';

import styles from './styles';

const AMLCompleteContainer = ({ classes, withdrawForm, onOpenQuiz }) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const history = useHistory();

  const isHideCompleteAML = useSelector(getHideCompleteAML);
  const user = useSelector(getUser);
  const withdrawFormIsDirty = useSelector(isDirty(withdrawForm));
  const isAustralianHub = useSelector(isAuHub);

  useEffect(() => {
    if (
      isAustralianHub &&
      withdrawForm &&
      withdrawFormIsDirty &&
      !get(user, 'AMLProgress.passed', false)
    ) {
      dispatch(openModal(AML_COMPLETE_MODAL));
    }
  }, [dispatch, isAustralianHub, user, withdrawForm, withdrawFormIsDirty]);

  useEffect(() => {
    const hasAMLQuery = history.location.search.includes(EMAIL_QUIZ_QUERY);
    if (
      isAustralianHub &&
      (!isHideCompleteAML || hasAMLQuery) &&
      !get(user, 'AMLProgress.passed', false) &&
      get(user, 'AMLProgress.requestedAt', null)
    ) {
      if (hasAMLQuery) {
        history.replace(history.location.pathname);
      }
      dispatch(openModal(AML_COMPLETE_MODAL));
    }
  }, [dispatch, history, isHideCompleteAML, user, isAustralianHub]);

  const handleOpenAMLQuiz = useCallback(() => {
    dispatch(closeModal(AML_COMPLETE_MODAL));
    dispatch(reset(withdrawForm));
    onOpenQuiz();
  }, [dispatch, onOpenQuiz, withdrawForm]);

  const handleCancel = useCallback(() => {
    dispatch(closeModal(AML_COMPLETE_MODAL));
    dispatch(hideCompleteAML());
    dispatch(reset(withdrawForm));
  }, [dispatch, withdrawForm]);

  const buttons = useMemo(
    () => [
      { label: t('buttons.notNow'), variant: 'text', onClick: handleCancel },
      { label: t('buttons.letsDoIt'), color: 'primary', onClick: handleOpenAMLQuiz },
    ],
    [t, handleCancel, handleOpenAMLQuiz],
  );

  return (
    <CustomModal
      modalClassNames={classes.modal}
      className={classes.modalPaper}
      modalName={AML_COMPLETE_MODAL}
      title="AML Questionnaire"
      isCloseButtonNeeded
      text="Kindly take a minute to complete 4 quick questions."
      buttons={buttons}
      customCloseModal={handleCancel}
    />
  );
};

AMLCompleteContainer.propTypes = {
  withdrawForm: PropTypes.string,
  onOpenQuiz: PropTypes.func.isRequired,
};

export default withStyles(styles)(AMLCompleteContainer);
