import { createAction } from 'redux-actions';

export const getNotificationsRequest = createAction('GET_NOTIFICATIONS_REQUEST');
export const getNotificationsSuccess = createAction('GET_NOTIFICATIONS_SUCCESS');
export const getNotificationsFail = createAction('GET_NOTIFICATIONS_FAIL');

export const createNotificationsRequest = createAction('CREATE_NOTIFICATIONS_REQUEST');
export const createNotificationsSuccess = createAction('CREATE_NOTIFICATIONS_SUCCESS');
export const createNotificationsFail = createAction('CREATE_NOTIFICATIONS_FAIL');

export const updateNotificationsRequest = createAction('UPDATE_NOTIFICATIONS_REQUEST');
export const updateNotificationsSuccess = createAction('UPDATE_NOTIFICATIONS_SUCCESS');
export const updateNotificationsFail = createAction('UPDATE_NOTIFICATIONS_FAIL');

export const createNotificationsOpen = createAction('CREATE_NOTIFICATIONS_OPEN');
export const createNotificationsClose = createAction('CREATE_NOTIFICATIONS_CLOSE');

export const deleteNotificationsRequest = createAction('DELETE_NOTIFICATIONS_REQUEST');
export const deleteNotificationsSuccess = createAction('DELETE_NOTIFICATIONS_SUCCESS');
export const deleteNotificationsFail = createAction('DELETE_NOTIFICATIONS_FAIL');

export const editNotificationOpen = createAction('EDIT_NOTIFICATION_OPEN');
export const editNotificationClose = createAction('EDIT_NOTIFICATION_CLOSE');

export const getUserNotificationsCountRequest = createAction(
  'GET_USER_NOTIFICATIONS_COUNT_REQUEST',
);
export const getUserNotificationsCountSuccess = createAction(
  'GET_USER_NOTIFICATIONS_COUNT_SUCCESS',
);
export const getUserNotificationsCountFail = createAction('GET_USER_NOTIFICATIONS_COUNT_FAIL');

export const getPendingNotificationsCountRequest = createAction(
  'GET_PENDING_NOTIFICATIONS_COUNT_REQUEST',
);
export const getPendingNotificationsCountSuccess = createAction(
  'GET_PENDING_NOTIFICATIONS_COUNT_SUCCESS',
);
export const getPendingNotificationsCountFail = createAction(
  'GET_PENDING_NOTIFICATIONS_COUNT_FAIL',
);

export const checkIfNewUserNotificationsRequest = createAction(
  'CHECK_IF_NEW_USER_NOTIFICATIONS_REQUEST',
);
export const checkIfNewUserNotificationsSuccess = createAction(
  'CHECK_IF_NEW_USER_NOTIFICATIONS_SUCCESS',
);
export const checkIfNewUserNotificationsRequestFail = createAction(
  'CHECK_IF_NEW_USER_NOTIFICATIONS_FAIL',
);

export const getUserNotificationsRequest = createAction('GET_USER_NOTIFICATIONS_REQUEST');
export const getUserNotificationsSuccess = createAction('GET_USER_NOTIFICATIONS_SUCCESS');
export const getUserNotificationsFail = createAction('GET_USER_NOTIFICATIONS_FAIL');

export const markAsReadRequest = createAction('MARK_AS_READ_REQUEST');
export const markAsReadSuccess = createAction('MARK_AS_READ_SUCCESS');
export const markAsReadFail = createAction('MARK_AS_READ_FAIL');

export const markAllAsReadRequest = createAction('MARK_ALL_AS_READ_REQUEST');
export const markAllAsReadSuccess = createAction('MARK_ALL_AS_READ_SUCCESS');
export const markAllAsReadFail = createAction('MARK_ALL_AS_READ_FAIL');

export const getUserNotificationsOpen = createAction('GET_USER_NOTIFICATIONS_OPEN');
export const getUserNotificationsClose = createAction('GET_USER_NOTIFICATIONS_CLOSE');
export const getUsersVerificationNotificationsCountRequest = createAction(
  'GET_USERS_VERIFICATION_NOTIFICATIONS_COUNT_REQUEST',
);
export const getUsersVerificationNotificationsCountSuccess = createAction(
  'GET_USERS_VERIFICATION_NOTIFICATIONS_COUNT_SUCCESS',
);
export const getUsersVerificationNotificationsCountFail = createAction(
  'GET_USERS_VERIFICATION_NOTIFICATIONS_COUNT_FAIL',
);

export const getAccountsVerificationNotificationsCountRequest = createAction(
  'GET_ACCOUNTS_VERIFICATION_NOTIFICATIONS_COUNT_REQUEST',
);
export const getAccountsVerificationNotificationsCountSuccess = createAction(
  'GET_ACCOUNTS_VERIFICATION_NOTIFICATIONS_COUNT_SUCCESS',
);
export const getAccountsVerificationNotificationsCountFail = createAction(
  'GET_ACCOUNTS_VERIFICATION_NOTIFICATIONS_COUNT_FAIL',
);

export const getFollowupsVerificationNotificationsCountRequest = createAction(
  'GET_FOLLOWUPS_VERIFICATION_NOTIFICATIONS_COUNT_REQUEST',
);
export const getFollowupsVerificationNotificationsCountSuccess = createAction(
  'GET_FOLLOWUPS_VERIFICATION_NOTIFICATIONS_COUNT_SUCCESS',
);
export const getFollowupsVerificationNotificationsCountFail = createAction(
  'GET_FOLLOWUPS_VERIFICATION_NOTIFICATIONS_COUNT_FAIL',
);

export const getExperienceUploadsNotificationsCountRequest = createAction(
  'GET_EXPERIENCE_UPLOADS_NOTIFICATIONS_COUNT_REQUEST',
);
export const getExperienceUploadsNotificationsCountSuccess = createAction(
  'GET_EXPERIENCE_UPLOADS_NOTIFICATIONS_COUNT_SUCCESS',
);
export const getExperienceUploadsNotificationsCountFail = createAction(
  'GET_EXPERIENCE_UPLOADS_NOTIFICATIONS_COUNT_FAIL',
);
