export default {
  container: (theme) => ({
    padding: theme.spacing(3),
    minHeight: 435,
    [theme.breakpoints.down('xs')]: {
      minHeight: 'auto',
    },
  }),
  image: {
    width: '100%',
    maxWidth: 200,
    maxHeight: 200,
  },
};
