const styles = (theme) => ({
  container: {
    flex: 1,
    height: '100%',
  },
  textContainer: {
    flex: 1,
    flexWrap: 'nowrap',
    marginBottom: 10,
  },
  progressText: {
    paddingTop: '12px',
    color: theme.palette.black,
    fontSize: '18px',
    textAlign: 'left',
    lineHeight: '18px',
  },
  progressTextBold: {
    color: theme.palette.lightText,
    fontSize: '14px',
    textAlign: 'left',
    lineHeight: '18px',
    fontWeight: 400,
    marginBottom: 14,
  },
  progressBarWrapper: {
    marginBottom: 8,
  },
  continueButton: {
    marginBottom: 24,
    padding: '16px',
    textTransform: 'unset',
    fontSize: '16px',
    fontWeight: 500,
    '& svg': {
      marginLeft: '8px',
    },
  },
});

export default styles;
