export default (theme) => ({
  footerText: {
    color: theme.palette.text.main,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '21px',
    margin: 0,
  },
  link: {
    color: theme.palette.blackText,
    fontWeight: 700,
    textDecoration: 'underline',
    fontSize: 16,
    '&:hover': {
      color: theme.palette.link.hover,
    },
  },
});
