export const boldText = () => ({ dataText: { fontWeight: 700 } });
export const semiboldText = () => ({ dataText: { fontWeight: 600 } });
export const normalText = () => ({ dataText: { fontWeight: 'normal' } });
export const mobileSmallCell = () => ({
  item: {
    padding: '17px 0px',
    '@media (min-width:640px)': {
      padding: '17px 15px',
    },
    '@media (min-width:1366px)': {
      padding: '22px 10px',
    },
    '@media (max-width:640px)': {
      maxWidth: '80px',
      minWidth: '80px',
    },
  },
});
export const leftWideCell = () => ({
  item: { paddingLeft: '32px' },
});

export const fixedWidthCell = () => ({
  item: {
    width: '150px !important',
    minWidth: '150px !important',
  },
});
export const flexCell = () => ({
  item: { flex: 1 },
});

export const wordWrap = () => ({
  item: {
    hyphens: 'auto',
    'overflow-wrap': 'break-word',
    'word-wrap': 'break-word',
  },
  dataText: {
    display: 'block',
  },
});

export const capitalized = () => ({
  dataText: { textTransform: 'capitalize' },
});

export const countryCell = () => ({
  item: {
    paddingLeft: 10,
    paddingRight: 0,
  },
});
