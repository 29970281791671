import { createAction } from 'redux-actions';

export const getEmailTemplatesRequest = createAction('GET_EMAIL_TEMPLATES_REQUEST');
export const getEmailTemplatesSuccess = createAction('GET_EMAIL_TEMPLATES_SUCCESS');
export const getEmailTemplatesFail = createAction('GET_EMAIL_TEMPLATES_FAIL');

export const editEmailTemplateRequest = createAction('EDIT_EMAIL_TEMPLATE_REQUEST');
export const editEmailTemplateSuccess = createAction('EDIT_EMAIL_TEMPLATE_SUCCESS');
export const editEmailTemplateFail = createAction('EDIT_EMAIL_TEMPLATE_FAIL');

export const deleteEmailTemplateRequest = createAction('DELETE_EMAIL_TEMPLATE_REQUEST');
export const deleteEmailTemplateSuccess = createAction('DELETE_EMAIL_TEMPLATE_SUCCESS');
export const deleteEmailTemplateFail = createAction('DELETE_EMAIL_TEMPLATE_FAIL');

export const getEmailSendersRequest = createAction('GET_EMAIL_SENDERS_REQUEST');
export const getEmailSendersSuccess = createAction('GET_EMAIL_SENDERS_SUCCESS');
export const getEmailSendersFail = createAction('GET_EMAIL_SENDERS_FAIL');
