export default {
  gotoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  gotoInput: (theme) => ({
    width: '45px',
    marginLeft: theme.spacing(1),
  }),
};
