import { Box, Typography } from 'material-latest';
import * as React from 'react';
import { styles } from './styles';
import Divider from '../Divider';
import CustomLink from '../CustomLink';

export const Footer = () => {
  return (
    <Box sx={styles.root}>
      <Divider orientation="horizontal" variant="fullWidth" spacing={0} />
      <Box sx={styles.container}>
        <Typography sx={styles.headerText} color="white" variant="h6">
          Customer Notice
        </Typography>
        <Typography color="white" variant="body2" sx={{ ...styles.typography, mb: 2 }}>
          Trading derivatives carries a high level of risk to your capital and you should only trade
          with money you can afford to lose. Any Information or advice contained on this website is
          general in nature and has been prepared without taking into account your objectives,
          financial situation or needs. Past performance of any product described on this website is
          not a reliable indication of future performance. You should consider whether you’re part
          of our target market by reviewing our&nbsp;
          <CustomLink
            rel="noopener noreferrer"
            target="_blank"
            to="https://globalprime.com/legal-documentation">
            Target Market Determination, and read our PDS and other legal documents
          </CustomLink>
          &nbsp; to ensure you fully understand the risks before you make any trading decisions. We
          encourage you to seek independent advice if necessary. Global Prime is not able to take
          clients from Afghanistan, Congo, Iran, Iraq, Myanmar, New Zealand, North Korea, Palestine,
          Russia, Somalia, Sudan, Syria, Ukraine, Ontario, Yemen, Japan, or the United States or its
          territories. The information on this website is not intended to be an inducement, offer or
          solicitation to any person in any country or jurisdiction where such distribution or use
          would be contrary to local law or regulation.
        </Typography>
        <Typography color="white" variant="body2" sx={styles.typography}>
          Global Prime is a trading name of FMGP Trading Group Pty Ltd (ABN 74 146 086 017) and is
          regulated by ASIC and licensed to carry on a financial services business in Australia
          under Australian Financial Services License No. 385620. Gleneagle Securities Pty Limited
          trading as Global Prime FX, is a registered Vanuatu company (Company Number 40256) and is
          regulated by the VFSC. The website is owned and operated by FMGP Trading Group Pty Ltd,
          ABN 74 146 086 017.
        </Typography>
      </Box>
    </Box>
  );
};
