import { createAction } from 'redux-actions';

export const approveWithdrawalRequest = createAction('APPROVE_WITHDRAWAL_REQUEST');
export const approveWithdrawalSuccess = createAction('APPROVE_WITHDRAWAL_SUCCESS');
export const approveWithdrawalFail = createAction('APPROVE_WITHDRAWAL_FAIL');

export const declineWithdrawalRequest = createAction('DECLINE_WITHDRAWAL_REQUEST');
export const declineWithdrawalSuccess = createAction('DECLINE_WITHDRAWAL_SUCCESS');
export const declineWithdrawalFail = createAction('DECLINE_WITHDRAWAL_FAIL');

export const approveSmartwayRequest = createAction('APPROVE_SMARTWAY_REQUEST');
export const approveSmartwaySuccess = createAction('APPROVE_SMARTWAY_SUCCESS');
export const approveSmartwayFail = createAction('APPROVE_SMARTWAY_FAIL');

export const declineSmartwayRequest = createAction('DECLINE_SMARTWAY_REQUEST');
export const declineSmartwaySuccess = createAction('DECLINE_SMARTWAY_SUCCESS');
export const declineSmartwayFail = createAction('DECLINE_SMARTWAY_FAIL');

export const addDepositRequest = createAction('ADD_DEPOSIT_REQUEST');
export const addDepositSuccess = createAction('ADD_DEPOSIT_SUCCESS');
export const addDepositFail = createAction('ADD_DEPOSIT_FAIL');

export const getLiveAccountsRequest = createAction('GET_LIVE_ACCOUNTS_REQUEST');
export const getLiveAccountsSuccess = createAction('GET_LIVE_ACCOUNTS_SUCCESS');
export const getLiveAccountsFail = createAction('GET_LIVE_ACCOUNTS_FAIL');

export const updatePaymentRequest = createAction('UPDATE_PAYMENT_REQUEST');
export const updatePaymentSuccess = createAction('UPDATE_PAYMENT_SUCCESS');
export const updatePaymentFail = createAction('UPDATE_PAYMENT_FAIL');

export const manualApproveWithdrawalRequest = createAction('MANUAL_APPROVE_WITHDRAWAL_REQUEST');
export const manualApproveWithdrawalSuccess = createAction('MANUAL_APPROVE_WITHDRAWAL_SUCCESS');
export const manualApproveWithdrawalFail = createAction('MANUAL_APPROVE_WITHDRAWAL_FAIL');
