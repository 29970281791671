import createAction from 'redux-actions/es/createAction';

export const userMigrationRequest = createAction('USER_MIGRATION_REQUEST');
export const userMigrationSuccess = createAction('USER_MIGRATION_SUCCESS');
export const userMigrationFail = createAction('USER_MIGRATION_FAIL');

export const setIsFirstTimeOpen = createAction('SET_IS_FIRST_TIME_OPEN');

export const acceptMigrationRequest = createAction('ACCEPT_MIGRATION_REQUEST');
export const acceptMigrationSuccess = createAction('ACCEPT_MIGRATION_SUCCESS');
export const acceptMigrationFail = createAction('ACCEPT_MIGRATION_FAIL');
