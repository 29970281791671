import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core';

import CheckboxLabel from '../CheckboxLabel';
import { required } from 'utils/validation/fieldValidationRules';
import PrimaryButton from '../../../../common/PrimaryButton';
import Checkbox from '../../../../common/Checkbox';

import styles from './styles';

const ConfirmContinueAsRetailClientForm = ({ classes, handleSubmit }) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="condition"
        component={Checkbox}
        label={<CheckboxLabel />}
        validate={[required]}
      />
      <div className={classes.buttonWrapper}>
        <PrimaryButton
          label={t('common:buttons.continue')}
          color="primary"
          type="submit"
          size="large"
        />
      </div>
    </form>
  );
};

export default compose(
  reduxForm({
    form: 'PRO_QUIZ_BLOCKED_MODAL_FORM',
    initialValues: { confirmCheckbox: false },
    destroyOnUnmount: true,
  }),
  withStyles(styles),
)(ConfirmContinueAsRetailClientForm);
