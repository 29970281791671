import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Grid, Alert, Card, CardContent, Typography, CardHeader } from 'material-latest';
import DescriptionList from 'modules/common/DescriptionList';
import { styles } from './styles';
import BankCopyLabel from '../BankCopyLabel';

const PayIDPayment = ({ data, onCopy }) => {
  const { t } = useTranslation('payments');

  const descriptionProps = useMemo(
    () => [
      {
        label: t('deposit.payId.coinspot'),
        dataKey: 'coinspot',
        fullWidth: true,
        renderItem: (item) => <BankCopyLabel wordBreakAll text={item.payId} onCopy={onCopy} />,
      },
      {
        label: t('deposit.payId.depositTime'),
        dataKey: 'depositTime',
        renderItem: () => t('deposit.payId.depositTimeValue'),
      },
      {
        label: t('deposit.payId.depositFee'),
        dataKey: 'depositFee',
        renderItem: () => t('deposit.payId.depositFeeValue'),
      },
      {
        label: t('deposit.payId.limit'),
        dataKey: 'limit',
        renderItem: () => t('deposit.payId.limitValue'),
      },
      {
        label: t('deposit.payId.min'),
        dataKey: 'min',
        renderItem: () => t('deposit.payId.minValue'),
      },
    ],
    [t, onCopy],
  );

  return (
    <Grid container spacing={2}>
      <Grid item container xs={12} md={7} lg={6}>
        <Card fullWidth>
          <CardHeader sx={styles.header} title={t('deposit.monoova.payId')}>
            <Typography>{t('deposit.monoova.payId')}</Typography>
          </CardHeader>
          <CardContent>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Alert severity="info">{t('deposit.monoova.payIdWarning')}</Alert>
              </Grid>
              <Grid item>
                <DescriptionList value={data} descriptionProps={descriptionProps} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

PayIDPayment.propTypes = {
  data: PropTypes.object.isRequired,
  onOpenSimulate: PropTypes.func,
  onCopy: PropTypes.func,
  simulateMode: PropTypes.bool,
};

export default PayIDPayment;
