import { palette } from '../../../../../components/CustomThemeProvider/themes/palette';

const styles = (theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100px',
    borderBottom: `1px solid ${palette.green}`,
  },
  title: {
    fontSize: '32px',
    fontWeight: 400,
    lineHeight: '42px',
    letterSpacing: 0,

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
    },

    '@media (min-width:768px)': {
      maxWidth: 250,
    },
    '@media (min-width:1280px)': {
      maxWidth: 340,
    },
    '@media (min-width:1440px)': {
      maxWidth: 500,
    },
    '@media (min-width:1600px)': {
      maxWidth: 'fit-content',
    },
  },
  mainInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '24px 20px 28px 25px',
  },
  warning: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 20px 20px 20px',
    fontSize: 16,
    fontWeight: 600,
    borderRadius: 8,
    color: theme.palette.text.main,
    backgroundColor: theme.header.warning.backgroundColor,
    boxShadow: theme.header.warning.boxShadow,
  },
  warningWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  ico: {
    height: 18,
    marginRight: 16,
  },
  hide: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '12px',
  },
  closeButton: {
    fontSize: 22,
    marginLeft: '12px',
    padding: 0,
    color: theme.palette.black,
  },
  circlesWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  avatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  modal: {
    paddingTop: 90,
    paddingRight: 30,
    justifyContent: 'right !important',
    alignItems: 'baseline !important',
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiDialog-container': {
      alignItems: 'baseline !important',
    },
    '& .MuiDialog-paper': {
      margin: 0,
      maxWidth: '500px',
      '& > div': {
        padding: 16,
        width: 'auto',
        '& section > p': {
          marginBottom: 16,
          fontSize: 18,
        },
      },
    },
  },
});

export default styles;
