import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, change } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import TextInput from 'modules/common/TextInput';
import PrimaryButton from 'modules/common/PrimaryButton';
import {
  required,
  number,
  moreThanZero,
  maxNumLength16,
} from 'utils/validation/fieldValidationRules';

import styles from './styles';
import Results from '../Results';
import SymbolPicker from '../SymbolPicker';
import { Box, Icon, Typography, Grid } from 'material-latest';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const CurrencyCalculatorForm = ({ handleSubmit, isPending, results, symbols, form }) => {
  const { t } = useTranslation('tradingCalculators');
  const dispatch = useDispatch();

  useEffect(() => {
    if (symbols.length) {
      dispatch(change(form, 'symbolGroup', 'Forex'));
    }
  }, [symbols]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.headerContainer}>
        <Icon sx={styles.headerIcon} component={InfoOutlinedIcon} />
        <Typography sx={styles.headerText}>{t('currencyCalculator.description')}</Typography>
      </Box>
      <Box container sx={styles.formContainer}>
        <Box component="form" sx={styles.form} onSubmit={handleSubmit}>
          <Box>
            <Typography sx={styles.title}>Calculator</Typography>
            <Typography sx={styles.description}>{t('formTitle')}</Typography>
            <Grid container gap={2}>
              <Grid item xs={12}>
                <SymbolPicker symbols={symbols} form={form} allowedGroups={['Forex']} />
              </Grid>
              <Grid item xs={12} md={5.7} sx={styles.field}>
                <Field
                  label={t('fields.amount')}
                  placeholder={t('fields.amount')}
                  name="amount"
                  type="text"
                  validate={[number, required, moreThanZero, maxNumLength16]}
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} sx={styles.buttonField}>
                <PrimaryButton
                  color="primary"
                  label={t('buttons.calculate')}
                  type="submit"
                  size="large"
                  isPending={isPending}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Results results={results} />
      </Box>
    </Box>
  );
};

CurrencyCalculatorForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  isPending: PropTypes.bool.isRequired,
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default reduxForm()(CurrencyCalculatorForm);
