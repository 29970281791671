import { handleActions } from 'redux-actions';
import * as actions from '../actions';

const defaultState = {
  countries: [],
};

export default handleActions(
  {
    [actions.riskGroupGetCountriesSuccess]: (state, action) => {
      return {
        ...state,
        countries: action.response.data,
      };
    },
    [actions.riskGroupAddCountrySuccess]: (state, action) => {
      return {
        ...state,
        countries: action.response.data,
      };
    },
    [actions.riskGroupRemoveCountrySuccess]: (state, action) => {
      return {
        ...state,
        countries: action.response.data,
      };
    },
  },
  defaultState,
);
