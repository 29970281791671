import createAction from 'redux-actions/es/createAction';

export const signLpoaRequest = createAction('SIGN_LPOA_REQUEST');
export const signLpoaSuccess = createAction('SIGN_LPOA_SUCCESS');
export const signLpoaFail = createAction('SIGN_LPOA_FAIL');

export const getLpoaRequest = createAction('GET_LPOA_REQUEST');
export const getLpoaSuccess = createAction('GET_LPOA_SUCCESS');
export const getLpoaFail = createAction('GET_LPOA_FAIL');

export const revokeLpoaRequest = createAction('REVOKE_LPOA_REQUEST');
export const revokeLpoaSuccess = createAction('REVOKE_LPOA_SUCCESS');
export const revokeLpoaFail = createAction('REVOKE_LPOA_FAIL');
