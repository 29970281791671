const styles = (theme) => ({
  label: {
    marginBottom: 2,
  },
  cellLabel: {
    '@media (min-width:1366px)': {
      display: 'none',
    },
  },
  title: {
    color: theme.palette.simpleGrey,
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 600,
  },
  amountText: {
    color: theme.tableAmountField.color,
    fontSize: 14,
    fontWeight: 700,
    whiteSpace: 'nowrap',
    lineHeight: 'normal',
  },
  item: {
    padding: '20px 12px',
    minHeight: 40,
    width: 80,
  },
});

export default styles;
