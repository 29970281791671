import { handleActions } from 'redux-actions';
import { MENU_ITEMS } from '../constants';
import get from 'lodash/get';

import {
  getAnalystDataRequest,
  getAnalystDataSuccess,
  setSelected,
  setFilter,
  setForexFilter,
  setType,
} from '../actions';

const defaultState = {
  list: [],
  selected: null,
  isLoading: true,
  filter: '',
  forexFilter: 'major',
  type: MENU_ITEMS[0],
};

export default handleActions(
  {
    [getAnalystDataRequest]: (state) => {
      return {
        ...state,
        isLoading: true,
        selected: null,
        filter: '',
      };
    },
    [getAnalystDataSuccess]: (state, action) => {
      const list = get(action, 'response.data', {});
      const mapper = list.reduce((res, curr) => ({ ...res, [curr.id]: curr }), {});

      return {
        ...state,
        list,
        mapper,
        isLoading: false,
      };
    },
    [setSelected]: (state, action) => {
      return {
        ...state,
        selected: state.mapper[action.payload.id],
      };
    },
    [setFilter]: (state, action) => {
      const filter = new RegExp(action.payload.id, 'ig');
      return {
        ...state,
        filter,
      };
    },
    [setForexFilter]: (state, action) => {
      return {
        ...state,
        forexFilter: action.payload.forexFilter,
      };
    },
    [setType]: (state, action) => {
      return {
        ...state,
        type: action.payload,
      };
    },
  },
  defaultState,
);
