import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

import TPPopover from 'components/TP-UI/TPPopover';
import { PLACEMENT, TRIGGER } from 'components/TP-UI/constants';

const TPTooltipBase = ({
  content,
  children,
  placement = PLACEMENT.TOP,
  disabled,
  minWidth,
  maxWidth = '250px',
  enterDelay = 500,
  offset = '0,4',
  trigger = TRIGGER.HOVER,
  open,
  forwardedRef,
  ...props
}) => {
  const actualTrigger = isMobile ? TRIGGER.CLICK : trigger;
  return (
    <TPPopover
      ref={forwardedRef}
      content={content}
      placement={placement}
      disabled={disabled}
      trigger={actualTrigger}
      minWidth={minWidth}
      maxWidth={maxWidth}
      enterDelay={actualTrigger === TRIGGER.CLICK ? 0 : enterDelay}
      offset={offset}
      data-clickable={actualTrigger === TRIGGER.CLICK}
      open={open}
      {...props}>
      {children}
    </TPPopover>
  );
};

TPTooltipBase.propTypes = {
  content: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node, PropTypes.string]),
  children: PropTypes.node.isRequired,
  placement: PropTypes.oneOf([
    PLACEMENT.TOP_START,
    PLACEMENT.TOP,
    PLACEMENT.TOP_END,
    PLACEMENT.LEFT_START,
    PLACEMENT.LEFT,
    PLACEMENT.LEFT_END,
    PLACEMENT.RIGHT_START,
    PLACEMENT.RIGHT,
    PLACEMENT.RIGHT_END,
    PLACEMENT.BOTTOM_START,
    PLACEMENT.BOTTOM,
    PLACEMENT.BOTTOM_END,
    PLACEMENT.AUTO_START,
    PLACEMENT.AUTO,
    PLACEMENT.AUTO_END,
  ]),
  disabled: PropTypes.bool,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  offset: PropTypes.string,
  trigger: PropTypes.oneOf([TRIGGER.HOVER, TRIGGER.CLICK]),
};

const TPTooltip = React.forwardRef((props, ref) => <TPTooltipBase {...props} forwardedRef={ref} />);

export default TPTooltip;
