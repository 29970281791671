export const mobileSmallCell = () => ({
  item: {
    padding: '17px 0px',
    '@media (min-width:640px)': {
      padding: '17px 15px',
    },
    '@media (min-width:1366px)': {
      padding: '22px 10px',
    },
  },
});

export const VPSCell = () => ({ item: { width: 110, minWidth: '110px !important' } });

export const UnfixedStatusCell = () => ({
  item: {
    '@media (min-width:400px)': {
      width: 'auto',
      minWidth: '105px',
    },
  },
});
