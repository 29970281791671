import { createAction } from 'redux-actions';

export const sendPartnerTransferRequest = createAction('SEND_PARTNER_TRANSFER_REQUEST');
export const sendPartnerTransferSuccess = createAction('SEND_PARTNER_TRANSFER_SUCCESS');
export const sendPartnerTransferFail = createAction('SEND_PARTNER_TRANSFER_FAIL');

export const getAffiliateBalanceRequest = createAction('GET_AFFILIATE_BALANCE_REQUEST');
export const getAffiliateBalanceSuccess = createAction('GET_AFFILIATE_BALANCE_SUCCESS');
export const getAffiliateBalanceFail = createAction('GET_AFFILIATE_BALANCE_FAIL');

export const getPartnerTransferHistoryRequest = createAction(
  'GET_PARTNER_TRANSFER_HISTORY_REQUEST',
);
export const getPartnerTransferHistorySuccess = createAction(
  'GET_PARTNER_TRANSFER_HISTORY_SUCCESS',
);
export const getPartnerTransferHistoryFail = createAction('GET_PARTNER_TRANSFER_HISTORY_FAIL');
