export default (theme) => ({
  root: {},
  title: {
    color: theme.palette.text.main,
    fontSize: '18px',
    fontWeight: '300',
    margin: '0',
    border: 'none',
    marginBottom: '25px',
  },
  fieldContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  buttonsSection: {
    display: 'flex',
    gap: '5px',
  },
  filter: {
    width: '100%',
    maxWidth: 'initial',
  },
});
