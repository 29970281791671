const styles = (theme) => ({
  label: {
    color: theme.tableTextField.labelColor,
    fontSize: 12,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 3,
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: 1.4,
    color: theme.palette.simpleGrey,
    marginRight: 10,
  },
  dataText: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.main,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.3,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  item: {
    padding: 16,
    height: 80,
    '@media (min-width:400px)': {
      height: 'auto',
    },
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  dataIconContainer: {
    minWidth: 20,
    maxWidth: 20,
    height: 20,
    borderRadius: 50,
    marginRight: 5,
    overflow: 'hidden',
    position: 'relative',
  },
  dataIcon: {
    height: 20,
    transform: 'scale(1)',
    objectPosition: 'center',
    objectFit: 'cover',
    width: 20,
  },
  loader: {
    margin: 'auto',
  },
});

export default styles;
