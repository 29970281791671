import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Stack } from 'material-latest';
import { withStyles } from '@material-ui/core/styles';

import PrimaryButton from 'modules/common/PrimaryButton';
import SecondaryButton from 'modules/common/SecondaryButton';
import styles from './styles';

const checkCondition = (condition = [], tableItem, conditionLabel) =>
  condition.includes(get(tableItem, conditionLabel));

const TableButtonsWithConditionField = ({
  classes,
  leftButtonProps,
  rightButtonProps,
  tableItem,
  showLabel,
  isHeadRow,
}) => {
  if (showLabel && isHeadRow) {
    return <div className={classes.item} />;
  }

  return (
    <div className={classes.item}>
      <Stack direction="row" justifyContent="space-between">
        {checkCondition(leftButtonProps.condition, tableItem, leftButtonProps.conditionLabel) ? (
          <SecondaryButton
            variant="contained"
            color="secondary"
            label={leftButtonProps.label}
            className={`${classes.button} ${classes.button__small}`}
            onClick={() => leftButtonProps.onClick(tableItem)}
          />
        ) : (
          <div />
        )}
        {checkCondition(rightButtonProps.condition, tableItem, rightButtonProps.conditionLabel) ? (
          <PrimaryButton
            color="primary"
            label={rightButtonProps.label}
            className={`${classes.button} ${classes.button__large}`}
            onClick={() => rightButtonProps.onClick(tableItem)}
          />
        ) : (
          <div />
        )}
      </Stack>
    </div>
  );
};

TableButtonsWithConditionField.propTypes = {
  tableItem: PropTypes.object.isRequired,
  showLabel: PropTypes.bool,
};

export default withStyles(styles)(TableButtonsWithConditionField);
