const copyTextToClipboard = (text) => {
  let input = document.createElement('textarea');
  input.style.top = '0';
  input.style.left = '0';
  input.style.position = 'fixed';
  input.value = text;
  document.body.appendChild(input);
  input.focus();
  input.select();
  input.setSelectionRange(0, 99999);
  document.execCommand('copy');
  document.body.removeChild(input);
};

export default copyTextToClipboard;
