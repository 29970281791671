import api from 'modules/api/index';

export const addRiskUserRequest = (payload) => {
  return api.post('/risk-flag', payload);
};

export const updateRiskUserRequest = (payload) => {
  return api.patch('/risk-flag', payload);
};

export const deleteRiskUserRequest = (payload) => {
  const { _id } = payload;
  return api.delete(`/risk-flag/${_id}`, payload);
};
