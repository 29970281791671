import { call, put, takeEvery, delay } from 'redux-saga/effects';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import unset from 'lodash/unset';

import { apiCallCounterDec, apiCallCounterInc } from '../actions';
import { logout } from 'modules/auth/actions';
import * as calls from '../calls';
import { push } from 'connected-react-router';
import { Routes } from 'constants/routeConstants';
import { deleteUserSessionsRequest } from 'modules/profile/pages/ProfileData/actions';

const successPostfix = '_SUCCESS';
const failPostfix = '_FAIL';
const startPostfix = '_REQUEST';
const requestPendingDelay = 200; //ms
const requestPollingDelay = 5000;
const allowedPaths = [
  `/hub${Routes.SIGNIN}`,
  `/hub${Routes.SIGNUP}`,
  '/hub/auth/forgot-password',
  `/hub${Routes.FORCE_PASSWORD_CHANGE}`,
];

function createFailAction(action, error) {
  const { payload, type } = action;
  const { response } = error;

  return {
    type: type.replace(startPostfix, failPostfix),
    payload,
    response,
    error,
  };
}

function createSuccessAction(action, response) {
  const { type, payload } = action;

  return {
    type: type.replace(startPostfix, successPostfix),
    payload,
    response,
  };
}

function* checkMfa(error) {
  if (get(error, 'data.index') === '442' && !allowedPaths.includes(window.location.pathname)) {
    yield put(push(Routes.MFA_VERIFICATION));
  }
}

function* isPasswordChangeRequired(error) {
  if (get(error, 'data.index') === '460') {
    yield put(push(Routes.FORCE_PASSWORD_CHANGE));
  }
}

function* checkStatus(status) {
  switch (status) {
    case 400:
      // yield put(push(Routes.BAD_REQUEST));
      break;
    case 401:
      // handled by axios interceptors
      yield put(logout());
      break;
    case 403:
      //yield put(push(Routes.FORBIDDEN));
      break;
    case 500:
    case 501:
    case 502:
    case 503:
      //yield put(push(Routes.ERROR));
      break;
    default:
      break;
  }
}

function* handleError(error, action) {
  if (error.status) {
    yield* checkStatus(error.status);
  }
  if (error.status === 400) {
    yield* isPasswordChangeRequired(error);
    yield* checkMfa(error);
  }
  yield put(createFailAction(action, error));
}

function* sendRequest(action) {
  yield put(apiCallCounterInc(action.type));
  let callMethod = calls[camelCase(action.type)];
  if (!callMethod) {
    throw new Error(`no api method for action ${action.type}`);
  }

  const token = get(action, 'payload.customAuthToken');

  if (token) {
    unset(action, 'payload.customAuthToken');
  }

  try {
    let response = yield call(callMethod, action.payload);
    if (response.data.isLongOperation && response.data.longOperationId) {
      const longOperationId = response.data.longOperationId;
      while (true) {
        const interval = response.data.interval || requestPollingDelay;
        yield delay(interval);
        try {
          response = yield call(calls.getLongOperationRequest, longOperationId);
        } catch (error) {
          if (error.status === 404) {
            return;
          }
          yield handleError(error, action);
        }
        if (!response.data.completed) {
          continue;
        }
        if (response.data.statusCode === 200) {
          yield put(createSuccessAction(action, { data: response.data.result }));
          return;
        }
        yield handleError(
          {
            status: response.data.statusCode,
            data: response.data.result,
          },
          action,
        );
        return;
      }
    }
    yield put(createSuccessAction(action, response));
  } catch (error) {
    yield handleError(error, action);
  }
}

function* requestEnded(action) {
  yield delay(requestPendingDelay);

  let type = action.type.replace(successPostfix, startPostfix).replace(failPostfix, startPostfix);
  yield put(apiCallCounterDec(type));
}

let isApiCallAction = (action) => {
  return action.type.endsWith(startPostfix);
};

let isApiCallEndedAction = (action) => {
  return action.type.endsWith(successPostfix) || action.type.endsWith(failPostfix);
};

function* apiCallsSaga() {
  yield takeEvery(isApiCallAction, sendRequest);
  yield takeEvery(isApiCallEndedAction, requestEnded);
}

export default apiCallsSaga;
