export const Status = {
  TOCONFIRM: 'To confirm',
  PENDING: 'Pending',
  APPROVED: 'Success',
  TRUSTED: 'Trusted',
  DECLINED: 'Declined',
  EXPIRED: 'Expired',
};

export const DEPOSIT_DEFAULT_STATUS = 'DEPOSIT_DEFAULT_STATUS';
