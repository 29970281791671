import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import InternalHubTable from 'modules/common/InternalHubTable/Table';
import FieldsFactory from 'modules/common/TableFields/FieldsFactory';
import styles from './styles';
import ScrollOnTopButton from 'modules/common/ScrollOnTopButton';
import Header from '../../components/BaseHeader';
import formatPercent from '../../../../utils/formatPercent';
import formatPriceMillions from '../../../../utils/formatPriceMillions';

const compareFormatFunc = (diff, amount) => {
  if (!diff || !amount) {
    return '0 (0.00%)';
  }
  return diff + ' (' + ((parseFloat(diff) / parseFloat(amount)) * 100).toFixed(2) + '%)';
};

const CountryUsersView = ({
  items,
  isPending,
  isDataFiltered,
  getRedirectRoute,
  headerProps,
  onSort,
  isSorted,
  getSortDirection,
  compare,
  // onPageChange,
}) => {
  const props = {
    isDataLoading: isPending,
    tableMessageProps: {
      isDataFiltered,
      message: 'No data',
    },
    tableFields: [
      {
        label: 'Country',
        dataLabel: 'country',
        getRedirectRoute,
        width: 168,
        Component: FieldsFactory.create('TableTextField')
          .addSorting(onSort, isSorted, getSortDirection)
          .addTheme('boldText')
          .addTheme('fixedCell')
          .addTheme('normalCell')
          .addTheme('countryCell')
          .get(),
      },
      {
        label: 'Registrations',
        dataLabel: 'registered',
        compareFormatFunc: compareFormatFunc,
        width: 120,
        Component: FieldsFactory.create(
          compare && !isPending ? 'TableTextCompareField' : 'TableTextField',
        )
          .addSorting(onSort, isSorted, getSortDirection)
          .addTheme('normalCell')
          .get(),
      },
      {
        label: 'Live Users',
        dataLabel: 'live',
        compareFormatFunc: compareFormatFunc,
        width: 90,
        Component: FieldsFactory.create(
          compare && !isPending ? 'TableTextCompareField' : 'TableTextField',
        )
          .addSorting(onSort, isSorted, getSortDirection)
          .addTheme('normalCell')
          .get(),
      },
      {
        label: 'Funded Users',
        dataLabel: 'funded',
        compareFormatFunc: compareFormatFunc,
        width: 90,
        Component: FieldsFactory.create(
          compare && !isPending ? 'TableTextCompareField' : 'TableTextField',
        )
          .addSorting(onSort, isSorted, getSortDirection)
          .addTheme('normalCell')
          .get(),
      },
      {
        label: 'FTT Users',
        dataLabel: 'traded',
        compareFormatFunc: compareFormatFunc,
        width: 90,
        Component: FieldsFactory.create(
          compare && !isPending ? 'TableTextCompareField' : 'TableTextField',
        )
          .addSorting(onSort, isSorted, getSortDirection)
          .addTheme('normalCell')
          .get(),
      },
      {
        label: 'Registrations to Live',
        dataLabel: 'conversionToLive',
        formatFunc: formatPercent,
        width: 90,
        Component: FieldsFactory.create(
          compare && !isPending ? 'TableTextCompareField' : 'TableTextField',
        )
          .addSorting(onSort, isSorted, getSortDirection)
          .addTheme('normalCell')
          .get(),
      },
      {
        label: 'Live to Traded',
        dataLabel: 'conversionToTraded',
        formatFunc: formatPercent,
        width: 80,
        Component: FieldsFactory.create(
          compare && !isPending ? 'TableTextCompareField' : 'TableTextField',
        )
          .addSorting(onSort, isSorted, getSortDirection)
          .addTheme('normalCell')
          .get(),
      },
      {
        label: 'Full Funnel Conversions',
        dataLabel: 'fullFunnelConversions',
        formatFunc: formatPercent,
        width: 110,
        Component: FieldsFactory.create(
          compare && !isPending ? 'TableTextCompareField' : 'TableTextField',
        )
          .addSorting(onSort, isSorted, getSortDirection)
          .addTheme('normalCell')
          .get(),
      },
      {
        label: '% of Reffered',
        dataLabel: 'refferedPercent',
        formatFunc: formatPercent,
        width: 74,
        Component: FieldsFactory.create(
          compare && !isPending ? 'TableTextCompareField' : 'TableTextField',
        )
          .addSorting(onSort, isSorted, getSortDirection)
          .addTheme('normalCell')
          .get(),
      },
      {
        label: 'FTT Referred',
        dataLabel: 'fttRefferedPercent',
        formatFunc: formatPercent,
        width: 74,
        Component: FieldsFactory.create(
          compare && !isPending ? 'TableTextCompareField' : 'TableTextField',
        )
          .addSorting(onSort, isSorted, getSortDirection)
          .addTheme('normalCell')
          .get(),
      },
      {
        label: 'Volume',
        dataLabel: 'volume',
        formatFunc: formatPriceMillions,
        width: 110,
        Component: FieldsFactory.create(
          compare && !isPending ? 'TableTextCompareField' : 'TableTextField',
        )
          .addSorting(onSort, isSorted, getSortDirection)
          .addTheme('normalCell')
          .get(),
      },
      {
        label: '% of Value',
        dataLabel: 'volumePercent',
        formatFunc: formatPercent,
        width: 74,
        Component: FieldsFactory.create(
          compare && !isPending ? 'TableTextCompareField' : 'TableTextField',
        )
          .addSorting(onSort, isSorted, getSortDirection)
          .addTheme('normalCell')
          .get(),
      },
      {
        label: 'Volume FX & Metals',
        dataLabel: 'volumeFxMetals',
        formatFunc: formatPriceMillions,
        width: 120,
        Component: FieldsFactory.create(
          compare && !isPending ? 'TableTextCompareField' : 'TableTextField',
        )
          .addSorting(onSort, isSorted, getSortDirection)
          .addTheme('normalCell')
          .get(),
      },
      {
        label: 'Volume Else',
        dataLabel: 'volumeElse',
        formatFunc: formatPriceMillions,
        width: 110,
        Component: FieldsFactory.create(
          compare && !isPending ? 'TableTextCompareField' : 'TableTextField',
        )
          .addSorting(onSort, isSorted, getSortDirection)
          .addTheme('normalCell')
          .get(),
      },
    ],
    data: items,
    // paginationProps: {
    //   onPageChange,
    // },
  };

  return (
    <>
      <Header {...headerProps} />
      <InternalHubTable {...props} />
      <ScrollOnTopButton />
    </>
  );
};

export default withStyles(styles)(CountryUsersView);
