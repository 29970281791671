import api from 'modules/api/index';

export const createLpoaModalRequest = (payload) => {
  const { userId } = payload;
  return api.post(`/lpoa/${userId}/create`, payload);
};

export const signLpoaRequest = (payload) => {
  return api.post('/lpoa/sign', payload);
};

export const getLpoaRequest = (userId) => {
  return api.get(userId ? `/lpoa/${userId}` : '/lpoa');
};

export const revokeLpoaRequest = () => {
  return api.post('/lpoa/revoke');
};
