import api from 'modules/api/index';

export const getTicketsRequest = (payload) => {
  const { email, asc, skip, limit } = payload;
  return api.post(`/tickets/${email}`, { asc, skip, limit });
};

export const getTicketDetailsRequest = (payload) => {
  const { ticketId } = payload;
  return api.get(`/tickets/${ticketId}/details`);
};

export const getTicketConversationsRequest = (payload) => {
  const { ticketId, page } = payload;
  return api.get(`/tickets/${ticketId}/conversations`, { params: { page } });
};
