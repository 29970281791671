import React from 'react';
import { IconButton } from 'material-latest';
import PropTypes from 'prop-types';

import { MAIN_COLORS, SIZES } from 'components/TP-UI/constants';

const TPIconButtonBase = ({
  className,
  color = '',
  size = SIZES.MEDIUM,
  disableRipple = false,
  children,
  forwardedRef,
  ...props
}) => {
  return (
    <IconButton
      ref={forwardedRef}
      sx={className}
      size={size}
      color={color}
      disableRipple={!!color || disableRipple}
      {...props}>
      {children}
    </IconButton>
  );
};

TPIconButtonBase.propTypes = {
  color: PropTypes.oneOf(['', MAIN_COLORS.PRIMARY]),
  size: PropTypes.oneOf([SIZES.LARGE, SIZES.MEDIUM, SIZES.SMALL, SIZES.XSMALL]),
  disableRipple: PropTypes.bool,
};

const TPIconButton = React.forwardRef((props, ref) => (
  <TPIconButtonBase {...props} forwardedRef={ref} />
));

export default TPIconButton;
