const styles = (theme) => ({
  container: {
    height: 'calc(100vh - 300px)',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  text: {
    color: theme.palette.text.main,
    fontSize: '20px',
    fontWeight: 300,
    lineHeight: '1.3',
    textAlign: 'center',
    marginTop: '25px',
  },
  noDataImage: {
    height: 'fit-content',
    alignSelf: 'center',
  },
  noMatchesImage: {
    height: 'fit-content',
    alignSelf: 'center',
  },
});

export default styles;
