export default {
  list: (theme) => ({
    margin: 0,
    padding: 0,
    listStyle: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
  }),
  errorContainer: (theme) => ({
    minHeight: theme.textError.root.minHeight,
  }),
};
