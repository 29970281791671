import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Box from 'material-latest/Box';

import TPTypography from 'components/TP-UI/TPTypography';
import { TPSelect } from 'components/TP-UI/TPSelect';
import { MAIN_COLORS, SIZES } from 'components/TP-UI/constants';
import { DEFAULT_PAGE_SIZE_OPTIONS } from './constants';

import styles from './styles';

export const TPPageSize = ({
  value = 0,
  options = DEFAULT_PAGE_SIZE_OPTIONS,
  disabled = false,
  onChange,
  className,
}) => {
  const { t } = useTranslation('');

  const displayedOptions = useMemo(
    () => options.map((pageSize) => ({ label: pageSize, value: pageSize })),
    [options],
  );

  return (
    <Box sx={[styles.pageSizeContainer, className]}>
      <TPTypography variant="body2" color={MAIN_COLORS.SECONDARY}>
        {t('common:labels:show')}
      </TPTypography>
      <TPSelect
        name="pageSize"
        value={value}
        size={SIZES.SMALL}
        options={displayedOptions}
        disabled={disabled}
        onChange={onChange}
        reservedErrorSpace={false}
      />
      <TPTypography variant="body2" color={MAIN_COLORS.SECONDARY}>
        {t('common:labels:entries')}
      </TPTypography>
    </Box>
  );
};

TPPageSize.propTypes = {
  /**
   * Number of data items per page
   */
  value: PropTypes.number,
  /**
   * List of available page sizes
   */
  options: PropTypes.arrayOf(PropTypes.number),
  disabled: PropTypes.bool,
  /**
   * Called when the page size is changed, function (pageSize)
   */
  onChange: PropTypes.func,
};

export default TPPageSize;
