import React from 'react';
import i18n from 'i18n';

const translateLabelsInArray = (translationsWithValues, LabelComponent) =>
  translationsWithValues.map(({ i18nValue, value, ...props }) => ({
    value,
    label: LabelComponent ? (
      <LabelComponent label={i18n.t(i18nValue)} {...props} />
    ) : (
      i18n.t(i18nValue)
    ),
  }));

export default translateLabelsInArray;
