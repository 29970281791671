import React from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import 'react-datepicker/dist/react-datepicker.css';
import InputLabel from '@material-ui/core/InputLabel';
import { ErrorOutline } from '@material-ui/icons';
import { withStyles } from '@material-ui/core';
import { getTranslation } from 'utils/compositeTranslationHandler';
import { VERIFICATION_MANAGEMENT_FOLLOWUPS_FORM } from '../../../../verificationManagement/pages/Requests/constants';

import img from '../../../../../static/date_picker.svg';
import styles from './styles';

const DatePickerComponent = (props) => {
  const {
    onChange,
    placeholder,
    startDate,
    endDate,
    isClearable,
    minDate,
    maxDate,
    selectsStart,
    selectsEnd,
    dateFormat,
    showTimeInput,
    classes,
    customInput,
    customTitle,
    meta,
    disabled,
    name,
    required,
    showIcon,
  } = props;
  const { t } = useTranslation();
  const showErrorWOSubmit = meta.form === VERIFICATION_MANAGEMENT_FOLLOWUPS_FORM;
  const addIcon = customInput && showIcon;

  return (
    <div
      className={classNames(classes.DatePickerWrapper, {
        [classes.DatePickerCustom]: customInput,
        [classes.DatePickerIcon]: addIcon,
      })}>
      {addIcon && (
        <img
          className={classNames({
            [classes.imgDisabled]: disabled,
          })}
          src={img}
          alt="icon"
        />
      )}
      <DatePicker
        className={classNames(classes.DatePicker, {
          [classes.textFieldError]: meta.error && (meta.submitFailed || showErrorWOSubmit),
        })}
        name={name}
        selected={selectsEnd ? endDate : startDate}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        showTimeInput={showTimeInput}
        timeFormat="h:mm aa"
        minDate={minDate}
        maxDate={maxDate}
        placeholderText={placeholder || new Date().toLocaleDateString('en-GB')}
        dateFormat={dateFormat || 'dd/MM/yyyy'}
        isClearable={isClearable}
        disabled={disabled}
      />
      {customInput ? (
        <InputLabel
          className={classNames(classes.customLabel, {
            [classes.labelError]: meta.error && meta.submitFailed,
          })}>
          {customTitle || t('common:labels.date')}
          {required ? '*' : ''}
        </InputLabel>
      ) : null}
      {meta.error && (meta.submitFailed || showErrorWOSubmit) && (
        <span className={classes.errorSelect}>
          <ErrorOutline className={classes.errorIcon} />
          &nbsp;
          {getTranslation(t, meta.error)}
        </span>
      )}
    </div>
  );
};

DatePickerComponent.propTypes = {
  onChange: PropTypes.func,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  dateFormat: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  isClearable: PropTypes.bool,
  classes: PropTypes.object,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  selectsStart: PropTypes.bool,
  selectsEnd: PropTypes.bool,
  showTimeInput: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  input: PropTypes.object,
  required: PropTypes.bool,
  showIcon: PropTypes.bool,
};

export default withStyles(styles)(DatePickerComponent);
