export function setMetaDataDescription(description) {
  let meta = document.createElement('meta');
  meta.name = 'description';
  meta.content = description;

  let existingMetaDescription = document.querySelector('meta[name="description"]');
  if (existingMetaDescription) {
    existingMetaDescription.content = description;
  } else {
    let viewportMetaTag = document.querySelector('meta[name="viewport"]');
    viewportMetaTag.insertAdjacentElement('afterend', meta);
  }
}
