import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomModal from 'modules/common/CustomModal';
import * as apiSelectors from 'modules/api/selectors';
import CountryUsersPage from '../views/CountryUsersView';
import CountryFilter from '../components/CountryFilter';
import * as actions from '../actions';
import { getCountryUsers, getLeadOwners } from '../selectors';
import moment from 'moment';
import {
  DASHBOARD_COUNTRY_USERS_HEADER_FORM,
  CUSTOM_DATE_RANGE_KEY,
  COUNTRY_FIELDS,
  COUNTRY_FILTER_MODAL,
} from '../constants';
import { DATE_FORMAT_YYYYMMDD } from '../../../constants';
import { store } from '../../../components/App';
import CountryUsersFilters from '../components/CountryUsersFilters';

const CountryUsersContainer = () => {
  const dispatch = useDispatch();
  const isCountryUsersGetPending = useSelector(
    apiSelectors.createPendingSelector(actions.getCountryUsersRequest),
  );
  const isCountryUsersCompareGetPending = useSelector(
    apiSelectors.createPendingSelector(actions.getCountryUsersCompareRequest),
  );
  const isCountryUsersCompareExportGetPending = useSelector(
    apiSelectors.createPendingSelector(actions.getCountryUsersCompareExportRequest),
  );
  const countryUsersResponse = useSelector(getCountryUsers);
  const [sortBy, setSortBy] = useState(COUNTRY_FIELDS.REGISTERED);
  const [sortDirection, setSortDirection] = useState(-1);
  const [countryUsers, setCountryUsers] = useState([]);
  const [compare, setCompare] = useState(false);
  const leadOwners = useSelector(getLeadOwners);

  useEffect(() => {
    const dateFrom = moment()
      .startOf('month')
      .format(DATE_FORMAT_YYYYMMDD);
    const dateTo = moment().format(DATE_FORMAT_YYYYMMDD);
    dispatch(actions.getCountryUsersRequest({ dateFrom, dateTo }));
    dispatch(actions.getLeadOwnersRequest({}));
    import('../sagas/country').then((saga) => {
      store.injectSaga('CountryContainer', saga.default);
    });
  }, [dispatch]);

  useEffect(() => {
    setCountryUsers(countryUsersResponse);
  }, [countryUsersResponse]);

  const handleHeaderFormSubmit = useCallback(
    (data) => {
      setCompare(data.shouldCompare);
      dispatch(actions.handleHeaderFormSubmit(data));
    },
    [dispatch, setCompare],
  );

  const onPageChange = useCallback(() => {
    // debugger;
  }, []);

  const onSort = useCallback(
    (field) => {
      let direction = -1;
      let _field = field;
      if (field === sortBy) {
        direction = sortDirection * -1;
      }

      if (_field !== 'registered' && sortDirection === 1 && direction === -1) {
        _field = 'registered';
      }

      const sorted = countryUsersResponse.sort((a, b) => {
        const a_conv = _field === 'volume' ? Number(a[_field]) : a[_field];
        const b_conv = _field === 'volume' ? Number(b[_field]) : b[_field];
        if (a_conv > b_conv) {
          return direction;
        }
        if (a_conv < b_conv) {
          return direction * -1;
        }
        return 0;
      });

      setCountryUsers([...sorted]);
      setSortBy(_field);
      setSortDirection(direction);
    },
    [countryUsersResponse, sortBy, sortDirection],
  );

  const isSorted = useCallback(
    (field) => {
      return sortBy === field;
    },
    [sortBy],
  );

  const getSortDirection = useCallback(() => {
    return sortDirection;
  }, [sortDirection]);

  const closeModal = useCallback(() => {
    dispatch(actions.closeCountryFilterModel());
  }, [dispatch]);

  const selectCountryFilter = useCallback(
    (countries) => {
      if (!countries) {
        setCountryUsers(countryUsersResponse);
      }
      if (countries === 'custom') {
        return dispatch(actions.openCountryFilterModel());
      }

      const countrySet = new Set(countries);
      const filtered = countryUsersResponse.filter(({ country }) => countrySet.has(country));
      setCountryUsers(filtered);
    },
    [dispatch, countryUsersResponse, setCountryUsers],
  );

  const selectCustomCountryFilter = useCallback(
    (countries) => {
      selectCountryFilter(countries);
      dispatch(actions.closeCountryFilterModel());
    },
    [dispatch, selectCountryFilter],
  );

  const exportData = useCallback(() => {
    dispatch(actions.getCountryUsersCompareExport());
  }, [dispatch]);

  const headerProps = {
    form: DASHBOARD_COUNTRY_USERS_HEADER_FORM,
    expandable: true,
    initialValues: {
      customDateSelector: CUSTOM_DATE_RANGE_KEY.MONTH_TO_DATE,
      dateFrom: moment()
        .startOf('month')
        .format(DATE_FORMAT_YYYYMMDD),
      dateTo: moment().format(DATE_FORMAT_YYYYMMDD),
      countryFilter: '',
      shouldCompare: false,
      compareDateSelector: CUSTOM_DATE_RANGE_KEY.MONTH_TO_DATE,
      dateFromCompare: moment()
        .startOf('month')
        .subtract(1, 'month')
        .format(DATE_FORMAT_YYYYMMDD),
      dateToCompare: moment()
        .subtract(1, 'month')
        .format(DATE_FORMAT_YYYYMMDD),
    },
    exportData: exportData,
    onSubmit: handleHeaderFormSubmit,
    additionalFilter: (
      <CountryUsersFilters selectCountryFilter={selectCountryFilter} leadOwners={leadOwners} />
    ),
  };

  return (
    <>
      <CountryUsersPage
        headerProps={headerProps}
        isPending={
          isCountryUsersGetPending ||
          isCountryUsersCompareGetPending ||
          isCountryUsersCompareExportGetPending
        }
        items={countryUsers}
        onSort={onSort}
        isSorted={isSorted}
        getSortDirection={getSortDirection}
        onPageChange={onPageChange}
        isDataFiltered={false}
        compare={compare}
      />
      <CustomModal modalName={COUNTRY_FILTER_MODAL}>
        <CountryFilter
          countries={countryUsersResponse}
          closeModal={closeModal}
          selectFilter={selectCustomCountryFilter}
        />
      </CustomModal>
    </>
  );
};

export default CountryUsersContainer;
