import api from 'modules/api';
import getFormData from './getFormData';

export const classicVerificationSecondStepUpdateRequest = (payload) => {
  const formData = getFormData(payload);

  let headers = {
    'Content-Type': 'multipart/form-data',
  };

  return api.patch('/verification/classic-second-step', formData, headers);
};
