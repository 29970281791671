import config from 'config';

const NO_WARNING = new Set(['Australia']);

const WARNING1_COUNTRIES = new Set([
  'Austria',
  'Belgium',
  'Cyprus',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'Ireland',
  'Italy',
  'Latvia',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Netherlands',
  'Portugal',
  'Slovakia',
  'Slovenia',
  'Spain',
  'United Kingdom of Great Britain and Northern Ireland',
  'Canada',
]);

const getWarning = (t, country) => {
  if (config.IS_CLIM) {
    return null;
  }

  if (NO_WARNING.has(country)) {
    return null;
  }

  if (WARNING1_COUNTRIES.has(country)) {
    return t('payments:page.warning1');
  }

  return t('payments:page.warning2');
};

export default getWarning;
