const styles = (theme) => ({
  item: {
    '@media (max-width:1280px)': {
      padding: '13px 20px 13px 14px',
      width: '238px',
    },
    '@media (min-width:1280px)': {
      padding: '13px 30px 13px 25px',
      width: 350,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    height: '34px',
    borderRadius: 0,
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '15px',
    whiteSpace: 'nowrap',
  },
  button__large: {
    width: '116px',
  },
  button__small: {
    width: '50px',
  },
  button__decline: {
    marginRight: '16px',
  },
  button__approve: {
    border: `2px solid ${theme.palette.green2}`,
  },
});

export default styles;
