import { combineReducers } from 'redux';

import redirectLink from './redirectLink';
import isPending from './isPending';

const reducer = combineReducers({
  redirectLink,
  isPending,
});

export default reducer;
