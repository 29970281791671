import { combineActions, handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = {};

export default handleActions(
  {
    [combineActions(actionCreators.paymentsDepositFinmoSuccess)](state, action) {
      return {
        ...action.response.data,
      };
    },
  },
  defaultState,
);
