export default (theme) => ({
  container: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    marginTop: '-27px',
    '@media (max-width:1024px)': {
      paddingTop: '30px',
    },
  },
  wrapper: {
    backgroundColor: '#ffffff',
  },
  header: {
    maxWidth: 700,
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingTop: 54,
    paddingRight: 30,
    paddingLeft: 30,
  },
  title: {
    color: '#01082B',
    fontSize: 24,
    fontWeight: 300,
  },
  wrapperInfo: {
    backgroundColor: '#F4F6F9',
    padding: '10px 0',
  },
  containerInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    maxWidth: 700,
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingRight: 30,
    paddingLeft: 30,
    flexWrap: 'wrap',

    '& > section': {
      padding: '10px 0',
    },

    '& > section:first-child': {
      paddingRight: 24,
    },
  },
  cell: {
    display: 'flex',
    flexDirection: 'column',
  },
  cellTitle: {
    color: theme.palette.moonstoneBlue,
    fontSize: 12,
    marginBottom: 8,
  },
  accountNickname: {
    color: '#36425A',
    fontSize: 16,
  },
  amountMoney: {
    color: '#36425A',
    fontSize: 18,
    fontWeight: 'bold',
  },
  buttonWrapper: {
    alignSelf: 'center',
    '& > button': {
      paddingLeft: 0,
    },
  },
  stepLinkIcon: {
    width: '20px',
  },
  paymentWrapper: {
    width: '100%',
    backgroundColor: '#ffffff',
    padding: 0,
    paddingBottom: 10,
    border: '1px solid #78D5D3',
    borderRadius: 7,
    margin: '24px 0',
    [theme.breakpoints.down(1280)]: {
      paddingTop: 36,
    },
    [theme.breakpoints.down(768)]: {
      paddingTop: 24,
    },
  },
  iframeWrapper: {
    // paddingTop: '56.25%',
    position: 'relative',
    height: `calc(100vh - 200px)`,
    '@media (max-width:1280px)': {
      height: 'calc(100vh - 160px)',
    },
    '@media (max-width:375px)': {
      height: `calc(100vh - 260px)`,
    },
  },
  displayIframe: {
    '& iframe': {
      border: 0,
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
      opacity: 1,
      transition: 'opacity 0.3s ease-in',
    },
  },
  hideIframe: {
    '& iframe': {
      opacity: 0,
    },
  },
  qrCodeWrapper: {
    paddingTop: 30,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  qrCode: {
    width: 320,
    height: 320,
    padding: 50,
  },
  qrCodeLabel: {
    color: '#01082B',
    fontSize: 23,
    fontWeight: 300,
  },
  qrFinishButton: {
    marginBottom: 20,
  },
});
