import { handleActions } from 'redux-actions';
import omit from 'lodash/omit';

import * as actionCreators from '../actions';
const defaultState = {};

export default handleActions(
  {
    [actionCreators.toggleModal](state, { payload }) {
      return state[payload] ? omit(state, payload) : { ...state, [payload]: true };
    },

    [actionCreators.openModal](state, { payload }) {
      return { ...state, [payload]: true };
    },

    [actionCreators.closeModal](state, { payload }) {
      return omit(state, payload);
    },
  },
  defaultState,
);
