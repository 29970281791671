import React from 'react';
import { Route, Switch } from 'react-router';
import { Routes } from 'constants/routeConstants';
import ConfirmPayment from '../../pages/Deposit/containers/ConfirmPaymentContainer';
import BankTransfer from '../../pages/Deposit/containers/BankTransferPaymentContainer';
import Payments from '../../containers/PaymentsContainer';
import NotFoundPage from 'modules/common/NotFoundPage';
import DepositMonoovaContainer from 'modules/payments/pages/Deposit/containers/DepositMonoovaContainer';

const PaymentsRoutes = ({ prefix }) => {
  return (
    <Switch>
      <Route exact path={prefix + Routes.PAY_ID_PAYMENT} component={DepositMonoovaContainer} />
      <Route exact path={prefix + Routes.BANK_TRANSFER_PAYMENT} component={BankTransfer} />
      <Route exact path={prefix + Routes.CONFIRM_PAYMENT} component={ConfirmPayment} />
      <Route exact path={prefix + '/payments/:token?'} component={Payments} />
      {prefix ? null : <Route component={NotFoundPage} />}
    </Switch>
  );
};
export default PaymentsRoutes;
