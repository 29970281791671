import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import styles from './styles';
import Spinner from '../Spinner';

const SecondaryButton = ({
  classes,
  content,
  label,
  onClick,
  className,
  isPending,
  isWithEmptyBackground = false,
  disabled = false,
  ...data
}) => {
  return (
    <Button
      disabled={disabled}
      style={isWithEmptyBackground ? { background: 'none' } : {}}
      className={className}
      color="primary"
      onClick={onClick}
      disableTouchRipple
      {...data}>
      <div className={classes.buttonContent} {...data}>
        <div
          className={classNames({
            [classes.loader]: true,
            [classes.hidden]: true,
            [classes.visible]: isPending,
          })}>
          <Spinner />
        </div>
        <div
          className={classNames({
            [classes.buttonTextContainer]: true,
            [classes.hidden]: isPending,
          })}>
          {content}
          {label}
        </div>
      </div>
    </Button>
  );
};

SecondaryButton.propTypes = {
  content: PropTypes.object,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  data: PropTypes.object,
  isWithEmptyBackground: PropTypes.bool,
};

export default withStyles(styles)(SecondaryButton);
