const styles = (theme) => ({
  itemWrapper: {
    paddingTop: '0 !important',
  },
  item: {
    paddingTop: 14,
    width: 200,
    paddingRight: 14,
  },
  title: {
    color: theme.palette.simpleGrey,
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 600,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    height: '34px',
    borderRadius: '4px',
    fontSize: '12px',
    fontWeight: '900',
    lineHeight: '15px',
    whiteSpace: 'nowrap',
  },
  button__large: {
    width: '116px',
  },
  button__small: {
    width: '50px',
  },
  formControlLabel: {
    alignItems: 'flex-start',
  },
  checkbox: {
    padding: '2px 12px',
  },
  label: {
    fontSize: 16,
    fontWeight: '300',
    lineHeight: '20px',
  },
  disabled: {},
  checkboxChecked: {},
  icon: {
    fontSize: 19,
  },
});

export default styles;
