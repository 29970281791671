const styles = (theme) => ({
  section: {
    width: '100%',
    display: 'flex',
    marginTop: 100,
    color: theme.palette.text.main,
    '@media (max-width:600px)': {
      flexDirection: 'column-reverse',
      marginTop: 0,
    },
  },
  title: {
    fontSize: '16px',
    fontWeight: 100,
    '@media (max-width:600px)': {
      fontSize: 50,
    },
  },
  contentContainer: {
    display: 'grid',
    gridGap: '50px',
    gridTemplateColumns: 'repeat(auto-fill, 500px)',
    width: '100%',
  },
});

export default styles;
