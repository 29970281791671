import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';

import styles from './styles';

const ResultsRow = ({ classes, className, label, value = '' }) => {
  const { t } = useTranslation('tradingCalculators');

  return (
    <Grid item xs={12} sm="auto" className={classNames(classes.resultsRow, className)}>
      <Typography className={classes.resultsRowLabel}>{t(`results.${label}`)}:</Typography>
      <Typography className={classes.resultsRowValue}>{String(value).toUpperCase()}</Typography>
    </Grid>
  );
};

ResultsRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default withStyles(styles)(ResultsRow);
