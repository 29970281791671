import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PRE_QUIZ_MODAL } from 'modules/profileQuiz/constants';
import { accountPlanSelector } from 'modules/auth/selectors';
import { questionsSelector } from 'modules/profileQuiz/selectors';
import PreQuizModal from 'modules/profileQuiz/components/PreQuizModal';
import { closeModal, openModal } from '../../common/CustomModal/actions';
import { ACCOUNT_PLANS } from '../../../constants/acctountPlans';

const PreQuizModalContainer = () => {
  const dispatch = useDispatch();

  const questions = useSelector(questionsSelector);
  const selectedPlan = useSelector(accountPlanSelector);
  const isProPlan = selectedPlan === ACCOUNT_PLANS.PRO;

  const handleSubmit = useCallback(
    (modalName) => {
      dispatch(closeModal(PRE_QUIZ_MODAL));
      dispatch(openModal(modalName));
    },
    [dispatch],
  );

  const handleCancel = useCallback(() => {
    dispatch(closeModal(PRE_QUIZ_MODAL));
  }, [dispatch]);

  return (
    <PreQuizModal
      isProPlan={isProPlan}
      modalName={PRE_QUIZ_MODAL}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      questions={questions}
    />
  );
};

export default PreQuizModalContainer;
