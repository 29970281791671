const styles = (theme) => ({
  container: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  arrowImage: {
    width: 8,
    height: 8,
    marginLeft: '8px',
    marginRight: '8px',
  },
  lastLinkStyle: {
    color: '#9EA1B9 !important',
    cursor: 'not-allowed',
    pointerEvents: 'none',
    fontSize: '12px',
    lineHeight: '15px',
    fontWeight: 300,
  },
  lastArrowStyle: {
    display: 'none',
  },
  breadCrumbsLink: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: theme.palette.textColor,
  },
});

export default styles;
