import React from 'react';
import { Field, reduxForm } from 'redux-form';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';

import TPGrid from 'components/TP-UI/TPGrid';
import TPRating, { TPRating as Rating } from 'components/TP-UI/TPRating';
import TPTypography from 'components/TP-UI/TPTypography';
import { required } from '../../../utils/validation/fieldValidationRules';

import styles from './styles.js';

const Ratings = () => {
  return (
    <form>
      <TPGrid container spacing={1}>
        <TPGrid item xs={3}>
          <TPTypography variant="h6">Simple rating</TPTypography>
          <Field component={TPRating} name="rating" label="Feedback" />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="h6">Maximum 10</TPTypography>
          <Field component={TPRating} name="feedRating" max={10} />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="h6">Hint</TPTypography>
          <Field component={TPRating} name="rating" hint="Please provide your feedback" />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="h6">Error</TPTypography>
          <Field
            component={TPRating}
            name="rating"
            validate={[required]}
            required
            hint="Additional info"
            meta={{ error: { key: 'common:errors.form.isRequired' }, submitFailed: true }}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="h6">Disabled, hint</TPTypography>
          <Field component={TPRating} name="rating" disabled hint="Please provide your feedback" />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="h6">Readonly, hint</TPTypography>
          <Field component={TPRating} name="rating" readonly hint="Your feedback" />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="h6">Small size, hint</TPTypography>
          <Field
            component={TPRating}
            name="rating"
            size="small"
            hint="Please provide your feedback"
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="h6">Large size, hint</TPTypography>
          <Field
            component={TPRating}
            name="rating"
            size="large"
            hint="Please provide your feedback"
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="h6">Custom icon</TPTypography>
          <Field
            component={TPRating}
            name="rating"
            size="large"
            icon={SentimentSatisfiedIcon}
            hint="Please provide your feedback"
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="h6">Custom color</TPTypography>
          <Field
            component={TPRating}
            name="rating"
            icon={SentimentSatisfiedIcon}
            hint="Please provide your feedback"
            classes={styles}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="h6">Not redux, readonly</TPTypography>
          <Rating value={3} readonly hint="Your feedback" />
        </TPGrid>
      </TPGrid>
    </form>
  );
};

export default reduxForm({
  form: 'TP-RATINGS-FORM',
  initialValues: {
    rating: 0,
    feedRating: 2,
  },
})(Ratings);
