import { handleActions } from 'redux-actions';
import {
  migrateUserSuccess,
  migrateUserFail,
  migrateUserRequest,
  requestMigrateUserSuccess,
  requestMigrateUserFail,
  requestMigrateUserRequest,
  migrateUserConfirm,
  migrateUserConfirmClose,
  confirmSuccessClose,
} from '../actions';

const defaultState = {
  isLoading: false,
  isOpened: false,
  isRedirect: false,
};

export default handleActions(
  {
    [migrateUserRequest]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [migrateUserFail]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [migrateUserSuccess]: (state) => ({
      ...state,
      isOpened: false,
      isRedirect: true,
      isLoading: false,
    }),
    [requestMigrateUserRequest]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [requestMigrateUserFail]: (state) => ({
      ...state,
      isOpened: false,
      isLoading: false,
    }),
    [requestMigrateUserSuccess]: (state) => ({
      ...state,
      isOpened: false,
      isLoading: false,
    }),
    [migrateUserConfirm]: (state) => ({
      ...state,
      isOpened: true,
    }),
    [migrateUserConfirmClose]: (state) => ({
      ...state,
      isOpened: false,
    }),
    [confirmSuccessClose]: (state) => ({
      ...state,
      isOpened: false,
      isRedirect: false,
    }),
  },
  defaultState,
);
