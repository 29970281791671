import { Grid, Typography } from '@material-ui/core';
import noMatchesFound from 'static/no_matches_found.svg';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import Spinner from '../../../common/Spinner';

function ComparisonDataItem({ classes, diff, income }) {
  if (!diff || !income) {
    return null;
  }

  const isDiffPositive = !diff.startsWith('-');
  const isIncomePositive = !income.startsWith('-');

  return (
    <Typography className={classes.dataItemComparison}>
      {`${isDiffPositive ? '+' : ''}${diff}`} (
      <span
        className={
          isIncomePositive ? classes.dataItemIncomePositive : classes.dataItemIncomeNegative
        }>
        {`${isIncomePositive ? '+' : ''}${income}`}
      </span>
      )
    </Typography>
  );
}

function NoData({ classes, message = 'No Data Available' }) {
  return (
    <Grid container className={classes.noDataWrapper} justify="center" alignItems="center">
      <img src={noMatchesFound} alt="no-details-yet" />
      <div className={classes.text}>{message}</div>
    </Grid>
  );
}

function OverviewDataItem({ label, value, classes, income, diff }) {
  return (
    <Grid item xs={2} className={classes.dataItem}>
      <Grid container direction="column">
        <Grid item>
          <Typography className={classes.dataItemLabel}>{label}</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.dataItemValue}>{value}</Typography>
        </Grid>
        <Grid item>
          <ComparisonDataItem classes={classes} diff={diff} income={income} />
        </Grid>
      </Grid>
    </Grid>
  );
}

function OverviewData({ data, isLoading, classes }) {
  if (isLoading || !data) {
    return (
      <Grid container justify="center" alignContent="center" className={classes.dataWrapper}>
        <Spinner />
      </Grid>
    );
  }

  const isData = data.find(({ numValue, value }) => {
    const isZeroPercent = value.includes('%') ? value !== '0.00%' : false;
    return numValue > 0 || isZeroPercent;
  });

  return isData ? (
    <Grid container className={classes.dataWrapper}>
      {data.map(({ value, label, diff, income }) => (
        <OverviewDataItem
          key={label}
          value={value}
          label={label}
          classes={classes}
          income={income}
          diff={diff}
        />
      ))}
    </Grid>
  ) : (
    <NoData classes={classes} />
  );
}

export default withStyles(styles)(OverviewData);
