import { createAction } from 'redux-actions';

export const getDashboardStatsCustomizationDataRequest = createAction(
  'GET_DASHBOARD_STATS_CUSTOMIZATION_DATA_REQUEST',
);
export const getDashboardStatsCustomizationDataSuccess = createAction(
  'GET_DASHBOARD_STATS_CUSTOMIZATION_DATA_SUCCESS',
);
export const getDashboardStatsCustomizationDataFail = createAction(
  'GET_DASHBOARD_STATS_CUSTOMIZATION_DATA_FAIL',
);

export const saveDashboardStatsCustomizationDataRequest = createAction(
  'SAVE_DASHBOARD_STATS_CUSTOMIZATION_DATA_REQUEST',
);
export const saveDashboardStatsCustomizationDataSuccess = createAction(
  'SAVE_DASHBOARD_STATS_CUSTOMIZATION_DATA_SUCCESS',
);
export const saveDashboardStatsCustomizationDataFail = createAction(
  'SAVE_DASHBOARD_STATS_CUSTOMIZATION_DATA_FAIL',
);

export const handleHeaderFormSubmit = createAction('HANDLE_HEADER_FORM_SUBMIT');

export const getDashboardOverviewDataRequest = createAction('GET_DASHBOARD_OVERVIEW_DATA_REQUEST');
export const getDashboardOverviewDataSuccess = createAction('GET_DASHBOARD_OVERVIEW_DATA_SUCCESS');
export const getDashboardOverviewDataFail = createAction('GET_DASHBOARD_OVERVIEW_DATA_FAIL');

export const getDashboardGraphDataRequest = createAction('GET_DASHBOARD_GRAPH_DATA_REQUEST');
export const getDashboardGraphDataSuccess = createAction('GET_DASHBOARD_GRAPH_DATA_SUCCESS');
export const getDashboardGraphDataFail = createAction('GET_DASHBOARD_GRAPH_DATA_FAIL');

export const getDashboardComparisonGraphDataRequest = createAction(
  'GET_DASHBOARD_COMPARISON_GRAPH_DATA_REQUEST',
);
export const getDashboardComparisonGraphDataSuccess = createAction(
  'GET_DASHBOARD_COMPARISON_GRAPH_DATA_SUCCESS',
);
export const getDashboardComparisonGraphDataFail = createAction(
  'GET_DASHBOARD_COMPARISON_GRAPH_DATA_FAIL',
);

export const getActiveUsersGraphDataRequest = createAction('GET_ACTIVE_USERS_GRAPH_DATA_REQUEST');
export const getActiveUsersGraphDataSuccess = createAction('GET_ACTIVE_USERS_GRAPH_DATA_SUCCESS');
export const getActiveUsersGraphDataFail = createAction('GET_ACTIVE_USERS_GRAPH_DATA_FAIL');

export const getCountryUsersRequest = createAction('GET_COUNTRY_USERS_REQUEST');
export const getCountryUsersSuccess = createAction('GET_COUNTRY_USERS_SUCCESS');
export const getCountryUsersFail = createAction('GET_COUNTRY_USERS_FAIL');

export const getCountryUsersCompareRequest = createAction('GET_COUNTRY_USERS_COMPARE_REQUEST');
export const getCountryUsersCompareSuccess = createAction('GET_COUNTRY_USERS_COMPARE_SUCCESS');
export const getCountryUsersCompareFail = createAction('GET_COUNTRY_USERS_COMPARE_FAIL');

export const getCountryUsersCompareExport = createAction('GET_COUNTRY_USERS_COMPARE_EXPORT');

export const getCountryUsersCompareExportRequest = createAction(
  'GET_COUNTRY_USERS_COMPARE_EXPORT_REQUEST',
);
export const getCountryUsersCompareExportSuccess = createAction(
  'GET_COUNTRY_USERS_COMPARE_EXPORT_SUCCESS',
);
export const getCountryUsersCompareExportFail = createAction(
  'GET_COUNTRY_USERS_COMPARE_EXPORT_FAIL',
);

export const getTopUserVolumesRequest = createAction('GET_TOP_USER_VOLUMES_REQUEST');
export const getTopUserVolumesSuccess = createAction('GET_TOP_USER_VOLUMES_SUCCESS');
export const getTopUserVolumesFail = createAction('GET_TOP_USER_VOLUMES_FAIL');

export const getMarketingChannelDataRequest = createAction('GET_MARKETING_CHANNEL_DATA_REQUEST');
export const getMarketingChannelDataSuccess = createAction('GET_MARKETING_CHANNEL_DATA_SUCCESS');
export const getMarketingChannelDataFail = createAction('GET_MARKETING_CHANNEL_DATA_FAIL');

export const getMarketingChannelSourcesRequest = createAction(
  'GET_MARKETING_CHANNEL_SOURCES_REQUEST',
);
export const getMarketingChannelSourcesSuccess = createAction(
  'GET_MARKETING_CHANNEL_SOURCES_SUCCESS',
);
export const getMarketingChannelSourcesFail = createAction('GET_MARKETING_CHANNEL_SOURCES_FAIL');

export const getMarketingExportRequest = createAction('GET_MARKETING_EXPORT_REQUEST');
export const getMarketingExportSuccess = createAction('GET_MARKETING_EXPORT_SUCCESS');
export const getMarketingExportFail = createAction('GET_MARKETING_EXPORT_FAIL');

export const openMarketingExportModal = createAction('OPEN_MARKETING_EXPORT_MODAL');
export const closeMarketingExportModal = createAction('CLOSE_MARKETING_EXPORT_MODAL');

export const openCountryFilterModel = createAction('OPEN_COUNTRY_FILTER_MODEL');
export const closeCountryFilterModel = createAction('CLOSE_COUNTRY_FILTER_MODEL');

export const getNetDepositDataRequest = createAction('GET_NET_DEPOSIT_DATA_REQUEST');
export const getNetDepositDataSuccess = createAction('GET_NET_DEPOSIT_DATA_SUCCESS');
export const getNetDepositDataFail = createAction('GET_NET_DEPOSIT_DATA_FAIL');

export const getLeadOwnersRequest = createAction('GET_LEAD_OWNERS_REQUEST');
export const getLeadOwnersSuccess = createAction('GET_LEAD_OWNERS_SUCCESS');
export const getLeadOwnersFail = createAction('GET_LEAD_OWNERS_FAIL');
