import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, reset } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import Box from 'material-latest/Box';
import { useTranslation } from 'react-i18next';

import { TPModal } from 'components/TP-UI/TPModal';
import TPCheckbox from 'components/TP-UI/TPCheckbox';

import styles from './styles';

const TPColumnsModal = ({ visible, title, columns = [], form, handleSubmit, onClose }) => {
  const displayedColumns = useMemo(() => (columns ? columns.filter((c) => c.label) : []), [
    columns,
  ]);
  const columnCount = displayedColumns.length > 10 ? 2 : 1;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmitForm = useCallback(
    (e) => {
      //prevent submitting filters form
      e.preventDefault();
      handleSubmit();
    },
    [handleSubmit],
  );

  const handleCancel = useCallback(() => {
    dispatch(reset(form));
    if (onClose) {
      onClose();
    }
  }, [dispatch, form, onClose]);

  const buttons = useMemo(
    () => [
      { label: t('common:buttons.save'), primary: true, type: 'submit', onClick: handleSubmitForm },
      { label: t('common:buttons.cancel'), secondary: true, onClick: handleCancel },
    ],
    [t, handleCancel, handleSubmitForm],
  );

  return (
    <TPModal
      value={visible}
      title={title}
      buttons={buttons}
      form={form}
      onClose={handleCancel}
      onSubmit={handleSubmitForm}
      className={{
        width: columnCount === 1 ? '400px' : '600px',
      }}>
      <Box sx={[styles.columnsContainer, { columnCount }]}>
        {displayedColumns.map(({ label }, index) => (
          <Field
            component={TPCheckbox}
            name={label}
            label={label}
            key={label + index}
            reservedErrorSpace={false}
          />
        ))}
      </Box>
    </TPModal>
  );
};

TPColumnsModal.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Column title
       */
      label: PropTypes.node,
      /**
       * Displayed by default
       */
      default: PropTypes.bool,
      /**
       * Current visibility state
       */
      visible: PropTypes.bool,
    }),
  ),
  form: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

let TPColumnsReduxModal = reduxForm({ destroyOnUnmount: true, enableReinitialize: true })(
  TPColumnsModal,
);

TPColumnsReduxModal = connect((_, ownProps) => {
  return {
    initialValues: ownProps.columns
      ? ownProps.columns.reduce((obj, c) => {
          obj[c.label] = c.visible;
          return obj;
        }, {})
      : {},
  };
})(TPColumnsReduxModal);

export default TPColumnsReduxModal;
