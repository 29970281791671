export default (amount, currency = 'USD') => {
  if (!amount) {
    amount = 0;
  }
  let formattedAmount;
  if (Intl) {
    const formatter = new Intl.NumberFormat('en-US', {
      ...(currency != '' && { currency, style: 'currency' }),
      minimumFractionDigits: 0,
    });
    formattedAmount = formatter.format(amount).replace(/^(\D+)/, '$1 ');
  } else {
    formattedAmount = Number(amount)
      .toLocaleString('en-US', {
        ...(currency != '' && { currency, style: 'currency' }),
      })
      .replace(/^(\D+)/, '$1 ');
  }
  return formattedAmount;
};
