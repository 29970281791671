const styles = (theme) => ({
  invisibleScroll: {
    display: 'none',
  },
  itemsContainer: {
    width: '100%',
    borderTop: theme.containerBorder.border,
  },
  titleContainer: {
    marginTop: 12,
    borderTop: '1px solid rgba(192, 248, 38, 0.3)',
    borderRight: '1px solid rgba(192, 248, 38, 0.3)',
    borderLeft: '1px solid rgba(192, 248, 38, 0.3)',
    borderBottom: 0,
  },
  verificationTitleContainer: {
    marginTop: 0,
    borderTop: 0,
  },
  verificationItemsContainer: {
    borderTop: theme.containerBorder.border,
    border: 'none',
    margin: 0,
  },
});

export default styles;
