import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import CustomModal from 'modules/common/CustomModal';
import PrimaryButton from 'modules/common/PrimaryButton';
import styles from './styles';

const RejectionModal = ({ classes, modalName, closeModal }) => {
  const { t } = useTranslation('accounts');
  return (
    <CustomModal modalName={modalName} className={classes.rejectionModal}>
      <section className={classes.sectionInfo}>
        <Typography className={classes.title}>{t('rejectionModal.title')}</Typography>
        <Typography className={classes.contentHeader}>{t('rejectionModal.welcome')}</Typography>
        <Typography className={classes.contentMessage}>{t('rejectionModal.content')}</Typography>
      </section>
      <section className={classes.sectionButtons}>
        <PrimaryButton
          color="primary"
          size="large"
          label={t('common:buttons.gotIt')}
          className={classes.button}
          onClick={closeModal}
        />
      </section>
    </CustomModal>
  );
};

RejectionModal.propTypes = {
  modalName: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default withStyles(styles)(RejectionModal);
