import { handleActions } from 'redux-actions';
import * as actions from '../actions';

const defaultState = {
  data: [],
};

export default handleActions(
  {
    [actions.getSecurityLogsSuccess]: (state, action) => {
      return { ...state, data: action.response.data.securityLogs };
    },
  },
  defaultState,
);
