import { combineReducers } from 'redux';

import countryUsers from './countryUsers';
import volumeUsers from './userVolumes';
import dashboardOverview from './dashboardOverview';
import marketingChannelData from './marketingChannelData';
import netDeposit from './netDeposit';
import leadOwners from './leadOwners';

const reducer = combineReducers({
  marketingChannelData,
  countryUsers,
  volumeUsers,
  dashboardOverview,
  netDeposit,
  leadOwners,
});

export default reducer;
