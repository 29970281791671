import React, { useCallback, useMemo } from 'react';
import { withStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { Routes } from '../../../../constants/routeConstants';
import { useTranslation } from 'react-i18next';
import verificationModalNames from '../../constants/verificationModalNames';
import { closeModal } from '../../../common/CustomModal/actions';
import ImageSubtitleTextContent from '../../../common/ContentModal/ImageSubtitleTextContent';
import quizFailureImage from '../../../../static/quizzes/quiz-failure.svg';
import CustomModal from '../../../common/CustomModal';

import styles from './styles';

const UpgradeQuizBlockedModal = ({ classes }) => {
  const { t } = useTranslation('quiz');
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(push(Routes.MY_ACCOUNTS));
    dispatch(closeModal(verificationModalNames.UPGRADE_BLOCKED_MODAL));
  }, [dispatch]);

  const buttons = useMemo(
    () => [
      {
        label: t('common:buttons.gotIt'),
        color: 'primary',
        onClick: handleClose,
      },
    ],
    [t, handleClose],
  );

  return (
    <CustomModal
      modalClassNames={classes.modal}
      className={classes.modalPaper}
      modalName={verificationModalNames.UPGRADE_BLOCKED_MODAL}
      title={t('pro.page.title')}
      isCloseButtonNeeded
      customCloseModal={handleClose}
      buttons={buttons}>
      <ImageSubtitleTextContent
        image={quizFailureImage}
        text={t('upgradeQuizBlockedModal.description')}
        subtitle={t('upgradeQuizBlockedModal.title')}
      />
    </CustomModal>
  );
};

export default withStyles(styles)(UpgradeQuizBlockedModal);
