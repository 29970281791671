import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Typography } from '@material-ui/core';

const TableTextAndCountField = ({
  classes,
  label,
  dataLabel,
  tableItem,
  formatFunc,
  className,
  showLabel = false,
  isHeadRow,
}) => {
  if (showLabel && isHeadRow) {
    return (
      <div className={classNames(classes.item, className)}>
        <Typography className={classes.label}>{label}</Typography>
      </div>
    );
  }

  return (
    <div className={classNames(classes.item, className)}>
      <Typography className={classes.dataText}>
        {formatFunc ? formatFunc(get(tableItem, dataLabel, '')) : get(tableItem, dataLabel, '')}
      </Typography>
    </div>
  );
};

TableTextAndCountField.propTypes = {
  showLabel: PropTypes.bool,
  label: PropTypes.string.isRequired,
  dataLabel: PropTypes.string.isRequired,
  tableItem: PropTypes.object.isRequired,
  formatFunc: PropTypes.func,
  className: PropTypes.string,
};

export default withStyles(styles)(TableTextAndCountField);
