import { combineReducers } from 'redux';

import emailTemplates from '../pages/EmailTemplates/reducers';
import emailHistory from '../pages/EmailHistory/reducers';

const reducer = combineReducers({
  emailTemplates,
  emailHistory,
});

export default reducer;
