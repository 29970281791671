import { combineReducers } from 'redux';

import paymentOptions from './paymentOptions';
import history from './history';
import hyperwallet from './hyperwallet';
import bankDetails from './bankDetails';
import localBank from './localBank';
import finrax from './finrax';

const withdraw = combineReducers({
  paymentOptions,
  history,
  hyperwallet,
  bankDetails,
  localBank,
  finrax,
});

export default withdraw;
