import { handleActions } from 'redux-actions';
import get from 'lodash/get';

import {
  currencyCalculatorSuccess,
  pipValueCalculatorSuccess,
  marginCalculatorSuccess,
  swapsCalculatorSuccess,
  profitLossCalculatorSuccess,
  clearResults,
} from '../actions';

const defaultState = {
  symbolConfig: {
    symbols: [],
  },
  currencyCalculator: {},
  pipValue: {
    results: {},
    meta: {},
  },
  margin: {
    results: {},
    meta: {},
  },
  swaps: {
    results: {},
    meta: {},
  },
  profitLoss: {
    results: {},
    meta: {},
  },
};

export default handleActions(
  {
    [currencyCalculatorSuccess]: (state, action) => ({
      ...state,
      currencyCalculator: get(action, 'response.data', {}),
    }),
    [pipValueCalculatorSuccess]: (state, action) => ({
      ...state,
      pipValue: {
        results: get(action, 'response.data.results', {}),
        meta: get(action, 'response.data.meta', {}),
      },
    }),
    [marginCalculatorSuccess]: (state, action) => ({
      ...state,
      margin: {
        results: get(action, 'response.data.results', {}),
        meta: get(action, 'response.data.meta', {}),
      },
    }),
    [swapsCalculatorSuccess]: (state, action) => ({
      ...state,
      swaps: {
        results: get(action, 'response.data.results', {}),
        meta: get(action, 'response.data.meta', {}),
      },
    }),
    [profitLossCalculatorSuccess]: (state, action) => ({
      ...state,
      profitLoss: {
        results: get(action, 'response.data.results', {}),
        meta: get(action, 'response.data.meta', {}),
      },
    }),
    [clearResults]: () => defaultState,
  },
  defaultState,
);
