import { combineActions, handleActions } from 'redux-actions';

import * as mfaActionCreators from '../pages/MfaInput/actions';
const defaultState = null;

export default handleActions(
  {
    [combineActions(mfaActionCreators.validateMfaSuccess)](state, action) {
      return action.response.data.hideMfaToken || null;
    },
  },
  defaultState,
);
