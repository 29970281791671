export default (theme) => ({
  root: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
  },
  modalContainer: {
    width: '310px',
    '@media (min-width:480px)': {
      width: '450px',
    },
    '@media (min-width:680px)': {
      width: '520px',
    },
    paddingLeft: '40px',
    '@media (min-width:768px)': {
      paddingLeft: '57px',
    },
  },
  textField: {
    marginTop: 16,
  },
  title: {
    color: theme.palette.text.main,
    fontSize: '28px',
    fontWeight: '300',
    lineHeight: '34px',
    marginBottom: '13px',
  },
  sectionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    padding: '10px 20px',
    width: '100%',
    fontSize: 12,
    lineHeight: 12,
    fontWeight: 400,
    color: theme.palette.text.main,
  },
  logo: {
    height: 18,
    width: 18,
    marginRight: 18,
  },
});
