import { createAction } from 'redux-actions';

export const paymentsDepositIsignthisRequest = createAction('PAYMENTS_DEPOSIT_ISIGNTHIS_REQUEST');
export const paymentsDepositIsignthisSuccess = createAction('PAYMENTS_DEPOSIT_ISIGNTHIS_SUCCESS');
export const paymentsDepositIsignthisFail = createAction('PAYMENTS_DEPOSIT_ISIGNTHIS_FAIL');

export const paymentsDepositSmartwayRequest = createAction('PAYMENTS_DEPOSIT_SMARTWAY_REQUEST');
export const paymentsDepositSmartwaySuccess = createAction('PAYMENTS_DEPOSIT_SMARTWAY_SUCCESS');
export const paymentsDepositSmartwayFail = createAction('PAYMENTS_DEPOSIT_SMARTWAY_FAIL');

export const paymentsDepositPaymentAsiaRequest = createAction(
  'PAYMENTS_DEPOSIT_PAYMENT_ASIA_REQUEST',
);
export const paymentsDepositPaymentAsiaSuccess = createAction(
  'PAYMENTS_DEPOSIT_PAYMENT_ASIA_SUCCESS',
);
export const paymentsDepositPaymentAsiaFail = createAction('PAYMENTS_DEPOSIT_PAYMENT_ASIA_FAIL');

export const getBankInfoRequest = createAction('GET_BANK_INFO_REQUEST');
export const getBankInfoSuccess = createAction('GET_BANK_INFO_SUCCESS');
export const getBankInfoFail = createAction('GET_BANK_INFO_FAIL');

export const searchDepositHistoryRequest = createAction('SEARCH_DEPOSIT_HISTORY_REQUEST');
export const searchDepositHistorySuccess = createAction('SEARCH_DEPOSIT_HISTORY_SUCCESS');
export const searchDepositHistoryFail = createAction('SEARCH_DEPOSIT_HISTORY_FAIL');

export const confirmPaymentInit = createAction('CONFIRM_PAYMENT_INIT');

export const preselectAccount = createAction('PAYMENT_PRESELECT_ACCOUNT');

export const copyBankwireInfo = createAction('COPY_BANK_WIRE_INFO');

export const getConversionRateDepositRequest = createAction('GET_CONVERSION_RATE_DEPOSIT_REQUEST');
export const getConversionRateDepositSuccess = createAction('GET_CONVERSION_RATE_DEPOSIT_SUCCESS');
export const getConversionRateDepositFail = createAction('GET_CONVERSION_RATE_DEPOSIT_FAIL');

export const paymentsDepositScbRequest = createAction('PAYMENTS_DEPOSIT_SCB_REQUEST');
export const paymentsDepositScbSuccess = createAction('PAYMENTS_DEPOSIT_SCB_SUCCESS');
export const paymentsDepositScbFail = createAction('PAYMENTS_DEPOSIT_SCB_FAIL');

export const paymentsDepositPraxisRequest = createAction('PAYMENTS_DEPOSIT_PRAXIS_REQUEST');
export const paymentsDepositPraxisSuccess = createAction('PAYMENTS_DEPOSIT_PRAXIS_SUCCESS');
export const paymentsDepositPraxisFail = createAction('PAYMENTS_DEPOSIT_PRAXIS_FAIL');

export const paymentsDepositFinmo = createAction('PAYMENTS_DEPOSIT_FINMO');

export const paymentsDepositFinmoRequest = createAction('PAYMENTS_DEPOSIT_FINMO_REQUEST');
export const paymentsDepositFinmoSuccess = createAction('PAYMENTS_DEPOSIT_FINMO_SUCCESS');
export const paymentsDepositFinmoFail = createAction('PAYMENTS_DEPOSIT_FINMO_FAIL');

export const paymentsDepositMonoova = createAction('PAYMENTS_DEPOSIT_MONOOVA');
export const paymentsDepositMonoovaRequest = createAction('PAYMENTS_DEPOSIT_MONOOVA_REQUEST');
export const paymentsDepositMonoovaSuccess = createAction('PAYMENTS_DEPOSIT_MONOOVA_SUCCESS');
export const paymentsDepositMonoovaFail = createAction('PAYMENTS_DEPOSIT_MONOOVA_FAIL');
