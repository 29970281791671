import React from 'react';
const re = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim;

const replaceLink = ({ className, text }) => {
  const parts = text.split(re);
  const links = text.match(re);

  const res = [];
  for (let i = 0; i < parts.length; i++) {
    res.push(parts[i]);
    if (links && links[i]) {
      res.push(
        <a href={links[i]} target="_blank" rel="noopener noreferrer" className={className}>
          {links[i]}
        </a>,
      );
    }
  }

  return res;
};

export default replaceLink;
