import { handleActions } from 'redux-actions';

import * as actions from '../actions';
import { menuTabChanged } from 'modules/menu/actions';

const defaultState = 1;

export default handleActions(
  {
    [actions.setCurrentPage](state, action) {
      return action.payload;
    },
    [actions.resetPagination]() {
      return defaultState;
    },
    [menuTabChanged]() {
      return defaultState;
    },
  },
  defaultState,
);
