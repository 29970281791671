import { handleActions } from 'redux-actions';

import { showCaptchaToken } from '../actions';

const defaultState = true;

export default handleActions(
  {
    [showCaptchaToken](_state, { payload }) {
      return !!payload;
    },
  },
  defaultState,
);
