import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';
import noMatchesFound from 'static/no_matches_found.svg';

const TableMessage = ({ classes, message, isDataFiltered }) => {
  return (
    <div className={classes.container}>
      {isDataFiltered && (
        <React.Fragment>
          <div className={classes.noMatchesImage}>
            <img src={noMatchesFound} alt="no-matches-found" />
          </div>
          <div className={classes.text}>No matches found</div>
        </React.Fragment>
      )}
      {!isDataFiltered && (
        <React.Fragment>
          <div className={classes.noDataImage}>
            <img src={noMatchesFound} alt="zero-accounts" />
          </div>
          <div className={classes.text}>{message}</div>
        </React.Fragment>
      )}
    </div>
  );
};

TableMessage.propTypes = {
  message: PropTypes.string.isRequired,
  isDataFiltered: PropTypes.bool.isRequired,
};

export default withStyles(styles)(TableMessage);
