import { FILE_TYPES_FROM_MIME } from '../constants/fileTypes';

const formatFileTypes = (types) => {
  return (
    types
      ?.split(',')
      .map((type) => FILE_TYPES_FROM_MIME[type]?.toLowerCase() || '')
      .filter((type) => type)
      .join(', ') || ''
  );
};

export const formatFileTypes2 = (types) => {
  return types?.map((type) => `${type.toLowerCase()}`).join(', ') || '';
};

export default formatFileTypes;
