import logoutIcon from 'static/log_out_up.svg';
import logoutIconFocused from 'static/log_out_down.svg';
import logoutIconHovered from 'static/log_out_over.svg';
import { mobileNaivgationHeight } from '../BottomNavigation/styles';

export default (theme) => ({
  root: {
    height: `calc(100% - ${mobileNaivgationHeight}px)`,
    boxSizing: 'border-box',
  },

  logoContainer: {
    paddingLeft: '16px',
    paddingRight: '15px',
    paddingBottom: '16px',
  },
  userContainer: {
    paddingLeft: '16px',
    paddingRight: '15px',
    marginTop: '45px',
    '@media (max-width:768px)': {
      marginTop: '30px',
    },
  },
  progressContainer: {
    marginTop: '15px',
    paddingLeft: '16px',
    paddingRight: '15px',
    minHeight: '36px',
  },
  authButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  signUpButton: {
    textTransform: 'uppercase',
    fontWeight: 700,
    marginRight: 10,
  },
  signInButton: {
    padding: 12,
  },
  menuContainer: {
    flex: 1,
    overflowY: 'auto',
  },
  infoContainer: {
    paddingTop: '20px',
  },
  emptySpace: {
    height: '80px',
  },
  logoutButtonContainer: {
    paddingTop: '10px',
  },
  logoutButton: {
    width: '20px',
    height: '24px',
    backgroundImage: `url("${logoutIcon}")`,
    '&:hover': {
      backgroundImage: `url("${logoutIconHovered}")`,
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundImage: `url("${logoutIconFocused}")`,
    },
  },
  languageContainer: {
    marginRight: '14px',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  languagePostfix: {
    color: theme.palette.white,
    paddingLeft: 3,
    opacity: 0.6,
    fontWeight: 600,
    fontSize: 12,
  },

  selected: {
    fontSize: 'inherit',
  },
  label: {
    color: theme.palette.black,
    fontSize: '16px !important',
  },
  icon: {
    color: '#DAE5FF',
    height: 12,
  },
  expandIcon: {
    color: '#DAE5FF',
    fontSize: theme.typography.pxToRem(16),
    height: 20,
  },
  backdrop: {
    backgroundColor: 'rgba(0,0,0,0.8)',
  },

  menuItem: {
    fontSize: theme.typography.pxToRem(16),
    color: '#DAE5FF',
  },
  menuItemList: {
    '&:hover': {
      backgroundColor: '#0A1939',
    },
  },
  linkContainer: {
    textDecoration: 'none',
    '&:focus, &:hover, &:visited, &:link, &:active': {
      textDecoration: 'none',
    },
  },
  closeIcon: {
    color: '#92AADC',
  },
  subMenuRoot: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  circlesWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    height: '100%',
  },
});
