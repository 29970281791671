import { palette } from '../palette';
import { fontSize } from '../../font';

const OverridesMuiChip = {
  styleOverrides: {
    root: {
      color: palette.text.dark,
      fontSize: fontSize._16,
      paddingRight: '7px',
      borderColor: palette.dividerCustom.dark,
      '&:hover': {
        borderColor: palette.dividerCustom.main,
      },
      '&.Mui-disabled': {
        borderColor: palette.dividerCustom.lightest,
        color: palette.textSecondary.lightest,
        opacity: 1,
        '& .MuiSvgIcon-root': {
          color: palette.dividerCustom.lightest,
        },
      },
    },
    label: {
      fontSize: 'inherit',
    },
    deleteIcon: {
      fontSize: fontSize._16,
      color: palette.icon.dark,
      margin: 0,
      padding: 0,
      '&:hover': {
        color: palette.text.main,
      },
    },
    sizeSmall: {
      fontSize: fontSize._14,
      paddingRight: '5px',
      label: {
        paddingRight: '4px',
      },
    },
    labelSmall: {
      paddingRight: '4px',
    },
  },
};

export default OverridesMuiChip;
