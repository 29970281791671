import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { store } from 'components/App';
import { withStyles } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MainLayout from 'modules/common/MainLayout';
import DocumentTitle from 'react-document-title';
import * as apiSelectors from 'modules/api/selectors';
import * as actions from '../actions';
import * as selectors from '../selectors';
import * as authSelectors from 'modules/auth/selectors';
import styles from './styles';
import NotAllowed from '../components/NotAllowed';

const Trades = lazy(() => import('../components/Trades'));
const PAGE_SIZE = 50;

const TradesContainer = ({ classes }) => {
  const { t } = useTranslation('myFollowers');
  const dispatch = useDispatch();
  const openedTrades = useSelector(selectors.getOpenedTrades);
  const closedTrades = useSelector(selectors.getClosedTrades);
  const errorTrades = useSelector(selectors.getErrorTrades);
  const closedTradesCount = useSelector(selectors.getClosedTradesCount);
  const [isSagaLoading, setSagaLoading] = useState(true);
  const [page, setPage] = useState(-1);
  const allowCopyAccount = useSelector(authSelectors.allowCopyAccount);
  const hub = useSelector(authSelectors.getUserHub);
  const isOpenedPending = useSelector(
    apiSelectors.createPendingSelector(actions.myFollowersTradesOpenedRequest),
  );
  const isClosedPending = useSelector(
    apiSelectors.createPendingSelector(actions.myFollowersTradesClosedRequest),
  );
  const isErrorPending = useSelector(
    apiSelectors.createPendingSelector(actions.myFollowersTradesErrorRequest),
  );

  useEffect(() => {
    import('../sagas/trades').then((saga) => {
      store.injectSaga('MyFollowersTrades', saga.default);
      setSagaLoading(false);
    });
  }, [dispatch]);

  const getClosedTradesPage = useCallback((page) => {
    setPage(page);
  }, []);

  useEffect(() => {
    if (isSagaLoading) {
      return;
    }
    if (!hub || !allowCopyAccount) {
      return;
    }
    dispatch(actions.myFollowersTradesOpenedRequest());
  }, [dispatch, isSagaLoading, allowCopyAccount, hub]);

  const getErrorTrades = useCallback(() => {
    dispatch(actions.myFollowersTradesErrorRequest());
  }, [dispatch]);

  useEffect(() => {
    if (page < 0) {
      return;
    }
    dispatch(actions.myFollowersTradesClosedRequest({ skip: page * PAGE_SIZE, limit: PAGE_SIZE }));
  }, [dispatch, page]);

  if (hub && !allowCopyAccount) {
    return <NotAllowed />;
  }

  return (
    <MainLayout title={t('trades.title')}>
      <DocumentTitle title={t('trades.title')} />
      <Suspense>
        <>
          <div className={classes.description}>{t('myFollowers:trades.description')}</div>

          <Trades
            openedTrades={openedTrades}
            closedTrades={closedTrades}
            errorTrades={errorTrades}
            closedTradesCount={closedTradesCount}
            getErrorTrades={getErrorTrades}
            isOpenedPending={isOpenedPending}
            isClosedPending={isClosedPending}
            isErrorPending={isErrorPending}
            getClosedTradesPage={getClosedTradesPage}
          />
        </>
      </Suspense>
    </MainLayout>
  );
};

export default withStyles(styles)(withRouter(TradesContainer));
