import * as actionCreators from '../actions';
import { handleActions } from 'redux-actions';
import * as authActions from 'modules/auth/actions';

const defaultState = [];

export default handleActions(
  {
    [actionCreators.addNotification](state, action) {
      return [...state, action.payload];
    },
    [actionCreators.removeNotification](state, action) {
      return state.filter((e) => e.id !== action.payload);
    },
    [authActions.logout]() {
      return [];
    },
  },
  defaultState,
);
