import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import TextInput from 'modules/common/TextInput';
import styles from './styles';

const GoToPageFields = ({ classes }) => {
  return (
    <>
      <div className={classes.jumperLabel}>Go to page</div>
      <Field
        placeholder={1}
        name="pageNumber"
        component={TextInput}
        className={classes.pageNumberField}
        inputClassName={classes.input}
        wrapperClassName={classes.textFieldWrapper}
        rootClassName={classes.pageNumberField}
      />
      <button className={classes.jumpButton}>Go</button>
    </>
  );
};

GoToPageFields.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GoToPageFields);
