import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as apiSelectors from 'modules/api/selectors';
import Loader from 'modules/common/LoaderFullPage';
import { clearResults, symbolConfigRequest } from '../actions';
import TradingCalculators from '../components/TradingCalculators';
import * as selectors from '../selectors';

import { store } from 'components/App';

const TradingCalculatorsContainer = ({ isPublic }) => {
  useEffect(() => {
    import('../sagas').then((saga) => {
      store.injectSaga('TradingCalculators', saga.default);
    });
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(symbolConfigRequest());
  }, [dispatch]);

  useSelector(selectors.getSymbolConfig);
  const symbols = useSelector(selectors.getSymbolConfig);

  const isSymbolConfigLoading = useSelector(
    apiSelectors.createPendingSelector(symbolConfigRequest),
  );

  const handleNavTabChange = useCallback(() => dispatch(clearResults()), [dispatch]);

  if (isSymbolConfigLoading) {
    return <Loader isLoading={isSymbolConfigLoading} />;
  }

  return (
    <TradingCalculators
      handleNavTabChange={handleNavTabChange}
      isPublic={isPublic}
      symbols={symbols}
    />
  );
};

export default TradingCalculatorsContainer;
