import React from 'react';
import PropTypes from 'prop-types';
import { Typography, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

import styles from './styles';

const CookiesBanner = ({ classes, policyLink, handleClose }) => {
  const { t } = useTranslation('common');

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.policyText}>
        {t('policyBanner.text')}
        <a
          href={policyLink}
          className={classes.policyLinkText}
          target="_blank"
          rel="noopener noreferrer">
          {t('policyBanner.linkText')}
        </a>
      </Typography>
      <IconButton
        className={classes.closeButton}
        onClick={handleClose}
        aria-label="Close"
        color="primary">
        <CloseIcon fontSize="inherit" />
      </IconButton>
    </div>
  );
};

CookiesBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  policyLink: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(CookiesBanner);
