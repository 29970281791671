import { combineReducers } from 'redux';

import deposit from '../pages/Deposit/reducers';
import withdraw from '../pages/Withdraw/reducers';
import transfer from '../pages/Transfer/reducers';

const reducer = combineReducers({
  deposit,
  withdraw,
  transfer,
});

export default reducer;
