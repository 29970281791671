const styles = (theme) => ({
  itemContainer: {
    border: theme.containerBorder.border,
    borderTopWidth: 0,
    backgroundColor: '#FFFFFF',
    width: '100%',
    height: '100%',
  },
  fieldsContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    textAlign: 'left',
    overflowX: 'hidden',
  },
});

export default styles;
