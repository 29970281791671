import { takeEvery, select, put, all } from 'redux-saga/effects';
import { initApplication } from '../actions';
import { getUserId } from 'modules/auth/selectors';
import { getUpdatedUserRequest } from 'modules/menu/actions';

function* init() {
  const userId = yield select(getUserId);
  if (userId) {
    yield put(getUpdatedUserRequest(userId));
  }
}

export default function* watchRequest() {
  yield all([takeEvery(initApplication, init)]);
}
