import { createTheme } from 'material-latest';
import { palette } from 'components/CustomThemeProvider/themes/palette';

const theme = createTheme({
  palette: {
    primary: {
      main: palette.green2,
    },
  },
  components: {
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          paddingLeft: '0 !important',
        },
        selectLabel: {
          color: palette.simpleGrey,
        },
        displayedRows: {
          color: palette.simpleGrey,
        },
        selectRoot: {
          marginRight: 16,
        },
        select: {
          border: `1px solid ${palette.divider.darkGray}`,
          borderRadius: 4,
        },
      },
    },
  },
});

export default theme;
