import { handleActions } from 'redux-actions';
import * as actionCreators from '../actions';

const defaultState = [];

export default handleActions(
  {
    [actionCreators.processOperationsSuccess](state, action) {
      return { ...state, processedOperations: action.response.data };
    },
  },
  defaultState,
);
