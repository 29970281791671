import { palette } from '../palette';

const OverridesMuiCheckbox = {
  styleOverrides: {
    root: {
      padding: '2px',
      marginRight: '8px',
    },

    colorPrimary: {
      color: palette.icon.dark,

      '&.Mui-checked': {
        color: palette.primary.dark,

        '&:hover': {
          color: palette.primary.main,
        },

        '&.Mui-disabled': {
          color: palette.dividerCustom.lightest,
        },
      },

      '&:hover': {
        color: palette.icon.main,
      },

      '&.Mui-disabled': {
        color: palette.dividerCustom.lightest,
      },
    },
  },
};

export default OverridesMuiCheckbox;
