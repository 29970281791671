export default (theme) => ({
  title: {
    color: theme.palette.text.main,
    fontSize: '28px',
    fontWeight: '300',
    margin: '0',
    paddingBottom: '18px',
    border: 'none',
    borderBottom: `1px solid ${theme.palette.text.light}55`,
    marginTop: '-24px',
    marginBottom: '35px',
  },
  radioGroupLabelClassName: {
    color: theme.palette.text.main,
    fontSize: '20px',
    fontWeight: '300',
    margin: '10px 0 15px',
  },
  radioGroupClassName: {
    paddingTop: '0',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '20px',
    '& > label': {
      marginRight: 0,
    },
    '& > label:not(:last-child)': {
      marginRight: 30,
    },
    [theme.breakpoints.down(480)]: {
      flexDirection: 'column',
      '& > label:first-child': {
        marginRight: 0,
        marginTop: 6,
      },
      '& > label:not(:first-child)': {
        marginRight: 0,
        marginTop: 16,
      },
    },
  },
});
