import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DocumentTitle from 'react-document-title';
import classNames from 'classnames';
import get from 'lodash/get';

import styles from './styles';
import { useTranslation, Trans } from 'react-i18next';

import MainLayout from 'modules/common/MainLayout';
import ScrollOnTopButton from 'modules/common/ScrollOnTopButton';
import LoaderFullPage from 'modules/common/LoaderFullPage';
import CustomLink from 'modules/common/CustomLink';
import { Routes } from 'constants/routeConstants';
import getWarning from '../../helpers/getWarning';

const Tabs = lazy(() => import('@material-ui/core/Tabs'));
const Tab = lazy(() => import('@material-ui/core/Tab'));
const Deposit = lazy(() => import('../../pages/Deposit'));
const Transfer = lazy(() => import('../../pages/Transfer'));
const Withdraw = lazy(() => import('../../pages/Withdraw'));
const PartnerTransfer = lazy(() => import('../../pages/PartnerTransfer'));

const Payments = ({
  classes,
  activeTab,
  handleChange,
  isConfirmWithdrawLoading,
  isThaiClient,
  affiliateAccount,
  accounts,
  userCountry,
  pageWarning,
}) => {
  const { t } = useTranslation('payments');
  let warning = getWarning(t, userCountry);
  if (get(pageWarning, 'message') && get(pageWarning, 'show')) {
    warning = pageWarning.message;
  }

  return (
    <MainLayout title={t('page.title')} warning={warning}>
      <DocumentTitle title={t('page.title')} />
      <div
        className={classNames({
          [classes.root]: true,
        })}>
        {!isConfirmWithdrawLoading ? (
          <>
            <Suspense fallback="">
              <Tabs
                className={classes.tabs}
                value={activeTab}
                variant="scrollable"
                scrollButtons="off"
                onChange={handleChange}>
                {t('page.tabs', { returnObjects: true })
                  .filter((_, index) => {
                    //hide tab if account has no rebate accounts
                    if (index === 3) {
                      return affiliateAccount;
                    }
                    return true;
                  })
                  .map((tab, index) => (
                    <Tab label={tab} key={`${tab}+${index}`} className={classes.tab} />
                  ))}
              </Tabs>
            </Suspense>
            {accounts &&
            !accounts.filter((account) => !account.isHidden).length &&
            accounts.filter((account) => account.isHidden).length ? (
              <div className={classes.noVisibleAccounts}>
                <Trans i18nKey="page.hiddenAccountsWarninig" ns="payments">
                  All accounts are currently hidden from this list. Please unhide the account you
                  wish to fund
                  <CustomLink className={classes.noVisibleAccountsLink} to={Routes.MY_ACCOUNTS}>
                    here
                  </CustomLink>
                </Trans>
              </div>
            ) : null}

            <Suspense fallback="">
              <div>{activeTab === 0 && <Deposit />}</div>
              <div>{activeTab === 1 && <Transfer />}</div>
              <div>
                {activeTab === 2 && (
                  <Withdraw isThaiClient={isThaiClient} userCountry={userCountry} />
                )}
              </div>
              <div>{activeTab === 3 && <PartnerTransfer accounts={accounts} />}</div>
            </Suspense>
          </>
        ) : (
          <LoaderFullPage isLoading={true} />
        )}
      </div>
      <ScrollOnTopButton />
    </MainLayout>
  );
};

Payments.propTypes = {
  activeTab: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  isConfirmWithdrawLoading: PropTypes.bool.isRequired,
  userCountry: PropTypes.string,
  isThaiClient: PropTypes.bool,
};

export default withStyles(styles)(Payments);
