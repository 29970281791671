import { all, takeEvery, put } from 'redux-saga/effects';
import * as notificationsActions from 'modules/notifications/actions';
import * as verificationActions from 'modules/profile/pages/Verification/actions';

function* userStateWasUpdated() {
  yield put(
    notificationsActions.showNotificationInfo({
      key: 'profile:verification.notifications.stateUpdates',
    }),
  );
}

export default function* watchRequest() {
  yield all([
    takeEvery(
      [
        verificationActions.updateUserStateSuccess,
        verificationActions.updateUserAddressLineSuccess,
      ],
      userStateWasUpdated,
    ),
  ]);
}
