export default {
  actionsContainer: (theme) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyItems: 'center',
    },
  }),
  filtersContainer: (theme) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    '@media(max-width: 430px)': {
      flexDirection: 'column',
      width: '100%',
      marginRight: 0,
    },
  }),
  filter: {
    width: '200px',
    '@media(max-width: 430px)': {
      width: '100% !important',
    },
  },
  filtersButtonsContainer: (theme) => ({
    flex: 1,
    display: 'flex',
    whiteSpace: 'nowrap',
    gap: theme.spacing(0.5),
    '& > *': {
      marginBottom: `${theme.spacing(2)} !important`,
    },
  }),
  settingsContainer: {
    flex: '1 0 auto',
  },
  settings: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
    '@media(max-width: 430px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  }),
  settingButtonsContainer: (theme) => ({
    paddingBottom: theme.spacing(2),
  }),
};
