import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

export const useUsersLocation = () => {
  const location = useLocation();
  const [usersLocaton, setUsersLocation] = useState({});

  useEffect(() => {
    const title = document.title;
    setUsersLocation({
      location: location.pathname,
      title,
    });
  }, [location]);

  return usersLocaton;
};
