export default {
  arrow: {
    width: '24px',
  },
  month: {
    width: '110px',
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
    },
    '& .MuiOutlinedInput-root': {
      paddingRight: '6px',
    },
  },
  year: {
    width: '80px',
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
    },
    '& .MuiOutlinedInput-root': {
      paddingRight: '6px',
    },
  },
  xsmallDatepickerDropdown: {
    '& .MuiOutlinedInput-input': {
      padding: '2px 0 2px 5px',
    },
  },
};
