import { fontSize } from '../../CustomThemeProviderMui5/themes/font';

export default {
  popper: (theme) => ({
    zIndex: theme.zIndex.modal + 1,
  }),
  root: (theme) => ({
    padding: '16px',
    fontSize: fontSize._14,
    color: theme.palette.text.dark,
    wordBreak: 'break-word',
  }),
  container: {
    cursor: 'pointer',
    display: 'inline-block',
  },
  fullWidth: {
    width: '100%',
  },
  disabled: {
    cursor: 'default',
  },
};
