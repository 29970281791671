import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import NavigationMenuList from '../components/NavigationMenuList';
import * as authSelectors from 'modules/auth/selectors';
import * as actionCreators from '../actions';
import { getMenuItemsList } from '../selectors';
import { Roles } from 'constants/userRoles';

class NavigationMenuListContainer extends Component {
  constructor(props) {
    super(props);
    this.userRole = this.props.userRole;
  }

  onMenuClick = () => {
    const {
      actions: { menuTabChanged },
    } = this.props;
    menuTabChanged();
  };

  filterMenuItemList = (role) => {
    const { isAuthenticated, menuItemsList } = this.props;
    return isAuthenticated
      ? menuItemsList.filter(
          (menuItem) =>
            menuItem?.access.includes(role) ||
            (role !== Roles.CLIENT && menuItem?.chinaManagerAllow),
        )
      : menuItemsList.filter((menuItem) => menuItem?.isPublic);
  };

  render() {
    const props = {
      isCollapsed: this.props.isCollapsed,
      onMenuClick: this.onMenuClick,
      selectedRoute: this.props.location.pathname,
      menuItemsList: this.filterMenuItemList(this.userRole)
        .filter(
          (item) =>
            !item.hideForCountry ||
            (this.props.userCountry && item.hideForCountry.indexOf(this.props.userCountry) < 0),
        )
        .filter((item) => !item.hub || item.hub === this.props.hub)
        .filter((item) => {
          if (item.allowedCountries && item.allowedCountries.length) {
            return (
              this.props.userCountry &&
              item.allowedCountries.indexOf(this.props.userCountry) >= 0 &&
              !this.props.isPro
            );
          }
          return true;
        })
        .filter((item) => item.visible),
    };
    return <NavigationMenuList {...props} />;
  }
}

NavigationMenuListContainer.propTypes = {
  location: PropTypes.object.isRequired,
  userRole: PropTypes.string,
  hub: PropTypes.string,
  userCountry: PropTypes.string,
  isPro: PropTypes.bool,
  isAuthenticated: PropTypes.bool.isRequired,
  isCountryManager: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    userRole: authSelectors.getUserRole(state),
    isCountryManager: authSelectors.getIsCountryManager(state),
    hub: authSelectors.getUserHub(state),
    userCountry: authSelectors.getUserCountry(state),
    isPro: authSelectors.getUserProStatus(state),
    isAuthenticated: authSelectors.getIsAuthenticated(state),
    menuItemsList: getMenuItemsList(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(NavigationMenuListContainer));
