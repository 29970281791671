import { palette } from '../palette';

const OverridesMuiDivider = {
  styleOverrides: {
    root: {
      color: palette.primary.lightest,
      '&:not(.MuiDivider-vertical)': {
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
    vertical: {
      marginLeft: '8px',
      marginRight: '8px',
    },
  },
};

export default OverridesMuiDivider;
