import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actionCreators from '../actions';
import { isOpen } from '../selectors';
import CustomModal from '../components/CustomModal';

class ModalContainer extends PureComponent {
  closeModal = () => {
    const {
      actions: { toggleModal },
      redirect,
      modalName,
    } = this.props;

    if (redirect) {
      redirect();
    }

    toggleModal(modalName);
  };

  render() {
    const props = {
      closeModal: this.closeModal,
    };
    return <CustomModal {...this.props} {...props} />;
  }
}

const mapStateToProps = (state, { modalName }) => {
  return {
    isModalOpen: isOpen(state, modalName),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

ModalContainer.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  redirect: PropTypes.func,
  actions: PropTypes.shape({
    toggleModal: PropTypes.func.isRequired,
  }).isRequired,
  modalName: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
