import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const isVisible = (condition, conditionLabel, minAmount, tableItem) => {
  if (condition) {
    if (get(tableItem, conditionLabel) !== condition) {
      return false;
    }
  }
  if (minAmount) {
    return get(tableItem, 'amount') >= minAmount;
  }
  return true;
};

const TableCheckboxField = ({
  classes,
  conditionLabel,
  condition,
  tableItem,
  onChange,
  minAmount,
  checkboxValues,
}) => {
  return (
    <div className={classes.item}>
      {isVisible(condition, conditionLabel, minAmount, tableItem) ? (
        <FormControlLabel
          classes={{
            root: classes.formControlLabel,
            label: classes.label,
            disabled: classes.disabled,
          }}
          control={
            <Checkbox
              name="saveUser"
              checked={checkboxValues && checkboxValues[tableItem._id]}
              onChange={(e) => onChange(e, tableItem)}
              classes={{
                root: classes.checkbox,
                checked: classes.checkboxChecked,
              }}
              icon={<CheckBoxOutlineBlankIcon className={classes.icon} />}
              checkedIcon={<CheckBoxIcon className={classes.icon} />}
            />
          }
          label="Save"
        />
      ) : null}
    </div>
  );
};

TableCheckboxField.propTypes = {
  dataLabel: PropTypes.string,
  conditionLabel: PropTypes.string,
  condition: PropTypes.any,
  checkboxValues: PropTypes.any,
  minAmount: PropTypes.number,
  tableItem: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(TableCheckboxField);
