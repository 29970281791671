export default (theme) => ({
  root: {
    padding: 16,
    background: theme.palette.white,
    border: `1px solid ${theme.palette.green}`,
    marginTop: 24,
    marginBottom: 24,
  },
  section: {
    padding: 16,
    background: theme.palette.white,
    border: `1px solid ${theme.palette.green}`,
    margin: 16,
  },
  title: {
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '26px',
  },
  description: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '19px',
    color: theme.palette.simpleGrey,
    marginTop: 6,
    marginBottom: 16,
  },
  termsContainerKey: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '16px',
    color: theme.palette.simpleGrey,
  },
  termsContainerValue: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '21px',
    color: theme.palette.textColor,
    wordBreak: 'break-all',
  },
  copyButton: {
    color: theme.palette.icon.black,
    padding: 2,
  },
});
