import { fontSize } from 'components/CustomThemeProviderMui5/themes/font';

export default {
  root: {
    marginBottom: 0,
    marginRight: '20px',
  },
  rootLabel: {
    display: 'flex',
    alignItems: 'flex-start',
    cursor: 'pointer',
    fontSize: fontSize._16,
  },
  rootLabelDisabled: {
    pointerEvents: 'none',
    cursor: 'default',
  },
  label: (theme) => ({
    paddingTop: theme.spacing(0.7),
    ...theme.typography.body1,
    color: theme.palette.text.dark,
  }),
  smallSizeLabel: (theme) => ({
    paddingTop: theme.spacing(0.4),
    ...theme.typography.body2,
  }),
  mediumSizeLabel: (theme) => ({
    paddingTop: theme.spacing(0.7),
    ...theme.typography.body1,
  }),
  labelDisabled: (theme) => ({
    color: theme.palette.textSecondary.lightest,
  }),
};
