import React from 'react';
import { Box } from 'material-latest';

import styles from './styles';

const TPCardContent = ({ divider, className, children }) => {
  return <Box sx={[styles.root, divider && styles.divider, className]}>{children}</Box>;
};

export default TPCardContent;
