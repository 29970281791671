import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';

import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import styles from './styles';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

const MenuScrollIndicators = (props) => {
  const {
    classes,
    isTopButtonShowed,
    isBottomButtonShowed,
    onScroll,
    scrollToBottom,
    scrollToTop,
    list,
    autoHeightMax,
  } = props;
  return (
    <div
      className={classNames({
        [classes.menu]: true,
        [classes.topSpaceMenuClass]: !isTopButtonShowed,
      })}>
      <Button
        onMouseEnter={scrollToTop}
        onMouseLeave={scrollToTop}
        className={classNames({
          [classes.upButton]: true,
          [classes.invisibleButton]: !isTopButtonShowed,
        })}>
        <ArrowDropUp className={classes.upButtonIcon} />
      </Button>
      <Scrollbars
        ref={list}
        autoHeight
        autoHeightMin={0}
        autoHeightMax={autoHeightMax || 400}
        renderTrackVertical={() => <div className={classes.invisibleScroll} />}
        renderTrackHorizontal={() => <div className={classes.invisibleScroll} />}
        className={classNames({
          [classes.scrollMenuRoot]: true,
        })}
        onScroll={onScroll}>
        {props.children}
      </Scrollbars>
      <Button
        onMouseEnter={scrollToBottom}
        onMouseLeave={scrollToBottom}
        className={classNames({
          [classes.downButton]: true,
          [classes.invisibleButton]: !isBottomButtonShowed,
        })}>
        <ArrowDropDown className={classes.downButtonIcon} />
      </Button>
    </div>
  );
};

MenuScrollIndicators.displayName = 'MenuScrollIndicators';

MenuScrollIndicators.propTypes = {
  isTopButtonShowed: PropTypes.bool.isRequired,
  isBottomButtonShowed: PropTypes.bool.isRequired,
  onScroll: PropTypes.func.isRequired,
  scrollToBottom: PropTypes.func.isRequired,
  scrollToTop: PropTypes.func.isRequired,
  list: PropTypes.object.isRequired,
  autoHeightMax: PropTypes.number,
};

export default withStyles(styles)(MenuScrollIndicators);
