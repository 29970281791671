import { createAction } from 'redux-actions';

export const getEconomicCalendarRequest = createAction('GET_ECONOMIC_CALENDAR_REQUEST');
export const getEconomicCalendarSuccess = createAction('GET_ECONOMIC_CALENDAR_SUCCESS');
export const getEconomicCalendarFail = createAction('GET_ECONOMIC_CALENDAR_FAIL');

export const getEconomicCalendarPublicRequest = createAction(
  'GET_ECONOMIC_CALENDAR_PUBLIC_REQUEST',
);
export const getEconomicCalendarPublicSuccess = createAction(
  'GET_ECONOMIC_CALENDAR_PUBLIC_SUCCESS',
);
export const getEconomicCalendarPublicFail = createAction('GET_ECONOMIC_CALENDAR_PUBLIC_FAIL');

export const getTechnicalInsightRequest = createAction('GET_TECHNICAL_INSIGHT_REQUEST');
export const getTechnicalInsightSuccess = createAction('GET_TECHNICAL_INSIGHT_SUCCESS');
export const getTechnicalInsightFail = createAction('GET_TECHNICAL_INSIGHT_FAIL');
