import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Field, Form, reduxForm, formValues } from 'redux-form';
import { Typography, Grid, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Loader from 'modules/common/LoaderFullPage';
import TextInput from 'modules/common/TextInput';
import PrimaryButton from 'modules/common/PrimaryButton';
import { required } from 'utils/validation/fieldValidationRules';
import JoinPlusTerms from '../JoinPlusTerms';

import styles from './styles';

const JoinPlus = ({ classes, isPending, isJoinPending, handleSubmit }) => {
  const { t } = useTranslation('myFollowers');

  if (isPending) {
    return <Loader className={classes.loader} isLoading={isPending} />;
  }

  return (
    <Form onSubmit={handleSubmit} className={classes.root}>
      <Typography className={classes.title}>
        May 2021
        <br />
        Fusion Plus Customer Terms and Conditions
      </Typography>
      <div className={classes.documentContainer}>
        <JoinPlusTerms />
      </div>

      <Grid>
        <Grid container spacing={8}>
          <Grid item xs={12} className={classes.fieldContainer}>
            <Field
              name="sign"
              component={TextInput}
              label={t('joinPlus.sign')}
              validate={[required]}
              placeholder={t('joinPlus.sign')}
            />
          </Grid>
        </Grid>
      </Grid>

      <div className={classes.buttonsSection}>
        <PrimaryButton
          color="primary"
          size="large"
          label={t('joinPlus.join')}
          type="submit"
          isPending={isJoinPending}
        />
      </div>
    </Form>
  );
};
JoinPlus.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default compose(reduxForm(), formValues('debitAccount'), withStyles(styles))(JoinPlus);
