import { all, put, select, take, takeEvery } from 'redux-saga/effects';
import { getQuizFail, getQuizRequest, setupQuiz } from 'modules/profileQuiz/actions';
import {
  getIsUserWholeSale,
  getUser,
  getUserHub,
  targetUserQuizSelector,
} from 'modules/auth/selectors';
import verificationModalNames from 'modules/VerificationModalsManager/constants/verificationModalNames';
import { selectAccountPlanSuccess } from 'modules/accounts/pages/MyAccounts/actions';
import { getQuizProgressAccessKey } from 'modules/VerificationModalsManager/utils/getQuizProgressAccessKey';
import { push } from 'connected-react-router';
import { Routes } from 'constants/routeConstants';
import { GLOBAL_PRIME } from 'constants/index';
import * as notificationsActions from 'modules/notifications/actions';
import { PRE_QUIZ_MODAL, PRO_QUIZ_MODAL } from 'modules/profileQuiz/constants';
import { closeModal, openModal } from '../../common/CustomModal/actions';

function* setupQuizWorker({ payload }) {
  const { showingPreQuiz } = payload ?? {};
  const { isUpgradeToPro, isPro } = yield select(getUser);

  if (isUpgradeToPro) {
    yield put(openModal(PRO_QUIZ_MODAL));
    return yield put(getQuizRequest());
  }

  yield put(openModal(verificationModalNames.PRO_OR_RETAIL_MODAL));

  const nextAction = yield take([selectAccountPlanSuccess, closeModal]);

  if (nextAction.type === selectAccountPlanSuccess().type) {
    yield put(closeModal(verificationModalNames.PRO_OR_RETAIL_MODAL));
  } else {
    return yield put(push(Routes.MY_ACCOUNTS));
  }

  const targetQuizProgress = yield select(targetUserQuizSelector);
  const userHub = yield select(getUserHub);
  const isWholeSaleUser = yield select(getIsUserWholeSale);

  if (targetQuizProgress?.isPassed || isPro || userHub !== GLOBAL_PRIME || isWholeSaleUser) {
    return yield put(push(Routes.MY_ACCOUNTS));
  }

  yield put(getQuizRequest());

  if (showingPreQuiz) {
    yield put(openModal(PRE_QUIZ_MODAL));
  }
}

function* getQuizFailWorker() {
  const user = yield select(getUser);

  const { selectedPlan, ...userData } = user;

  const quizProgressAccessKey = getQuizProgressAccessKey(selectedPlan);

  const targetQuiz = userData[quizProgressAccessKey];

  if (targetQuiz.isBlocked) {
    yield put(closeModal(PRE_QUIZ_MODAL));
    yield put(openModal(verificationModalNames.QUIZ_BLOCKED_MODAL));
    return;
  }

  if (targetQuiz.isPassed) {
    yield put(push(Routes.MY_ACCOUNTS));
    return;
  }

  yield put(
    notificationsActions.showNotificationError({
      key: `backend:somethingWrong`,
    }),
  );

  yield put(push(Routes.MY_ACCOUNTS));
}

function* setupQuizWatcher() {
  yield takeEvery(setupQuiz, setupQuizWorker);
}

function* getQuizFailWatcher() {
  yield takeEvery(getQuizFail, getQuizFailWorker);
}

function* setupQuizSaga() {
  yield all([setupQuizWatcher(), getQuizFailWatcher()]);
}

export default setupQuizSaga;
