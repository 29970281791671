import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Typography } from 'material-latest';
import { withStyles } from '@material-ui/core';
import CopyIcon from '@material-ui/icons/FileCopyTwoTone';

import styles from './styles';
import { useTranslation } from 'react-i18next';
import SecondaryButton from 'modules/common/SecondaryButton';
import { сopyToClipboardByString } from 'utils/copyToClipboard';

const BankCopyLabel = ({ text, wordBreakAll, onCopy, disabled, classes }) => {
  const { t } = useTranslation('payments');

  return (
    <>
      <Typography className={classnames({ [classes.wordBreakAll]: wordBreakAll })} component="span">
        {text}
      </Typography>
      <SecondaryButton
        className={classes.copyButton}
        disabled={disabled}
        label={t('deposit.bankwire.step2.copyInfoButton')}
        content={<CopyIcon className={classes.copyButtonIcon} />}
        onClick={() => сopyToClipboardByString(text, onCopy)}
      />
    </>
  );
};

BankCopyLabel.propTypes = {
  text: PropTypes.string.isRequired,
  wordBreakAll: PropTypes.bool,
  onCopy: PropTypes.func,
  disabled: PropTypes.bool,
  size: PropTypes.string,
};

export default withStyles(styles)(BankCopyLabel);
