const GAP_BETWEEN_ITEMS = 3;

const getColumnFlexValue = (columns, gap) => `0 1 calc(${100 / columns}% - ${gap * 8}px)`;

export default (theme) => ({
  descriptionList: {
    margin: 0,
    flex: 1,
    display: 'flex',
    flexWrap: 'wrap',
    gap: `16px 24px`,
    marginBottom: 0,
  },
  descriptionItem: {
    wordBreak: 'break-word',
  },
  label: {
    color: theme.palette.simpleGrey,
    fontSize: 14,
  },
  boldText: {
    fontWeight: 700,
  },
  fullWidth: {
    width: '100%',
  },
  columns1: {
    '& > *': {
      flex: getColumnFlexValue(1, GAP_BETWEEN_ITEMS),
    },
  },
  columns2: {
    '& > *': {
      flex: getColumnFlexValue(2, GAP_BETWEEN_ITEMS),
    },
  },
  columns3: {
    '& > *': {
      flex: getColumnFlexValue(3, GAP_BETWEEN_ITEMS),
    },
  },
  columns4: {
    '& > *': {
      flex: getColumnFlexValue(4, GAP_BETWEEN_ITEMS),
    },
  },
});
