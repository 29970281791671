import React from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import PrimaryButton from 'modules/common/PrimaryButton';
import CustomLink from 'modules/common/CustomLink';

const TableButtonField = ({
  classes,
  label,
  route,
  dataLabel,
  tableItem,
  onClick,
  showEmptyField = () => false,
  disabled = false,
  showLabel = false,
  isHeadRow,
}) => {
  if (showLabel && isHeadRow) {
    return <div className={classes.item} />;
  }

  const buttonLabel = isFunction(label) ? label(tableItem) : label;

  const getAdditionalClass = (item) => {
    return item.type ? classes.button__approve : '';
  };

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onClick(tableItem);
  };

  return (
    <div className={classes.item}>
      {!showEmptyField(tableItem) && (
        <>
          {route && (
            <CustomLink to={`${route}/${tableItem[dataLabel]}`}>
              <PrimaryButton
                color="secondary"
                label={buttonLabel}
                className={`${classes.button} ${getAdditionalClass(tableItem)}`}
              />
            </CustomLink>
          )}
          {onClick && (
            <PrimaryButton
              color="secondary"
              label={buttonLabel}
              className={classes.button}
              onClick={handleClick}
              disabled={disabled}
            />
          )}
        </>
      )}
    </div>
  );
};

TableButtonField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  showLabel: PropTypes.bool,
  showEmptyField: PropTypes.func,
  dataLabel: PropTypes.string,
  tableItem: PropTypes.object,
  route: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default withStyles(styles)(TableButtonField);
