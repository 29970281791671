import { createAction } from 'redux-actions';

export const evidenceStatementPageStart = createAction('EVIDENCE_STATEMENT_PAGE_START');

export const getEvidenceStatementRequest = createAction('GET_EVIDENCE_STATEMENT_REQUEST');
export const getEvidenceStatementSuccess = createAction('GET_EVIDENCE_STATEMENT_SUCCESS');
export const getEvidenceStatementFail = createAction('GET_EVIDENCE_STATEMENT_FAIL');
export const clearEvidenceStatementData = createAction('CLEAR_EVIDENCE_STATEMENT_DATA');

export const createEvidenceStatementRequest = createAction('CREATE_EVIDENCE_STATEMENT_REQUEST');
export const createEvidenceStatementSuccess = createAction('CREATE_EVIDENCE_STATEMENT_SUCCESS');
export const createEvidenceStatementFail = createAction('CREATE_EVIDENCE_STATEMENT_FAIL');

export const updateEvidenceStatementRequest = createAction('UPDATE_EVIDENCE_STATEMENT_REQUEST');
export const updateEvidenceStatementSuccess = createAction('UPDATE_EVIDENCE_STATEMENT_SUCCESS');
export const updateEvidenceStatementFail = createAction('UPDATE_EVIDENCE_STATEMENT_FAIL');

export const setActiveStep = createAction('SET_EVIDENCE_UPLOAD_ACTIVE_STEP');
