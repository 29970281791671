import { QUIZ_MODAL_STYLES } from '../../../../../constants/quizModalStyles';

const styles = () => ({
  ...QUIZ_MODAL_STYLES,
  progressBar: {
    position: 'relative',
    top: '-8px',
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
    marginBottom: '24px',
    '@media (min-width:768px)': {
      marginBottom: '12px',
    },
  },
});

export default styles;
