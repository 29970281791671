import { combineReducers } from 'redux';

import templates from './templates';
import emailSenders from './emailSenders';

const reducer = combineReducers({
  templates,
  emailSenders,
});

export default reducer;
