import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { Box } from 'material-latest';

import styles from './styles';

const TPImage = ({ src, alt = '', className, ...props }) => {
  const [errorOccured, setErrorStatus] = useState(false);

  const handleError = useCallback(
    ({ currentTarget }) => {
      currentTarget.onerror = null;
      setErrorStatus(true);
    },
    [setErrorStatus],
  );

  return !errorOccured ? (
    <Box
      component={'img'}
      src={src}
      alt={alt}
      sx={[styles.image, className]}
      onError={handleError}
      {...props}
    />
  ) : (
    <Box sx={[styles.image, styles.placeholder, className]}>
      <ImageOutlinedIcon fontSize="inherit" sx={styles.placeholderIcon} />
    </Box>
  );
};

TPImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export default TPImage;
