import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';

import styles from './styles';

const NavigationMenuListItem = ({
  classes,
  className,
  label,
  isSelected,
  icon,
  onClick,
  link,
  id,
  isCollapsed = false,
}) => {
  return (
    <div id={id}>
      <Link to={link} className={classes.linkContainer}>
        <MenuItem
          selected={isSelected}
          className={classNames(classes.menuListItem, className, {
            [classes.menuListItemCollapsed]: isCollapsed,
          })}
          classes={{ selected: classes.menuListItemSelected }}
          button
          onClick={onClick}>
          <div className={classes.checked} />
          {icon && (
            <ListItemIcon className={classNames({ [classes.iconContainerCollapsed]: isCollapsed })}>
              <img src={icon} alt={label} className={classes.icon} />
            </ListItemIcon>
          )}
          {!isCollapsed && (
            <ListItemText
              className={classes.textContainer}
              disableTypography
              primary={<Typography className={classes.menuListItemText}>{label}</Typography>}
            />
          )}
        </MenuItem>
      </Link>
    </div>
  );
};

NavigationMenuListItem.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
  className: PropTypes.string,
  isCollapsed: PropTypes.bool,
};

export default withStyles(styles)(NavigationMenuListItem);
