import React, { useCallback, useMemo } from 'react';
import { withStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  AML_QUIZ_MODAL_TITLE,
  AML_QUIZ_MODAL_TITLE_END_ADORNMENT,
  AML_SUCCESS_MODAL,
} from '../../constants';
import CustomModal from '../../../CustomModal';
import { closeModal } from '../../../CustomModal/actions';
import ImageSubtitleTextContent from '../../../ContentModal/ImageSubtitleTextContent';

import quizSuccessImage from '../../../../../static/quizzes/quiz-success.svg';
import styles from './styles';

const AMLSuccessModal = ({ classes }) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(closeModal(AML_SUCCESS_MODAL));
  }, [dispatch]);

  const buttons = useMemo(
    () => [{ label: t('buttons.gotIt'), color: 'primary', onClick: handleClose }],
    [t, handleClose],
  );

  return (
    <CustomModal
      modalClassNames={classes.modal}
      className={classes.modalPaper}
      modalName={AML_SUCCESS_MODAL}
      title={AML_QUIZ_MODAL_TITLE}
      titleEndAdornment={AML_QUIZ_MODAL_TITLE_END_ADORNMENT}
      isCloseButtonNeeded
      buttons={buttons}>
      <ImageSubtitleTextContent
        image={quizSuccessImage}
        subtitle="Thank you"
        text="Thank you for completing the questionnaire. You can now continue exploring Global Prime."
      />
    </CustomModal>
  );
};

export default withStyles(styles)(AMLSuccessModal);
