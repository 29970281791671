import { useMemo } from 'react';

const useFilterMenuItems = (
  menuItemsList,
  isAuthenticated,
  userRole,
  userCountry,
  hub,
  isPro,
  location,
) => {
  const [mainMenuItems, subMenuItems] = useMemo(() => {
    const filteredByRole = menuItemsList.filter((menuItem) =>
      isAuthenticated ? menuItem.access.includes(userRole) : menuItem.isPublic,
    );

    const filteredByCountry = filteredByRole
      .filter(
        (item) =>
          !item.hideForCountry || (userCountry && !item.hideForCountry.includes(userCountry)),
      )
      .filter((item) => !item.hub || item.hub === hub);

    const filteredByAllowedCountries = filteredByCountry.filter((item) => {
      if (item.allowedCountries?.length) {
        return userCountry && item.allowedCountries.includes(userCountry) && !isPro;
      }
      return true;
    });

    const filteredByVisibility = filteredByAllowedCountries.filter((item) => item.visible);

    const mainMenuItems = filteredByVisibility.filter((e) => e.mainMenuItem);
    const subMenuItems = filteredByVisibility.filter((e) => !e.mainMenuItem);

    return [mainMenuItems, subMenuItems];
  }, [menuItemsList, isAuthenticated, userRole, userCountry, hub, isPro]);

  const selectedItem = useMemo(() => {
    const matchedItem = mainMenuItems.find((item) => {
      if (item.url && location.pathname.includes(item.url)) {
        return true;
      }
      if (item.items && item.items.some((i) => location.pathname.includes(i.url))) {
        return true;
      }
      return false;
    });
    const index = mainMenuItems.indexOf(matchedItem);
    const lastMenuIndex = mainMenuItems.length;
    return index >= 0 ? index : lastMenuIndex;
  }, [location.pathname, mainMenuItems]);

  return { mainMenuItems, subMenuItems, selectedItem };
};

export default useFilterMenuItems;
