import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';

import Picker from 'modules/common/Picker';
import TextInput from 'modules/common/TextInput';
import PrimaryButton from 'modules/common/PrimaryButton';
import {
  required,
  number,
  moreThanZero,
  maxNumLength16,
} from 'utils/validation/fieldValidationRules';

import styles from './styles';
import { LEVERAGE_VALUES } from '../../constants';
import Results from '../Results';
import { useSelector } from 'react-redux';
import * as authSelectors from 'modules/auth/selectors';
import { Box, Icon, Typography, Grid } from 'material-latest';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SymbolPicker from '../SymbolPicker';
// import AccountType from '../AccountType';

const MarginCalculatorForm = ({
  handleSubmit,
  isPending,
  isLeverageDisabled,
  contractSizes,
  results,
  symbols,
  form,
}) => {
  const { t } = useTranslation('tradingCalculators');
  const currencies = useSelector(authSelectors.getUserCurrencies);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.headerContainer}>
        <Icon sx={styles.headerIcon} component={InfoOutlinedIcon} />
        <Typography sx={styles.headerText}>{t('marginCalculator.description')}</Typography>
      </Box>
      <Box container sx={styles.formContainer}>
        <Box component="form" sx={styles.form} onSubmit={handleSubmit}>
          <Box>
            <Typography sx={styles.title}>Calculator</Typography>
            <Typography sx={styles.description}>{t('formTitle')}</Typography>
            {/* <AccountType form={form} /> */}
            <Grid container gap={2}>
              <Grid item xs={12}>
                <SymbolPicker symbols={symbols} form={form} />
              </Grid>
              <Grid item xs={12} md={5.7} sx={styles.field}>
                <Field
                  label={t('fields.baseCurrency')}
                  placeholder={t('placeholders.baseCurrency')}
                  name="baseCurrency"
                  validate={[required]}
                  component={Picker}
                  values={currencies}
                />
              </Grid>
              <Grid item xs={12} md={5.7} sx={styles.field}>
                <Field
                  label={t('fields.contractSize')}
                  placeholder={t('placeholders.contractSize')}
                  name="contractSize"
                  component={Picker}
                  values={contractSizes}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={5.7} sx={styles.field}>
                <Field
                  label={t('fields.leverage')}
                  placeholder={t('placeholders.leverage')}
                  name="leverage"
                  validate={[required]}
                  component={Picker}
                  values={LEVERAGE_VALUES}
                  disabled={isLeverageDisabled}
                />
              </Grid>
              <Grid item xs={12} md={5.7} sx={styles.field}>
                <Field
                  label={t('fields.volumeInLots')}
                  placeholder={t('placeholders.volumeInLots')}
                  name="volumeInLots"
                  type="text"
                  validate={[number, required, moreThanZero, maxNumLength16]}
                  component={TextInput}
                />
              </Grid>
              <Grid item xs={12} sx={styles.buttonField}>
                <PrimaryButton
                  color="primary"
                  label={t('buttons.calculate')}
                  type="submit"
                  size="large"
                  isPending={isPending}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Results results={results} />
      </Box>
    </Box>
  );
};

MarginCalculatorForm.propTypes = {
  form: PropTypes.string.isRequired,
  isPending: PropTypes.bool.isRequired,
  isLeverageDisabled: PropTypes.bool.isRequired,
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
  symbols: PropTypes.array.isRequired,
  contractSizes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm()(MarginCalculatorForm);
