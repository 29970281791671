const styles = () => ({
  headerTitle: {
    display: 'none',
  },
  notFoundPageContentContainer: {
    backgroundColor: '#FAFBFC',
    minHeight: '100vh',
    paddingLeft: '17px',
    paddingRight: '16px',
    paddingTop: '20px',
    '@media (min-width:1820px)': {
      paddingLeft: '90px',
      paddingRight: '75px',
    },
    '@media (min-width:768px)': {
      paddingRight: '20px',
      paddingLeft: '30px',
    },
  },
});

export default styles;
