import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import WrappedPicker from '../WrappedPicker';
import WrappedCreatablePicker from '../WrappedCreatablePicker';
import { ErrorOutline } from '@material-ui/icons';
// import { TEXT_SHRINK_WIDTH } from 'modules/common/Picker/constants';
import { getTranslation } from 'utils/compositeTranslationHandler';

const WrapperPicker = ({
  classes,
  input,
  label,
  placeholder,
  meta: { error, submitFailed },
  selectedValue,
  values,
  isTopButtonShowed,
  handleTopButtonChange,
  tipText,
  labelRef,
  labelWidth,
  wrapperClass,
  isMulti,
  isCreatablePicker,
  menuContainer,
  onInputChange,
  ...others
}) => {
  const { t } = useTranslation();
  const Wrapper = isCreatablePicker ? WrappedCreatablePicker : WrappedPicker;

  return (
    <FormControl
      variant="outlined"
      className={classNames(classes.textFieldContainer, wrapperClass)}>
      <InputLabel
        shrink
        classes={{
          root: classes.label,
          focused: classes.labelFocused,
          error: classes.labelError,
        }}
        error={Boolean(submitFailed && error)}
        htmlFor="picker">
        <div ref={labelRef}>{label}</div>
      </InputLabel>
      <OutlinedInput
        fullWidth
        error={Boolean(submitFailed && error)}
        inputComponent={Wrapper}
        classes={{
          root: classes.textField,
          focused: classes.textFieldFocused,
          notchedOutline: classes.notchedOutline,
          input: classes.input,
          error: classes.textFieldError,
        }}
        inputProps={{
          isTopButtonShowed,
          handleTopButtonChange,
          values,
          selectedValue: selectedValue,
          onChange: (value) => {
            input.onChange(value);
          },
          id: input.name,
          name: input.name,
          placeholder: placeholder || label,
          dropDownIconClassName: classes.dropDownIcon,
          isMulti,
          menuContainer,
          onInputChange: onInputChange,
        }}
        id="picker"
        labelWidth={labelWidth}
        notched
        {...others}
        placeholder={placeholder || label}
      />
      {error && submitFailed ? (
        <span className={classes.errorText}>
          <ErrorOutline className={classes.errorIcon} />
          &nbsp;
          {getTranslation(t, error)}
        </span>
      ) : (
        tipText && <span className={classes.tip}>{tipText}</span>
      )}
    </FormControl>
  );
};

WrapperPicker.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  selectedValue: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  labelWidth: PropTypes.number.isRequired,
  handleTopButtonChange: PropTypes.func.isRequired,
  isTopButtonShowed: PropTypes.bool.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    submitFailed: PropTypes.bool.isRequired,
  }).isRequired,
  tipText: PropTypes.string,
  isMulti: PropTypes.bool,
};

export default withStyles(styles)(WrapperPicker);
