import { combineReducers } from 'redux';

import bankWire from './bankWire';
import redirectUrl from './redirectUrl';
import history from './history';
import accountId from './accountId';
import amount from './amount';
import currency from './currency';
import rate from './rate';
import qrImage from './qrImage';
import finmo from './finmo';
import monoova from './monoova';

const reducer = combineReducers({
  bankWire,
  redirectUrl,
  history,
  amount,
  currency,
  accountId,
  rate,
  qrImage,
  finmo,
  monoova,
});

export default reducer;
