import { createAction } from 'redux-actions';

// export const getClientsRequest = createAction('GET_CLIENTS_REQUEST');
// export const getClientsSuccess = createAction('GET_CLIENTS_SUCCESS');
// export const getClientsFail = createAction('GET_CLIENTS_FAIL');

// export const getClientsAccountsRequest = createAction('GET_CLIENTS_ACCOUNTS_REQUEST');
// export const getClientsAccountsSuccess = createAction('GET_CLIENTS_ACCOUNTS_SUCCESS');
// export const getClientsAccountsFail = createAction('GET_CLIENTS_ACCOUNTS_FAIL');

export const getFullMyClientsRequest = createAction('GET_FULL_MY_CLIENTS_REQUEST');
export const getFullMyClientsAccountsSuccess = createAction('GET_FULL_MY_CLIENTS_SUCCESS');
export const getFullClientsAccountsFail = createAction('GET_FULL_CLIENTS_FAIL');

// export const updateFullClients = createAction('UPDATE_FULL_CLIENTS');
// export const setLastRequestId = createAction('SET_LAST_REQUEST_ID');
