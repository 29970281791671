import { handleActions } from 'redux-actions';

import { DIALOG_NAME, READY_TO_CREATE_ACCOUNT_MODAL } from '../constants';
import * as actionCreators from '../actions';
import { ACCOUNT_SERVERS } from 'constants/index';
import { getLiveAccounts, getRebateAccounts, getDemoAccounts } from '../helpers';

const defaultState = {
  activeAccountsTab: ACCOUNT_SERVERS.LIVE,
  live: [],
  demo: [],
  rebate: [],
  activeDialog: '',
  showHidden: false,
  hideAccount: {},
  requestAccountDialogHidden: true,
};

export default handleActions(
  {
    [actionCreators.accountsPageOpened](state, { payload }) {
      return {
        ...defaultState,
        activeAccountsTab: payload.activeTab ? payload.activeTab : defaultState.activeAccountsTab,
      };
    },
    [actionCreators.getDemoAccountsSuccess](state, action) {
      return {
        ...state,
        demo: getDemoAccounts(action.response.data),
      };
    },
    [actionCreators.accountsTabChanged](state, action) {
      return {
        ...state,
        activeAccountsTab: action.payload,
      };
    },
    [actionCreators.getAccountsSuccess](state, action) {
      const resultState = { ...state };
      if (action.payload.isLive) {
        resultState.live = getLiveAccounts(action.response.data);
        resultState.rebate = getRebateAccounts(action.response.data);
      } else {
        resultState.demo = getDemoAccounts(action.response.data);
      }
      return resultState;
    },
    [actionCreators.accountsShowAddDialog](state) {
      return {
        ...state,
        activeDialog: DIALOG_NAME.ADD_ACCOUNT,
      };
    },
    [actionCreators.accountsShowReadyToCreateDialog](state) {
      return {
        ...state,
        activeDialog: READY_TO_CREATE_ACCOUNT_MODAL,
      };
    },
    [actionCreators.accountsHideReadyToCreateDialog](state) {
      return {
        ...state,
        activeDialog: '',
        requestAccountDialogHidden: true,
      };
    },
    [actionCreators.additionalAccountDialogToggle](state) {
      return {
        ...state,
        requestAccountDialogHidden: false,
      };
    },

    [actionCreators.accountsShowEditDialog](state) {
      return {
        ...state,
        activeDialog: DIALOG_NAME.EDIT_ACCOUNT,
      };
    },
    [actionCreators.accountsShowFundDemoDialog](state) {
      return {
        ...state,
        activeDialog: DIALOG_NAME.FUND_DEMO_ACCOUNT,
      };
    },
    [actionCreators.accountsHideDialog](state) {
      return {
        ...state,
        activeDialog: '',
        requestAccountDialogHidden: true,
      };
    },
    [actionCreators.hideAccountOpen](state, action) {
      return { ...state, hideAccount: action.payload };
    },
    [actionCreators.hideAccountSuccess](state, action) {
      const account = action.response.data;
      const mapAccount = (a) => {
        if (a._id === account._id) {
          return { ...a, ...{ isHidden: account.isHidden } };
        }
        return { ...a };
      };
      if (account.isLive) {
        state.live = state.live.map(mapAccount);
        state.rebate = state.rebate.map(mapAccount);
      } else {
        state.demo = state.demo.map(mapAccount);
      }
      return { ...state };
    },
    [actionCreators.toggleShowHidden](state) {
      return {
        ...state,
        showHidden: !state.showHidden,
      };
    },
    [actionCreators.selectProOrRetail](state, action) {
      const { type, isQuizSelected, isUpgrade } = action.payload;
      return {
        ...state,
        isProSelected: type,
        isQuizSelected,
        isUpgrade,
      };
    },
  },
  defaultState,
);
