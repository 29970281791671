import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { TMD_LOCKED_MODAL } from '../../constants';
import CustomModal from '../../../CustomModal';
import CustomLink from '../../../CustomLink';
import ImageSubtitleTextContent from '../../../ContentModal/ImageSubtitleTextContent';

import quizFailureImage from '../../../../../static/quizzes/quiz-failure.svg';

import styles from './styles';

const TMDLockedModal = ({ classes }) => {
  return (
    <CustomModal
      modalClassNames={classes.modal}
      className={classes.modalPaper}
      modalName={TMD_LOCKED_MODAL}
      title="Oh no! Unfortunately you don't meet our target market"
      disableBackdropClick
      hideButtons>
      <ImageSubtitleTextContent
        image={quizFailureImage}
        text={
          <Typography className={classes.text}>
            To ensure you don&apos;t engage in trading activities you are not suitable for,
            you&apos;ll be logged out in 30 seconds, and account closed. If you believe this is a
            mistake, please contact our support team at{' '}
            <CustomLink to="mailto:support@globalprime.com" className={classes.link}>
              support@globalprime.com
            </CustomLink>
          </Typography>
        }
      />
    </CustomModal>
  );
};

export default withStyles(styles)(TMDLockedModal);
