import get from 'lodash/get';
import { handleActions } from 'redux-actions';
import * as actions from '../actions';

const defaultState = {
  isLoading: true,
  history: [],
  balance: null,
  affiliate: null,
};

export default handleActions(
  {
    [actions.sendPartnerTransferRequest]: (state) => {
      return { ...state, isLoading: true };
    },
    [actions.sendPartnerTransferSuccess]: (state) => {
      return { ...state, isLoading: false };
    },
    [actions.sendPartnerTransferFail]: (state) => {
      return { ...state, isLoading: false };
    },
    [actions.getPartnerTransferHistoryRequest]: (state) => {
      return { ...state, isLoading: true };
    },
    [actions.getPartnerTransferHistorySuccess]: (state, action) => {
      return { ...state, isLoading: false, history: get(action, 'response.data', []) };
    },
    [actions.getPartnerTransferHistoryFail]: (state) => {
      return { ...state, isLoading: false };
    },
    [actions.getAffiliateBalanceRequest]: (state) => {
      return { ...state, isLoading: true };
    },
    // "partner transfer" is temporarily disabled
    // [actions.getAffiliateBalanceSuccess]: (state, action) => {
    //   return {
    //     ...state,
    //     isLoading: false,
    //     affiliate: get(action, 'response.data.affiliateId', 0),
    //     balance: get(action, 'response.data.balance', 0),
    //   };
    // },
    // [actions.getAffiliateBalanceFail]: (state) => {
    //   return { ...state, isLoading: false };
    // },
  },
  defaultState,
);
