import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import Notifications from '../components/Notifications';
import * as selectors from '../selectors';
import * as actionCreators from '../actions';

class NotificationsContainer extends React.PureComponent {
  onRemoveNotification = (id) => {
    this.props.actions.removeNotification(id);
  };

  render() {
    const { notifications, className } = this.props;
    const props = {
      notifications,
      className,
      onRemoveNotification: this.onRemoveNotification,
    };

    return <Notifications {...props} />;
  }
}

NotificationsContainer.propTypes = {
  notifications: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    removeNotification: PropTypes.func.isRequired,
  }),
  className: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    notifications: selectors.getNotifications(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsContainer);
