import { handleActions } from 'redux-actions';
import * as actions from '../actions';

const defaultState = {
  queue: [],
};

export default handleActions(
  {
    [actions.paymentQueuePendingSuccess]: (state, action) => {
      return {
        ...state,
        queue: action.response.data.queue,
      };
    },
  },
  defaultState,
);
