import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  TMD_SUCCESS_MODAL,
  TMD_TEST_MODAL_TITLE,
  TMD_TEST_MODAL_TITLE_END_ADORNMENT,
} from '../../constants';
import CustomModal from '../../../CustomModal';
import ImageSubtitleTextContent from '../../../ContentModal/ImageSubtitleTextContent';

import quizSuccessImage from '../../../../../static/quizzes/quiz-success.svg';

import styles from './styles';

const TMDSuccessModal = ({ classes, onContinue, onCancel }) => {
  const { t } = useTranslation('common');
  const buttons = useMemo(
    () => [
      { label: t('buttons.cancel'), variant: 'text', onClick: onCancel },
      { label: t('buttons.continue'), color: 'primary', onClick: onContinue },
    ],
    [t, onCancel, onContinue],
  );
  return (
    <CustomModal
      modalClassNames={classes.modal}
      className={classes.modalPaper}
      customCloseModal={onCancel}
      isCloseButtonNeeded
      modalName={TMD_SUCCESS_MODAL}
      title={TMD_TEST_MODAL_TITLE}
      titleEndAdornment={TMD_TEST_MODAL_TITLE_END_ADORNMENT}
      buttons={buttons}>
      <ImageSubtitleTextContent
        image={quizSuccessImage}
        subtitle="Thank you"
        text="Thank you for completing the Appropriateness test. Please answer some additional questions that will help us evaluate your understanding."
      />
    </CustomModal>
  );
};

TMDSuccessModal.propTypes = {
  onContinue: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default withStyles(styles)(TMDSuccessModal);
