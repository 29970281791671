import { createAction } from 'redux-actions';

export const getDashboardDataRequest = createAction('GET_DASHBOARD_DATA_REQUEST');
export const getDashboardDataSuccess = createAction('GET_DASHBOARD_DATA_SUCCESS');
export const getDashboardDataFail = createAction('GET_DASHBOARD_DATA_FAIL');

export const navigateToDashboard = createAction('DISCOVER_NAVIGATE_TO_DASHBOARD');

export const getSentimentTimeseriesRequest = createAction('GET_SENTIMENT_TIMESERIES_REQUEST');
export const getSentimentTimeseriesSuccess = createAction('GET_SENTIMENT_TIMESERIES_SUCCESS');
export const getSentimentTimeseriesFail = createAction('GET_SENTIMENT_TIMESERIES_FAIL');

export const getDailyPricesRequest = createAction('GET_DAILY_PRICES_REQUEST');
export const getDailyPricesSuccess = createAction('GET_DAILY_PRICES_SUCCESS');
export const getDailyPricesFail = createAction('GET_DAILY_PRICES_FAIL');
export const setDailyPrices = createAction('SET_DAILY_PRICES');
