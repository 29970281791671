import api from 'modules/api';

export const getCustomFieldsQuestionsRequest = () => {
  return api.get('/custom-fields');
};

export const updateCustomFieldsQuestionsRequest = (payload) => {
  return api.patch('/custom-fields', { params: payload });
};

export const createCustomFieldsQuestionsRequest = () => {
  return api.post('/custom-fields');
};

export const removeCustomFieldsQuestionsRequest = (payload) => {
  const { id } = payload;
  return api.delete(`/custom-fields/${id}`, payload);
};
