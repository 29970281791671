import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import PrimaryButton from '../../PrimaryButton';

const TableDeleteField = ({ classes, tableItem, onDeleteDetailsClick, label }) => {
  return (
    <div className={classes.deleteContainer}>
      <PrimaryButton
        variant="outlined"
        onClick={() => onDeleteDetailsClick(tableItem._id)}
        label={label}
      />
    </div>
  );
};

TableDeleteField.propTypes = {
  className: PropTypes.string,
  onDeleteDetailsClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(TableDeleteField);
