import api from '..';

export const getMyPerformanceRequest = ({ mt4login }) => {
  return api.get(`/performance/${mt4login}/analysis`);
};

export const getSharedPerformanceRequest = ({ mt4login, shareId }) => {
  return api.get(`/performance/${mt4login}/share/${shareId}/analysis`);
};

export const getSharedPerformanceTradesRequest = ({ mt4login, shareId }) => {
  return api.get(`/performance/${mt4login}/share/${shareId}/trades`);
};

export const getMyPerformanceTradesRequest = ({ mt4login, skip, limit }) => {
  return api.get(`/performance/${mt4login}/trades?skip=${skip}&limit=${limit}`);
};

export const getUserPerformanceRequest = ({ userId, mt4login }) => {
  return api.get(`/users/${userId}/performance/${mt4login}/analysis`);
};

export const getUserPerformanceTradesRequest = ({ userId, mt4login }) => {
  return api.get(`/users/${userId}/performance/${mt4login}/trades`);
};

export const getPerformanceShareLinkRequest = ({ mt4login }) => {
  return api.get(`/performance/${mt4login}/link`);
};
