import { combineActions, handleActions } from 'redux-actions';

import { signinSuccess, selectHub } from '../pages/SignIn/actions';
import * as userActionCreators from 'modules/userManagement/pages/UserDetails/actions';

const defaultState = false;

export default handleActions(
  {
    [combineActions(selectHub)]() {
      return true;
    },
    [combineActions(signinSuccess, userActionCreators.signinByEmailSuccess)]() {
      return false;
    },
  },
  defaultState,
);
