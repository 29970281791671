const styles = (theme) => ({
  menu: {
    backgroundColor: theme.contentLayout.backgroundColor,
  },
  upButton: {
    width: '100%',
    height: '28px',
    border: `1px solid ${theme.contentLayout.borderColor}`,
    borderRadius: '8px 8px 0 0',
    backgroundColor: theme.contentLayout.backgroundColor,
    padding: 0,
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover $upButtonIcon': {
      color: '#46ADAE ',
    },
    '&:hover': {
      backgroundColor: theme.contentLayout.backgroundColor,
    },
    '@media (max-width:768px)': {
      display: 'none',
    },
  },
  downButton: {
    width: '100%',
    height: '28px',
    border: `1px solid ${theme.contentLayout.borderColor}`,
    borderRadius: '0 0 8px 8px',
    backgroundColor: theme.contentLayout.backgroundColor,
    color: '#8E96B3',
    padding: 0,
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover $downButtonIcon': {
      color: '#46ADAE ',
    },
    '&:hover': {
      backgroundColor: theme.contentLayout.backgroundColor,
    },
    '@media (max-width:768px)': {
      display: 'none',
    },
  },
  upButtonIcon: {
    color: '#8E96B3',
    fontSize: '27px',
  },
  downButtonIcon: {
    color: '#8E96B3',
    fontSize: '27px',
  },
  invisibleScroll: {
    display: 'none',
  },
  scrollMenuRoot: {
    boxSizing: 'border-box',
    scrollBehavior: 'smooth',
  },
  invisibleButton: {
    display: 'none',
  },
});

export default styles;
