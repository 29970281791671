import React, { lazy, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import isEqual from 'lodash/isEqual';

const Account = lazy(() => import('../Account'));

const checkAccountsEqual = (oldProps, newProps) => {
  return (
    isEqual(oldProps.accounts, newProps.accounts) &&
    isEqual(oldProps.showHidden, newProps.showHidden) &&
    isEqual(oldProps.showLeverage, newProps.showLeverage)
  );
};

const SortAccounts = React.memo(
  ({
    classes,
    showHidden,
    onSort,
    accounts,
    actions,
    openMyPerformance,
    hideAccountLoading,
    isQuizRequired,
    isLead2,
    isLead3,
    isLead4,
    accountsShowAddDialog,
    onLiveFund,
    accountsShowFundDemoDialog,
    onGoVerification,
    wasRejected,
    userStatus,
    showLeverage,
    userIsVerified,
    followerId,
    openFollowAccount,
    hub,
    onUndoArchived,
  }) => {
    const SortableItem = SortableElement(({ account }) => {
      return (
        <li className={classes.liAccountContainer}>
          <Account
            {...account}
            onEdit={() => actions.accountsShowEditDialog(account)}
            onHide={actions.hideAccountRequest}
            openMyPerformance={openMyPerformance}
            hideAccountClose={actions.hideAccountClose}
            hideAccountOpen={actions.hideAccountOpen}
            hideAccountLoading={hideAccountLoading}
            isQuizRequired={isQuizRequired}
            isLead2={isLead2}
            isLead3={isLead3}
            isLead4={isLead4}
            accountsShowAddDialog={accountsShowAddDialog}
            onFund={
              account.isLive ? () => onLiveFund(account) : () => accountsShowFundDemoDialog(account)
            }
            onGoVerification={onGoVerification}
            wasRejected={wasRejected}
            userStatus={userStatus}
            showLeverage={showLeverage}
            userIsVerified={userIsVerified}
            followerId={followerId}
            openFollowAccount={openFollowAccount}
            hub={hub}
            onUndoArchived={onUndoArchived}
          />
        </li>
      );
    });

    const SortableList = SortableContainer(({ items }) => {
      return (
        <ul className={classes.ulAccountsContainer}>
          {items.map((value, index) => (
            <SortableItem key={`item-${index}`} index={index} account={value} />
          ))}
        </ul>
      );
    });

    const SortableComponent = ({ accounts }) => {
      const [acc, setAcc] = useState(
        accounts.filter((account) => !!showHidden === !!account.isHidden),
      );

      const sortAccounts = useCallback(({ acc, oldIndex, newIndex }) => {
        onSort({ accountId: acc[oldIndex]._id, indexId: newIndex });
      }, []);

      const shouldCancelStart = (e) => {
        if (
          ['a', 'path', 'svg', 'input', 'textarea', 'select', 'option'].indexOf(
            e.target.tagName.toLowerCase(),
          ) !== -1 ||
          document.body.clientWidth < 1085
        ) {
          return true;
        }
      };

      const onSortEnd = ({ oldIndex, newIndex }) => {
        const sorted = arrayMove(acc, oldIndex, newIndex);
        setAcc(sorted);
        sortAccounts({ acc, oldIndex, newIndex });
      };

      return (
        <div>
          <SortableList
            distance={1}
            axis="xy"
            items={acc}
            onSortEnd={onSortEnd}
            shouldCancelStart={shouldCancelStart}
          />
        </div>
      );
    };

    return <SortableComponent accounts={accounts} />;
  },
  checkAccountsEqual,
);

SortAccounts.propTypes = {
  onLiveFund: PropTypes.func.isRequired,
  onGoVerification: PropTypes.func.isRequired,
  openMyPerformance: PropTypes.func.isRequired,
  hideAccountLoading: PropTypes.bool,
  accountsShowAddDialog: PropTypes.func.isRequired,
  accountsShowFundDemoDialog: PropTypes.func.isRequired,
  openFollowAccount: PropTypes.func.isRequired,
  accounts: PropTypes.array,
  actions: PropTypes.object.isRequired,
  isQuizRequired: PropTypes.bool,
  isLead2: PropTypes.bool.isRequired,
  isLead3: PropTypes.bool.isRequired,
  isLead4: PropTypes.bool.isRequired,
  wasRejected: PropTypes.bool.isRequired,
  userStatus: PropTypes.string.isRequired,
  hub: PropTypes.string.isRequired,
  onUndoArchived: PropTypes.func.isRequired,
};
SortAccounts.displayName = 'SortAccounts';

export default withStyles(styles)(SortAccounts);
