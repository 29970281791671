import { createAction } from 'redux-actions';

export const getDiscoverDataRequest = createAction('GET_DISCOVER_DATA_REQUEST');
export const getDiscoverDataSuccess = createAction('GET_DISCOVER_DATA_SUCCESS');
export const getDiscoverDataFail = createAction('GET_DISCOVER_DATA_FAIL');

export const setDiscoverView = createAction('SET_DISCOVER_VIEW');
export const navigateToDashboard = createAction('DISCOVER_NAVIGATE_TO_DASHBOARD');

export const getDiscoverEntitiesRequest = createAction('GET_DISCOVER_ENTITIES_REQUEST');
export const getDiscoverEntitiesSuccess = createAction('GET_DISCOVER_ENTITIES_SUCCESS');
export const getDiscoverEntitiesFail = createAction('GET_DISCOVER_ENTITIES_FAIL');
