import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = '';

export default handleActions(
  {
    [actionCreators.getEconomicCalendarSuccess](state, action) {
      return action.response.data;
    },
    [actionCreators.getEconomicCalendarFail]() {
      return defaultState;
    },
    [actionCreators.getEconomicCalendarPublicSuccess](state, action) {
      return action.response.data;
    },
    [actionCreators.getEconomicCalendarPublicFail]() {
      return defaultState;
    },
    [actionCreators.getTechnicalInsightSuccess](state, action) {
      return action.response.data;
    },
    [actionCreators.getTechnicalInsightFail]() {
      return defaultState;
    },
  },
  defaultState,
);
