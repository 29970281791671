import { createAction } from 'redux-actions';

export const getMigrateToIBRequest = createAction('GET_MIGRATE_TO_IB_REQUEST');
export const getMigrateToIBSuccess = createAction('GET_MIGRATE_TO_IB_SUCCESS');
export const getMigrateToIBFail = createAction('GET_MIGRATE_TO_IB_FAIL');

export const getBulkMigrateToIB = createAction('GET_BULK_MIGRATE_TO_IB');

export const operationsBulkMigrateToIBRequest = createAction(
  'OPERATIONS_BULK_MIGRATE_TO_IB_REQUEST',
);
export const operationsBulkMigrateToIBSuccess = createAction(
  'OPERATIONS_BULK_MIGRATE_TO_IB_SUCCESS',
);
export const operationsBulkMigrateToIBFail = createAction('OPERATIONS_BULK_MIGRATE_TO_IB_FAIL');
