import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = { users: [], lots: [] };

export default handleActions(
  {
    [actionCreators.getVpsUsersSuccess](state, action) {
      return { ...state, users: action.response.data.vpsUsers };
    },
    [actionCreators.getLotsListSuccess](state, action) {
      return { ...state, lots: action.response.data.lots };
    },
  },
  defaultState,
);
