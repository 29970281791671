export default (theme) => {
  return {
    formWrapper: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    mainLayout: {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: 16,
      '@media (max-width:680px)': {
        paddingTop: 32,
      },
    },
    form: {
      width: 485,
      background: theme.signIn.backgroundColor,
      border: theme.signIn.border,
      padding: 40,
      [theme.breakpoints.down('xs')]: {
        width: 320,
      },
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },
    wrapper: {
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      paddingTop: 24,
      [theme.breakpoints.down('xs')]: {
        paddingTop: 12,
      },
      width: '100%',
    },
    title: {
      fontSize: 24,
      fontWeight: 600,
      color: '#000000',
    },
    subTitle: {
      fontSize: 16,
      color: theme.palette.textColor,
    },
    textField: {
      marginTop: 16,
    },
    formError: {
      ...theme.textError,
      top: 14,
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        top: 8,
      },
    },
    errorIcon: {
      fontSize: 12,
    },
    submitButton: {
      marginTop: 12,
      [theme.breakpoints.down('xs')]: {
        marginTop: 8,
      },
    },
    loader: {
      height: 50,
      padding: 20,
      marginTop: 9,
      textAlign: 'center',
    },
    logo: {
      marginBottom: 40,
    },
    logoWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    image: {
      marginBottom: '70px',
    },
    inputLabel: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '19px',
      color: theme.palette.simpleGrey,
    },
  };
};
