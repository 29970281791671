import { handleActions } from 'redux-actions';
import * as actions from '../actions';

const defaultState = {
  followers: [],
  accounts: [],
  leadAccount: {},
  invite: {},
  invites: [],
  cxd: null,
  symbolMap: [],
  settingsAccepted: false,
  settings: {},
  openedTrades: [],
  closedTrades: [],
  closedTradesCount: 0,
  errorTrades: [],
  inviteFollowerId: '',
  myInvitations: [],
  isWaitlistCompleted: false,
  waitlist: [],
};

export default handleActions(
  {
    [actions.getMyFollowersSuccess]: (state, action) => {
      return { ...state, followers: action.response.data };
    },
    [actions.getUserFollowersMyFollowersSuccess]: (state, action) => {
      return {
        ...state,
        followers: action.response.data.map((f) => ({
          ...f,
          leadAccount: f.leadAccountId,
          followerAccount: f.followerAccountId,
        })),
      };
    },
    [actions.getMyFollowersAccountsSuccess]: (state, action) => {
      return { ...state, accounts: action.response.data };
    },
    [actions.createFollowerAccountSuccess]: (state, action) => {
      return { ...state, accounts: [...state.accounts, action.response.data] };
    },
    [actions.getMyFollowersJoinIdSuccess]: (state, action) => {
      return {
        ...state,
        leadAccount: action.response.data.account,
        settings: action.response.data.settings,
      };
    },
    [actions.getFollowerInvitesSuccess]: (state, action) => {
      return { ...state, invites: action.response.data };
    },
    [actions.getUserCxdSuccess]: (state, action) => {
      return { ...state, cxd: action.response.data.cxd };
    },
    [actions.myFollowersTradesOpenedSuccess]: (state, action) => {
      return { ...state, openedTrades: action.response.data.trades };
    },
    [actions.myFollowersTradesErrorSuccess]: (state, action) => {
      return { ...state, errorTrades: action.response.data.trades };
    },
    [actions.myFollowersTradesClosedSuccess]: (state, action) => {
      return {
        ...state,
        closedTrades: [...state.closedTrades, ...action.response.data.trades],
        closedTradesCount: action.response.data.count,
      };
    },

    [actions.userFollowersTradesOpenedSuccess]: (state, action) => {
      return { ...state, openedTrades: action.response.data.trades };
    },
    [actions.userFollowersTradesErrorSuccess]: (state, action) => {
      return { ...state, errorTrades: action.response.data.trades };
    },
    [actions.userFollowersTradesClosedSuccess]: (state, action) => {
      return {
        ...state,
        closedTrades: [...state.closedTrades, ...action.response.data.trades],
        closedTradesCount: action.response.data.count,
      };
    },

    [actions.getMyFollowersMyInvitationsSuccess]: (state, action) => {
      return { ...state, myInvitations: action.response.data };
    },
    [actions.getUserFollowersMyInvitationsSuccess]: (state, action) => {
      return { ...state, myInvitations: action.response.data };
    },
    [actions.createFollowerInvitationLinkSuccess]: (state, action) => {
      return { ...state, inviteFollowerId: action.response.data.link };
    },
    [actions.setInviteFollowerId]: (state, action) => {
      return { ...state, inviteFollowerId: action.payload };
    },

    [actions.myFollowersJoinWaitlistCopySuccess]: (state) => {
      return { ...state, isWaitlistCompleted: true };
    },
    [actions.myFollowersJoinWaitlistSuccess]: (state) => {
      return { ...state, isWaitlistCompleted: true };
    },
    [actions.openMyFollowersJoinWaitlist]: (state) => {
      return { ...state, isWaitlistCompleted: false };
    },
    [actions.openMyFollowersJoinWaitlistCopy]: (state) => {
      return { ...state, isWaitlistCompleted: false };
    },
    [actions.myFollowersGetWaitlistSuccess]: (state, action) => {
      return { ...state, waitlist: action.response.data };
    },
  },
  defaultState,
);
