const styles = () => ({
  button: {
    fontSize: 12,
    fontWeight: 700,
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    padding: 14,
    width: 300,
    justifyContent: 'flex-end',
  },
  loader: {
    alignSelf: 'center',
    textAlign: 'center',
    width: 191,
  },
});

export default styles;
