const IMAGE_FILE_EXTENSIONS = ['png', 'gif', 'jpg', 'jpeg', 'tiff', 'svg', 'bmp', 'ico'];
const RE_PDF = /\.pdf($|\?)/i;
const RE_CSV = /\.csv($|\?)/i;

export const isFile = (input) => {
  return 'File' in window && input instanceof File;
};

export const getFileExtension = (path) =>
  path
    ? path
        .split('?')
        .shift()
        .split('.')
        .pop()
        .toLowerCase()
    : '';

export const isImage = (path) => {
  const ext = getFileExtension(path);
  return IMAGE_FILE_EXTENSIONS.includes(ext);
};

export const isPDF = (path) => {
  return RE_PDF.test(String(path).toLowerCase());
};

export const isCSV = (path) => {
  return RE_CSV.test(String(path).toLowerCase());
};

export const getImageUrl = (url, file) => {
  const ext = getFileExtension(url || file?.name);
  if (!IMAGE_FILE_EXTENSIONS.includes(ext)) return null;
  return url || (isFile(file) && URL.createObjectURL(file)) || '';
};
