const styles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    boxSizing: 'border-box',
  },
  listItem: {
    height: 60,
  },
  listItemCollapsed: {
    justifyContent: 'center',
  },
  rootOpen: {
    backgroundColor: '#EFFAD0',
  },
  icon: {
    width: 20,
    height: 20,
  },
  label: {
    fontSize: 16,
    lineHeight: 1.1,
  },
  nestedItem: {
    height: 'initial',
    paddingLeft: 55,
    paddingRight: 0,
    backgroundColor: '#EFFAD0',
  },
  nestedItemCollapsed: {
    background: 'none',
    paddingLeft: 0,
    paddingRight: 0,
    height: 40,
  },
  textContainer: {
    paddingLeft: '0',
    whiteSpace: 'normal',
  },
  iconContainerCollapsed: {
    marginRight: 0,
  },
});

export default styles;
