import { combineActions, handleActions } from 'redux-actions';

import * as actions from '../actions';

import * as clientsActions from 'modules/userManagement/pages/Clients/actions';
import * as blockedUsersActions from 'modules/userManagement/pages/BlockedUsers/actions';
import * as employeesActions from 'modules/userManagement/pages/Employees/actions';
import * as myClientsActions from 'modules/userManagement/pages/MyClients/actions';
import * as userDetailsActions from 'modules/userManagement/pages/UserDetails/actions';
import {
  searchQuizAnswersFail,
  searchQuizAnswersSuccess,
} from 'modules/userManagement/pages/UserDetails/actions';
import * as clientsBirthdaysActions from 'modules/userManagement/pages/ClientsBirthdays/actions';
import * as userNotifications from 'modules/userNotifications/actions';
import * as customFields from 'modules/customFields/actions';
import * as emailTemplatesActions from 'modules/emailManagement/pages/EmailTemplates/actions';
import * as emailHistoryActions from 'modules/emailManagement/pages/EmailHistory/actions';
import * as withdrawalRequestsActions from 'modules/paymentManagement/pages/WithdrawalRequests/actions';
import * as smartwayRequestsActions from 'modules/paymentManagement/pages/SmartwayRequests/actions';
import * as paymentHistoryActions from 'modules/paymentManagement/pages/History/actions';
import * as paymentPendingHistoryActions from 'modules/paymentManagement/pages/PendingHistory/actions';
import * as verificationRequestsActions from 'modules/verificationManagement/pages/Requests/actions';
import * as paymentQueueActions from 'modules/paymentQueue/actions';
import { getSecurityLogsFail, getSecurityLogsSuccess } from 'modules/security/actions';
import * as migrationRequestsActions from 'modules/migrationRequests/pages/Requests/actions';
import * as VPSUsersActions from 'modules/userManagement/pages/VPSUsers/actions';
import * as followUpsActions from 'modules/userManagement/pages/FollowUps/actions';
import * as operationNotificationsActions from 'modules/userManagement/pages/Notifications/actions';
import * as deletedUsersActions from 'modules/userManagement/pages/DeletedClients/actions';

import getTotalNumbersLabel from '../helpers/getTotalNumbersLabel';
import * as migrateToIBActions from 'modules/operations/pages/MigrateToIB/actions';

const defaultState = {};

export default handleActions(
  {
    [combineActions(
      clientsActions.getClientsSuccess,
      clientsActions.getFullClientsAccountsSuccess,
      myClientsActions.getFullMyClientsAccountsSuccess,
      emailTemplatesActions.getEmailTemplatesSuccess,
      emailHistoryActions.getEmailHistorySuccess,
      withdrawalRequestsActions.getPendingWithdrawalSuccess,
      smartwayRequestsActions.getPendingSmartwaySuccess,
      paymentHistoryActions.getPaymentsHistorySuccess,
      paymentPendingHistoryActions.getPaymentsPendingHistorySuccess,
      verificationRequestsActions.getPendingAccountsSuccess,
      verificationRequestsActions.getPendingVerificationsSuccess,
      userDetailsActions.getUserEmailsSuccess,
      userDetailsActions.getUserPaymentsSuccess,
      userDetailsActions.getTicketsSuccess,
      paymentQueueActions.paymentQueueProcessSuccess,
      paymentQueueActions.paymentQueuePendingSuccess,
      getSecurityLogsSuccess,
      employeesActions.getEmployeesSuccess,
      userNotifications.getNotificationsSuccess,
      customFields.getCustomFieldsQuestionsSuccess,
      clientsBirthdaysActions.getBirthdaysSuccess,
      migrationRequestsActions.getPendingMigrationsSuccess,
      VPSUsersActions.getVpsUsersSuccess,
      migrationRequestsActions.getPendingMigrationsSuccess,
      blockedUsersActions.getBlockedUsersSuccess,
      followUpsActions.getFollowUpsSuccess,
      operationNotificationsActions.getOperationNotificationsSuccess,
      deletedUsersActions.getDeletedUsersSuccess,
      migrateToIBActions.getMigrateToIBSuccess,
      migrateToIBActions.getBulkMigrateToIB,
      verificationRequestsActions.getPendingExperienceUploadsSuccess,
      searchQuizAnswersSuccess,
    )](state, action) {
      // client side pagination for tables using the same redux approach.
      if (!action.response) {
        const payload = action.payload;
        const totalNumbersLabel = getTotalNumbersLabel(payload);
        return { ...state, [totalNumbersLabel]: payload.totalNumber };
      }

      const response = action.response.data;
      const totalNumbersLabel = getTotalNumbersLabel(response);
      return { ...state, [totalNumbersLabel]: response.totalNumber };
    },
    [combineActions(
      clientsActions.getClientsFail,
      clientsActions.getFullClientsAccountsSuccess,
      myClientsActions.getFullMyClientsAccountsSuccess,
      emailTemplatesActions.getEmailTemplatesFail,
      emailHistoryActions.getEmailHistoryFail,
      paymentPendingHistoryActions.getPaymentsPendingHistoryFail,
      withdrawalRequestsActions.getPendingWithdrawalFail,
      smartwayRequestsActions.getPendingSmartwayFail,
      paymentHistoryActions.getPaymentsHistoryFail,
      verificationRequestsActions.getPendingAccountsFail,
      verificationRequestsActions.getPendingVerificationsFail,
      paymentQueueActions.paymentQueueProcessFail,
      paymentQueueActions.paymentQueuePendingFail,
      userDetailsActions.getUserEmailsFail,
      userDetailsActions.getTicketsFail,
      getSecurityLogsFail,
      employeesActions.getEmployeesFail,
      blockedUsersActions.getBlockedUsersFail,
      followUpsActions.getFollowUpsFail,
      deletedUsersActions.getDeletedUsersFail,
      migrateToIBActions.getMigrateToIBFail,
      verificationRequestsActions.getPendingExperienceUploadsFail,
      searchQuizAnswersFail,
    )](state) {
      return state;
    },
    [actions.resetPagination]() {
      return defaultState;
    },
  },
  defaultState,
);
