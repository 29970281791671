const styles = (theme) => ({
  label: {
    color: theme.palette.simpleGrey,
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 1.2,
  },
  amountText: {
    color: theme.tableAmountField.color,
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '22px',
    marginTop: '2px',
    whiteSpace: 'nowrap',
  },
  amountContainer: {
    minWidth: '100%',
    paddingLeft: '16px',
    paddingBottom: '22px',
    '@media (min-width:640px)': {
      minWidth: '200px',
      maxWidth: '200px',
      paddingLeft: '23px',
      paddingBottom: '0px',
    },
    '@media (min-width:768px)': {
      minWidth: '250px',
      maxWidth: '250px',
    },
    '@media (min-width:1024px)': {
      minWidth: '307px',
      maxWidth: '307px',
      paddingLeft: '30px',
    },
    backgroundColor: theme.tableAmountField.backgroundColor,
    paddingTop: '22px',
  },
});

export default styles;
