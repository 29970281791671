import { combineActions, handleActions } from 'redux-actions';
import { setBlockedHubs } from '../actions';

const defaultState = [];

export default handleActions(
  {
    [combineActions(setBlockedHubs)](state, action) {
      return action.payload;
    },
  },
  defaultState,
);
