import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import config from 'config';
import logoIcon from '../../../static/logoIcon.svg';
import styles from './styles';

const { LOGO } = config;

const Logo = ({ classes, color = 'white', isCollapsed = false }) => {
  return (
    <img
      style={isCollapsed ? { width: 'max-content' } : {}}
      src={isCollapsed ? logoIcon : LOGO[color]}
      className={classes.logo}
      alt="Logo"
    />
  );
};

Logo.propTypes = {
  color: PropTypes.string,
  isCollapsed: PropTypes.bool,
};

export default withStyles(styles)(Logo);
