import { palette } from '../palette';
import { fontSize } from '../../font';

const MuiFormLabel = {
  styleOverrides: {
    root: {
      color: palette.textSecondary.dark,
      fontSize: fontSize._16,

      '&.Mui-disabled': {
        color: palette.textSecondary.lightest,
      },

      '&.Mui-focused': {
        color: palette.textSecondary.dark,
      },
    },
  },
};

export default MuiFormLabel;
