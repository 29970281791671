import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = {
  isCreateAffiliateSubmitted: false,
  isCreateLpoaSubmitted: false,
};

export default handleActions(
  {
    [actionCreators.getUserAccountsSuccess](state, action) {
      return action.response.data;
    },

    [actionCreators.getUserAccountsFail]() {
      return defaultState;
    },

    [actionCreators.deleteDemoAccountSuccess](state, action) {
      const deletedAccount = action.response.data;
      const demoAccounts = state.demoAccounts.filter(
        (account) => account._id !== deletedAccount._id,
      );
      const accounts = { ...state, demoAccounts };
      return accounts;
    },

    [actionCreators.setDemoAccountUnlimitedExpirationSuccess](state, action) {
      const targetAccount = action.response.data;
      const { demoAccounts } = state;

      return {
        ...state,
        demoAccounts: demoAccounts.map((a) => {
          if (a._id === targetAccount._id) {
            a.isLimitless = targetAccount.isLimitless;
          }
          return a;
        }),
      };
    },

    [actionCreators.createAffiliateModalOpen]: (state) => ({
      ...state,
      isCreateAffiliateModalOpen: true,
    }),
    [actionCreators.createAffiliateModalClose]: (state) => ({
      ...state,
      isCreateAffiliateModalOpen: false,
    }),
    [actionCreators.createLpoaModalOpen]: (state) => ({
      ...state,
      isCreateLpoaModalOpen: true,
    }),
    [actionCreators.createAffiliateModalRequest]: (state) => ({
      ...state,
      isCreateAffiliateSubmitted: true,
    }),
    [actionCreators.createAffiliateModalSuccess]: (state) => ({
      ...state,
      isCreateAffiliateSubmitted: false,
      isCreateAffiliateModalOpen: false,
    }),
    [actionCreators.createAffiliateModalFail]: (state) => ({
      ...state,
      isCreateAffiliateSubmitted: false,
    }),
    [actionCreators.createLpoaModalRequest]: (state) => ({
      ...state,
      isCreateLpoaSubmitted: true,
    }),
    [actionCreators.createLpoaModalSuccess]: (state) => ({
      ...state,
      isCreateLpoaSubmitted: false,
      isCreateLpoaModalOpen: false,
    }),
    [actionCreators.createLpoaModalFail]: (state) => ({
      ...state,
      isCreateLpoaSubmitted: false,
    }),
    [actionCreators.createCellxpertTransferSuccess]: (state, action) => {
      const accountId = action.payload.account._id;
      const { balance } = action.response.data;
      const { liveAccounts } = state;
      return {
        ...state,
        liveAccounts: liveAccounts.map((a) => {
          if (a._id === accountId) {
            a.balance = String(parseInt(balance));
          }
          return a;
        }),
      };
    },
  },
  defaultState,
);
