import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { closeModal } from 'modules/common/CustomModal/actions';
import TMDWarningModal from '../../components/TMDWarningModal';
import { TMD_WARNING_MODAL } from '../../constants';

const TMDWarningModalContainer = ({ onSuccess }) => {
  const dispatch = useDispatch();

  const handleAccepted = useCallback(() => {
    dispatch(closeModal(TMD_WARNING_MODAL));
    onSuccess();
  }, [dispatch, onSuccess]);

  const handleCancel = useCallback(() => {
    dispatch(closeModal(TMD_WARNING_MODAL));
  }, [dispatch]);

  return <TMDWarningModal onAccepted={handleAccepted} onCancel={handleCancel} />;
};

TMDWarningModal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default TMDWarningModalContainer;
