import { memo, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Menu from 'material-latest/Menu';
import MenuItem from 'material-latest/MenuItem';
import Box from 'material-latest/Box';
import Typography from 'material-latest/Typography';
import { useHistory } from 'react-router-dom';

import {
  getFullName,
  getIsAdminRole,
  getUserEmail,
  getUserIsVerified,
  getIsImpersonation,
} from 'modules/auth/selectors';

import CircleItem from '../../../CircleItem';
import { getUserNameFirstLetter } from '../../../../auth/selectors';
import { styles } from './styles';
import { Routes } from '../../../../../constants/routeConstants';
import { logout, logoutAsCurrentUser } from '../../../../auth/actions';
import Divider from '../../../Divider';
import { deleteUserSessionsRequest } from 'modules/profile/pages/ProfileData/actions';

const MenuDropDown = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const isAdmin = useSelector(getIsAdminRole);
  const userName = useSelector(getFullName);
  const userEmail = useSelector(getUserEmail);
  const isUserVerified = useSelector(getUserIsVerified);
  const isImpersonation = useSelector(getIsImpersonation);

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = useMemo(() => {
    const items = [];
    if (!isAdmin) {
      items.push(
        {
          title: 'Profile',
          handleClick: () => history.push(Routes.PROFILE),
        },
        {
          title: 'Contact Us',
          handleClick: () => history.push(Routes.CONTACT_US),
        },
      );
    }

    items.push({
      title: 'Logout',
      handleClick: () => {
        dispatch(deleteUserSessionsRequest());
        dispatch(logout());
      },
    });

    if (isImpersonation) {
      items.push({
        title: 'Logout As Current User',
        handleClick: () => dispatch(logoutAsCurrentUser()),
      });
    }

    return items;
  }, [isAdmin, dispatch, history, isImpersonation]);

  const userNameFirstLetter = useSelector(getUserNameFirstLetter);

  return (
    <>
      <CircleItem
        handleClick={handleClick}
        isWithCheckmark={isUserVerified && !isAdmin}
        isMenuOpen={isOpen}>
        {userNameFirstLetter}
      </CircleItem>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: styles.menuDropDown,
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <Box sx={styles.userInfo}>
          {userName && (
            <Typography title={userName} sx={styles.userName}>
              {userName}
            </Typography>
          )}
          <Typography title={userEmail} sx={styles.userEmail}>
            {userEmail}
          </Typography>

          <Divider spacing={5} />
        </Box>

        {menuItems.map(({ title, handleClick }, index) => (
          <div key={title}>
            <MenuItem onClick={handleClick} sx={styles.menuItem}>
              {title}
            </MenuItem>

            {index === menuItems.length - 2 && (
              <Box padding="0 16px">
                <Divider spacing={5} />
              </Box>
            )}
          </div>
        ))}
      </Menu>
    </>
  );
};

export default memo(MenuDropDown);
