import api from '../index';
import { MAP_HUB_BY_PREFIX } from '../../../constants';

export const signinRequest = (payload) => {
  if (!payload.hideMfaToken) {
    delete payload.hideMfaToken;
  }
  if (payload.prefix) {
    payload.hub = MAP_HUB_BY_PREFIX[payload.prefix];
  }
  delete payload.prefix;
  return api.post('/auth/signin', payload);
};

export const signupRequest = (payload) => {
  if (payload.cxd === 'null') {
    delete payload.cxd;
  }
  if (payload.hub === 'null') {
    delete payload.hub;
  }
  return api.post('/auth/signup', payload);
};

export const forgotPasswordRequest = ({ hub, email }) => {
  return api.post('/auth/forgot-password', { hub, email });
};

export const resetPasswordRequest = (payload) => {
  return api.post('/auth/reset-password', payload);
};

export const setCxdForUserRequest = (payload) => {
  return api.patch(`/auth/setCxdIfEmpty`, payload);
};

export const getMfaQrCodeRequest = () => {
  return api.get('/auth/mfa/qr-code');
};

export const setupMfaRequest = (payload) => {
  return api.post('/auth/mfa/setup', payload);
};

export const disableMfaRequest = (payload) => {
  return api.post('/auth/mfa/disable', payload);
};

export const disableUserMfaRequest = (payload) => {
  return api.post(`/auth/mfa/disable/${payload.userId}`);
};

export const validateMfaRequest = (payload) => {
  return api.post('/auth/mfa/input', payload);
};

export const googleAuthRequest = (payload) => {
  if (payload.cxd === 'null') {
    delete payload.cxd;
  }
  if (payload.hub === 'null') {
    delete payload.hub;
  }
  return api.post('/auth/google', payload);
};

export const signinByEmailRequest = (payload) => {
  return api.post('/auth/sign-in-email', payload);
};

export const forcePasswordChangeRequest = (payload) => {
  return api.post('/auth/force-password-change', payload);
};

export const getUserSessionsRequest = () => {
  return api.get('/auth/sessions');
};

export const deleteUserSessionsRequest = (payload) => {
  return api.post(`/auth/sessions`, payload);
};

export const leadUserJoinRequest = ({ password, token }) => {
  const headers = {
    Cookie: `token=${token}`,
  };

  return api.post(
    '/lead/accept',
    {
      password,
    },
    { headers },
  );
};
