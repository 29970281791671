import createAction from 'redux-actions/es/createAction';

export const signupRequest = createAction('SIGNUP_REQUEST');
export const signupSuccess = createAction('SIGNUP_SUCCESS');
export const signupFail = createAction('SIGNUP_FAIL');

export const preInsertSignUp = createAction('PRE_INSERT_SIGN_UP');

export const googleAuthRequest = createAction('GOOGLE_AUTH_REQUEST');
export const googleAuthSuccess = createAction('GOOGLE_AUTH_SUCCESS');
export const googleAuthFail = createAction('GOOGLE_AUTH_FAIL');
