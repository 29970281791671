import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Typography } from '@material-ui/core';
import replaceLinks from './replaceLinks';
import classNames from 'classnames';

const Notifications = ({ classes, notification, markAsRead }) => {
  const markAsReadHandler = () => markAsRead(notification._id);

  const descriptionArray = (notification.description || '').split('{{image}}');

  const descriptionComponents = [];
  for (let i = 0; i < descriptionArray.length; i++) {
    descriptionArray[i].split('\n').forEach((text, i) => {
      descriptionComponents.push(
        <Typography className={classes.description} key={`p-${i}`}>
          {replaceLinks({ text, className: classes.link })}
        </Typography>,
      );
    });
    if (notification.images[i]) {
      descriptionComponents.push(
        <img src={notification.images[i].url} className={classes.image} alt="notification" />,
      );
    }
  }
  for (let i = descriptionArray.length; i < notification.images.length; i++) {
    descriptionComponents.push(
      <img src={notification.images[i].url} className={classes.image} alt="notification" />,
    );
  }

  const markAsReadClick = (e) => {
    if (!notification.viewed) {
      markAsReadHandler(e);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.container} onClick={markAsReadClick}>
        <div
          className={classNames(classes.notification, {
            [classes.clickable]: !notification.viewed,
          })}>
          <div className={classes.header}>
            <Typography
              className={classNames(classes.title, {
                [classes.titlePseudo]: !notification.viewed,
              })}>
              {notification.title}
            </Typography>
            <div className={classes.footerItem}>{moment(notification.created).fromNow()}</div>
          </div>
          {descriptionComponents.map((component, key) => (
            <div key={key}>{component}</div>
          ))}
          <div className={classes.footer}>
            <div className={classes.footerItem}>{notification.category || ''}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

Notifications.propTypes = {
  notification: PropTypes.object.isRequired,
};

export default withStyles(styles)(Notifications);
