import React, { useCallback, useEffect } from 'react';
import { getFormValues, reset } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import QuizModalContainer from '../../../Quiz';
import {
  TMD_CHECK_MODAL,
  TMD_QUIZ_FORM,
  TMD_QUIZ_MODAL,
  TMD_TEST_MODAL_TITLE,
  TMD_TEST_MODAL_TITLE_END_ADORNMENT,
} from '../../constants';
import TMDWarningModalContainer from '../../containers/TMDWarningModalContainer';
import TMDCheckModal from '../../components/TMDCheckModal';
import TMDSuccessModalContainer from '../../containers/TMDSuccessModalContainer';
import TMDLockedModal from '../../components/TMDLockedModal';
import {
  getTmdQuestionsRequest,
  lockFailedTMDUser,
  sendTmdAnswersRequest,
  setTmdCurrentQuestionIndex,
  setTmdProgressIndex,
} from '../../actions';
import { createPendingSelector } from 'modules/api/selectors';
import { closeModal, openModal } from 'modules/common/CustomModal/actions';
import { store } from 'components/App';
import { getUserTMDData } from 'modules/auth/selectors';
import { getProgressIndex, getQuestionIndex, getTMDQuestions } from '../../selectors';
import useQuiz from '../../../../../hooks/useQuiz';

const TMDContainer = () => {
  const questions = useSelector(getTMDQuestions);
  const dispatch = useDispatch();
  const quizLoading = useSelector(createPendingSelector(getTmdQuestionsRequest));
  const submitLoading = useSelector(createPendingSelector(sendTmdAnswersRequest));
  const tmdData = useSelector(getUserTMDData);
  const currentQuestionIndex = useSelector(getQuestionIndex);
  const progressIndex = useSelector(getProgressIndex);
  const answers = useSelector(getFormValues(TMD_QUIZ_FORM));

  const checkLockedUser = useCallback(() => {
    if (get(tmdData, 'accepted', null) === false) {
      dispatch(lockFailedTMDUser());
    }
  }, [dispatch, tmdData]);

  useEffect(() => {
    import('../../sagas').then((saga) => {
      store.injectSaga('TMD', saga.default);
      checkLockedUser();
    });
  }, [checkLockedUser, dispatch]);

  const handleTMDQuizOpen = useCallback(() => {
    dispatch(getTmdQuestionsRequest());
    dispatch(openModal(TMD_QUIZ_MODAL));
  }, [dispatch]);

  const handleRecheck = useCallback(() => {
    dispatch(setTmdCurrentQuestionIndex(0));
    dispatch(closeModal(TMD_CHECK_MODAL));
    dispatch(openModal(TMD_QUIZ_MODAL));
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    dispatch(sendTmdAnswersRequest(answers));
  }, [answers, dispatch]);

  const handleRecheckCancel = useCallback(() => {
    dispatch(reset(TMD_QUIZ_FORM));
    dispatch(closeModal(TMD_CHECK_MODAL));
    dispatch(setTmdCurrentQuestionIndex(0));
    dispatch(setTmdProgressIndex(0));
  }, [dispatch]);

  const handleNextTMDQuestion = useCallback(
    (next) => {
      dispatch(setTmdCurrentQuestionIndex(next + 1));
      dispatch(setTmdProgressIndex(progressIndex < next + 1 ? next + 1 : progressIndex));
    },
    [progressIndex, dispatch],
  );

  const handlePreviousQuestion = useCallback(
    (next) => {
      dispatch(setTmdCurrentQuestionIndex(next - 1));
    },
    [dispatch],
  );

  const handleFinish = useCallback(() => {
    dispatch(setTmdProgressIndex(questions.length));
    dispatch(closeModal(TMD_QUIZ_MODAL));
    dispatch(openModal(TMD_CHECK_MODAL));
  }, [dispatch, questions.length]);

  const handleCancel = useCallback(() => {
    dispatch(reset(TMD_QUIZ_FORM));
    dispatch(closeModal(TMD_QUIZ_MODAL));
    dispatch(setTmdCurrentQuestionIndex(0));
    dispatch(setTmdProgressIndex(0));
  }, [dispatch]);

  const startNextQuiz = useQuiz();

  return (
    <>
      <QuizModalContainer
        modalName={TMD_QUIZ_MODAL}
        form={TMD_QUIZ_FORM}
        currentQuestionIndex={currentQuestionIndex}
        loading={quizLoading}
        onNext={handleNextTMDQuestion}
        onPrevious={handlePreviousQuestion}
        onSubmit={handleFinish}
        progressIndex={progressIndex}
        questions={questions}
        onCancel={handleCancel}
        title={TMD_TEST_MODAL_TITLE}
        titleEndAdornment={TMD_TEST_MODAL_TITLE_END_ADORNMENT}
      />
      <TMDWarningModalContainer onSuccess={handleTMDQuizOpen} />
      <TMDCheckModal
        loading={submitLoading}
        onSubmit={handleSubmit}
        onRecheck={handleRecheck}
        onRecheckCancel={handleRecheckCancel}
      />
      <TMDSuccessModalContainer onSuccess={startNextQuiz} />
      <TMDLockedModal />
    </>
  );
};

export default TMDContainer;
