import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from 'material-latest/Box';

import TPPageSize from './components/TPPageSize';
import TPGoToPage from './components/TPGoToPage';
import TPPagesNavigation from './components/TPPagesNavigation';
import useCurrentPageUrl from './hooks/useCurrentPageUrl';
import usePrevious from '../hooks/usePrevious';

import styles from './styles';

const TPPagination = ({
  value = 0,
  pageSize = 10,
  total,
  pageSizeOptions,
  disabled = false,
  onChange,
  className,
  nav = false,
}) => {
  const pagesAmount = total && pageSize && pageSize !== 0 ? Math.ceil(total / pageSize) : 1;
  const [currentPageUrl, setCurrentPageUrl] = useCurrentPageUrl();
  const prevCurrentPageUrl = usePrevious(currentPageUrl);

  const handleChange = useCallback(
    (page, pageSize) => {
      if (nav) {
        setCurrentPageUrl(page);
      }
      if (onChange) {
        onChange(page, pageSize);
      }
    },
    [nav, onChange, setCurrentPageUrl],
  );

  const handlePageSizeChange = useCallback(
    (val) => {
      //reset selected page since number of pages is changed
      handleChange(0, val);
    },
    [handleChange],
  );

  const handlePageChange = useCallback(
    (value) => {
      handleChange(value, pageSize);
    },
    [pageSize, handleChange],
  );

  useEffect(() => {
    //emit change only if pageNumber more than 1st
    if (nav && prevCurrentPageUrl === undefined && currentPageUrl > 0) {
      handlePageChange(currentPageUrl);
    }
  }, [nav, currentPageUrl, prevCurrentPageUrl, handlePageChange]);

  useEffect(() => {
    if (value !== undefined && nav) {
      setCurrentPageUrl(value);
    }
  }, [value, nav, setCurrentPageUrl]);

  return (
    <Box sx={[styles.container, className]}>
      <Box sx={styles.root}>
        <TPPagesNavigation
          value={value}
          pagesAmount={pagesAmount}
          disabled={disabled}
          onChange={handlePageChange}
          nav={nav}
        />
        <TPGoToPage pagesAmount={pagesAmount} disabled={disabled} onChange={handlePageChange} />
        <TPPageSize
          value={pageSize}
          options={pageSizeOptions}
          disabled={disabled}
          onChange={handlePageSizeChange}
          nav={nav}
        />
      </Box>
    </Box>
  );
};

TPPagination.propTypes = {
  /**
   * Current page value
   */
  value: PropTypes.number,
  /**
   * Number of data items per page
   */
  pageSize: PropTypes.number,
  /**
   * List of possible page size values
   */
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  /**
   * Total number of data items
   */
  total: PropTypes.number,
  disabled: PropTypes.bool,
  /**
   * true means that pageNumber will be get/set from/in the browser url as parameter :pageNumber
   */
  nav: PropTypes.bool,
  /**
   * Called when the page number or pageSize is changed, function (page, pageSize)
   */
  onChange: PropTypes.func,
};

export default TPPagination;
