import React from 'react';
import classNames from 'classnames';
import { components } from 'react-select';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const GroupHeading = (props) => {
  const { classes } = props;
  const isFirstGroup = props.children === 'A';
  return (
    <div
      className={classNames({
        // [classes.marginForHiddenGroup]: props.selectProps.inputValue && !isFirstGroup,
      })}>
      <div
        className={classNames({
          [classes.hiddenGroup]: props.selectProps.inputValue,
        })}>
        <div
          className={classNames({
            [classes.delimiter]: true,
            [classes.hiddenDelimiter]: isFirstGroup,
          })}
        />
        <components.GroupHeading {...props} />
      </div>
    </div>
  );
};

GroupHeading.propTypes = {
  selectProps: PropTypes.shape({
    inputValue: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(GroupHeading);
