const styles = (theme) => ({
  navLink: {
    display: 'inline',
    cursor: 'pointer',
    outlineColor: '#ACF8E8',
    color: theme.palette.blackText,
    fontWeight: 700,
    textDecoration: 'none',
    fontSize: 14,
    '&:hover': {
      color: theme.palette.link.hover,
    },
    '&:active': {
      color: '#2A2097',
    },
  },
  link: {
    cursor: 'pointer',
    display: 'inline',
    color: theme.palette.blackText,
    fontWeight: 700,
    textDecoration: 'underline',
    fontSize: 14,
    '&:hover': {
      color: theme.palette.link.hover,
    },
  },
});

export default styles;
