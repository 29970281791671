import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Field, change, formValues } from 'redux-form';
import { isMobile, isTablet } from 'react-device-detect';
import ReCAPTCHA from 'react-google-recaptcha';
import Grid from 'material-latest/Grid';

import config from 'config';

const captchaFieldName = 'captcha';
const CAPTCHA_SIZES = {
  COMPACT: 'compact',
  NORMAL: 'normal',
};

const ReCaptcha = ({ form, isCompact, captcha }) => {
  const dispatch = useDispatch();
  const captchaRef = useRef();
  let captchaSize =
    (isMobile && !isTablet) || isCompact ? CAPTCHA_SIZES.COMPACT : CAPTCHA_SIZES.NORMAL;

  const handleChange = (captcha) => {
    dispatch(change(form, captchaFieldName, captcha));
  };

  useEffect(() => {
    if (!captcha && captchaRef.current) {
      captchaRef.current.reset();
    }
  }, [captcha, captchaRef]);

  return (
    <Grid item xs={12} container>
      <Field id={captchaFieldName} name={captchaFieldName} type="hidden" component="input" />
      <ReCAPTCHA
        ref={captchaRef}
        sitekey={config.CAPTCHA.client}
        onChange={handleChange}
        theme="light"
        size={captchaSize}
      />
    </Grid>
  );
};

export default formValues(captchaFieldName)(ReCaptcha);
