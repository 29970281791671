import { palette } from '../palette';

const OverridesMuiStepConnector = {
  styleOverrides: {
    vertical: {
      marginLeft: '12px',
      paddingBottom: 0,
    },
    line: {
      borderColor: palette.primary.lightest,
    },
    lineVertical: {
      borderLeftStyle: 'solid',
      borderLeftWidth: '1px',
      /**
       * This number is responsible for the proper alignment of the step headers with the step labels.
       * It depends on the height in pixels between the headers.
       */
      height: '40px',
    },
  },
};

export default OverridesMuiStepConnector;
