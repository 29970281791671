import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import CustomLink from 'modules/common/CustomLink';
import { getUserDocuments } from 'modules/auth/selectors';

import styles from './styles';

const CheckboxLabel = ({ classes }) => {
  const documents = useSelector(getUserDocuments);

  return (
    <BrowserRouter basename="/">
      <p className={classes.footerText}>
        <Trans i18nKey={`verification.secondStep.checkboxLabelQuiz`} ns="profile">
          I’m electronically signing your
          <CustomLink
            target="_blank"
            rel="noopener noreferrer"
            to={documents.pds}
            className={classes.link}>
            Product Disclosure Statement
          </CustomLink>
        </Trans>
      </p>
    </BrowserRouter>
  );
};

CheckboxLabel.propTypes = {
  classes: PropTypes.shape({ footerText: PropTypes.string.isRequired }).isRequired,
};

export default withStyles(styles)(CheckboxLabel);
