import { palette } from '../palette';

const OverridesMuiRadio = {
  styleOverrides: {
    root: {
      padding: '4px',
      marginRight: '6px',
    },
    colorPrimary: {
      color: palette.icon.dark,

      '&.Mui-checked': {
        color: palette.primary.dark,
        '&:hover': {
          color: palette.primary.main,
        },
        '&.Mui-disabled': {
          color: palette.dividerCustom.lightest,
        },
      },
      '&:hover': {
        color: palette.icon.main,
      },
      '&.Mui-disabled': {
        color: palette.dividerCustom.lightest,
      },
    },
  },
};

export default OverridesMuiRadio;
