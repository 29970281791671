import createAction from 'redux-actions/es/createAction';

export const setSelectedPhoneCountry = createAction('SET_SELECTED_PHONE_COUNTRY');
export const setKeyEnterError = createAction('SET_KEY_ENTER_ERROR');

export const showStateRestrictor = createAction('SHOW_STATE_RESTRICTOR');
export const hideStateRestrictor = createAction('HIDE_STATE_RESTRICTOR');
export const showCountryRestrictor = createAction('SHOW_COUNTRY_RESTRICTOR');
export const hideCountryRestrictor = createAction('HIDE_COUNTRY_RESTRICTOR');
export const showBannedCountryModal = createAction('SHOW_BANNED_COUNTRY_MODAL');
export const hideBannedCountryModal = createAction('HIDE_BANNED_COUNTRY_MODAL');

export const redirectToHomepage = createAction('REDIRECT_TO_HOMEPAGE');
export const migrateToHub = createAction('MIGRATE_TO_HUB');
export const closeMigrationConfirmation = createAction('CLOSE_MIGRATION_CONFIRMATION');

export const personalDetailsMount = createAction('PERSONAL_DETAILS_MOUNT');
