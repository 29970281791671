import axios from 'axios';

import { store } from 'components/App';
import { logout } from 'modules/auth/actions';
import conf from '../../config';

const { API_HOST } = conf;
const API_PREFIX = process.env.API_PREFIX || 'api';
const API_VERSION = process.env.API_VERSION || 'v1';

export const domain = `${API_HOST}/${API_PREFIX}/${API_VERSION}/`;

let config = {
  withCredentials: true,
  baseURL: domain,
  timeout: 300000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Pragma: 'no-cache',
    'Access-Control-Allow-Origin': conf.API_HOST,
    'Access-Control-Allow-Credentials': true,
  },
  params: {},
};

const api = axios.create(config);

// This function makes a call to get the auth token
// or it returns the same promise as an in-progress call to get the auth token

async function refreshAccessToken() {
  const api2 = axios.create(config);
  const token = await api2.post(`${domain}auth/refresh`);
  return token;
}

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

api.interceptors.response.use(
  (result) => result,
  (err) => {
    const error = err.response;

    if (!error) {
      console.log(error);
      delete err.config;
      console.log(JSON.stringify(err));
      return Promise.reject(err);
    }

    if (error.status === 401 && error.config && !error.config._retry) {
      if (isRefreshing) {
        return new Promise(function(resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            return axios(error.config);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      error.config._retry = true;
      isRefreshing = true;

      return new Promise(function(resolve, reject) {
        refreshAccessToken()
          .then(({ data }) => {
            processQueue(null, data.token);
            resolve(axios(error.config));
          })
          .catch((err) => {
            processQueue(err, null);
            store.dispatch(logout());
            reject(error);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  },
);

export default api;
