import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'material-latest';

import TPTrendingIcon from 'components/TP-UI/TPTrendingIcon';
import { SIZES } from 'components/TP-UI/constants';

import styles from './styles';

const TPTrending = ({
  value,
  label = '',
  hideIcon = false,
  iconPosition = 'end',
  size = '',
  className,
}) => {
  const trending =
    value > 0
      ? { sign: '+', value: 'up' }
      : value < 0
      ? { sign: '-', value: 'down' }
      : { value: 'flat' };

  return value !== undefined && value !== null ? (
    <Box
      component="span"
      sx={[
        styles.trendingContainer,
        trending?.value && styles[trending.value],
        size && styles[`${size}Size`],
        className,
      ]}>
      {!hideIcon && iconPosition === 'start' ? (
        <Box component="span" sx={styles.startAdornment}>
          <TPTrendingIcon trending={trending.value} />
        </Box>
      ) : null}
      {label}
      {!hideIcon && iconPosition === 'end' ? (
        <Box component="span" sx={styles.endAdornment}>
          <TPTrendingIcon trending={trending.value} />
        </Box>
      ) : null}
    </Box>
  ) : null;
};

TPTrending.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.node,
  hideIcon: PropTypes.bool,
  iconPosition: PropTypes.oneOf(['start', 'end']),
  size: PropTypes.oneOf(['', SIZES.LARGE, SIZES.MEDIUM, SIZES.SMALL]),
};

export default TPTrending;
