import api from 'modules/api/index';

export const addFollowUpRequest = (payload) => {
  return api.post('/follow-up', payload);
};

export const updateFollowUpRequest = (payload) => {
  return api.put('/follow-up', payload);
};

export const getFollowUpsRequest = (payload) => {
  return api.get('/follow-up', { params: payload });
};

export const deleteFollowUpRequest = (payload) => {
  const { id } = payload;
  return api.delete(`/follow-up/${id}`, payload);
};
