import PropTypes from 'prop-types';
import collapseIcon from '../../../../../static/close_fullscreen.svg';

const SidebarModeSwitcher = ({ onClick, className }) => {
  return <img onClick={onClick} className={className} src={collapseIcon} alt="" />;
};

SidebarModeSwitcher.propTypes = {
  onClick: PropTypes.func.isRequired,
};
export default SidebarModeSwitcher;
