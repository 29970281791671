const styles = () => ({
  delimiter: {
    width: '100%',
    backgroundColor: '#DADFEC',
    height: '1px',
    marginTop: '-16px',
  },
  hiddenDelimiter: {
    display: 'none',
  },
  hiddenGroup: {
    display: 'none',
  },
  /* marginForHiddenGroup: {
    marginTop: '-16px',
  }, */
});

export default styles;
