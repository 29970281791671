import api from 'modules/api';

export const getWarningPagesRequest = (payload) => {
  return api.get('/page-warning', { params: payload });
};

export const getWarningPageRequest = (page) => {
  return api.get(`/page-warning/${page}`);
};

export const updateWarningPageRequest = ({ page, message, show = false }) => {
  const data = { page, message, show };
  if (!message) {
    delete data.message;
  }

  return api.post('/page-warning', data);
};
