import { handleActions } from 'redux-actions';
import { newOperationNotifications } from '../pages/Notifications/actions';

const defaultState = [];

export default handleActions(
  {
    [newOperationNotifications](state, action) {
      return { ...state, newNotifications: action.payload };
    },
  },
  defaultState,
);
