import { palette } from 'components/CustomThemeProvider/themes/palette';

const styles = {
  container: {
    width: '100%',
    maxWidth: '1292px',
  },
  headerContainer: {
    display: 'flex',
    gap: 2,
    p: 2,
    backgroundColor: palette.lightGreen,
    mb: 3,
  },
  headerText: {
    fontSize: 14,
  },
  headerIcon: {
    color: palette.text,
    fontSize: 20,
  },

  formContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    gap: 3,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    color: palette.text,
  },
  description: {
    fontSize: 14,
    color: palette.simpleGrey,
    mb: 4,
  },
  form: {
    flex: '1 1',
    border: `1px solid ${palette.lightGreen}`,
    p: 2,
  },
  field: {},
  buttonField: {
    '& > button': {
      '@media (max-width:1024px)': {
        width: '100%',
      },
    },
  },
};

export default styles;
