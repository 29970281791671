import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PrivateRouteComponent from '../component/PrivateRoute';

import { getIsAuthenticated, getIsAdminRole, getIsManagerRole } from 'modules/auth/selectors';

PrivateRouteComponent.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: getIsAuthenticated(state),
    isAdmin: getIsAdminRole(state),
    isManager: getIsManagerRole(state),
  };
};

export default connect(mapStateToProps)(PrivateRouteComponent);
