import React, { cloneElement, useState, useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import MenuItem from 'material-latest/MenuItem';
import ListSubheader from 'material-latest/ListSubheader';
import Paper from 'material-latest/Paper';
import ClickAwayListener from 'material-latest/ClickAwayListener';
import MenuList from 'material-latest/MenuList';
import Popper from 'material-latest/Popper';
import Grow from 'material-latest/Grow';
import ListItemIcon from 'material-latest/ListItemIcon';
import Box from 'material-latest/Box';

import TPLink from 'components/TP-UI/TPLink';
import { PLACEMENT, SIZES } from 'components/TP-UI/constants';

import styles from './styles';

const TPMenu = ({
  header,
  children,
  placement = PLACEMENT.TOP_END,
  value = '',
  menuItems = [],
  itemValue = 'id',
  itemLabel = 'label',
  onChange,
  onOpen,
  onClose,
}) => {
  const [open, setOpen] = useState(false);
  const targetRef = useRef(null);
  const reservedIconSpace = useMemo(() => menuItems.some((item) => !!item.iconComponent), [
    menuItems,
  ]);

  const handleMenuClose = useCallback(
    (event) => {
      if (
        !targetRef?.current ||
        event.target === targetRef.current ||
        targetRef.current.contains(event.target)
      ) {
        return false;
      }
      setOpen(false);
      if (onClose) {
        onClose();
      }
    },
    [setOpen, onClose],
  );

  const handleMenuToggle = useCallback(() => {
    setOpen((open) => {
      if (open && onClose) {
        onClose();
      } else if (!open && onOpen) {
        onOpen();
      }

      return !open;
    });
  }, [setOpen, onOpen, onClose]);

  const handleItemClick = useCallback(
    (item) => {
      if (!item.disabled) {
        item.onClick && item.onClick(item[itemValue], item);
        onChange && onChange(item[itemValue], item);
        setOpen(false);
        if (onClose) {
          onClose();
        }
      }
    },
    [setOpen, itemValue, onChange, onClose],
  );

  return (
    <>
      {cloneElement(children, {
        ref: targetRef,
        onClick: handleMenuToggle,
      })}
      <Popper
        open={open}
        anchorEl={targetRef?.current}
        role="menu"
        transition
        placement={placement}
        disablePortal
        sx={styles.menuRoot}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === PLACEMENT.BOTTOM_START ? 'center top' : 'center bottom',
            }}>
            <Paper elevation={4}>
              <ClickAwayListener mouseEvent="onMouseUp" onClickAway={handleMenuClose}>
                <MenuList variant="selectedMenu" sx={[header && styles.menuListWithHeader]}>
                  {header ? (
                    <ListSubheader disableSticky sx={styles.menuListHeader}>
                      {header}
                    </ListSubheader>
                  ) : null}
                  {menuItems.map((item, index) => {
                    const IconComponent = item.iconComponent;
                    const [ContentContainer, contentContainerProps] = item.path
                      ? [TPLink, { path: item.path, className: styles.linkContainer }]
                      : [React.Fragment, {}];

                    return (
                      <MenuItem
                        key={item[itemValue] || index}
                        selected={item[itemValue] === value || item.selected}
                        disabled={item.disabled}
                        onClick={() => handleItemClick(item)}
                        sx={[styles.menuItem, (item.noGutters || item.path) && styles.noGutters]}>
                        <ContentContainer {...contentContainerProps}>
                          {reservedIconSpace ? (
                            <ListItemIcon sx={styles.iconContainer}>
                              {IconComponent ? <IconComponent fontSize={SIZES.SMALL} /> : null}
                            </ListItemIcon>
                          ) : null}
                          {item.renderItem ? (
                            item.renderItem(item)
                          ) : (
                            <Box component="span" sx={styles.text}>
                              {item[itemLabel]}
                            </Box>
                          )}
                        </ContentContainer>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

TPMenu.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node,
  placement: PropTypes.oneOf([
    PLACEMENT.TOP_START,
    PLACEMENT.TOP,
    PLACEMENT.TOP_END,
    PLACEMENT.LEFT_START,
    PLACEMENT.LEFT,
    PLACEMENT.LEFT_END,
    PLACEMENT.RIGHT_START,
    PLACEMENT.RIGHT,
    PLACEMENT.RIGHT_END,
    PLACEMENT.BOTTOM_START,
    PLACEMENT.BOTTOM,
    PLACEMENT.BOTTOM_END,
    PLACEMENT.AUTO_START,
    PLACEMENT.AUTO,
    PLACEMENT.AUTO_END,
  ]),
  value: PropTypes.string,
  onChange: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      path: PropTypes.string,
      iconComponent: PropTypes.elementType,
      label: PropTypes.string,
      renderItem: PropTypes.func,
      disabled: PropTypes.bool,
      noGutters: PropTypes.bool,
      onClick: PropTypes.func,
    }),
  ).isRequired,
  /**
   * Key in menuItem object for value
   */
  itemValue: PropTypes.string,
  /**
   * Key in menuItem object for label
   */
  itemLabel: PropTypes.string,
};

export default TPMenu;
