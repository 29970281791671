import React, { useCallback } from 'react';
import { withStyles, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectAccountPlanRequest } from '../../../accounts/pages/MyAccounts/actions';
import { ACCOUNT_PLANS } from '../../../../constants/acctountPlans';
import verificationModalNames from '../../constants/verificationModalNames';
import { closeModal } from '../../../common/CustomModal/actions';
import ImageSubtitleTextContent from '../../../common/ContentModal/ImageSubtitleTextContent';
import CustomModal from '../../../common/CustomModal';
import ConfirmContinueAsRetailClientForm from './ConfirmContinueAsRetailClientForm';

import quizFailureImage from '../../../../static/quizzes/quiz-failure.svg';
import styles from './styles';

const ProQuizBlockedModal = ({ classes }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('quiz');

  const handleSubmit = useCallback(() => {
    dispatch(selectAccountPlanRequest(ACCOUNT_PLANS.RETAIL));
    dispatch(closeModal(verificationModalNames.PRO_QUIZ_BLOCKED_MODAL));
  }, [dispatch]);

  return (
    <CustomModal
      modalClassNames={classes.modal}
      className={classes.modalPaper}
      modalName={verificationModalNames.PRO_QUIZ_BLOCKED_MODAL}
      title={t('pro.page.title')}
      disableBackdropClick>
      <ImageSubtitleTextContent
        image={quizFailureImage}
        text={
          <>
            <Typography className={classes.text}>
              {t('proQuizBlockedModal.description_1')}
            </Typography>
            <Typography className={classes.text}>
              {t('proQuizBlockedModal.description_2')}
            </Typography>
          </>
        }
        subtitle={t('proQuizBlockedModal.title')}
      />
      <ConfirmContinueAsRetailClientForm onSubmit={handleSubmit} />
    </CustomModal>
  );
};

export default withStyles(styles)(ProQuizBlockedModal);
