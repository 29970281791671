export const mobileNaivgationHeight = 72;

export default (theme) => ({
  root: {
    width: '100%',
    height: mobileNaivgationHeight,
    position: 'fixed',
    bottom: 0,
    borderTop: `1px solid #C0F8264D`,
    zIndex: theme.zIndex.appBar,
  },
  selected: {
    backgroundColor: theme.palette.lightGreen,
    fontSize: 'inherit',
  },
  label: {
    color: '#DAE5FF',
    fontSize: theme.typography.pxToRem(12),
    '&$selected': {
      fontWeight: '600',
      fontSize: theme.typography.pxToRem(12),
    },
  },
});
