import { createMuiTheme } from '@material-ui/core/styles';
import { common } from './common';
import { palette } from './palette';

// Normal or default theme
const theme = {
  themeName: 'normal',

  // palette
  palette: {
    primary: {
      main: palette.green2,
    },
    secondary: {
      main: '#EFFAD0',
    },
    text: {
      main: palette.textColor,
      light: palette.lightText,
      dark: '#01082B',
    },
    inputLabel: {
      main: palette.simpleGrey,
    },
    note: {
      main: '#747C8D',
    },
    picker: {
      color: '#46ADAE',
    },
    ...palette,
  },
  // common styles
  ...common,

  // styles
  mainLayout: {
    backgroundColor: '#FFFFFF',
  },
  contentLayout: {
    backgroundColor: '#FEFEFE',
    color: palette.moodyBlue,
    borderColor: palette.green2,
    accentBackgroundColor: '#F4F6F9',
    boxShadow: '-8px 2px 35px rgba(130, 127, 159, 0.1)',
    greyColor: '#B0B0B0',
  },

  // components
  input: {
    color: '#36425A',
  },
  radioInput: {
    color: '#36425A',
  },
  imageArray: {
    backgroundColor: '#EFFAD0',
    border: `1px dashed ${palette.green2}`,
  },
  containerBorder: {
    border: '1px solid rgba(192,248,38, 0.3)',
  },
  picker: {
    backgroundColor: '#FFFFFF',
    hoverBackgroundColor: '#EFF2F7',
  },
  signIn: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #EFFAD0',
  },
  scrollOnTopButton: {
    backgroundColor: 'rgba(255,255,255,0.94)',
    border: '1px solid #F4F6F9',
    backgroundColorHover: 'rgba(255,255,255,0.94)',
    backgroundColorActive: 'rgba(255,255,255,0.94)',
  },
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#01082B',
  },
  clientTable: {
    header: {
      backgroundColor: '#F4F6F9',
      border: '1px solid #E3E8F3',
    },
    button: {
      backgroundColor: '#FFFFFF',
    },
    separator: '1px solid #EFF2F7',
    backgroundColor: '#FFFFFF',
    border: '1px solid #EFF2F7',
    labelColor: palette.moonstoneBlue,
  },
  searchInput: {
    backgroundColor: '#FFFFFF',
  },
  tableAmountField: {
    backgroundColor: '#F8F9FB',
    labelColor: palette.moonstoneBlue,
    color: '#36425A',
  },
  tableTextField: {
    labelColor: palette.moonstoneBlue,
  },
  tableAmountReuseField: {
    backgroundColor: '#F8F9FB',
    labelColor: palette.moonstoneBlue,
    color: '#36425A',
  },
  tableDeleteField: {
    backgroundColor: '#F8F9FB',
  },
  account: {
    backgroundColor: '#FFFFFF',
    backgroundColorFooter: '#F2F4FA',
    border: '1px solid #ECF0FC',
  },
  myPerformance: {
    backgroundColor: '#FFFFFF',
    backgroundColorFooter: '#F2F4FA',
    border: '1px solid #F0F2F6',
    boxShadow: '0px 16px 16px -16px rgba(13, 38, 99, 0.08);',
  },
  separator: {
    color: '#F2F4FA',
  },
  loader: {
    backgroundColor: 'rgb(250, 251, 252, 0.3)',
  },
  alphabeticalPicker: {
    backgroundColor: '#FFFFFF',
    borderWidth: '1px !important',
    borderColor: '#DADFEC !important',
    option: {
      selected: {
        backgroundColor: '#DADFEC !important',
      },
    },
  },
  article: {
    sourceColor: '#3D459E',
    tag: {
      backgroundColor: '#E3E8F3',
    },
  },
  header: {
    backgroundColor: '#EFF2F7',
    warning: {
      backgroundColor: palette.lightGreen,
      boxShadow: '0 2px 4px 0 rgba(23,37,59,0.14)',
    },
  },
  inviteFriendForm: {
    radioGroupItem: {
      border: 'none',
      boxShadow: '0 5px 11px 0 rgba(63,90,151,0.06)',

      checked: {
        border: '1px solid #2727C5',
      },
    },
  },
  //views
  searchPage: {
    recentEntity: {
      backgroundColor: '#FFFFFF',
      boxShadow: '0 5px 11px 0 rgba(63,90,151,0.06)',
    },
    recentEntityBar: {
      positive: {
        backgroundColor: '#7DC4B5',
      },
      neutral: {
        backgroundColor: '#2E2E2E',
      },
    },
    recentEntitySentiment: {
      backgroundColor: '#E3E8F3',
      boxShadow: '0 5px 11px 0 rgba(63,90,151,0.06)',
    },
  },
  analystViewsPage: {
    analystPreview: {
      border: '1px solid #DAE5FF',
      backgroundColor: '#FFFFFF',
      boxShadow: '0 5px 11px 0 rgba(23,37,59,0.04)',
    },
    analystDetails: {
      border: '1px solid #DAE5FF',
      backgroundColor: '#FFFFFF',
      boxShadow: '0 5px 11px 0 rgba(23,37,59,0.04)',
    },
  },
  profile: {
    stepper: {
      title: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #ECF0FC',
        finished: {
          backgroundColor: '#EDF0F6',
          color: '#767C9E',
        },
      },
    },
  },
  contactInfo: {
    backgroundColor: '#1E246E',
    boxShadow: '0 5px 11px 0 rgba(72,84,139,0.09)',
  },
  proDeclarationModal: {
    text: {
      color: '#282525',
    },
    footer: {
      color: '#9D9D9D',
    },
    sign: {
      color: '#36425A',
    },
  },

  // overrides
  overrides: {
    MuiInputLabel: {
      outlined: {
        backgroundColor: palette.white,
      },
    },
    MuiPrivateNotchedOutline: {
      legend: {
        width: '0 !important',
      },
    },
    MuiTabs: {
      root: {},
      indicator: {
        height: 0,
        background: 'none',
      },
      scroller: {
        scrollbarWidth: 'none',
        marginBottom: '0!important',
      },
      scrollable: {
        overflowX: 'auto',
      },
      flexContainer: {
        gap: '16px',
      },
    },
    MuiTab: {
      root: {
        color: palette.text,
        fontWeight: 400,
        backgroundColor: '#FFFFFF',
        border: `1px solid ${palette.green2}`,
        padding: '7px 32px',
        minWidth: '120px',
        fontSize: '18px',
        lineHeight: '24px',
        textTransform: 'none',
        '@media (min-width: 960px)': {
          fontSize: '20px',
          minWidth: '120px',
        },
        '@media (max-width: 480px)': {
          padding: 0,
          minWidth: 80,
        },
        '&[aria-selected="true"]': {
          backgroundColor: '#EFFAD0',
          border: '1px solid #EFFAD0',
          fontWeight: 600,
        },
      },
      textColorInherit: {
        color: palette.text,
        opacity: 1,
      },
    },
    MuiButton: {
      root: {
        fontWeight: 700,
        '&$disabled': {
          color: '#ffffff',
          background: 'none',
          backgroundColor: '#D8D8DF',
        },
      },
      outlined: {
        border: `1px solid ${palette.green2}`,
        borderRadius: 0,
        backgroundColor: 'transparent',
        '&:hover': {
          border: '1px solid #D5FA6E',
          backgroundColor: 'transparent',
        },
        '&:active': {
          border: '1px solid #E0FC93',
          backgroundColor: 'transparent',
        },
      },
      flatPrimary: {
        color: palette.text,
        backgroundColor: palette.green2,
        '&:hover': {
          color: palette.text,
          backgroundColor: '#D5FA6E',
        },
        '&:active': {
          color: palette.text,
          backgroundColor: '#E0FC93',
        },
        textTransform: 'none',
        fontWeight: 500,
      },
      containedPrimary: {
        color: palette.text,
        fontWeight: 700,
        backgroundSize: '200% auto',
        transition: '0.5s',
        backgroundColor: palette.green2,
        borderRadius: 'unset',
        boxShadow: 'none',
        '&:hover': {
          backgroundPosition: 'right center',
          backgroundColor: '#D5FA6E',
        },
        '&:active': {
          backgroundColor: '#E0FC93',
          boxShadow: 'none',
        },
      },
      containedSecondary: {
        color: palette.black,
        fontWeight: 700,
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&:active': {
          boxShadow: 'none',
          background: 'transparent',
        },
      },
      contained: {
        backgroundColor: palette.green2,
        boxShadow: 'none',
        borderRadius: 0,
        '&:hover': {
          backgroundColor: '#D5FA6E !important',
        },
        '&:active': {
          backgroundColor: '#E0FC93',
        },
        '&$disabled': {
          color: '#ffffff',
          background: 'none',
          backgroundColor: '#D8D8DF',
        },
      },
      text: {
        boxShadow: 'none',
        borderRadius: 0,
        '&:hover': {
          backgroundColor: palette.green,
        },
        '&:active': {
          backgroundColor: palette.green,
        },
      },
      sizeSmall: {
        padding: '10px 15px',
        fontSize: 12,
      },
      sizeLarge: {
        padding: '16px 24px',
        fontSize: 15,
        textTransform: 'uppercase',
        fontWeight: '700',
      },
      label: {
        lineHeight: '15px',
        boxShadow: 'none',
      },
    },
    MuiMenuItem: {
      root: {
        '&:hover': {
          backgroundColor: '#EFF2F7',
        },
        color: '#36425A',
        fontSize: '16px',
        lineHeight: '15px',
        height: 'auto',
        minHeight: '24px',
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 0,
        '@media (max-width: 600px)': {
          margin: '5px',
        },
      },
    },
    MuiInputAdornment: {
      root: {
        color: '#888EB4',
        fontSize: 16,
        fontWeight: 300,
        marginRight: '-3px !important',
      },
    },
    MuiCheckbox: {
      root: {
        '&$disabled': {
          color: '#ffffff',
        },
        '&$colorSecondary': {
          color: palette.green2,
          '&$checked': {
            color: palette.green2,
          },
        },
        '&$colorPrimary': {
          color: palette.green2,
          '&$checked': {
            color: palette.green2,
          },
        },
      },
    },
    MuiRadio: {
      root: {
        '&$checked': {
          color: '#2727C5',
        },
        '&$disabled': {
          color: '#ffffff',
        },
      },
    },
    MuiTypography: {
      body2: {
        fontFamily: 'Red Hat Display',
      },
    },
  },
};

export default createMuiTheme(theme);
