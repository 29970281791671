const ArrowIcon = () => {
  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.33366 8L6.40033 7.03333L8.76699 4.66667H0.666992V3.33333H8.76699L6.40033 0.966667L7.33366 0L11.3337 4L7.33366 8Z"
        fill="#1C1B1F"
      />
    </svg>
  );
};

export default ArrowIcon;
