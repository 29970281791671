import React, { lazy, useEffect, useCallback, Suspense, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { store } from 'components/App';
import { useTranslation } from 'react-i18next';
import MainLayout from 'modules/common/MainLayout';
import DocumentTitle from 'react-document-title';
import { withStyles } from '@material-ui/core';

import * as apiSelectors from 'modules/api/selectors';
import { Routes } from 'constants/routeConstants';
import * as accountsActionCreators from 'modules/accounts/pages/MyAccounts/actions';
import * as accountsSelectors from 'modules/accounts/pages/MyAccounts/selectors';
import * as authSelectors from 'modules/auth/selectors';
import * as actions from '../actions';
import {
  getLeadAccount,
  getTradeSyncAccounts,
  getSettingsAccepted,
  getSettings,
} from '../selectors';
import { JOIN_FORM, JOIN_PLUS_MODAL, JOIN_PLUS_FORM, REJECT_INVITIATION_MODAL } from '../constants';
import NoData from '../components/NoData';
import joinFusionPlusIcon from 'static/myFollowers/joinFusionPlus.svg';
import SecondaryButton from 'modules/common/SecondaryButton';
import PrimaryButton from 'modules/common/PrimaryButton';
import CustomModal from 'modules/common/CustomModal';
import JoinPlus from '../components/JoinPlus';
import styles from './styles';
import NotAllowed from '../components/NotAllowed';

const JoinForm = lazy(() => import('../components/JoinForm'));
const RejectInvitationModal = lazy(() => import('../components/RejectInvitationModal'));

const MyFollowersContainer = ({ history, classes }) => {
  const { t } = useTranslation('myFollowers');
  const [inviteFollowerId, setInviteFollowerId] = useState();
  const dispatch = useDispatch();
  const tradeSyncAccounts = useSelector(getTradeSyncAccounts);
  const leadAccount = useSelector(getLeadAccount);
  const settingsAccepted = useSelector(getSettingsAccepted);
  const settings = useSelector(getSettings);
  const accounts = useSelector(accountsSelectors.getLiveAccounts);
  const isFusionPlus = useSelector(authSelectors.getIsFusionPlus);
  const allowCopyAccount = useSelector(authSelectors.allowCopyAccount);
  const hub = useSelector(authSelectors.getUserHub);
  const isPending = useSelector(
    apiSelectors.createPendingSelector(actions.getMyFollowersJoinIdRequest),
  );
  const isJoinPending = useSelector(
    apiSelectors.createPendingSelector(actions.joinAddCopierByInviteRequest),
  );
  const isAccountsPending = useSelector(
    apiSelectors.createPendingSelector(accountsActionCreators.getAccountsRequest),
  );
  const isJoinPlusPending = useSelector(
    apiSelectors.createPendingSelector(actions.joinFusionPlusInvitedRequest),
  );
  const isRejectInvitationPending = useSelector(
    apiSelectors.createPendingSelector(actions.myFollowersRejectInvitationRequest),
  );

  const [isSagaLoaded, setSagaLoaded] = useState(false);

  useEffect(() => {
    import('../sagas/join').then((saga) => {
      store.injectSaga('MyFollowersJoin', saga.default);
      setSagaLoaded(true);
    });
  }, [dispatch]);

  useEffect(() => {
    if (!inviteFollowerId) {
      return;
    }
    if (!isSagaLoaded) {
      return;
    }
    if (!hub || !allowCopyAccount) {
      return;
    }

    dispatch(actions.getMyFollowersJoinIdRequest({ id: inviteFollowerId }));
  }, [dispatch, inviteFollowerId, isSagaLoaded, hub, allowCopyAccount]);

  useEffect(() => {
    if (inviteFollowerId) {
      return;
    }
    const id = history.location.pathname.split('/').slice(-1)[0];
    if (id) {
      setInviteFollowerId(id);
    }
  }, [dispatch, history.location, inviteFollowerId]);

  useEffect(() => {
    if (!isSagaLoaded) {
      return;
    }
    dispatch(actions.getMyFollowersAccountsRequest());
    dispatch(accountsActionCreators.getAccountsRequest({ isLive: true }));
  }, [dispatch, isSagaLoaded]);

  const joinFollower = useCallback(
    (data) => {
      const { account: mt4login, password, sign, debitAccountLogin } = data;
      dispatch(
        actions.joinAddCopierByInviteRequest({
          mt4login,
          password,
          sign,
          debitAccountLogin,
          inviteFollowerId: history.location.pathname.split('/').slice(-1)[0],
        }),
      );
    },
    [dispatch, history.location],
  );

  const joinFusionPlus = useCallback(() => {
    dispatch(actions.openJoinFusionPlus());
  }, [dispatch]);

  const joinPlus = useCallback(
    (data) => {
      dispatch(actions.joinFusionPlusInvitedRequest(data));
    },
    [dispatch],
  );

  const back = useCallback(() => {
    history.push(Routes.MY_ACCOUNTS);
  }, [history]);

  useEffect(() => {
    if (settingsAccepted || !isSagaLoaded) {
      return;
    }

    dispatch(actions.updateSettings(settings));
  }, [dispatch, settingsAccepted, isSagaLoaded, settings]);

  const myFollowersRejectInvitationRequest = useCallback(() => {
    dispatch(actions.myFollowersRejectInvitationRequest(inviteFollowerId));
  }, [dispatch, inviteFollowerId]);

  const openRejectInvitation = useCallback(() => {
    dispatch(actions.openRejectInvitation());
  }, [dispatch]);

  const closeRejectInvitation = useCallback(() => {
    dispatch(actions.closeRejectInvitation());
  }, [dispatch]);

  if (hub && !allowCopyAccount) {
    return <NotAllowed />;
  }

  return (
    <MainLayout title={isFusionPlus ? t('join.title') : ''}>
      <DocumentTitle title={isFusionPlus ? t('join.title') : ''} />
      <Suspense>
        {isFusionPlus ? (
          <JoinForm
            form={JOIN_FORM}
            leadAccount={leadAccount}
            accounts={accounts}
            isPending={isPending && isAccountsPending}
            isJoinPending={isJoinPending}
            onSubmit={joinFollower}
            tradeSyncAccounts={tradeSyncAccounts}
            rejectInvitation={openRejectInvitation}
          />
        ) : (
          <NoData
            message={t('join.joinFusionPlus')}
            image={joinFusionPlusIcon}
            ButtonComponent={() => (
              <div className={classes.joinFusionPlusButtons}>
                <SecondaryButton
                  color="primary"
                  size="large"
                  label={t('common:buttons.back')}
                  onClick={back}
                />
                <PrimaryButton
                  color="primary"
                  size="large"
                  label={t('buttons.joinFusionPlus')}
                  onClick={joinFusionPlus}
                />
              </div>
            )}
          />
        )}
        <CustomModal modalName={JOIN_PLUS_MODAL} className={classes.joinModal} isCloseButtonNeeded>
          <JoinPlus
            form={JOIN_PLUS_FORM}
            onSubmit={joinPlus}
            isJoinPending={isJoinPlusPending}></JoinPlus>
        </CustomModal>
        <CustomModal modalName={REJECT_INVITIATION_MODAL} isCloseButtonNeeded>
          <RejectInvitationModal
            isPending={isRejectInvitationPending}
            onCancel={closeRejectInvitation}
            rejectInvitation={myFollowersRejectInvitationRequest}></RejectInvitationModal>
        </CustomModal>
      </Suspense>
    </MainLayout>
  );
};

export default withStyles(styles)(withRouter(MyFollowersContainer));
