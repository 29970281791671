import { combineActions, handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = '';

export default handleActions(
  {
    [combineActions(actionCreators.setKeyEnterError)](state, { payload: { error } }) {
      return error;
    },
  },
  defaultState,
);
