import React from 'react';
import Button from 'modules/common/PrimaryButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import Picker from 'modules/common/Picker';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import {
  GLOBAL_PRIME_LABEL,
  GLOBAL_PRIME_X_LABEL,
  GLOBAL_PRIME,
  GLOBAL_PRIME_X,
} from '../../../../../../constants';

const HUBS = [
  { value: GLOBAL_PRIME, label: GLOBAL_PRIME_LABEL },
  { value: GLOBAL_PRIME_X, label: GLOBAL_PRIME_X_LABEL },
];

const Confirm = (props) => {
  const { onCancel, isVisible, classes, handleSubmit } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open={isVisible}
      onClose={onCancel}
      classes={{ paper: classes.dialog }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <form onSubmit={handleSubmit}>
        <DialogTitle id="alert-dialog-title">{t('common:labels.selectHub')}</DialogTitle>

        <DialogContent className={classes.content}>
          <Field name="hub" component={Picker} label={'Hub'} values={HUBS} />
          <Button
            fullWidth
            className={classes.confirmButton}
            color="primary"
            size="large"
            label={t('auth:buttons.resetPassword')}
            type="submit"
          />
        </DialogContent>
      </form>
    </Dialog>
  );
};

Confirm.propTypes = {
  isVisible: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Confirm);
