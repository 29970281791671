import { QUIZ_MODAL_STYLES } from '../../../../../constants/quizModalStyles';

const styles = (theme) => ({
  ...QUIZ_MODAL_STYLES,
  progressBar: {
    position: 'relative',
    top: '-8px',
    marginBottom: '16px',
  },
  text: {
    display: 'block',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '21px',
    color: theme.palette.text.main,
  },
  highlighted: {
    display: 'inline',
    color: '#EB5757',
  },
});

export default styles;
