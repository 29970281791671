import { common } from '../common';
import { palette } from '../palette';

const OverridesMuiOutlinedInput = {
  styleOverrides: {
    root: {
      borderRadius: common.formControl.root.borderRadius,
      fontSize: common.formControl.root.fontSize,
      lineHeight: common.formControl.root.lineHeight,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: common.formControl.root.borderColor,
        color: common.formControl.root.color,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: common.formControl.hover.borderColor,
      },
      '&.Mui-disabled .MuiOutlinedInput-notchedOutline, &.Mui-disabled:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: common.formControl.disabled.borderColor,
      },
      '&.Mui-disabled .MuiInputAdornment-root': {
        color: common.formControl.disabled.color,
      },
      '&:hover .MuiInputAdornment-root': {
        borderColor: palette.text.main,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline, &.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
        color: common.formControl.error.color,
        borderColor: common.formControl.error.borderColor,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: common.formControl.focus.borderWidth,
        borderColor: common.formControl.focus.borderColor,
      },
    },
    input: {
      paddingTop: common.formControl.root.paddingTop,
      paddingBottom: common.formControl.root.paddingBottom,
      borderRadius: common.formControl.root.borderRadius, //override autocomplete style
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      color: common.formControl.root.color,
      '&::placeholder': {
        ...common.formControl.placeholder,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
      '&.Mui-disabled': {
        '-webkit-text-fill-color': common.formControl.disabled.color,
        color: common.formControl.disabled.color,
      },
    },
    multiline: {
      paddingTop: common.formControl.root.paddingTop,
      paddingBottom: common.formControl.root.paddingBottom,
    },
    inputMultiline: {
      whiteSpace: 'pre-wrap',
      textOverflow: 'initial',
      overflow: 'initial',
      borderRadius: 0,
      padding: 0,
    },
    inputSizeSmall: {
      ...common.formControl.smallSize,
    },
  },
};

export default OverridesMuiOutlinedInput;
