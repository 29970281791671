import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { change } from 'redux-form';

import * as actionCreators from '../actions';
import PaginationBar from '../components/PaginationBar';
import * as selectors from '../selectors';
import {
  PAGINATION_FORM,
  DEFAULT_PAGE_LIMIT,
  PAGINATION_DEFAULT_PAGE,
  PAGINATION_DEFAULT_SKIP,
} from '../constants';
import countSkip from '../helpers/countSkip';
import useSetCurrentPageUrl from '../hooks/useSetCurrentPageUrl';
import isPageExists from '../helpers/isPageExists';

const PaginationContainer = ({ onPageChange, totalItems }) => {
  const dispatch = useDispatch();
  const currentPage = useSelector(selectors.getCurrentPage);
  const routeParams = useParams();
  const urlPageNumber = routeParams.pageNumber;
  const setCurrentPageUrl = useSetCurrentPageUrl();
  const pagesNumber = useSelector(selectors.getPagesNumber);
  const selectedPageSize = useSelector(selectors.getSelectedPageSize);

  useEffect(() => {
    dispatch(change(PAGINATION_FORM, 'pageNumber', ''));
    dispatch(change(PAGINATION_FORM, 'pageSize', DEFAULT_PAGE_LIMIT));
  }, [dispatch]);

  useEffect(() => {
    const parsedPageNumber = Number.parseInt(urlPageNumber);
    if (
      (!urlPageNumber || !isPageExists(parsedPageNumber, pagesNumber)) &&
      currentPage &&
      currentPage !== parsedPageNumber
    ) {
      setCurrentPageUrl(currentPage);
    }
  }, [currentPage, urlPageNumber, setCurrentPageUrl, pagesNumber]);

  useEffect(() => {
    const parsedPageNumber = Number.parseInt(urlPageNumber);

    if (
      parsedPageNumber &&
      isPageExists(parsedPageNumber, pagesNumber) &&
      parsedPageNumber !== currentPage
    ) {
      dispatch(actionCreators.setCurrentPage(parsedPageNumber));
      const skip = countSkip(parsedPageNumber, selectedPageSize);
      onPageChange?.({ skip, limit: selectedPageSize });
    }
  }, [dispatch, urlPageNumber, onPageChange, currentPage, pagesNumber, selectedPageSize]);

  const validatePageNumber = ({ pageNumber }) => {
    let error;
    const parsedPageNumber = Number.parseInt(pageNumber);
    if (!parsedPageNumber) {
      error = 'Wrong page number';
    } else if (!isPageExists(parsedPageNumber, pagesNumber)) {
      error = "Page doesn't exist";
    }
    return { _error: error };
  };

  const onGoToPageSubmit = ({ pageNumber }) => {
    const pageNumberParsed = Number.parseInt(pageNumber);
    changePage(pageNumberParsed);
  };

  const changePage = (pageNumber) => {
    setCurrentPageUrl(pageNumber);
  };

  const onPageClick = (pageNumber) => {
    changePage(pageNumber);
    dispatch(change(PAGINATION_FORM, 'pageNumber', ''));
  };

  const onPageSizeChange = (pageSize) => {
    onPageChange?.({ skip: PAGINATION_DEFAULT_SKIP, limit: pageSize });
    changePage(PAGINATION_DEFAULT_PAGE);
    dispatch(change(PAGINATION_FORM, 'pageSize', pageSize));
    dispatch(change(PAGINATION_FORM, 'pageNumber', ''));
  };

  const props = {
    currentPage,
    onPageChange: onPageClick,
    pages: pagesNumber,
    form: PAGINATION_FORM,
    validate: validatePageNumber,
    onSubmit: onGoToPageSubmit,
    initialValues: {
      pageSize: DEFAULT_PAGE_LIMIT,
    },
    onPageSizeChange,
    totalItems,
  };
  return <PaginationBar {...props} />;
};

PaginationContainer.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  totalItems: PropTypes.number,
};

export default PaginationContainer;
