import React from 'react';
import PropTypes from 'prop-types';

const IndicatorSeparator = ({ innerProps }) => {
  return (
    <span
      style={{
        display: 'none',
      }}
      {...innerProps}
    />
  );
};

IndicatorSeparator.propTypes = {
  innerProps: PropTypes.object,
};

export default IndicatorSeparator;
