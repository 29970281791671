import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Typography } from '@material-ui/core';

import CustomLink from 'modules/common/CustomLink';
import { getFieldData } from './helpers';
import TableSortButton from '../TableSortButton';
import Spinner from '../../Spinner';

const getWidth = (width) => ({ width, minWidth: width });

const Compare = withStyles(styles)(({ classes, data, field, formatFunc, compareFormatFunc }) => {
  let value = get(data, `difference-${field}`, 0);
  const amount = get(data, field, 0);
  const sign = value < 0 ? '' : '+';
  const colorClass = value < 0 ? classes.diffNegative : classes.diffPositive;

  if (compareFormatFunc) {
    value = compareFormatFunc(value, amount);
  } else if (formatFunc) {
    value = formatFunc(value);
  }

  return (
    <span className={classes.diff}>
      <span className={colorClass}>
        {sign}
        {value}
      </span>
    </span>
  );
});

const TableTextField = ({
  classes,
  label,
  showLabel,
  dataLabel,
  dataIcon,
  tableItem,
  formatFunc,
  compareFormatFunc,
  route,
  getRedirectRoute,
  className,
  isLoading,
  sort,
  isHeadRow,
  width = 150,
}) => {
  const { fieldLabel, fieldValue } = useMemo(() => getFieldData('', dataLabel, tableItem), [
    dataLabel,
    tableItem,
  ]);

  if (showLabel && isHeadRow) {
    return (
      <div
        className={classNames([classes.item, classes.titleWrapper, className])}
        style={getWidth(width)}>
        <Typography className={classes.title}>{label}</Typography>
        {sort ? (
          <Typography className={classes.label}>
            <TableSortButton
              dataLabel={dataLabel}
              onSort={sort.onSort}
              isSorted={sort.isSorted}
              getSortDirection={sort.getSortDirection}
            />
          </Typography>
        ) : null}
      </div>
    );
  }

  return (
    <div className={classNames([classes.item, className])} style={getWidth(width)}>
      {showLabel ? (
        <Typography className={classNames(classes.title, classes.cellLabel)}>
          {fieldLabel}
        </Typography>
      ) : null}
      <div className={classes.dataText}>
        {dataIcon && get(tableItem, dataIcon) && (
          <div className={classes.dataIconContainer}>
            <img className={classes.dataIcon} alt="flag" src={get(tableItem, dataIcon)} />
          </div>
        )}
        {isLoading ? (
          <Spinner />
        ) : (
          <div>
            {route || getRedirectRoute ? (
              <CustomLink to={route || getRedirectRoute(tableItem)}>
                {formatFunc ? formatFunc(fieldValue) : get(tableItem, dataLabel)}
              </CustomLink>
            ) : formatFunc ? (
              fieldValue !== undefined && formatFunc(fieldValue)
            ) : (
              fieldValue !== undefined && fieldValue
            )}
            &nbsp;&shy;
            <div>
              <Compare
                data={tableItem}
                field={dataLabel}
                formatFunc={formatFunc}
                compareFormatFunc={compareFormatFunc}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

TableTextField.propTypes = {
  showLabel: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  dataLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  tableItem: PropTypes.object.isRequired,
  formatFunc: PropTypes.func,
  className: PropTypes.string,
  route: PropTypes.string,
  getRedirectRoute: PropTypes.func,
  width: PropTypes.number,
};

export default withStyles(styles)(TableTextField);
