import { combineActions, handleActions } from 'redux-actions';

import * as actionCreators from '../actions';
import formatPaymentOptions from '../../../helpers/formatPaymentOptions';
const defaultState = [];

export default handleActions(
  {
    [combineActions(actionCreators.getPaymentOptionsSuccess)](state, action) {
      return formatPaymentOptions(action.response.data.paymentOptions);
    },
  },
  defaultState,
);
