const checkIfTabExists = (tabPath, tabs, currentBaseUrl) => {
  let validTabPathWithoutPagination = tabPath.replace(/\/[0-9]+$/, '/');
  const tabPathParts = validTabPathWithoutPagination.split('/');
  let shortTabPath = tabPathParts.pop();
  if (!shortTabPath) {
    shortTabPath = tabPathParts.pop();
  }
  const isTabExist = tabs.find((tab) => {
    return tab.path.includes(shortTabPath);
  });
  if (!isTabExist) {
    validTabPathWithoutPagination = `${currentBaseUrl}/${tabs[0].path}`;
  }
  const validPathWithoutParams = validTabPathWithoutPagination.split(':')[0];
  return validPathWithoutParams;
};

export default checkIfTabExists;
