export const groupOptionsByKey = ({ options, key }) => {
  return Object.entries(
    options?.reduce((groups, option) => {
      if (!option[key]) return groups;
      if (!groups[option[key]]) {
        groups[option[key]] = [];
      }
      groups[option[key]].push(option);
      return groups;
    }, {}),
  ).map(([key, value]) => ({ label: key, options: value }));
};
