import { combineReducers } from 'redux';

import token from './token';
import user from './user';
import referral from './referral';
import refreshToken from './refreshToken';
import proxyToken from './proxyToken';
import isAuthenticated from './isAuthenticated';
import isImpersonation from './isImpersonation';
import isFirstVisit from './isFirstVisit';
import hideMfaToken from './hideMfaToken';
import migration from '../pages/Migration/reducers';
import signIn from '../pages/SignIn/reducers';
import signUp from '../pages/SignUp/reducers';
import mfa from '../components/MfaPanel/reducers';
import forgotPassword from '../pages/ForgotPassword/reducers';

const reducer = combineReducers({
  isImpersonation,
  isAuthenticated,
  user,
  referral,
  token,
  refreshToken,
  proxyToken,
  hideMfaToken,
  isFirstVisit,
  migration,
  signIn,
  signUp,
  mfa,
  forgotPassword,
});

export default reducer;
