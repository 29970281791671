import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = [];

export default handleActions(
  {
    [actionCreators.getSwapsSuccess](state, action) {
      return action.response.data.swaps;
    },
    [actionCreators.getSwapsFail]() {
      return defaultState;
    },
  },
  defaultState,
);
