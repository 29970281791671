const styles = (theme) => ({
  rejectionModal: {
    paddingLeft: '57px',
  },
  title: {
    color: theme.palette.text.main,
    fontSize: '28px',
    fontWeight: '300',
    lineHeight: '34px',
    marginBottom: '13px',
  },
  contentHeader: {
    color: theme.palette.text.main,
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: '20px',
    width: '421px',
  },
  applicationLink: {
    color: theme.palette.moodyBlue,
    textDecoration: 'none',
    fontWeight: '400',
  },
  sectionInfo: {
    marginBottom: '25px',
  },
  sectionButtons: {
    textAlign: 'right',
  },

  button: {
    width: '159px',
  },
  contentMessage: {
    marginTop: '6px',
    color: theme.palette.text.main,
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: '20px',
    width: '421px',
  },
});

export default styles;
