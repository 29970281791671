import api from 'modules/api/index';

export const addLeadManagerRequest = (payload) => {
  return api.post('/lead-manager', payload);
};

export const updateLeadManagerRequest = (payload) => {
  return api.patch('/lead-manager', payload);
};

export const deleteLeadManagerRequest = (payload) => {
  const { userId } = payload;
  return api.delete(`/lead-manager/${userId}`, payload);
};
