import api from '..';

export const riskGroupGetCountriesRequest = () => {
  return api.get('/risk-group/country');
};

export const riskGroupAddCountryRequest = ({ countryCode }) => {
  return api.post('/risk-group/country', { countryCode });
};

export const riskGroupRemoveCountryRequest = ({ countryCode }) => {
  return api.delete(`/risk-group/country/${countryCode}`);
};
