import React from 'react';
import { Field, reduxForm } from 'redux-form';

import TPGrid from 'components/TP-UI/TPGrid';
import TPTinyEditor from 'components/TP-UI/TPTinyEditor';
import TPTypography from 'components/TP-UI/TPTypography';
import { UPLOAD_EMAIL_FILE_ROUTE } from 'constants/index';
import { required } from '../../../utils/validation/fieldValidationRules';

const TinyEditor = () => {
  return (
    <form>
      <TPGrid container spacing={2}>
        <TPGrid item xs={12}>
          <TPTypography variant="h6" mb={1}>
            No label, default size
          </TPTypography>
          <Field
            component={TPTinyEditor}
            initialValue={'initial value'}
            name="message"
            filesUploadUrl={UPLOAD_EMAIL_FILE_ROUTE}
            onBlur={(e) => {
              console.log('tiny editor blurred:', e);
            }}
            onChange={(e) => {
              console.log('tiny editor changed:', e);
            }}
          />
        </TPGrid>
        <TPGrid item xs={6}>
          <TPTypography variant="h6" mb={1}>
            Error, default size
          </TPTypography>
          <Field
            component={TPTinyEditor}
            initialValue={'initial value'}
            name="message2"
            label="Message"
            filesUploadUrl={UPLOAD_EMAIL_FILE_ROUTE}
            validate={[required]}
            required
            hint="Additional info"
            meta={{ error: { key: 'common:errors.form.isRequired' }, submitFailed: true }}
            onBlur={(e) => {
              console.log('tiny editor blurred:', e);
            }}
            onChange={(e) => {
              console.log('tiny editor changed:', e);
            }}
          />
        </TPGrid>
        <TPGrid item xs={6}>
          <TPTypography variant="h6" mb={1}>
            Label, default size
          </TPTypography>
          <Field
            component={TPTinyEditor}
            initialValue={'initial value'}
            name="message3"
            label="Label"
            filesUploadUrl={UPLOAD_EMAIL_FILE_ROUTE}
            onBlur={(e) => {
              console.log('tiny editor blurred:', e);
            }}
            onChange={(e) => {
              console.log('tiny editor changed:', e);
            }}
          />
        </TPGrid>
        <TPGrid item xs={6}>
          <TPTypography variant="h6" mb={1}>
            Label, default size, disabled
          </TPTypography>
          <Field
            component={TPTinyEditor}
            initialValue={'initial value'}
            name="message3"
            label="Label"
            disabled
            filesUploadUrl={UPLOAD_EMAIL_FILE_ROUTE}
            onBlur={(e) => {
              console.log('tiny editor blurred:', e);
            }}
            onChange={(e) => {
              console.log('tiny editor changed:', e);
            }}
          />
        </TPGrid>
        <TPGrid item xs={12}>
          <TPTypography variant="h6" mb={1}>
            Full width
          </TPTypography>
          <Field
            fullWidth
            component={TPTinyEditor}
            initialValue={'initial value'}
            name="message4"
            filesUploadUrl={UPLOAD_EMAIL_FILE_ROUTE}
            onBlur={(e) => {
              console.log('tiny editor blurred:', e);
            }}
            onChange={(e) => {
              console.log('tiny editor changed:', e);
            }}
          />
        </TPGrid>
      </TPGrid>
    </form>
  );
};
export default reduxForm({
  form: 'TP-TINYEDITOR-FORM',
  initialValues: {
    message: '<p>Lorem <b>ipsum</b> dolor sit amet</p>',
    message2: '',
    message3: '<p>Hello</p>',
    message4: '',
  },
})(TinyEditor);
