import normal from './normal';
import dark from './dark';
import { THEME_NAME } from '../../../constants/theme';

const themes = {
  [THEME_NAME.NORMAL]: normal,
  [THEME_NAME.DARK]: dark,
};

export default function getTheme(theme) {
  return themes[theme];
}
