import { createAction } from 'redux-actions';

export const getBlockedUsersRequest = createAction('GET_BLOCKED_USERS_REQUEST');
export const getBlockedUsersSuccess = createAction('GET_BLOCKED_USERS_SUCCESS');
export const getBlockedUsersFail = createAction('GET_BLOCKED_USERS_FAIL');

export const unlockUser = createAction('BLOCKED_USERS_UNLOCK_USER');
export const unlockUserSuccess = createAction('BLOCKED_USERS_UNLOCK_USER_SUCCESS');
export const unlockUserFail = createAction('BLOCKED_USERS_UNLOCK_USER_FAIL');

export const updateBlockedUsers = createAction('BLOCKED_USERS_UPDATE_USERS');
