import { createSelector } from 'reselect';
import {
  CURRENCY_FORMAT_METRICS,
  DASHBOARD_METRICS_PRIORITY,
  DASHBOARD_METRICS_TO_EXCLUDE,
  DASHBOARD_OVERVIEW_DATA_HEADER_FORM,
  DASHBOARD_OVERVIEW_DATA_LABEL,
} from '../constants';
import get from 'lodash/get';
import { formSelector } from 'selectors';
import formatPrice from 'utils/formatPrice';

const getDashboardData = (state) => state.dashboard.dashboardOverview;

export const getDashboardStats = createSelector(getDashboardData, (data) => data.stats);
export const getDashboardMetrics = createSelector(getDashboardData, (data) => data.metrics);
export const getDashboardGraphData = createSelector(getDashboardData, (data) => data.graphData);
export const getDashboardComparisonGraphData = createSelector(
  getDashboardData,
  (data) => data.comparisonGraphData,
);
export const getActiveUserGraphData = createSelector(
  getDashboardData,
  (data) => data.activeUsersGraphData,
);
export const getCountryUsers = (state) => state.dashboard.countryUsers || {};
export const getUserVolumes = (state) => state.dashboard.volumeUsers || {};
export const getMarketingChannelData = (state) =>
  state.dashboard.marketingChannelData.marketingData || [];
export const getMarketingSources = (state) => state.dashboard.marketingChannelData.sources || [];

const getDiff = (metrics) => (key) => {
  if (`${key}Diff` in metrics) {
    return CURRENCY_FORMAT_METRICS.includes(key)
      ? formatPrice(metrics[`${key}Diff`])
      : metrics[`${key}Diff`];
  }

  return null;
};

const getIncome = (metrics) => (key) => {
  if (`${key}Diff` in metrics) {
    return metrics[`${key}Income`];
  }
  return null;
};

export const getDashboardOverviewData = createSelector(
  getDashboardMetrics,
  getDashboardStats,
  (metrics, stats) => {
    if (metrics) {
      const getMetricDiff = getDiff(metrics);
      const getMetricIncome = getIncome(metrics);

      return Object.keys(metrics)
        .filter((i) => stats[i] === true)
        .sort((a, b) => DASHBOARD_METRICS_PRIORITY[a] - DASHBOARD_METRICS_PRIORITY[b])
        .map((key) => ({
          label: get(DASHBOARD_OVERVIEW_DATA_LABEL, key, ''),
          value: CURRENCY_FORMAT_METRICS.includes(key) ? formatPrice(metrics[key]) : metrics[key],
          numValue: Number(metrics[key]),
          diff: getMetricDiff(key),
          income: getMetricIncome(key),
        }));
    }
    return null;
  },
);

export const getDashboardDateFrom = (state) =>
  formSelector(DASHBOARD_OVERVIEW_DATA_HEADER_FORM, state, 'dateFrom');

export const getDashboardDateTo = (state) =>
  formSelector(DASHBOARD_OVERVIEW_DATA_HEADER_FORM, state, 'dateTo');

export const getDashboardShouldCompareTo = (state) =>
  formSelector(DASHBOARD_OVERVIEW_DATA_HEADER_FORM, state, 'shouldCompare');

export const getDashboardDateFromCompare = (state) =>
  formSelector(DASHBOARD_OVERVIEW_DATA_HEADER_FORM, state, 'dateFromCompare');

export const getDashboardDateToCompare = (state) =>
  formSelector(DASHBOARD_OVERVIEW_DATA_HEADER_FORM, state, 'dateToCompare');

const getGraphDatasets = (data, stats) => {
  return data.reduce((result, item) => {
    const { day, ...metrics } = item;

    Object.keys(metrics)
      .filter((i) => stats[i] === true)
      .sort((a, b) => DASHBOARD_METRICS_PRIORITY[a] - DASHBOARD_METRICS_PRIORITY[b])
      .forEach((key) => {
        if (!DASHBOARD_METRICS_TO_EXCLUDE.includes(key)) {
          if (key in result) {
            result[key].push({ x: day, y: metrics[key] });
          } else {
            result[key] = [{ x: day, y: metrics[key] }];
          }
        }
      });

    return result;
  }, {});
};

export const getDashboardGraphDatasets = createSelector(
  getDashboardGraphData,
  getDashboardStats,
  getGraphDatasets,
);

export const getDashboardComparisonGraphDatasets = createSelector(
  getDashboardComparisonGraphData,
  getDashboardStats,
  getGraphDatasets,
);

export const getActiveUsersGraphDatasets = createSelector(getActiveUserGraphData, (graphData) =>
  (graphData || []).reduce((result, item) => {
    const { day, ...metrics } = item;

    Object.keys(metrics).forEach((key) => {
      if (key in result) {
        result[key].push({ x: day, y: metrics[key] });
      } else {
        result[key] = [{ x: day, y: metrics[key] }];
      }
    });

    return result;
  }, {}),
);

export const getNetDeposit = (state) => state.dashboard.netDeposit || [];

export const getEmployees = (state) => state.dashboard.employees || [];

export const getLeadOwners = (state) => state.dashboard.leadOwners || [];
