import { createAction } from 'redux-actions';

export const getRecentEntitiesRequest = createAction('GET_RECENT_ENTITIES_REQUEST');
export const getRecentEntitiesSuccess = createAction('GET_RECENT_ENTITIES_SUCCESS');
export const getRecentEntitiesFail = createAction('GET_RECENT_ENTITIES_FAIL');

export const getDashboardDataRequest = createAction('GET_DASHBOARD_DATA_REQUEST');
export const getDashboardDataSuccess = createAction('GET_DASHBOARD_DATA_SUCCESS');
export const getDashboardDataFail = createAction('GET_DASHBOARD_DATA_FAIL');

export const setRecentEntities = createAction('SET_RECENT_ENTITIES');

export const getArticleContentRequest = createAction('GET_ARTICLE_CONTENT_REQUEST');
export const getArticleContentSuccess = createAction('GET_ARTICLE_CONTENT_SUCCESS');
export const getArticleContentFail = createAction('GET_ARTICLE_CONTENT_FAIL');

export const getDiscoverEntitiesRequest = createAction('GET_DISCOVER_ENTITIES_REQUEST');
export const getDiscoverEntitiesSuccess = createAction('GET_DISCOVER_ENTITIES_SUCCESS');
export const getDiscoverEntitiesFail = createAction('GET_DISCOVER_ENTITIES_FAIL');

export const navigateToDashboard = createAction('DISCOVER_NAVIGATE_TO_DASHBOARD');
