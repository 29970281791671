const styles = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  badge: {
    position: 'relative',
    color: '#FFFFFF',
    backgroundColor: theme.palette.bageNotification,
    fontSize: '10px',
    fontWeight: '700',
    borderRadius: '11px',
    padding: '0px 6px',
    minWidth: '22px',
    height: '22px',
    transform: 'unset',
    top: 'unset',
    left: 'unset',
    right: 'unset',
    bottom: 'unset',
    transformOrigin: 'unset',
  },
});

export default styles;
