const styles = () => ({
  item: {
    '@media (max-width:1280px)': {
      width: '90px',
      padding: '30px 20px 26px 10px',
    },
    '@media (min-width:1280px)': {
      padding: '30px 30px 26px 10px',
      width: '100px',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    height: '34px',
    borderRadius: '4px',
    fontSize: '12px',
    fontWeight: '900',
    lineHeight: '15px',
    whiteSpace: 'nowrap',
  },
  button__large: {
    width: '116px',
  },
  button__small: {
    width: '50px',
  },
  formControlLabel: {
    alignItems: 'flex-start',
  },
  checkbox: {
    padding: '2px 12px',
  },
  label: {
    fontSize: 16,
    fontWeight: '300',
    lineHeight: '20px',
  },
  disabled: {},
  checkboxChecked: {},
  icon: {
    fontSize: 19,
  },
});

export default styles;
