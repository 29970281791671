import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../actions';
import * as selectors from '../selectors';
import NotificationsBell from '../components/NotificationsBell';
import { push } from 'connected-react-router';
import { Routes } from 'constants/routeConstants';

const UserNotificationsContainer = () => {
  const dispatch = useDispatch();
  const notificationsCount = useSelector(selectors.getPendingNotificationsCount);

  useEffect(() => {
    dispatch(actions.getPendingNotificationsCountRequest());
  }, [dispatch]);

  const getUserNotificationsOpen = useCallback(() => {
    dispatch(push(Routes.VERIFICATION_MANAGEMENT));
  }, [dispatch]);

  const props = {
    getUserNotificationsOpen,
    notificationsCount,
  };
  return <NotificationsBell {...props} />;
};

export default UserNotificationsContainer;
