const styles = (theme) => ({
  label: {
    color: theme.palette.simpleGrey,
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '16px',
  },
  dataText: {
    color: '#36425A',
    fontSize: 14,
    fontWeight: 300,
    lineHeight: '19px',
    marginTop: 6,
  },
  item: {
    padding: '22px 10px',
    height: 80,
    '@media (min-width:400px)': {
      height: 'auto',
    },
  },
  boldText: {
    fontWeight: 700,
  },
});

export default styles;
