function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${color} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${color} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${color} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${color}`,
      },
    },
  };
}

export default (theme) => ({
  tooltip: {
    backgroundColor: theme.tooltip.backgroundColor,
    color: theme.tooltip.color,
    boxShadow: '0 4px 10px 0 rgba(73,93,124,0.13)',
    borderRadius: 8,
    padding: '28px 24px',
    fontSize: 14,
    fontWeight: 300,
    textAlign: 'left',
    width: 300,
    opacity: 0,
  },
  arrow: {
    position: 'absolute',
    fontSize: 6,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  arrowPopper: arrowGenerator('#ffffff'),
  link: {
    borderBottom: `1px ${theme.palette.icon.black} dashed`,
    color: theme.palette.textColor,
    fontWeight: 700,

    fontSize: 14,
    '&:hover': {
      color: '#2727C5',
      textDecoration: 'none',
    },
  },
  label: {
    cursor: 'pointer',
    color: theme.palette.textColor,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: 7,
    },
    '& > a': {
      border: 0,
      marginRight: 6,
    },
  },
});
