import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';
import { DIALOG_NAME } from '../constants';
const defaultState = {
  rate: '',
  history: [],
  activeDialog: '',
  submitValues: {},
};

export default handleActions(
  {
    [actionCreators.transferPageOpened]() {
      return {
        ...defaultState,
      };
    },
    [actionCreators.transferAccountsShowConfirm](state, action) {
      return {
        ...state,
        activeDialog: DIALOG_NAME.CONFIRM_TRANSFER,
        submitValues: action.payload,
      };
    },
    [actionCreators.transferAccountsHideDialog](state) {
      return {
        ...state,
        activeDialog: '',
      };
    },

    [actionCreators.getConversionRateSuccess](state, action) {
      return {
        ...state,
        rate: action.response.data.rate,
      };
    },

    [actionCreators.createTransferSuccess]() {
      return {
        ...defaultState,
      };
    },
    [actionCreators.searchTransferHistorySuccess](state, action) {
      return {
        ...state,
        history: action.response.data,
      };
    },
  },
  defaultState,
);
