import { handleActions, combineActions } from 'redux-actions';

import * as signUpActionCreators from '../pages/SignUp/actions';
import * as actionCreators from '../actions';

export default handleActions(
  {
    [combineActions(signUpActionCreators.signupSuccess, signUpActionCreators.googleAuthSuccess)]() {
      return true;
    },
    [actionCreators.firstVisitFulfilled]() {
      return false;
    },
  },
  false,
);
