export const VerificationStatuses = {
  NEW: 'new',
  EDITING: {
    LEAD1: 'lead1',
    LEAD2: 'lead2',
    LEAD3: 'lead3',
    LEAD4: 'Lead4',
  },
  POSTED: 'posted',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  FUNDED: 'funded',
  ACTIVE: 'active',
  DORMANT: 'dormant',
  AUTOMATIC_APPROVEMENT: 'sumsub',
};
