import createAction from 'redux-actions/es/createAction';

export const forgotPasswordRequestSend = createAction('FORGOT_PASSWORD_REQUEST_SEND');
export const forgotPasswordRequest = createAction('FORGOT_PASSWORD_REQUEST');
export const forgotPasswordSuccess = createAction('FORGOT_PASSWORD_SUCCESS');
export const forgotPasswordFail = createAction('FORGOT_PASSWORD_FAIL');

export const signinRequest = createAction('SIGNIN_REQUEST');
export const signinSuccess = createAction('SIGNIN_SUCCESS');
export const signinFail = createAction('SIGNIN_FAIL');

export const signinNonfxSuccess = createAction('SIGNIN_NONFX_SUCCESS');

export const preInsertLogin = createAction('PRE_INSERT_LOGIN');

export const setDestinationHub = createAction('SET_DESTINATION_HUB');

export const updateToken = createAction('SIGNIN_UPDATE_TOKEN');

export const setHub = createAction('SET_HUB');
export const selectHub = createAction('SELECT_HUB');
export const setBlockedHubs = createAction('SET_BLOCKED_HUBS');

export const redirectToAfterprime = createAction('REDIRECT_TO_AFTERPRIME');
export const redirectToSignUp = createAction('REDIRECT_TO_SIGN_UP');
export const redirectToSignIn = createAction('REDIRECT_TO_SIGN_IN');

export const showCaptchaToken = createAction('SHOW_CAPTCHA_TOKEN');
