import React from 'react';

import TPTypography from 'components/TP-UI/TPTypography';
import { MAIN_COLORS } from 'components/TP-UI/constants';

const TPCardDescription = ({
  variant = 'body2',
  color = MAIN_COLORS.SECONDARY,
  className,
  children,
  ...props
}) => {
  return (
    <TPTypography variant={variant} color={color} className={className} {...props}>
      {children}
    </TPTypography>
  );
};

export default TPCardDescription;
