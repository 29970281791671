import { memo } from 'react';
import CircularProgress from 'material-latest/CircularProgress';

import { palette } from '../../../components/CustomThemeProvider/themes/palette';

const Spinner = () => {
  return <CircularProgress sx={{ color: palette.green2 }} />;
};

export default memo(Spinner);
