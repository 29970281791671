import { handleActions } from 'redux-actions';
import * as actions from '../actions';

const defaultState = {
  isUpdating: false,
};
export default handleActions(
  {
    [actions.updateCustomFieldsUserRequest]: (state) => {
      return { ...state, isUpdating: true };
    },
    [actions.updateCustomFieldsUserSuccess]: (state) => {
      return { ...state, isUpdating: false };
    },
  },
  defaultState,
);
