import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import NotificationItem from '../NotificationItem';

import styles from './styles';

const Notifications = (props) => {
  const { classes, notifications, onRemoveNotification, className } = props;

  return (
    <div className={classNames(classes.root, className)}>
      {notifications.map((notification) => (
        <NotificationItem
          key={notification.id}
          notification={notification}
          onClick={() => onRemoveNotification(notification.id)}
        />
      ))}
    </div>
  );
};

Notifications.propTypes = {
  notifications: PropTypes.array.isRequired,
  onRemoveNotification: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(Notifications);
