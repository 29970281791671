export const COLOR_TYPES = {
  primary: 'text.dark',
  secondary: 'textSecondary.dark',
  error: 'error.dark',
  highlight: 'primary.dark',
};

export const variantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'h6',
  subtitle2: 'h6',
  body1: 'p',
  body2: 'p',
  caption: 'span',
  button: 'span',
};

export const inherit = 'inherit';
