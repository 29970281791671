import api from '..';

export const migrateUserRequest = () => {
  return api.post('/migration/migrate-account');
};

export const requestMigrateUserRequest = () => {
  return api.post('/migration/migrate-account/request');
};

export const getPendingMigrationsRequest = (payload) => {
  const cleanedPayload = { ...payload };
  delete cleanedPayload.persistStoreData;

  return api.get('/migration/migrate-account/request', { params: cleanedPayload });
};

export const approveMigrationsRequest = ({ _id }) => {
  return api.post(`/migration/migrate-account/request/${_id}/approve`);
};

export const declineMigrationsRequest = ({ _id, message }) => {
  return api.post(`/migration/migrate-account/request/${_id}/decline`, { message });
};
