import api from 'modules/api';
import getFormData from './getFormData';

export const getEvidenceStatementRequest = () => {
  return api.get('/trading-statement-evidence');
};

export const createEvidenceStatementRequest = (payload) => {
  const formData = getFormData(payload);
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  return api.post('/trading-statement-evidence', formData, headers);
};

export const updateEvidenceStatementRequest = (payload) => {
  const formData = getFormData(payload);
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  return api.patch('/trading-statement-evidence', formData, headers);
};

export const getPendingExperienceUploadsRequest = (payload) => {
  return api.get('/trading-statement-evidence/pending', { params: payload });
};
