import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'material-latest';

import TPImage from 'components/TP-UI/TPImage';
import TPTypography from 'components/TP-UI/TPTypography';
import { TPModal } from 'components/TP-UI/TPModal';
import { VIDEO_SETTINGS, WEBCAM_STATE, WEBCAM_STATE_DEFAULT_MESSAGE } from './constants';

import styles from './styles';

const TPTakePhotoModal = ({
  value,
  onTakePhoto,
  onClose,
  webcamStatusMessages,
  title = 'Take a Photo',
  captureButtonLabel = 'Capture',
  cancelButtonLabel = 'Cancel',
  okButtonLabel = 'Got It!',
}) => {
  const playerRef = useRef();
  const [imageDataURL, setImageDataUrl] = useState(null);
  const [cameraAllowed, setCameraAllowed] = useState(false);
  const [webcamState, setWebcamState] = useState(WEBCAM_STATE.WAIT);
  const handleCaptureClick = useCallback(() => {
    let canvas = document.createElement('canvas');
    canvas.width = playerRef.current.videoWidth;
    canvas.height = playerRef.current.videoHeight;
    let contex = canvas.getContext('2d');
    contex.drawImage(playerRef.current, 0, 0, canvas.width, canvas.height);
    playerRef.current.srcObject.getVideoTracks().forEach((track) => {
      track.stop();
    });

    canvas.toBlob((blob) => {
      const date = new Date().getTime();
      const file = new File([blob], `image${date}.png`, { type: 'image/png' });
      onTakePhoto(file);
    });
  }, [onTakePhoto]);
  const stopTracking = useCallback(() => {
    if (playerRef?.current?.srcObject) {
      playerRef.current.srcObject.getVideoTracks().forEach((track) => {
        track.stop();
      });
    }
  }, []);
  const handelClose = useCallback(() => {
    stopTracking();
    if (onClose) {
      onClose();
    }
  }, [stopTracking, onClose]);
  const buttons = useMemo(() => {
    const canTakePhoto = webcamState === WEBCAM_STATE.WAIT || webcamState === WEBCAM_STATE.GRANTED;
    return [
      {
        label: canTakePhoto ? captureButtonLabel : okButtonLabel,
        primary: true,
        disabled: webcamState === WEBCAM_STATE.WAIT,
        onClick: canTakePhoto ? handleCaptureClick : handelClose,
      },
      ...(canTakePhoto
        ? [
            {
              label: cancelButtonLabel,
              secondary: true,
              onClick: handelClose,
            },
          ]
        : []),
    ];
  }, [
    captureButtonLabel,
    okButtonLabel,
    cancelButtonLabel,
    handelClose,
    handleCaptureClick,
    webcamState,
  ]);
  const messages = useMemo(
    () => Object.assign({}, WEBCAM_STATE_DEFAULT_MESSAGE, webcamStatusMessages),
    [webcamStatusMessages],
  );

  const initializeMedia = useCallback(async () => {
    setImageDataUrl(null);

    if (!('mediaDevices' in navigator)) {
      navigator.mediaDevices = {};
    }

    if (!('getUserMedia' in navigator.mediaDevices)) {
      navigator.mediaDevices.getUserMedia = function(constraints) {
        let getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

        if (!getUserMedia) {
          return Promise.reject(new Error('getUserMedia Not Implemented'));
        }

        return new Promise((resolve, reject) => {
          getUserMedia.call(navigator, constraints, resolve, reject);
        });
      };
    }
    const enumerateDevices = await navigator.mediaDevices.enumerateDevices();
    const videoInputs = enumerateDevices.filter((device) => device.kind === 'videoinput');

    if (videoInputs.length) {
      const params = {
        video: VIDEO_SETTINGS,
      };
      if (videoInputs[0].deviceId) {
        params.deviceId = {
          exact: videoInputs[0].deviceId,
        };
      }
      setWebcamState(WEBCAM_STATE.WAIT);

      navigator.mediaDevices
        .getUserMedia(params)
        .then((stream) => {
          setWebcamState(WEBCAM_STATE.GRANTED);
          setCameraAllowed(true);
          playerRef.current.srcObject = stream;
        })
        .catch((error) => {
          setWebcamState(WEBCAM_STATE.DENIED);
          setCameraAllowed(false);
          console.error(error);
        });
    } else {
      setWebcamState(WEBCAM_STATE.NO_CAMERA);
    }
  }, []);

  useEffect(() => {
    if (value) {
      initializeMedia();
    }
    return () => {
      stopTracking();
    };
  }, [value, stopTracking, initializeMedia]);

  return (
    <TPModal title={title} value={value} onClose={onClose} buttons={buttons}>
      <Box sx={styles.root}>
        {imageDataURL ? (
          <TPImage src={imageDataURL} alt="cameraPic" />
        ) : cameraAllowed ? (
          <video width="100%" ref={playerRef} autoPlay></video>
        ) : (
          <TPTypography>{messages[webcamState]}</TPTypography>
        )}
      </Box>
    </TPModal>
  );
};

TPTakePhotoModal.propTypes = {
  value: PropTypes.bool.isRequired,
  onTakePhoto: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  webcamStatusMessages: PropTypes.shape({
    denied: PropTypes.string,
    granted: PropTypes.string,
    wait: PropTypes.string,
    noCamera: PropTypes.string,
  }),
  title: PropTypes.string,
  captureButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  okButtonLabel: PropTypes.string,
};

export default TPTakePhotoModal;
