import {
  DASHBOARD_OVERVIEW_DATA_LABEL,
  DASHBOARD_STATS_CUSTOMIZATION_MODAL,
} from '../../constants';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Typography } from '@material-ui/core';
import CustomModal from '../../../common/CustomModal';
import Checkbox from '../../../common/Checkbox';
import React, { useCallback, useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import PrimaryButton from '../../../common/PrimaryButton';
import { Field, reduxForm } from 'redux-form';
import FormSelect from 'modules/common/FormSelect';
import { getFullName } from 'utils/getFullName';

const StatsCustomizationModal = ({
  classes,
  toggleModal,
  handleSubmit,
  isGlobalAdmin,
  leadOwners,
}) => {
  const handleSave = useCallback(
    (data) => {
      toggleModal();
      handleSubmit(data);
    },
    [handleSubmit, toggleModal],
  );

  const leadOwnersOptions = useMemo(
    () => [
      {
        value: '',
        label: 'All Owners',
      },
      ...leadOwners.map((user) => ({ label: getFullName(user), value: user.email })),
    ],
    [leadOwners],
  );

  return (
    <CustomModal className={classes.root} modalName={DASHBOARD_STATS_CUSTOMIZATION_MODAL}>
      <form onSubmit={handleSave}>
        <div className={classes.header}>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={toggleModal}
            color="primary">
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title}>Dashboard Stats Customization</Typography>
        </div>
        <section>
          {Object.keys(DASHBOARD_OVERVIEW_DATA_LABEL).map(function(key) {
            return key === 'leadOwnerFilter' ? (
              <Field
                disabled={!isGlobalAdmin}
                key={key}
                name="asUser"
                id="asUser"
                component={FormSelect}
                items={leadOwnersOptions}
                multiple={false}
              />
            ) : (
              <Field
                key={key}
                name={key}
                component={Checkbox}
                label={DASHBOARD_OVERVIEW_DATA_LABEL[key]}
              />
            );
          })}
        </section>
        <div className={classes.buttonsSection}>
          <PrimaryButton
            size="large"
            onClick={toggleModal}
            variant="text"
            className={classes.cancelButton}
            label="Cancel"
          />
          <PrimaryButton color="primary" size="large" label="Save" type="submit" />
        </div>
      </form>
    </CustomModal>
  );
};

const StatsCustomizationModalStyled = withStyles(styles)(StatsCustomizationModal);

export default reduxForm()(StatsCustomizationModalStyled);
