import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import TableMessage from '../../../TableMessage';
import TableHeader from '../TableHeader';
import TableList from '../../containers/TableListContainer';
import PaginationBar from 'modules/common/PaginationBar';
import Spinner from '../../../../Spinner';

const Table = ({
  classes,
  tableHeader,
  tableFields,
  data,
  isVerificationHistoryTable,
  tableMessageProps,
  isDataLoading,
  minHeight,
  maxHeight,
  paginationProps,
  generateTableItemId,
  onClick,
}) => {
  const tableProps = {
    tableFields,
    minHeight,
    maxHeight,
    data,
    generateTableItemId,
    onClick,
    isVerificationHistoryTable,
  };
  return (
    <div className={classes.root}>
      <div className={classes.tableRoot}>
        <div className={classes.container}>
          {isDataLoading && (
            <div className={classes.loaderContainer}>
              <Spinner />
            </div>
          )}
          {tableHeader && <TableHeader {...tableHeader} />}
          {data.length === 0 && !isDataLoading ? (
            <TableMessage {...tableMessageProps} />
          ) : (
            <TableList {...tableProps} />
          )}
        </div>
      </div>
      {paginationProps && (
        <div className={classes.paginationContainer}>
          <PaginationBar {...paginationProps} />
        </div>
      )}
    </div>
  );
};

Table.propTypes = {
  tableHeader: PropTypes.object,
  tableFields: PropTypes.array.isRequired,
  tableMessageProps: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  isDataLoading: PropTypes.bool.isRequired,
  minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  paginationProps: PropTypes.shape({
    onPageChange: PropTypes.func.isRequired,
    totalItems: PropTypes.number,
  }),
  generateTableItemId: PropTypes.func,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
};

export default withStyles(styles)(Table);
