import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import get from 'lodash/get';

import { createPendingSelector } from 'modules/api/selectors';

import { marginCalculatorRequest } from '../actions';
import { MARGIN_CALCULATOR_FORM, MAP_MARGIN_STYLE_TO_DEFAULT_LEVERAGE_VALUE } from '../constants';
import {
  getCurrencySpecs,
  getPreparedMarginResults,
  getCurrencyPairMarginStyle,
  getIsLeverageDisabled,
  marginFormSelector,
  getContractSizes,
} from '../selectors';

import MarginCalculatorForm from '../components/MarginCalculatorForm';

const MarginCalculatorFormContainer = ({ symbols }) => {
  const dispatch = useDispatch();
  const isPending = useSelector(createPendingSelector(marginCalculatorRequest));
  const currencySpecs = useSelector(getCurrencySpecs);
  const results = useSelector(getPreparedMarginResults);
  const isLeverageDisabled = useSelector(getIsLeverageDisabled);
  const currencyPairMarginStyle = useSelector(getCurrencyPairMarginStyle);
  const currencyPair = useSelector((state) => marginFormSelector(state, 'symbol'));
  const contractSizes = useSelector(getContractSizes);

  const currencySpec = useMemo(() => {
    return get(currencySpecs, get(currencyPair, 'value', currencyPair), {});
  }, [currencySpecs, currencyPair]);

  useEffect(() => {
    if (currencySpec.contractSize) {
      dispatch(change(MARGIN_CALCULATOR_FORM, 'contractSize', currencySpec.contractSize));
    }
  }, [currencySpec.contractSize, dispatch]);

  useEffect(() => {
    if (isLeverageDisabled) {
      dispatch(
        change(
          MARGIN_CALCULATOR_FORM,
          'leverage',
          MAP_MARGIN_STYLE_TO_DEFAULT_LEVERAGE_VALUE[currencyPairMarginStyle],
        ),
      );
    }
  }, [dispatch, isLeverageDisabled, currencyPairMarginStyle]);

  const handleSubmit = useCallback(
    ({ symbol, baseCurrency, contractSize, volumeInLots, leverage, accountType }) => {
      dispatch(
        marginCalculatorRequest({
          symbol: symbol.value,
          baseCurrency,
          volumeInLots,
          leverage,
          contractSize,
          marginStyle: currencySpec.marginStyle,
          accountType,
        }),
      );
    },
    [dispatch, currencySpec.marginStyle],
  );

  return (
    <MarginCalculatorForm
      isPending={isPending}
      isLeverageDisabled={isLeverageDisabled}
      form={MARGIN_CALCULATOR_FORM}
      symbols={symbols}
      contractSizes={contractSizes}
      results={results}
      onSubmit={handleSubmit}
    />
  );
};

export default memo(MarginCalculatorFormContainer);
