import { createAction } from 'redux-actions';

export const getUserRequest = createAction('GET_USER_REQUEST');
export const getUserSuccess = createAction('GET_USER_SUCCESS');
export const getUserFail = createAction('GET_USER_FAIL');

export const updateUserDetailsRequest = createAction('UPDATE_USER_DETAILS_REQUEST');
export const updateUserDetailsSuccess = createAction('UPDATE_USER_DETAILS_SUCCESS');
export const updateUserDetailsFail = createAction('UPDATE_USER_DETAILS_FAIL');

export const getUserPhoneNumberRequest = createAction('GET_USER_PHONE_NUMBER_REQUEST');
export const getUserPhoneNumberSuccess = createAction('GET_USER_PHONE_NUMBER_SUCCESS');
export const getUserPhoneNumberFail = createAction('GET_USER_PHONE_NUMBER_FAIL');

export const updateUserLeiRequest = createAction('UPDATE_USER_LEI_REQUEST');
export const updateUserLeiSuccess = createAction('UPDATE_USER_LEI_SUCCESS');
export const updateUserLeiFail = createAction('UPDATE_USER_LEI_FAIL');

export const getUserLeiInfoRequest = createAction('GET_USER_LEI_INFO_REQUEST');
export const getUserLeiInfoSuccess = createAction('GET_USER_LEI_INFO_SUCCESS');
export const getUserLeiInfoFail = createAction('GET_USER_LEI_INFO_FAIL');
