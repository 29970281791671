import { palette } from '../palette';
import { fontSize, fontWeight } from '../../font';

const OverridesMuiInputAdornment = {
  styleOverrides: {
    root: {
      height: 'auto',
      color: palette.icon.dark,
      fontSize: fontSize._16,
      fontWeight: fontWeight.regular,
    },
  },
};

export default OverridesMuiInputAdornment;
