import { combineReducers } from 'redux';

import destinationHub from './destinationHub';
import blockedHubs from './blockedHubs';
import showCaptcha from './showCaptcha';

const reducer = combineReducers({
  destinationHub,
  blockedHubs,
  showCaptcha,
});

export default reducer;
