import { RISK_CLASSIFICATION } from 'constants/clientRiskClassification';

export const ADD_CLIENT_RISK_CLASSIFICATION_FORM = 'ADD_CLIENT_RISK_CLASSIFICATION_FORM';
export const ADD_CLIENT_RISK_CLASSIFICATION_MODAL = 'ADD_CLIENT_RISK_CLASSIFICATION_MODAL';
export const RISK_FLAGS_PICKER_VALUES = [
  { value: RISK_CLASSIFICATION.LOW, label: RISK_CLASSIFICATION.LOW },
  { value: RISK_CLASSIFICATION.MEDIUM, label: RISK_CLASSIFICATION.MEDIUM },
  { value: RISK_CLASSIFICATION.HIGH, label: RISK_CLASSIFICATION.HIGH },
  { value: RISK_CLASSIFICATION.PROHIBITED, label: RISK_CLASSIFICATION.PROHIBITED },
];
