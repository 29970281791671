import { createAction } from 'redux-actions';

export const getMyFeedDetailsDataRequest = createAction('GET_MY_FEED_DETAILS_DATA_REQUEST');
export const getMyFeedDetailsDataSuccess = createAction('GET_MY_FEED_DETAILS_DATA_SUCCESS');
export const getMyFeedDetailsDataFail = createAction('GET_MY_FEED_DETAILS_DATA_FAIL');

export const getMyFeedArticlesRequest = createAction('GET_MY_FEED_ARTICLES_REQUEST');
export const getMyFeedArticlesSuccess = createAction('GET_MY_FEED_ARTICLES_SUCCESS');
export const getMyFeedArticlesFail = createAction('GET_MY_FEED_ARTICLES_FAIL');

export const getArticleContentRequest = createAction('GET_ARTICLE_CONTENT_REQUEST');
export const getArticleContentSuccess = createAction('GET_ARTICLE_CONTENT_SUCCESS');
export const getArticleContentFail = createAction('GET_ARTICLE_CONTENT_FAIL');

export const showDetails = createAction('MY_FEED_SHOW_DETAILS');

export const updateSnippet = createAction('MY_FEED_UPDATE_SNIPPET');

export const deleteMyFeedRequest = createAction('DELETE_MY_FEED_REQUEST');
export const deleteMyFeedSuccess = createAction('DELETE_MY_FEED_SUCCESS');
export const deleteMyFeedFail = createAction('DELETE_MY_FEED_FAIL');

export const updateMyFeedRequest = createAction('UPDATE_MY_FEED_REQUEST');
export const updateMyFeedSuccess = createAction('UPDATE_MY_FEED_SUCCESS');
export const updateMyFeedFail = createAction('UPDATE_MY_FEED_FAIL');

export const getDiscoverEntitiesRequest = createAction('GET_DISCOVER_ENTITIES_REQUEST');
export const getDiscoverEntitiesSuccess = createAction('GET_DISCOVER_ENTITIES_SUCCESS');
export const getDiscoverEntitiesFail = createAction('GET_DISCOVER_ENTITIES_FAIL');

export const navigateToDashboard = createAction('NAVIGATE_TO_DASHBOARD');
