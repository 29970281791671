const styles = (theme) => ({
  welcomeModal: {
    width: '800px',
    overflowX: 'hidden',
    '@media (min-width:768px)': {
      paddingLeft: '50px',
      paddingRight: '50px',
    },
    '@media (max-width:768px)': {
      width: '95%',
    },
  },
  title: {
    color: theme.proDeclarationModal.text.color,
    fontSize: '28px',
    letterSpacing: '0',
    lineHeight: '33px',
  },
  content: {
    color: theme.proDeclarationModal.text.color,
    fontSize: '20px',
    fontWeight: '300',
    letterSpacing: '0',
    lineHeight: '24px',
  },
  checkboxText: {
    color: theme.proDeclarationModal.text.color,
    fontSize: '16px',
    fontWeight: '300',
    letterSpacing: '0',
    lineHeight: '19px',
    marginLeft: '27px',
  },
  footer: {
    color: theme.proDeclarationModal.footer.color,
    fontSize: '16px',
    fontWeight: '300',
    letterSpacing: '0',
    lineHeight: '19px',
  },
  line: {
    width: '810px',
    borderBottom: '1px solid #EFF2F7',
    marginLeft: '-54px',
    '@media (max-width:768px)': {
      marginLeft: '-21px',
    },
  },
  sectionInfo: {
    marginBottom: '25px',
  },
  sectionButtons: {
    textAlign: 'right',
  },
  button: {
    width: '159px',
  },
  migrateButton: {
    margin: '28px 0 0',
    fontWeight: '900',
    padding: '15px 18px',
    float: 'right',
  },

  textField: {
    color: theme.proDeclarationModal.text.color,
    '&::placeholder': {
      color: '#AEBEE1',
      fontSize: '16px',
      letterSpacing: '0',
      lineHeight: '20px',
    },
  },
  labelText: {
    color: theme.palette.moonstoneBlue,
  },

  termsBox: {
    overflowY: 'scroll',
    height: '300px',
    width: '100%',
    border: '1px solid #DDD',
  },

  sign: {
    display: 'flex',
    marginBottom: '3%',
  },

  signForm: {
    display: 'flex',
    width: '100%',
  },

  signDate: {
    color: theme.proDeclarationModal.sign.color,
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0',
    lineHeight: '20px',
  },

  checkboxField: {
    color: theme.proDeclarationModal.text.color,
    fontSize: '16px',
    fontWeight: '300',
    letterSpacing: '0',
    lineHeight: '19px',
    '&$disabled': {
      color: '#C6D7FC',
    },
  },
  tickbox: {
    color: '#9D9D9D',
  },
  disabled: {},
});

export default styles;
