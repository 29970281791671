import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = [];

export default handleActions(
  {
    [actionCreators.getClientsAccountsSuccess](state, action) {
      return !action.payload.persistStoreData ? action.response.data.clientsAccounts : state;
    },
    [actionCreators.getClientsAccountsFail]() {
      return defaultState;
    },
  },
  defaultState,
);
