export default {
  placeholder: (theme) => ({ ...theme.formControl.placeholder }),
  valueContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%',
    height: 'inherit',
    verticalAlign: 'middle',
  },
  value: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: 1,
  },
};
