import get from 'lodash/get';
import last from 'lodash/last';

export const getResetToken = (ownProps) => {
  const {
    match: { params },
  } = ownProps;
  const token = get(params, 'token', '');

  return last(token.split('/'));
};

export const getTokenWithId = (props) => {
  const {
    location: { search },
  } = props;
  const token = new URLSearchParams(search).get('token', null);
  const userId = new URLSearchParams(search).get('userId', null);

  return { token, userId };
};
