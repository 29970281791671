import { handleActions } from 'redux-actions';

import { searchQuizAnswersSuccess, searchQuizAnswersFail } from '../actions';

const defaultState = {
  quiz: [],
};

export default handleActions(
  {
    [searchQuizAnswersSuccess](state, action) {
      return {
        ...state,
        quiz: action.response.data.quiz || [],
        totalNumber: action.response.data.totalNumber || 0,
      };
    },

    [searchQuizAnswersFail]() {
      return {
        ...defaultState,
        totalNumber: 0,
      };
    },
  },
  defaultState,
);
