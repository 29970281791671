import React from 'react';
import { useSelector } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { Grid, Box, Avatar } from 'material-latest';
import { Search } from '@mui/icons-material';

import TPTypography from 'components/TP-UI/TPTypography';
import TPTextField from 'components/TP-UI/TPTextField';
import TPCopyButton from 'components/TP-UI/TPCopyButton';
import TPInputAdornment from 'components/TP-UI/TPInputAdornment';
import TPSelect from 'components/TP-UI/TPSelect';
import TPSearchTextField from 'components/TP-UI/TPSearchTextField';
import TPPhoneTextField from 'components/TP-UI/TPPhoneTextField';
import { required } from '../../../utils/validation/fieldValidationRules';
import countries from '../common/countries.json';

const styles = {
  typography: (theme) => ({
    marginLeft: theme.spacing(1),
  }),
  form: {
    width: '100%',
  },
  lngIcon: (theme) => ({
    marginRight: theme.spacing(1),
  }),
  control: (theme) => ({
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
  }),
};

const TextFields = () => {
  const copyFieldValue = useSelector((state) =>
    formValueSelector('TP-TEXTFIELDS-FORM')(state, 'copy'),
  );

  return (
    <Box component="form" sx={styles.form}>
      <Grid container spacing={2}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={3}>
            <TPTypography variant="h6" className={styles.typography}>
              Normal, default size, clearable
            </TPTypography>
            <Field
              component={TPTextField}
              name="age"
              label="Age"
              placeholder="Please enter age"
              fullWidth
              required
              clearable
              className={styles.control}
            />
          </Grid>
          <Grid item xs={3}>
            <TPTypography variant="h6" className={styles.typography}>
              Multiline (textarea), optional
            </TPTypography>
            <Field
              component={TPTextField}
              id="type"
              name="type"
              multiline
              label="Type"
              minRows={2}
              placeholder="Please enter text"
              fullWidth
              clearable
              className={styles.control}
            />
          </Grid>
          <Grid item xs={3}>
            <TPTypography variant="h6" className={styles.typography}>
              Hint, type number
            </TPTypography>
            <Field
              component={TPTextField}
              name="age2"
              type="number"
              label="Age"
              placeholder="Please enter age"
              fullWidth
              required
              hint="Additional information"
              className={styles.control}
            />
          </Grid>
          <Grid item xs={3}>
            <TPTypography variant="h6" className={styles.typography}>
              No label
            </TPTypography>
            <Field
              component={TPTextField}
              name="currency"
              placeholder="No label"
              fullWidth
              className={styles.control}
            />
          </Grid>
          <Grid item xs={3}>
            <TPTypography variant="h6" className={styles.typography}>
              Error
            </TPTypography>
            <Field
              component={TPTextField}
              name="currency3"
              label="Currency"
              placeholder="Please enter currency"
              fullWidth
              validate={[required]}
              required
              hint="Additional info"
              meta={{ error: { key: 'common:errors.form.isRequired' }, submitFailed: true }}
              className={styles.control}
            />
          </Grid>
          <Grid item xs={3}>
            <TPTypography variant="h6" className={styles.typography}>
              Disabled
            </TPTypography>
            <Field
              component={TPTextField}
              name="currency4"
              label="Disabled"
              disabled
              placeholder="Please enter currency"
              fullWidth
              hint="Additional info"
              className={styles.control}
            />
          </Grid>
          <Grid item xs={3}>
            <TPTypography variant="h6" className={styles.typography}>
              Small size, clearable
            </TPTypography>
            <Field
              component={TPTextField}
              name="country2"
              placeholder="Small size"
              fullWidth
              size="small"
              hint="Additional info"
              clearable
              className={styles.control}
            />
          </Grid>
          <Grid item xs={3}>
            <TPTypography variant="h6" className={styles.typography}>
              Confidential (e.g. password)
            </TPTypography>
            <Field
              component={TPTextField}
              id="password"
              name="password"
              placeholder="Password"
              type="password"
              autocomplete="current-password"
              confidential
              fullWidth
              className={styles.control}
            />
          </Grid>
          <Grid item xs={3}>
            <TPTypography variant="h6" className={styles.typography}>
              Copy to clipboard
            </TPTypography>
            <Field
              component={TPTextField}
              id="copy"
              name="copy"
              type="text"
              fullWidth
              className={styles.control}
              endAdornment={<TPCopyButton text={copyFieldValue} />}
            />
          </Grid>
          <Grid item xs={3}>
            <TPTypography variant="h6" className={styles.typography}>
              Custom selected value render
            </TPTypography>
            <Field
              component={TPTextField}
              id="amount"
              name="amount"
              label="Amount"
              type="number"
              placeholder="Please select amount"
              fullWidth
              startAdornment="$"
              required
              hint="Additional information"
              className={styles.control}
            />
          </Grid>
          <Grid item xs={3}>
            <TPTypography variant="h6" className={styles.typography}>
              Custom selected value render, type tel
            </TPTypography>
            <Field
              component={TPTextField}
              name="phone"
              label="Phone number"
              type="tel"
              placeholder="Please enter phone number"
              fullWidth
              startAdornment="+"
              required
              hint="Additional information"
              className={styles.control}
            />
          </Grid>
          <Grid item xs={3}>
            <TPTypography variant="h6" className={styles.typography}>
              Start adornment with icon
            </TPTypography>
            <Field
              component={TPTextField}
              name="search2"
              type="text"
              placeholder="Search"
              fullWidth
              required
              className={styles.control}
              clearable
              startAdornment={
                <TPInputAdornment position="start">
                  <Search />
                </TPInputAdornment>
              }
            />
          </Grid>
          <Grid item xs={3}>
            <TPTypography variant="h6" className={styles.typography}>
              Start adornment with select
            </TPTypography>
            <Field
              component={TPTextField}
              name="search3"
              type="text"
              placeholder="Search"
              fullWidth
              required
              className={styles.control}
              clearable
              startAdornment={
                <TPInputAdornment position="start">
                  <Field
                    component={TPSelect}
                    name="country"
                    variant="custom"
                    reservedErrorSpace={false}
                    options={[
                      {
                        label: 'England',
                        value: 'England',
                        img: 'http://localhost:3000/static/media/en.2e9c71c7.svg',
                      },
                      {
                        label: 'Thai',
                        value: 'Thai',
                        img: 'http://localhost:3000/static/media/th.9667e838.svg',
                      },
                    ]}
                    renderOption={(option) => (
                      <>
                        <Avatar sx={styles.lngIcon} alt={option.label} src={option.img} />{' '}
                        {option.label}
                      </>
                    )}
                  />
                </TPInputAdornment>
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <TPTypography variant="h6" className={styles.typography}>
            TPSearchTextField(debounced) component: default size
          </TPTypography>
          <Field
            component={TPSearchTextField}
            name="search4"
            placeholder="Search"
            fullWidth
            className={styles.control}
            clearable
          />
        </Grid>
        <Grid item xs={3}>
          <TPTypography variant="h6">
            TPSearchTextField(debounced) component: small size
          </TPTypography>
          <Field
            component={TPSearchTextField}
            name="search5"
            placeholder="Search"
            fullWidth
            className={styles.control}
            clearable
            size="small"
          />
        </Grid>
        {/*<Grid item xs={3}>*/}
        {/*  <TPTypography variant="h6">AmountTextField component (based on TPTextField)</TPTypography>*/}
        {/*  <Field*/}
        {/*    component={AmountTextField}*/}
        {/*    id="amount"*/}
        {/*    name="amount"*/}
        {/*    label="Amount"*/}
        {/*    placeholder="Amount"*/}
        {/*    currency="eur"*/}
        {/*    fullWidth*/}
        {/*    className={styles.control}*/}
        {/*    clearable*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid item xs={3}>
          <TPTypography variant="h6">TPPhoneTextField component</TPTypography>
          <Field
            component={TPPhoneTextField}
            label="Phone Number"
            name="phone2"
            placeholder="Enter phone"
            options={countries}
            groupBy="region"
            fullWidth
            autofocus
            className={styles.control}
            clearable
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default reduxForm({
  form: 'TP-TEXTFIELDS-FORM',
  initialValues: {
    age: null,
    age2: null,
    type: '',
    password: '',
    currency: 1,
    currency2: null,
    currency3: null,
    currency4: null,
    country2: 'England',
    amount: 0,
    phone: null,
    phone2: null,
    search: '',
    search2: '',
    search3: '',
    search4: '',
    search5: '',
    copy: 'Copy this text to the clipboard',
  },
})(TextFields);
