export const smallCell = () => ({
  item: { minWidth: '108px' },
});
export const limitedResponsiveMediumCell = () => ({
  item: {
    width: '120px',
    '@media (min-width:400px) and (max-width:1365px)': {
      width: 'auto',
      paddingLeft: 10,
      paddingRight: 10,
    },
    '@media (min-width:1366px)': {
      width: '120px',
    },
    '@media (min-width:1440px)': {
      width: '160px',
    },
  },
});
export const normalCell = () => ({
  item: { minWidth: '150px' },
});

export const noPadding = () => ({
  item: { paddingLeft: '5px', paddingRight: '5px' },
});
export const limitedCell = () => ({
  item: { maxWidth: '150px' },
});
export const accountNameLimitedCell = () => ({
  item: {
    maxWidth: '208px',
    minWidth: '208px',
    '@media (max-width:1366px)': {
      /* maxWidth: '100%',
      minWidth: '100%',*/
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
});
export const extraSmallLimitedCell = () => ({
  item: {
    width: 130,
    minWidth: 130,
    '@media (max-width:1366px)': {
      width: 'auto',
      minWidth: 'auto',
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
});
export const smallLimitedCell = () => ({
  item: { width: '150px', minWidth: '150px' },
});
export const normalLimitedCell = () => ({
  item: { width: '180px' },
});
export const mediumLimitedCell = () => ({
  item: { width: '300px' },
});
export const hugeLimitedCell = () => ({
  item: {
    width: '420px',
    '@media (max-width:680px)': {
      width: '280px',
    },
  },
});
export const fixedCell = () => ({
  item: {
    width: '158px',
    overflowX: 'hidden',
    '@media (min-width:1680px)': {
      width: '250px',
      minWidth: '180px',
      alignSelf: 'center',
    },
  },
});
export const responsiveCell = () => ({
  item: {
    '@media (max-width:480px)': {
      minWidth: '120px',
    },
  },
});

export const capitalized = () => ({
  item: { textTransform: 'capitalize' },
});

export const uppercase = () => ({
  item: { textTransform: 'uppercase' },
});

export const mobileSmallCell = () => ({
  item: {
    padding: '17px 0px',
    '@media (min-width:640px)': {
      padding: '17px 15px',
    },
    '@media (min-width:1366px)': {
      padding: '22px 10px',
    },
    '@media (max-width:640px)': {
      maxWidth: '80px',
      minWidth: '80px',
    },
  },
});
