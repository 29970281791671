export const getHistory = (state) => state.partnerTransfer.history;

export const getIsLoading = (state) => {
  return state.partnerTransfer.isLoading;
};

export const getBalance = (state) => {
  return state.partnerTransfer.balance;
};

export const getAffiliate = (state) => {
  return state.partnerTransfer.affiliate;
};
