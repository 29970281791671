const styles = (themes) => ({
  joinModal: {
    width: '100%',
    maxWidth: '720px',
    minWidth: '520px',
  },
  copySettingsModal: {
    maxWidth: '742px',
    width: '100%',
  },
  joinFusionPlusButtons: {
    display: 'flex',
    gap: '10px',
  },
  myInvitations: {
    padding: '24px 35px',
    display: 'grid',
    gap: '10px 20px',
    width: '100%',
    gridTemplateColumns: '2fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    backgroundColor: themes.myPerformance.backgroundColor,
    border: themes.myPerformance.border,
    '@media (max-width:768px)': {
      padding: '24px 10px',
      gap: '15px 10px',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
    '@media (max-width:375px)': {
      padding: '24px 10px',
      gap: '15px 10px',
      gridTemplateColumns: '1fr 1fr',
    },
  },
  noData: {
    color: themes.palette.text.main,
    fontSize: '14px',
    fontWeight: '100',
  },
  description: {
    color: themes.palette.text.dark,
    fontSize: '20px',
    fontWeight: '300',
    paddingBottom: '30px',
  },
  noDataDescription: {
    marginTop: '10px',
    color: themes.palette.text.main,
    fontSize: '16px',
    fontWeight: '100',
  },
  buttonsContainer: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    '@media (max-width:768px)': {
      marginTop: '15px',
      justifyContent: 'flex-end',
      gridColumn: '1/5',
    },
    '@media (max-width:375px)': {
      marginTop: '15px',
      justifyContent: 'flex-end',
      gridColumn: '1/3',
    },
  },
  iconButton: {
    padding: 0,
    width: '30px',
    minWidth: '30px',
    overflow: 'hidden',
  },
  reloadButton: {
    padding: 0,
    width: '30px',
    minWidth: '30px',
    overflow: 'hidden',
    color: '#f48fb1',
  },
  waitlistJoinButton: {
    color: '#742CCE',
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
  },
  waitlistLayout: {
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='1216' height='1189' viewBox='0 0 1216 1189' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_594_3221)'%3E%3Crect width='1216' height='1189' fill='url(%23paint0_linear_594_3221)'/%3E%3Crect opacity='0.05' x='916' y='714.517' width='306.201' height='306.201' rx='24' transform='rotate(-45 916 714.517)' fill='url(%23paint1_linear_594_3221)'/%3E%3Cpath d='M-357.6 558.309C-311.087 530.526 -139.369 263.022 254.318 345.643C618.078 421.983 647.907 617.733 1161.15 722.451C1674.39 827.169 1622.68 531.246 2011.87 581.093C2277.07 615.059 2384.01 1006.96 2974.86 1030.66' stroke='%23DFC8FC' stroke-linecap='round'/%3E%3Cpath d='M-386.253 696.432C-339.74 668.649 -168.022 401.145 225.665 483.766C589.425 560.106 619.254 755.856 1132.5 860.574C1645.74 965.292 1594.02 669.369 1983.22 719.216C2248.42 753.182 2355.35 1145.09 2946.21 1168.78' stroke='%23DFC8FC' stroke-linecap='round'/%3E%3Cpath d='M-411.648 846.557C-365.134 818.774 -193.417 551.27 200.27 633.891C564.03 710.231 593.86 905.981 1107.1 1010.7C1620.35 1115.42 1568.63 819.494 1957.82 869.341C2223.03 903.307 2329.96 1295.21 2920.81 1318.91' stroke='%23DFC8FC' stroke-linecap='round'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_594_3221' x1='1216' y1='-12.6827' x2='-223.748' y2='391.003' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23EFE3FF'/%3E%3Cstop offset='1' stop-color='%23FDFCFF'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_594_3221' x1='1069.1' y1='714.517' x2='1069.1' y2='1020.72' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%234A20BA'/%3E%3Cstop offset='1' stop-color='%23732DCE' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='clip0_594_3221'%3E%3Crect width='1216' height='1189' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  waitlistButtonMobile: {
    padding: '8px 2px',
    fontSize: '12px',
  },
  waitlistHeader: {
    fontSize: '22px',
  },
});

export default styles;
