import React from 'react';
import { Box } from 'material-latest';

import styles from './styles';

const TPCardActions = ({ noGutters, fullWidth, className, children }) => {
  return (
    <Box
      sx={[styles.root, fullWidth && styles.fullWidth, noGutters && styles.noGutters, className]}>
      {children}
    </Box>
  );
};

export default TPCardActions;
