import { store } from 'components/App';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { raiseError, postErrorRequest } from '../actions';
import { DEFAULT_MESSAGE } from '../constants';
import ErrorPageContent from './ErrorPageContent';
import { Routes } from 'constants/routeConstants';
import { withRouter } from 'react-router-dom';
import ErrorUpdateCache from '../components/ErrorUpdateCache';

const RE_LOADING_CHUNK_FAILED = /^Loading chunk/;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidMount() {
    import('../sagas').then((saga) => {
      store.injectSaga('ErrorBoundary', saga.default);
    });
  }

  componentDidCatch(error, errorInfo) {
    //eslint-disable-next-line
    console.log('componentDidCatch', error, errorInfo);

    if (RE_LOADING_CHUNK_FAILED.test(error.message)) {
      this.setState({
        cacheIsInvalid: true,
      });
    }

    const errorData = {
      error,
      stack: error.stack,
      errorInfo,
      state: store.getState(),
      url: window.location.href,
    };
    //eslint-disable-next-line
    console.dir(errorData);
    this.props.postErrorRequest(errorData);
    this.props.raiseError(this.props.message);
  }

  onReturnToPreviousPage = () => {
    this.props.history.goBack();
  };

  onRedirectToHomePage = () => {
    this.props.history.push(Routes.HOME);
  };

  render() {
    const { ErrorComponent } = this.props;
    const props = {
      onReturnToPreviousPage: this.onReturnToPreviousPage,
      onRedirectToHomePage: this.onRedirectToHomePage,
    };

    if (this.state.cacheIsInvalid) {
      return <ErrorUpdateCache />;
    }

    if (this.state.hasError) {
      return <ErrorComponent {...props} />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  history: PropTypes.object.isRequired,
  message: PropTypes.string,
  ErrorComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.object]),
};

ErrorBoundary.defaultProps = {
  message: DEFAULT_MESSAGE,
  ErrorComponent: ErrorPageContent,
};

const mapDispatchToProps = (dispatch) => ({
  raiseError: bindActionCreators(raiseError, dispatch),
  postErrorRequest: bindActionCreators(postErrorRequest, dispatch),
});

export default withRouter(connect(null, mapDispatchToProps)(ErrorBoundary));
