import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'material-latest';

import TPTrendingIcon from 'components/TP-UI/TPTrendingIcon';
import { SIZES } from 'components/TP-UI/constants';

import styles from './styles';

const formatNumber = (number, minDigits, digit) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: digit,
  }).format(number);
};

const TPNumberDifference = ({
  value,
  digits = 2,
  minDigits = 0,
  format,
  size = '',
  percentage,
  showIcon = false,
  iconPosition = 'end',
  hideSign = false,
  mask,
  className,
}) => {
  const trending =
    value > 0
      ? { sign: '+', value: 'up' }
      : value < 0
      ? { sign: '-', value: 'down' }
      : { value: 'flat' };

  let formattedValue = null;
  if (value !== null && value !== undefined) {
    formattedValue = (!hideSign && trending?.sign) || '';
    formattedValue += format
      ? format(Math.abs(value))
      : formatNumber(Math.abs(Number.parseFloat(value)), minDigits, digits);
  }
  if (mask && formattedValue) {
    formattedValue = mask.replace('$value', formattedValue);
  }

  return formattedValue ? (
    <Box
      component="span"
      sx={[
        styles.numberContainer,
        trending?.value && styles[trending.value],
        size && styles[`${size}Size`],
        className,
      ]}>
      {showIcon && iconPosition === 'start' ? (
        <Box component="span" sx={styles.startAdornment}>
          <TPTrendingIcon trending={trending.value} />
        </Box>
      ) : null}
      {formattedValue}
      {percentage && '%'}
      {showIcon && iconPosition === 'end' ? (
        <Box component="span" sx={styles.endAdornment}>
          <TPTrendingIcon trending={trending.value} />
        </Box>
      ) : null}
    </Box>
  ) : null;
};

TPNumberDifference.propTypes = {
  value: PropTypes.number.isRequired,
  digits: PropTypes.number,
  format: PropTypes.func,
  percentage: PropTypes.bool,
  showIcon: PropTypes.bool,
  iconPosition: PropTypes.oneOf(['start', 'end']),
  size: PropTypes.oneOf(['', SIZES.LARGE, SIZES.MEDIUM, SIZES.SMALL]),
};

export default TPNumberDifference;
