import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = [];

export default handleActions(
  {
    [actionCreators.getQuizCommentsSuccess](state, action) {
      return action.response.data;
    },

    [actionCreators.addQuizCommentsSuccess](state, action) {
      if (action.response.data) {
        return [...state, action.response.data];
      }

      return state;
    },
  },
  defaultState,
);
