import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'react-i18next';
import DocumentTitle from 'react-document-title';
import classNames from 'classnames';

import config from 'config';

import MainLayout from 'modules/common/MainLayout';
import Breadcrumbs from 'modules/common/Breadcrumbs';
import styles from './styles';
import SecondaryButton from 'modules/common/SecondaryButton';
import CustomLink from 'modules/common/CustomLink';
import { Routes } from 'constants/routeConstants';
import PrimaryButton from 'modules/common/PrimaryButton';
import getWarning from 'modules/payments/helpers/getWarning';

const ConfirmPayment = ({
  classes,
  redirectUrl = '',
  accountNickname,
  amountMoney,
  currency,
  isSCBPayment,
  qrImage,
  onQrFinishClick,
  userCountry,
  history,
}) => {
  const { t } = useTranslation('payments');
  const [displayIframe, setIframeDisplay] = React.useState(false);
  const { IS_CLIM } = config;
  const warning = getWarning(t, userCountry);

  if (!redirectUrl) {
    history.push(Routes.PAYMENTS);
  }

  return (
    <MainLayout title={t('deposit.confirmPayment.title')} warning={warning}>
      <DocumentTitle title={t('deposit.confirmPayment.title')} />
      <div className={classes.container}>
        <Breadcrumbs
          routes={[
            { label: t('page.tabs.0'), link: Routes.PAYMENTS },
            { label: t('deposit.confirmPayment.title'), link: Routes.CONFIRM_PAYMENT },
          ]}
        />

        <div className={classes.paymentWrapper}>
          {IS_CLIM && (
            <>
              <header className={classes.header}>
                <p className={classes.title}>{t('deposit.confirmPayment.header')}</p>
              </header>
              <section className={classes.wrapperInfo}>
                <section className={classes.containerInfo}>
                  <section className={classes.cell}>
                    <span className={classes.cellTitle}>{t('common:labels.account')}</span>
                    <span className={classes.accountNickname}>{accountNickname}</span>
                  </section>
                  <section className={classes.cell}>
                    <span className={classes.cellTitle}>{t('common:labels.amountOfMoney')}</span>
                    <span className={classes.amountMoney}>{`${amountMoney} ${currency}`}</span>
                  </section>
                  <section className={classes.buttonWrapper}>
                    <CustomLink to={Routes.PAYMENTS}>
                      <SecondaryButton
                        content={<EditIcon className={classes.stepLinkIcon} />}
                        label={t('deposit.confirmPayment.changeDetailsButton')}
                      />
                    </CustomLink>
                  </section>
                </section>
              </section>
            </>
          )}
          {isSCBPayment ? (
            <div className={classes.qrCodeWrapper}>
              <div className={classes.qrCodeLabel}>{t('deposit.confirmPayment.qrCodeLabel')}</div>
              <img
                className={classes.qrCode}
                src={`data:image/png;base64,${qrImage}`}
                alt="QRCode"
              />
              <PrimaryButton
                color="primary"
                label={t('deposit.confirmPayment.finishScbButton')}
                onClick={onQrFinishClick}
                size="large"
                className={classes.qrFinishButton}
              />
            </div>
          ) : (
            <div
              className={classNames(classes.iframeWrapper, {
                [classes.displayIframe]: displayIframe,
                [classes.hideIframe]: !displayIframe,
              })}>
              <iframe
                target="_parent"
                src={redirectUrl}
                allowFullScreen
                title={t('deposit.confirmPayment.title')}
                onLoad={() => setIframeDisplay(true)}
              />
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

ConfirmPayment.propTypes = {
  redirectUrl: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  accountNickname: PropTypes.string,
  amountMoney: PropTypes.string,
  currency: PropTypes.string,
  isSCBPayment: PropTypes.bool.isRequired,
  qrImage: PropTypes.string,
  onQrFinishClick: PropTypes.func.isRequired,
  userCountry: PropTypes.string,
};

export default withRouter(withStyles(styles)(ConfirmPayment));
