import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { Pagination, Stack, TablePagination, ThemeProvider } from 'material-latest';

import { PAGE_LIMITS_VALUES } from '../../constants';
import theme from './styles';
import GoToPageFields from '../GoToPageFields';

const PaginationBar = ({
  currentPage,
  onPageSizeChange,
  totalItems,
  onPageChange,
  pages,
  initialValues,
  handleSubmit,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(initialValues.pageSize);

  const onChange = useCallback(
    ({ target: { value } }) => {
      setRowsPerPage(value);
      onPageSizeChange(value);
    },
    [onPageSizeChange],
  );

  const handleClickArrow = useCallback(
    (_, pageNumber) => {
      onPageChange(pageNumber + 1);
    },
    [onPageChange],
  );

  const handleChange = useCallback(
    (_, pageNumber) => {
      onPageChange(pageNumber);
    },
    [onPageChange],
  );

  return (
    <ThemeProvider theme={theme}>
      <Stack
        width={['100%', 'initial']}
        component="form"
        direction={{ xs: 'column', sm: 'row' }}
        alignItems="center"
        onSubmit={handleSubmit}
        spacing={2}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Pagination
            count={pages || 1}
            onChange={handleChange}
            page={currentPage}
            color="primary"
            size="small"
          />
          <GoToPageFields />
        </Stack>
        <TablePagination
          component="div"
          count={totalItems || 0}
          page={currentPage - 1}
          onPageChange={handleClickArrow}
          rowsPerPageOptions={PAGE_LIMITS_VALUES}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onChange}
          ActionsComponent={() => null}
          labelDisplayedRows={() => 'entries'}
          labelRowsPerPage="Show"
        />
      </Stack>
    </ThemeProvider>
  );
};

PaginationBar.propTypes = {
  pages: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
  form: PropTypes.string.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  totalItems: PropTypes.number,
};

export default compose(
  reduxForm({
    destroyOnUnmount: false,
  }),
)(PaginationBar);
