import React, { useCallback, useMemo, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Box } from 'material-latest';
import EqualizerIcon from '@mui/icons-material/EqualizerOutlined';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GetAppIcon from '@mui/icons-material/GetApp';
import DotIcon from '@mui/icons-material/FiberManualRecord';

import TPGrid from 'components/TP-UI/TPGrid';
import TPCard from 'components/TP-UI/TPCard';
import TPCardContent from 'components/TP-UI/TPCard/components/TPCardContent';
import TPCardTitle from 'components/TP-UI/TPCard/components/TPCardTitle';
import TPCardSubtitle from 'components/TP-UI/TPCard/components/TPCardSubtitle';
import TPCardDescription from 'components/TP-UI/TPCard/components/TPCardDescription';
import TPDescriptionList from 'components/TP-UI/TPDescriptionList';
import TPButton from 'components/TP-UI/TPButton';
import TPCardActions from 'components/TP-UI/TPCard/components/TPCardActions';
import TPIconButton from 'components/TP-UI/TPIconButton';
import TPCardHeader from 'components/TP-UI/TPCard/components/TPCardHeader';
import TPTypography from 'components/TP-UI/TPTypography';
import TPSelect from 'components/TP-UI/TPSelect';
import TPTrending from 'components/TP-UI/TPTrending';
import TPCardAvatar from 'components/TP-UI/TPCard/components/TPCardAvatar';
import TPCardImage from 'components/TP-UI/TPCard/components/TPCardImage';
import formatFullDate from '../../../utils/formatFullDate';
import { formatCurrency } from '../../../utils/formatters';

import logo from 'static/tradingTools/mt4Android.png';
import chartImg from 'static/news/placeholderImages/6.jpg';
import TPNumberDifference from '../../../components/TP-UI/TPNumberDifference';

import styles from './styles';

const profileInfo = {
  email: 'bruce.wayne@fusionmarkets.com',
  phoneNumber: '+61419881215',
  dateOfBirth: '1991-06-28T12:27:55.201Z',
  location: 'Level 7, 222 Exhibition St, Melbourne, VIC, 3000, United Kingdom',
};

const accountInfo = {
  mt4Login: '48273',
  accountType: 'Raw',
  platform: 'mt4',
  server: 'FusionMarkets-Live',
  currency: 'usd',
  leverage: '1:1000',
};

const accountProps = [
  {
    dataKey: 'mt4Login',
    label: 'MT4 Login',
  },
  {
    dataKey: 'accountType',
    label: 'Account Type',
  },
  {
    dataKey: 'platform',
    label: 'Platform',
  },
  {
    dataKey: 'server',
    label: 'Server',
  },
  {
    dataKey: 'currency',
    label: 'Currency',
    format: formatCurrency,
  },
  {
    dataKey: 'leverage',
    label: 'Leverage',
  },
];

const profileProps = [
  {
    dataKey: 'email',
    label: 'Email',
  },
  {
    dataKey: 'phoneNumber',
    label: 'Phone Number',
  },
  {
    dataKey: 'dateOfBirth',
    label: 'Date of Birth',
    format: formatFullDate,
  },
  {
    dataKey: 'location',
    label: 'Location',
  },
];

const Cards = () => {
  const [selected, setSelected] = useState();
  const profile = useMemo(() => ({ ...profileInfo }), []);
  const account = useMemo(() => ({ ...accountInfo }), []);

  const handleCardClick = useCallback(() => {
    setSelected((val) => !val);
  }, []);

  return (
    <TPGrid container spacing={1}>
      <TPGrid item xs={12}>
        <TPCard>
          <TPCardHeader>
            <div>
              <TPCardSubtitle>Bruce Wayne 003</TPCardSubtitle>
              <TPCardTitle bold>100 AUD</TPCardTitle>
            </div>
            <TPCardActions noGutters>
              <TPIconButton size="small">
                <EqualizerIcon fontSize="medium" />
              </TPIconButton>
              <TPIconButton size="small">
                <VisibilityIcon fontSize="medium" />
              </TPIconButton>
              <TPIconButton size="small">
                <EditIcon fontSize="medium" />
              </TPIconButton>
            </TPCardActions>
          </TPCardHeader>
          <TPCardContent divider>
            <TPDescriptionList value={account} descriptionProps={accountProps} />
          </TPCardContent>
        </TPCard>
      </TPGrid>
      <TPGrid item xs={12}>
        <TPCard>
          <TPCardHeader>
            <TPCardTitle semiBold>Profile Details</TPCardTitle>
            <TPCardActions noGutters>
              <TPIconButton size="small">
                <EditIcon fontSize="medium" />
              </TPIconButton>
            </TPCardActions>
          </TPCardHeader>
          <TPCardContent divider>
            <TPDescriptionList value={profile} descriptionProps={profileProps} />
          </TPCardContent>
        </TPCard>
      </TPGrid>
      <TPGrid item xs={6}>
        <TPCard fullWidth>
          <TPCardContent>
            <TPCardTitle semiBold>Select the Account</TPCardTitle>
            <TPCardDescription>
              Please select the account you wish to deposit into
            </TPCardDescription>
            <Box component="form" sx={styles.form}>
              <Field
                component={TPSelect}
                id="account"
                name="account"
                label="Account"
                placeholder="Please select account"
                options={[]}
                noOptionsText="No accounts"
                fullWidth
                required
                hint={
                  <>
                    Current account balance:{' '}
                    <TPTypography component="span" variant="inherit" color="primary">
                      13,550 USD
                    </TPTypography>
                  </>
                }
              />
            </Box>
            <TPCardTitle>Choose a payment method</TPCardTitle>
            <TPCardActions fullWidth className={styles.paymentButtons}>
              <TPButton alternative size="large">
                Visa
              </TPButton>
              <TPButton alternative size="large">
                Pay
              </TPButton>
              <TPButton alternative size="large">
                Bank
              </TPButton>
            </TPCardActions>
          </TPCardContent>
        </TPCard>
      </TPGrid>
      <TPGrid item xs={6}>
        <TPCard fullWidth>
          <TPCardContent>
            <TPCardTitle semiBold>Select the Account</TPCardTitle>
            <TPCardDescription>
              Please select the account you wish to deposit into
            </TPCardDescription>
            <Box component="form" sx={styles.form}>
              <Field
                component={TPSelect}
                id="account"
                name="account"
                label="Account"
                placeholder="Please select account"
                options={[]}
                noOptionsText="No accounts"
                fullWidth
                required
                hint={
                  <>
                    Current account balance:{' '}
                    <TPTypography component="span" variant="inherit" color="primary">
                      13,550 USD
                    </TPTypography>
                  </>
                }
                className={styles.control}
              />
              <TPCardActions>
                <TPButton primary size="large">
                  Transfer
                </TPButton>
              </TPCardActions>
              <TPTypography variant="body2" color="secondary" className={styles.note}>
                <TPTypography component="span" variant="inherit" color="primary">
                  Please note:
                </TPTypography>{' '}
                The currency conversion rates that are currently streaming are live market rates and
                the final amount converted may change significantly by the time your conversion is
                completed from one currency to the other due to market conditions.
              </TPTypography>
            </Box>
          </TPCardContent>
        </TPCard>
      </TPGrid>
      <TPGrid item xs={3}>
        <TPCard fullWidth>
          <TPCardHeader>
            <TPCardTitle semiBold>A reduced commission rate on your trades</TPCardTitle>
          </TPCardHeader>
          <TPCardContent divider>
            <TPTypography>
              Refer 5 friends who deposit and trade more than 5 standard lots of FX, and Fusion will
              reduce your commission rate by 5%. Refer 10 friends and you will receive 10% off your
              commission.
            </TPTypography>
            <TPCardActions fullWidth>
              <TPButton alternative size="large">
                Select
              </TPButton>
            </TPCardActions>
          </TPCardContent>
        </TPCard>
      </TPGrid>
      <TPGrid item xs={3}>
        <TPCard fullWidth>
          <TPCardHeader>
            <TPCardTitle semiBold>USD/JPY may rise 38 - 68 pips</TPCardTitle>
            <TPCardSubtitle variant="body2" color="secondary">
              5:01 PM (+03:00 UTC)
            </TPCardSubtitle>
          </TPCardHeader>
          <TPCardContent>
            <TPCardSubtitle>
              <TPTrending label="USD/JPY Intraday: rebound" value={5} />
            </TPCardSubtitle>
            <TPTypography variant="body2">
              Long positions above 125.05 with targets at 126.30 & 126.60 in extension. Alternative
              scenario: below 125.05 look for further downside with 124.85 & 124.65 as targets.
            </TPTypography>
          </TPCardContent>
        </TPCard>
      </TPGrid>
      <TPGrid item xs={3}>
        <TPCard fullWidth selected={selected} onClick={handleCardClick}>
          <TPCardHeader>
            <TPCardTitle semiBold>USD/JPY may rise 38 - 68 pips</TPCardTitle>
            <TPCardSubtitle variant="body2" color="secondary">
              5:01 PM (+03:00 UTC)
            </TPCardSubtitle>
          </TPCardHeader>
          <TPCardContent>
            <TPCardSubtitle>
              <TPTrending label="USD/JPY Intraday: rebound" value={5} />
            </TPCardSubtitle>
            <TPTypography variant="body2">
              Long positions above 125.05 with targets at 126.30 & 126.60 in extension. Alternative
              scenario: below 125.05 look for further downside with 124.85 & 124.65 as targets.
            </TPTypography>
          </TPCardContent>
        </TPCard>
      </TPGrid>
      <TPGrid item xs={6}>
        <TPCard fullWidth>
          <TPCardContent>
            <TPCardAvatar primary>
              <WhatshotIcon />
            </TPCardAvatar>
            <TPCardTitle semiBold>Fusion Goes Crypto</TPCardTitle>
            <TPTypography>
              The reason we started Fusion Markets was to make{' '}
              <TPTypography variant="inherit" bold component="span">
                low-cost trading available to everyone, everywhere
              </TPTypography>
              .
            </TPTypography>
            <TPTypography>
              As Crypto CFD providers with decades of experience in Fintech, we see the
              inefficiencies of other exchanges, and
              <TPTypography variant="inherit" bold component="span">
                know crypto traders could have it better
              </TPTypography>
              .
            </TPTypography>
          </TPCardContent>
        </TPCard>
      </TPGrid>
      <TPGrid item xs={6}>
        <TPCard fullWidth>
          <TPCardHeader>
            <div>
              <TPCardTitle semiBold>MetaTrader 4</TPCardTitle>
              <TPCardDescription>Desktop & Mobile App</TPCardDescription>
            </div>
            <TPCardAvatar src={logo} className={styles.logo} variant="square" />
          </TPCardHeader>
          <TPCardContent divider>
            <TPTypography>
              MetaTrader 4 is the industry standard in FX trading. It is fast, convenient and
              extremely stable. While many brokers offer this platform to clients, it is important
              to note that it initially comes as an empty shell. It is what the broker puts into it
              that makes it so compelling.
            </TPTypography>
            <TPCardActions fullWidth>
              <TPGrid container spacing={1}>
                <TPGrid item container xs={12} md={6}>
                  <TPGrid item xs={12}>
                    <TPButton secondary size="large" className={styles.button}>
                      <GetAppIcon sx={styles.buttonIcon} /> For windows
                    </TPButton>
                  </TPGrid>
                  <TPGrid item xs={12}>
                    <TPButton secondary size="large">
                      <GetAppIcon sx={styles.buttonIcon} /> For android
                    </TPButton>
                  </TPGrid>
                </TPGrid>
                <TPGrid item container xs={12} md={6}>
                  <TPGrid item xs={12}>
                    <TPButton secondary size="large" className={styles.button}>
                      <GetAppIcon sx={styles.buttonIcon} /> For iphone/ipad
                    </TPButton>
                  </TPGrid>
                  <TPGrid item xs={12}>
                    <TPButton secondary size="large">
                      <GetAppIcon sx={styles.buttonIcon} /> For MAC
                    </TPButton>
                  </TPGrid>
                </TPGrid>
              </TPGrid>
            </TPCardActions>
          </TPCardContent>
        </TPCard>
      </TPGrid>
      <TPGrid item xs={4}>
        <TPCard fullWidth>
          <TPCardContent>
            <TPCardImage className={styles.image} src={chartImg} alt="News image" />
            <TPTypography variant="body2">
              <Box component="ul" sx={styles.inlineList}>
                <Box component="li" sx={styles.inlineListItem}>
                  1 hour ago
                </Box>
                <Box component="li" sx={styles.inlineListItem}>
                  Action Forex
                </Box>
              </Box>
            </TPTypography>
            <TPCardTitle>GBP/USD Mid-Day Outlook</TPCardTitle>
            <TPTypography>
              Daily Pivots: (S1) 1.3004; (P) 1.3047; (R1) 1.3071; More … GBP/USD’s down trend
              resumes today by breaking through 1.2971 support. Intraday bias...
            </TPTypography>
            <TPTypography variant="body2">
              <Box component="ul" sx={styles.inlineList}>
                {['RB', 'AUD/USD', 'GBP/USD', 'NZD/USD'].map((tag) => (
                  <Box component="li" sx={styles.inlineListItem} key={tag}>
                    {tag}
                  </Box>
                ))}
              </Box>
            </TPTypography>
          </TPCardContent>
        </TPCard>
      </TPGrid>
      <TPGrid item xs={5}>
        <TPCard fullWidth>
          <TPCardHeader>
            <Box sx={styles.headerContainer}>
              <TPCardTitle className={styles.customTitle} semiBold>
                <DotIcon fontSize="small" sx={styles.marker} />
                GBP/USD
              </TPCardTitle>
              <TPTypography variant="h5" semiBold>
                1.28
              </TPTypography>
            </Box>
            <TPTypography>United Kingdom Pound - United States Dollar</TPTypography>
          </TPCardHeader>
          <TPCardContent divider>
            <TPGrid container justifyContent="space-between">
              <TPTypography>413 Publications</TPTypography>
              <TPTypography variant="body2" color="secondary">
                <TPNumberDifference value={-28} /> in the last 7 days
              </TPTypography>
            </TPGrid>
          </TPCardContent>
        </TPCard>
      </TPGrid>
    </TPGrid>
  );
};

export default reduxForm({
  form: 'TP-CARDS-FORM',
  initialValues: {
    account: '',
  },
})(Cards);
