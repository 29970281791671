export const ACCOUNT_SETTINGS_FORM = 'ACCOUNT_SETTINGS_FORM';

export const CLASSIC_VERIFICATION_FORM = 'CLASSIC_VERIFICATION_FORM';

export const CLASSIC_VERIFICATION_SECOND_STEP_FORM = 'CLASSIC_VERIFICATION_SECOND_STEP_FORM';

export const VERIFICATION_TYPE = {
  CLASSIC: 'classic',
  AUTOMATED: 'automated',
};

export const DOCUMENT_PASSPORT = 'PASSPORT';
export const DOCUMENT_ID_CARD = 'ID_CARD';
export const DOCUMENT_DRIVERS = 'DRIVERS';
export const DOCUMENT_ID_DOC_PHOTO = 'ID_DOC_PHOTO';
export const DOCUMENT_UTILITY_BILL = 'UTILITY_BILL';
export const DOCUMENT_BANK_STATEMENT = 'DOCUMENT_BANK_STATEMENT';

export const DOCUMENT_FRONT_SIDE = 'FRONT_SIDE';
export const DOCUMENT_BACK_SIDE = 'BACK_SIDE';

export const DOCUMENT_TYPES = [
  { value: DOCUMENT_DRIVERS, label: 'Drivers Licence' },
  { value: DOCUMENT_ID_CARD, label: 'Government Issued Photo ID' },
  { value: DOCUMENT_PASSPORT, label: 'Passport' },
  // { value: DOCUMENT_ID_DOC_PHOTO, label: 'Other' },
];

export const DOCUMENT_TYPES_SECOND_STEP = [
  { value: DOCUMENT_ID_CARD, label: 'Government Issued Photo ID' },
  { value: DOCUMENT_DRIVERS, label: 'Drivers Licence' },
  { value: DOCUMENT_PASSPORT, label: 'Passport' },
  { value: DOCUMENT_UTILITY_BILL, label: 'Utility Bill' },
  { value: DOCUMENT_BANK_STATEMENT, label: 'Bank Statement' },
  { value: DOCUMENT_ID_DOC_PHOTO, label: 'Birth Certificate' },
];

export const DOCUMENT_SIDES = [
  { value: DOCUMENT_FRONT_SIDE, label: 'Front' },
  { value: DOCUMENT_BACK_SIDE, label: 'Back' },
];

export const REQUIRE_BACKSIDE = new Set([DOCUMENT_DRIVERS, DOCUMENT_ID_CARD]);

export const STEP_CONFIRM_MODAL = 'STEP_CONFIRM_MODAL';

export const UPLOAD_BACKSIDE_MODAL = 'UPLOAD_BACKSIDE_MODAL';

export const AUSTRALIA = 'Australia';
