import { createSelector } from 'reselect';

import { PAGINATION_FORM, DEFAULT_PAGE_LIMIT } from '../constants';
import countSkip from '../helpers/countSkip';
import { formSelector } from 'selectors';

export const getTotalNumbers = (state) => state.pagination.totalNumbers;
export const getCurrentPage = (state) => state.pagination.currentPage;
export const getCurrentTable = (state) => state.pagination.currentTable;
export const getSelectedPageSize = (state) =>
  formSelector(PAGINATION_FORM, state, 'pageSize') || DEFAULT_PAGE_LIMIT;

export const getPaginatedItemsTotalNumber = createSelector(
  getTotalNumbers,
  getCurrentTable,
  (totalNumbers, currentTable) => {
    return totalNumbers[currentTable] || 0;
  },
);

export const getTableTotalNumber = (state, tableName) => {
  const totalNumbers = getTotalNumbers(state);
  return totalNumbers[tableName] || 0;
};

export const getPagesNumber = createSelector(
  getPaginatedItemsTotalNumber,
  getSelectedPageSize,
  (totalNumber, pageSize) => {
    const pagesNumber = Math.ceil(Number.parseInt(totalNumber) / pageSize);
    return pagesNumber;
  },
);

export const getCurrentSkip = createSelector(
  getCurrentPage,
  getSelectedPageSize,
  (currentPage, pageSize) => {
    const skip = countSkip(currentPage, pageSize);
    return skip;
  },
);
