import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from 'redux-form';
import PropTypes from 'prop-types';

import Quiz from 'modules/common/Quiz';
import {
  AML_QUIZ_FORM,
  AML_QUIZ_MODAL,
  AML_QUIZ_MODAL_TITLE,
  AML_QUIZ_MODAL_TITLE_END_ADORNMENT,
} from '../../constants';
import { getAMLQuestions } from '../../selectors';
import { getAmlQuestionsRequest, sendAmlAnswersRequest } from '../../actions';
import { createPendingSelector } from 'modules/api/selectors';
import { store } from 'components/App';
import AMLCompleteContainer from '../AMLCompleteContainer';
import EmploymentStatusForm from '../../components/EmploymentStatusForm';
import AMLSuccessModal from '../../components/AMLSuccessModal';
import { closeModal, openModal } from '../../../CustomModal/actions';

const AMLContainer = ({ withdrawForm }) => {
  const dispatch = useDispatch();

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [progressIndex, setProgressIndex] = useState(0);
  const questions = useSelector(getAMLQuestions);
  const quizLoading = useSelector(createPendingSelector(getAmlQuestionsRequest));
  const submitLoading = useSelector(createPendingSelector(sendAmlAnswersRequest));

  useEffect(() => {
    import('../../sagas').then((saga) => {
      store.injectSaga('AML', saga.default);
    });
  }, []);

  const handleNextQuestion = useCallback(
    (next) => {
      setCurrentQuestionIndex(next + 1);
      setProgressIndex(progressIndex < next + 1 ? next + 1 : progressIndex);
    },
    [progressIndex],
  );

  const handlePreviousQuestion = useCallback((next) => {
    setCurrentQuestionIndex(next - 1);
  }, []);

  const handleSubmit = useCallback(
    (answers) => {
      setProgressIndex(questions.length);
      dispatch(sendAmlAnswersRequest(answers));
    },
    [dispatch, questions.length],
  );

  const handleCancel = useCallback(() => {
    if (withdrawForm) {
      dispatch(reset(withdrawForm));
    }
    dispatch(closeModal(AML_QUIZ_MODAL));
    dispatch(reset(AML_QUIZ_FORM));
    dispatch(setCurrentQuestionIndex(0));
    dispatch(setProgressIndex(0));
  }, [dispatch, withdrawForm]);

  const handleAMLQuizOpen = useCallback(() => {
    dispatch(getAmlQuestionsRequest());
    dispatch(reset(AML_QUIZ_FORM));
    setCurrentQuestionIndex(0);
    setProgressIndex(0);
    dispatch(openModal(AML_QUIZ_MODAL));
  }, [dispatch]);

  return (
    <>
      <Quiz
        form={AML_QUIZ_FORM}
        modalName={AML_QUIZ_MODAL}
        title={AML_QUIZ_MODAL_TITLE}
        titleEndAdornment={AML_QUIZ_MODAL_TITLE_END_ADORNMENT}
        progressIndex={progressIndex}
        currentQuestionIndex={currentQuestionIndex}
        loading={quizLoading || submitLoading}
        onNext={handleNextQuestion}
        onPrevious={handlePreviousQuestion}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        questions={questions}
        questionComponent={
          questions.length && questions[currentQuestionIndex].hasNestedQuestions
            ? EmploymentStatusForm
            : null
        }
      />
      <AMLCompleteContainer withdrawForm={withdrawForm} onOpenQuiz={handleAMLQuizOpen} />
      <AMLSuccessModal />
    </>
  );
};

AMLContainer.propTypes = {
  withdrawForm: PropTypes.string,
};

export default AMLContainer;
