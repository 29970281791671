import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'material-latest';

import { TPSelect } from 'components/TP-UI/TPSelect';
import TPTypography from 'components/TP-UI/TPTypography';
import TPLanguageIcon from './components/TPLanguageIcon';
import { MENU_PROPS_CENTER, MENU_PROPS_LEFT } from './config';

import styles from './styles';

export const TPLanguageSelect = ({
  name,
  value,
  options,
  error,
  reservedErrorSpace = false,
  minimized = false,
  fullWidth = false,
  hideArrow = true,
  selectDisplayProps,
  selectClasses,
  onChange,
  onBlur,
  className,
}) => {
  const menuProps = useMemo(
    () => ({
      ...(minimized ? MENU_PROPS_CENTER : MENU_PROPS_LEFT),
      classes: { paper: styles.menuPaper },
    }),
    [minimized],
  );

  const renderMinimizedLanguage = useCallback((option) => <TPLanguageIcon {...option} />, []);

  const renderLanguageLabel = useCallback(
    (option) => (
      <Box sx={styles.languageLabelContainer}>
        <TPLanguageIcon {...option} />
        <TPTypography variant="body2" component="span" className={styles.label}>
          {option.label}
        </TPTypography>
      </Box>
    ),
    [],
  );

  return options.length && options.length > 0 ? (
    <TPSelect
      name={name}
      variant="custom"
      value={value}
      options={options}
      error={error}
      reservedErrorSpace={reservedErrorSpace}
      optionValue="language"
      hideArrow={hideArrow}
      fullWidth={fullWidth}
      className={className}
      renderValue={renderMinimizedLanguage}
      renderOption={minimized ? renderMinimizedLanguage : renderLanguageLabel}
      menuProps={menuProps}
      selectDisplayProps={selectDisplayProps}
      selectClasses={selectClasses}
      onChange={onChange}
      onBlur={onBlur}
    />
  ) : null;
};

TPLanguageSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      language: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.string,
    }),
  ),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Reserved space to display error in 1 line
   */
  reservedErrorSpace: PropTypes.bool,
  minimized: PropTypes.bool,
  fullWidth: PropTypes.bool,
  hideArrow: PropTypes.bool,
  selectDisplayProps: PropTypes.object,
  selectClasses: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

const TPLanguageReduxSelect = ({ input, meta, ...others }) => {
  const error = meta.submitFailed && meta.error ? meta.error : null;
  return (
    <TPLanguageSelect
      {...input}
      error={error}
      {...others}
      onChange={(value) => {
        input.onChange(value);
      }}
      onBlur={() => input.onBlur(input.value)}
    />
  );
};

export default TPLanguageReduxSelect;
