export const MENU_ITEMS = [
  { id: 'forex', type: 'forex', forexFilter: 'major', name: 'Forex' },
  { id: 'cryptos', type: 'forex', forexFilter: 'crypto', name: 'Cryptos' },
  { id: 'commodities', type: 'commodities', name: 'Commodities' },
  { id: 'index', type: 'index', name: 'Indices' },
  { id: 'stock', type: 'stock', name: 'Stocks' },
];

export const FIELDS_ORDER = ['preference', 'alternative', 'comment', 'supportAndResist'];

export const FOREX_FILTER = [
  'all',
  'popular',
  'major',
  'g7',
  'pacific',
  'exotic',
  'nordic',
  'eastern',
];

export const FOREX_FITLER_FORM = 'FOREX_FITLER_FORM';
