import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getParams } from 'selectors';
import config from 'config';

const localstorageKey = 'bypassmaintenance';
const bypassToken = config.MAINTENANCE_BYPASS_TOKEN;

const getIsMaintenance = (params) => {
  const localstorage = window.localStorage.getItem(localstorageKey) === bypassToken;
  const queryParam = params?.bypass === bypassToken;
  return !(localstorage || queryParam);
};

const useMaintenancePage = () => {
  const params = useSelector(getParams);
  const [isMaintenance] = useState(() => getIsMaintenance(params));

  useEffect(() => {
    if (params?.bypass === bypassToken) {
      window.localStorage.setItem(localstorageKey, bypassToken);
    }
  }, []);

  if (!bypassToken) return false;

  return isMaintenance;
};

export default useMaintenancePage;
