const styles = (theme) => ({
  question: {
    display: 'block',
    color: theme.palette.text.main,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '21px',
    marginBottom: '24px',
  },
});

export default styles;
