import { combineActions, handleActions } from 'redux-actions';

import * as signUpActionCreators from '../pages/SignUp/actions';
import * as signInActionCreators from '../pages/SignIn/actions';
import * as migrationActionCreators from '../pages/Migration/actions';
import * as actionCreators from '../actions';
import * as leadActionCreators from 'modules/lead/actions';
import * as userActionCreators from 'modules/userManagement/pages/UserDetails/actions';

const defaultState = false;

export default handleActions(
  {
    [combineActions(
      signUpActionCreators.signupSuccess,
      signInActionCreators.signinSuccess,
      signInActionCreators.signinNonfxSuccess,
      signUpActionCreators.googleAuthSuccess,
      migrationActionCreators.userMigrationSuccess,
      leadActionCreators.leadUserJoinSuccess,
      userActionCreators.signinByEmailSuccess,
    )]() {
      return true;
    },
    [combineActions(actionCreators.logout)]() {
      return defaultState;
    },
  },
  defaultState,
);
