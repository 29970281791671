import createAction from 'redux-actions/es/createAction';

export const startTMDProcess = createAction('START_TMD_PROCESS');

export const sendTmdAnswersRequest = createAction('SEND_TMD_ANSWERS_REQUEST');
export const sendTmdAnswersSuccess = createAction('SEND_TMD_ANSWERS_SUCCESS');
export const sendTmdAnswersFail = createAction('SEND_TMD_ANSWERS_FAIL');

export const getTmdQuestionsRequest = createAction('GET_TMD_QUESTIONS_REQUEST');
export const getTmdQuestionsSuccess = createAction('GET_TMD_QUESTIONS_SUCCESS');
export const getTmdQuestionsFail = createAction('GET_TMD_QUESTIONS_FAIL');

export const lockFailedTMDUser = createAction('LOCK_FAILED_TMD_USER');

export const setTmdCurrentQuestionIndex = createAction('SET_TMD_CURRENT_QUESTION_INDEX');
export const setTmdProgressIndex = createAction('SET_TMD_PROGRESS_INDEX');
