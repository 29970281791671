import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import InternalHubTable from 'modules/common/InternalHubTable/Table';
import FieldsFactory from 'modules/common/TableFields/FieldsFactory';
import styles from './styles';
import ScrollOnTopButton from 'modules/common/ScrollOnTopButton';
import Header from '../../components/BaseHeader';
import formatPrice from '../../../../utils/formatPrice';

const NetDepositView = ({
  items,
  isPending,
  isDataFiltered,
  getRedirectRoute,
  headerProps,
  onSort,
  isSorted,
  getSortDirection,
  // onPageChange,
}) => {
  const props = {
    isDataLoading: isPending,
    tableMessageProps: {
      isDataFiltered,
      message: 'No data',
    },
    tableFields: [
      {
        label: 'Name',
        dataLabel: 'name',
        getRedirectRoute,
        Component: FieldsFactory.create('TableSingleTextField')
          .addTheme('boldText')
          .get(),
      },
      {
        label: 'Email',
        dataLabel: 'email',
        getRedirectRoute,
        Component: FieldsFactory.create('TableSingleTextField')
          .addTheme('boldText')
          .get(),
      },
      {
        label: 'Deposit',
        dataLabel: 'deposit',
        formatFunc: formatPrice,
        Component: FieldsFactory.create('TableTextField')
          .addSorting(onSort, isSorted, getSortDirection)
          .addTheme('fixedCell')
          .addTheme('normalCell')
          .get(),
      },
      {
        label: 'Withdraw',
        dataLabel: 'withdraw',
        formatFunc: formatPrice,
        Component: FieldsFactory.create('TableTextField')
          .addSorting(onSort, isSorted, getSortDirection)
          .addTheme('fixedCell')
          .addTheme('normalCell')
          .get(),
      },
      {
        label: 'Net Deposit',
        dataLabel: 'netDepoist',
        formatFunc: formatPrice,
        Component: FieldsFactory.create('TableTextField')
          .addSorting(onSort, isSorted, getSortDirection)
          .addTheme('fixedCell')
          .addTheme('normalCell')
          .get(),
      },
    ],
    data: items,
    // paginationProps: {
    //   onPageChange,
    // },
  };

  return (
    <>
      <Header {...headerProps} />
      <InternalHubTable {...props} />
      <ScrollOnTopButton />
    </>
  );
};

export default withStyles(styles)(NetDepositView);
