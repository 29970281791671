import { combineReducers } from 'redux';

import reportData from './reportData';
import invitationLink from './invitationLink';

const reducer = combineReducers({
  reportData,
  invitationLink,
});

export default reducer;
