import { CURRENCIES_BY_HUB, CURRENCY_MAPPER, DEFAULT_CURRENCY, LEVERAGES } from 'constants/index';

export const getCurrencyByCountry = (hub, country) => {
  const currencies = CURRENCIES_BY_HUB[hub] || [];
  let currency;
  if (country in CURRENCY_MAPPER) {
    currency = CURRENCY_MAPPER[country];
  } else {
    currency = DEFAULT_CURRENCY;
  }

  const existingCurrency = currencies.find(({ label }) => label === currency);
  if (!existingCurrency) {
    return currencies[0]?.value;
  }

  return existingCurrency.value;
};

export const getLeverageByHub = (isPro) => {
  if (isPro) {
    return LEVERAGES['PRO'];
  }
  return LEVERAGES['RETAIL'];
};
