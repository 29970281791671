import { ERROR_MESSAGE_HEIGHT } from '../../constants';

const styles = (theme) => ({
  textFieldContainer: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
  },
  bottomErrorPadding: {
    paddingBottom: ERROR_MESSAGE_HEIGHT,
  },
  textField: {
    '&:not($disabled):not($focused):not($textFieldError) $notchedOutline': {
      borderColor: theme.palette.divider.darkGray,
    },
    '&$textFieldFocused:not($disabled):not($textFieldError) $notchedOutline': {
      borderColor: theme.palette.icon.lightGrey,
    },
    '&:hover:not($disabled):not($focused):not($textFieldError) $notchedOutline': {
      borderColor: theme.palette.divider.darkGray,
      borderWidth: '1px',
    },
    '&$textFieldFocused $notchedOutline': {
      borderColor: theme.palette.icon.lightGrey,
      borderWidth: '1px',
    },
    '&:hover:not($disabled):not($focused):not($error) $dropDownIcon': {
      color: theme.palette.icon.black,
    },
    '& $dropDownIcon': {
      color: theme.palette.icon.black,
    },
    '&$textFieldFocused $dropDownIcon': {
      color: theme.palette.icon.black,
    },
    '&$textFieldError $notchedOutline': {
      borderColor: `${theme.palette.error.main} !important`,
    },
    height: '48px',
    color: theme.palette.text.main,
    lineHeight: '20px',
    fontSize: '16px',
    textAlign: 'left',
  },
  topSpaceMenuClass: {
    top: '0px !important',
    '@media (max-width:768px)': {
      top: '-28px !important',
    },
  },
  menu: {
    top: '-28px',
  },
  menuList: {
    paddingBottom: '0px',
    paddingTop: '0px',
  },
  label: {
    color: theme.palette.simpleGrey,
    fontSize: '15px',
    lineHeight: '15px',
    '&$labelFocused': {
      color: theme.palette.simpleGrey,
    },
    '&$labelError': {
      color: `${theme.palette.error.main} !important`,
    },
  },
  labelFocused: {},
  notchedOutline: {
    borderWidth: '1px !important',
    borderColor: theme.palette.divider.darkGray,
  },
  textFieldFocused: {},
  focused: {},
  disabled: {},
  selectContainer: {
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuItem: {
    minWidth: '205px',
    maxWidth: '310px',
    minHeight: '44px',
    height: 'auto',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: theme.picker.hoverBackgroundColor,
    },
  },
  alphabeticalMenuItem: {
    width: '288px',
    height: '38px',
    boxSizing: 'border-box',
    paddingLeft: '35px',
  },
  menuLabel: {
    color: theme.palette.text.main,
    fontSize: '16px',
    lineHeight: '20px',
    maxWidth: '300px',
    whiteSpace: 'normal',
    flex: 1,
  },
  menuPaper: {
    boxShadow: '0 10px 10px 0 rgba(0,0,0,0.13) !important',
    borderRadius: '8px',
    marginLeft: 0,
    '@media (min-width:768px)': {
      marginLeft: '-50px',
    },
  },
  dropDownIcon: {
    color: '#8E96B3',
    marginRight: '14px',
    fontSize: '18px',
    marginTop: '3px',
  },
  select: {
    '&:active': {
      background: 'none',
    },
    '&:focus': {
      background: 'none',
    },
  },
  menuItemSelected: {
    backgroundColor: '#DADFEC !important',
  },
  hiddenItem: {
    display: 'none !important',
  },
  placeholderLabel: {
    color: theme.palette.simpleGrey,
  },
  itemsContainer: {
    backgroundColor: theme.picker.backgroundColor,
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  errorSelect: {
    ...theme.textError,
    bottom: 0,
    maxHeight: ERROR_MESSAGE_HEIGHT - 3,
  },
  errorIcon: {
    fontSize: 12,
  },
  textFieldError: {},
  error: {},
  labelError: {},
  labelDisabled: {
    color: '#888EB4',
  },
  firstItemLetter: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
  },
  firstLetterContainer: {
    position: 'absolute',
    top: 10,
    left: 11,
  },
  delimiter: {
    height: '1px',
    width: '100%',
    borderTop: '1px solid #DADFEC',
  },
  alphabeticalValuesContainer: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
});

export default styles;
