import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { DATE } from '../../config';

const TPDatePickerMask = (props1) => {
  const { inputRef, mask = DATE.dateMask, ...props } = props1;
  return (
    <MaskedInput
      {...props}
      ref={(ref) => {
        inputRef && inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      guide={false}
    />
  );
};

TPDatePickerMask.propTypes = {
  mask: PropTypes.array,
};

export default TPDatePickerMask;
