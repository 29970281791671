import { combineActions, handleActions } from 'redux-actions';
import get from 'lodash/get';

import * as signInActionCreators from '../pages/SignIn/actions';
import * as actionCreators from '../actions';
import * as userActionCreators from 'modules/userManagement/pages/UserDetails/actions';

const defaultState = null;

export default handleActions(
  {
    [combineActions(signInActionCreators.signinSuccess, userActionCreators.signinByEmailSuccess)](
      state,
      action,
    ) {
      return get(action, 'response.data.proxy.token');
    },
    [combineActions(actionCreators.logout, signInActionCreators.updateToken)]() {
      return defaultState;
    },
  },
  defaultState,
);
