import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'material-latest/Avatar';

import styles from './styles';

const TPLanguageIcon = ({ label, icon }) => {
  return <Avatar sx={styles.lngIcon} alt={label} src={icon} />;
};

TPLanguageIcon.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
};

export default TPLanguageIcon;
