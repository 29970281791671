import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import TableRowContainer from '../../containers/TableRowContainer';
import classNames from 'classnames';

const TableList = ({
  classes,
  tableFields,
  data,
  minHeight,
  generateTableItemId,
  maxHeight,
  ExpandedRowContent,
  onExpand,
  expendedRowId,
  onClick = () => {},
  isVerificationHistoryTable,
}) => {
  const scrollbarProps = !isVerificationHistoryTable
    ? {
        renderTrackHorizontal: () => <div className={classes.invisibleScroll} />,
        renderTrackVertical: () => <div className={classes.invisibleScroll} />,
      }
    : null;
  return (
    <>
      <TableRowContainer
        tableItem={{}}
        tableFields={tableFields.map((item) => ({ ...item, showLabel: true }))}
        isExpanded={false}
        onExpand={() => {}}
        onClick={() => {}}
        className={classNames(classes.titleContainer, {
          [classes.verificationTitleContainer]: isVerificationHistoryTable,
        })}
        isHeadRow
      />
      <Scrollbars
        {...scrollbarProps}
        autoHeight
        autoHeightMin={minHeight || 300}
        autoHeightMax={maxHeight || '65vh'}>
        <div
          className={classNames(classes.itemsContainer, {
            [classes.verificationItemsContainer]: isVerificationHistoryTable,
          })}>
          <Suspense fallback="">
            {data.map((item, i) => {
              const rowId =
                item._id || (generateTableItemId && generateTableItemId(item)) || `TableList-${i}`;
              return (
                <TableRowContainer
                  key={rowId}
                  tableItem={item}
                  tableFields={tableFields}
                  ExpandedRowContent={ExpandedRowContent}
                  isExpanded={expendedRowId === rowId}
                  rowIndex={i}
                  onExpand={() =>
                    onExpand(item._id || (generateTableItemId && generateTableItemId(item)))
                  }
                  onClick={onClick}
                />
              );
            })}
          </Suspense>
        </div>
      </Scrollbars>
    </>
  );
};

TableList.propTypes = {
  tableFields: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  generateTableItemId: PropTypes.func,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
};

export default withStyles(styles)(TableList);
