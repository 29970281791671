import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = [];

export default handleActions(
  {
    [actionCreators.getBulkMigrateToIB](state, action) {
      return action.payload.bulkMigrateToIB;
    },
    [actionCreators.operationsBulkMigrateToIBFail]() {
      return defaultState;
    },
  },
  defaultState,
);
