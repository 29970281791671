const styles = () => ({
  root: {},
  container: {
    position: 'relative',
    minWidth: 'fit-content',
  },
  loaderContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgb(250, 251, 252, 0.3)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingTop: '80px',
    zIndex: 1,
  },
  tableRoot: {
    overflowX: 'auto',
    marginBottom: 15,
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 4,
  },
});

export default styles;
