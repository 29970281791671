import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { startTMDProcess } from 'modules/common/TMD/actions';
import { store } from 'components/App';
import { getUserPassedQuiz, getUserTMDData } from '../modules/auth/selectors';
import { setupQuiz } from '../modules/profileQuiz/actions';

export default (showingPreQuiz = true) => {
  const dispatch = useDispatch();
  const tmdData = useSelector(getUserTMDData);
  const isAnyQuizAlreadyPassed = useSelector(getUserPassedQuiz);

  useEffect(() => {
    import('modules/common/TMD/sagas').then((saga) => {
      store.injectSaga('TMD', saga.default);
    });
  }, []);

  const startQuiz = useCallback(() => {
    if (tmdData || isAnyQuizAlreadyPassed) {
      dispatch(setupQuiz({ showingPreQuiz }));
    } else {
      dispatch(startTMDProcess());
    }
  }, [tmdData, isAnyQuizAlreadyPassed, dispatch, showingPreQuiz]);

  return startQuiz;
};
