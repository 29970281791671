import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';
const defaultState = '';

export default handleActions(
  {
    [actionCreators.getConversionRateDepositSuccess](state, action) {
      return action.response.data.rate;
    },
  },
  defaultState,
);
