import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import TodayIcon from '@mui/icons-material/Today';

import { TPTextField } from 'components/TP-UI/TPTextField';
import TPInputAdornment from 'components/TP-UI/TPInputAdornment';
import { SIZES } from 'components/TP-UI/constants';
import TPDatePickerMask from '../TPDatePickerMask';

const TPDatePickerInput = ({
  name,
  value = '',
  size = SIZES.MEDIUM,
  clearable = false,
  onChange,
  onClick,
  ...props
}) => {
  const handleChange = useCallback(
    (_, event) => {
      onChange(event);
    },
    [onChange],
  );

  return (
    <TPTextField
      name={name}
      value={value}
      size={size}
      reservedErrorSpace={false}
      clearable={clearable}
      onChange={handleChange}
      onClick={onClick}
      {...props}
      InputProps={{ inputComponent: TPDatePickerMask }}
      startAdornment={
        <TPInputAdornment>
          <TodayIcon fontSize={size} />
        </TPInputAdornment>
      }
    />
  );
};

TPDatePickerInput.propTypes = {
  /**
   * Form name
   */
  name: PropTypes.string.isRequired,
  /**
   * Picker value
   */
  value: PropTypes.string,
  /**
   * Show field clear button
   */
  clearable: PropTypes.bool,
  /**
   * Handling date changes
   */
  onChange: PropTypes.func,
  /**
   * Event to open the calendar
   */
  onClick: PropTypes.func,
};

export default React.forwardRef((props, ref) => (
  <TPDatePickerInput {...props} forwardedRef={ref} />
));
