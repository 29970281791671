import React from 'react';
import { Route, Redirect, useLocation } from 'react-router/';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import { getUserLanguage } from 'modules/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import { Routes } from 'constants/routeConstants';
import {
  WITHDRAWAL_TOKEN,
  JOIN_FUSION_PLUS_TOKEN,
  JOIN_FUSION_PLUS_HUB,
} from '../../auth/pages/SignIn/constants';
import getAuthData from 'modules/auth/helpers/getAuthData';
import { signinNonfxSuccess } from 'modules/auth/pages/SignIn/actions';

const PrivateRoute = ({
  isAuthenticated,
  adminOnly,
  staffOnly,
  isAdmin,
  isManager,
  allAuthorized,
  allowAnonymous,
  component,
  ...props
}) => {
  const prefix = useSelector(getUserLanguage);
  const isStaff = isAdmin || isManager;
  const search = useLocation().search;
  const preAuthData = getAuthData(search);
  const dispatch = useDispatch();

  const RouteComponent = (
    <Route path={(prefix || '') + props.path} {...props} component={component} />
  );

  /*
    SignIn after successful signUp from Fusion-market.com | NonFX website
  */
  if (preAuthData) {
    const { _id, token, refreshToken } = preAuthData;

    dispatch(
      signinNonfxSuccess({
        token: token,
        refreshToken: refreshToken,
        user: { _id: _id, role: 'client' },
      }),
    );

    return RouteComponent;
  }

  if (adminOnly) {
    return isAdmin ? RouteComponent : <Redirect to={Routes.SIGNIN} />;
  }

  if (!isAuthenticated) {
    const fullPath = get(props, 'location.pathname', '');
    if (
      isEqual(props.path, Routes.PAYMENTS) &&
      !isEqual(props.path, Routes.CONFIRM_PAYMENT) &&
      !isEqual(fullPath.length, Routes.PAYMENTS.length)
    ) {
      const token = fullPath.replace(`${Routes.PAYMENTS}/`, '');

      //TODO: make all withdrawals token through getApproveUrl
      if (token.length > 200)
        Cookies.set(WITHDRAWAL_TOKEN, token, { sameSite: 'none', secure: true });
    }

    const path = '/' + last(props.path.split('/'));
    if (path === Routes.MY_FOLLOWERS_JOIN) {
      const token = last(props.location.pathname.split('/'));
      const query = queryString.parse(props.location.search);
      Cookies.set(JOIN_FUSION_PLUS_TOKEN, token, { sameSite: 'none', secure: true });
      if (query.followerHub) {
        Cookies.set(JOIN_FUSION_PLUS_HUB, query.followerHub, { sameSite: 'none', secure: true });
      }
    }
    return allowAnonymous ? RouteComponent : <Redirect to={Routes.SIGNIN} />;
  }

  if (allowAnonymous) {
    return isStaff ? (
      <Redirect to={Routes.VERIFICATION_MANAGEMENT} />
    ) : (
      <Redirect to={(prefix || '') + Routes.MY_ACCOUNTS} />
    );
  }

  if (staffOnly) {
    return isStaff ? RouteComponent : <Redirect to={Routes.SIGNIN} />;
  }

  if (allAuthorized) {
    return RouteComponent;
  }

  return isStaff ? <Redirect to={Routes.SIGNIN} /> : RouteComponent;
};

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  staffOnly: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isManager: PropTypes.bool,
  allAuthorized: PropTypes.bool,
  allowAnonymous: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.object]),
  props: PropTypes.object.isRequired,
};

export default PrivateRoute;
