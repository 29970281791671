import get from 'lodash/get';
import { all, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { showNotificationInfo, showNotificationError } from 'modules/notifications/actions';
import { signinByEmailSuccess, signinByEmailFail } from '../actions';
import { Routes } from 'constants/routeConstants';

function* signinByEmailSuccessHandler() {
  const email = localStorage.getItem('adminEmail');
  yield put(push(Routes.HOME));
  if (email) {
    yield put(showNotificationInfo(`Login as user successfully`));
  }
}

function* signinByEmailFailHandler(action) {
  const errorMessage = get(action, 'error.data.message', 'Error');
  localStorage.removeItem('adminEmail');
  localStorage.removeItem('adminToken');
  yield put(showNotificationError(errorMessage));
}

export default function* watchRequest() {
  yield all([
    takeEvery(signinByEmailSuccess, signinByEmailSuccessHandler),
    takeEvery(signinByEmailFail, signinByEmailFailHandler),
  ]);
}
