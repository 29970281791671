export default (theme) => ({
  root: {
    padding: '35px',
  },
  header: {
    marginBottom: 15,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.greyBlue,
  },
  title: {
    color: '#282525',
    fontSize: '28px',
    fontWeight: 300,
    lineHeight: '34px',
    marginBottom: 30,
  },
  buttonsSection: {
    textAlign: 'right',
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    fontWeight: 900,
  },
});
