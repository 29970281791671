import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = [];

export default handleActions(
  {
    [actionCreators.putUserRecentPayments](state, action) {
      return action.payload.userPayments;
    },
  },
  defaultState,
);
