const styles = (theme) => ({
  button: {
    height: 34,
    fontSize: 12,
    fontWeight: 900,
    paddingLeft: 13,
    paddingRight: 13,
    whiteSpace: 'nowrap',
  },
  item: {
    paddingTop: 16,
    paddingBottom: 16,
    textAlign: 'center',
    width: 204,
    minWidth: 204,
  },
  button__approve: {
    width: '116px',
    fontWeight: 700,
    border: `2px solid ${theme.palette.green2}`,
  },
});

export default styles;
