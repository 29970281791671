import React, { useEffect } from 'react';
import { Field, getFormValues, change } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';

import { RadioGroup } from '../../../RadioClassic';
import { required } from '../../../../../utils/validation/fieldValidationRules';
import TextInput from 'modules/common/TextInput';
import Picker from '../../../Picker';

import styles from './styles';

const EmploymentStatusForm = ({ classes, questionId, questionLabel, answers, form }) => {
  const formValues = useSelector(getFormValues(form));
  const dispatch = useDispatch();
  const selectedAnswer = formValues[questionId]
    ? answers.find((answer) => answer.value === formValues[questionId])
    : null;

  useEffect(() => {
    // cleanup nested fields if new answer doesn't have any
    if (selectedAnswer && !selectedAnswer.questions) {
      const nestedFields = answers.reduce((acc, answer) => {
        if (answer.questions?.length) {
          acc = [...acc, ...answer.questions.map(({ _id }) => _id)];
        }
        return acc;
      }, []);
      if (nestedFields.length) {
        nestedFields.forEach((field) => {
          dispatch(change(form, field, undefined));
        });
      }
    }
  }, [answers, dispatch, form, selectedAnswer]);

  return (
    <div>
      <Typography className={classes.question}>{questionLabel}</Typography>
      {Array.isArray(answers) && (
        <Field buttons={answers} component={RadioGroup} name={questionId} validate={[required]} />
      )}
      {selectedAnswer?.questions?.length ? (
        <div className={classes.customQuestions}>
          {selectedAnswer.questions.map((question) => (
            <div className={classes.input} key={question._id}>
              <Field
                key={question._id}
                name={question._id}
                label={question.label}
                type="text"
                component={Picker}
                values={question.answers}
                validate={[required]}
                fullWidth
                placeholder={question.label}
                required
              />
            </div>
          ))}
          {Object.values(formValues).includes('other') ? (
            <div className={classes.input}>
              <Field
                name="other"
                label="Industry"
                validate={[required]}
                required
                component={TextInput}
                fullWidth
                placeholder="Industry"
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

EmploymentStatusForm.propTypes = {
  questionId: PropTypes.string.isRequired,
  questionLabel: PropTypes.string.isRequired,
  answers: PropTypes.array.isRequired,
  form: PropTypes.string.isRequired,
};

export default withStyles(styles)(EmploymentStatusForm);
