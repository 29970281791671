import { combineReducers } from 'redux';

import currencySpecs from './currencySpecs';
import results from './results';

const reducer = combineReducers({
  currencySpecs,
  results,
});

export default reducer;
