import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';

import {
  PRO_QUIZ_MODAL,
  RETAIL_PRO_QUIZ_MODAL_TITLE_END_ADORNMENT,
  RETAIL_QUIZ_MODAL,
} from '../../constants';
import { ACCOUNT_PLANS } from '../../../../constants/acctountPlans';
import CustomModal from '../../../common/CustomModal';
import ImageSubtitleTextContent from '../../../common/ContentModal/ImageSubtitleTextContent';

import quizSuccessImage from '../../../../static/quizzes/quiz-success.svg';
import styles from './styles';

const PreQuizModal = ({ classes, onSubmit, modalName, onCancel, isProPlan, questions }) => {
  const { t } = useTranslation('common');

  const quizModalName = isProPlan ? PRO_QUIZ_MODAL : RETAIL_QUIZ_MODAL;
  const title = t(`quiz:${isProPlan ? ACCOUNT_PLANS.PRO : ACCOUNT_PLANS.RETAIL}.page.title`);

  const handleClick = useCallback(() => {
    if (onSubmit) {
      onSubmit(quizModalName);
    }
  }, [onSubmit, quizModalName]);

  const buttons = useMemo(() => {
    return [
      {
        label: t('common:buttons.cancel'),
        variant: 'text',
        onClick: onCancel ? onCancel : () => {},
      },
      {
        label: t('quiz:buttons.startQuiz'),
        onClick: handleClick,
        color: 'primary',
        type: 'submit',
      },
    ];
  }, [t, handleClick, onCancel]);

  return (
    <CustomModal
      modalClassNames={classes.modal}
      className={classes.modalPaper}
      modalName={modalName}
      title={title}
      titleEndAdornment={RETAIL_PRO_QUIZ_MODAL_TITLE_END_ADORNMENT}
      isCloseButtonNeeded
      buttons={buttons}
      customCloseModal={onCancel}>
      <ImageSubtitleTextContent
        image={quizSuccessImage}
        subtitle={title}
        text={
          isProPlan
            ? t('quiz:pro.page.note')
            : t('quiz:retail.page.note', { questionsAmount: questions?.length })
        }
      />
    </CustomModal>
  );
};

PreQuizModal.propTypes = {
  modalName: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
      label: PropTypes.string,
    }),
  ),
};

export default withStyles(styles)(PreQuizModal);
