import get from 'lodash/get';
import isArray from 'lodash/isArray';

export const getFieldData = (label, dataLabel, tableItem) => {
  let fieldLabel = label;
  let fieldValue = get(tableItem, dataLabel);
  if (isArray(label) && isArray(dataLabel)) {
    const fieldValueIndex = dataLabel.findIndex((fieldData) => get(tableItem, fieldData));
    fieldLabel = label[fieldValueIndex];
    fieldValue = get(tableItem, dataLabel[fieldValueIndex]);
  }
  return { fieldLabel, fieldValue };
};
