import 'regenerator-runtime/runtime';
import { all } from 'redux-saga/effects';

import apiCallsSaga from 'modules/api/sagas';
import notificationsSaga from 'modules/notifications/sagas';
import accountsSaga from 'modules/accounts/sagas';
import authSaga from 'modules/auth/sagas';
import lpoaSaga from 'modules/lpoa/sagas';
import geoSaga from './modules/geolocation/sagas';
import verificationModalsRunnerSaga from './modules/VerificationModalsManager/sagas/verificatioModalsRunner';
import setupQuizSaga from './modules/profileQuiz/sagas';
import upgradeToPro from './modules/upgradeToProV2/sagas';
import userNotificationsSaga from 'modules/userNotifications/sagas';
import loginAsUser from './modules/userManagement/pages/UserDetails/sagas/loginAsUser';
import rootModuleSaga from './modules/rootModule/sagas';

export const createSagaInjector = (runSaga) => {
  const injectedSagas = new Map();
  const isInjected = (key) => injectedSagas.has(key);
  const injectSaga = (key, saga) => {
    if (isInjected(key)) {
      if (saga === null) {
        //stop watching the requests
        injectedSagas.get(key).cancel();
        injectedSagas.delete(key);
      }
      return;
    } else if (saga === null) {
      return;
    }
    const task = runSaga(saga);
    injectedSagas.set(key, task);
  };
  return injectSaga;
};

function* rootSaga() {
  yield all([
    rootModuleSaga(),
    apiCallsSaga(),
    notificationsSaga(),
    accountsSaga(),
    authSaga(),
    lpoaSaga(),
    verificationModalsRunnerSaga(),
    setupQuizSaga(),
    upgradeToPro(),
    geoSaga(),
    userNotificationsSaga(),
    loginAsUser(),
  ]);
}

export default rootSaga;
