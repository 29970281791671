import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core';

import styles from './styles';

const JoinPlusTerms = ({ classes }) => {
  return (
    <div>
      <p className={classes.p}>
        These terms and conditions contain important information, including disclaimers and
        limitations of liability.
      </p>
      <p className={classes.title}>PART A: OVERVIEW AND MEMBERSHIP</p>
      <ol className={classes.ol}>
        <li className={classes.p}>
          <p className={classes.title}>Customer acceptance</p>
        </li>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>
            This agreement, governed by these terms and conditions (
            <strong className={classes.strong}>Terms</strong>), is made by and between Gleneagle
            Securities Pty Ltd T/A Global Prime (referred to as{' '}
            <strong className={classes.strong}>Fusion</strong>,{' '}
            <strong className={classes.strong}>we</strong>,{' '}
            <strong className={classes.strong}>us</strong>,{' '}
            <strong className={classes.strong}>our</strong>) and the Customer (referred to as{' '}
            <strong className={classes.strong}>you</strong> or{' '}
            <strong className={classes.strong}>your</strong>).&nbsp;
          </li>
          <li className={classes.p}>
            Subject to the terms and conditions of this agreement, Fusion agrees to provide the
            Services.
          </li>
          <li className={classes.p}>
            The Customer agrees to use the Services in good faith in accordance with these Terms and
            Applicable Laws.
          </li>
          <li className={classes.p}>
            The Customer agrees to be bound by these Terms by using, browsing or accessing any part
            of the Services. If you do not accept these Terms, you must not use the Services.
          </li>
          <li className={classes.p}>
            The Customer agrees to comply with any policies, procedures, operating rules and
            directions of Fusion from time to time in relation to the operation of the Services,
            your access to the Fusion Services and the manner of performance of your obligations
            under these Terms.
          </li>
        </ol>
        <li className={classes.p}>
          <p className={classes.title}>Amendments to Services and Terms</p>
        </li>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>
            The Services are subject to change at any time without notice and may contain errors.
          </li>
          <li className={classes.p}>
            Fusion may from time to time review and update these Terms, including to take account of
            new laws, regulations, products or technology. Your use of the Fusion Services will be
            governed by the most recent Terms posted on the Website. By continuing to use the
            Services, you agree to be bound by the most recent Terms which will be made available on
            the Website. It is your responsibility to check the Website regularly for updated
            versions of the Terms.
          </li>
        </ol>
        <li className={classes.p}>
          <p className={classes.title}>Entire agreement</p>
          <p className={classes.p}>
            This agreement is intended to cover all Services provided by Fusion and received by the
            customer. This agreement contains the entire understanding between Fusion and Customer
            with respect to the Services described in the Order and supersedes all prior agreements,
            understandings, negotiations and discussions, whether oral or written, between Fusion
            and the Customer. The Customer acknowledges and agrees that its entire right relating to
            Fusion Services are as set forth in this agreement.
          </p>
        </li>
        <li className={classes.p}>
          <p className={classes.title}>Term</p>
        </li>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>This Agreement commences when we provide Services to you.</li>
          <li className={classes.p}>
            Unless terminated by Fusion or in accordance with clause 10 of this agreement, this
            agreement will automatically continue.
          </li>
        </ol>
        <li className={classes.p}>
          <p className={classes.title}>Termination of Agreement</p>
        </li>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>
            If the Customer breaches any of its obligations under this agreement or the Acceptable
            Use Policy, Fusion may terminate this agreement at any time and without prior notice.
          </li>
        </ol>
        <li className={classes.p}>
          <p className={classes.title}>AML/CTF Obligations</p>
        </li>
        <p className={classes.p}>The Customer acknowledges and agrees:</p>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>
            in order for Fusion to meet its obligations under the{' '}
            <em>Anti-Money Laundering and Counter-Terrorism Financing Act 2006</em> and associated
            Rules (AML/CTF obligations), Fusion is required verify the identity of its customers as
            well as certain information about customers beneficial owners;
          </li>
          <li className={classes.p}>
            Fusion may at any time request further information from you to verify your identity
            and/or the source of monies credited or to be credited to your Fusion account and you
            agree to meet Fusion&apos;s request. If you do not provide Fusion with the information
            as requested, or there is a delay in you providing this information to Fusion, Fusion
            may not be able to open your Fusion account, or may suspend your access to the Fusion
            Services.&nbsp;
          </li>
          <li className={classes.p}>
            Fusion may disclose your Personal Information to a Credit Reporting Body to verify your
            identity and obtain an assessment of whether the information you have provided us with
            matches the information provided by the Credit Reporting Body.&nbsp;
          </li>
          <li className={classes.p}>
            Fusion is not liable for any loss incurred by you as a result of any action of Fusion
            which either delays a Fusion account being opened or results in an Application being
            declined, when these actions are necessary for Fusion to comply with its AML/CTF
            obligations; and
          </li>
          <li className={classes.p}>
            Fusion may require further information from you from time to time in order to meet its
            AML/CTF obligations and you agree to provide Fusion with whatever additional information
            is reasonably required in order for, Fusion to meet its AML/CTF obligations.
          </li>
        </ol>
      </ol>
      <p className={classes.title}>PART B: FUSION PLUS TRADING</p>
      <ol className={classes.ol} start="7">
        <li className={classes.p}>
          <p className={classes.title}>Trading Service</p>
        </li>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>
            We may assume the authenticity of any instructions given or purportedly given through a
            Third Party Trading Platform by you or any person claiming to be your representative. We
            are not obliged to enquire into any authenticity of instructions received through the
            Third Party Trading Platform and you authorise us to act upon any instructions it
            reasonably believes to be authentic.
          </li>
          <li className={classes.p}>
            We reserve the right at all times to decline to accept your instructions through the
            Third Party Trading Platform without explanation or prior notice for reasons including
            where:
          </li>
          <ol type="i" className={classes.ol}>
            <li className={classes.p}>your instructions are ambiguous, incomplete or unclear;</li>
            <li className={classes.p}>your instructions would result in a breach of the law;</li>
            <li className={classes.p}>it is not possible to place an order on the market.</li>
          </ol>
          <li className={classes.p}>
            We will inform you of any instructions which are declined as soon as reasonably
            practicable.
          </li>
          <li className={classes.p}>
            We will use our best endeavours to pass on accepted instructions, but we do not
            guarantee that your instructions will be wholly or partially executed or will be
            executed by a certain time, due to reasons including if:
          </li>
          <ol type="i" className={classes.ol}>
            <li className={classes.p}>
              your instructions are contrary to this Agreement or the terms applicable to the Third
              Party Trading Platform;
            </li>
            <li className={classes.p}>
              the trade is unexecuted due to any act or omission of the Third Party Trading
              Platform;
            </li>
            <li className={classes.p}>your instructions are ambiguous or incomplete;</li>
            <li className={classes.p}>your instructions would result in a breach of the law; or</li>
            <li className={classes.p}>you have insufficient funds available.</li>
          </ol>
        </ol>
        <li className={classes.p}>
          <p className={classes.title}>Fees and Payment</p>
        </li>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>
            The Customer agrees to pay Fusion, without limitation, for the Services it uses, either
            in the form of an agreed minimum monthly trading volume fee, or a flat monthly fee.
          </li>
          <li className={classes.p}>In respect of the Trade Copying Service:</li>
          <ol type="i" className={classes.ol}>
            <li className={classes.p}>
              you may also be required to pay a fee in respect of any Portfolios which you choose to
              follow and/or copy. The fee may be based a flat fee or calculated by reference to any
              returns you generate in connection with the Trade Copying Service. This fee may be
              wholly or partially retained by us or passed on to the Customer.
            </li>
            <li className={classes.p}>
              you may be entitled to receive a reward, payment or incentive associated with any
              other Customers who choose to follow and/or copy your Portfolio.
            </li>
            <p className={classes.p}>
              Additional information about the fees applicable to the Trade Copying Service will be
              provided via the Website.&nbsp;
            </p>
            <li className={classes.p}>
              Fusion reserves the right to charge the Customer standard hourly rates to cover the
              resolution of excessive or unusual problems or complaints.
            </li>
          </ol>
        </ol>
        <li className={classes.p}>
          <p className={classes.title}>Cancellation Policy</p>
        </li>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>
            We accept cancellation on any Service up until the Renewal Date, which occurs every 30
            days from the date that the Services were originally purchased (&quot;
            <strong className={classes.strong}>Renewal Date</strong>&quot;). Fees are charged to you
            on the Renewal Date and in accordance with clause 8 even if no trading volume has been
            made.
          </li>
          <li className={classes.p}>
            Any cancellation requests must be received via email or a cancellation through the
            member&apos;s area before the renewal invoice is generated. Any cancellation requests
            received after this time will not be processed until the following month. There are no
            partial refunds or credits for early cancellations.
          </li>
          <li className={classes.p}>
            Upon cancellation of your account and termination of these Terms, you will no longer
            have access to the Services, other than those portions of the Website which are publicly
            available to all Customers. Any such use of the Website by you will continue to be
            subject to these Terms.
          </li>
        </ol>
        <li className={classes.p}>
          <p className={classes.title}>Refund Policy</p>
        </li>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>
            All payments to Fusion are final and non-refundable unless otherwise stated. Any refunds
            on products that are eligible for a refund are at the discretion of Fusion.
          </li>
          <li className={classes.p}>
            Your obligation to pay fees continues through the end of your membership period during
            which you cancel your membership.
          </li>
        </ol>
        <li className={classes.p}>
          <p className={classes.title}>Trade Copying</p>
        </li>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>
            You may choose to use our Trade Copying Service while you are a member.
          </li>
          <li className={classes.p}>
            The Trade Copying Service Solution provides the following functionality:
          </li>
          <ol type="i" className={classes.ol}>
            <li className={classes.p}>
              Other members can choose to publish their trading activity. &nbsp;
            </li>
            <li className={classes.p}>
              You can follow choose to follow and copy that the trading activity.
            </li>
          </ol>
          <li className={classes.p}>
            Foreign exchange products and derivatives (such as contracts for difference or CFDs) are
            highly leveraged products. They may not be suitable for you as they carry a high degree
            of risk to your capital, and you can lose more than your investment. You should ensure
            you understand all of the risks. The Trade Copier services provided are not a
            recommendation from us to buy or sell, but rather a set of tools to enable you to adopt
            a specified analysis method. This information should only be used by investors who are
            aware of the risk inherent with trading.
          </li>
          <li className={classes.p}>
            Trade Copying does not guarantee anything. The Customer is responsible for any trades
            generated by the trade copier. By using the Trade Copying Service, the Customer
            acknowledges that they are in complete control of the product, can turn it off, modify
            risk settings and trades at any time. It is highly recommended that you test any signals
            generated by the trade copier in a demo environment to verify that it in fact functions
            to your expectation.
          </li>
          <li className={classes.p}>
            Please be aware that while the demo environment is similar to the live environment there
            will be differences in the way trades are processed and executed.
          </li>
          <li className={classes.p}>
            Fusion does not take responsibility for any electronic or technological errors that
            occur while using the Trade Copying Service. In particular, Fusion is not responsible
            for any costs or losses, including, but not limited to, those incurred as a result of
            the use of the Trade Copying Service.
          </li>
        </ol>
      </ol>
      <p className={classes.title}>PART C: TECHNICAL DETAILS</p>
      <ol className={classes.ol} start="12">
        <li className={classes.p}>
          <p className={classes.title}>Data storage</p>
        </li>
        <p className={classes.p}>
          Data stored on Fusion systems is not guaranteed to be backed up. It is recommended that
          Customers keep an independent copy of all data.
        </p>
        <li className={classes.p}>
          <p className={classes.title}>Fusion Administrative Login and Management Software</p>
        </li>
        <p className={classes.p}>
          To facilitate network/server management, inventory and related activities, all Fusion
          Servers include a Fusion administrative account and password and/or a Fusion daemon. All
          reasonable precautions are taken by Fusion to maintain the security of these tools and the
          privacy of client data. Customers must not tamper, hinder, delete, or in any way change
          the functioning of these tools. To do so intentionally or otherwise is grounds for the
          immediate suspension of Customer&apos;s hosting account.
        </p>
        <li className={classes.p}>
          <p className={classes.title}>Temporary Service Suspension</p>
        </li>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>
            The Customer agrees that it may be necessary for Fusion to temporarily suspend Services
            for technical reasons or to maintain the Fusion network, the equipment or any other
            facilities, the timing of which will be as determined by Fusion.&nbsp;
          </li>
          <li className={classes.p}>
            Such suspension of the Services will not be an interruption of the Fusion Services for
            the purpose of calculating network availability or the Customer&apos;s entitlement to
            credit for network interruption.
          </li>
        </ol>
        <li className={classes.p}>
          <p className={classes.title}>Emergency Service Suspension</p>
        </li>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>
            Fusion may interrupt the Services at any time for any duration of time, without penalty
            or liability for any claim by the Customer, where necessary to prevent improper or
            unlawful use of Fusion Services or network. Such suspension of Services will not be an
            interruption for the purpose of calculating network availability or the Customer&apos;s
            entitlement to credit for network interruption.
          </li>
        </ol>
        <li className={classes.p}>
          <p className={classes.title}>Compromised or Hacked Servern</p>
        </li>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>
            A compromised or hacked server is a serious threat to our network. Fusion, at its sole
            discretion, will take any and all measures to prevent a compromised server from doing
            additional damage to its own system and files or to the rest of the network.
          </li>
          <li className={classes.p}>
            If you believe your system may have been hacked, immediately report the situation to
            Fusion technical support. Fusion will examine your system and may implement additional
            monitoring of your system.
          </li>
          <li className={classes.p}>
            If a Fusion system administrator believes a server on our network is compromised, Fusion
            will:
          </li>
          <ol type="i" className={classes.ol}>
            <li className={classes.p}>disconnect the server from the network.</li>
            <li className={classes.p}>contact the Customer.</li>
            <li className={classes.p}>provide evidence of the server being hacked.</li>
          </ol>
        </ol>
        <li className={classes.p}>
          <p className={classes.title}>Fusion Administrative Account and Software</p>
        </li>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>
            For the purposes of Network/Server maintenance, inventory and related activities, Fusion
            staff and management retain the right to administrative access to any and all Servers on
            their network. To facilitate this, all Fusion Servers include a Fusion administrative
            account and/or daemon designed for these purposes. See the Acceptable Use Policy (AUP)
            for more details.&nbsp;
          </li>
          <li className={classes.p}>
            Fusion makes no warranty or representation, either express or implied, with respect to
            this software, the hardware, or documentation, including their quality, performance,
            merchantability, or fitness for a particular purpose.
          </li>
        </ol>
        <li className={classes.p}>
          <p className={classes.title}>Software Products</p>
        </li>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>
            Because software is inherently complex and may not be completely free of errors, you are
            advised to verify your work. In no event will Fusion be liable for direct, indirect,
            special, incidental, or consequential damages arising out of the use of or inability to
            use the software, hardware, or documentation, even if advised of the possibility of such
            damages.&nbsp;
          </li>
          <li className={classes.p}>
            In particular, Fusion is not responsible for any costs, including, but not limited to,
            those incurred as a result of the use of the software, loss of data, the costs of
            recovering such software or data, the cost of substitute software, claims by third
            parties, or for other similar costs.
          </li>
        </ol>
      </ol>
      <p className={classes.title}>PART D: GENERAL TERMS AND DEFINITIONS</p>
      <ol className={classes.ol} start="19">
        <li className={classes.p}>
          <p className={classes.title}>Portfolio information</p>
        </li>
        <p className={classes.p}>
          You consent to Fusion disclosing to other Customers information regarding the current and
          historical performance of your portfolio.
        </p>
        <li className={classes.p}>
          <p className={classes.title}>Third Party Trading Platform</p>
        </li>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>You acknowledge and agree that:</li>
          <ol type="i" className={classes.ol}>
            <li className={classes.p}>
              we are not responsible for the Third Party Trading Platform;
            </li>
            <li className={classes.p}>
              we do not recommend or endorse any investments in or available through the Third Party
              Trading Platform, and we assume no responsibility or liability in relation to the
              quality of such investments;
            </li>
            <li className={classes.p}>
              you authorise the Third Party Trading Platform to provide information about your
              Portfolio to us.
            </li>
            <li className={classes.p}>
              in addition to being bound by these Terms, you are bound by any terms and conditions
              of the Third Party Trading Platform;
            </li>
            <li className={classes.p}>
              it is your responsibility to assess and research each trade and Investment and
              determine its suitability for your purposes.
            </li>
          </ol>
          <li className={classes.p}>
            To the greatest extent permissible by law, you further acknowledge and agree that we are
            not responsible (whether in contract, tort or otherwise) for any loss or damage caused
            or suffered by you to the extent that the loss or damage results from:
          </li>
          <ol type="i" className={classes.ol}>
            <li className={classes.p}>
              any defect in our computer systems, or any delay, fault, failure in or loss of access
              to the Third Party Trading Platform;
            </li>
            <li className={classes.p}>
              telecommunications failure, delay, or interruption of or defective network or internet
              connections or services by a third party, including any defect in the computer systems
              of the Third Party Trading Platform;
            </li>
            <li className={classes.p}>
              your incorrect operation of the Third Party Trading Platform;
            </li>
            <li className={classes.p}>
              inaccuracy, error or omission in any material, data or information provided by you or
              any other third party through the Australian Trade Service;
            </li>
            <li className={classes.p}>
              actions of third parties in respect of the Third Party Trading Platform; or&nbsp;
            </li>
            <li className={classes.p}>
              any delay, interruption, omission, failure, error or fault in the execution of your
              instructions provided through the Trading Platform other than as a direct result of
              our fraud, wilful default or negligence.
            </li>
          </ol>
        </ol>
        <li className={classes.p}>
          <p className={classes.title}>Third Party Information</p>
        </li>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>The Services incorporate Third Party Information.</li>
          <li className={classes.p}>
            Fusion is not responsible for the Third Party Information and makes no representation as
            to the accuracy, reliability, timeliness or appropriateness of the Third Party
            Information. You acknowledge that Current Price information may be delayed by up to 24
            hours or longer in extraordinary circumstances.
          </li>
          <li className={classes.p}>
            Any recommendations or statements of opinion contained in the Third Party Information
            are those of the relevant third party and are not adopted by or attributable to Fusion.
          </li>
        </ol>
        <li className={classes.p}>
          <p className={classes.title}>No Recommendations</p>
        </li>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>
            You acknowledge that Fusion is not authorised to, and does not through the Fusion
            Services, provide personal financial product advice (within the meaning of the
            Corporations Act).
          </li>
          <li className={classes.p}>
            All information provided on the Fusion Services is, unless otherwise indicated, factual
            information and does not involve any recommendation or statement of opinion by Fusion or
            any third party. Such information does not take into account your objectives, financial
            situation or needs and you should consider obtaining professional financial advice
            before making any investment decisions.
          </li>
          <li className={classes.p}>
            References to the performance of your Portfolio are to the past performance of that
            Portfolio. Past performance should not be taken as an indication of future performance.
          </li>
          <li className={classes.p}>Tools in the Service for:</li>
          <ol type="i" className={classes.ol}>
            <li className={classes.p}>comparing the relative performance of Portfolios;</li>
            <li className={classes.p}>
              displaying Portfolios with particular characteristics; and&nbsp;
            </li>
            <li className={classes.p}>
              displaying Portfolios of other Customers on the basis of particular Profile
              Information,&nbsp;
            </li>
          </ol>
          <p className={classes.p}>
            present factual information only and do not convey any recommendation or statement of
            opinion by Fusion that a Portfolio is generally suitable for Customers or is suitable
            for you.
          </p>
        </ol>
        <li className={classes.p}>
          <p className={classes.title}>Disclosure</p>
        </li>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>
            You acknowledge that you have been provided with access to our{' '}
            <a href="https://globalprime.com/sites/5e3407911c37826ea0378bf3/assets/60796f4d1c378234e46a1a14/Fusion_Markets_AU_Product_Disclosure_Statement__PDS_.pdf">
              Product Disclosure Statement
            </a>{' '}
            through the Website at <a href="https://globalprime.com">https://globalprime.com</a>.
          </li>
          <li className={classes.p}>
            may provide benefits to third parties in connection with the Website including
            distributors (such as accountants and other professional service providers) who refer
            Customers to us.&nbsp;
          </li>
        </ol>
        <li className={classes.p}>
          <p className={classes.title}>Disclaimer of Warranties and Limitation of Liability</p>
        </li>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>
            Customer agrees to not rely on any representation, description, illustration or
            specification that is not expressly stated in these Terms.
          </li>
          <li className={classes.p}>
            Nothing in these Terms excludes, restricts or modifies any consumer guarantee, right or
            remedy conferred on you by Schedule 2 to the Competition and Consumer Act 2010 (
            <strong className={classes.strong}>ACL</strong>), or any other Applicable Law, that
            cannot be excluded, restricted or modified by agreement.
          </li>
          <li className={classes.p}>
            To the extent permitted by law (including the ACL), Fusion excludes all warranties,
            whether express or implied (not including any consumer guarantees under the ACL),
            including any warranties or representations concerning availability of the Fusion
            Services, quality, completeness, accuracy, suitability, acceptability or fitness for
            purpose in relation to the Fusion services including content, all links to or from the
            Fusion services and the goods and services advertised or accessible using the Fusion
            services. Subject to the consumer guarantees provided for in consumer protection
            legislation (including the ACL), we do not warrant that you will have continuous access
            to the Fusion services.&nbsp;
          </li>
          <li className={classes.p}>
            We will not be liable in the event that the website is unavailable to you or due to
            computer downtime attributable to malfunctions, upgrades, preventative or remedial
            maintenance activities or interruption in telecommunications supply.
          </li>
          <li className={classes.p}>
            Except where Fusion fails to meet a consumer guarantee under the ACL and notwithstanding
            any other provision of these Terms, Fusion will not be liable to you for:
          </li>
          <ol type="i" className={classes.ol}>
            <li className={classes.p}>any damage, loss or expense resulting from or caused by:</li>
            <ol type="A" className={classes.ol}>
              <li className={classes.p}>
                any act of Fusion which was carried out in good faith (notwithstanding that such act
                may have been in breach of these Terms or negligent);
              </li>
              <li className={classes.p}>
                your investment decisions and any acquisitions and sales of investments you make,
                regardless of whether they were made in light of content available on the Fusion
                services;
              </li>
              <li className={classes.p}>fluctuations in the value of investments;</li>
              <li className={classes.p}>any act or omission of any third party;</li>
              <li className={classes.p}>any inaccurate or incorrect Third Party Information;</li>
              <li className={classes.p}>
                any inaccurate or incorrect information in Your Content or otherwise provided by
                you;
              </li>
              <li className={classes.p}>
                any event or circumstance beyond Fusion&apos;s reasonable control including, without
                limitation, a Force Majeure Event;
              </li>
              <li className={classes.p}>
                any breach of these Terms, negligence, default, fraud or dishonesty by you;
              </li>
            </ol>
            <li className={classes.p}>
              any direct or indirect lost profit, loss of opportunity, incidental, consequential or
              special damages, howsoever the loss is caused and regardless of whether it was
              foreseeable or not,
            </li>
            <li className={classes.p}>
              any unauthorised or illegal access or interference with the Customer&apos;s
              server/network unless such access or interference is caused by the intentional
              unlawful acts of Fusion, its agents or employees.
            </li>
          </ol>
          <li className={classes.p}>
            If, despite the foregoing limitations, Fusion or any of its shareholders, directors,
            officers, employees or representatives should become liable to Customer or any other
            person in connection with this agreement for any reason, then the maximum aggregate
            liability of Fusion, its members, shareholders, directors, officers, employees and
            representatives for all such things and to all such parties will be the amount of any
            fees received from you in the preceding [12] months in the aggregate of all
            claims.&nbsp;
          </li>
        </ol>
        <li className={classes.p}>
          <p className={classes.title}>Indemnity</p>
        </li>
        <p className={classes.p}>
          Customer agrees to indemnify Fusion and its members, shareholders, directors, officers,
          employees, agents, contractors, and representatives from and against all damages, losses,
          costs and expenses (including actual legal fees and costs), fines and liabilities incurred
          by or awarded, asserted or claimed against Fusion or any of its members, shareholders,
          directors, officers, employees and representatives in connection with any of the
          following:
        </p>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>
            Customer&apos;s negligence, activities or omissions, or&nbsp;
          </li>
          <li className={classes.p}>
            breaches of its obligations under this Agreement, including claims brought by a person
            using; or relying upon any advice, communication or publication produced and distributed
            by Customer.
          </li>
        </ol>
        <li className={classes.p}>
          <p className={classes.title}>Intellectual Property</p>
        </li>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>
            All intellectual property rights (including copyright and patents) in the Fusion
            Services and all components of them are owned or licensed by us unless otherwise
            indicated. You must not copy, modify or transmit any part of the Fusion Services or any
            content except as permitted in these Terms.
          </li>
          <li className={classes.p}>
            The Fusion Services contain trademarks, logos, service names and trade names of Fusion
            or third parties that may be registered or otherwise protected by law. These include the
            Fusion logo. You are not permitted to use any trademarks, logos, service names or trade
            names appearing on the Fusion Services.
          </li>
          <li className={classes.p}>
            We grant you a non-exclusive and non-transferable licence to use the Website. You may
            not download (other than page caching) or modify the Website.
          </li>
        </ol>
        <li className={classes.p}>
          <p className={classes.title}>Your Content</p>
        </li>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>
            Subject to certain restrictions and limits outlined in this clause and clause 29.2, the
            Website and Services may allow you to upload, post, link to, store, communicate, send or
            transmit content (<strong className={classes.strong}>Your Content</strong>) on the
            Website.
          </li>
          <li className={classes.p}>
            You grant to us a non-exclusive, royalty-free, perpetual, irrevocable and fully
            sub-licensable right to use, modify, reproduce, adapt, communicate, display, perform and
            distribute Your Content in relation to and only for the purpose of our operation of the
            Website and Services.
          </li>
          <li className={classes.p}>
            You represent and warrant that Your Content does not infringe any third party
            intellectual property rights and that you own or otherwise control all of the rights to
            the content or that you have obtained all necessary authorisations and consents to post,
            link to, store or communicate Your Content and to grant us the rights in clause 28(b),
            that Your Content is accurate, that the use of Your Content does not violate these Terms
            and will not cause any loss or injury to any person. You agree to be solely responsible
            for any of Your Content that you post to the Website and through the Services.
          </li>
          <li className={classes.p}>
            Fusion has the right, but not the obligation, to monitor any Content (including Your
            Content) made available on the Website or Services. We reserve the right, in our
            absolute discretion, to block, modify or remove any Content (including Your Content)
            without notice, and will not be liable in any way for possible consequences of such
            actions. If you have a complaint regarding any Content, our sole obligation will be to
            review any written complaint notified to us and, if we see fit, in our sole discretion,
            to modify or remove the particular Content.&nbsp;
          </li>
        </ol>
        <li className={classes.p}>
          <p className={classes.title}>Acceptable Use Policy</p>
        </li>
        <ol className={classes.ol}>
          <li className={classes.p}>
            <p className={classes.title}>28.1 AUP requirements</p>
          </li>
          <ol type="a" className={classes.ol}>
            <li className={classes.p}>
              Customers shall at all times comply with and help ensure that they comply with the
              terms of the current version of the AUP. Customer agrees that Fusion may amend the
              terms of the AUP from time to time by posting a new or different version of the
              AUP.&nbsp;
            </li>
            <li className={classes.p}>
              For existing customers, changes shall come into effect one month after they are
              published on the Fusion website.
            </li>
            <li className={classes.p}>
              This AUP is not exhaustive. Fusion has the right to refuse service to anyone at any
              time without warning or prior notice. No refunds of fees paid will be made if account
              termination is due to a violation of the AUP as outlined above.
            </li>
            <li className={classes.p}>
              This policy recognises the fundamental fact that no one owns or controls the Internet.
              Fusion cannot monitor or control all the activities of our Customers. We do not intend
              to actively screen, review, censor, edit or take responsibility for the activities or
              content of our Customers. The Customer, not Fusion, assumes all responsibility
              relating to their Internet activities including, but not limited to:
            </li>
            <ol type="i" className={classes.ol}>
              <li className={classes.p}>Aspects of the Customer&apos;s business.</li>
              <li className={classes.p}>
                Content and data provided by or through a Customer for use with the Services.
              </li>
              <li className={classes.p}>
                Decisions about Customer&apos;s computer and communications systems needed to access
                the Services.
              </li>
              <li className={classes.p}>Results obtained from using Fusion Services.</li>
              <li className={classes.p}>
                Compliance with all applicable laws and governmental regulations regarding
                Customer&apos;s business or use of the Services.
              </li>
              <li className={classes.p}>Compliance with this AUP by the Customer.</li>
            </ol>
            <li className={classes.p}>
              Activities conducted on the Internet are subject to many of the same laws and
              regulations applicable to the offline environment. Customers must exercise a high
              degree of judgement and responsibility with respect to their use of the Services,
              including the responsibility to comply with this AUP. Customers will violate this
              Policy when they or their affiliates engage in any of the following activities
              outlined in clause 29.2 below
            </li>
          </ol>
          <li className={classes.p}>
            <p className={classes.title}>28.2 Prohibitions under the AUP</p>
          </li>
          <ol type="a" className={classes.ol}>
            <li className={classes.p}>Network and Other Abuse</li>
            <ol type="i" className={classes.ol}>
              <li className={classes.p}>
                Using the Fusion network or other Fusion resources in any way that adversely affects
                other Fusion Customers is strictly prohibited. This includes but is not limited to:
              </li>
              <ol type="A" className={classes.ol}>
                <li className={classes.p}>
                  Gaining or attempting to gain unauthorised access to servers or services. Such
                  attempts include &apos;Internet scamming&apos; (tricking other people into
                  releasing their passwords), password robbery, security hole scanning, port
                  scanning, probing, monitoring or testing for system or network vulnerabilities.
                </li>
                <li className={classes.p}>
                  Introducing viruses, Trojan horses, trap doors, back doors, Easter eggs, worms,
                  time bombs, packet bombs, cancel bots or other computer programming routines that
                  are intended to damage, detrimentally interfere with, surreptitiously intercept or
                  expropriate any system, data or personal information.
                </li>
                <li className={classes.p}>
                  Intentionally omitting, deleting, forging or misrepresenting transmission
                  information, including headers, return addressing information and IP addresses.
                  Using IP addresses which were not assigned to them by Fusion.
                </li>
                <li className={classes.p}>
                  Maintaining an Open Email Relay/Open Data Relay or allowing any data to be sent
                  through one&apos;s server by an unrelated third party, including, but is not
                  limited to, via open email gateways and open proxy servers.
                </li>
              </ol>
            </ol>
            <li className={classes.p}>Illegal Content</li>
            <ol type="i" className={classes.ol}>
              <li className={classes.p}>
                Accounts may be terminated that include content or which have links to content that:
              </li>
              <ol type="A" className={classes.ol}>
                <li className={classes.p}>
                  Is unlawful or is considered offensive by the web community.&nbsp;
                </li>
                <li className={classes.p}>
                  Promotes injury or physical harm against any group or individual.
                </li>
                <li className={classes.p}>Promotes or teaches illegal activities.</li>
                <li className={classes.p}>
                  Exploits or depicts children in a negative/sexual way.
                </li>
                <li className={classes.p}>
                  Infringes on copyright, patents, trademarks, trade secrets, or other intellectual
                  property including pirated computer programs, cracker utilities, warez and
                  software serial numbers or registration codes.
                </li>
                <li className={classes.p}>
                  Violates any law, statute, ordinance or regulation governing the Customer&apos;s
                  business or activities, including without limitation the laws and regulations
                  governing export control, unfair competition, false advertising, consumer
                  protection, issuance or sale of securities, trade in firearms, privacy, data
                  transfer and telecommunications.
                </li>
              </ol>
            </ol>
            <li className={classes.p}>SPAM or Unsolicited Commercial Email</li>
            <ol type="a" className={classes.ol}>
              <li className={classes.p}>
                Fusion has zero tolerance for the sending of SPAM or Unsolicited Commercial Email (
                <strong className={classes.strong}>UCE</strong>) over our network. Fusion Customers
                cannot send UCE and cannot host sites or information advertised in UCE. Customers
                must take all reasonable precautions to secure their servers and sites against SPAM
                exploits (e.g. open email relays and insecure cgi scripts).
              </li>
              <li className={classes.p}>
                Violating our UCE or SPAM policy will result in penalties. Upon detection or
                notification of a violation of our UCE policy, Fusion will initiate an immediate
                investigation. During this time, outgoing mail may be suspended from the offending
                IP address to prevent further violations. If a Customer is found to be in violation
                of our UCE policy, Fusion will impose penalties and/or, at its discretion, restrict
                or terminate the offending account and the Customer&apos;s access to our network.
                Repeated or serious violations may result in the immediate termination of the
                account. SPAM is a very serious matter. Please do not SPAM and take all precautions
                against SPAM exploits.
              </li>
            </ol>
          </ol>
        </ol>
        <li className={classes.p}>
          <p className={classes.title}>Registration and Login</p>
        </li>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>
            Certain parts of the Fusion Services are only accessible to Customers who have
            &ldquo;logged in&rdquo; or paid the relevant fee.
          </li>
          <li className={classes.p}>
            You represent and warrant that you are the individual identified in the Application
            Form, you have all necessary authorities in respect of your Portfolio and that all
            information you have supplied to us in connection with your use of the Fusion Services
            is true and correct.
          </li>
          <li className={classes.p}>
            You represent that you do not hold an Australian Financial Services (
            <strong className={classes.strong}>AFS</strong>) Licence and are not an authorised
            representative of an AFS Licence holder.
          </li>
          <li className={classes.p}>
            When using the Fusion Services, you are responsible for maintaining the confidentiality
            of your login details and for restricting access by third parties to your account. You
            agree to be liable if your login details are used by an unauthorised person and are
            fully responsible for all activities that are conducted under your membership. If you
            believe that any of your login details have been compromised, lost or misplaced, you
            must contact us immediately by email at support@globalprime.com. You must exercise
            particular caution when accessing the Fusion Services using the Software from a public
            or shared computer or mobile device so that others are not able to view or record your
            login details or other Personal Information.
          </li>
        </ol>
        <li className={classes.p}>
          <p className={classes.title}>Severability</p>
        </li>
        <p className={classes.p}>
          Each provision of these Terms is severable from the others and no severance of a provision
          will affect any other provision.
        </p>
        <li className={classes.p}>
          <p className={classes.title}>Contacting Fusion</p>
        </li>
        <p className={classes.p}>
          If you have questions about the Fusion Services, these Terms or the Privacy Policy, please
          contact Fusion by emailing support@globalprime.com.
        </p>
        <li className={classes.p}>
          <p className={classes.title}>Governing Law</p>
        </li>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>
            The Terms of this Agreement are governed by and must be construed in accordance with the
            law of the state of New South Wales, Australia. The Customer submits to the exclusive
            jurisdiction of the courts of that State and the Commonwealth of Australia in respect of
            all matters arising out of or relating to these Terms, their performance and subject
            matter.&nbsp;
          </li>
          <li className={classes.p}>
            If any provision of this agreement is held by any competent authority to be unlawful,
            invalid or unenforceable in whole or in part then the provision shall be deemed to be
            severable from the remaining provisions and shall not affect their validity or
            enforceability.
          </li>
        </ol>
        <li className={classes.p}>
          <p className={classes.title}>Definitions and Interpretation</p>
        </li>
        <p className={classes.p}>In these Terms unless the context requires otherwise:</p>
        <p className={classes.p}>
          <strong className={classes.strong}>Applicable Law</strong> means the Corporations Act 2001
          (Cth), and any other statute, statutory instrument or general law that is applicable to a
          party in connection with these Terms;
        </p>
        <p className={classes.p}>
          <strong className={classes.strong}>Application Form</strong> means the application form to
          become a Customer made available on the Website by Fusion, from time to time;
        </p>
        <p className={classes.p}>
          <strong className={classes.strong}>Content</strong> means any content made available on
          the Website or through the Services;
        </p>
        <p className={classes.p}>
          <strong className={classes.strong}>Customer</strong> means the person, firm or company
          that has requested any Services.
        </p>
        <p className={classes.p}>
          <strong className={classes.strong}>Credit Reporting Body</strong> has the meaning given to
          this term in the<em> Privacy Act 1988 (Cth)</em>.
        </p>
        <p className={classes.p}>
          <strong className={classes.strong}>Force Majeure Event</strong> means any act, event or
          cause including:
        </p>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>
            an act of God, peril of the sea, accident of navigation, war, sabotage, riot, act of
            terrorism, insurrection, civil commotion, national emergency (whether in fact or law),
            martial law, fire, lightning, flood, cyclone, earthquake, landslide, storm or other
            adverse weather conditions, explosion, power shortage, strike or other labour difficulty
            (whether or not involving employees of the party concerned), epidemic, quarantine,
            radiation or radioactive contamination;
          </li>
          <li className={classes.p}>
            an action or inaction of a Government Agency, including expropriation, restraint,
            prohibition, intervention, requisition, requirement, direction or embargo by
            legislation, regulation, decree or other legally enforceable order; or
          </li>
          <li className={classes.p}>
            breakdown of plant, machinery or equipment or shortages of labour, transportation, fuel,
            power or plant, machinery, equipment or material,
          </li>
        </ol>
        <p className={classes.p}>
          To the extent that the act, event or cause directly or indirectly results in a party being
          prevented from or delayed in performing one or more of its obligations under these Terms
          and that act, event or cause is beyond the reasonable control of that party;
        </p>
        <p className={classes.p}>
          <strong className={classes.strong}>Fusion </strong>means Gleneagle Securities Pty Ltd T/A
          Global Prime;
        </p>
        <p className={classes.p}>
          <strong className={classes.strong}>Personal Information</strong> means your name, address,
          date of birth, credit card details, Fusion login details, broker login details and any
          other information you have entered into the Service about yourself which the Service
          identifies as &quot;personal information&quot;;
        </p>
        <p className={classes.p}>
          <strong className={classes.strong}>Portfolio</strong> means a portfolio of investments
          available through the Third Party Trading Platform;
        </p>
        <p className={classes.p}>
          <strong className={classes.strong}>Profile Information</strong> means the information
          stored in your profile on the Website, including your name, your Portfolio, your trading
          activity and such other information you have entered into the Website about yourself;
        </p>
        <p className={classes.p}>
          <strong className={classes.strong}>Server </strong>means an Internet-connected server,
          virtual or otherwise, provided by Fusion for the Customer&apos;s use;
        </p>
        <p className={classes.p}>
          <strong className={classes.strong}>Services </strong>means any services supplied or to be
          supplied by Fusion, including but not limited to the provision of online trading services
          in respect of foreign exchange products and derivatives through a Third Party Trading
          Platform;
        </p>
        <p className={classes.p}>
          <strong className={classes.strong}>Third Party Information</strong> means information
          supplied by another Customer or third party and incorporated into the Website or Service,
          including:
        </p>
        <ol type="a" className={classes.ol}>
          <li className={classes.p}>
            Current Price and historical price information in relation to investments available
            through the Third Party Trading Platform;
          </li>
          <li className={classes.p}>content provided by other Customers;</li>
        </ol>
        <p className={classes.p}>
          <strong className={classes.strong}>Third Party Trading Platform</strong> means a trading
          platform operated by a third party which is selected by Fusion through which the Services
          a provided;
        </p>
        <p className={classes.p}>
          <strong className={classes.strong}>Trade Copy </strong>means the service provided by us as
          described in clause 11.
        </p>
        <p className={classes.p}>
          <strong className={classes.strong}>Website </strong>means the website in which Fusion
          provides its Services;
        </p>
        <p className={classes.p}>
          <strong className={classes.strong}>Your Content</strong> means the content uploaded,
          posted, linked to, stored, communicated, sent or transmitted on the Website or via the
          Services.
        </p>
      </ol>
    </div>
  );
};

export default compose(withStyles(styles))(JoinPlusTerms);
