import api from 'modules/api';

// export const getVerificationRequest = (payload) => {
//   return api.get('/verification?t=' + new Date().getTime(), payload);
// };

export const getPendingVerificationsRequest = (payload) => {
  return api.get('/verification/pending', { params: payload });
};

export const changeVerificationStatusRequest = (payload) => {
  return api.post('/verification/change-status', payload);
};

export const approveUserRequest = (payload) => {
  return api.post('/verification/change-status', payload);
};

export const loginSinceRejectionRequest = (payload) => {
  return api.patch('/verification/login-since-rejection', payload);
};
