export const boldText = () => ({ amountText: { fontWeight: 700 } });
export const smallText = () => ({ amountText: { fontSize: '14px', marginTop: '5px' } });
export const normalCell = () => ({
  amountContainer: {
    padding: '22px 10px 22px 30px',
    minWidth: '150px',
    backgroundColor: '#FFFFFF',
  },
});
export const smallCell = () => ({
  amountContainer: {
    padding: '22px 10px 22px 30px',
    minWidth: '120px',
  },
});
export const normalText = () => ({ amountText: { fontWeight: 'normal' } });
