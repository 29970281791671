export default (theme) => {
  const { climCustomButton, climCustomLink, climCustomLogo } = theme.climCustomStyles;

  return {
    formWrapper: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    form: {
      width: 485,
      background: theme.signIn.backgroundColor,
      border: theme.signIn.border,
      padding: 40,
      [theme.breakpoints.down('xs')]: {
        width: 320,
      },
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },
    wrapper: {
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      width: '100%',
    },
    textField: {
      marginTop: 16,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 26,
      },
    },
    confirmPassword: {
      marginTop: 20,
      [theme.breakpoints.down('xs')]: {
        marginTop: 35,
      },
    },
    title: {
      fontWeight: 'bold',
      fontSize: '28px',
      lineHeight: '34px',
      marginTop: 0,
      marginBottom: 24,
      color: theme.palette.textColor,
    },
    formError: {
      ...theme.textError,
      top: 40,
      width: '100%',
    },
    errorIcon: {
      fontSize: 12,
    },
    submitButton: {
      marginTop: 20,
      [theme.breakpoints.down('xs')]: {
        marginTop: 30,
      },
    },
    customLinkWrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 22,
    },
    customLinkText: {
      margin: '0 0 4px 0',
      color: theme.palette.blackText,
      fontSize: 14,
      fontWeight: 400,
      marginRight: 4,
    },
    modal: {
      width: 280,
    },
    loader: {
      height: 50,
      padding: 20,
      marginTop: 9,
      textAlign: 'center',
    },
    languageContainer: {
      position: 'absolute',
      top: '14px',
      right: '14px',
    },
    climCustomButton,
    climCustomLink,
    climCustomLogo,
    prefixSelect: {
      marginTop: '16px',
      width: '100%',
    },
    mainLayout: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 16,
      paddingBottom: 16,
      '@media (max-width:680px)': {
        paddingTop: 32,
      },
    },
    logoWrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 40,
    },
    image: {
      marginBottom: '70px',
    },
    inputLabel: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '19px',
      color: theme.palette.simpleGrey,
    },
  };
};
