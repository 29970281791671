export default {
  error: (theme) => ({
    ...theme.textError.root,
  }),
  icon: (theme) => ({
    marginRight: theme.spacing(0.5),
  }),
  mediumSize: (theme) => ({
    ...theme.textError.mediumSize,
  }),
};
