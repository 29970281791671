import { ERROR_MESSAGE_HEIGHT } from 'modules/common/TextInput/constants';

const styles = (theme) => ({
  textFieldContainer: {
    width: '100%',
    marginTop: 0,
    marginBottom: ERROR_MESSAGE_HEIGHT,
    backgroundColor: theme.alphabeticalPicker.backgroundColor,
  },
  textField: {
    width: '100%',
    '&$textFieldFocused:not($textFieldError) $notchedOutline': {
      borderColor: `${theme.palette.simpleGrey} !important`,
    },
    '&:hover:not($textFieldDisabled):not($textFieldError):not($textFieldFocused) $notchedOutline': {
      borderColor: `${theme.palette.simpleGrey} !important`,
    },
    '&$textFieldError $notchedOutline': {
      borderColor: `${theme.palette.error.main} !important`,
    },
    '&$textFieldError $labelError': {
      borderColor: `${theme.palette.error.main}`,
    },
    '&$textFieldFocused $dropDownIcon': {
      color: theme.palette.icon.black,
    },
    color: '#36425A',
    lineHeight: '20px',
    fontSize: '16px',
  },
  textFieldDisabled: {},
  label: {
    color: theme.palette.simpleGrey,
    fontSize: '15px',
    lineHeight: '15px',
    '&$labelFocused': {
      color: theme.palette.simpleGrey,
    },
    '&$labelError': {
      color: `${theme.palette.error.main} !important`,
    },
  },
  labelFocused: {},
  labelError: {},
  notchedOutline: {
    borderWidth: theme.alphabeticalPicker.borderWidth,
    borderColor: theme.alphabeticalPicker.borderColor,
  },
  textFieldFocused: {},
  textFieldError: {},
  focused: {},
  error: {},
  disabled: {},
  selectContainer: {
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menu: {
    top: '13px',
  },
  menuItem: {
    width: '205px',
    height: '44px',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: '#EFF2F7',
    },
  },
  menuLabel: {
    color: '#36425A',
    fontSize: '16px',
    lineHeight: '20px',
  },
  menuPaper: {
    boxShadow: '0 10px 10px 0 rgba(0,0,0,0.13) !important',
    borderRadius: '8px',
    marginLeft: '125px',
  },
  dropDownIcon: {
    color: theme.palette.black,
    fontSize: '18px',
    paddingRight: '3px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  select: {
    '&:active': {
      background: 'none',
    },
    '&:focus': {
      background: 'none',
    },
  },
  menuItemSelected: {
    backgroundColor: '#DADFEC !important',
  },
  hiddenItem: {
    display: 'none !important',
  },
  placeholderLabel: {
    color: theme.palette.simpleGrey,
  },
  input: {
    padding: '7px 5px 7px 14px',
    '&::placeholder': {
      color: theme.palette.simpleGrey,
      opacity: 1,
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
  errorText: {
    ...theme.textError,
    bottom: -19,
    left: 0,
    maxHeight: ERROR_MESSAGE_HEIGHT - 3,
  },
  errorIcon: {
    fontSize: 12,
  },
  tip: {
    ...theme.tip,
    // bottom: -19,
  },
});

export default styles;
