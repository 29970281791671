import { Box, Avatar } from 'material-latest';
import PropTypes from 'prop-types';

import verifiedCheckmarkIcon from '../../../static/verifiedCheckmark.svg';

import { styles } from './styles';

const CircleItem = ({ handleClick, children, isWithCheckmark = false, isMenuOpen = false }) => {
  return (
    <Box position="relative">
      <Avatar sx={styles.avatarWrapper} onClick={handleClick}>
        {children}
      </Avatar>
      {isWithCheckmark && !isMenuOpen && (
        <img style={styles.checkMark} src={verifiedCheckmarkIcon} alt="" width={12} height={12} />
      )}
    </Box>
  );
};

CircleItem.propTypes = {
  handleClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isWithCheckmark: PropTypes.bool,
  isMenuOpen: PropTypes.bool,
};
export default CircleItem;
