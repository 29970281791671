import React from 'react';
import PropTypes from 'prop-types';
import { isMobileOnly } from 'react-device-detect';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

import TPGrid from 'components/TP-UI/TPGrid';
import TPIconButton from 'components/TP-UI/TPIconButton';
import { TPAutocomplete } from 'components/TP-UI/TPAutocomplete';
import { SIZES } from 'components/TP-UI/constants';
import { MAX_MONTH_SIZE, MAX_YEAR_SIZE, MONTHS, YEARS } from './constants';

import styles from './styles';

const TPDatePickerHeader = ({
  forwardedRef,
  name,
  size,
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  const year = new Date(date).getFullYear();
  const month = new Date(date).getMonth();

  return (
    <TPGrid container alignItems="center" justifyContent="space-between" wrap="nowrap">
      <TPGrid item>
        <TPIconButton
          ref={forwardedRef}
          size={SIZES.SMALL}
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          className={styles.arrow}>
          <ArrowBackIosOutlinedIcon fontSize={SIZES.SMALL} />
        </TPIconButton>
      </TPGrid>
      <TPGrid item container spacing={1} justifyContent="center" wrap="nowrap">
        <TPGrid item>
          <TPAutocomplete
            name={`${name}_month`}
            size={SIZES.SMALL}
            value={month}
            options={MONTHS}
            autofocus={isMobileOnly}
            reservedErrorSpace={false}
            select
            className={[styles.month, size === SIZES.SMALL && styles.xsmallDatepickerDropdown]}
            inputProps={{ size: MAX_MONTH_SIZE }}
            onChange={changeMonth}
          />
        </TPGrid>
        <TPGrid item>
          <TPAutocomplete
            name={`${name}_year`}
            size={SIZES.SMALL}
            value={year}
            options={YEARS}
            onChange={changeYear}
            className={[styles.year, size === SIZES.SMALL && styles.xsmallDatepickerDropdown]}
            inputProps={{ size: MAX_YEAR_SIZE }}
            select
            reservedErrorSpace={false}
          />
        </TPGrid>
      </TPGrid>
      <TPGrid item>
        <TPIconButton
          size={SIZES.SMALL}
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
          className={styles.arrow}>
          <ArrowForwardIosOutlinedIcon fontSize={SIZES.SMALL} />
        </TPIconButton>
      </TPGrid>
    </TPGrid>
  );
};

TPDatePickerHeader.propTypes = {
  /**
   * Form datepicker name
   */
  name: PropTypes.string.isRequired,
  /**
   * Datepicker text field size
   */
  size: PropTypes.oneOf([SIZES.MEDIUM, SIZES.SMALL]),
  /**
   * props are provided by react-datepicker
   */
  date: PropTypes.object,
  changeYear: PropTypes.func.isRequired,
  changeMonth: PropTypes.func.isRequired,
  decreaseMonth: PropTypes.func.isRequired,
  increaseMonth: PropTypes.func.isRequired,
  prevMonthButtonDisabled: PropTypes.bool,
  nextMonthButtonDisabled: PropTypes.bool,
};

export default React.forwardRef((props, ref) => (
  <TPDatePickerHeader {...props} forwardedRef={ref} />
));
