const styles = () => ({
  container: {
    flex: 1,
    background: 'transparent',
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

export default styles;
