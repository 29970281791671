import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = [];

export default handleActions(
  {
    [actionCreators.getSimilarUsersSuccess](state, action) {
      return action.response.data;
    },

    [actionCreators.getSimilarUsersFail]() {
      return defaultState;
    },
  },
  defaultState,
);
