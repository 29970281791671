const styles = (theme) => ({
  label: {
    color: theme.palette.simpleGrey,
    fontSize: 12,
    lineHeight: 1.4,
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: 1.4,
    color: theme.palette.simpleGrey,
    marginRight: 10,
  },
  dataText: {
    color: theme.palette.text.main,
    fontSize: 14,
    fontWeight: 300,
    lineHeight: '17px',
    marginTop: 6,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    alignItems: 'center',
  },
  item: {
    padding: '22px 10px',
    height: 80,
    '@media (min-width:400px)': {
      height: 'auto',
    },
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  dataIconContainer: {
    minWidth: 20,
    maxWidth: 20,
    height: 20,
    borderRadius: 50,
    marginRight: 5,
    overflow: 'hidden',
    position: 'relative',
  },
  dataIcon: {
    height: 20,
    transform: 'scale(1)',
    objectPosition: 'center',
    objectFit: 'cover',
    width: 20,
  },
  loader: {
    margin: '6px auto',
  },
  diff: {
    fontSize: '0.8rem',
  },
  diffNegative: {
    color: '#FE6B6B',
  },
  diffPositive: {
    color: '#80CA8A',
  },
});

export default styles;
