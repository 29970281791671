import { fontSize, fontWeight } from '../../CustomThemeProviderMui5/themes/font';

export default {
  root: (theme) => ({
    display: 'inline-flex',
    alignItems: 'center',
    fontWeight: fontWeight.semiBold,
    fontSize: 'inherit',
    lineHeight: 'normal',
    color: theme.palette.text.dark,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.text.main,
    },
    '&:active': {
      color: theme.palette.text.main,
    },
  }),
  bold: {
    fontWeight: fontWeight.bold,
  },
  smallSize: {
    fontSize: fontSize._12,
  },
  mediumSize: {
    fontSize: fontSize._14,
  },
  largeSize: {
    fontSize: fontSize._16,
  },
};
