import { palette } from '../palette';
import { fontSize, fontWeight } from '../../font';

const OverridesMuiMenuItem = {
  styleOverrides: {
    root: {
      color: palette.text.dark,
      fontSize: fontSize._16,
      fontWeight: fontWeight.regular,
      height: 'auto',
      minHeight: 'auto',
      paddingTop: '10px',
      paddingBottom: '10px',
      '&:hover, &.Mui-selected, &:hover.Mui-selected': {
        backgroundColor: palette.primary.lightest,
      },
    },
  },
};

export default OverridesMuiMenuItem;
