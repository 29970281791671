import { createAction } from 'redux-actions';

export const getEmployeesRequest = createAction('GET_EMPLOYEES_REQUEST');
export const getEmployeesSuccess = createAction('GET_EMPLOYEES_SUCCESS');
export const getEmployeesFail = createAction('GET_EMPLOYEES_FAIL');

export const editEmployeesPermissionsRequest = createAction('EDIT_EMPLOYEES_PERMISSIONS_REQUEST');
export const editEmployeesPermissionsSuccess = createAction('EDIT_EMPLOYEES_PERMISSIONS_SUCCESS');
export const editEmployeesPermissionsFail = createAction('EDIT_EMPLOYEES_PERMISSIONS_FAIL');

export const editEmployeesPasswordRequest = createAction('EDIT_EMPLOYEES_PASSWORD_REQUEST');
export const editEmployeesPasswordSuccess = createAction('EDIT_EMPLOYEES_PASSWORD_SUCCESS');
export const editEmployeesPasswordFail = createAction('EDIT_EMPLOYEES_PASSWORD_FAIL');

export const editEmployeesAssignedCxdRequest = createAction('EDIT_EMPLOYEES_ASSIGNED_CXD_REQUEST');
export const editEmployeesAssignedCxdSuccess = createAction('EDIT_EMPLOYEES_ASSIGNED_CXD_SUCCESS');
export const editEmployeesAssignedCxdFail = createAction('EDIT_EMPLOYEES_ASSIGNED_CXD_FAIL');

export const editEmployeesDisableMfaRequest = createAction('EDIT_EMPLOYEES_DISABLE_MFA_REQUEST');
export const editEmployeesDisableMfaSuccess = createAction('EDIT_EMPLOYEES_DISABLE_MFA_SUCCESS');
export const editEmployeesDisableMfaFail = createAction('EDIT_EMPLOYEES_DISABLE_MFA_FAIL');

export const getEmailSendersRequest = createAction('GET_EMAIL_SENDERS_REQUEST');
export const getEmailSendersSuccess = createAction('GET_EMAIL_SENDERS_SUCCESS');
export const getEmailSendersFail = createAction('GET_EMAIL_SENDERS_FAIL');

export const lockEmployee = createAction('LOCK_EMPLOYEE');
export const lockEmployeeSuccess = createAction('LOCK_EMPLOYEE_SUCCESS');
export const lockEmployeeFail = createAction('LOCK_EMPLOYEE_FAIL');

export const unlockEmployee = createAction('UNLOCK_EMPLOYEE');
export const unlockEmployeeSuccess = createAction('UNLOCK_EMPLOYEE_SUCCESS');
export const unlockEmployeeFail = createAction('UNLOCK_EMPLOYEE_FAIL');

export const updateEmployee = createAction('UPDATE_EMPLOYEE');

export const openEditModal = createAction('OPEN_EDIT_MODAL');
export const editEmployeesSave = createAction('EDIT_EMPLOYEES_SAVE');
