export default {
  PRO_OR_RETAIL_MODAL: 'proOrRetail',
  VERIFICATION_AWAITING_MODAL: 'verificationAwaiting',
  TRY_AGAIN_MODAL: 'tryAgain',
  CONGRATS_MODAL: 'congratsModal',
  SECOND_FAILED_MODAL: 'secondQuizFailed',
  SUCCESS_QUIZ_MODAL: 'successModal',
  PRO_QUIZ_BLOCKED_MODAL: 'proQuizBlockedModal',
  QUIZ_BLOCKED_MODAL: 'quizBlockedModal',
  UPGRADE_BLOCKED_MODAL: 'upgradeBlockedModal',
};
