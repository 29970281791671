import { typographyFonts } from '../../CustomThemeProviderMui5/themes/light/typographyFonts';

export default {
  linkContainer: (theme) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: `${theme.spacing(1.25)} ${theme.spacing(2)}`,
  }),
  menuRoot: {
    zIndex: 1000,
  },
  menuListWithHeader: {
    paddingTop: 0,
  },
  menuListHeader: (theme) => ({
    padding: `0 ${theme.spacing(2)}`,
    lineHeight: 1.2,
    background: theme.palette.background.main,
  }),
  noGutters: {
    padding: 0,
  },
  iconContainer: (theme) => ({
    minWidth: '24px',
    color: theme.palette.text.dark,
  }),
  text: (theme) => ({
    ...typographyFonts.body2,
    whiteSpace: 'normal',
    lineHeight: 1.2,
    color: theme.palette.text.dark,
  }),
};
