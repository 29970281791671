import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import { Routes } from '../../../../constants/routeConstants';
import CustomLink from '../../../common/CustomLink';
import Tooltip from '../../../common/Tooltip/containers/TooltipContainer';
import { getUserHub, getUserPassedQuiz } from '../../../auth/selectors';
import { GLOBAL_PRIME } from '../../../../constants';
import useQuiz from '../../../../hooks/useQuiz';
import CustomModal from '../../../common/CustomModal';
import verificationModalNames from '../../constants/verificationModalNames';
import { closeModal } from '../../../common/CustomModal/actions';

import styles from './styles';

const AwaitingApprovalModal = ({ classes }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('profile');

  const handleSubmit = useCallback(() => {
    dispatch(closeModal(verificationModalNames.VERIFICATION_AWAITING_MODAL));
    history.push(Routes.MY_ACCOUNTS);
  }, [dispatch, history]);

  const hub = useSelector(getUserHub);

  const isQuizPassed = useSelector(getUserPassedQuiz);

  const isQuizRequired = hub === GLOBAL_PRIME && !isQuizPassed;

  const startQuiz = useQuiz();

  const onQuizClick = () => {
    dispatch(closeModal(verificationModalNames.VERIFICATION_AWAITING_MODAL));
    startQuiz();
  };

  const confirmText = isQuizRequired ? (
    <div>
      <Trans i18nKey="verification.notes.smallQuiz" ns="profile">
        While our team is busy approving your account, before we allow you to trade, we need you to
        pass a small 2 minute
        <CustomLink onClick={onQuizClick}>&nbsp; quiz&nbsp;</CustomLink>
        so that we can assess whether these products are right for you (
        <Tooltip
          label={t('common:whyDoWeAsk.title')}
          value={t('common:whyDoWeAsk.messages.quide227')}
        />
        ).
      </Trans>
    </div>
  ) : (
    t('verification.notes.verificationProcess')
  );

  const buttons = useMemo(
    () => [
      {
        label: t('common:buttons.gotIt'),
        color: 'primary',
        onClick: handleSubmit,
      },
    ],
    [t, handleSubmit],
  );

  return (
    <CustomModal
      modalClassNames={classes.modal}
      className={classes.modalPaper}
      modalName={verificationModalNames.VERIFICATION_AWAITING_MODAL}
      isCloseButtonNeeded
      title={t('verification.notes.verificationAwaiting')}
      buttons={buttons}>
      {confirmText}
    </CustomModal>
  );
};

export default withStyles(styles)(AwaitingApprovalModal);
