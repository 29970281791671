import { combineActions, handleActions } from 'redux-actions';

import * as userActionCreators from 'modules/userManagement/pages/UserDetails/actions';
import * as authActionCreators from '../actions';

const defaultState = false;

export default handleActions(
  {
    [combineActions(userActionCreators.signinByEmailSuccess)](_, action) {
      return action.response.data.isImpersonation;
    },
    [combineActions(authActionCreators.logout)]() {
      return defaultState;
    },
  },
  defaultState,
);
