export default (theme) => ({
  dataWrapper: {
    padding: '30px 20px',
  },
  noDataWrapper: {
    padding: '30px 20px',
    display: 'flex',
    flexDirection: 'column',
  },
  dataItem: {
    textAlign: 'center',
    marginBottom: 20,
  },
  dataItemLabel: {
    color: theme.palette.simpleGrey,
    fontStyle: 'normal',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.1,
    paddingBottom: 4,
    textAlign: 'left',
  },
  dataItemValue: {
    color: theme.palette.textColor,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 1.4,
    textAlign: 'left',
  },
  dataItemComparison: {
    margin: '-8px 0 8px -9px',
    color: theme.palette.textColor,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 1.4,
    textAlign: 'left',
  },
  dataItemIncomePositive: {
    color: '#7DC4B5',
    fontSize: 12,
  },
  dataItemIncomeNegative: {
    color: '#AA5072',
    fontSize: 12,
  },
  text: {
    color: theme.palette.text.main,
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '26px',
    paddingTop: 32,
  },
});
