import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from 'material-latest';
import CloseIcon from '@mui/icons-material/Close';

import { SIZES } from 'components/TP-UI/constants';

const TPChip = ({ label, onDelete, disabled, size = SIZES.MEDIUM }) => {
  return (
    <Chip
      variant="outlined"
      label={label}
      onDelete={onDelete}
      disabled={disabled}
      size={size}
      deleteIcon={<CloseIcon />}
    />
  );
};

TPChip.propTypes = {
  label: PropTypes.node.isRequired,
  onDelete: PropTypes.func,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf([SIZES.SMALL, SIZES.MEDIUM]),
};

export default TPChip;
