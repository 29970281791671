import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = [];

export default handleActions(
  {
    [actionCreators.getLeadOwnersSuccess](state, action) {
      return action.response.data;
    },
    [actionCreators.getLeadOwnersFail]() {
      return defaultState;
    },
  },
  defaultState,
);
