import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox } from 'material-latest';
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';

import TPErrorMessage from 'components/TP-UI/TPErrorMessage';
import { MAIN_COLORS, SIZES } from '../constants';

import styles from './styles';

export const TPCheckbox = ({
  name,
  value,
  label,
  color = MAIN_COLORS.PRIMARY,
  size = SIZES.MEDIUM,
  disabled = false,
  required = false,
  error,
  reservedErrorSpace = true,
  onChange,
  onBlur,
  ...others
}) => {
  const isChecked = typeof value === 'boolean' ? value : Boolean(value) && value !== 'false';

  return (
    <>
      <Box component="label" sx={[styles.rootLabel, disabled && styles.rootLabelDisabled]}>
        <Checkbox
          name={name}
          value={value}
          disabled={disabled}
          checked={isChecked}
          color={color}
          size={size}
          icon={<CheckBoxOutlineBlank sx={[error && styles.errorIcon]} />}
          checkedIcon={<CheckBox />}
          required={required}
          onChange={onChange}
          onBlur={onBlur}
          {...others}
        />
        <Box
          component="span"
          sx={[styles.label, disabled && styles.labelDisabled, size && styles[`${size}SizeLabel`]]}>
          {label}
        </Box>
      </Box>
      <Box component="div" sx={[reservedErrorSpace && styles.errorContainer]}>
        {error && (
          <TPErrorMessage error={error} size={SIZES.SMALL} className={styles[`error_${size}`]} />
        )}
      </Box>
    </>
  );
};

TPCheckbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  color: PropTypes.oneOf([MAIN_COLORS.PRIMARY, MAIN_COLORS.SECONDARY]),
  size: PropTypes.oneOf([SIZES.MEDIUM, SIZES.SMALL]),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /**
   * Reserved space to display error in 1 line
   */
  reservedErrorSpace: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

const TPReduxCheckbox = ({ input, meta, ...others }) => {
  const error = meta.submitFailed && meta.error ? meta.error : null;
  const { onChange, onBlur, value } = input;
  const handleBlur = useCallback(() => onBlur(value), [onBlur, value]);
  return (
    <TPCheckbox {...input} error={error} {...others} onChange={onChange} onBlur={handleBlur} />
  );
};

export default TPReduxCheckbox;
