import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = [];

export default handleActions(
  {
    [actionCreators.getPendingMigrationsSuccess](state, action) {
      return action.response.data.requests;
    },
    [actionCreators.getPendingMigrationsFail]() {
      return defaultState;
    },
  },
  defaultState,
);
