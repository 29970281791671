import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { includes, get } from 'lodash';
import * as authSelectors from 'modules/auth/selectors';
import loadScript from 'utils/loadScript';
import { getIpCountryCode } from 'modules/geolocation/selectors';

import {
  POLICY_COOKIE_NAME,
  COUNTRY_CODES_FOR_COOKIES,
  FC_FRAME_BOTTOM_CLASS,
} from '../../constants';
import './styles.css';

const FreeChat = ({ license }) => {
  const user = useSelector(authSelectors.getUser);
  const [script, setScript] = useState();
  const isClientRole = useSelector(authSelectors.getIsClientRole);

  useEffect(() => {
    async function getScripts() {
      const script = await loadScript('https://fusionmarkets.freshchat.com/js/widget.js', []);
      setScript(script);
    }
    getScripts();
  }, []);

  const ipCountryCode = useSelector(getIpCountryCode);

  const showBanner = useMemo(
    () =>
      includes(COUNTRY_CODES_FOR_COOKIES, ipCountryCode) &&
      !(Cookies.get(POLICY_COOKIE_NAME) === 'true'),
    [ipCountryCode],
  );

  useEffect(() => {
    if (!script) {
      return;
    }

    if (!user.email) {
      return;
    }

    if (!isClientRole) {
      return;
    }

    // prevention of rendering in any other iframe's
    if (self !== top) {
      return null;
    }

    if (window.fcWidget?.isInitialized()) {
      return;
    }

    window.fcWidget?.init({
      token: license,
      host: 'https://fusionmarkets.freshchat.com',
      tags: ['public', 'global'],
      widgetUuid: '4f0b37ed-85f8-4d0b-9aae-a5c27c8cb9f2',
      externalId: user._id,
      email: user.email,
      firstName: get(user, 'profile.firstName'),
      lastName: get(user, 'profile.lastName'),
      config: {
        cssNames: {
          widget: `custom_fc_frame ${showBanner ? FC_FRAME_BOTTOM_CLASS : ''}`,
          expanded: 'custom_fc_expanded',
        },
        headerProperty: {
          appName: 'Global Prime',
        },
      },
    });

    return () => window.fcWidget?.destroy();
  }, [user, license, isClientRole, script, showBanner]);

  return null;
};

export default FreeChat;
