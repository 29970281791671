import { createAction } from 'redux-actions';

export const getQuizRequest = createAction('GET_QUIZ_REQUEST');
export const getQuizSuccess = createAction('GET_QUIZ_SUCCESS');
export const getQuizFail = createAction('GET_QUIZ_FAIL');

export const passQuizRequest = createAction('PASS_QUIZ_REQUEST');
export const passQuizSuccess = createAction('PASS_QUIZ_SUCCESS');
export const passQuizFail = createAction('PASS_QUIZ_FAIL');

export const setupQuiz = createAction('SETUP_QUIZ');

export const changeQuizCurrentQuestion = createAction('CHANGE_QUIZ_CURRENT_QUESTION');
export const changeQuizProgress = createAction('CHANGE_QUIZ_PROGRESS');

export const cleanUpQuizQuestions = createAction('CLEANUP_QUIZ_QUESTIONS');
