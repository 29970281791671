import { SIDE_MENU_WIDTH_SMALL, SIDE_MENU_WIDTH_LARGE } from 'modules/menu/constants';
import { palette } from '../../../../components/CustomThemeProvider/themes/palette';

const styles = (theme) => ({
  root: {
    padding: 0,
    width: `${SIDE_MENU_WIDTH_LARGE}px`,
    '@media (max-width:1440px)': {
      width: `${SIDE_MENU_WIDTH_SMALL}px`,
    },
    transition: '0.3s',
    flex: 1,
    minHeight: '100vh',
    boxSizing: 'border-box',
    borderRight: `1px solid ${palette.lightGreen}`,
    backgroundColor: theme.palette.white,
    overflowY: 'auto',
  },

  userContainer: {
    paddingLeft: '16px',
    paddingRight: '15px',
    marginTop: '45px',
    '@media (max-width:768px)': {
      marginTop: '30px',
    },
  },
  progressContainer: {
    marginTop: '15px',
    paddingLeft: '16px',
    paddingRight: '15px',
    minHeight: '36px',
    borderBottom: `1px solid ${palette.lightGreen}`,

    // '@media (max-width:768px)': {
    //   display: 'none',
    // },
  },
  goLiveContainer: {
    paddingLeft: '16px',
    paddingRight: '15px',
    paddingTop: '26px',
    paddingBottom: '45px',
    '@media (max-width:768px)': {
      paddingBottom: '40px',
    },
    alignItems: 'center',
  },
  goLiveButton: {
    borderRadius: '4px',
    width: '83px',
    minHeight: '34px',
    fontSize: '12px',
  },
  authButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  signUpButton: {
    textTransform: 'uppercase',
    fontWeight: 700,
    marginRight: 10,
  },
  signInButton: {
    padding: 12,
  },
  menuContainer: {
    flex: 1,
  },
  infoContainer: {
    borderBottom: `1px solid ${palette.lightGreen}`,
    paddingLeft: 20,
    paddingTop: 24,
    paddingBottom: 28,
  },
  emptySpace: {
    height: '80px',
  },
  languageContainer: {
    marginTop: '5px',
    marginRight: '14px',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  languagePostfix: {
    color: theme.palette.white,
    paddingLeft: 3,
    opacity: 0.6,
    fontWeight: 600,
    fontSize: 12,
  },

  switchIcon: {
    cursor: 'pointer',
  },
});

export default styles;
