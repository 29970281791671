import React from 'react';
import { Field, reduxForm } from 'redux-form';

import TPGrid from 'components/TP-UI/TPGrid';
import TPDatePicker from 'components/TP-UI/TPDatePicker';
import TPTypography from 'components/TP-UI/TPTypography';
import { required } from '../../../utils/validation/fieldValidationRules';

const DatePickers = () => {
  return (
    <form>
      <TPGrid container spacing={1}>
        <TPGrid item container spacing={1}>
          <TPGrid item xs={12}>
            <TPTypography>Default</TPTypography>
          </TPGrid>
          <TPGrid item container xs={5}>
            <Field component={TPDatePicker} name="date" id="date" label="Date" fullWidth />
          </TPGrid>
        </TPGrid>
        <TPGrid item container spacing={1}>
          <TPGrid item xs={12}>
            <TPTypography>Error</TPTypography>
          </TPGrid>
          <TPGrid item container xs={5}>
            <Field
              component={TPDatePicker}
              name="dateError"
              id="dateError"
              label="Date"
              required
              fullWidth
              validate={[required]}
              meta={{ error: { key: 'common:errors.form.isRequired' }, submitFailed: true }}
            />
          </TPGrid>
        </TPGrid>
        <TPGrid item container spacing={1}>
          <TPGrid item xs={12}>
            <TPTypography>Disabled</TPTypography>
          </TPGrid>
          <TPGrid item container xs={5}>
            <Field
              component={TPDatePicker}
              name="dateDisabled"
              id="dateDisabled"
              label="Date"
              disabled
              fullWidth
            />
          </TPGrid>
        </TPGrid>
        <TPGrid item container spacing={1}>
          <TPGrid item xs={12}>
            <TPTypography>Future date</TPTypography>
          </TPGrid>
          <TPGrid item container xs={5} alignItems="center">
            <Field
              component={TPDatePicker}
              name="futureDate"
              id="futureDate"
              label="Date in future"
              minDate={new Date()}
              fullWidth
            />
          </TPGrid>
        </TPGrid>
        <TPGrid item container spacing={1}>
          <TPGrid item xs={12}>
            <TPTypography>Custom Placeholder </TPTypography>
          </TPGrid>
          <TPGrid item container xs={5} alignItems="center">
            <Field
              component={TPDatePicker}
              name="datePlaceholder"
              id="datePlaceholder"
              label="Date"
              placeholder="Please enter date"
              fullWidth
            />
          </TPGrid>
        </TPGrid>
        <TPGrid item container spacing={1}>
          <TPGrid item xs={12}>
            <TPTypography>Clearable</TPTypography>
          </TPGrid>
          <TPGrid item container xs={5} alignItems="center">
            <Field
              component={TPDatePicker}
              name="dateClearable"
              id="dateClearable"
              label="Date"
              clearable
              fullWidth
            />
          </TPGrid>
        </TPGrid>
        <TPGrid item container spacing={1}>
          <TPGrid item xs={12}>
            <TPTypography>Small</TPTypography>
          </TPGrid>
          <TPGrid item container xs={5} alignItems="center">
            <Field
              component={TPDatePicker}
              name="dateSmall"
              id="dateSmall"
              size="small"
              clearable
              fullWidth
            />
          </TPGrid>
        </TPGrid>
        <TPGrid item container spacing={1}>
          <TPGrid item xs={12}>
            <TPTypography>Right Popover</TPTypography>
          </TPGrid>
          <TPGrid item container xs={5} alignItems="center">
            <Field
              component={TPDatePicker}
              name="dateRightPopover"
              id="dateRightPopover"
              popoverPlacement="right-end"
              clearable
              fullWidth
            />
          </TPGrid>
        </TPGrid>
        <TPGrid item container spacing={1}>
          <TPGrid item xs={12}>
            <TPTypography>Multiple</TPTypography>
          </TPGrid>
          <TPGrid item xs={6} container spacing={1}>
            <TPGrid item>
              <Field
                component={TPDatePicker}
                name="multipleDate1"
                id="multipleDate1"
                label="Date"
              />
            </TPGrid>
            <TPGrid item>
              <Field
                component={TPDatePicker}
                name="multipleDate2"
                id="multipleDate2"
                label="Date"
              />
            </TPGrid>
          </TPGrid>
        </TPGrid>
      </TPGrid>
    </form>
  );
};

export default reduxForm({
  form: 'TP-DATEPICKER-FORM',
  initialValues: {
    date: '30-01-2000',
    dateError: '2022-06-20',
    dateDisabled: '',
    futureDate: new Date(),
    datePlaceholder: '',
    dateClearable: '',
    dateSmall: '',
    dateRightPopover: '',
    multipleDate1: '',
    multipleDate2: '',
  },
})(DatePickers);
