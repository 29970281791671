import React, { Component } from 'react';
import { connect } from 'react-redux';

import WrapperPicker from '../components/WrapperPicker';

class PickerContainer extends Component {
  constructor(props) {
    super(props);
    this.label = React.createRef();
    this.state = {
      showTopButton: false,
      labelWidth: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.label !== this.props.label) {
      this.setState({ labelWidth: this.label.current.offsetWidth });
    }
  }

  componentDidMount() {
    this.setState({ labelWidth: this.label.current.offsetWidth });
  }

  handleTopButtonChange = (newTopButtonValue) => {
    this.setState({
      showTopButton: newTopButtonValue,
    });
  };

  render() {
    const props = {
      isTopButtonShowed: this.state.showTopButton,
      handleTopButtonChange: this.handleTopButtonChange,
      labelRef: this.label,
      labelWidth: this.state.labelWidth,
    };
    return <WrapperPicker {...this.props} {...props} />;
  }
}

PickerContainer.propTypes = {};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PickerContainer);
