import notificationClose from 'static/notification_close.svg';
import notificationInfo from 'static/notification_info.svg';
import notificationError from 'static/notification_error.svg';
import notificationDocument from 'static/doc_icon.svg';

const styles = (theme) => ({
  notificationItem: {
    width: '100%',
    borderRadius: '4px',
    backgroundColor: theme.palette.lightGreen,
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.4)',
    color: theme.palette.black,
    fontWeight: 600,
    fontSize: '14px',
    cursor: 'pointer',
    padding: '18px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundImage: `url("${notificationClose}")`,
    backgroundPosition: 'right 25px center',
    backgroundRepeat: 'no-repeat',
    userSelect: 'none',
    position: 'relative',
    transition: 'all 2s ease 0s',
    margin: '0 auto',
    marginTop: 10,
  },
  notificationDocument: {
    width: '100%',
    borderRadius: '4px',
    backgroundColor: theme.palette.lightGreen,
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.4)',
    color: theme.palette.black,
    fontSize: '14px',
    cursor: 'pointer',
    padding: '18px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundPosition: 'right 25px center',
    backgroundRepeat: 'no-repeat',
    userSelect: 'none',
    position: 'relative',
    transition: 'all 2s ease 0s',
    margin: '0 auto',
    marginTop: 10,
  },
  message: {
    backgroundImage: `url("${notificationInfo}")`,
    backgroundPosition: 'left center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '17px',
    padding: '0 35px',
    maxWidth: '90%',
    overflow: 'hidden',
  },
  info: {
    backgroundColor: theme.palette.lightGreen,
    '& $message': {
      backgroundImage: `url("${notificationInfo}")`,
    },
  },
  error: {
    backgroundColor: '#AC3C5D',
    '& $message': {
      backgroundImage: `url("${notificationError}")`,
    },
  },
  document: {
    backgroundColor: theme.palette.lightGreen,
    '& $message': {
      backgroundImage: `url("${notificationDocument}")`,
    },
  },
});

export default styles;
