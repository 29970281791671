const styles = (theme) => ({
  button: {
    backgroundColor: theme.palette.lightGreen,
    width: 40,
    height: 40,
    overflow: 'unset',
    '&:focus': {
      outline: 0,
    },
    '&:hover': {
      backgroundColor: theme.palette.lightGreen,
    },
  },
  bell: {
    height: '18px',
  },
  rootBage: {
    width: 'unset',
  },
  badge: {
    position: 'absolute',
    left: -2,
    top: -22,
    zIndex: 10,
    border: '1px solid',
    borderColor: theme.palette.white,
  },
});

export default styles;
