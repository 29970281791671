const styles = () => ({
  button: {
    fontSize: 12,
    fontWeight: 900,
    lineHeight: '15px',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    width: 102,
    height: 34,
  },
  container: {
    display: 'flex',
    padding: '10px 24px',
    flexDirection: 'row',
    width: 260,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  loader: {
    alignSelf: 'center',
    width: 191,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
});

export default styles;
