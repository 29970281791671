import { mobileNaivgationHeight } from 'modules/menu/components/BottomNavigation/styles';
import { SIDE_MENU_WIDTH_SMALL, SIDE_MENU_WIDTH_LARGE } from 'modules/menu/constants';

const styles = (theme) => ({
  scrollOnTopButton: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: theme.scrollOnTopButton.backgroundColor,
    border: theme.scrollOnTopButton.border,
    width: `calc(100% - ${SIDE_MENU_WIDTH_LARGE}px)`,
    '@media (max-width:1440px)': {
      width: `calc(100% - ${SIDE_MENU_WIDTH_SMALL}px)`,
    },
    '@media (max-width:1024px)': {
      width: '100%',
    },
    height: '54px',
    '&:hover': {
      backgroundColor: theme.scrollOnTopButton.backgroundColorHover,
    },
    '&:active': {
      backgroundColor: theme.scrollOnTopButton.backgroundColorActive,
    },
    fontSize: '12px',
    marginLeft: '-17px',
    '@media (min-width:768px)': {
      marginLeft: '-30px',
    },
    '@media (min-width:1820px)': {
      marginLeft: '-90px',
    },
    zIndex: 100,
    [theme.breakpoints.down('sm')]: {
      bottom: mobileNaivgationHeight,
    },
  },
  icon: {
    fontSize: '15px',
    marginRight: '3px',
  },
  hidden: {
    display: 'none',
  },
});

export default styles;
