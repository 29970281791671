export default {
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  placeholder: (theme) => ({
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.lightest,
    color: theme.palette.primary.light,
    fontSize: '140px',
    borderRadius: '4px',
  }),
  placeholderIcon: {
    maxWidth: '50%',
    maxHeight: '80%',
  },
};
