import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from 'material-latest';

import { MAIN_COLORS } from 'components/TP-UI/constants';

import styles from './styles';

const TPLoader = ({ color = MAIN_COLORS.PRIMARY, size = 50, className }) => {
  return (
    <Box sx={[styles.loaderContainer, className]}>
      <CircularProgress color={color} size={size} />
    </Box>
  );
};

TPLoader.displayName = 'MuiLoader';
TPLoader.propTypes = {
  color: PropTypes.oneOf([MAIN_COLORS.PRIMARY, MAIN_COLORS.SECONDARY, MAIN_COLORS.INHERIT]),
  size: PropTypes.number,
};

export default TPLoader;
