import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import {
  resources,
  language,
  fallbackLanguage,
  defaultNamespace,
  debug,
  namespaces,
} from './i18nextConfig';

i18n.use(initReactI18next).init({
  resources,
  lng: language,
  fallbackLng: fallbackLanguage,
  defaultNS: defaultNamespace,
  debug,
  ns: namespaces,
});

export default i18n;
