import React, { memo } from 'react';
import Badge from '@material-ui/core/Badge';
import styles from './styles';
import PropTypes from 'prop-types';
import classMerge from 'classnames';

import { withStyles } from '@material-ui/core/styles';

const InlineBadge = memo(({ children = '', classNames, classes, badgeContent, ...badgeProps }) => {
  return (
    <Badge
      classes={{
        root: classMerge(classes.root, classNames?.root),
        badge: classMerge(classes.badge, classNames?.badge),
      }}
      badgeContent={badgeContent}
      {...badgeProps}>
      {children}
    </Badge>
  );
});

InlineBadge.displayName = 'InlineBadge';

InlineBadge.propTypes = {
  children: PropTypes.node,
  classNames: PropTypes.object,
  badgeContent: PropTypes.node.isRequired,
};

export default withStyles(styles)(InlineBadge);
