import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as apiSelectors from 'modules/api/selectors';
import * as actions from '../actions';
import * as selectors from '../selectors';
import * as authSelectors from 'modules/auth/selectors';
import NotificationsBell from '../components/NotificationsBell';
import ShowNotificationsModal from '../components/ShowNotificationsModal';
import { Routes } from '../../../constants/routeConstants';

const UserNotificationsContainer = ({
  bellClassNames,
  modalClassNames,
  isCloseButtonNeeded,
  disableEnforceFocus,
  fullScreen,
}) => {
  const dispatch = useDispatch();
  const notificationsCount = useSelector(selectors.getUserNotificationsCount);
  const IDNotificationsCount = useSelector(selectors.getPendingNotificationsCount);

  const notifications = useSelector(selectors.getUserNotifications);
  const userId = useSelector(authSelectors.getUserId);
  const isNotificationsLoading = useSelector(
    apiSelectors.createPendingSelector(actions.getUserNotificationsRequest),
  );
  const isMarkAsReadLoading = useSelector(
    apiSelectors.createPendingSelector(actions.markAllAsReadRequest),
  );
  const isClient = useSelector(authSelectors.getIsClientRole);
  const history = useHistory();

  useEffect(() => {
    if (!userId) {
      return;
    }
    dispatch(actions.getUserNotificationsCountRequest(userId));
  }, [dispatch, userId]);

  const getUserNotificationsOpen = useCallback(() => {
    if (isClient) {
      dispatch(actions.getUserNotificationsOpen(userId));
    } else {
      history.push(`${Routes.VERIFICATION_MANAGEMENT_REQUESTS}/1`);
    }
  }, [isClient, dispatch, userId, history]);

  const getUserNotificationsClose = useCallback(
    () => dispatch(actions.getUserNotificationsClose()),
    [dispatch],
  );

  const markAsRead = useCallback(
    (id) => {
      dispatch(actions.markAsReadRequest({ userId, id }));
    },
    [dispatch, userId],
  );

  const markAllAsRead = useCallback(
    (ids) => {
      dispatch(actions.markAllAsReadRequest({ userId, ids }));
    },
    [dispatch, userId],
  );

  const props = {
    getUserNotificationsOpen,
    getUserNotificationsClose,
    markAsRead,
    markAllAsRead,
    isNotificationsLoading,
    isMarkAsReadLoading,
    notificationsCount: isClient ? notificationsCount : IDNotificationsCount,
    notifications,
    bellClassNames,
    modalClassNames,
    isCloseButtonNeeded,
    disableEnforceFocus,
    fullScreen,
  };
  return (
    <>
      <NotificationsBell {...props} />
      <ShowNotificationsModal {...props} />
    </>
  );
};

export default UserNotificationsContainer;
