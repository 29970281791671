import api from '..';
import get from 'lodash/get';

export const sendMessageRequest = (payload) => {
  const { captcha } = payload;
  delete payload.captcha;
  const message = JSON.stringify(payload);
  return api.post('/contact-us/clients', { subject: 'Contact Us', message, captcha });
};

export const getContactInfoRequest = (payload) => {
  return api.get('/contact-us', payload);
};

export const requestAdditionalAccountRequest = (payload) => {
  const message = JSON.stringify(payload);
  const demoAccount = get(payload, 'demoAccount', false);
  return api.post('/contact-us/request-account', {
    demoAccount,
    message,
  });
};

// export const requestHyperwalletStatusChangeRequest = (payload) => {
//   const message = JSON.stringify(payload);
//   return api.post('/contact-us/request-hyperwallet-status-change', {
//     subject: 'Request Hyperwallet status change',
//     message,
//   });
// };
