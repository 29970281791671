import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import TPIconButton from 'components/TP-UI/TPIconButton';
import TPTooltip from 'components/TP-UI/TPTooltip';
import TPBadge from 'components/TP-UI/TPBadge';
import TPColumnsModal from '../TPColumnsModal';
import { FORM } from './constants';

const TPColumnsSettings = ({
  form,
  title,
  loading,
  disabled,
  columns = [],
  onChange,
  className,
}) => {
  const { t } = useTranslation('common');
  const changed = useMemo(
    () =>
      columns.some(
        (column) =>
          (column.default && column.visible === false) || (!column.default && column.visible),
      ),
    [columns],
  );
  const [visible, setVisible] = useState(false);

  const handleIconClick = useCallback(() => {
    setVisible(true);
  }, []);

  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);

  const handleSubmit = useCallback(
    (values) => {
      if (onChange) {
        onChange(columns.map((c) => ({ ...c, visible: !!values[c.label] })));
      }
      setVisible(false);
    },
    [onChange, columns],
  );

  return (
    <>
      <TPTooltip content={t('labels.columnSettings')} disabled={isTablet || isMobile}>
        <TPIconButton
          className={className}
          disabled={loading || disabled}
          onClick={handleIconClick}>
          <TPBadge variant="dot" color="error" invisible={!changed}>
            <SettingsOutlinedIcon />
          </TPBadge>
        </TPIconButton>
      </TPTooltip>
      <TPColumnsModal
        title={title}
        form={form || FORM}
        columns={columns}
        visible={visible}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </>
  );
};

TPColumnsSettings.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Column title
       */
      label: PropTypes.node,
      /**
       * Displayed by default
       */
      default: PropTypes.bool,
      /**
       * Current visibility state
       */
      visible: PropTypes.bool,
    }),
  ),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  form: PropTypes.string,
  /**
   * Called when the column visibility is changed, function ({..., visible: bool})
   */
  onChange: PropTypes.func,
};

export default TPColumnsSettings;
