const isPageExists = (pageNumber, pagesNumber) => {
  let isPageExists = true;
  const pageNumberParsed = Number.parseInt(pageNumber);
  if (!pageNumberParsed || pageNumberParsed < 1 || pageNumberParsed > pagesNumber) {
    isPageExists = false;
  }
  return isPageExists;
};

export default isPageExists;
