import { mobileNaivgationHeight } from 'modules/menu/components/BottomNavigation/styles';

const styles = (theme) => ({
  tabs: {
    border: 'none',
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    [theme.breakpoints.up(640)]: {
      width: '500px !important',
    },
  },
  modalRoot: {
    bottom: `${mobileNaivgationHeight}px !important`,
  },
  title: {
    color: '#282525',
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '24px',
  },
  badgeRoot: {
    alignItems: 'center',
  },
  badge: {
    position: 'relative',
    color: '#FFFFFF',
    backgroundColor: theme.palette.bageNotification,
    fontSize: '11px',
    lineHeight: '13px',
    transform: 'unset',
    marginLeft: 8,
  },
  tab: {
    padding: '0 10px',
    fontSize: 14,
  },
  loader: {
    margin: '20px 0',
  },
  placeholderImage: {
    display: 'block',
    margin: '20px auto',
    width: '60%',
    height: '100%',
  },
  noDataTitle: {
    color: theme.palette.textColor,
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'center',
  },
  notifications: {
    minHeight: '340px',
    overflow: 'auto',
    padding: '0 14px',
  },
});

export default styles;
