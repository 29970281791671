import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Typography } from '@material-ui/core';
import formatPrice from 'utils/formatPrice';
import TableSortButton from '../TableSortButton';

const TableAmountField = ({
  classes,
  label,
  dataLabel,
  tableItem,
  className,
  currencyLabel,
  sort,
  rowIndex,
  showLabel,
  isHeadRow,
}) => {
  if (showLabel && isHeadRow) {
    return (
      <div className={classNames([classes.item, className])}>
        <Typography className={classes.title}>{label}</Typography>
      </div>
    );
  }
  return (
    <div className={classNames([classes.item, className])}>
      {rowIndex === 0 && sort ? (
        <Typography className={classes.label}>
          <TableSortButton
            dataLabel={dataLabel}
            onSort={sort.onSort}
            isSorted={sort.isSorted}
            getSortDirection={sort.getSortDirection}
          />
        </Typography>
      ) : null}
      {showLabel ? (
        <Typography className={classNames(classes.title, classes.cellLabel)}>{label}</Typography>
      ) : null}
      <Typography className={classes.amountText}>
        {formatPrice(
          tableItem[dataLabel],
          currencyLabel ? tableItem[currencyLabel] : tableItem.currency,
        )}
      </Typography>
    </div>
  );
};

TableAmountField.propTypes = {
  label: PropTypes.string.isRequired,
  dataLabel: PropTypes.string.isRequired,
  tableItem: PropTypes.object,
  className: PropTypes.string,
  currencyLabel: PropTypes.string,
  showLabel: PropTypes.bool,
};

export default withStyles(styles)(TableAmountField);
