import { palette } from '../palette';
import { fontSize, fontWeight } from '../../font';

const OverridesMuiStepIcon = {
  styleOverrides: {
    root: {
      width: '24px',
      height: '24px',
      color: palette.background.main,
      '&.Mui-completed': {
        color: palette.primary.lightest,
      },
      '&.Mui-active': {
        color: palette.primary.lightest,
      },
    },
    text: {
      transform: 'translateY(5%)',
      fontSize: fontSize._12,
      fontWeight: fontWeight.bold,
      fill: palette.text.dark,
    },
  },
};

export default OverridesMuiStepIcon;
