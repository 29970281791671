import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box } from 'material-latest';

import isURL from 'utils/isURL';
import isMailTo from 'utils/isMailTo';
import { SIZES } from 'components/TP-UI/constants';

import styles from './styles';

const TPLink = ({
  path = '',
  size = '',
  children,
  iconComponent: IconComponent,
  iconPosition = 'start',
  iconSize = SIZES.SMALL,
  className,
  bold,
  ...props
}) => {
  const linkClassName = [
    styles.root,
    size && styles[`${size}Size`],
    bold && styles.bold,
    ...(Array.isArray(className) ? className : [className]),
  ];

  return isURL(path) || isMailTo(path) ? (
    <Box component="a" href={path} sx={linkClassName} {...props}>
      {IconComponent && iconPosition === 'start' ? (
        <>
          <IconComponent color="inherit" fontSize={iconSize} />
          &nbsp;
        </>
      ) : null}
      {children}
      {IconComponent && iconPosition === 'end' ? (
        <>
          &nbsp;
          <IconComponent color="inherit" fontSize={iconSize} />
        </>
      ) : null}
    </Box>
  ) : (
    <Box component={NavLink} sx={linkClassName} to={path} {...props}>
      {IconComponent && iconPosition === 'start' ? (
        <>
          <IconComponent color="inherit" fontSize={iconSize} />
          &nbsp;
        </>
      ) : null}
      {children}
      {IconComponent && iconPosition === 'end' ? (
        <>
          &nbsp;
          <IconComponent color="inherit" fontSize={iconSize} />
        </>
      ) : null}
    </Box>
  );
};

TPLink.propTypes = {
  /**
   * Link path.
   */
  path: PropTypes.string,
  /**
   * Link font size, 'inherit' by default.
   */
  size: PropTypes.oneOf(['', SIZES.LARGE, SIZES.MEDIUM, SIZES.SMALL]),
  /**
   * Link icon component.
   */
  iconComponent: PropTypes.elementType,
  /**
   * Position of icon relative to the main content.
   */
  iconPosition: PropTypes.oneOf(['start', 'end']),
  bold: PropTypes.bool,
};

export default TPLink;
