import { createAction } from 'redux-actions';

export const migrateUserRequest = createAction('MIGRATE_USER_REQUEST');
export const migrateUserSuccess = createAction('MIGRATE_USER_SUCCESS');
export const migrateUserFail = createAction('MIGRATE_USER_FAIL');

export const migrateUserConfirm = createAction('MIGRATE_USER_CONFIRM');
export const migrateUserConfirmClose = createAction('MIGRATE_USER_CONFIRM_CLOSE');
export const confirmSuccessClose = createAction('MIGRATE_CONFIRM_SUCCESS_CLOSE');

export const requestMigrateUserRequest = createAction('REQUEST_MIGRATE_USER_REQUEST');
export const requestMigrateUserSuccess = createAction('REQUEST_MIGRATE_USER_SUCCESS');
export const requestMigrateUserFail = createAction('REQUEST_MIGRATE_USER_FAIL');
