import { handleActions } from 'redux-actions';

import { getSentimentDataSuccess, getSentimentDataFail } from '../actions';

const defaultState = {
  dailyPrices: [],
  sentimentSummary: {},
  sentimentTimeseries: {},
  entity: {},
};

export default handleActions(
  {
    [getSentimentDataSuccess](state, action) {
      return action.response.data;
    },

    [getSentimentDataFail]() {
      return defaultState;
    },
  },
  defaultState,
);
