import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from 'material-latest';
import { ErrorOutline } from '@mui/icons-material';

import { getTranslation } from 'utils/compositeTranslationHandler';
import { SIZES } from 'components/TP-UI/constants';

import styles from './styles';

const TPErrorMessageBase = ({ error, size, className, forwardedRef }) => {
  const { t } = useTranslation('common');
  return (
    <Box
      component="span"
      ref={forwardedRef}
      sx={[styles.error, className, size === SIZES.MEDIUM && styles.mediumSize]}>
      <ErrorOutline sx={styles.icon} fontSize={size} color="error" />
      {getTranslation(t, error) || 'Error'}
    </Box>
  );
};

TPErrorMessageBase.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  size: PropTypes.oneOf([SIZES.MEDIUM, SIZES.SMALL]),
  className: PropTypes.oneOf([PropTypes.string, PropTypes.func]),
};

const TPErrorMessage = React.forwardRef((props, ref) => (
  <TPErrorMessageBase {...props} forwardedRef={ref} />
));

export default TPErrorMessage;
