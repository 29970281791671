import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = {
  isOpened: false,
  isLoading: false,
  account: {},
  affiliate: {},
  amount: 0,
};

export default handleActions(
  {
    [actionCreators.openTransferModal](state, action) {
      return { ...defaultState, isLoading: true, isOpened: true, account: action.payload };
    },
    [actionCreators.closeTransferModal]() {
      return { ...defaultState };
    },

    [actionCreators.getAffiliateDataRequest](state) {
      return { ...state, isLoading: true };
    },

    [actionCreators.getAffiliateDataFail]() {
      return { ...defaultState };
    },

    [actionCreators.getAffiliateDataSuccess](state, action) {
      const accounts = { ...state, isLoading: false, affiliate: action.response.data };
      return accounts;
    },
    [actionCreators.createCellxpertTransferRequest]: (state) => {
      return { ...state, isLoading: true };
    },
    [actionCreators.createCellxpertTransferSuccess]() {
      return { ...defaultState };
    },
    [actionCreators.createCellxpertTransferFail](state) {
      return { ...state, isLoading: false };
    },
  },
  defaultState,
);
