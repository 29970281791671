import React from 'react';
import { Redirect } from 'react-router';
import { getIsAdminRole, getIsManagerRole, getIsCountryManager } from 'modules/auth/selectors';
import { useSelector } from 'react-redux';

import { Routes } from 'constants/routeConstants';

const Home = () => {
  const isManager = useSelector(getIsManagerRole);
  const isAdmin = useSelector(getIsAdminRole);
  const isCountryManager = useSelector(getIsCountryManager);
  if (isAdmin || isCountryManager) {
    return <Redirect to={Routes.DASHBOARD} />;
  }

  if (isManager) {
    return <Redirect to={Routes.VERIFICATION_MANAGEMENT} />;
  }

  return <Redirect to={Routes.MY_ACCOUNTS} />;
};

export default Home;
