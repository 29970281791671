import { push } from 'connected-react-router';
import get from 'lodash/get';
import { stopSubmit } from 'redux-form';
import { all, put, takeEvery, select } from 'redux-saga/effects';
import { validateMfaFail, validateMfaSuccess } from '../actions';
import { MFA_INPUT_FORM } from '../constants';
import { getUserName, getUserId } from 'modules/auth/selectors';
import * as menuActions from 'modules/menu/actions';

function* redirectToDashboard() {
  let userName = yield select(getUserName);

  if (!userName) {
    let userId = yield select(getUserId);
    yield put(menuActions.getUpdatedUserRequest(userId));
  }
  yield put(push('/'));
}

function* showError(action) {
  const { message: errorMessage } = get(action, 'error.data', {});

  yield put(
    stopSubmit(MFA_INPUT_FORM, {
      _error: errorMessage,
    }),
  );
}

export default function* watchRequest() {
  yield all([
    takeEvery(validateMfaSuccess, redirectToDashboard),
    takeEvery(validateMfaFail, showError),
  ]);
}
