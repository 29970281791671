import { all, takeEvery } from 'redux-saga/effects';
import { logout } from '../actions';

function* logoutHandler() {
  yield localStorage.clear();
}

export default function* watchRequest() {
  yield all([takeEvery(logout, logoutHandler)]);
}
