import PropTypes from 'prop-types';
import { GoogleLogin } from '@react-oauth/google';

const GoogleButton = ({ onSuccess, onFailure }) => {
  return (
    <GoogleLogin
      render={({ onClick, disabled }) => (
        <button type="button" className="" onClick={onClick} disabled={disabled}>
          Sign in with google
        </button>
      )}
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy="single_host_origin"
    />
  );
};

GoogleButton.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
};

export default GoogleButton;
