import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Routes } from 'constants/routeConstants';
import { Roles } from 'constants/userRoles';
import Logo from 'modules/common/Logo';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import NavigationMenuList from '../../containers/NavigationMenuListContainer';
import VerificationProgress from '../VerificationProgress';
import styles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import SidebarModeSwitcher from './SidebarModeSwitcher';
import { isSidebarCollapsed } from './selectors';
import { TOGGLE_COLLAPSIBLE_SIDEBAR } from './actions';
import CollapsedProgress from '../../../common/CircularProgress';
import { SIDE_MENU_WIDTH_COLLAPSED } from 'modules/menu/constants';
import * as authSelectors from 'modules/auth/selectors';
import { useBottomNavigation } from 'modules/menu/context/bottomNavigationContext';

const SideMenu = ({
  classes,
  verificationProgress,
  isVerified,
  isPosted,
  isNew,
  isPublic,
  userRole,
}) => {
  const dispatch = useDispatch();
  const isAdminRole = useSelector(authSelectors.getIsAdminRole);
  const isCollapsed = useSelector(isSidebarCollapsed);

  const bottomNavigation = useBottomNavigation();

  const handleSubMenuClose = useCallback(() => {
    bottomNavigation.setMenuOpen(false);
  }, [bottomNavigation]);

  const handleCollapseToggle = useCallback(() => {
    dispatch(TOGGLE_COLLAPSIBLE_SIDEBAR());
  }, [dispatch]);

  const linkTo = useMemo(
    () => ([Roles.ADMIN, Roles.MANAGER].includes(userRole) ? Routes.HOME : Routes.MY_ACCOUNTS),
    [userRole],
  );

  return (
    !isPublic && (
      <div className={classes.root} style={isCollapsed ? { width: SIDE_MENU_WIDTH_COLLAPSED } : {}}>
        <div className={classes.infoContainer}>
          <Grid container className={classes.logoContainer} direction="row" justify="space-between">
            <Grid item>
              <Grid container alignItems="center" wrap="nowrap">
                <Link to={linkTo} style={{ marginRight: isCollapsed ? 19 : 37 }}>
                  <Logo color="blue" isCollapsed={isCollapsed} />
                </Link>
                <SidebarModeSwitcher
                  className={classes.switchIcon}
                  onClick={handleCollapseToggle}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
        {!isVerified && !isAdminRole && (
          <div className={classes.progressContainer}>
            {isCollapsed ? (
              <CollapsedProgress value={verificationProgress} />
            ) : (
              <VerificationProgress
                progress={verificationProgress}
                onClickContinue={handleSubMenuClose}
                isPosted={isPosted}
                isNew={isNew}
              />
            )}
          </div>
        )}
        <div className={classes.menuContainer}>
          <NavigationMenuList isCollapsed={isCollapsed} />
        </div>
      </div>
    )
  );
};

SideMenu.propTypes = {
  email: PropTypes.string,
  verificationProgress: PropTypes.number,
  onLogoutClick: PropTypes.func.isRequired,
  isVerified: PropTypes.bool,
  isPosted: PropTypes.bool,
  isUser: PropTypes.bool,
  userName: PropTypes.string,
  userRole: PropTypes.string,
  isPublic: PropTypes.bool,
  isNew: PropTypes.bool,
  userStatus: PropTypes.string,
};

export default withStyles(styles)(SideMenu);
