import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import MainLayout from 'modules/common/MainLayout';
import NavTabs from 'modules/common/NavTabs';
import queryString from 'query-string';

import styles from './styles';

import CurrencyCalculatorForm from '../../containers/CurrencyCalculatorFormContainer';
import PipValueCalculatorForm from '../../containers/PipValueCalculatorFormContainer';
import MarginCalculatorForm from '../../containers/MarginCalculatorFormContainer';
import SwapsCalculatorForm from '../../containers/SwapsCalculatorFormContainer';
import ProfitLossCalculatorForm from '../../containers/ProfitLossCalculatorFormContainer';

import { LANGUAGE_MAPPER } from '../../constants';

const TradingCalculators = ({ classes, handleNavTabChange, isPublic, symbols }) => {
  const { t, i18n } = useTranslation('tradingCalculators');
  const [lang, setLang] = useState('');
  if (!lang) {
    const query = queryString.parse(window.location.search || '');
    if (query.lang) {
      setLang(query.lang);
    }
  }

  useEffect(() => {
    if (!lang || !isPublic) {
      return;
    }

    i18n.changeLanguage(LANGUAGE_MAPPER[lang]);
  }, [lang, i18n, isPublic]);

  const tabs = [
    {
      path: 'currency',
      label: t('currencyCalculator.title'),
      Component: CurrencyCalculatorForm,
    },
    {
      path: 'pip-value',
      label: t('pipValueCalculator.title'),
      Component: PipValueCalculatorForm,
    },
    {
      path: 'margin',
      label: t('marginCalculator.title'),
      Component: MarginCalculatorForm,
    },
    {
      path: 'swaps',
      label: t('swapsCalculator.title'),
      Component: SwapsCalculatorForm,
    },
    {
      path: 'profit-loss',
      label: t('profitLossCalculator.title'),
      Component: ProfitLossCalculatorForm,
    },
  ];

  return (
    <MainLayout title={t('page.title')} isPublic={isPublic}>
      <NavTabs classes={classes} variant="scrollable" onChange={() => handleNavTabChange()}>
        {tabs.map(({ path, label, Component }) => (
          <div key={label} path={path} label={label}>
            <Component symbols={symbols} />
          </div>
        ))}
      </NavTabs>
    </MainLayout>
  );
};

TradingCalculators.propTypes = {
  classes: PropTypes.object.isRequired,
  handleNavTabChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(TradingCalculators);
