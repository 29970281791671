import { palette } from '../palette';

const OverridesMuiPaper = {
  styleOverrides: {
    outlined: {
      borderColor: palette.primary.lighter,
    },
    rounded: {
      borderRadius: 0,
    },
  },
};

export default OverridesMuiPaper;
