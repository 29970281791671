import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from 'material-latest';

import { VARIANTS } from './config';
import { SIZES } from '../constants';

import styles from './styles';

const TPButtonBase = ({
  primary,
  secondary,
  alternative,
  icon: Icon,
  textNoWrap,
  loading,
  disabled,
  size,
  fullWidth,
  className,
  children,
  forwardedRef,
  ...props
}) => {
  const variant =
    (primary && VARIANTS.primary) ||
    (alternative && VARIANTS.alternative) ||
    (secondary && VARIANTS.secondary) ||
    {};

  const loaderSize = size && size === SIZES.LARGE ? 24 : size && size === SIZES.SMALL ? 14 : 18;

  return (
    <Button
      {...variant}
      disabled={disabled}
      size={size}
      fullWidth={fullWidth}
      disableTouchRipple
      ref={forwardedRef}
      sx={[className, loading && styles.loading, textNoWrap && styles.textNoWrap]}
      {...props}>
      {Icon && (
        <Icon
          sx={[
            size === SIZES.SMALL && styles.iconSmall,
            (!size || size === SIZES.MEDIUM) && styles.iconDefault,
            size === SIZES.LARGE && styles.iconLarge,
          ]}
        />
      )}
      {children}
      {loading && (
        <CircularProgress
          size={loaderSize}
          sx={[
            styles.buttonProgress,
            primary && styles.progressPrimary,
            secondary && styles.progressSecondary,
            alternative && styles.progressAlternative,
            size === SIZES.LARGE && styles.progressLargeSize,
            size === SIZES.SMALL && styles.progressSmallSize,
          ]}
        />
      )}
    </Button>
  );
};

TPButtonBase.propTypes = {
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  alternative: PropTypes.bool,
  icon: PropTypes.elementType,
  textNoWrap: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf([SIZES.LARGE, SIZES.MEDIUM, SIZES.SMALL]),
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

const TPButton = React.forwardRef((props, ref) => <TPButtonBase {...props} forwardedRef={ref} />);

export default TPButton;
