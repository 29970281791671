import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import get from 'lodash/get';

import { createPendingSelector } from 'modules/api/selectors';

import { swapsCalculatorRequest } from '../actions';
import { SWAPS_CALCULATOR_FORM } from '../constants';
import {
  getCurrencySpecs,
  getPreparedSwapsResults,
  swapsFormSelector,
  getContractSizes,
} from '../selectors';

import SwapsCalculatorForm from '../components/SwapsCalculatorForm';

const SwapsCalculatorFormContainer = ({ symbols }) => {
  const dispatch = useDispatch();
  const isPending = useSelector(createPendingSelector(swapsCalculatorRequest));
  const currencySpecs = useSelector(getCurrencySpecs);
  const results = useSelector(getPreparedSwapsResults);
  const currencyPair = useSelector((state) => swapsFormSelector(state, 'symbol'));
  const contractSizes = useSelector(getContractSizes);

  const currencySpec = useMemo(() => {
    return get(currencySpecs, get(currencyPair, 'value', currencyPair), {});
  }, [currencySpecs, currencyPair]);

  useEffect(() => {
    if (currencySpec.contractSize) {
      dispatch(change(SWAPS_CALCULATOR_FORM, 'contractSize', currencySpec.contractSize));
    }
  }, [currencySpec.contractSize, dispatch]);

  const handleSubmit = useCallback(
    ({ symbol, baseCurrency, volumeInLots }) => {
      dispatch(
        swapsCalculatorRequest({
          symbol: symbol.value,
          baseCurrency,
          volumeInLots,
        }),
      );
    },
    [dispatch],
  );

  return (
    <SwapsCalculatorForm
      isPending={isPending}
      form={SWAPS_CALCULATOR_FORM}
      symbols={symbols}
      contractSizes={contractSizes}
      results={results}
      onSubmit={handleSubmit}
    />
  );
};

export default memo(SwapsCalculatorFormContainer);
