const isStateRestricted = (userProfile, countiesCollection) => {
  return !!(
    userProfile?.state &&
    countiesCollection
      ?.find(({ name }) => name === userProfile?.country)
      ?.states?.find(({ name }) => name === userProfile.state)?.restrictedState
  );
};

export default isStateRestricted;
