import { Typography, withStyles } from '@material-ui/core';
import jwtDecode from 'jwt-decode';
import CustomModal from 'modules/common/CustomModal';
import { openModal } from 'modules/common/CustomModal/actions';
import { default as PrimaryButton } from 'modules/common/PrimaryButton';
import TextInput from 'modules/common/TextInput';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { password, required, stringLengthMax128 } from 'utils/validation/fieldValidationRules';
import { equalPasswords } from 'utils/validation/formValidationRules';
import styles from './styles';
import GoogleButton from '../GoogleButton';
import {
  GOOGLE_AUTH_CREATE_PASSWORD_FORM,
  GOOGLE_AUTH_CREATE_PASSWORD_MODAL,
} from 'modules/auth/constants';
import { createPendingSelector } from 'modules/api/selectors';
import { googleAuthRequest } from 'modules/auth/pages/SignUp/actions';

const GoogleSignup = ({ classes, onSuccess }) => {
  const dispatch = useDispatch();
  const [googleUser, setGoogleUser] = useState(null);

  const handlePasswordSubmit = useCallback(
    ({ password }) => {
      const { email, given_name, family_name, locale, ...restData } = googleUser;
      onSuccess({
        googleUser: {
          email: email,
          firstName: given_name,
          lastName: family_name,
          ...restData,
        },
        password,
      });
    },
    [onSuccess, googleUser],
  );

  const validatePasswordForm = (data) => {
    return equalPasswords(data);
  };

  const onSuccessGoogleResponse = (response) => {
    const userObject = jwtDecode(response.credential);
    setGoogleUser(userObject);

    dispatch(openModal(GOOGLE_AUTH_CREATE_PASSWORD_MODAL));
  };

  const onFailure = () => {};

  return (
    <div className={classes.root}>
      <GoogleButton onFailure={onFailure} onSuccess={onSuccessGoogleResponse} />
      <CreatePasswordModal
        modalName={GOOGLE_AUTH_CREATE_PASSWORD_MODAL}
        onSubmit={handlePasswordSubmit}
        validate={validatePasswordForm}
      />
    </div>
  );
};

const CreatePasswordModal = compose(
  reduxForm({ form: GOOGLE_AUTH_CREATE_PASSWORD_FORM, enableReinitialize: true }),
  withStyles(styles),
)(({ modalName, classes, handleSubmit }) => {
  const { t } = useTranslation('auth');
  const isRequestPending = useSelector(createPendingSelector(googleAuthRequest));

  return (
    <CustomModal modalName={modalName} className={classes.modalContainer} isCloseButtonNeeded>
      <section>
        <Typography className={classes.title}>{t('signUp.createPasswordModal.title')}</Typography>
      </section>
      <form onSubmit={handleSubmit}>
        <Field
          name="password"
          label={t('common:labels.createPassword')}
          placeholder={t('common:labels.createPassword')}
          isConfidential
          tipText={t('tipText')}
          component={TextInput}
          className={classes.textField}
          validate={[required, password, stringLengthMax128]}
        />
        <Field
          name="confirmPassword"
          label={t('common:labels.confirmPassword')}
          placeholder={t('common:labels.confirmPassword')}
          isConfidential
          component={TextInput}
          className={classes.textField}
          validate={[required, password, stringLengthMax128]}
        />
        <section className={classes.sectionButtons}>
          <PrimaryButton
            type="submit"
            variant="contained"
            size="large"
            label={t('signUp.createPasswordModal.saveButton')}
            className={classes.primaryButton}
            isPending={isRequestPending}
          />
        </section>
      </form>
    </CustomModal>
  );
});

export default withStyles(styles)(GoogleSignup);
