import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/LayoutNew';
import DocumentTitle from 'react-document-title';
import { Field, reduxForm } from 'redux-form';
import { FORCE_PASSWORD_CHANGE_FORM } from './constants';
import CustomLink from '../../../common/CustomLink';
import { getStaticSiteUrl } from '../../../../utils/getStaticSiteUrl';
import config from '../../../../config';
import { Typography } from '@material-ui/core';
import TextInput from '../../../common/TextInput/containers/TextInputContainer';
import {
  password,
  required,
  stringLengthMax128,
} from '../../../../utils/validation/fieldValidationRules';
import Button from '../../../common/PrimaryButton';
import isEqual from 'lodash/isEqual';
import { createPendingSelector } from '../../../api/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { forcePasswordChangeRequest } from './actions';
import omit from 'lodash/omit';
import { ErrorOutline } from '@material-ui/icons';
import { getTranslation } from '../../../../utils/compositeTranslationHandler';
import classNames from 'classnames';
import styles from './styles';
import { compose } from 'redux';
import Spinner from 'modules/common/Spinner';

const validatePasswords = (formData) => {
  if (formData.password && formData.confirmPassword) {
    if (isEqual(formData.oldPassword, formData.password)) {
      return {
        password: { key: 'common:errors.form.samePassword' },
      };
    }

    if (!isEqual(formData.password, formData.confirmPassword)) {
      return {
        confirmPassword: { key: 'common:errors.form.confirmPassword' },
      };
    }
  }
  return {};
};

let ForcePasswordChange = ({ classes, handleSubmit, error }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation('auth');
  const { CURRENT_HUB_LABEL, LOGO, IS_CLIM, SIGNIN_DISCLAIMER } = config;
  const isPending = useSelector(createPendingSelector(forcePasswordChangeRequest()));

  const onSubmit = (data) => {
    dispatch(forcePasswordChangeRequest(omit(data, ['confirmPassword'])));
  };

  return (
    <Layout>
      <div className={classNames({ [classes.mainLayout]: SIGNIN_DISCLAIMER })}>
        <DocumentTitle title={t('resetPassword.documentTitle', { company: CURRENT_HUB_LABEL })} />
        <div className={classes.formWrapper}>
          <CustomLink className={classes.logoWrapper} to={getStaticSiteUrl()}>
            <img
              src={LOGO.blue}
              className={classNames(classes.logo, { [classes.climCustomLogo]: IS_CLIM })}
              alt="black-logo"
            />
          </CustomLink>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <section className={classes.wrapper}>
              <h2 className={classes.title}>{t('forcePasswordChange.title')}</h2>
              <Typography className={classes.caption}>
                {t('forcePasswordChange.caption')}
              </Typography>
              {error && (
                <span className={classes.formError}>
                  <ErrorOutline className={classes.errorIcon} />
                  &nbsp;
                  {getTranslation(t, error)}
                </span>
              )}
              <Field
                name="oldPassword"
                className={classes.passwordField}
                component={TextInput}
                validate={[required]}
                isConfidential
                label={t('common:labels.oldPassword')}
                placeholder={t('common:labels.oldPassword')}
              />
              <Field
                name="password"
                className={classes.passwordField}
                component={TextInput}
                validate={[required, password, stringLengthMax128]}
                isConfidential
                tipText={t('tipText')}
                label={t('common:labels.newPassword')}
                placeholder={t('common:labels.newPassword')}
              />
              <Field
                name="confirmPassword"
                className={classes.passwordField}
                component={TextInput}
                validate={[required, password, stringLengthMax128]}
                isConfidential
                label={t('common:labels.confirmPassword')}
                placeholder={t('common:labels.confirmPassword')}
              />

              {isPending ? (
                <div className={classes.loader}>
                  <Spinner />
                </div>
              ) : (
                <Button
                  isPending={isPending}
                  fullWidth
                  color="primary"
                  size="large"
                  label={t('forcePasswordChange.updatePassword')}
                  type="submit"
                  className={classNames(classes.submitButton, {
                    [classes.climCustomButton]: IS_CLIM,
                  })}
                />
              )}
            </section>
          </form>
        </div>
      </div>
    </Layout>
  );
};
export default compose(
  reduxForm({
    form: FORCE_PASSWORD_CHANGE_FORM,
    validate: validatePasswords,
  }),
  withStyles(styles),
)(ForcePasswordChange);
