import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Typography } from '@material-ui/core';

import { getMultipleFieldsData } from './helpers';
import Spinner from '../../Spinner';

const TableTextField = ({
  classes,
  label,
  dataLabel,
  dataIcon,
  tableItem,
  formatFunc,
  className,
  isLoading,
  showLabel = false,
  isHeadRow,
}) => {
  const { fieldValues } = useMemo(() => getMultipleFieldsData('', dataLabel, tableItem), [
    dataLabel,
    tableItem,
  ]);

  if (showLabel && isHeadRow) {
    return (
      <div className={classNames([classes.item, className])}>
        {label.map((value) => (
          <Typography key={value} className={classes.label}>
            {value}
          </Typography>
        ))}
      </div>
    );
  }

  return (
    <div className={classNames([classes.item, className])}>
      {fieldValues.map((fieldValue, key) => {
        if (fieldValue === undefined) {
          return null;
        }
        return (
          <div key={key} className={classes.dataText}>
            {dataIcon && get(tableItem, dataIcon) && (
              <div className={classes.dataIconContainer}>
                <img className={classes.dataIcon} alt="flag" src={get(tableItem, dataIcon)} />
              </div>
            )}
            {isLoading ? (
              <Spinner />
            ) : (
              <div>{formatFunc ? formatFunc(fieldValue) : fieldValue}</div>
            )}
          </div>
        );
      })}
    </div>
  );
};

TableTextField.propTypes = {
  showLabel: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  dataLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  tableItem: PropTypes.object.isRequired,
  formatFunc: PropTypes.func,
  className: PropTypes.string,
  route: PropTypes.string,
  getRedirectRoute: PropTypes.func,
};

export default withStyles(styles)(TableTextField);
