export default {
  root: (theme) => ({
    '& .slick-prev': {
      left: '-16px',
    },
    '& .slick-next': {
      right: '-16px',
    },
    '& .slick-prev, & .slick-next': {
      height: '16px',
      width: '16px',
      background: theme.palette.primary.lightest,
    },
    '& .slick-prev:before, & .slick-next:before': {
      fontSize: '10px',
      color: theme.palette.text.dark,
      display: 'inline-block',
    },
    '& .slick-disabled, & .slick-disabled:before': {
      opacity: 0.5,
    },
    '& .slick-prev:before': {
      paddingRight: '2px',
      paddingTop: '2px',
    },
    '& .slick-next:before': {
      paddingLeft: '2px',
      paddingTop: '2px',
    },
  }),
};
