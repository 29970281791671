import React from 'react';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const NoData = ({ classes, message, image, ButtonComponent }) => {
  const { t } = useTranslation('myPerformance');

  return (
    <div className={classes.noDataContainer}>
      <div className={classes.noDataTitle}>{message}</div>
      <img className={classes.noDataImage} src={image} alt={t('page.noData')} />
      <ButtonComponent />
    </div>
  );
};

export default withStyles(styles)(NoData);
