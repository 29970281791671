export const responsiveButtonCell = () => ({
  item: {
    '@media (max-width:1440px)': {
      width: '148px',
      minWidth: '148px',
    },
    '@media (min-width:1440px)': {
      width: '204px',
      minWidth: '204px',
    },
  },
});
