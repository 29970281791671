import React from 'react';

import TPTypography from 'components/TP-UI/TPTypography';

const TPCardTitle = ({ variant = 'h5', children, ...props }) => (
  <TPTypography variant={variant} {...props}>
    {children}
  </TPTypography>
);

export default TPCardTitle;
