import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import * as authSelectors from 'modules/auth/selectors';
import { Routes } from 'constants/routeConstants';
import NotFoundPage from '../components/NotFoundPage';

class NotFoundPageContainer extends PureComponent {
  onReturnToPreviousPage = () => {
    this.props.history.goBack();
  };

  onRedirectToHomePage = () => {
    this.props.history.push(Routes.MY_ACCOUNTS);
  };

  render() {
    const props = {
      onReturnToPreviousPage: this.onReturnToPreviousPage,
      onRedirectToHomePage: this.onRedirectToHomePage,
      isUserAuthenticated: this.props.isUserAuthenticated,
    };
    return <NotFoundPage {...props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    isUserAuthenticated: authSelectors.getIsAuthenticated(state),
  };
};

export default connect(mapStateToProps)(NotFoundPageContainer);
