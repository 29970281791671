import React from 'react';
import PropTypes from 'prop-types';
import { Box, Icon, Typography } from 'material-latest';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import styles from './styles';

const InfoBox = ({ text }) => {
  return (
    <Box sx={styles.boxContainer}>
      <Icon sx={styles.boxIcon} component={InfoOutlinedIcon} />
      <Typography sx={styles.boxText}>{text}</Typography>
    </Box>
  );
};

InfoBox.propTypes = {
  text: PropTypes.string.isRequired,
};

export default InfoBox;
