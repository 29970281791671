import createAction from 'redux-actions/es/createAction';

export const sendAmlAnswersRequest = createAction('SEND_AML_ANSWERS_REQUEST');
export const sendAmlAnswersSuccess = createAction('SEND_AML_ANSWERS_SUCCESS');
export const sendAmlAnswersFail = createAction('SEND_AML_ANSWERS_FAIL');

export const hideCompleteAML = createAction('HIDE_COMPLETE_AML');
export const clearCompleteAML = createAction('CLEAR_COMPLETE_AML');

export const getAmlQuestionsRequest = createAction('GET_AML_QUESTIONS_REQUEST');
export const getAmlQuestionsSuccess = createAction('GET_AML_QUESTIONS_SUCCESS');
export const getAmlQuestionsFail = createAction('GET_AML_QUESTIONS_FAIL');
