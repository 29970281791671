export default {
  inherit: {
    fontSize: 'inherit',
    lineHeight: 'inherit',
  },
  semiBold: (theme) => ({
    fontWeight: theme.typography.fontWeightMedium,
  }),
  bold: (theme) => ({
    fontWeight: theme.typography.fontWeightBold,
  }),
  truncated: {
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    width: 'auto',
    overflow: 'hidden',
    wordBreak: 'break-all',
  },
};
