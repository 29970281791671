import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import MenuScrollIndicators from '../components/MenuScrollIndicators';

class MenuScrollIndicatorsContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.list = React.createRef();
    this.state = {
      showTopButton: false,
      showBottomButton: true,
    };
    this.scrollTopInterval = null;
    this.scrollBottomInterval = null;
  }

  checkBottomButton = () => {
    if (
      this.list.current.getClientHeight() === this.list.current.getScrollHeight() &&
      this.state.showBottomButton
    ) {
      this.setState({
        showBottomButton: false,
      });
    } else if (
      this.list.current.getClientHeight() !== this.list.current.getScrollHeight() &&
      this.list.current.getScrollHeight() - this.list.current.getScrollTop() !==
        this.list.current.getClientHeight() &&
      !this.state.showBottomButton
    ) {
      this.setState({
        showBottomButton: true,
      });
    }
  };

  componentDidMount() {
    this.checkBottomButton();
  }

  componentDidUpdate(prevProps, prevState) {
    const { handleTopButtonChange } = this.props;
    const { showTopButton } = this.state;
    if (prevState.showTopButton !== showTopButton) {
      handleTopButtonChange && handleTopButtonChange(showTopButton);
    }
    this.checkBottomButton();
  }

  onScroll = (event) => {
    this.setState({
      showTopButton: event.target.scrollTop !== 0,
      showBottomButton:
        event.target.scrollHeight - event.target.scrollTop !== event.target.clientHeight,
    });
  };

  onMenuClose = () => {
    this.setState({
      showTopButton: false,
      showBottomButton: true,
    });
    clearInterval(this.scrollBottomInterval);
    clearInterval(this.scrollTopInterval);
    this.scrollTopInterval = null;
    this.scrollBottomInterval = null;
  };

  componentWillUnmount() {
    this.onMenuClose();
    this.list = null;
  }

  scrollToBottom = () => {
    if (this.scrollBottomInterval) {
      clearInterval(this.scrollBottomInterval);
      this.scrollBottomInterval = null;
    } else {
      this.scrollBottomInterval = setInterval(() => {
        const scrollTop = this.list.current.getScrollTop();
        this.list.current.scrollTop(scrollTop + 20);
      }, 20);
    }
  };

  scrollToTop = () => {
    if (this.scrollTopInterval) {
      clearInterval(this.scrollTopInterval);
      this.scrollTopInterval = null;
    } else {
      this.scrollTopInterval = setInterval(() => {
        const scrollTop = this.list.current.getScrollTop();
        this.list.current.scrollTop(scrollTop - 20);
      }, 20);
    }
  };

  render() {
    const props = {
      onScroll: this.onScroll,
      isTopButtonShowed: this.state.showTopButton,
      isBottomButtonShowed: this.state.showBottomButton,
      scrollToTop: this.scrollToTop,
      scrollToBottom: this.scrollToBottom,
      list: this.list,
      onMenuClose: this.onMenuClose,
    };
    return <MenuScrollIndicators {...this.props} {...props} />;
  }
}

MenuScrollIndicatorsContainer.propTypes = {
  handleTopButtonChange: PropTypes.func,
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuScrollIndicatorsContainer);
