import { put, select, take, takeEvery } from 'redux-saga/effects';
import { getUser, getUserHub } from '../../auth/selectors';
import { ACCOUNT_PLANS } from '../../../constants/acctountPlans';
import { push } from 'connected-react-router';
import { Routes } from '../../../constants/routeConstants';
import {
  getQuizInsightsRequest,
  getQuizInsightsSuccess,
  setEvidenceUploadView,
  startUpgradeToProV2,
  upgradeToProV2Request,
  upgradeToProV2Success,
} from '../actions';
import verificationModalNames from '../../VerificationModalsManager/constants/verificationModalNames';
import { GLOBAL_PRIME_X } from '../../../constants';
import { openModal } from '../../common/CustomModal/actions';

function* upgradeToProWorker() {
  const { selectedPlan, proQuizProgress, isPro, proSign } = yield select(getUser);
  const hub = yield select(getUserHub);

  const isENUser = hub === GLOBAL_PRIME_X;

  if (isENUser || (selectedPlan === ACCOUNT_PLANS.PRO && isPro)) {
    return yield put(push(Routes.MY_ACCOUNTS));
  }

  if (proQuizProgress.isPassed && !proSign) {
    yield put(getQuizInsightsRequest());
    yield take(getQuizInsightsSuccess);
    return;
  }

  if (!isPro && proQuizProgress.isPassed && selectedPlan === ACCOUNT_PLANS.PRO && proSign) {
    return yield put(setEvidenceUploadView(true));
  }

  if (proQuizProgress.isBlocked) {
    return yield put(openModal(verificationModalNames.UPGRADE_BLOCKED_MODAL));
  }

  yield put(upgradeToProV2Request(true));

  yield take(upgradeToProV2Success);
}

function* upgradeToProWatcher() {
  yield takeEvery(startUpgradeToProV2, upgradeToProWorker);
}

export default upgradeToProWatcher;
