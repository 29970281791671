import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = '';

export default handleActions(
  {
    [actionCreators.setCurrentLanguage](state, { payload }) {
      return payload;
    },
  },
  defaultState,
);
