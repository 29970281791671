export default {
  pagesContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  prevIcon: (theme) => ({
    marginLeft: theme.spacing(1.25),
  }),
  pageList: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    display: 'flex',
  },
  nextIcon: (theme) => ({
    marginLeft: theme.spacing(0.25),
  }),
};
