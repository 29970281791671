import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import OutdatedPassword from '../components/OutdatedPassword';
import * as actionCreators from '../actions';
import { FORGOT_PASSWORD_FORM } from '../constants';
import * as apiSelectors from 'modules/api/selectors';
import { getSelectHub } from '../selectors';

class OutdatedPasswordContainer extends PureComponent {
  componentDidMount() {
    const {
      actions: { initializeForm },
    } = this.props;
    initializeForm();
  }

  onSubmit = (data) => {
    const {
      actions: { forgotPasswordRequest },
    } = this.props;

    forgotPasswordRequest(data);
  };

  redirect = () => {
    const {
      actions: { redirectToSignIn },
    } = this.props;

    redirectToSignIn();
  };

  render() {
    return (
      <OutdatedPassword
        form={FORGOT_PASSWORD_FORM}
        onSubmit={this.onSubmit}
        redirect={this.redirect}
        isPending={this.props.isPending}
        selectHub={this.props.selectHub}
      />
    );
  }
}

OutdatedPasswordContainer.propTypes = {
  actions: PropTypes.shape({
    forgotPasswordRequest: PropTypes.func.isRequired,
  }).isRequired,
  isPending: PropTypes.bool.isRequired,
  selectHub: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isPending: apiSelectors.createPendingSelector(actionCreators.forgotPasswordRequest)(state),
    selectHub: getSelectHub(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OutdatedPasswordContainer);
