import { handleActions, combineActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = false;

export default handleActions(
  {
    [actionCreators.getEconomicCalendarRequest]() {
      return true;
    },
    [actionCreators.getTechnicalInsightRequest]() {
      return true;
    },
    [combineActions(
      actionCreators.getEconomicCalendarSuccess,
      actionCreators.getEconomicCalendarFail,
    )]() {
      return defaultState;
    },
    [combineActions(
      actionCreators.getTechnicalInsightSuccess,
      actionCreators.getTechnicalInsightFail,
    )]() {
      return defaultState;
    },
  },
  defaultState,
);
