import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment } from 'material-latest';

const TPInputAdornment = ({ position = 'start', children, ...others }) => {
  return children ? (
    <InputAdornment position={position} {...others}>
      {children}
    </InputAdornment>
  ) : null;
};

TPInputAdornment.propTypes = {
  position: PropTypes.oneOf(['start', 'end']),
  children: PropTypes.node,
};

export default TPInputAdornment;
