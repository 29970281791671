import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import TPTable from 'components/TP-UI/TPTable';
import NoMatches from './components/NoMatches';

const Table = ({ noMatchesText, ...props }) => {
  const { t } = useTranslation('common');

  return (
    <TPTable
      {...props}
      noMatchesText={<NoMatches text={noMatchesText || t('messages.noMatchesFound')} />}
    />
  );
};

Table.propTypes = {
  ...TPTable.propTypes,
  noMatchesText: PropTypes.string,
};

export default Table;
