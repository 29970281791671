import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import styles from './styles';

const ImageSubtitleTextContent = ({ classes, image, subtitle, text }) => {
  return (
    <div>
      <div className={classes.image}>
        <img src={image} alt="image-modal" />
      </div>
      {subtitle ? (
        <Typography className={classes.subtitle} align="center">
          {subtitle}
        </Typography>
      ) : null}
      {typeof text === 'string' ? <Typography className={classes.text}>{text}</Typography> : text}
    </div>
  );
};

ImageSubtitleTextContent.propTypes = {
  image: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.elementType]).isRequired,
};

export default withStyles(styles)(ImageSubtitleTextContent);
