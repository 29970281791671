const styles = (themes) => ({
  noDataContainer: {
    color: themes.palette.text,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  noDataImage: {
    maxWidth: '450px',
    width: '80%',
    marginBottom: 20,
  },
  noDataTitle: {
    color: themes.palette.text,
    fontSize: 26,
    marginBottom: 20,
    maxWidth: '775px',
    textAlign: 'center',
  },
});

export default styles;
