import { palette } from '../palette';
import { fontSize } from '../../font';

const getButtonSize = (size) => ({
  width: size,
  height: size,
});

const OverridesMuiIconButton = {
  styleOverrides: {
    root: {
      color: palette.text.dark,
      '&:hover': {
        background: palette.primary.lightest2,
      },
      '&.Mui-disabled': {
        color: palette.textSecondary.lightest,
      },
    },

    colorPrimary: {
      background: palette.primary.lightest,
      color: palette.text.dark,
      '&:hover': {
        background: palette.primary.lighter,
      },
      '&:active': {
        background: palette.primary.lightest,
      },
      '&.Mui-disabled': {
        background: palette.primary.lightest,
        color: palette.textSecondary.lightest,
      },
    },

    sizeLarge: {
      ...getButtonSize('40px'),
      fontSize: fontSize._24,
    },

    sizeMedium: {
      ...getButtonSize('36px'),
      fontSize: fontSize._16,
    },

    sizeSmall: {
      ...getButtonSize('32px'),
      fontSize: fontSize._14,
    },

    sizeXsmall: {
      ...getButtonSize('20px'),
      fontSize: fontSize._12,
    },
  },
};
export default OverridesMuiIconButton;
