import { createTheme } from 'material-latest';
import { palette } from './palette';
import OverridesMuiButton from './overrides/button';
import { OverridesTypography, typography } from './overrides/typography';
import OverridesMuiFormControlLabel from './overrides/formControlLabel';
import OverridesMuiFormLabel from './overrides/formLabel';
import OverridesMuiCheckbox from './overrides/checkbox';
import OverridesMuiSvgIcon from './overrides/svgIcon';
import OverridesMuiRadio from './overrides/radio';
import OverridesMuiOutlinedInput from './overrides/outlinedInput';
import OverridesMuiFormHelperText from './overrides/formHelperText';
import OverridesMuiInputAdornment from './overrides/inputAdornment';
import OverridesMuiTabs from './overrides/tabs';
import OverridesMuiTab from './overrides/tab';
import OverridesMuiMenuItem from './overrides/menuItem';
import OverridesMuiDivider from './overrides/divider';
import OverridesMuiIconButton from './overrides/iconButton';
import OverridesMuiSelect from './overrides/select';
import OverridesMuiChip from './overrides/chip';
import OverridesMuiPaper from './overrides/paper';
import OverridesMuiStepIcon from './overrides/stepIcon';
import OverridesMuiStepConnector from './overrides/stepConnector';
import OverridesMuiStepper from './overrides/stepper';
import OverridesMuiStepLabel from './overrides/stepLabel';
import OverridesMuiFormControl from './overrides/formControl';
import { common } from './common';

// Normal or default theme
const theme = {
  themeName: 'normal',

  // palette
  palette: {
    ...palette,
  },
  // common styles
  ...common,
  // overrides
  components: {
    MuiButton: { ...OverridesMuiButton },
    MuiIconButton: { ...OverridesMuiIconButton },
    MuiCheckbox: { ...OverridesMuiCheckbox },
    MuiDivider: { ...OverridesMuiDivider },
    MuiFormControl: { ...OverridesMuiFormControl },
    MuiFormLabel: { ...OverridesMuiFormLabel },
    MuiFormControlLabel: { ...OverridesMuiFormControlLabel },
    MuiFormHelperText: { ...OverridesMuiFormHelperText },
    MuiOutlinedInput: { ...OverridesMuiOutlinedInput },
    MuiInputAdornment: { ...OverridesMuiInputAdornment },
    MuiPaper: { ...OverridesMuiPaper },
    MuiRadio: { ...OverridesMuiRadio },
    MuiSvgIcon: { ...OverridesMuiSvgIcon },
    MuiTypography: { ...OverridesTypography },
    MuiTabs: { ...OverridesMuiTabs },
    MuiTab: { ...OverridesMuiTab },
    MuiMenuItem: { ...OverridesMuiMenuItem },
    MuiSelect: { ...OverridesMuiSelect },
    MuiChip: { ...OverridesMuiChip },
    MuiStepIcon: { ...OverridesMuiStepIcon },
    MuiStepConnector: { ...OverridesMuiStepConnector },
    MuiStepper: { ...OverridesMuiStepper },
    MuiStepLabel: { ...OverridesMuiStepLabel },
  },
  typography: { ...typography },
};

export default createTheme(theme);
