import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = {};

export default handleActions(
  {
    [actionCreators.setSlidesEditing](state, action) {
      return { ...state, ...action.payload };
    },
  },
  defaultState,
);
