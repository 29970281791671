import assign from 'lodash/assign';
import { handleActions } from 'redux-actions';
import * as actionCreators from '../actions';
import { VIEW_TYPE } from '../constants';

const defaultState = {
  articleCounts: [],
  entities: [],
  discoverViewType: VIEW_TYPE.TABLE,
};

export default handleActions(
  {
    [actionCreators.getDiscoverDataSuccess](state, action) {
      return assign({}, state, {
        articleCounts: action.response.data,
      });
    },
    [actionCreators.getDiscoverDataFail](state) {
      return { ...state, ...{ articleCounts: [] } };
    },
    [actionCreators.getDiscoverEntitiesSuccess](state, action) {
      return { ...state, ...{ entities: action.response.data } };
    },
    [actionCreators.getDiscoverEntitiesFail](state) {
      return { ...state, ...{ entities: [] } };
    },
    [actionCreators.setDiscoverView](state, action) {
      return {
        ...state,
        discoverViewType: action.payload,
      };
    },
  },
  defaultState,
);
