const styles = (theme) => ({
  label: {
    color: theme.palette.simpleGrey,
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 1.4,
  },
  text: {
    color: theme.palette.textColor,
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: 1.2,
    whiteSpace: 'nowrap',
  },
  item: {
    padding: '20px 12px',
    '@media (max-width:1366px)': {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
});

export default styles;
