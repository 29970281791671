import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = {
  isPending: false,
};

export default handleActions(
  {
    [actionCreators.createMailchimpUserRequest]: () => ({ isPending: true }),
    [actionCreators.createMailchimpUserSuccess]: () => ({ isPending: false }),
    [actionCreators.createMailchimpUserFail]: () => ({ isPending: false }),

    [actionCreators.setProUserRequest]: () => ({ isPending: true }),
    [actionCreators.setProUserSuccess]: () => ({ isPending: false }),
    [actionCreators.setProUserFail]: () => ({ isPending: false }),

    [actionCreators.setUnlimitedAccountsRequest]: () => ({ isPending: true }),
    [actionCreators.setUnlimitedAccountsSuccess]: () => ({ isPending: false }),
    [actionCreators.setUnlimitedAccountsFail]: () => ({ isPending: false }),

    [actionCreators.setUnlimitedDemoAccountsRequest]: () => ({ isPending: true }),
    [actionCreators.setUnlimitedDemoAccountsSuccess]: () => ({ isPending: false }),
    [actionCreators.setUnlimitedDemoAccountsFail]: () => ({ isPending: false }),

    [actionCreators.setWholesaleUserRequest]: () => ({ isPending: true }),
    [actionCreators.setWholesaleUserSuccess]: () => ({ isPending: false }),
    [actionCreators.setWholesaleUserFail]: () => ({ isPending: false }),

    [actionCreators.restrictUserLeverageRequest]: () => ({ isPending: true }),
    [actionCreators.restrictUserLeverageSuccess]: () => ({ isPending: false }),
    [actionCreators.restrictUserLeverageFail]: () => ({ isPending: false }),
  },
  defaultState,
);
