import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';
import * as authActionCreators from '../../../actions';

const defaultState = true;

export default handleActions(
  {
    [actionCreators.setIsFirstTimeOpen](state, action) {
      return action.payload;
    },
    [authActionCreators.logout]() {
      return defaultState;
    },
  },
  defaultState,
);
