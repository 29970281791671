const styles = () => ({
  dataText: {
    color: '#36425A',
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '17px',
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '0 30px 10px 16px',
  },
  statusImage: {
    marginRight: '9px',
  },
});

export default styles;
