export default {
  root: (theme) => ({
    '& > *:not(:first-child)': {
      marginTop: theme.spacing(1),
    },
    '& > *:first-child': {
      marginTop: 0,
    },
  }),
  divider: (theme) => ({
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.primary.lighter}`,
  }),
};
