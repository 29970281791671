import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createPendingSelector } from 'modules/api/selectors';

import { currencyCalculatorRequest } from '../actions';
import { CURRENCY_CALCULATOR_FORM } from '../constants';
import { getPreparedCurrencyResults } from '../selectors';

import CurrencyCalculatorForm from '../components/CurrencyCalculatorForm';

const CurrencyCalculatorFormContainer = ({ symbols }) => {
  const dispatch = useDispatch();
  const isPending = useSelector(createPendingSelector(currencyCalculatorRequest));
  const results = useSelector(getPreparedCurrencyResults);

  const handleSubmit = useCallback(
    (calculationRequestData) => {
      dispatch(currencyCalculatorRequest(calculationRequestData));
    },
    [dispatch],
  );

  return (
    <CurrencyCalculatorForm
      isPending={isPending}
      form={CURRENCY_CALCULATOR_FORM}
      symbols={symbols}
      results={results}
      onSubmit={handleSubmit}
    />
  );
};

export default memo(CurrencyCalculatorFormContainer);
