import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import CustomModal from 'modules/common/CustomModal';
import MainLayout from 'modules/common/MainLayout';
import PrimaryButton from 'modules/common/PrimaryButton';
import NoData from 'modules/myFollowers/components/NoData';
import fusionPlusIcon from 'static/myFollowers/noFollowersYet.svg';
import { useDispatch, useSelector } from 'react-redux';
import * as apiSelectors from 'modules/api/selectors';
import { REQUEST_FUSION_PLUS_MODAL } from '../../constants';
import RequestFusionPlusForm from '../RequestFusionPlusForm';
import * as actions from '../../actions';

const MyFollowersContainer = () => {
  const { t } = useTranslation('myFollowers');
  const dispatch = useDispatch();
  const requestFusionPlusRequestPending = useSelector(
    apiSelectors.createPendingSelector(actions.requestFusionPlusRequest),
  );

  const requestFusionPlusRequest = useCallback(
    (data) => {
      dispatch(actions.requestFusionPlusRequest(data));
    },
    [dispatch],
  );

  const requestFusionPlusOpen = useCallback(() => {
    dispatch(actions.requestFusionPlusOpen());
  }, [dispatch]);

  const requestFusionPlusClose = useCallback(() => {
    dispatch(actions.requestFusionPlusClose());
  }, [dispatch]);

  return (
    <MainLayout title={t('page.fusionPlus')}>
      <NoData
        message={t('page.notAllowed')}
        image={fusionPlusIcon}
        ButtonComponent={() => (
          <PrimaryButton
            color="primary"
            size="large"
            label={t('buttons.request')}
            onClick={requestFusionPlusOpen}
          />
        )}
      />
      <CustomModal modalName={REQUEST_FUSION_PLUS_MODAL}>
        <RequestFusionPlusForm
          onSubmit={requestFusionPlusRequest}
          onCancel={requestFusionPlusClose}
          isPending={requestFusionPlusRequestPending}
        />
      </CustomModal>
    </MainLayout>
  );
};

export default withRouter(MyFollowersContainer);
