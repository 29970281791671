export const VARIANTS = {
  primary: {
    variant: 'contained',
    color: 'primary',
  },
  alternative: {
    variant: 'outlined',
    color: 'primary',
  },
  secondary: {
    variant: 'text',
    color: 'primary',
  },
};
