import * as tableTextFieldThemes from './TableTextField/themes';
import * as tableSingleTextFieldThemes from './TableSingleTextField/themes';
import * as tableCheckboxThemes from './TableCheckboxField/themes';
import * as tableStatusFieldThemes from './TableStatusField/themes';
import * as tableButtonFieldThemes from './TableButtonField/themes';
import * as tableAmountFieldThemes from './TableAmountField/themes';
import * as tableAmountReuseFieldThemes from './TableAmountReuseField/themes';
import * as tableTwoButtonsFieldThemes from './TableTwoButtonsField/themes';
import * as tableTextAndCountFieldThemes from './TableTextAndCountField/themes';
import * as tableButtonsWithConditionFieldThemes from './TableButtonsWithConditionField/themes';
import * as tableTwoButtonsWithConditionFieldThemes from './TableTwoButtonsWithConditionField/themes';
import * as TableButtonsWithOptionalFieldThemes from './TableButtonsWithOptionalField/themes';
import * as tableTwoPrimaryButtonsFieldThemes from './TableTwoPrimaryButtonsField/themes';
import * as tableBoldAndNormalTextFieldThemes from './TableBoldAndNormalTextField/themes';
import * as tableListOfLinksFieldThemes from './TableListOfLinksField/themes';
import * as TableDeleteFieldThemes from './TableDeleteField/themes';
import * as TableLevelFieldThemes from './TableLevelField/themes';
import * as TableDropdownFieldThemes from './TableDropdownField/themes';
import TableTextField from './TableTextField';
import TableSingleTextField from './TableSingleTextField';
import TableTextCompareField from './TableTextCompareField';
import TableCheckboxField from './TableCheckboxField';
import TableStatusField from './TableStatusField';
import TableAmountField from './TableAmountField';
import TableAmountMultipleField from './TableAmountMultipleField';
import TableAmountReuseField from './TableAmountReuseField';
import TableTwoButtonsField from './TableTwoButtonsField';
import TableButtonField from './TableButtonField';
import TableTextAndCountField from './TableTextAndCountField';
import TableButtonsWithConditionField from './TableButtonsWithConditionField';
import TableTwoButtonsWithConditionField from './TableTwoButtonsWithConditionField';
import TableButtonsWithOptionalField from './TableButtonsWithOptionalField';
import TableTwoPrimaryButtonsField from './TableTwoPrimaryButtonsField';
import TableBoldAndNormalTextField from './TableBoldAndNormalTextField';
import TableListOfLinksField from './TableListOfLinksField';
import TableDeleteField from './TableDeleteField';
import TableLevelField from './TableLevelField';
import TableTextMultipleField from './TableTextMultipleField';
import TableDropdownField from './TableDropdownField';

export default {
  TableTextField: {
    Component: TableTextField,
    themes: tableTextFieldThemes,
  },
  TableSingleTextField: {
    Component: TableSingleTextField,
    themes: tableSingleTextFieldThemes,
  },
  TableTextCompareField: {
    Component: TableTextCompareField,
    themes: tableTextFieldThemes,
  },
  TableCheckboxField: {
    Component: TableCheckboxField,
    themes: tableCheckboxThemes,
  },
  TableTextMultipleField: {
    Component: TableTextMultipleField,
    themes: tableTextFieldThemes,
  },
  TableStatusField: {
    Component: TableStatusField,
    themes: tableStatusFieldThemes,
  },
  TableButtonField: {
    Component: TableButtonField,
    themes: tableButtonFieldThemes,
  },
  TableAmountField: {
    Component: TableAmountField,
    themes: tableAmountFieldThemes,
  },
  TableAmountMultipleField: {
    Component: TableAmountMultipleField,
    themes: tableAmountFieldThemes,
  },
  TableAmountReuseField: {
    Component: TableAmountReuseField,
    themes: tableAmountReuseFieldThemes,
  },
  TableTwoButtonsField: {
    Component: TableTwoButtonsField,
    themes: tableTwoButtonsFieldThemes,
  },
  TableTextAndCountField: {
    Component: TableTextAndCountField,
    themes: tableTextAndCountFieldThemes,
  },
  TableButtonsWithConditionField: {
    Component: TableButtonsWithConditionField,
    themes: tableButtonsWithConditionFieldThemes,
  },
  TableTwoButtonsWithConditionField: {
    Component: TableTwoButtonsWithConditionField,
    themes: tableTwoButtonsWithConditionFieldThemes,
  },
  TableButtonsWithOptionalField: {
    Component: TableButtonsWithOptionalField,
    themes: TableButtonsWithOptionalFieldThemes,
  },
  TableTwoPrimaryButtonsField: {
    Component: TableTwoPrimaryButtonsField,
    themes: tableTwoPrimaryButtonsFieldThemes,
  },
  TableBoldAndNormalTextField: {
    Component: TableBoldAndNormalTextField,
    themes: tableBoldAndNormalTextFieldThemes,
  },
  TableListOfLinksField: {
    Component: TableListOfLinksField,
    themes: tableListOfLinksFieldThemes,
  },
  TableDeleteField: {
    Component: TableDeleteField,
    themes: TableDeleteFieldThemes,
  },
  TableLevelField: {
    Component: TableLevelField,
    themes: TableLevelFieldThemes,
  },
  TableDropdownField: {
    Component: TableDropdownField,
    themes: TableDropdownFieldThemes,
  },
};
