import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as apiSelectors from 'modules/api/selectors';
import VolumeUsersPage from '../views/VolumeUsersView';
import { getTopUserVolumesRequest } from '../actions';
import { getUserVolumes } from '../selectors';
import moment from 'moment';
import { CUSTOM_DATE_RANGE_KEY, DASHBOARD_USER_VOLUMES_HEADER_FORM } from '../constants';
import { DATE_FORMAT_YYYYMMDD } from '../../../constants';
import get from 'lodash/get';

const VolumeUsersContainer = () => {
  const dispatch = useDispatch();
  const isCountryUsersGetPending = useSelector(
    apiSelectors.createPendingSelector(getTopUserVolumesRequest),
  );
  const volumeUsers = useSelector(getUserVolumes);

  useEffect(() => {
    const dateFrom = moment()
      .startOf('month')
      .format(DATE_FORMAT_YYYYMMDD);
    const dateTo = moment().format(DATE_FORMAT_YYYYMMDD);
    dispatch(getTopUserVolumesRequest({ from: dateFrom, to: dateTo }));
  }, [dispatch]);

  const handleHeaderFormSubmit = useCallback(
    (data) => {
      const dateFrom = get(data, 'dateFrom', moment());
      const dateTo = get(data, 'dateTo', moment());
      const formattedDateFrom = moment(dateFrom).format(DATE_FORMAT_YYYYMMDD);
      const formattedDateTo = moment(dateTo).format(DATE_FORMAT_YYYYMMDD);

      dispatch(getTopUserVolumesRequest({ from: formattedDateFrom, to: formattedDateTo }));
    },
    [dispatch],
  );

  const headerProps = {
    form: DASHBOARD_USER_VOLUMES_HEADER_FORM,
    initialValues: {
      customDateSelector: CUSTOM_DATE_RANGE_KEY.MONTH_TO_DATE,
      dateFrom: moment()
        .startOf('month')
        .format(DATE_FORMAT_YYYYMMDD),
      dateTo: moment().format(DATE_FORMAT_YYYYMMDD),
    },
    onSubmit: handleHeaderFormSubmit,
  };

  return (
    <VolumeUsersPage
      headerProps={headerProps}
      isCountryUsersGetPending={isCountryUsersGetPending}
      items={volumeUsers}
    />
  );
};

export default VolumeUsersContainer;
