import { combineActions, handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = '';

export default handleActions(
  {
    [combineActions(actionCreators.paymentsDepositScbSuccess)](state, action) {
      return action.response.data.qrImage;
    },
  },
  defaultState,
);
