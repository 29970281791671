export default {
  iconFilled: (theme) => ({
    color: theme.palette.success.main,
  }),
  iconEmpty: (theme) => ({
    color: theme.palette.textSecondary.dark,
  }),
  iconHovered: (theme) => ({
    color: theme.palette.warning.main,
  }),
};
