import React, { PureComponent } from 'react';

import Tooltip from '../components/Tooltip';

class TooltipContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  handleTooltipOpen = () => {
    this.setState({ isOpen: true });
  };

  handleTooltipClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    return (
      <Tooltip
        handleTooltipOpen={this.handleTooltipOpen}
        handleTooltipClose={this.handleTooltipClose}
        isOpen={this.state.isOpen}
        {...this.props}
      />
    );
  }
}

export default TooltipContainer;
