import { handleActions } from 'redux-actions';
import get from 'lodash/get';

import * as apiActions from '../actions';

const defaultState = {
  attorney: '',
  attorneyAccount: '',
  fee: '',
  sign: '',
  signDate: '',
  createdAt: '',
};

export default handleActions(
  {
    [apiActions.getLpoaSuccess]: (state, action) => {
      return {
        ...state,
        ...get(action, 'response.data.lpoa', {}),
      };
    },
    [apiActions.getLpoaFail]: (state) => ({
      ...state,
    }),
  },
  defaultState,
);
