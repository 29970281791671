export default () => ({
  button: {
    height: 32,
    marginLeft: 15,
    verticalAlign: 'bottom',
  },
  select: {
    height: 32,
    marginLeft: 15,
  },
});
