import React, { PureComponent } from 'react';
import { getFormNames, destroy } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import Payments from '../components/Payments';
import * as accountsActionCreators from 'modules/accounts/pages/MyAccounts/actions';
import { DEPOSIT_CARD_FORM } from '../pages/Deposit/constants';
import * as appSelectors from 'selectors';
import { clientConfirmWithdrawRequest } from '../pages/Withdraw/actions';
import * as apiSelectors from 'modules/api/selectors';
import * as authSelectors from 'modules/auth/selectors';
import * as notificationsActions from 'modules/notifications/actions';
import * as accountsSelectors from 'modules/accounts/pages/MyAccounts/selectors';
import * as partnerTransferActions from 'modules/payments/pages/PartnerTransfer/actions';
import * as pageWarningActions from 'modules/pageWarning/actions';
import * as partnerTransferSelectors from 'modules/payments/pages/PartnerTransfer/selectors';
import * as pageWarningSelectors from 'modules/pageWarning/selectors';
import * as geolocationActions from 'modules/geolocation/actions';
import { store } from 'components/App';

class PaymentsContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
    };
  }

  componentDidMount() {
    const {
      accountsActions: { getAccountsRequest },
      token,
      clientConfirmWithdrawRequest,
    } = this.props;
    import('../sagas').then((saga) => {
      store.injectSaga('Payments', saga.default);
      if (token) {
        clientConfirmWithdrawRequest({ token });
      }
      getAccountsRequest({ isLive: true });
      // "partner transfer" is temporarily disabled
      // this.props.partnerTransferActions.getAffiliateBalanceRequest();
      this.props.pageWarningActions.getWarningPageRequest('payment');
      this.props.geolocationActions.getCountriesAction();
    });
  }

  handleChange = (event, value) => {
    this.setState({ activeTab: value });
    const { currentFormNames, destroy } = this.props;
    if (currentFormNames.includes(DEPOSIT_CARD_FORM)) {
      destroy(DEPOSIT_CARD_FORM);
    }
  };

  render() {
    return (
      <Payments
        activeTab={this.state.activeTab}
        handleChange={this.handleChange}
        isConfirmWithdrawLoading={this.props.isConfirmWithdrawLoading}
        isThaiClient={this.props.isThaiClient}
        accounts={this.props.accounts}
        affiliateAccount={this.props.affiliateAccount}
        userCountry={this.props.userCountry}
        pageWarning={this.props.pageWarning}
      />
    );
  }
}

PaymentsContainer.propTypes = {
  accountsActions: PropTypes.shape({
    getAccountsRequest: PropTypes.func.isRequired,
  }).isRequired,
  currentFormNames: PropTypes.array,
  destroy: PropTypes.func.isRequired,
  currentUserId: PropTypes.string.isRequired,
  isThaiClient: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
  return {
    accountsActions: bindActionCreators(accountsActionCreators, dispatch),
    destroy: bindActionCreators(destroy, dispatch),
    clientConfirmWithdrawRequest: bindActionCreators(clientConfirmWithdrawRequest, dispatch),
    notificationsActions: bindActionCreators(notificationsActions, dispatch),
    partnerTransferActions: bindActionCreators(partnerTransferActions, dispatch),
    pageWarningActions: bindActionCreators(pageWarningActions, dispatch),
    geolocationActions: bindActionCreators(geolocationActions, dispatch),
  };
};

const mapStateToProps = (state, ownProps) => ({
  currentFormNames: getFormNames()(state),
  token: appSelectors.getToken(ownProps),
  isConfirmWithdrawLoading: apiSelectors.createPendingSelector(clientConfirmWithdrawRequest)(state),
  currentUserId: authSelectors.getUserId(state),
  userCountry: authSelectors.getUserCountry(state),
  isThaiClient: authSelectors.isThai(state),
  affiliateAccount: partnerTransferSelectors.getAffiliate(state),
  accounts: accountsSelectors.getLiveAccounts(state),
  pageWarning: pageWarningSelectors.getPageWarning(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PaymentsContainer));
