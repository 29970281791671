import api from 'modules/api/index';

export const getDiscoverDataRequest = ({ params }) => {
  return api.get(`/news/discover`, { params });
};

export const getDiscoverEntitiesRequest = ({ params }) => {
  return api.get(`/news/discover/entities`, { params });
};

export const getDashboardDataRequest = ({ entityId }) => {
  return api.get(`/news/discover/dashboard/${entityId}`);
};

export const getArticleContentRequest = ({ provider, articleId }) => {
  return api.get(`/news/discover/dashboard/article/${provider}/${articleId}`);
};

export const getSentimentTimeseriesRequest = ({ entityId, params }) => {
  return api.get(`/news/discover/sentiment/timeseries/${entityId}`, { params });
};

export const getFeedFiltersRequest = () => {
  return api.get(`/news/discover/feedfilters`);
};

export const searchEntitiesRequest = ({ query }) => {
  return api.get('/news/discover/entitysearch', { params: { q: query } });
};

export const createFeedFilterRequest = (payload) => {
  return api.post('/news/discover/feedfilters', payload);
};

export const updateMyFeedRequest = ({ id, payload }) => {
  return api.patch(`/news/discover/feedfilters/${id}`, payload);
};
export const deleteMyFeedRequest = ({ id }) => {
  return api.delete(`/news/discover/feedfilters/${id}`);
};

export const getMyFeedDetailsDataRequest = ({ id }) => {
  return api.get(`/news/discover/feed/${id}`);
};

export const getMyFeedArticlesRequest = ({ entities, sources }) => {
  return api.get(`/news/discover/articles`, { params: { entities, sources } });
};

export const getSentimentDataRequest = ({ entityId }) => {
  return api.get(`/news/discover/sentiment/${entityId}`);
};

export const getRecentEntitiesRequest = () => {
  return api.get(`/news/discover/recent`);
};

export const getDailyPricesRequest = ({ symbol, latest }) => {
  return api.get(`/news/discover/dailyprices`, { params: { symbol, latest } });
};
