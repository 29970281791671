const styles = (theme) => ({
  sectionTextHighlight: {
    color: theme.palette.simpleGrey,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 1.2,
    margin: 0,
    marginBottom: 6,
  },
  sectionTextGroup: {
    color: '#36425A',
    fontSize: '14px',
    margin: 0,
    minHeight: '42px',
    marginBottom: 16,
  },
  groupWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  sectionText__bold: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  sectionText__highlighted: {
    backgroundColor: '#DAE5FF',
  },
  sectionText__grey: {
    color: theme.palette.textColor,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 1.2,
  },
  editButton: {
    height: '16px',
    marginLeft: 3,
  },
  icon: {
    width: '14px',
    height: '14px',
    color: theme.palette.turquoise,
    '&:hover': {
      color: '#78D5D3',
    },
    '&:active': {
      color: theme.palette.moonstoneBlue,
    },
  },
  headerContainer: {
    display: 'flex',
    flexDirecation: 'row',
  },
});
export default styles;
