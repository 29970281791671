import api from '..';

export const paymentQueuePendingRequest = (params) => {
  return api.get('/payment-queue', { params });
};

export const paymentQueueProcessRequest = ({ _id }) => {
  return api.post(`/payment-queue/${_id}/process`);
};

export const paymentQueueRemoveRequest = ({ _id }) => {
  return api.delete(`/payment-queue/${_id}`);
};
