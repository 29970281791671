import { handleActions } from 'redux-actions';

import * as actions from '../actions';

const defaultState = [];

export default handleActions(
  {
    [actions.getTmdQuestionsSuccess](state, action) {
      return action.response.data || [];
    },
  },
  defaultState,
);
