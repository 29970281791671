import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import queryString from 'query-string';
import map from 'lodash/map';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import last from 'lodash/last';
import * as authSelectors from 'modules/auth/selectors';

export const getAcceptedCurrencies = (state) => {
  const currencies = authSelectors.getUserCurrencies(state);
  const currencyLabels = map(currencies, (item) => item.label);
  return currencyLabels.join(', ');
};

export const formSelector = (formName, ...rest) => formValueSelector(formName)(...rest);

export const getLocation = (state) => cloneDeep(get(state, 'router.location', {}));

export const getParams = createSelector(getLocation, (location) => {
  return queryString.parse(location.search);
});

export const getDefaultAccountId = createSelector(getParams, (params) =>
  get(params, 'accountId', null),
);

export const getToken = (ownProps) => {
  const {
    match: { params },
  } = ownProps;
  const token = get(params, 'token', '');

  return last(token.split('/'));
};

export const getTokenHub = createSelector(getToken, (token) => {
  if (!token) {
    return '';
  }

  try {
    const base64 = token.split('.')[1];
    const json = JSON.parse(atob(base64));
    return json.sourceHub;
  } catch (err) {
    return 'GLOBAL_PRIME';
  }
});

export const getSearchFormValue = (state, formName) => formSelector(formName, state, 'search');
