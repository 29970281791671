import { combineReducers } from 'redux';

import countries from './countries';
import ipCountry from './ipCountry';

const reducer = combineReducers({
  countries,
  ipCountry,
});

export default reducer;
