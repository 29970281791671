import get from 'lodash/get';
import { handleActions } from 'redux-actions';
import * as actions from '../actions';
import { MODE } from '../constants';

const defaultState = {
  notifications: [],
  mode: MODE.ADD,
  notification: {},
  userNotificationsCount: 0,
  userNotifications: [],
  viewedUserNotificationIds: [],
  pendingNotificationsCount: 0,
  usersVerificationNotificationsCount: 0,
  accountsVerificationNotificationsCount: 0,
  followUpsVerificationNotificationsCount: 0,
  experienceUploadsNotificationsCount: 0,
};

export default handleActions(
  {
    [actions.getNotificationsSuccess]: (state, action) => {
      return {
        ...state,
        notifications: action.response.data.notifications.map((notification) => ({
          ...notification,
          users: (notification.users || []).join(', '),
        })),
      };
    },
    [actions.editNotificationOpen]: (state, action) => {
      return { ...state, mode: MODE.EDIT, notification: action.payload };
    },
    [actions.editNotificationClose]: (state) => {
      return { ...state, mode: MODE.ADD };
    },
    [actions.createNotificationsClose]: (state) => {
      return { ...state, mode: MODE.ADD };
    },
    [actions.getUserNotificationsCountSuccess]: (state, action) => {
      return { ...state, userNotificationsCount: get(action, 'response.data.count', 0) };
    },
    [actions.getUserNotificationsSuccess]: (state, action) => {
      return {
        ...state,
        userNotifications: get(action, 'response.data.notifications', []),
        viewedUserNotificationIds: get(action, 'response.data.viewedIds', []),
      };
    },
    [actions.markAsReadRequest]: (state, action) => {
      return {
        ...state,
        viewedUserNotificationIds: [...state.viewedUserNotificationIds, action.payload.id],
        userNotificationsCount: state.userNotificationsCount - 1,
      };
    },
    [actions.getPendingNotificationsCountSuccess]: (state, action) => {
      return { ...state, pendingNotificationsCount: get(action, 'response.data.count', 0) };
    },
    [actions.getUsersVerificationNotificationsCountSuccess]: (state, action) => {
      return {
        ...state,
        usersVerificationNotificationsCount: get(action, 'response.data.count', 0),
      };
    },
    [actions.getUsersVerificationNotificationsCountFail]: (state) => {
      return {
        ...state,
        usersVerificationNotificationsCount: defaultState.usersVerificationNotificationsCount,
      };
    },
    [actions.getAccountsVerificationNotificationsCountSuccess]: (state, action) => {
      return {
        ...state,
        accountsVerificationNotificationsCount: get(action, 'response.data.count', 0),
      };
    },
    [actions.getAccountsVerificationNotificationsCountFail]: (state) => {
      return {
        ...state,
        accountsVerificationNotificationsCount: defaultState.accountsVerificationNotificationsCount,
      };
    },
    [actions.getFollowupsVerificationNotificationsCountSuccess]: (state, action) => {
      return {
        ...state,
        followUpsVerificationNotificationsCount: get(action, 'response.data.count', 0),
      };
    },
    [actions.getFollowupsVerificationNotificationsCountFail]: (state) => {
      return {
        ...state,
        followUpsVerificationNotificationsCount:
          defaultState.followUpsVerificationNotificationsCount,
      };
    },
    [actions.getExperienceUploadsNotificationsCountSuccess]: (state, action) => {
      return {
        ...state,
        experienceUploadsNotificationsCount: get(action, 'response.data.count', 0),
      };
    },
  },
  defaultState,
);
