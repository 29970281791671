export const CALL_STATUS = {
  ATTEMPTING_CONTACT_INITIAL: 'Attempting contact initial',
  ATTEMPTING_CONTACT_WORKING: 'Attempting contact working',
  ATTEMPTING_CONTACT_NO_CONTACT: 'Attempting contact no contact',
  SOME_INTEREST: 'Some interest',
  STRONG_INTEREST: 'Strong interest',
  NOT_INTERESTED: 'Not Interested',
  DO_NOT_CONTACT: 'Do not contact',
  COMPLETED: 'Completed',
  WRONG_NUMBER: 'Wrong Number',
  OVER_3_ATTEMPTS_CALLED: 'Called 3+ attempts',
  NO_CALL_STATUS: 'No call status',
};
