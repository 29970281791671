import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Box from 'material-latest/Box';

import TPTypography from 'components/TP-UI/TPTypography';
import TPIconButton from 'components/TP-UI/TPIconButton';
import { TPTextField } from 'components/TP-UI/TPTextField';
import { MAIN_COLORS, SIZES } from 'components/TP-UI/constants';

import styles from './styles';

export const TPGoToPage = ({ pagesAmount = 0, disabled = false, onChange, className }) => {
  const { t } = useTranslation('common');
  const [goto, setGoto] = useState();

  const handleGoToPageChange = useCallback((value) => {
    setGoto(value);
  }, []);

  const handleGoToPageClick = useCallback(() => {
    setGoto();
    if (goto !== undefined && onChange) {
      const newPage = parseInt(goto) - 1;
      onChange(newPage < 0 ? 0 : newPage >= pagesAmount ? pagesAmount - 1 : newPage);
    }
  }, [onChange, goto, pagesAmount]);

  return (
    <Box sx={[styles.gotoContainer, className]}>
      <TPTypography variant="body2" color={MAIN_COLORS.SECONDARY}>
        {t('common:labels:goToPage')}
      </TPTypography>
      <TPTextField
        name="goToPage"
        value={goto}
        type="number"
        size={SIZES.SMALL}
        onChange={handleGoToPageChange}
        disabled={disabled}
        className={styles.gotoInput}
        reservedErrorSpace={false}
      />
      <TPIconButton disabled={disabled} onClick={handleGoToPageClick}>
        {t('common:buttons:go')}
      </TPIconButton>
    </Box>
  );
};

TPGoToPage.propTypes = {
  /**
   * Total number of pages
   */
  pagesAmount: PropTypes.number,
  disabled: PropTypes.bool,
  /**
   * Called when the page number is entered, function (page)
   */
  onChange: PropTypes.func,
};

export default TPGoToPage;
