export default {
  tinyEditorContainer: (theme) => ({
    '& .tox-tinymce': {
      borderColor: theme.formControl.root.borderColor,
      borderWidth: theme.formControl.root.borderWidth,
    },
    '& :not($tinyEditorError) .tox-tinymce:hover': {
      borderColor: theme.formControl.hover.borderColor,
      borderWidth: theme.formControl.hover.borderWidth,
    },
  }),
  focused: (theme) => ({
    '& :not($tinyEditorError) .tox-tinymce': {
      borderColor: theme.formControl.focus.borderColor,
      borderWidth: theme.formControl.focus.borderWidth,
    },
  }),
  tinyEditorError: (theme) => ({
    '& .tox-tinymce': {
      borderColor: theme.palette.error.dark,
      borderWidth: theme.formControl.root.borderWidth,
    },
    '& .tox-tinymce:hover': {
      borderColor: theme.palette.error.dark,
    },
  }),
  tinyEditorLabel: (theme) => ({
    marginBottom: theme.spacing(1),
  }),
  errorContainer: (theme) => ({
    minHeight: theme.textError.root.minHeight,
  }),
};
