import get from 'lodash/get';

export const getMyFollowers = (state) => get(state, 'myFollowers.followers');

export const getTradeSyncAccounts = (state) => {
  return get(state, 'myFollowers.accounts');
};

export const getLeadAccount = (state) => {
  return get(state, 'myFollowers.leadAccount');
};

export const getInvites = (state) => {
  return get(state, 'myFollowers.invites');
};

export const getCxd = (state) => {
  return get(state, 'myFollowers.cxd');
};

export const getSymbolMap = (state) => {
  return get(state, 'myFollowers.symbolMap');
};

export const getSettingsAccepted = (state) => {
  return get(state, 'myFollowers.settingsAccepted');
};

export const getSettings = (state) => {
  return get(state, 'myFollowers.settings');
};

export const getOpenedTrades = (state) => {
  return get(state, 'myFollowers.openedTrades', []);
};

export const getErrorTrades = (state) => {
  return get(state, 'myFollowers.errorTrades', []);
};

export const getClosedTrades = (state) => {
  return get(state, 'myFollowers.closedTrades', []);
};

export const getClosedTradesCount = (state) => {
  return get(state, 'myFollowers.closedTradesCount', 0);
};

export const getInviteFollowerId = (state) => {
  return get(state, 'myFollowers.inviteFollowerId', '');
};

export const getMyInvitations = (state) => {
  return get(state, 'myFollowers.myInvitations', []);
};

export const getWaitlistCompleted = (state) => {
  return get(state, 'myFollowers.isWaitlistCompleted', false);
};

export const getWaitlist = (state) => {
  return get(state, 'myFollowers.waitlist', []);
};
