export const copyToClipboardByDomNode = (input) => {
  input.select();
  input.setSelectionRange(0, 99999);
  document.execCommand('copy');
};

export const сopyToClipboardByString = (text, onCopy) => {
  if (window.clipboardData?.setData) {
    if (onCopy) {
      onCopy();
    }

    // IE: prevent textarea being shown while dialog is visible
    return window.clipboardData.setData('Text', text);
  }

  if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea');
    textarea.textContent = text;
    // Prevent scrolling to bottom of page in MS Edge
    textarea.style.position = 'fixed';
    document.body.appendChild(textarea);
    textarea.select();
    try {
      if (onCopy) {
        onCopy();
      }

      // Security exception may be thrown by some browsers
      return document.execCommand('copy');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Copy to clipboard failed.', err);
    } finally {
      document.body.removeChild(textarea);
    }
  }

  return navigator.clipboard.writeText(text);
};
