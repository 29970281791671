export const ALL_IN_ONE_CALCULATOR_FORM = 'ALL_IN_ONE_CALCULATOR_FORM';
export const CURRENCY_CALCULATOR_FORM = 'CURRENCY_CALCULATOR_FORM';
export const PIP_VALUE_CALCULATOR_FORM = 'PIP_VALUE_CALCULATOR_FORM';
export const MARGIN_CALCULATOR_FORM = 'MARGIN_CALCULATOR_FORM';
export const SWAPS_CALCULATOR_FORM = 'SWAPS_CALCULATOR_FORM';
export const PROFIT_LOSS_CALCULATOR_FORM = 'PROFIT_LOSS_CALCULATOR_FORM';
export const STOP_LOSS_FORM = 'STOP_LOSS_FORM';

export const LEVERAGE_VALUES = [
  { label: '500', value: 500 },
  { label: '400', value: 400 },
  { label: '300', value: 300 },
  { label: '200', value: 200 },
  { label: '100', value: 100 },
  { label: '66', value: 66 },
  { label: '50', value: 50 },
  { label: '30', value: 30 },
  { label: '25', value: 25 },
  { label: '20', value: 20 },
  { label: '15', value: 15 },
  { label: '10', value: 10 },
  { label: '5', value: 5 },
  { label: '3', value: 3 },
  { label: '2', value: 2 },
  { label: '1', value: 1 },
];

export const MARGIN_STYLES = {
  FX: 'FX',
  CFD: 'CFD',
  CRYPTO: 'Crypto',
};

export const MARGIN_STYLES_WITH_DISABLED_LEVERAGE = [MARGIN_STYLES.CFD, MARGIN_STYLES.CRYPTO];

export const MAP_MARGIN_STYLE_TO_DEFAULT_LEVERAGE_VALUE = {
  [MARGIN_STYLES.CFD]: 100,
  [MARGIN_STYLES.CRYPTO]: 2,
};

export const CONTRACT_SIZE_VALUES = [
  { label: '1 lot = 1', value: 1 },
  { label: '1 lot = 10', value: 10 },
  { label: '1 lot = 100', value: 100 },
  { label: '1 lot = 1,000', value: 1000 },
  { label: '1 lot = 10,000', value: 10000 },
  { label: '1 lot = 100,000', value: 100000 },
];

export const TRADE_TYPES = {
  BUY: 'buy',
  SELL: 'sell',
};

export const TRADE_VALUES = [
  { i18nValue: 'tradingCalculators:constants.tradeValues.buyLong', value: TRADE_TYPES.BUY },
  { i18nValue: 'tradingCalculators:constants.tradeValues.sellShort', value: TRADE_TYPES.SELL },
];

export const LANGUAGE_MAPPER = {
  zh: 'chm',
  en: 'en',
  th: 'th',
};

export const ACCOUNT_TYPE_TRADER = 'trader';
export const ACCOUNT_TYPE_PRO = 'pro';

export const ACCOUNT_TYPES = [ACCOUNT_TYPE_TRADER, ACCOUNT_TYPE_PRO].map((value) => ({
  value,
  label: value,
}));

export const SWAP_TYPES = {
  POINT: 'Points',
  DOLLAR: 'Dollar',
  INTEREST: 'Interest',
};
