export default (theme) => ({
  root: {
    minHeight: 400,
  },
  chartWrapper: {
    display: 'flex',
  },
  listWrapper: {
    paddingLeft: 20,
  },
  nameLabel: {
    color: theme.palette.textColor,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,

    paddingRight: 10,
  },
  valueLabel: {
    color: theme.palette.textColor,
    fontStyle: 'normal',
    fontSize: 16,
    fontWeight: 700,
  },
  indexLabel: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.1,
    color: theme.palette.simpleGrey,
  },
  subTitle: {
    padding: 20,
    color: theme.palette.textColor,
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.1,
    fontStyle: 'normal',
  },
  topUsersWrapper: {
    border: `1px solid ${theme.palette.green2}`,
  },
  gridField: {
    paddingBottom: 16,
  },
});
