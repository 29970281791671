import api from 'modules/api';

export const getEmailTemplatesRequest = (payload) => {
  return api.get('/email-templates', { params: payload });
};

export const createEmailTemplateRequest = (payload) => {
  return api.post('/email-templates', payload);
};

export const editEmailTemplateRequest = (payload) => {
  return api.patch('/email-templates', payload);
};

export const deleteEmailTemplateRequest = ({ id }) => {
  return api.delete(`/email-templates/${id}`);
};

export const getEmailHistoryRequest = (payload) => {
  return api.get('/email-history', { params: payload });
};

export const emailResendRequest = (payload) => {
  return api.post('/email/resend', payload);
};
