import React, { useState, useCallback, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import CustomModal from 'modules/common/CustomModal';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Typography } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import { useTranslation } from 'react-i18next';
import placeholderImg from 'static/notFoundImage.svg';
import { SHOW_NOTIFICATIONS_MODAL, NOTIFICATION_TYPES } from '../../constants';
import Notification from '../Notification';
import PrimaryButton from 'modules/common/PrimaryButton';
import { Box, Stack } from 'material-latest';
import Spinner from '../../../common/Spinner';
import classNames from 'classnames';

const ShowNotificationsModal = ({
  classes,
  markAsRead,
  markAllAsRead,
  isNotificationsLoading,
  isMarkAsReadLoading,
  notifications,
  modalClassNames,
  isCloseButtonNeeded = true,
  disableEnforceFocus,
  fullScreen,
}) => {
  const { t } = useTranslation('notificationCentre');

  const [selectedTab, setTab] = useState(NOTIFICATION_TYPES.NOTIFICATION);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [whatsNewCount, setWhatsNewCount] = useState(0);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [viewedAll, setViewedAll] = useState(false);

  const onTabChange = useCallback((event, tab) => {
    setTab(tab);
  }, []);

  useEffect(() => {
    setNotificationsCount(
      notifications.filter(
        (notification) =>
          notification.type === NOTIFICATION_TYPES.NOTIFICATION && notification.viewed !== true,
      ).length,
    );
    setWhatsNewCount(
      notifications.filter(
        (notification) =>
          notification.type === NOTIFICATION_TYPES.WHATS_NEW && notification.viewed !== true,
      ).length,
    );
    setFilteredNotifications(
      notifications.filter((notification) =>
        viewedAll
          ? notification.type === selectedTab
          : notification.type === selectedTab && notification.viewed === false,
      ),
    );
  }, [selectedTab, notifications, viewedAll]);

  const markAllAsReadHandler = useCallback(() => {
    const ids = notifications
      .filter((i) => i.viewed !== true)
      .map((notification) => notification._id);
    markAllAsRead(ids);
  }, [notifications, markAllAsRead]);

  const markAsReadHandler = useCallback(
    (id) => {
      markAsRead(id);
    },
    [markAsRead],
  );

  const handleViewAllClick = useCallback(() => {
    setViewedAll(true);
  }, []);

  return (
    <CustomModal
      fullScreen={fullScreen}
      modalName={SHOW_NOTIFICATIONS_MODAL}
      modalClassNames={classNames(modalClassNames, classes.modalRoot)}
      className={classes.modalContainer}
      isCloseButtonNeeded={isCloseButtonNeeded}
      disableEnforceFocus={disableEnforceFocus}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom="20px">
        <Typography className={classes.title}>{t('page.title')}</Typography>
        {notifications.length ? (
          <PrimaryButton
            size="small"
            color="secondary"
            onClick={markAllAsReadHandler}
            label={t('page.markAllAsRead')}
          />
        ) : null}
      </Stack>
      <Tabs classes={{ root: classes.tabs }} value={selectedTab} onChange={onTabChange}>
        <Tab
          label={
            <Badge
              classes={{ badge: classes.badge, root: classes.badgeRoot }}
              badgeContent={notificationsCount}
              color="primary">
              {t('page.notifications')}
            </Badge>
          }
          classes={{ root: classes.tab }}
          value={NOTIFICATION_TYPES.NOTIFICATION}
        />
        <Tab
          label={
            <Badge
              classes={{ badge: classes.badge, root: classes.badgeRoot }}
              badgeContent={whatsNewCount}
              color="primary">
              {t('page.whatsNew')}
            </Badge>
          }
          classes={{ root: classes.tab }}
          value={NOTIFICATION_TYPES.WHATS_NEW}
        />
      </Tabs>
      {isNotificationsLoading || isMarkAsReadLoading ? (
        <div className={classes.loader}>
          <Spinner />
        </div>
      ) : (
        <div className={classes.notifications}>
          {filteredNotifications.length ? (
            filteredNotifications.map((notification, key) => (
              <Notification key={key} notification={notification} markAsRead={markAsReadHandler} />
            ))
          ) : (
            <>
              <img src={placeholderImg} alt="" className={classes.placeholderImage} />
              <Typography variant="subtitle1" className={classes.noDataTitle}>
                {t('page.emptyMessage')}
              </Typography>
            </>
          )}
          {!viewedAll && (
            <Box display="flex" justifyContent="center" pt={2}>
              <PrimaryButton
                color="primary"
                size="large"
                className={classes.secondaryButton}
                label={'Show read notifications'}
                onClick={handleViewAllClick}
              />
            </Box>
          )}
        </div>
      )}
    </CustomModal>
  );
};

export default withStyles(styles)(ShowNotificationsModal);
