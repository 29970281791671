import { fontSize } from '../../CustomThemeProviderMui5/themes/font';

export default {
  groupLabel: (theme) => ({
    ...theme.formControl.groupLabel,
    color: theme.palette.textSecondary.dark,
  }),
  errorContainer: (theme) => ({
    minHeight: theme.textError.root.minHeight,
  }),
  menuRoot: {
    zIndex: 1500,
  },
  menuPaper: {
    maxHeight: 180,
    overflow: 'auto',
  },
  menuItem: {
    whiteSpace: 'initial',
  },
  menuItemSmall: {
    paddingTop: '5px',
    paddingBottom: '5px',
    fontSize: fontSize._14,
  },
  chips: (theme) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }),
  textFieldSelectMode: {
    cursor: 'pointer',
    '& .MuiInputBase-root, & .MuiOutlinedInput-input': {
      cursor: 'pointer',
    },
  },
};
