import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Typography } from '@material-ui/core';

const TableLevelField = ({ classes, label, dataLabel, tableItem, className }) => {
  const { t } = useTranslation();
  const levelValue = tableItem[dataLabel];
  return (
    <div className={classNames([classes.item, className])}>
      <Typography className={classes.label}>{label}</Typography>
      <div
        className={classNames({
          [classes.levelContainer]: true,
        })}>
        <Typography className={classes.levelText}>
          {t(`common:constants.eventImpactValues.${levelValue}`)}
        </Typography>
      </div>
    </div>
  );
};

TableLevelField.propTypes = {
  label: PropTypes.string.isRequired,
  dataLabel: PropTypes.string.isRequired,
  tableItem: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(TableLevelField);
