const styles = (theme) => ({
  image: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '24px',
    '@media (min-width:768px)': {
      marginBottom: '12px',
    },
  },
  subtitle: {
    display: 'block',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    marginBottom: '12px',
    color: theme.palette.text.main,
  },
  text: {
    display: 'block',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '21px',
    color: theme.palette.text.main,
  },
});

export default styles;
