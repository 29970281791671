import { fontSize } from '../../CustomThemeProviderMui5/themes/font';

export default {
  groupLabel: (theme) => ({
    ...theme.formControl.groupLabel,
  }),
  errorContainer: (theme) => ({
    minHeight: theme.textError.root.minHeight,
  }),
  customSelect: {
    '&:before, &:after': {
      display: 'none',
    },
    // '&$hiddenArrow .MuiSelect-select.MuiSelect-select': {
    //   paddingRight: 0,
    // },
  },
  hiddenArrow: (theme) => ({
    // '& .MuiSelect-select.MuiSelect-select': {
    //   paddingRight: theme.spacing(1.25),
    // },
  }),
  menuPaper: {
    maxHeight: '180px',
  },
  menuItemSmall: {
    fontSize: fontSize._14,
  },
};
