import React, { useCallback, useMemo } from 'react';
import { reset } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core';

import { accountPlanSelector, targetUserQuizSelector } from '../../../auth/selectors';
import { Routes } from '../../../../constants/routeConstants';
import { ACCOUNT_SERVERS } from '../../../../constants';
import CustomModal from '../../../common/CustomModal';
import verificationModalNames from '../../constants/verificationModalNames';
import { ACCOUNT_PLANS } from '../../../../constants/acctountPlans';
import { closeModal, openModal } from '../../../common/CustomModal/actions';
import {
  PRO_QUIZ_FORM,
  PRO_QUIZ_MODAL,
  RETAIL_QUIZ_FORM,
  RETAIL_QUIZ_MODAL,
} from '../../../profileQuiz/constants';
import { getQuizRequest } from '../../../profileQuiz/actions';
import ImageSubtitleTextContent from '../../../common/ContentModal/ImageSubtitleTextContent';

import quizFailureImage from '../../../../static/quizzes/quiz-failure.svg';
import styles from './styles';

const FirstFailedQuizModal = ({ classes }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('quiz');
  const selectedPlan = useSelector(accountPlanSelector);
  const targetQuiz = useSelector(targetUserQuizSelector);

  const handleClose = useCallback(() => {
    dispatch(closeModal(verificationModalNames.TRY_AGAIN_MODAL));
  }, [dispatch]);

  const handleTryAgain = useCallback(() => {
    dispatch(getQuizRequest());
    handleClose();
    if (!targetQuiz?.isBlocked) {
      const activeQuizModalName =
        selectedPlan === ACCOUNT_PLANS.PRO ? PRO_QUIZ_MODAL : RETAIL_QUIZ_MODAL;
      dispatch(openModal(activeQuizModalName));
    }
  }, [dispatch, handleClose, selectedPlan, targetQuiz]);

  const handleTryLater = useCallback(() => {
    handleClose();
    history.push({
      pathname: Routes.MY_ACCOUNTS,
      state: { activeTab: ACCOUNT_SERVERS.DEMO },
    });
  }, [handleClose, history]);

  const buttons = useMemo(
    () => [
      {
        label: t('firstFailModal.secondaryButtonText'),
        variant: 'text',
        onClick: handleTryLater,
      },
      {
        label: t('firstFailModal.primaryButtonText'),
        color: 'primary',
        onClick: handleTryAgain,
      },
    ],
    [t, handleTryAgain, handleTryLater],
  );

  return (
    <CustomModal
      modalClassNames={classes.modal}
      className={classes.modalPaper}
      modalName={verificationModalNames.TRY_AGAIN_MODAL}
      title={t(
        `quiz:${
          selectedPlan === ACCOUNT_PLANS.PRO ? ACCOUNT_PLANS.PRO : ACCOUNT_PLANS.RETAIL
        }.page.title`,
      )}
      isCloseButtonNeeded
      buttons={buttons}>
      <ImageSubtitleTextContent
        image={quizFailureImage}
        text={t(`firstFailModal.${selectedPlan || 'retail'}Description`)}
        subtitle={t('firstFailModal.title')}
      />
    </CustomModal>
  );
};

export default withStyles(styles)(FirstFailedQuizModal);
