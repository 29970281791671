export const QUIZ_MODAL_STYLES = {
  modal: {
    '& > div:nth-child(3)': {
      width: '95%',
      '@media (min-width:768px)': {
        width: 'auto',
      },
      '& > div': {
        borderRadius: 0,
        margin: 0,
        width: '100%',
        maxWidth: '529px',
        '@media (min-width:768px)': {
          margin: '32px',
          width: 'auto',
        },
      },
    },
  },
  modalPaper: {
    width: 'unset !important',
    '@media (min-width:768px)': {
      width: '529px !important',
    },
  },
};
