import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getTranslation } from 'utils/compositeTranslationHandler';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { openModal } from 'modules/common/CustomModal/actions';

import styles from './styles';

const NotificationItem = ({ classes, notification, onClick }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handler = useCallback(() => {
    if (notification.modalName) {
      dispatch(openModal(notification.modalName));
    }
    onClick(notification.id);
  }, [dispatch, notification, onClick]);
  return (
    <div
      role="presentation"
      key={notification.id}
      className={classNames(
        notification.type === 'document' ? classes.notificationDocument : classes.notificationItem,
        classes[notification.type],
      )}
      onClick={handler}>
      <div className={classes.message}>{getTranslation(t, notification.message)}</div>
    </div>
  );
};

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(NotificationItem);
