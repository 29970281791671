import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { isTablet, isMobile } from 'react-device-detect';
import Box from 'material-latest/Box';
import DnsIcon from '@mui/icons-material/Dns';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';

import TPButton from 'components/TP-UI/TPButton';
import TPIconButton from 'components/TP-UI/TPIconButton';
import TPTooltip from 'components/TP-UI/TPTooltip';
import TPBadge from 'components/TP-UI/TPBadge';
import { SIZES } from 'components/TP-UI/constants';
import { debounce } from './helpers/debounce';
import { MAX_PRIMARY_FILTERS } from './constants';

import styles from './styles';

const TPFilters = ({
  fields,
  children,
  loading = false,
  disabled = false,
  handleSubmit,
  reset,
  pristine,
  maxPrimaryFilters = MAX_PRIMARY_FILTERS,
  className,
}) => {
  const { t } = useTranslation('common');
  const [showFilterButtons, setShowFilterButtons] = useState(false);
  const [primaryFilters, setPrimaryFilters] = useState();
  const [additionalFilters, setAdditionalFilters] = useState([]);
  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);

  useEffect(() => {
    if (fields) {
      setShowFilterButtons(fields.length > maxPrimaryFilters);
      setPrimaryFilters(fields.slice(0, maxPrimaryFilters));
      if (fields.length > maxPrimaryFilters) {
        setAdditionalFilters(fields.slice(maxPrimaryFilters));
      }
    }
  }, [fields, maxPrimaryFilters]);

  const handleToggleFiltersClick = useCallback(() => {
    setShowAdditionalFilters((val) => !val);
  }, []);

  const debouncedHandleChange = useMemo(
    () =>
      debounce(() => {
        handleSubmit();
      }, 700),
    [handleSubmit],
  );

  return (
    <Box component="form" sx={[styles.headerContainer, className]} onSubmit={handleSubmit}>
      <Box sx={styles.actionsContainer}>
        {primaryFilters ? (
          <Box sx={styles.filtersContainer}>
            {primaryFilters.map(({ width, ...props }) => (
              <Box sx={styles.filter} style={width ? { width: width } : null} key={props.name}>
                <Field
                  clearable={true}
                  {...props}
                  reservedErrorSpace={false}
                  size={SIZES.SMALL}
                  debounced={additionalFilters.length === 0}
                  disabled={loading || disabled}
                  fullWidth
                  {...(additionalFilters.length === 0 ? { onChange: debouncedHandleChange } : {})}
                />
              </Box>
            ))}
          </Box>
        ) : null}
        <Box sx={styles.settingsContainer}>
          <Box sx={styles.settings}>
            <Box sx={styles.filtersButtonsContainer}>
              {showFilterButtons ? (
                <>
                  <TPButton alternative type="submit" loading={loading} disabled={disabled}>
                    {t('buttons.apply')}
                  </TPButton>
                  <TPButton secondary onClick={reset} disabled={loading || disabled}>
                    {t('buttons.reset')}
                  </TPButton>
                </>
              ) : null}
            </Box>
            <Box sx={styles.settingButtonsContainer}>
              {additionalFilters.length > 0 ? (
                <TPTooltip
                  disabled={isTablet || isMobile}
                  content={
                    showAdditionalFilters ? t('labels.collapseFilters') : t('labels.expandFilters')
                  }>
                  <TPIconButton onClick={handleToggleFiltersClick}>
                    <TPBadge variant="dot" color="error" invisible={pristine}>
                      {showAdditionalFilters ? <DnsIcon /> : <DnsOutlinedIcon />}
                    </TPBadge>
                  </TPIconButton>
                </TPTooltip>
              ) : null}
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
      {showAdditionalFilters ? (
        <Box sx={styles.filtersContainer}>
          {additionalFilters.map(({ width, ...props }) => (
            <Box sx={styles.filter} style={width ? { width: width } : null} key={props.name}>
              <Field
                clearable={true}
                {...props}
                reservedErrorSpace={false}
                size={SIZES.SMALL}
                disabled={loading || disabled}
                fullWidth
              />
            </Box>
          ))}
        </Box>
      ) : null}
    </Box>
  );
};

TPFilters.propTypes = {
  form: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      /**
       * Since there is no form label should be in filter's panel, the placeholder should inform user about field meaning
       */
      placeholder: PropTypes.node,
      /**
       * Redux Form Component
       */
      component: PropTypes.elementType.isRequired,
      /**
       * field width in pixels or percentage, ex. 100px or 25%
       */
      width: PropTypes.string,
      className: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  maxPrimaryFilters: PropTypes.number,
  /**
   * Called when the page number or pageSize is changed, function ({page, pageSize})
   */
  onSubmit: PropTypes.func,
};

const TPFiltersReduxForm = reduxForm({
  enableReinitialize: true,
  destroyOnUnmount: true,
  /**
   * This is default name to resolve issue with unmount for cases like using sorting or/and file actions or/and allow columns settings.
   * Need to set unique name for each place of usage, if you define filters!
   */
  form: 'TPTABLE_FILTERS_FORM',
})(TPFilters);

export default TPFiltersReduxForm;
