import { handleActions } from 'redux-actions';
import * as actions from '../actions';

const defaultState = {
  isLoading: true,
  isUpdating: false,
};
export default handleActions(
  {
    [actions.getCustomFieldsQuestionsSuccess]: (state, action) => {
      return { ...state, questions: action.response.data.customFields, isLoading: false };
    },

    [actions.updateCustomFieldsQuestionsRequest]: (state) => {
      return { ...state, isUpdating: true };
    },
    [actions.updateCustomFieldsQuestionsSuccess]: (state, action) => {
      const updatedQuestion = action.response.data;
      return {
        ...state,
        questions: state.questions.map((question) => {
          if (question._id === updatedQuestion._id) {
            return updatedQuestion;
          }
          return question;
        }),
        isUpdating: false,
      };
    },

    [actions.updateCustomFieldsQuestionsFail]: (state) => {
      return { ...state, isUpdating: false };
    },

    [actions.removeCustomFieldsQuestionsRequest]: (state) => {
      return { ...state, isUpdating: true };
    },
    [actions.removeCustomFieldsQuestionsSuccess]: (state, action) => {
      const deletedQuestion = action.response.data;
      return {
        ...state,
        questions: state.questions.filter((question) => question._id !== deletedQuestion._id),
        isUpdating: false,
      };
    },

    [actions.removeCustomFieldsQuestionsFail]: (state) => {
      return {
        ...state,
        isUpdating: false,
      };
    },

    [actions.createCustomFieldsQuestionsRequest]: (state) => {
      return { ...state, isUpdating: true };
    },
    [actions.createCustomFieldsQuestionsSuccess]: (state, action) => {
      const createdQuestion = action.response.data;
      return { ...state, questions: [...state.questions, createdQuestion], isUpdating: false };
    },
    [actions.createCustomFieldsQuestionsFail]: (state) => {
      return { ...state, isUpdating: false };
    },
  },
  defaultState,
);
