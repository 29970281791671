import { palette } from '../palette';
import { fontSize } from '../../font';

const MuiFormControlLabel = {
  styleOverrides: {
    root: {
      color: palette.text.dark,
      fontSize: fontSize._14,
      marginLeft: 0,

      '&.Mui-disabled': {
        color: palette.textSecondary.lightest,
      },
    },

    label: {
      color: palette.text.dark,
      fontSize: fontSize._14,

      '&.Mui-disabled': {
        color: palette.textSecondary.lightest,
      },
    },
  },
};

export default MuiFormControlLabel;
