import { handleActions } from 'redux-actions';
import { getAccountsWithOpenedTradesFail, getAccountsWithOpenedTradesSuccess } from '../actions';

const defaultState = { accountsWithOpenedTrades: [] };

export default handleActions(
  {
    [getAccountsWithOpenedTradesSuccess]: (state, action) => ({
      ...state,
      accountsWithOpenedTrades: action.response.data.accountsWithOpenedTradesList,
    }),
    [getAccountsWithOpenedTradesFail]: () => ({
      ...defaultState,
    }),
  },
  defaultState,
);
