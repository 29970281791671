const styles = (theme) => ({
  countContainer: {
    position: 'absolute',
    width: '99px',
    opacity: 0.49,
    borderRadius: '0 0 6px 0',
    backgroundColor: '#DAE5FF',
  },
  countText: {
    display: 'flex',
    color: ' #888EB4',
    fontSize: '10px',
    lineHeight: '12px',
    padding: '3px 8px',
  },
  label: {
    color: theme.palette.simpleGrey,
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 1.4,
  },
  dataText: {
    color: theme.palette.textColor,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.2,
  },
  item: {
    padding: '20px 12px',
  },
});

export default styles;
