import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';
import isURL from 'utils/isURL';
import { Typography } from '@material-ui/core';

const CustomLink = ({ classes, children, className, to, onClick, isNavLinkClass, ...other }) => {
  const navLinkClassName = classNames(
    onClick && !to && !isNavLinkClass ? classes.link : classes.navLink,
    className,
  );

  if (onClick && !to) {
    return (
      <Typography component="span" className={navLinkClassName} onClick={onClick} {...other} c>
        {children}
      </Typography>
    );
  }

  return isURL(to) ? (
    <a href={to} className={navLinkClassName} {...other}>
      {children}
    </a>
  ) : (
    <NavLink className={navLinkClassName} to={to} {...other}>
      {children}
    </NavLink>
  );
};

CustomLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  onClick: PropTypes.func,
  classes: PropTypes.shape({
    navLink: PropTypes.string.isRequired,
  }).isRequired,
  isNavLinkClass: PropTypes.bool,
};

export default withStyles(styles)(CustomLink);
