export const DEFAULT_PAGINATION = {
  value: 0,
  pageSize: 10,
  total: 0,
};

export const TABLE_CHANGE_EVENT_TYPE = {
  SORTING: 'sorting',
  FILTERS: 'filters',
  PAGINATION: 'pagination',
  COLUMNS: 'columns',
};
