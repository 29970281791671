import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

import CustomLink from '../CustomLink';
import arrow from 'static/arrow.svg';

const Breadcrumbs = ({ classes, routes, className, isMobileHidden = true }) => {
  return (
    <div className={classNames(className, { [classes.container]: isMobileHidden })}>
      {routes.map((route, index) => {
        const isLastRoute = index === routes.length - 1;
        return (
          <span key={route.label}>
            <CustomLink
              className={classNames({
                [classes.breadCrumbsLink]: true,
                [classes.lastLinkStyle]: isLastRoute,
              })}
              to={route.link}>
              {route.label}
            </CustomLink>
            <img
              className={classNames({
                [classes.arrowImage]: true,
                [classes.lastArrowStyle]: isLastRoute,
              })}
              src={arrow}
              alt="arrow"
            />
          </span>
        );
      })}
    </div>
  );
};

Breadcrumbs.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string,
    }),
  ).isRequired,
  isMobileHidden: PropTypes.bool,
};

export default withStyles(styles)(Breadcrumbs);
