import { all, put, takeEvery } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import get from 'lodash/get';
import config from 'config';
import * as notificationsActions from 'modules/notifications/actions';

import * as actionCreators from '../actions';
import { MIGRATION_FORM } from '../constants';

function* redirectToDashboard() {
  const signInUrld = config.HUBS.find((hub) => hub.KEY === 'GLOBAL_PRIME_X').SIGN_IN_URL;
  yield put((window.window.location = `${signInUrld}?migrated`));
}

function* showError(action) {
  const errorMessage = get(action, 'error.data.message');
  yield put(
    stopSubmit(MIGRATION_FORM, {
      _error: errorMessage,
    }),
  );

  yield put(notificationsActions.showNotificationError(errorMessage));
}

export default function* watchRequest() {
  yield all([
    takeEvery(actionCreators.userMigrationSuccess, redirectToDashboard),
    takeEvery(actionCreators.userMigrationFail, showError),
    takeEvery(actionCreators.acceptMigrationFail, showError),
  ]);
}
