import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import Sort from '@material-ui/icons/Sort';
import styles from './styles';

const TradingItem = ({ classes, dataLabel, onSort, isSorted, getSortDirection }) => {
  const isDisabled = !isSorted(dataLabel);
  const sortDirection = getSortDirection();

  let buttonClassName = classes.sortButton;
  if (isDisabled) {
    buttonClassName += ' ' + classes.sortDisabled;
  } else if (sortDirection === 1) {
    buttonClassName += ' ' + classes.sortDesc;
  }

  const onSortHandler = useCallback(() => {
    onSort(dataLabel);
  }, [dataLabel, onSort]);

  return (
    <IconButton
      className={buttonClassName}
      onClick={onSortHandler}
      aria-label="Sort"
      color="primary">
      <Sort fontSize="inherit" />
    </IconButton>
  );
};

export default withStyles(styles)(TradingItem);
