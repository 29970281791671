const getAuthData = (data) => {
  const token = new URLSearchParams(data).get('token', null);
  const id = new URLSearchParams(data).get('id', null);
  const refreshToken = new URLSearchParams(data).get('refreshToken', null);

  if (!token || !id | refreshToken) {
    return null;
  }
  let prepAdata = {
    token,
    _id: id,
    refreshToken,
    role: 'client',
  };

  return prepAdata;
};

export default getAuthData;
