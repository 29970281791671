import { Roles } from 'constants/userRoles';

const rules = {
  [Roles.ADMIN]: {
    static: [
      'userManagement::employees:view',
      'userManagement::blockedUsers:view',
      'userProfile:phoneNumber:view',
      'verificationManager:phoneNumber:view',
      'emailTemplate:personal:create',
      'emailTemplate:personal:delete',
      'userManagement:addDeposit:view',
      'userManagement:loginAsUser:view',
      'userManagement:myClients:view',
      'userManagement:deletedUsers:view',
    ],
  },
  [Roles.MANAGER]: {
    static: [
      'userManagement::blockedUsers:view',
      'userProfile:phoneNumber:get',
      'userManagement:loginAsUser:view',
      'userManagement:myClients:view',
    ],
    dynamic: {
      'emailTemplate:personal:create': ({ canSendFromIndividualEmail } = {}) =>
        canSendFromIndividualEmail === true,
      'userManagement:addDeposit:view': ({ canAddDeposit } = {}) => canAddDeposit === true,
    },
  },
  [Roles.CLIENT]: {
    static: ['userProfile:phoneNumber:view', 'theme:use', 'userNotifications:check'],
  },
};

export default rules;
