// import config from 'config';

export default (theme) => ({
  wrapper: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    background: `radial-gradient(circle at top left, #C0F82615 30%, transparent 30%) top left,
    radial-gradient(circle at bottom right, #C0F82615 30%, transparent 30%) bottom right`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    paddingTop: 80,
  },
  wraperContent: {
    maxWidth: '900px',
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
});
