import { push } from 'connected-react-router';
import { Routes } from 'constants/routeConstants';
import { all, put, takeEvery } from 'redux-saga/effects';
import { loginSinceRejectionSuccess } from '../actions';

function* loginSinceRejectionSuccessHandler() {
  yield put(
    push({
      pathname: Routes.PROFILE_VERIFICATION,
    }),
  );
}

export default function* watchRequest() {
  yield all([takeEvery(loginSinceRejectionSuccess, loginSinceRejectionSuccessHandler)]);
}
