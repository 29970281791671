import api from '..';

export const getCountriesRequest = (userId) => {
  const query = userId ? `?userId=${userId}` : '';
  return api.get('/verification/personal/countries' + query);
};

export const getCountryByIpRequest = () => {
  return api.get('/verification/personal/ipCountry');
};

/*export const submitPersonalDetailsRequest = (payload) => {
  return api.post('/verification/personal', payload);
};*/

export const updatePersonalDetailsRequest = (payload) => {
  return api.patch('/verification/personal', payload);
};

export const updateUserStateRequest = ({ _id, state }) => {
  const params = { _id, state };
  return api.patch('/verification/update-user-state', params);
};

export const updateUserAddressLineRequest = ({ _id, address }) => {
  const params = { _id, address };
  return api.patch('/verification/update-user-address', params);
};
