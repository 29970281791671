export default (theme) => ({
  selected: {
    backgroundColor: theme.palette.lightGreen,
    fontSize: 'inherit',
  },
  label: {
    color: theme.palette.icon.black,
    fontSize: 16,
    '&$selected': {
      color: theme.palette.icon.black,
      fontWeight: 600,
    },
  },
  icon: {
    color: '#DAE5FF',
    height: 12,
  },
  expandIcon: {
    color: '#DAE5FF',
    fontSize: theme.typography.pxToRem(16),
    height: 20,
  },
  backdropRoot: {
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  backdropHidden: {
    visibility: 'hidden',
  },
  menu: {
    backgroundColor: '#0A1939',
  },
  menuItem: {
    fontSize: theme.typography.pxToRem(16),
    color: '#DAE5FF',
  },
  menuItemList: {
    '&:hover': {
      backgroundColor: '#0A1939',
    },
  },
  linkContainer: {
    textDecoration: 'none',
    '&:focus, &:hover, &:visited, &:link, &:active': {
      textDecoration: 'none',
    },
  },
});
