import React, { useState } from 'react';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import TimelineIcon from '@mui/icons-material/Timeline';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import MenuIcon from '@mui/icons-material/Menu';

import { Routes } from 'constants/routeConstants';
import TPGrid from 'components/TP-UI/TPGrid';
import TPTypography from 'components/TP-UI/TPTypography';
import TPDrawer from 'components/TP-UI/TPDrawer';
import TPBottomNavigation from 'components/TP-UI/TPBottomNavigation';
import TPBottomNavigationAction from 'components/TP-UI/TPBottomNavigationAction';
import TPBottomNavigationActionMenu from 'components/TP-UI/TPBottomNavigationActionMenu';

import styles from './styles';

const items = [
  {
    id: 'accounts',
    iconComponent: PersonOutlineIcon,
    path: Routes.MY_ACCOUNTS,
    label: 'Accounts',
  },
  {
    id: 'payments',
    iconComponent: AccountBalanceWalletOutlinedIcon,
    path: Routes.PAYMENTS,
    label: 'Payment Management',
  },
  {
    id: 'tradeIdeas',
    iconComponent: AssessmentOutlinedIcon,
    label: 'Dashboard',
    items: [
      {
        id: 'analystViews',
        iconComponent: TimelineIcon,
        label: { key: 'menu:category.analystViews' },
        path: Routes.ANALYST_VIEWS,
      },
      {
        id: 'technicalInsight',
        iconComponent: EmojiObjectsOutlinedIcon,
        label: { key: 'menu:category.technicalInsight' },
        path: Routes.TECHNICAL_INSIGHT,
      },
    ],
  },
];

const BottomNavigations = () => {
  const [openedDrawer, setOpenedDrawer] = useState(false);

  return (
    <TPGrid container spacing={1}>
      <TPBottomNavigation value={1} className={styles.root}>
        {items.map((item) => {
          return item?.items?.length ? (
            <TPBottomNavigationActionMenu
              selectedRoute={Routes.MY_ACCOUNTS}
              id={item.id}
              key={item.id}
              iconComponent={item.iconComponent}
              menuItems={item.items}
              label={item.label}
            />
          ) : (
            <TPBottomNavigationAction
              selectedRoute={Routes.MY_ACCOUNTS}
              id={item.id}
              key={item.id}
              iconComponent={item.iconComponent}
              path={item.path}
              label={item.label}
            />
          );
        })}
        <TPBottomNavigationAction
          expandable
          expanded={openedDrawer}
          iconComponent={MenuIcon}
          onClick={() => setOpenedDrawer(true)}
          label="ID Verification"
        />
      </TPBottomNavigation>
      <TPDrawer open={openedDrawer} anchor="top" onClose={() => setOpenedDrawer(false)}>
        <TPTypography variant="h1" m={3}>
          Top Drawer
        </TPTypography>
      </TPDrawer>
    </TPGrid>
  );
};

export default BottomNavigations;
