import theme from 'components/CustomThemeProvider/themes/normal';
import { palette } from 'components/CustomThemeProvider/themes/palette';

export const styles = {
  root: {
    minHeight: '30vh',
    background: theme.palette.black,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    px: { xs: 3, sm: 5 },
    py: { xs: 3, sm: 5 },
    m: '0 auto',
    maxWidth: { xs: 500, sm: 600, md: 1200 },
    '& > p:first-of-type': {
      mt: 0,
    },
    '& > p:last-of-type': {
      mb: 0,
    },
    '& a': {
      color: palette.green2,
      textDecorationColor: palette.green2,
      '&:hover': {
        color: palette.green2,
        textDecorationColor: palette.green2,
      },
    },
  },
  headerText: {
    fontSize: 24,
  },
  typography: {
    fontSize: 16,
  },
};
