import { createAction } from 'redux-actions';

export const paymentsWithdrawBankwireRequest = createAction('PAYMENTS_WITHDRAW_BANKWIRE_REQUEST');
export const paymentsWithdrawBankwireSuccess = createAction('PAYMENTS_WITHDRAW_BANKWIRE_SUCCESS');
export const paymentsWithdrawBankwireFail = createAction('PAYMENTS_WITHDRAW_BANKWIRE_FAIL');

export const paymentsWithdrawInternationalRequest = createAction(
  'PAYMENTS_WITHDRAW_INTERNATIONAL_REQUEST',
);
export const paymentsWithdrawInternationalSuccess = createAction(
  'PAYMENTS_WITHDRAW_INTERNATIONAL_SUCCESS',
);
export const paymentsWithdrawInternationalFail = createAction(
  'PAYMENTS_WITHDRAW_INTERNATIONAL_FAIL',
);

export const paymentsWithdrawHyperwalletRequest = createAction(
  'PAYMENTS_WITHDRAW_HYPERWALLET_REQUEST',
);
export const paymentsWithdrawHyperwalletSuccess = createAction(
  'PAYMENTS_WITHDRAW_HYPERWALLET_SUCCESS',
);
export const paymentsWithdrawHyperwalletFail = createAction('PAYMENTS_WITHDRAW_HYPERWALLET_FAIL');

export const paymentsWithdrawHyperwalletInternationalRequest = createAction(
  'PAYMENTS_WITHDRAW_HYPERWALLET_INTERNATIONAL_REQUEST',
);
export const paymentsWithdrawHyperwalletInternationalSuccess = createAction(
  'PAYMENTS_WITHDRAW_HYPERWALLET_INTERNATIONAL_SUCCESS',
);
export const paymentsWithdrawHyperwalletInternationalFail = createAction(
  'PAYMENTS_WITHDRAW_HYPERWALLET_INTERNATIONAL_FAIL',
);

export const paymentsWithdrawVisaRequest = createAction('PAYMENTS_WITHDRAW_VISA_REQUEST');
export const paymentsWithdrawVisaSuccess = createAction('PAYMENTS_WITHDRAW_VISA_SUCCESS');
export const paymentsWithdrawVisaFail = createAction('PAYMENTS_WITHDRAW_VISA_FAIL');

export const validateCardRequest = createAction('VALIDATE_CARD_REQUEST');
export const validateCardSuccess = createAction('VALIDATE_CARD_SUCCESS');
export const validateCardFail = createAction('VALIDATE_CARD_FAIL');

export const getPaymentOptionsRequest = createAction('GET_PAYMENT_OPTIONS_REQUEST');
export const getPaymentOptionsSuccess = createAction('GET_PAYMENT_OPTIONS_SUCCESS');
export const getPaymentOptionsFail = createAction('GET_PAYMENT_OPTIONS_FAIL');
export const initializeAccountReference = createAction('INITIALIZE_ACCOUNT_REFERENCE');
export const initializeChineseName = createAction('INITIALIZE_CHINESE_NAME');

export const searchWithdrawHistoryRequest = createAction('SEARCH_WITHDRAW_HISTORY_REQUEST');
export const searchWithdrawHistorySuccess = createAction('SEARCH_WITHDRAW_HISTORY_SUCCESS');
export const searchWithdrawHistoryFail = createAction('SEARCH_WITHDRAWT_HISTORY_FAIL');

export const clientConfirmWithdrawRequest = createAction('CLIENT_CONFIRM_WITHDRAW_REQUEST');
export const clientConfirmWithdrawSuccess = createAction('CLIENT_CONFIRM_WITHDRAW_SUCCESS');
export const clientConfirmWithdrawFail = createAction('CLIENT_CONFIRM_WITHDRAW_FAIL');

export const initializeFromBankAccount = createAction('INITIALIZE_FROM_BANK_ACCOUNT');

export const openSaveBankDetailsModal = createAction('OPEN_SAVE_BANK_DETAILS_MODAL');

export const skrillWithdrawalRequest = createAction('SKRILL_WITHDRAWAL_REQUEST');
export const skrillWithdrawalSuccess = createAction('SKRILL_WITHDRAWAL_SUCCESS');
export const skrillWithdrawalFail = createAction('SKRILL_WITHDRAWAL_FAIL');

export const netellerWithdrawalRequest = createAction('NETELLER_WITHDRAWAL_REQUEST');
export const netellerWithdrawalSuccess = createAction('NETELLER_WITHDRAWAL_SUCCESS');
export const netellerWithdrawalFail = createAction('NETELLER_WITHDRAWAL_FAIL');

export const paypalWithdrawalRequest = createAction('PAYPAL_WITHDRAWAL_REQUEST');
export const paypalWithdrawalSuccess = createAction('PAYPAL_WITHDRAWAL_SUCCESS');
export const paypalWithdrawalFail = createAction('PAYPAL_WITHDRAWAL_FAIL');

export const payidWithdrawalRequest = createAction('PAYID_WITHDRAWAL_REQUEST');
export const payidWithdrawalSuccess = createAction('PAYID_WITHDRAWAL_SUCCESS');
export const payidWithdrawalFail = createAction('PAYID_WITHDRAWAL_FAIL');

export const perfectMoneyWithdrawalRequest = createAction('PERFECT_MONEY_WITHDRAWAL_REQUEST');
export const perfectMoneyWithdrawalSuccess = createAction('PERFECT_MONEY_WITHDRAWAL_SUCCESS');
export const perfectMoneyWithdrawalFail = createAction('PERFECT_MONEY_WITHDRAWAL_FAIL');

export const jetonWithdrawalRequest = createAction('JETON_WITHDRAWAL_REQUEST');
export const jetonWithdrawalSuccess = createAction('JETON_WITHDRAWAL_SUCCESS');
export const jetonWithdrawalFail = createAction('JETON_WITHDRAWAL_FAIL');

export const interacETransferOutboundWithdrawalRequest = createAction(
  'INTERAC_E_TRANSFER_OUTBOUND_WITHDRAWAL_REQUEST',
);
export const interacETransferOutboundWithdrawalSuccess = createAction(
  'INTERAC_E_TRANSFER_OUTBOUND_WITHDRAWAL_SUCCESS',
);
export const interacETransferOutboundWithdrawalFail = createAction(
  'INTERAC_E_TRANSFER_OUTBOUND_WITHDRAWAL_FAIL',
);

export const paymentsWithdrawLocalBankwireRequest = createAction(
  'PAYMENTS_WITHDRAW_LOCAL_BANKWIRE_REQUEST',
);
export const paymentsWithdrawLocalBankwireSuccess = createAction(
  'PAYMENTS_WITHDRAW_LOCAL_BANKWIRE_SUCCESS',
);
export const paymentsWithdrawLocalBankwireFail = createAction(
  'PAYMENTS_WITHDRAW_LOCAL_BANKWIRE_FAIL',
);

export const paymentsWithdrawUnionpayBankwireRequest = createAction(
  'PAYMENTS_WITHDRAW_UNIONPAY_BANKWIRE_REQUEST',
);
export const paymentsWithdrawUnionpayBankwireSuccess = createAction(
  'PAYMENTS_WITHDRAW_UNIONPAY_BANKWIRE_SUCCESS',
);
export const paymentsWithdrawUnionpayBankwireFail = createAction(
  'PAYMENTS_WITHDRAW_UNIONPAY_BANKWIRE_FAIL',
);

export const currencyCalculatorRequest = createAction('CURRENCY_CALCULATOR_REQUEST');
export const currencyCalculatorSuccess = createAction('CURRENCY_CALCULATOR_SUCCESS');
export const currencyCalculatorFail = createAction('CURRENCY_CALCULATOR_FAIL');

export const getThaiBanksRequest = createAction('GET_THAI_BANKS_REQUEST');
export const getThaiBanksSuccess = createAction('GET_THAI_BANKS_SUCCESS');
export const getThaiBanksFail = createAction('GET_THAI_BANKS_FAIL');

export const paymentsWithdrawThunderxpayRequest = createAction(
  'PAYMENTS_WITHDRAW_THUNDERXPAY_REQUEST',
);
export const paymentsWithdrawThunderxpaySuccess = createAction(
  'PAYMENTS_WITHDRAW_THUNDERXPAY_SUCCESS',
);
export const paymentsWithdrawThunderxpayFail = createAction('PAYMENTS_WITHDRAW_THUNDERXPAY_FAIL');

export const validateAddressRequest = createAction('VALIDATE_ADDRESS_REQUEST');
export const validateAddressSuccess = createAction('VALIDATE_ADDRESS_SUCCESS');
export const validateAddressFail = createAction('VALIDATE_ADDRESS_FAIL');

export const feeRequest = createAction('FEE_REQUEST');
export const feeSuccess = createAction('FEE_SUCCESS');
export const feeFail = createAction('FEE_FAIL');

export const paymentsWithdrawFinraxRequest = createAction('PAYMENTS_WITHDRAW_FINRAX_REQUEST');
export const paymentsWithdrawFinraxSuccess = createAction('PAYMENTS_WITHDRAW_FINRAX_SUCCESS');
export const paymentsWithdrawFinraxFail = createAction('PAYMENTS_WITHDRAW_FINRAX_FAIL');

export const paymentsWithdrawAstropayRequest = createAction('PAYMENTS_WITHDRAW_ASTROPAY_REQUEST');
export const paymentsWithdrawAstropaySuccess = createAction('PAYMENTS_WITHDRAW_ASTROPAY_SUCCESS');
export const paymentsWithdrawAstropayFail = createAction('PAYMENTS_WITHDRAW_ASTROPAY_FAIL');

export const paymentsWithdrawDragonpayRequest = createAction('PAYMENTS_WITHDRAW_DRAGONPAY_REQUEST');
export const paymentsWithdrawDragonpaySuccess = createAction('PAYMENTS_WITHDRAW_DRAGONPAY_SUCCESS');
export const paymentsWithdrawDragonpayFail = createAction('PAYMENTS_WITHDRAW_DRAGONPAY_FAIL');

export const paymentsWithdrawDokupayRequest = createAction('PAYMENTS_WITHDRAW_DOKUPAY_REQUEST');
export const paymentsWithdrawDokupaySuccess = createAction('PAYMENTS_WITHDRAW_DOKUPAY_SUCCESS');
export const paymentsWithdrawDokupayFail = createAction('PAYMENTS_WITHDRAW_DOKUPAY_FAIL');

export const getTransferDetailsRequest = createAction('GET_TRANSFER_DETAILS_REQUEST');
export const getTransferDetailsSuccess = createAction('GET_TRANSFER_DETAILS_SUCCESS');
export const getTransferDetailsFail = createAction('GET_TRANSFER_DETAILS_FAIL');

export const getUserStatusRequest = createAction('GET_USER_STATUS_REQUEST');
export const getUserStatusSuccess = createAction('GET_USER_STATUS_SUCCESS');
export const getUserStatusFail = createAction('GET_USER_STATUS_FAIL');

// export const requestHyperwalletStatusChangeRequest = createAction(
//   'REQUEST_HYPERWALLET_STATUS_CHANGE_REQUEST',
// );
// export const requestHyperwalletStatusChangeSuccess = createAction(
//   'REQUEST_HYPERWALLET_STATUS_CHANGE_SUCCESS',
// );
// export const requestHyperwalletStatusChangeFail = createAction(
//   'REQUEST_HYPERWALLET_STATUS_CHANGE_FAIL',
// );
