const styles = (theme) => ({
  jumperLabel: {
    color: '#888EB4',
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '17px',
  },
  jumpButton: {
    color: theme.palette.black,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '19px',
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    padding: 0,
    font: 'inherit',
    outline: 'inherit',
  },
  pageNumberField: {
    width: 32,
    height: 30,
    marginBottom: 0,
  },
  input: {
    padding: '0 8px',
    height: 15,
    textAlign: 'center',
  },
  textFieldWrapper: {
    width: 'auto',
    paddingBottom: 0,
  },
});

export default styles;
